import { z } from 'zod';
import { zId } from ':utils/id';
import { zOptionalString, zRequiredString } from ':utils/common';
import { zFileOutput, zFileUpsert } from './file';

/** One A4 page of text is ~1800 characters so this should be more than enough. But this is going to be html, so just to be sure ... */
export const PAGE_CONTENT_MAX_LENGTH = 10000;

export type PageOutput = z.infer<typeof zPageOutput>;
export const zPageOutput = z.object({
    id: zId,
    title: zRequiredString(),
    content: zOptionalString(PAGE_CONTENT_MAX_LENGTH),
    buttonText: z.string(),
    cover: zFileOutput.optional(),
    isHideButton: z.boolean(),
});

/** Like PUT (except for the cover, which is like PATCH). */
export type PageUpsert = z.infer<typeof zPageUpsert>;
export const zPageUpsert = z.object({
    title: zRequiredString(),
    content: zOptionalString(PAGE_CONTENT_MAX_LENGTH),
    buttonText: zRequiredString(),
    /** Like PATCH. */
    cover: zFileUpsert.nullish(),
    isHideButton: z.boolean(),
});
