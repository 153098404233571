import { useCallback, useMemo, type BaseSyntheticEvent, type ChangeEvent } from 'react';
import { type FieldPath, type FieldValues, type RegisterOptions, type SubmitErrorHandler, type SubmitHandler, type UseFormHandleSubmit, type UseFormRegister, type UseFormSetValue } from 'react-hook-form';
import { toNumber, transformToPositiveIntegerOrEmpty, transformToPrice, transformToPriceNegative, transformToValidNumberOrEmpty, transformToValidNumberOrEmptyNegative } from ':utils/math';
import type { MultiValue, SingleValue } from ':components/shadcn';
import { taxRateFromPercent, taxRateToPercent, type TaxRate } from ':utils/money';

type TransformFunction<TValue> = (rawValue: unknown) => TValue;

// TODO is it possible to type this better?

function registerTransform<TFieldValues extends FieldValues>(
    register: UseFormRegister<TFieldValues>,
    setValue: UseFormSetValue<TFieldValues>,
    transform: TransformFunction<any>,
    defaultOptions: Record<string, unknown> = {},
): UseFormRegister<TFieldValues> {
    return <TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
        name: TFieldName,
        options?: RegisterOptions<TFieldValues, TFieldName>,
    ) => {
        return register(name, { ...defaultOptions, ...options, onChange: (event: ChangeEvent<HTMLInputElement>) => setValue(name, transform(event.target.value)) });
    };
}

type UseTransformReturn<TFieldValues extends FieldValues> = {
    register: UseFormRegister<TFieldValues>;
    registerPositiveInteger: UseFormRegister<TFieldValues>;
    registerPrice: UseFormRegister<TFieldValues>;
    registerPriceNegative: UseFormRegister<TFieldValues>;
    //registerTaxInPercent: UseFormRegister<TFieldValues>;
};

/**
 * The purpose of this hook is to extend functionality of the useForm hook to enable custom transformation of input values.
 * For example with registerPrice, if the user inputs `-32` to the `registerPrice` input, it is transformed to `0` since price can't be less than zero.
 * Furthemore, `32.345` will be rounded to `32.35` because we allow only `${DEFAULT_DECIMAL_PRECISION}`.
 * @param register
 * @param setValue
 * @returns
 */
export function useTransform<TFieldValues extends FieldValues>(
    register: UseFormRegister<TFieldValues>,
    setValue: UseFormSetValue<TFieldValues>,
): UseTransformReturn<TFieldValues> {
    return useMemo(() => ({
        register,
        registerPositiveInteger: registerTransform(register, setValue, transformToPositiveIntegerOrEmpty, { setValueAs: transformToValidNumberOrEmpty }),
        registerPrice: registerTransform(register, setValue, transformToPrice, { setValueAs: transformToValidNumberOrEmpty }),
        registerPriceNegative: registerTransform(register, setValue, transformToPriceNegative, { setValueAs: transformToValidNumberOrEmptyNegative }),
        //registerTaxInPercent: registerTransform(register, setValue, transformToTaxInPercent, { valueAsNumber: true })
    }), [ register, setValue ]);
}

export type ControlledRules<
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<RegisterOptions<TFieldValues, TName>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;

/**
 * This hook fixes the inability of the useForm hook to stop propagation of the submit events.
 * Without it, nested forms would be impossible because they would automatically trigger their parent forms.
 */
export function useNestedForm<TFieldValues extends FieldValues = FieldValues>(handleSubmit: UseFormHandleSubmit<TFieldValues>): UseFormHandleSubmit<TFieldValues>{
    return useCallback((onValid: SubmitHandler<TFieldValues>, onInvalid?: SubmitErrorHandler<TFieldValues>) => {
        return async (e?: BaseSyntheticEvent) => {
            e?.stopPropagation();
            handleSubmit(onValid, onInvalid)(e);
        };
    }, [ handleSubmit ]);
}

// Multi values

export type Value<Type, IsMulti extends boolean> = IsMulti extends true
    ? Type[]
    : Type | undefined;
export type OnChange<Type, IsMulti extends boolean> = (value: Value<Type, IsMulti>) => void;

type UniversalType<Type, IsMulti extends boolean> = IsMulti extends true
    ? MultiValue<Type> | Type[]
    : SingleValue<Type> | Type | undefined;

type UniversalOnChange<Type, IsMulti extends boolean> = (value: UniversalType<Type, IsMulti>) => void;

export function createOnChange<TypeIn, TypeOut, IsMulti extends boolean>(
    transform: (value: TypeIn) => TypeOut,
    isMulti: IsMulti | undefined,
    onChange: OnChange<TypeOut, IsMulti>,
): UniversalOnChange<TypeIn, IsMulti> {
    if (isMulti) {
        return multiValue => (onChange as OnChange<TypeOut, true>)((multiValue as TypeIn[]).map(transform));
    }
    else {
        return singleValue => (onChange as OnChange<TypeOut, false>)((singleValue === null || singleValue === undefined)
            ? undefined
            : transform(singleValue as TypeIn),
        );
    }
}

export function createValue<TypeIn, TypeOut, IsMulti extends boolean>(
    transform: (value: TypeOut) => TypeIn,
    isMulti: IsMulti | undefined,
    value: Value<TypeOut, IsMulti>,
): Value<TypeIn, IsMulti> {
    if (isMulti)
        return (value as Value<TypeOut, true>).map(transform) as Value<TypeIn, IsMulti>;
    else
        return (value !== undefined ? transform(value as TypeOut) : undefined) as Value<TypeIn, IsMulti>;
}

export function createValueWithFilter<TypeIn, TypeOut, IsMulti extends boolean>(
    transform: (value: TypeOut) => TypeIn | undefined,
    isMulti: IsMulti | undefined,
    value: Value<TypeOut, IsMulti>,
): Value<TypeIn, IsMulti> {
    if (isMulti)
        return (value as Value<TypeOut, true>).map(transform).filter(item => item !== undefined) as Value<TypeIn, IsMulti>;
    else
        return (value !== undefined ? transform(value as TypeOut) : undefined) as Value<TypeIn, IsMulti>;
}
