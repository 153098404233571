import { StoreBioDisplay } from ':components/store/StoreBioDisplay';
import { getStoreBackgroundStyle, getStoreStyles } from ':components/store/utils';
import { customFonts, defaultFont, isStoreDesignEqual, type StoreDesign, type StoreOutput } from ':utils/entity/store';
import { useStoreDesign, type ColorSuggestion, type UseStoreDesignDispatch, type UseStoreDesignState } from './useStoreDesign';
import { Accordion, Form, ScrollArea, SpinnerButton } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ColorPickerDropdown } from ':frontend/components/forms/ColorPicker';
import { ProductPublicDisplay } from ':components/store/product/ProductCard';
import type { ProductOutput } from ':utils/entity/product';
import { FillIcon, GradientIcon, SectionIcon, SquareLayoutGrid3Icon, TextTool2Icon } from ':components/icons/basic';
import { toRecord } from ':utils/common';
import { cn } from ':components/shadcn/utils';
import { useBlockerModal, useTailwindMediaQuery } from ':frontend/hooks';
import { BlockNavigationModal } from ':frontend/components/BlockNavigationModal';
import { NoPaymentMethodBannerStore, StoreNotEnabledBanner } from './StorePage';

type StoreDesignTabProps = Readonly<{
    store: StoreOutput;
    products: ProductOutput[];
}>;

export function StoreDesignTab({ store, products }: StoreDesignTabProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'store.design' });
    const { state, dispatch } = useStoreDesign(store.design);
    // const [ showUpsell, setShowUpsell ] = useToggle(false); Hiding the badge is disabled for everyone for now so no upsell needed
    const isChanged = useMemo(() => !isStoreDesignEqual(state.form, state.original), [ state.form, state.original ]);
    const { control } = useBlockerModal(isChanged);

    const isDesktop = useTailwindMediaQuery({ minWidth: 'md' });

    return (
        <div className='flex'>
            <BlockNavigationModal control={control} />

            <div {...getStoreStyles(state.form, 'max-md:hidden grow fl-store-bg md:pr-[360px] min-h-[calc(100dvh-var(--topbar-height))]')}>
                <NoPaymentMethodBannerStore />
                <StoreNotEnabledBanner />

                <div className='py-12 px-4'>
                    <StoreBioDisplay bio={store.bio} image={store.image} />

                    <div className='mt-6 flex flex-col items-center gap-3'>
                        {products.map(product => (
                            <ProductPublicDisplay key={product.id} product={product} />
                        ))}
                    </div>
                </div>
            </div>

            <div className='w-full md:w-[360px] md:fixed md:top-[--topbar-height] md:bottom-0 md:right-[--removed-body-scroll-bar-size] max-md:pb-[73px] bg-white flex flex-col'>
                {isDesktop ? (
                    <ScrollArea className='flex-1'>
                        <StoreDesignAccordion state={state} dispatch={dispatch} />
                    </ScrollArea>
                ) : (
                    <StoreDesignAccordion state={state} dispatch={dispatch} />
                )}

                <div className='max-md:fixed max-md:bottom-20 w-full px-6 py-4 border-t border-secondary-50 bg-white shadow-[0_-4px_6px_0_rgb(0,0,0,0.05)]'>
                    <SpinnerButton
                        onClick={() => dispatch({ type: 'sync', operation: 'design', fid: FID_SAVE })}
                        disabled={!isChanged}
                        className='w-full'
                        fetching={state.sync?.fetching}
                        fid={FID_SAVE}
                    >
                        {t('save-button')}
                    </SpinnerButton>
                </div>
            </div>
        </div>
    );
}

function StoreDesignAccordion({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'store.design' });

    return (
        <Accordion.Root type='multiple' defaultValue={[ 'template', 'background', 'card', 'font' ]}>
            <Accordion.Item value='template'>
                <Accordion.Trigger><SquareLayoutGrid3Icon />{t('template.title')}</Accordion.Trigger>
                <Accordion.Content>
                    <TemplateSuggestionRadio state={state} dispatch={dispatch} />
                </Accordion.Content>
            </Accordion.Item>

            <Accordion.Item value='background'>
                <Accordion.Trigger><FillIcon />{t('background.title')}</Accordion.Trigger>
                <Accordion.Content>
                    <Form.RadioGroup
                        className='flex-row'
                        value={state.form.background.type}
                        onValueChange={value => dispatch({ type: 'input', field: 'background.type', value })}
                    >
                        <Form.CustomRadioItem value='solid' direction='col' label={t('background.type.value-solid')}>
                            <div className='p-4 bg-secondary-50'>
                                <FillIcon size='lg' />
                            </div>
                        </Form.CustomRadioItem>
                        <Form.CustomRadioItem value='gradient' direction='col' label={t('background.type.value-gradient')}>
                            <div className='p-4 bg-secondary-50'>
                                <GradientIcon size='lg' />
                            </div>
                        </Form.CustomRadioItem>
                    </Form.RadioGroup>
                    {/* TODO - image background <ImageSparkle3Icon /> */}

                    <h4 className='mt-8 mb-3'>{t('background.color-suggestions-title')}</h4>
                    <ColorSuggestionRadio state={state} dispatch={dispatch} />
                    <CustomColorPicker state={state} dispatch={dispatch} className='mt-6' />
                </Accordion.Content>
            </Accordion.Item>

            <Accordion.Item value='card'>
                <Accordion.Trigger><SectionIcon />{t('card.title')}</Accordion.Trigger>
                <Accordion.Content>
                    <h4 className='mb-3'>{t('card.corners.title')}</h4>
                    <Form.RadioGroup
                        className='flex-row'
                        value={state.form.card.corners}
                        onValueChange={value => dispatch({ type: 'input', field: 'card.corners', value })}
                    >
                        <Form.CustomRadioItem value='rounded' label={t('card.corners.value-rounded')} labelClassName='sr-only'>
                            {cardSkeleton('rounded-md w-[146px]')}
                        </Form.CustomRadioItem>
                        <Form.CustomRadioItem value='square' label={t('card.corners.square')} labelClassName='sr-only'>
                            {cardSkeleton('rounded-none w-[146px]')}
                        </Form.CustomRadioItem>
                    </Form.RadioGroup>

                    <h4 className='mt-8 mb-3'>{t('card.shadow.title')}</h4>
                    <Form.RadioGroup
                        className='flex-row'
                        value={state.form.card.shadow}
                        onValueChange={value => dispatch({ type: 'input', field: 'card.shadow', value })}
                    >
                        <Form.CustomRadioItem value='none' direction='col' label={t('card.shadow.value-none')}>
                            <div className='shadow-none w-[92px] h-12 border border-secondary-200' />
                        </Form.CustomRadioItem>
                        <Form.CustomRadioItem value='soft' direction='col' label={t('card.shadow.value-soft')}>
                            <div className='shadow-store-soft w-[92px] h-12 border border-secondary-200' />
                        </Form.CustomRadioItem>
                        <Form.CustomRadioItem value='hard' direction='col' label={t('card.shadow.value-hard')}>
                            <div className='shadow-store-hard w-[92px] h-12 border border-secondary-200' />
                        </Form.CustomRadioItem>
                    </Form.RadioGroup>

                    <h4 className='mt-8 mb-3'>{t('card.button.title')}</h4>
                    <Form.RadioGroup
                        className='flex-row'
                        value={state.form.card.button}
                        onValueChange={value => dispatch({ type: 'input', field: 'card.button', value })}
                    >
                        <Form.CustomRadioItem value='square' direction='col' label={t('card.button.value-square')}>
                            <div className='rounded-none w-[92px] h-6 border border-secondary-200' />
                        </Form.CustomRadioItem>
                        <Form.CustomRadioItem value='rounded' direction='col' label={t('card.button.value-rounded')}>
                            <div className='rounded-sm w-[92px] h-6 border border-secondary-200' />
                        </Form.CustomRadioItem>
                        <Form.CustomRadioItem value='pill' direction='col' label={t('card.button.value-pill')}>
                            <div className='rounded-full w-[92px] h-6 border border-secondary-200' />
                        </Form.CustomRadioItem>
                    </Form.RadioGroup>

                    <h4 className='mt-8 mb-3'>{t('card.buttonColor.title')}</h4>
                    <div className='flex items-center gap-2'>
                        <ColorPickerDropdown color={state.form.card.buttonBackground} onSave={value => dispatch({ type: 'input', field: 'card.buttonBackground', value })} />
                        <span>#{state.form.card.buttonBackground}</span>
                        <div className='grow' />
                        <span>#{state.form.card.buttonColor}</span>
                        <ColorPickerDropdown color={state.form.card.buttonColor} onSave={value => dispatch({ type: 'input', field: 'card.buttonColor', value })} />
                    </div>
                    <div className='mt-2 px-1 flex items-center justify-between text-secondary-300'>
                        <span>{t('card.buttonColor.background')}</span>
                        <span>{t('card.buttonColor.color')}</span>
                    </div>
                </Accordion.Content>
            </Accordion.Item>

            <Accordion.Item value='font' className='border-0'>
                <Accordion.Trigger><TextTool2Icon />{t('font.title')}</Accordion.Trigger>
                <Accordion.Content>
                    <FontFamilyRadio state={state} dispatch={dispatch} />
                </Accordion.Content>
            </Accordion.Item>

            {/* Hiding the badge is disabled for everyone for now
                        <Accordion.Item value='other'>
                            <Accordion.Trigger><Nut2Icon />{t('other.title')}</Accordion.Trigger>
                            <Accordion.Content>
                                <div className='flex items-center gap-2'>
                                    <Form.Switch
                                        label={t('other.hide-flowlance-badge-switch')}
                                        checked={state.form.other.hideFlowlanceBadge}
                                        onCheckedChange={value => {
                                            if (!subscription.restrictions.store.customDesign) {
                                                setShowUpsell.true();
                                                return;
                                            }

                                            dispatch({ type: 'input', field: 'other.hideFlowlanceBadge', value });
                                        }}
                                    />
                                </div>
                            </Accordion.Content>
                        </Accordion.Item> */}
        </Accordion.Root>
    );
}

const FID_SAVE = 'save';

type StateDispatchProps = Readonly<{
    state: UseStoreDesignState;
    dispatch: UseStoreDesignDispatch;
}>;

function TemplateSuggestionRadio({ state, dispatch }: StateDispatchProps) {
    // Yes, the label is used as id. And yes, we compare the whole object each time. Is there a better way?
    const selectedLabel = useMemo(() =>
        Object.values(templateSuggestions).find(s => isStoreDesignEqual(s.value, state.form))?.label,
    [ state.form ]);

    return (
        <Form.RadioGroup
            className='w-full grid grid-cols-[repeat(auto-fit,68px)] justify-between gap-8'
            value={selectedLabel ?? 'custom'}
            onValueChange={suggestionId => dispatch({ type: 'suggestion', template: templateSuggestions[suggestionId].value })}
        >
            {Object.keys(templateSuggestions).map(key => {
                const suggestion = templateSuggestions[key];
                const background = getStoreBackgroundStyle(suggestion.value.background);

                return (
                    // We need w-17 because there is a margin of 2px on each side for the outline.
                    <Form.CustomRadioItem key={key} value={suggestion.label} label={suggestion.label} direction='col' className='w-17'>
                        {storeSkeleton(background)}
                    </Form.CustomRadioItem>
                );
            })}
        </Form.RadioGroup>
    );
}

function storeSkeleton(background: string, className?: string) {
    return (
        <div className={cn('w-16 h-16 rounded flex flex-col items-center', className)} style={{ background }}>
            <div className='mt-[3px] w-[18px] h-[18px] rounded-full bg-white'/>
            <div className='mt-[2px] h-px w-4 bg-white' />
            <div className='mt-px h-px w-[22px] bg-white' />
            <div className='mt-1 flex gap-[2px]'>
                <div className='w-[5px] h-[5px] rounded bg-white' />
                <div className='w-[5px] h-[5px] rounded bg-white' />
                <div className='w-[5px] h-[5px] rounded bg-white' />
                <div className='w-[5px] h-[5px] rounded bg-white' />
                <div className='w-[5px] h-[5px] rounded bg-white' />
            </div>
            <div className='mt-1 w-12 h-2 rounded bg-white' />
            <div className='mt-[2px] w-12 h-2 rounded bg-white' />
        </div>
    );
}

const solidSuggestions = toRecord<'id', ColorSuggestion>('id', [
    { id: 'yellow', color1: 'fdf5a6' },
    { id: 'pink', color1: 'fdd7e4' },
    { id: 'blue', color1: 'a7d8f2' },
    { id: 'orange', color1: 'fbc3a7' },
    { id: 'green', color1: 'bde7bd' },
    { id: 'gray', color1: 'dadada' },
]);

const gradientSuggestions = toRecord<'id', ColorSuggestion>('id', [
    { id: 'orange-pink', color1: 'fbc3a7', color2: 'fdd7e4' },
    { id: 'purple-orange', color1: 'd4a5e1', color2: 'fbc3a7' },
    { id: 'green-blue', color1: 'bde7bd', color2: 'a7d8f2' },
    { id: 'yellow-red', color1: 'fdf5a6', color2: 'f9a6a6' },
    { id: 'blue-yellow', color1: 'a7d8f2', color2: 'fdf5a6' },
    { id: 'red-purple', color1: 'f9a6a6', color2: 'd4a5e1' },
]);

function ColorSuggestionRadio({ state, dispatch }: StateDispatchProps) {
    const bg = state.form.background;
    const suggestions = (bg.type === 'gradient' ? gradientSuggestions : solidSuggestions);
    const selectedId = useMemo(() =>
        Object.values(suggestions).find(s => s.color1 === bg.color1 && (s.color2 === undefined || s.color2 === bg.color2))?.id,
    [ suggestions, bg ]);

    return (
        <Form.RadioGroup
            className='w-full flex-row justify-between'
            value={selectedId ?? 'custom'}
            onValueChange={suggestionId => dispatch({ type: 'suggestion', backgroundColor: suggestions[suggestionId] })}
        >
            {Object.keys(suggestions).map(key => {
                const suggestion = suggestions[key];
                const background = getStoreBackgroundStyle({ type: bg.type, color2: '000000', ...suggestion });
                const label = '#' + suggestion.color1 + bg.type === 'gradient' ? ' - #' + suggestion.color2 : '';

                return (
                    <Form.CustomRadioItem key={key} value={key} label={label} labelClassName='sr-only'>
                        <div className='w-8 h-8' style={{ background }} />
                    </Form.CustomRadioItem>
                );
            })}
        </Form.RadioGroup>
    );
}

function CustomColorPicker({ state, dispatch, className }: StateDispatchProps & Readonly<{ className?: string }>) {
    const bg = state.form.background;
    const background = getStoreBackgroundStyle(bg, 'x');

    return (
        <div className={className}>
            {bg.type === 'gradient' && (
                <div className='mb-3 w-full h-8 rounded' style={{ background }} />
            )}
            <div className='flex items-center gap-2'>
                <ColorPickerDropdown color={bg.color1} onSave={value => dispatch({ type: 'input', field: 'background.color1', value })} />
                <span>#{bg.color1}</span>
                {bg.type === 'gradient' && (<>
                    <div className='grow' />
                    <span>#{bg.color2}</span>
                    <ColorPickerDropdown color={bg.color2} onSave={value => dispatch({ type: 'input', field: 'background.color2', value })} />
                </>)}
            </div>
        </div>
    );
}

function cardSkeleton(className?: string) {
    return (
        <div className={cn('px-2 py-4 flex border border-secondary-200', className)}>
            <div>
                <div className='w-5 h-1 rounded bg-secondary-100' />
                <div className='mt-1 w-10 h-1 rounded bg-secondary-100' />
                <div className='mt-1 w-15 h-1 rounded bg-secondary-100' />
            </div>
            <div className='grow' />
            <div className='w-5 h-2 bg-secondary-100 self-end' />
        </div>
    );
}

// TODO different radio design

function FontFamilyRadio({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'store.design.font' });

    return (
        <Form.RadioGroup
            value={state.form.font}
            onValueChange={value => dispatch({ type: 'input', field: 'font', value })}
        >
            <Form.RadioItem
                key={defaultFont}
                value={defaultFont}
                label={<span className='tracking-normal font-semibold'>{t('default-value-label')}</span>}
            />

            {customFonts.map(({ name }) => (
                <Form.RadioItem
                    key={name}
                    value={name}
                    label={<span className='tracking-normal' style={{ fontFamily: name }}>{name}</span>}
                />
            ))}
        </Form.RadioGroup>
    );
}

type TemplateSuggestion = { label: string, value: StoreDesign };

const templateSuggestions = toRecord<'label', TemplateSuggestion>('label', [
    { label: 'Default', value: {
        background: {
            type: 'solid',
            color1: 'f4f2f7',
            color2: 'cbb3fb',
        },
        card: {
            corners: 'rounded',
            shadow: 'none',
            button: 'square',
            buttonBackground: '200456',
            buttonColor: 'ffffff',
        },
        font: defaultFont,
    } },
    { label: 'Midnight Blue', value: {
        // TODO transparent
        background: {
            type: 'gradient',
            color1: '0d1b2a',
            color2: '1b263b',
        },
        card: {
            corners: 'rounded',
            shadow: 'soft',
            button: 'pill',
            buttonBackground: '200456',
            buttonColor: 'ffffff',
        },
        font: defaultFont,
    } },
    { label: 'Lavender Mist', value: {
        background: {
            type: 'solid',
            color1: 'e6e6fa',
            color2: 'cbb3fb',
        },
        card: {
            corners: 'rounded',
            shadow: 'hard',
            button: 'pill',
            buttonBackground: '200456',
            buttonColor: 'ffffff',
        },
        font: defaultFont,
    } },
    { label: 'Apricot Sorbet', value: {
        background: {
            type: 'solid',
            color1: 'ffdab9',
            color2: 'cbb3fb',
        },
        card: {
            corners: 'rounded',
            shadow: 'hard',
            button: 'pill',
            buttonBackground: '200456',
            buttonColor: 'ffffff',
        },
        font: defaultFont,
    } },
    { label: 'Sky High', value: {
        background: {
            type: 'solid',
            color1: '4682b4',
            color2: 'cbb3fb',
        },
        card: {
            corners: 'rounded',
            shadow: 'hard',
            button: 'pill',
            buttonBackground: '200456',
            buttonColor: 'ffffff',
        },
        font: defaultFont,
    } },
    { label: 'Slate Blue', value: {
        background: {
            type: 'solid',
            color1: '708090',
            color2: 'cbb3fb',
        },
        card: {
            corners: 'rounded',
            shadow: 'hard',
            button: 'pill',
            buttonBackground: '200456',
            buttonColor: 'ffffff',
        },
        font: defaultFont,
    } },
    { label: 'Sunset Gradient', value: {
        // TODO transparent
        background: {
            type: 'gradient',
            color1: 'ffa07a',
            color2: '800080',
        },
        card: {
            corners: 'rounded',
            shadow: 'soft',
            button: 'pill',
            buttonBackground: '200456',
            buttonColor: 'ffffff',
        },
        font: defaultFont,
    } },
    { label: 'Retro Pastel', value: {
        background: {
            type: 'gradient',
            color1: 'ffc0cb',
            color2: 'add8e6',
        },
        card: {
            corners: 'rounded',
            shadow: 'hard',
            button: 'pill',
            buttonBackground: '200456',
            buttonColor: 'ffffff',
        },
        font: defaultFont,
    } },
    { label: 'Lavender Dream', value: {
        background: {
            type: 'solid',
            color1: 'e3b9ff',
            color2: 'cbb3fb',
        },
        card: {
            corners: 'rounded',
            shadow: 'soft',
            button: 'pill',
            buttonBackground: '200456',
            buttonColor: 'ffffff',
        },
        font: defaultFont,
    } },
    { label: 'Pearl Mist', value: {
        background: {
            type: 'solid',
            color1: 'f0f8ff',
            color2: 'cbb3fb',
        },
        card: {
            corners: 'rounded',
            shadow: 'soft',
            button: 'pill',
            buttonBackground: '200456',
            buttonColor: 'ffffff',
        },
        font: defaultFont,
    } },
    { label: 'Sky Pearl', value: {
        background: {
            type: 'gradient',
            color1: 'add8e6',
            color2: 'ffffff',
        },
        card: {
            corners: 'rounded',
            shadow: 'soft',
            button: 'pill',
            buttonBackground: '200456',
            buttonColor: 'ffffff',
        },
        font: defaultFont,
    } },
]);
