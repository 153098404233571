import { useMemo, useRef, useState, type Dispatch, type SetStateAction } from 'react';
import { CreatedAtEndFilter, CreatedAtStartFilter } from ':frontend/components/common/filters/CreatedAtFilter';
import { OrderStateFilter } from ':frontend/components/common/filters/OrderStateFilter';
import { createOrderClientFilter } from ':frontend/components/common/filters/OrderClientFilter';
import { useClients, useToggle } from ':frontend/hooks';
import { Button, Form, Modal, SpinnerButton } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { FilterRow, useFilters, type UseFiltersControl } from ':frontend/components/common/filters/FilterRow';
import { api } from ':frontend/utils/api';
import DownloadFile, { type DownloadFileRef } from ':frontend/components/common/DownloadFile';
import { getEnumValues } from ':utils/common';
import { type Id } from ':utils/id';
import { documentExportOrderFormats, ExportOrdersFormat, type ExportOrdersQuery, type OrderType } from ':utils/entity/order';
import type { DateTime } from 'luxon';
import { trpc } from ':frontend/context/TrpcProvider';
import type { OrderState } from ':utils/entity/invoicing';
import { ExportIcon } from ':components/icons/basic';
import { OrderTypeFilter } from '../common/filters/OrderTypeFilter';
import { useMaster } from ':frontend/context/UserProvider';

type ExportOrdersModalProps = Readonly<{
    inputFilters: UseFiltersControl;
}>;

export function ExportOrdersModal({ inputFilters }: ExportOrdersModalProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'exportOrdersModal' });

    return (
        <Modal.Root>
            <Modal.Trigger asChild>
                <Button size='small' variant='dark'>
                    <ExportIcon />{t('trigger-button')}
                </Button>
            </Modal.Trigger>

            <Modal.Content className='max-w-3xl p-8 border-none' closeButton={t('close-button')}>
                <ExportModalInner inputFilters={inputFilters} />
            </Modal.Content>
        </Modal.Root>
    );
}

/** We do want to reset the modal state when closed, so we use a separate component for it. */
function ExportModalInner({ inputFilters }: ExportOrdersModalProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'exportOrdersModal' });

    const { teamSettings } = useMaster();
    const downloadRef = useRef<DownloadFileRef>(null);
    const [ isFetching, setIsFetching ] = useToggle(false);
    const [ documentOnly, setDocumentOnly ] = useState(false);
    const [ format, setFormat ] = useState(ExportOrdersFormat.PdfZip);
    const finalFormat = (documentOnly || !documentExportOrderFormats.includes(format)) ? format : ExportOrdersFormat.Csv;

    const { count, filtersControl, query, isFetching: isFetchingCount } = useOrderCount(documentOnly, finalFormat, inputFilters);

    async function fetchExportedInvoices() {
        const ref = downloadRef.current;
        if (!ref)
            return;

        setIsFetching.true();

        const response = await api.backend.exportOrders(query);

        if (!response.status) {
            setIsFetching.false();

            return;
        }

        ref.download(response.data);

        setIsFetching.false();
    }

    return (<>
        <Modal.Header>
            <Modal.Title className='text-2xl/8 text-secondary'>{t('title')}</Modal.Title>
        </Modal.Header>

        <FilterRow control={filtersControl} />

        {teamSettings.isInvoicingModuleOn && (<>
            <div className='mt-4 flex items-center gap-6'>
                <Form.Switch
                    checked={documentOnly}
                    onCheckedChange={setDocumentOnly}
                    label={t('documentOnly-label')}
                />
            </div>

            {documentOnly && (
                <Form.RadioGroup value={format} onValueChange={setFormat as Dispatch<SetStateAction<string>>} className='py-2'>
                    {exportFormats.map(value => (
                        <Form.RadioItem key={value} value={value} label={t(`formats.${value}.label`)} description={t(`formats.${value}.description`)} />
                    ))}
                </Form.RadioGroup>
            )}
        </>)}

        <Modal.Footer className='mt-4'>
            <DownloadFile fileName={`orders.${FILE_EXTENSIONS[finalFormat]}`} ref={downloadRef} />

            <SpinnerButton
                isFetching={isFetching || isFetchingCount}
                onClick={fetchExportedInvoices}
                // This key is used to refresh the button each time the size of the `count` string, so that its width isn't fixed on the size of the largest width.
                key={String(count ?? 0).length}
            >
                {t(documentOnly ? 'export-button-document' : 'export-button', { count })}
            </SpinnerButton>
        </Modal.Footer>
    </>);
}

// ISDOC is not supported as of Nov 2, 2024 as supporting it under Bun is harder than it should be.
// See https://github.com/oven-sh/bun/issues/4726
const exportFormats = getEnumValues(ExportOrdersFormat).filter(format => format !== ExportOrdersFormat.Isdoc);

const FILE_EXTENSIONS = {
    [ExportOrdersFormat.PdfZip]: 'zip',
    [ExportOrdersFormat.Pdf]: 'pdf',
    [ExportOrdersFormat.Csv]: 'csv',
    [ExportOrdersFormat.Isdoc]: 'zip',
} as const;

function useOrderCount(documentOnly: boolean, format: ExportOrdersFormat, inputFilters: UseFiltersControl) {
    const { clients } = useClients();
    const filters = useMemo(() => [
        OrderTypeFilter,
        OrderStateFilter,
        CreatedAtStartFilter,
        CreatedAtEndFilter,
        createOrderClientFilter(clients ?? []),
    ], [ clients ]);

    const filtersControl = useFilters(filters, inputFilters);

    const query: ExportOrdersQuery = {
        documentOnly,
        format,
        type: filtersControl.toServer(OrderTypeFilter.name) as OrderType[] | undefined,
        state: filtersControl.toServer(OrderStateFilter.name) as OrderState[] | undefined,
        client: filtersControl.toServer(createOrderClientFilter.filterName) as Id[] | undefined,
        createdAtStart: filtersControl.toServer(CreatedAtStartFilter.name) as DateTime | undefined,
        createdAtEnd: filtersControl.toServer(CreatedAtEndFilter.name) as DateTime | undefined,
    };

    const countExportOrdersQuery = trpc.order.countExportOrders.useQuery(query);

    return {
        count: countExportOrdersQuery.data?.count,
        filtersControl,
        query,
        isFetching: countExportOrdersQuery.isFetching,
    };
}
