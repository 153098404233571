import type { StiggFeature } from ':utils/lib/stigg';
import { TypedError } from './typed.error';

export class AccessDeniedError extends TypedError {
    static readonly type = 'generic.accessDenied';

    constructor() {
        super(AccessDeniedError.type, 403);
    }
}

export class NotFoundError extends TypedError {
    constructor() {
        super('generic.notFound', 404);
    }
}

export class PlanExceededError extends TypedError {
    static readonly type = 'generic.planExceeded';

    constructor(
        readonly feature: StiggFeature,
    ) {
        super(PlanExceededError.type, 403);
    }
}

export class UnselectablePlanError extends TypedError {
    static readonly type = 'generic.unselectablePlan';

    constructor() {
        super(UnselectablePlanError.type, 400);
    }
}

export class InvalidProductAvailibility extends TypedError {
    static readonly type = 'generic.invalidProductAvailibility';

    constructor() {
        super(InvalidProductAvailibility.type, 400);
    }
}
