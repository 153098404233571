import { getCommonProps, pathProps, type IconProps, type IconType } from './common';

// Whenever you need an icon that's not included here yet, copy it from /resources/all.tsx.
// Keep the icons in alphabetical order and add it to basicIcons at the end.
// In general, the icons have the same name as in Ligma. However, the number might not be correct (e.g., Box1Icon and Box2Icon might have been swapped).
// The reason is that the numbers were reassigned (because there were icons like XYZ-3 but no XYZ-1 or XYZ-2).

export const AccessibilityIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 3.75a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M7.25 16.5V6.24M10.75 6.24V16.5M2.75 5.75a37 37 0 0 0 12.5 0M7.25 11.25h3.5' />
</svg>);

export const ArrowDiagonalIn1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M10.25 2.75h3a2 2 0 0 1 2 2v8.5a2 2 0 0 1-2 2h-8.5a2 2 0 0 1-2-2v-3' />
    <path {...pathProps.stroke} d='M7.25 2.75v4.5h-4.5M7.25 7.25l-5.5-5.5' />
</svg>);

export const ArrowLeftIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M2.75 9h12.5M7 13.25 2.75 9 7 4.75' />
</svg>);

export const ArrowRightIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M15.25 9H2.75M11 4.75 15.25 9 11 13.25' />
</svg>);

export const ArrowRightIconScaling = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.strokeScaling} d='M15.25 9H2.75M11 4.75 15.25 9 11 13.25' />
</svg>);

export const ArrowsExpandDiagonal6Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M10.75 2.75h4.5v4.5M15.25 2.75l-4.5 4.5M2.75 10.75v4.5h4.5M2.75 15.25l4.5-4.5' />
</svg>);

export const ArrowsReduceDiagonal1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M15.25 7.25h-4.5v-4.5M10.75 7.25l4.5-4.5M7.25 15.25v-4.5h-4.5M7.25 10.75l-4.5 4.5' />
</svg>);

export const AscendingSortingIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m15.25 8.75-2.5-2.5-2.5 2.5M12.75 6.25v9M2.75 9.75h5M2.75 6.25h5M2.75 2.75h10' />
</svg>);

export const AutomatedLogistics1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M10.75 2.75h-3.5a1 1 0 0 0-1 1v3.5a1 1 0 0 0 1 1h3.5a1 1 0 0 0 1-1v-3.5a1 1 0 0 0-1-1M1.75 2.75h1a1 1 0 0 1 1 1v3.5a1 1 0 0 1-1 1h-1M16.25 2.75h-1a1 1 0 0 0-1 1v3.5a1 1 0 0 0 1 1h1M9 2.75V5M14.25 11.25H3.75a2 2 0 1 0 0 4h10.5a2 2 0 1 0 0-4' />
    <path {...pathProps.fill} d='M9 14a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M5.75 14a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M12.25 14a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5' />
</svg>);

/** Not from the All icons file. */
export const BankIcon = (props: IconProps) => (<svg {...getCommonProps(props, 18)}>
    <path {...pathProps.stroke} d='M2.25 16.5h13.5m-11.25-3V8.25m3 5.25V8.25m3 5.25V8.25m3 5.25V8.25M9 1.5l6 3.75H3z' />
</svg>);

export const BankingMobileIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M12.25 1.75h-6.5a2 2 0 0 0-2 2v10.5a2 2 0 0 0 2 2h6.5a2 2 0 0 0 2-2V3.75a2 2 0 0 0-2-2' />
    <path {...pathProps.stroke} d='M10.817 6.95c-.394-.932-1.183-1.143-1.779-1.143-.554 0-2.01.295-1.875 1.692.094.98 1.019 1.346 1.827 1.49s1.981.452 2.01 1.635c.024 1-.875 1.683-1.962 1.683-1.038 0-1.76-.404-2.038-1.317M9 5.048v.645M9 12.307v.645' />
</svg>);

export const BankStatementIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M15.25 14.25V3.75a2 2 0 0 0-2-2h-8.5a2 2 0 0 0-2 2v10.5a2 2 0 0 0 2 2h8.5a2 2 0 0 0 2-2M5.75 10.25h6.5M5.75 13.25h6.5' />
    <path {...pathProps.stroke} d='m6.071 5.127 2.75-1.058a.5.5 0 0 1 .359 0l2.75 1.058a.5.5 0 0 1 .321.467v.657a.5.5 0 0 1-.5.5H6.25a.5.5 0 0 1-.5-.5v-.657a.5.5 0 0 1 .321-.467M9 6.75v3.5M6.75 6.75v3.5M11.25 6.75v3.5' />
</svg>);

export const BoxPlusIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 2.25v6M3 6.284l1.449-2.922A2 2 0 0 1 6.241 2.25h5.518a2 2 0 0 1 1.792 1.112L15 6.285M14.75 12.25v5M17.25 14.75h-5' />
    <path {...pathProps.stroke} d='M15.25 9.801V7.25a2 2 0 0 0-2-2h-8.5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h5.051' />
</svg>);

export const Box2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 2.25v6M13.25 5.25h-8.5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h8.5a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2' />
    <path {...pathProps.stroke} d='m3 6.284 1.449-2.922A2 2 0 0 1 6.241 2.25h5.518a2 2 0 0 1 1.792 1.112L15 6.285M5.25 12.75h2' />
</svg>);

export const Box2PlusIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.25 9.25V6.083l6.75-3' />
    <path {...pathProps.stroke} d='M9.406 1.931 15.75 4.75 9.406 7.569a1 1 0 0 1-.812 0L2.25 4.75l6.344-2.819a1 1 0 0 1 .812 0M9 7.656v8.413M14.75 10.75v5M17.25 13.25h-5M2.25 4.75v7.85c0 .395.233.753.594.914l5.75 2.556a1 1 0 0 0 .812 0l1.552-.69M15.75 8.461V4.75' />
</svg>);

export const BoxHeartIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 2.25v6M3 6.284l1.449-2.922A2 2 0 0 1 6.241 2.25h5.518a2 2 0 0 1 1.792 1.112L15 6.285' />
    <path {...pathProps.stroke} d='M15.25 9.019V7.25a2 2 0 0 0-2-2h-8.5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h3.881' />
    <path {...pathProps.stroke} d='M13.782 17.197a.48.48 0 0 0 .437 0c.729-.374 3.031-1.73 3.031-3.934.004-.968-.791-1.757-1.777-1.763a1.8 1.8 0 0 0-1.473.786 1.81 1.81 0 0 0-1.473-.786c-.985.006-1.78.794-1.777 1.763 0 2.205 2.303 3.56 3.031 3.934z' />
</svg>);

export const BoxMinusIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 2.25v6M3 6.284l1.449-2.922A2 2 0 0 1 6.241 2.25h5.518a2 2 0 0 1 1.792 1.112L15 6.285M17.25 14.75h-5' />
    <path {...pathProps.stroke} d='M15.25 12.25v-5a2 2 0 0 0-2-2h-8.5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h5.051' />
</svg>);

export const BugIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 15.25v-4.5M6.75 5.75V4.5a2.25 2.25 0 1 1 4.5 0v1.25M4.75 9.75h-3M4.75 7.25A2.75 2.75 0 0 1 2 4.5M4.75 12.25A2.75 2.75 0 0 0 2 15M13.25 9.75h3M13.25 7.25A2.75 2.75 0 0 0 16 4.5M13.25 12.25A2.75 2.75 0 0 1 16 15' />
    <path {...pathProps.stroke} d='M6.75 5.75h4.5a2 2 0 0 1 2 2V11A4.25 4.25 0 0 1 9 15.25 4.25 4.25 0 0 1 4.75 11V7.75a2 2 0 0 1 2-2' />
</svg>);

export const BugSlashIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M6.75 5.75V4.5a2.25 2.25 0 1 1 4.5 0v1.25M9 15.25v-2.5' />
    <path {...pathProps.stroke} d='M5.169 12.843A4.2 4.2 0 0 1 4.75 11V7.75a2 2 0 0 1 2-2h4.5c.293 0 .572.063.823.177M13.25 8.25V11a4.25 4.25 0 0 1-6.39 3.672M4.75 9.75h-3' />
    <path {...pathProps.stroke} d='M4.75 7.25A2.75 2.75 0 0 1 2 4.5M13.25 9.75h3M13.25 12.25A2.75 2.75 0 0 1 16 15M2 16 16 2' />
</svg>);

export const ButtonIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M16.25 8.917V5.75a2 2 0 0 0-2-2H3.75a2 2 0 0 0-2 2v3.5a2 2 0 0 0 2 2h3.681' />
    <path {...pathProps.stroke} d='m9.934 9.27 6.854 2.504a.323.323 0 0 1-.012.611L13.64 13.39l-1.004 3.137a.323.323 0 0 1-.61.012L9.52 9.684a.323.323 0 0 1 .414-.414' />
</svg>);

export const CalendarCheck2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 2.75v-2M12.25 2.75v-2M2.25 6.25h13.5M15.75 9.621V4.75a2 2 0 0 0-2-2h-9.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h4.766M11.492 15l1.61 1.5 3.396-4.5' />
</svg>);

export const CalendarCheckInIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 2.75v-2M12.25 2.75v-2M2.25 6.25h13.5M15.75 10.267V4.75a2 2 0 0 0-2-2h-9.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h4.714M13.75 16.75l-2.5-2.5 2.5-2.5M11.25 14.25h5' />
</svg>);

export const CalendarCheckOutIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 2.75v-2M12.25 2.75v-2M2.25 6.25h13.5M13.75 11.75l2.5 2.5-2.5 2.5M15.75 10.215V4.75a2 2 0 0 0-2-2h-9.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h4.711M16.25 14.25h-5' />
</svg>);

export const CalendarEventIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 2.75v-2M12.25 2.75v-2M13.75 2.75h-9.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h9.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2M2.25 6.25h13.5' />
    <path {...pathProps.strokeFill} d='M11.25 11.75a1 1 0 1 0 0-2 1 1 0 0 0 0 2' />
</svg>);

export const CalendarIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 2.75v-2M12.25 2.75v-2M13.75 2.75h-9.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h9.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2M2.25 6.25h13.5' />
</svg>);

export const CalendarPhoneIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 2.75v-2M12.25 2.75v-2M2.25 6.25h13.5M15.75 11.446V4.75a2 2 0 0 0-2-2h-9.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h4.636' />
    <path {...pathProps.fill} d='m10.418 12.905 1.604-1.382a.5.5 0 0 0 .145-.593l-.36-.882a.5.5 0 0 0-.582-.281l-1.111.365a.505.505 0 0 0-.351.502 6 6 0 0 0 5.605 5.605.504.504 0 0 0 .502-.351l.365-1.111a.5.5 0 0 0-.281-.583l-.882-.36a.5.5 0 0 0-.593.146l-1.382 1.604' />
    <path {...pathProps.stroke} d='m10.418 12.905 1.604-1.382a.5.5 0 0 0 .145-.593l-.36-.882a.5.5 0 0 0-.582-.281l-1.111.365a.505.505 0 0 0-.351.502 6 6 0 0 0 5.605 5.605.504.504 0 0 0 .502-.351l.365-1.111a.5.5 0 0 0-.281-.583l-.882-.36a.5.5 0 0 0-.593.146l-1.382 1.604' />
</svg>);

export const CalendarPlus1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M13.75 2.75h-9.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h9.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2M5.75 2.75v-2M12.25 2.75v-2M2.25 6.25h13.5M9 12.75v-4M7 10.75h4' />
</svg>);

export const CalendarPlus2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 2.75v-2M12.25 2.75v-2M2.25 6.25h13.5M14.75 10.25v5M15.75 7.961V4.75a2 2 0 0 0-2-2h-9.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2H12M17.25 12.75h-5' />
</svg>);

export const CartShoppingIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m1.75 1.75 1.351.338a1 1 0 0 1 .747.825l1.153 7.838' />
    <path {...pathProps.stroke} d='M15.25 13.25H4.5a1.25 1.25 0 0 1 0-2.5h8.529a1 1 0 0 0 .949-.684l1.333-4a1 1 0 0 0-.949-1.316H4.118' />
    <path {...pathProps.fill} d='M3.75 17a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M14.25 17a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5' />
</svg>);

export const ChartCircular2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 1.75A7.25 7.25 0 1 1 2.952 5' />
    <path {...pathProps.stroke} d='M9 4.75A4.25 4.25 0 1 1 4.75 9' />
    <path {...pathProps.stroke} d='M9 7.75a1.25 1.25 0 0 1 0 2.5' />
</svg>);

export const ChartColumnIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M13.25 3.75v8M6.25 6.75v5M9.75 9.75v2M2.75 2.75v10a2 2 0 0 0 2 2h10.5' />
</svg>);

export const ChartComboIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 12.25v3M14.75 7.75v7.5M9 9.25a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M14.75 4.75a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M3.25 9.25v6M3.25 6.25a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M4.566 5.472l3.06 1.68M10.18 6.826l3.389-2.652' />
</svg>);

export const ChartDonut1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 5.25v-3.5M12.75 9h3.5M6.348 11.652l-2.473 2.473M9 16.25a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5' />
    <path {...pathProps.stroke} d='M9 12.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5' />
</svg>);

export const ChartStackedAreaIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m1.75 11.25 2.954-2.045a.5.5 0 0 1 .585.011l3.484 2.613c.141.106.328.13.491.062L16.251 9' />
    <path {...pathProps.stroke} d='M14.25 14.25H3.75a2 2 0 0 1-2-2V8.458c0-.133.053-.26.146-.354l2.809-2.809a.5.5 0 0 1 .637-.058l3.374 2.32a.5.5 0 0 0 .567 0l6.183-4.265a.5.5 0 0 1 .784.412v8.548a2 2 0 0 1-2 2' />
</svg>);

export const Check1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m2.75 9.25 4 5 8.5-10.5' />
</svg>);

export const CheckboxCheckedIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 9.25 8 11.75l4.25-5.5' />
    <path {...pathProps.stroke} d='M13.25 2.75h-8.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h8.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2' />
</svg>);

export const ChevronDownIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M15.25 6.5 9 12.75 2.75 6.5' />
</svg>);

export const ChevronLeftIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M11.5 15.25 5.25 9l6.25-6.25' />
</svg>);

export const ChevronRightIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M6.5 2.75 12.75 9 6.5 15.25' />
</svg>);

export const ChevronUpIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M2.75 11.5 9 5.25l6.25 6.25' />
</svg>);

export const CircleCheck1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 16.25a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5' />
    <path {...pathProps.stroke} d='M5.75 9.25 8 11.75l4.25-5.5' />
</svg>);

export const CircleCopy1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M10.75 16.25a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11' />
    <path {...pathProps.stroke} d='M3 10.741A5.5 5.5 0 0 1 10.741 3' />
</svg>);

export const CircleDollarIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 16.25a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5' />
    <path {...pathProps.stroke} d='M10.817 6.95c-.394-.932-1.183-1.143-1.779-1.143-.554 0-2.01.295-1.875 1.692.094.98 1.019 1.346 1.827 1.49s1.981.452 2.01 1.635c.024 1-.875 1.683-1.962 1.683-1.038 0-1.76-.404-2.038-1.317M9 4.75v1.057M9 12.307v.943' />
</svg>);

export const CircleDotsIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 16.25a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5' />
    <path {...pathProps.fill} d='M9 10c-.551 0-1-.449-1-1s.449-1 1-1 1 .449 1 1-.449 1-1 1M5.5 10c-.551 0-1-.449-1-1s.449-1 1-1 1 .449 1 1-.449 1-1 1M12.5 10c-.551 0-1-.449-1-1s.449-1 1-1 1 .449 1 1-.449 1-1 1' />
</svg>);

export const CircleDottedUserIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 9.75a2 2 0 1 0 0-4 2 2 0 0 0 0 4M8.999 16.25a7.2 7.2 0 0 0 3.827-1.101c-.481-1.67-2.003-2.899-3.827-2.899s-3.347 1.229-3.827 2.899a7.2 7.2 0 0 0 3.827 1.101' />
    <path {...pathProps.fill} d='M1.89 11.164a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M2.973 5.722a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M2.973 13.777a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M1.89 8.336a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M4.973 3.722a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M7.586 2.639a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M15.027 13.778a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M16.11 8.336a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M13.027 3.72a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M16.11 11.164a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M15.027 5.723a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M10.414 2.64a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5' />
</svg>);

export const CircleHalfDottedClockIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 4.75V9l3.25 2.25' />
    <path {...pathProps.stroke} d='M9 1.75a7.25 7.25 0 0 1 0 14.5' />
    <path {...pathProps.fill} d='M3.871 14.877a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M1.75 9.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M3.871 4.623a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M6.227 16.448a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M2.3 12.524a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M2.3 6.976a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M6.227 3.052a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5' />
</svg>);

export const CircleInfoIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 16.25a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5M9 12.819V8.25' />
    <path {...pathProps.fill} d='M9 6.75a1.001 1.001 0 0 1 0-2 1.001 1.001 0 0 1 0 2' />
</svg>);

export const CircleMinusIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 16.25a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5M5.75 9h6.5' />
</svg>);

export const CirclePercentageIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 16.25a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5' />
    <path {...pathProps.fill} d='M6.75 8a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M11.25 12.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5' />
    <path {...pathProps.stroke} d='m6.25 11.75 5.5-5.5' />
</svg>);

export const CirclePlusIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 16.25a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5M5.75 9h6.5M9 5.75v6.5' />
</svg>);

export const CircleUserIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 9.75a2 2 0 1 0 0-4 2 2 0 0 0 0 4M5.152 15.147a4.001 4.001 0 0 1 7.692 0' />
    <path {...pathProps.stroke} d='M9 16.25a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5' />
</svg>);

export const Clock1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 16.25a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5' />
    <path {...pathProps.stroke} d='M9 4.75V9l3.25 2.25' />
</svg>);

export const ClockRotateClockwise1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 4.75V9l3.25 2.25' />
    <path {...pathProps.stroke} d='M16.25 9a7.25 7.25 0 1 1-.54-2.75' />
    <path {...pathProps.stroke} d='m16.122 3.305-.409 2.945-2.943-.407' />
</svg>);

export const Clone1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M7.25 16.25h7a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2h-7a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2' />
    <path {...pathProps.stroke} d='M3 12.605a2 2 0 0 1-1.25-1.855v-7a2 2 0 0 1 2-2h7c.839 0 1.558.517 1.855 1.25' />
</svg>);

export const CoinsIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9.5 5.75c3.452 0 6.25-.895 6.25-2s-2.798-2-6.25-2-6.25.895-6.25 2 2.798 2 6.25 2' />
    <path {...pathProps.stroke} d='M3.25 3.75v3c0 1.104 2.798 2 6.25 2s6.25-.896 6.25-2v-3M9.5 5.75v3M6.25 5.458v3M12.75 5.458v3M2.25 11.25v3c0 1.104 2.798 2 6.25 2s6.25-.896 6.25-2v-3M8.5 13.25v3M5.25 12.958v3M11.75 12.958v3' />
    <path {...pathProps.stroke} d='M3.311 10.135c-.67.319-1.061.702-1.061 1.115 0 1.104 2.798 2 6.25 2s6.25-.896 6.25-2c0-.223-.119-.438-.33-.638' />
</svg>);

export const Compose1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M15.25 8.75v4.5a2 2 0 0 1-2 2h-8.5a2 2 0 0 1-2-2v-8.5a2 2 0 0 1 2-2h4.5' />
    <path {...pathProps.stroke} d='M6.75 11.25s2.12-.12 2.836-.836l6.25-6.25a1.415 1.415 0 0 0-2-2l-6.25 6.25c-.716.716-.836 2.836-.836 2.836' />
</svg>);

export const Copies6Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M2.75 8v4.917a1.75 1.75 0 0 0 1.75 1.75h9a1.75 1.75 0 0 0 1.75-1.75V8a1.75 1.75 0 0 0-1.75-1.75h-9A1.75 1.75 0 0 0 2.75 8m9.917-5.25H5.333' />
</svg>);

export const Copy1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M4.75 12.25h-1a2 2 0 0 1-2-2v-5.5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 2 2v1' />
    <path {...pathProps.stroke} d='M6.75 15.25h7.5a2 2 0 0 0 2-2v-5.5a2 2 0 0 0-2-2h-7.5a2 2 0 0 0-2 2v5.5a2 2 0 0 0 2 2' />
</svg>);

export const CreditCardIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M1.75 7.25h14.5M3.75 14.25h10.5a2 2 0 0 0 2-2v-6.5a2 2 0 0 0-2-2H3.75a2 2 0 0 0-2 2v6.5a2 2 0 0 0 2 2M4.25 11.25h3M12.75 11.25h1' />
</svg>);

export const CurrencyExchangeIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m16.12 14.695-.408-2.945h-.002A7.25 7.25 0 0 1 1.75 9M1.879 3.305l.408 2.945h.002A7.252 7.252 0 0 1 16.249 9' />
    <path {...pathProps.stroke} d='M9 9.75c2.071 0 3.75-.895 3.75-2s-1.679-2-3.75-2-3.75.895-3.75 2 1.679 2 3.75 2' />
    <path {...pathProps.stroke} d='M5.25 7.75v2.5c0 1.104 1.679 2 3.75 2s3.75-.896 3.75-2v-2.5' />
</svg>);


export const DescendingSortingIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m10.25 12.75 2.5 2.5 2.5-2.5M12.75 15.25v-9M2.75 9.75h6.5M2.75 6.25h6.5M2.75 2.75h10' />
</svg>);

export const DiscountIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m15.718 8.293-1.469-1.468V4.75a1 1 0 0 0-1-1h-2.075L9.708 2.282a1 1 0 0 0-1.414 0L6.825 3.75H4.75a1 1 0 0 0-1 1v2.075L2.282 8.293a1 1 0 0 0 0 1.414l1.468 1.468v2.075a1 1 0 0 0 1 1h2.075l1.468 1.468a1 1 0 0 0 1.414 0l1.467-1.468h2.076a1 1 0 0 0 1-1v-2.075l1.467-1.468a1 1 0 0 0 0-1.414' />
    <path {...pathProps.fill} d='M7 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2M11 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2' />
    <path {...pathProps.stroke} d='m6.75 11.25 4.5-4.5' />
</svg>);

export const DoorOpenIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9.25 16V2a.5.5 0 0 0-.692-.462L3.865 3.494a1 1 0 0 0-.615.923v9.166a1 1 0 0 0 .615.923l4.692 1.955a.5.5 0 0 0 .692-.462zM11.75 3.25h2a1 1 0 0 1 1 1v9.5a1 1 0 0 1-1 1h-2M6.75 8.5v1' />
</svg>);

export const DotsIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.strokeFill} d='M9 9.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1M3.25 9.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1M14.75 9.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1' />
</svg>);

export const DotsVerticalIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.strokeFill} d='M9 9.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1M9 3.75a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1M9 15.25a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1' />
</svg>);

export const Duplicate1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M3.75 12.75h7a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2h-7a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2' />
    <path {...pathProps.stroke} d='m15.2 6.002 1.029 6.924a2 2 0 0 1-1.684 2.272L7.62 16.227a2 2 0 0 1-2.148-1.228' />
</svg>);

export const EnvelopeIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m1.75 5.75 6.767 3.733a1 1 0 0 0 .966 0L16.25 5.75' />
    <path {...pathProps.stroke} d='M3.75 14.75h10.5a2 2 0 0 0 2-2v-7.5a2 2 0 0 0-2-2H3.75a2 2 0 0 0-2 2v7.5a2 2 0 0 0 2 2' />
</svg>);

export const ExportIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M11.75 7.25h1a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-7.5a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h1M6 4.25l3-3 3 3M9 1.25V12' />
</svg>);

export const Eye1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M2.088 10.132a2.07 2.07 0 0 1 0-2.265C3.106 6.324 5.35 3.749 8.999 3.749c3.65 0 5.896 2.574 6.913 4.118.45.683.45 1.582 0 2.265-1.019 1.543-3.263 4.118-6.913 4.118s-5.895-2.574-6.911-4.118' />
    <path {...pathProps.stroke} d='M9 11.75a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5' />
</svg>);

export const Eye2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 11.75a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5' />
    <path {...pathProps.stroke} d='M1.75 9S3.521 3.5 9 3.5 16.25 9 16.25 9' />
</svg>);

export const Eye2SlashIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M11.58 9.956a2.76 2.76 0 0 1-1.623 1.623M7.055 10.945a2.75 2.75 0 1 1 3.89-3.89' />
    <path {...pathProps.stroke} d='M1.75 9S3.521 3.5 9 3.5 16.25 9 16.25 9M2 16 16 2' />
</svg>);

export const FacePlusIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M11.25 11.758a2.66 2.66 0 0 1-4.5 0' />
    <path {...pathProps.fill} d='M6 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2' />
    <path {...pathProps.stroke} d='M14.75 1.25v5M17.25 3.75h-5M10.557 1.922A7.3 7.3 0 0 0 9 1.75a7.25 7.25 0 1 0 7.213 6.518' />
</svg>);

export const FileDownloadIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 6.75h2M5.75 9.75h4.5M15.16 6.25h-3.41a1 1 0 0 1-1-1V1.852M17.25 14.75l-2.5 2.5-2.5-2.5' />
    <path {...pathProps.stroke} d='M15.25 9.8V6.664a1 1 0 0 0-.293-.707l-3.914-3.914a1 1 0 0 0-.707-.293H4.75a2 2 0 0 0-2 2v10.5a2 2 0 0 0 2 2h5.514M14.75 17v-4.75' />
</svg>);

export const FilePlusIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 6.75h2M5.75 9.75h4.5M15.16 6.25h-3.41a1 1 0 0 1-1-1V1.852M14.75 10.75v5' />
    <path {...pathProps.stroke} d='M15.25 8.3V6.664a1 1 0 0 0-.293-.707l-3.914-3.914a1 1 0 0 0-.707-.293H4.75a2 2 0 0 0-2 2v10.5a2 2 0 0 0 2 2h7.55M17.25 13.25h-5' />
</svg>);

export const FileSendIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 6.75h2M5.75 9.75h4.5M15.16 6.25h-3.41a1 1 0 0 1-1-1V1.852M14.75 11.25l2.5 2.5-2.5 2.5' />
    <path {...pathProps.stroke} d='M15.25 8.802V6.664a1 1 0 0 0-.293-.707l-3.914-3.914a1 1 0 0 0-.707-.293H4.75a2 2 0 0 0-2 2v10.5a2 2 0 0 0 2 2h7.5M17 13.75h-4.75' />
</svg>);

export const FileUserIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 6.75h2M5.75 9.75h4.5M15.16 6.25h-3.41a1 1 0 0 1-1-1V1.852' />
    <path {...pathProps.strokeFill} d='M14.64 12.75a1 1 0 1 0 0-2 1 1 0 0 0 0 2' />
    <path {...pathProps.stroke} d='M15.25 8.312V6.665a1 1 0 0 0-.293-.707l-3.914-3.914a1 1 0 0 0-.707-.293H4.75a2 2 0 0 0-2 2V14.25a2 2 0 0 0 2 2h4.843M16.742 17.25c.34 0 .594-.337.482-.658a2.733 2.733 0 0 0-5.166 0c-.112.321.142.658.482.658z' />
</svg>);

export const FillIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M14.375 16.75c1.036 0 1.875-.852 1.875-1.903 0-1.445-1.051-2.063-1.875-3.097-.824 1.034-1.875 1.652-1.875 3.097 0 1.051.839 1.903 1.875 1.903M1.82 8.75h11.851M7.023.749l6.867 8.168-5.886 4.946a2 2 0 0 1-2.835-.265l-2.967-3.595a2 2 0 0 1 .283-2.814l5.89-4.814' />
</svg>);

export const FindReplaceIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m4.25 13.5-1.5-1.75-1.5 1.75' />
    <path {...pathProps.stroke} d='M6.25 16.25h-1a2.5 2.5 0 0 1-2.5-2.5V12M13.75 4.5l1.5 1.75 1.5-1.75' />
    <path {...pathProps.stroke} d='M11.75 1.75h1a2.5 2.5 0 0 1 2.5 2.5V6M9.694 6.306c.026-.183.056-.366.056-.556a4 4 0 1 0-4 4c.191 0 .373-.03.556-.056M14.75 8.75h-4.5a1.5 1.5 0 0 0-1.5 1.5v4.5a1.5 1.5 0 0 0 1.5 1.5h4.5a1.5 1.5 0 0 0 1.5-1.5v-4.5a1.5 1.5 0 0 0-1.5-1.5' />
</svg>);

export const Gear1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 11.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5' />
    <path {...pathProps.stroke} d='M16.248 9.355v-.71a1 1 0 0 0-.89-.994l-1.094-.122-.503-1.214.688-.859a1 1 0 0 0-.074-1.332l-.502-.502a1 1 0 0 0-1.332-.074l-.859.688-1.214-.503-.122-1.094a1 1 0 0 0-.994-.89h-.71a1 1 0 0 0-.994.89l-.122 1.094-1.214.503-.859-.687a1 1 0 0 0-1.332.074l-.502.502a1 1 0 0 0-.074 1.332l.688.859L3.73 7.53l-1.094.122a1 1 0 0 0-.89.994v.71a1 1 0 0 0 .89.994l1.094.122.503 1.214-.687.859a1 1 0 0 0 .074 1.332l.502.502a1 1 0 0 0 1.332.074l.86-.688 1.213.503.122 1.094a1 1 0 0 0 .994.89h.71a1 1 0 0 0 .994-.89l.122-1.094 1.214-.503.86.688a1 1 0 0 0 1.331-.074l.502-.502a1 1 0 0 0 .074-1.332l-.687-.859.503-1.214 1.094-.122a1 1 0 0 0 .89-.994z' />
</svg>);

export const Globe2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 16.25c1.657 0 3-3.246 3-7.25s-1.343-7.25-3-7.25S6 4.996 6 9s1.343 7.25 3 7.25M2.105 6.75h13.789M2.29 11.75h13.42' />
    <path {...pathProps.stroke} d='M9 16.25a7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0 0 14.5' />
</svg>);

export const GoalFlagIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M14.75 6.25v5.5h-5a1 1 0 0 0-1 1v2h-5a1 1 0 0 0-1 1V17' />
    <path {...pathProps.stroke} d='m6.75 7.25 3-2.75-3-2.75h7a1 1 0 0 1 1 1v3.5a1 1 0 0 1-1 1z' />
</svg>);

export const GradientIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.fill} d='M6 6V3.75H2.75a1 1 0 0 0-1 1v2.5a1 1 0 0 0 1 1H8V6z' />
    <path {...pathProps.stroke} d='m.75 12.75 1.75-2 1.75 2v1.5a1 1 0 0 1-1 1h-1.5a1 1 0 0 1-1-1zM13.75 12.75l1.75-2 1.75 2v1.5a1 1 0 0 1-1 1h-1.5a1 1 0 0 1-1-1z' />
    <path {...pathProps.fill} d='M10 3.75H8V6h2zM12 6h-2v2.25h2z' />
    <path {...pathProps.stroke} d='M15.25 3.75H2.75a1 1 0 0 0-1 1v2.5a1 1 0 0 0 1 1h12.5a1 1 0 0 0 1-1v-2.5a1 1 0 0 0-1-1' />
</svg>);

export const GridPlus2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M6.25 2.75h-2.5a1 1 0 0 0-1 1v2.5a1 1 0 0 0 1 1h2.5a1 1 0 0 0 1-1v-2.5a1 1 0 0 0-1-1M14.25 2.75h-2.5a1 1 0 0 0-1 1v2.5a1 1 0 0 0 1 1h2.5a1 1 0 0 0 1-1v-2.5a1 1 0 0 0-1-1M6.25 10.75h-2.5a1 1 0 0 0-1 1v2.5a1 1 0 0 0 1 1h2.5a1 1 0 0 0 1-1v-2.5a1 1 0 0 0-1-1M13 10.25v5M15.5 12.75h-5' />
</svg>);

export const ImageSparkle3Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m4.445 15.227 5.64-5.641a2 2 0 0 1 2.828 0l2.336 2.336' />
    <path {...pathProps.stroke} d='M13.25 2.75h-8.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h8.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2' />
    <path {...pathProps.fill} d='m9.159 6.508-1.263-.421-.421-1.263c-.137-.408-.812-.408-.949 0l-.421 1.263-1.263.42a.5.5 0 0 0 0 .949l1.263.42.421 1.264a.501.501 0 0 0 .95 0l.421-1.263 1.263-.421a.5.5 0 0 0 0-.948z' />
</svg>);

export const Images2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m4 15.25 5.836-5.836a2 2 0 0 1 2.828 0L15.75 12.5' />
    <path {...pathProps.stroke} d='M13.75 4.75h-9.5a2 2 0 0 0-2 2v6.5a2 2 0 0 0 2 2h9.5a2 2 0 0 0 2-2v-6.5a2 2 0 0 0-2-2M4.75 1.75h8.5' />
    <path {...pathProps.fill} d='M5.75 9.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5' />
</svg>);

export const ImportIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M11.75 6.75h1a2 2 0 0 1 2 2v5.5a2 2 0 0 1-2 2h-7.5a2 2 0 0 1-2-2v-5.5a2 2 0 0 1 2-2h1' />
    <path {...pathProps.stroke} d='m12 10.25-3 3-3-3M9 13.25V1.75' />
</svg>);

export const InputPasswordEditIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.fill} d='M5.5 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M9 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12.5 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2' />
    <path {...pathProps.stroke} d='M16.25 7.274V6.75a2 2 0 0 0-2-2H3.75a2 2 0 0 0-2 2v4.5a2 2 0 0 0 2 2h5.391M13.207 15.401a1 1 0 0 0 .38-.238l3.303-3.303a1.23 1.23 0 0 0-.005-1.745 1.23 1.23 0 0 0-1.745-.005l-3.303 3.303a1 1 0 0 0-.238.38l-.849 2.457z' />
</svg>);

export const InputPasswordPointerIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.fill} d='M5 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M8.5 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2' />
    <path {...pathProps.stroke} d='M15.75 9.795V6.75a2 2 0 0 0-2-2H3.25a2 2 0 0 0-2 2v4.5a2 2 0 0 0 2 2h5.632' />
    <path {...pathProps.stroke} d='m11.127 10.768 5.94 2.17a.28.28 0 0 1-.011.53l-2.72.87-.87 2.718a.28.28 0 0 1-.528.011l-2.17-5.94a.28.28 0 0 1 .359-.359' />
</svg>);

export const InvoiceDollarIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M14.75 3.75v12.5L12 14.75l-3 1.5-3-1.5-2.75 1.5V3.75a2 2 0 0 1 2-2h7.5a2 2 0 0 1 2 2' />
    <path {...pathProps.stroke} d='M10.723 6.556C10.349 5.67 9.6 5.47 9.035 5.47c-.526 0-1.907.28-1.78 1.606.09.93.968 1.277 1.735 1.414s1.88.429 1.907 1.55c.023.95-.83 1.598-1.861 1.598-.985 0-1.67-.383-1.934-1.25M9 4.75v.72M9 11.638v.612' />
</svg>);

export const InvoiceIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M14.75 3.75v12.5L12 14.75l-3 1.5-3-1.5-2.75 1.5V3.75a2 2 0 0 1 2-2h7.5a2 2 0 0 1 2 2M5.75 11.25h3.5M11.75 11.25h.5' />
    <path {...pathProps.stroke} d='M9 8.25a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5' />
</svg>);

export const Label1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m4.408 5.905 3.921-3.547a1 1 0 0 1 1.342 0l3.921 3.547a2 2 0 0 1 .658 1.483v6.862a2 2 0 0 1-2 2h-6.5a2 2 0 0 1-2-2V7.388a2 2 0 0 1 .658-1.483M6.75 13.25h4.5' />
    <path {...pathProps.fill} d='M9 8a1.25 1.25 0 1 0 0-2.5A1.25 1.25 0 0 0 9 8' />
</svg>);

export const LanguageIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M2.25 4.25h8M6.25 2.25v2M4.25 4.25a6.11 6.11 0 0 0 4.5 5.684' />
    <path {...pathProps.stroke} d='M8.25 4.25c-.4 5.625-6 6-6 6M9.25 15.75l3-8h.5l3 8M10.188 13.25h4.624' />
</svg>);

export const LicenseIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 7.75h3.5M5.75 5.25h6.5M13.25 1.75h-8.5a2 2 0 0 0-2 2v10.5a2 2 0 0 0 2 2h8.5a2 2 0 0 0 2-2V3.75a2 2 0 0 0-2-2' />
    <path {...pathProps.stroke} d='M11.25 13.75a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3' />
</svg>);

export const Link5Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M8.5 6.827a3.5 3.5 0 0 0-.974.69l-.01.01a3.535 3.535 0 0 0 0 5l2.175 2.175a3.535 3.535 0 0 0 5 0l.01-.01a3.535 3.535 0 0 0 0-5l-.93-.93' />
    <path {...pathProps.stroke} d='M9.5 11.173c.351-.168.681-.398.973-.69l.01-.01a3.535 3.535 0 0 0 0-5L8.307 3.298a3.535 3.535 0 0 0-5 0l-.01.01a3.535 3.535 0 0 0 0 5l.931.931' />
</svg>);

export const ListCheckbox1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m2.25 13.391 1.359 1.359 3.397-4.417M2.25 5.891 3.609 7.25l3.397-4.417M9.75 5.25h6.5M9.75 12.75h6.5' />
</svg>);

export const LockIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 8.25V5a3.25 3.25 0 1 1 6.5 0v3.25M9 11.75v1' />
    <path {...pathProps.stroke} d='M12.75 8.25h-7.5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h7.5a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2' />
</svg>);

export const LockOpen2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 8.25V5a3.25 3.25 0 1 1 6.5 0M9 11.75v1' />
    <path {...pathProps.stroke} d='M12.75 8.25h-7.5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h7.5a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2' />
</svg>);

export const MediaPlayIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m5.245 2.878 9.492 5.256a.987.987 0 0 1 0 1.732l-9.492 5.256c-.669.371-1.495-.108-1.495-.866V3.744c0-.758.825-1.237 1.495-.866' />
</svg>);

export const MoneyBillCoinIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M8.033 8.751A2 2 0 0 1 9 5c.345 0 .67.087.953.241M4.75 1.75a3 3 0 0 1-3 3M13.25 1.75a3 3 0 0 0 3 3M4.75 12.25a3 3 0 0 0-3-3' />
    <path {...pathProps.stroke} d='M7.25 12.25h-3.5a2 2 0 0 1-2-2v-6.5a2 2 0 0 1 2-2h10.5a2 2 0 0 1 2 2v1M15.25 10.25h-4.5a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h4.5a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1' />
    <path {...pathProps.stroke} d='M16.25 7.25h-4.5a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h4.5a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1M16.25 13.25h-4.5a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h4.5a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1' />
</svg>);

export const MoneyBillsDollarIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M10.266 8.22c-.321-.519-.835-.644-1.235-.644-.421 0-1.526.224-1.423 1.285.072.745.774 1.022 1.387 1.131.613.11 1.504.343 1.526 1.241.019.76-.664 1.277-1.49 1.277-.683 0-1.185-.23-1.445-.744M9 7v.576M9 12.51V13M3.25 1.75h11.5' />
    <path {...pathProps.stroke} d='M14.25 4.75H3.75a2 2 0 0 0-2 2v6.5a2 2 0 0 0 2 2h10.5a2 2 0 0 0 2-2v-6.5a2 2 0 0 0-2-2' />
    <path {...pathProps.stroke} d='M5 4.75A3.25 3.25 0 0 1 1.75 8M13 4.75A3.25 3.25 0 0 0 16.25 8M5 15.25A3.25 3.25 0 0 0 1.75 12M13 15.25A3.25 3.25 0 0 1 16.25 12' />
</svg>);

export const MsgIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 1.75A7.25 7.25 0 0 0 1.75 9c0 1.319.358 2.552.973 3.617.43.806-.053 2.712-.973 3.633 1.25.068 2.897-.497 3.633-.973A7.192 7.192 0 0 0 9 16.25a7.25 7.25 0 0 0 0-14.5' />
</svg>);

export const MsgSmile2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 1.75A7.25 7.25 0 0 0 1.75 9c0 1.319.358 2.552.973 3.617.43.806-.053 2.712-.973 3.633 1.25.068 2.897-.497 3.633-.973A7.192 7.192 0 0 0 9 16.25a7.25 7.25 0 0 0 0-14.5' />
    <path {...pathProps.stroke} d='M5.992 12A4.24 4.24 0 0 0 9 13.25a4.23 4.23 0 0 0 3-1.242' />
</svg>);

export const NotepadIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M13.25 2.75h-8.5a2 2 0 0 0-2 2v9.5a2 2 0 0 0 2 2h8.5a2 2 0 0 0 2-2v-9.5a2 2 0 0 0-2-2M9 1.25v3M5.75 1.25v3M12.25 1.25v3' />
    <path {...pathProps.stroke} d='M11.75 6.75h-5.5v3h5.5z' />
</svg>);

export const NumberInputIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M6.751 11V7s-.458.806-1.43.992' />
    <path {...pathProps.stroke} d='M11.25 13.25h-7.5a2 2 0 0 1-2-2v-4.5a2 2 0 0 1 2-2h7.5' />
    <path {...pathProps.fill} d='M13.5 8h3a.5.5 0 0 0 .4-.8l-1.5-2c-.188-.251-.61-.251-.8 0l-1.5 2a.497.497 0 0 0 .4.8M16.5 10h-3a.5.5 0 0 0-.4.799l1.5 2a.5.5 0 0 0 .8 0l1.5-2a.497.497 0 0 0-.4-.799' />
</svg>);

export const Nut2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 11.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5' />
    <path {...pathProps.stroke} d='M15.25 11.473V6.527a2 2 0 0 0-.997-1.73l-4.25-2.465a2 2 0 0 0-2.007 0L3.747 4.797a2 2 0 0 0-.997 1.73v4.946a2 2 0 0 0 .997 1.73l4.25 2.465a2 2 0 0 0 2.007 0l4.25-2.465a2 2 0 0 0 .997-1.73z' />
</svg>);

export const Palette1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M8.303 16.217A7.25 7.25 0 0 1 1.756 8.69c.157-3.766 3.35-6.876 7.12-6.939A7.25 7.25 0 0 1 16.25 9a2.75 2.75 0 0 1-2.75 2.75h-2.963a1.366 1.366 0 0 0-1.203 2.014l.238.44c.26.483.206 1.074-.136 1.502-.274.342-.697.552-1.133.51' />
    <path {...pathProps.fill} d='M9 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2M6.172 7.172a1 1 0 1 0 0-2 1 1 0 0 0 0 2M11.828 7.172a1 1 0 1 0 0-2 1 1 0 0 0 0 2M5 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2' />
</svg>);

export const Paperclip1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m10.986 5.422-4.773 4.773a1.5 1.5 0 1 0 2.121 2.121l4.95-4.95a3 3 0 1 0-4.243-4.243l-4.95 4.95a4.5 4.5 0 0 0 6.364 6.364l4.773-4.773' />
</svg>);

export const PaperPlane1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m15.812 2.187-8.156 8.156M15.946 2.73l-4.154 12.923a.538.538 0 0 1-.977.106l-3.16-5.416L2.24 7.184a.538.538 0 0 1 .106-.977l12.923-4.154a.538.538 0 0 1 .677.677' />
</svg>);

export const Pen2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m10.547 4.422 3.03 3.03M2.75 15.25s3.599-.568 4.546-1.515l7.327-7.327a2.142 2.142 0 1 0-3.03-3.03l-7.327 7.327c-.947.947-1.515 4.546-1.515 4.546z' />
</svg>);

export const PeopleRoofIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.25 10.25a2 2 0 1 0 0-4 2 2 0 0 0 0 4M1.32 16a4 4 0 0 1 7.86 0M12.75 10.25a2 2 0 1 0 0-4 2 2 0 0 0 0 4M11 13.152A4 4 0 0 1 16.68 16M1.75 4.25 9 .75l7.25 3.5' />
</svg>);

export const PersonArrowUpIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.75 4.25a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5M9.345 11.26 8.18 7.572a1 1 0 0 0-.802-.69 10.4 10.4 0 0 0-3.254-.001 1 1 0 0 0-.802.68l-1.17 3.704a.494.494 0 0 0 .338.626l1.258.359.202 4.05a1 1 0 0 0 1 .95h1.597a1 1 0 0 0 1-.95l.201-4.05 1.256-.36a.5.5 0 0 0 .34-.63zM14.25 13V5M12 7.25 14.25 5l2.25 2.25' />
</svg>);

export const PinIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M14.778 7.266c0 2.622-3.428 6.833-5.004 8.631a1.028 1.028 0 0 1-1.551 0C6.647 14.1 3.219 9.889 3.219 7.266c0-3.49 2.987-5.516 5.78-5.516s5.779 2.026 5.779 5.516' />
    <path {...pathProps.stroke} d='M9 9.25a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5' />
</svg>);

export const Pins2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M12.249 7.425c0 2.382-3.114 6.208-4.545 7.84a.935.935 0 0 1-1.409 0C4.864 13.632 1.75 9.807 1.75 7.425c0-3.17 2.713-5.01 5.25-5.01s5.25 1.84 5.25 5.01z' />
    <path {...pathProps.stroke} d='M7 9.5A1.75 1.75 0 1 0 7 6a1.75 1.75 0 0 0 0 3.5M13.228 2.886c1.679.736 3.022 2.308 3.022 4.539 0 2.382-3.114 6.208-4.545 7.84a.93.93 0 0 1-.705.32' />
</svg>);

export const PlusIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 3.25v11.5M3.25 9h11.5' />
</svg>);

export const Receipt1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M16.25 2.75H1.75M14.75 5.75v10.5L12 14.75l-3 1.5-3-1.5-2.75 1.5V5.75M5.75 11.25h3.5M5.75 8.25h3.5M11.75 11.25h.5M11.75 8.25h.5' />
</svg>);

export const RectanglesHorizontalIcon = (props: IconProps) => (<svg {...getCommonProps(props)} viewBox='0 0 2 18'>
    <path {...pathProps.fill} d='M1,6 C1.55228475,6 2,6.44771525 2,7 L2,11 C2,11.5522847 1.55228475,12 1,12 C0.44771525,12 0,11.5522847 0,11 L0,7 C0,6.44771525 0.44771525,6 1,6 Z M1,0 C1.55228475,0 2,0.44771525 2,1 L2,3 C2,3.55228475 1.55228475,4 1,4 C0.44771525,4 0,3.55228475 0,3 L0,1 C0,0.44771525 0.44771525,0 1,0 Z M1,14 C1.55228475,14 2,14.4477153 2,15 L2,17 C2,17.5522847 1.55228475,18 1,18 C0.44771525,18 0,17.5522847 0,17 L0,15 C0,14.4477153 0.44771525,14 1,14 Z' id='Path' />
</svg>);

export const RectLogoutIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M6.25 5.75v-1.5a2 2 0 0 1 2-2h5.5a2 2 0 0 1 2 2v9.5a2 2 0 0 1-2 2h-5.5a2 2 0 0 1-2-2v-1.5M3.5 11.75.75 9 3.5 6.25M.75 9h8.5' />
</svg>);

export const SackDollarIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M10.266 8.72c-.321-.519-.835-.644-1.235-.644-.421 0-1.526.224-1.423 1.285.072.745.774 1.022 1.387 1.131.613.11 1.504.343 1.526 1.241.019.76-.664 1.277-1.49 1.277-.683 0-1.185-.23-1.445-.744M9 7.25v.826M9 13.01v.74M10.75 4.75l2-3a1 1 0 0 0-1-1h-5.5a1 1 0 0 0-1 1l2 3zM9 4.75v-1.5' />
    <path {...pathProps.stroke} d='M7.25 4.75C4.859 6.031 3 9.266 3 12c0 3.314 2.686 4.25 6 4.25s6-.936 6-4.25c0-2.734-1.859-5.969-4.25-7.25' />
</svg>);

export const SectionIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M1.75 7.75v2.5a2 2 0 0 0 2 2h10.5a2 2 0 0 0 2-2v-2.5a2 2 0 0 0-2-2H3.75a2 2 0 0 0-2 2M3.75 15.75h10.5M3.75 2.25h10.5' />
</svg>);

export const ShapeTriangleIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m7.638 3.495-5.425 9.396a1.573 1.573 0 0 0 1.362 2.359h10.85a1.573 1.573 0 0 0 1.362-2.359l-5.425-9.396a1.573 1.573 0 0 0-2.724 0' />
</svg>);

export const ShieldUserIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 9.25a2 2 0 1 0 0-4 2 2 0 0 0 0 4M5.191 14.522a4.002 4.002 0 0 1 7.616 0' />
    <path {...pathProps.stroke} d='m9.305 1.845 5.25 1.68a1 1 0 0 1 .695.952v6.52c0 3.03-4.684 4.748-5.942 5.155a1 1 0 0 1-.616 0c-1.258-.407-5.942-2.125-5.942-5.155V4.478c0-.435.281-.82.695-.952l5.25-1.68c.198-.063.411-.064.61 0' />
</svg>);

export const ShopIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M3.75 16.25V9.5M14.25 9.5v6.75M13.67 1.75H4.333c-.36 0-.691.193-.87.505L1.759 5.25A2.99 2.99 0 0 0 4.478 7 2.98 2.98 0 0 0 6.74 5.951 2.98 2.98 0 0 0 9.002 7c.908 0 1.71-.411 2.26-1.048A2.98 2.98 0 0 0 13.525 7a3 3 0 0 0 2.72-1.75L14.54 2.255a1 1 0 0 0-.87-.505M7.25 16v-3a1.75 1.75 0 0 1 3.5 0v3M1.75 16.25h14.5' />
</svg>);

export const SidebarLeft2ShowIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M4.75 5.75v6.5M11 11.25 13.25 9 11 6.75M13.25 9h-6' />
    <path {...pathProps.stroke} d='M3.75 15.25h10.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2H3.75a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2' />
</svg>);

export const SidebarLeft3HideIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M6.25 12.5v2.75M6.25 2.75V5.5M8.75 6.5 6.25 9l2.5 2.5M6.25 9h6.5' />
    <path {...pathProps.stroke} d='M3.75 15.25h10.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2H3.75a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2' />
</svg>);

export const SidebarRight2ShowIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M4.75 9h6M7 6.75 4.75 9 7 11.25m6.25 1V5.75' />
    <path {...pathProps.stroke} d='M3.75 15.25h10.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2H3.75a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2' />
</svg>);

export const SidebarRight3HideIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M11.75 12.5v2.75M11.75 2.75V5.5M9.25 6.5l2.5 2.5-2.5 2.5M11.75 9h-6.5' />
    <path {...pathProps.stroke} d='M14.25 2.75H3.75a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h10.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2' />
</svg>);

export const Sliders3Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M13.25 5.25h3M1.75 5.25h7M11 7.5A2.25 2.25 0 1 0 11 3a2.25 2.25 0 0 0 0 4.5M4.75 12.75h-3M16.25 12.75h-7M7 15a2.25 2.25 0 1 0 0-4.5A2.25 2.25 0 0 0 7 15' />
</svg>);

export const SortBottomToTop1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M11.5 6 9.25 3.75 11.5 1.5M2.25 3.75h4M2.25 9h10' />
    <path {...pathProps.stroke} d='M2.25 14.25h11.5a2 2 0 0 0 2-2v-6.5a2 2 0 0 0-2-2h-4.5' />
</svg>);

export const SpaceshipIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m5.07 10.556-1.232.746a2 2 0 0 0-.956 1.92l.368 3.528 3.15-2M12.928 10.556l1.233.746a2 2 0 0 1 .956 1.92l-.368 3.528-3.151-2' />
    <path {...pathProps.stroke} d='M9 14.75h2.6c.37-.672 2.327-4.368.865-8.651C11.573 3.487 9.84 1.916 9 1.25c-.839.666-2.573 2.237-3.465 4.849-1.462 4.283.495 7.979.866 8.651zM9 16.75v-5.5' />
    <path {...pathProps.fill} d='M9 9a1.25 1.25 0 1 0 0-2.5A1.25 1.25 0 0 0 9 9' />
</svg>);

export const SquareGrid1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M13.25 2.75h-8.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h8.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2' />
    <path {...pathProps.strokeFill} d='M7.75 5.75h-2v2h2zM12.25 5.75h-2v6.5h2zM7.75 10.25h-2v2h2z' />
</svg>);

export const SquareGrid2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M13.25 2.75h-8.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h8.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2' />
    <path {...pathProps.fill} d='M9.6 5H8.4a.4.4 0 0 0-.4.4v1.2c0 .22.18.4.4.4h1.2a.4.4 0 0 0 .4-.4V5.4a.4.4 0 0 0-.4-.4M9.6 8H8.4a.4.4 0 0 0-.4.4v1.2c0 .22.18.4.4.4h1.2a.4.4 0 0 0 .4-.4V8.4a.4.4 0 0 0-.4-.4M9.6 11H8.4a.4.4 0 0 0-.4.4v1.2c0 .22.18.4.4.4h1.2a.4.4 0 0 0 .4-.4v-1.2a.4.4 0 0 0-.4-.4M6.6 5H5.4a.4.4 0 0 0-.4.4v1.2c0 .22.18.4.4.4h1.2a.4.4 0 0 0 .4-.4V5.4a.4.4 0 0 0-.4-.4M6.6 8H5.4a.4.4 0 0 0-.4.4v1.2c0 .22.18.4.4.4h1.2a.4.4 0 0 0 .4-.4V8.4a.4.4 0 0 0-.4-.4M6.6 11H5.4a.4.4 0 0 0-.4.4v1.2c0 .22.18.4.4.4h1.2a.4.4 0 0 0 .4-.4v-1.2a.4.4 0 0 0-.4-.4M12.6 5h-1.2a.4.4 0 0 0-.4.4v1.2c0 .22.18.4.4.4h1.2a.4.4 0 0 0 .4-.4V5.4a.4.4 0 0 0-.4-.4M12.6 8h-1.2a.4.4 0 0 0-.4.4v1.2c0 .22.18.4.4.4h1.2a.4.4 0 0 0 .4-.4V8.4a.4.4 0 0 0-.4-.4M12.6 11h-1.2a.4.4 0 0 0-.4.4v1.2c0 .22.18.4.4.4h1.2a.4.4 0 0 0 .4-.4v-1.2a.4.4 0 0 0-.4-.4' />
</svg>);

export const SquareGrid3Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M13.25 2.75h-8.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h8.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2' />
    <path {...pathProps.strokeFill} d='M7.75 5.75h-2v2h2zM12.25 5.75h-2v2h2zM7.75 10.25h-2v2h2zM12.25 10.25h-2v2h2z' />
</svg>);

export const SquareLayoutGrid3Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 7.25v8M15.25 7.25H2.75M13.25 2.75h-8.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h8.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2' />
</svg>);

export const SquareSparkleIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m9 5.75.921 2.329L12.25 9l-2.329.921L9 12.25l-.921-2.329L5.75 9l2.329-.921z' />
    <path {...pathProps.stroke} d='M13.25 2.75h-8.5a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h8.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2' />
</svg>);

export const SquareUserPlusIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 9.75a2 2 0 1 0 0-4 2 2 0 0 0 0 4M5.125 15.25a4.002 4.002 0 0 1 7.748 0' />
    <path {...pathProps.stroke} d='M15.25 8.5v4.75a2 2 0 0 1-2 2h-8.5a2 2 0 0 1-2-2v-8.5a2 2 0 0 1 2-2H9.5M14.75.75v5M17.25 3.25h-5' />
</svg>);

export const SquareWandSparkleIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.fill} d='m11.746 2.492-.946-.315-.316-.947c-.102-.306-.61-.306-.711 0l-.316.947-.946.315a.375.375 0 0 0 0 .712l.946.315.316.947a.374.374 0 0 0 .71 0l.316-.947.946-.315a.375.375 0 0 0 0-.712M16.659 5.99l-1.263-.421-.421-1.263c-.137-.408-.812-.408-.949 0l-.421 1.263-1.263.421a.5.5 0 0 0 0 .948l1.263.421.421 1.263a.501.501 0 0 0 .95 0l.421-1.263 1.263-.421a.5.5 0 0 0-.001-.948M15.25 2.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5' />
    <path {...pathProps.stroke} d='m7.956 8.043-6.413 6.413a1 1 0 0 0 0 1.414l.586.586a1 1 0 0 0 1.414 0l6.412-6.413a1 1 0 0 0 0-1.414l-.585-.586a1 1 0 0 0-1.414 0M8.285 15.25h4.965a2 2 0 0 0 2-2v-2.634M6.502 2.75H4.75a2 2 0 0 0-2 2v4.965M6.07 9.93l2 2' />
</svg>);

export const StarUpgradeIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m9 1.774 2.009 4.07 4.491.653-3.25 3.168.767 4.473L9 12.026l-4.017 2.112.767-4.473L2.5 6.497l4.491-.653zM9 15.75v1.065M15.836 10.673l1.013.329M13.227 2.636l.626-.862M4.774 2.636l-.626-.862M2.161 10.673l-1.013.329' />
</svg>);

export const Tag3Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M3.25 2.25h4.922a2 2 0 0 1 1.414.586l5.75 5.75a2 2 0 0 1 0 2.828l-3.922 3.922a2 2 0 0 1-2.828 0l-5.75-5.75a2 2 0 0 1-.586-1.414V3.25a1 1 0 0 1 1-1' />
    <path {...pathProps.fill} d='M6.25 7.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5' />
</svg>);

export const Tasks2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M7.25 16.25h7a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2h-7a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2' />
    <path {...pathProps.stroke} d='M3 12.605a2 2 0 0 1-1.25-1.855v-7a2 2 0 0 1 2-2h7c.839 0 1.558.517 1.855 1.25M8.246 11.5l1.61 1.5 3.396-4.5' />
</svg>);

export const TextAlignLeft2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M2.75 14.25h12.5M2.75 3.75h12.5M2.75 9h5.5' />
</svg>);

export const TextTool2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 6.25v5.5M11.25 6.25h-4.5M4.25 1.75h-2a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5M15.75 1.75h-2a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5M4.25 13.25h-2a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5M15.75 13.25h-2a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5M3.25 13.25v-8.5M13.25 14.75h-8.5M14.75 4.75v8.5M4.75 3.25h8.5' />
</svg>);

export const ThumbsUpIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M5.25 7.494c0-.48.173-.944.486-1.307L10 1.25a1.874 1.874 0 0 1 .92 2.324L9.75 6.75h4.402a2 2 0 0 1 1.933 2.512l-1.191 4.5a2 2 0 0 1-1.933 1.488H7.25a2 2 0 0 1-2-2M4.25 6.75h-1.5a1 1 0 0 0-1 1v6.5a1 1 0 0 0 1 1h1.5a1 1 0 0 0 1-1v-6.5a1 1 0 0 0-1-1' />
</svg>);

export const ThumbsUpIconScaling = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.strokeScaling} d='M5.25 7.494c0-.48.173-.944.486-1.307L10 1.25a1.874 1.874 0 0 1 .92 2.324L9.75 6.75h4.402a2 2 0 0 1 1.933 2.512l-1.191 4.5a2 2 0 0 1-1.933 1.488H7.25a2 2 0 0 1-2-2M4.25 6.75h-1.5a1 1 0 0 0-1 1v6.5a1 1 0 0 0 1 1h1.5a1 1 0 0 0 1-1v-6.5a1 1 0 0 0-1-1' />
</svg>);

export const Trash2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m13.474 7.25-.374 7.105a2 2 0 0 1-1.997 1.895H6.898a2 2 0 0 1-1.997-1.895L4.527 7.25M2.75 4.75h12.5M6.75 4.75v-2a1 1 0 0 1 1-1h2.5a1 1 0 0 1 1 1v2' />
</svg>);

export const TrashSlashIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M4.84 13.163 4.526 7.25M13.43 8.107l-.33 6.25a2 2 0 0 1-1.997 1.894H6.898c-.452 0-.87-.15-1.207-.405M2.75 4.75h10.5M6.75 4.75v-2a1 1 0 0 1 1-1h2.5a1 1 0 0 1 1 1v2M2 16 16 2' />
</svg>);

export const TrashXmarkIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m13.474 7.25-.374 7.105a2 2 0 0 1-1.997 1.895H6.898a2 2 0 0 1-1.997-1.895L4.527 7.25M2.75 4.75h12.5M6.75 4.75v-2a1 1 0 0 1 1-1h2.5a1 1 0 0 1 1 1v2M7.23 8.732l3.537 3.536M10.767 8.732 7.23 12.268' />
</svg>);

export const TriangleWarningIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m7.638 3.495-5.425 9.396a1.573 1.573 0 0 0 1.362 2.359h10.85a1.573 1.573 0 0 0 1.362-2.359l-5.425-9.396a1.573 1.573 0 0 0-2.724 0M9 6.5V10' />
    <path {...pathProps.fill} d='M9 13.57a1.001 1.001 0 0 1 0-2 1.001 1.001 0 0 1 0 2' />
</svg>);

export const TriangleWarningIconScaling = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.strokeScaling} d='m7.638 3.495-5.425 9.396a1.573 1.573 0 0 0 1.362 2.359h10.85a1.573 1.573 0 0 0 1.362-2.359l-5.425-9.396a1.573 1.573 0 0 0-2.724 0M9 6.5V10' />
    <path {...pathProps.fillScaling} d='M9 13.57a1.001 1.001 0 0 1 0-2 1.001 1.001 0 0 1 0 2' />
</svg>);

export const TrophyIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9.5 12.25s0 2.938 3.75 4h-8.5c3.75-1.062 3.75-4 3.75-4M5.287 9C1.47 9 1.75 3.75 1.75 3.75h2.237M12.715 9c3.818 0 3.536-5.25 3.536-5.25h-2.237' />
    <path {...pathProps.stroke} d='M14 1.75c-.625 6.531-2.281 10.219-4.75 10.5h-.5C6.281 11.969 4.625 8.281 4 1.75z' />
</svg>);

export const UserArrowRightIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 7.25a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5M14.25 10.25l2.5 2.5-2.5 2.5M11.484 10.277A6.2 6.2 0 0 0 9 9.75a6.24 6.24 0 0 0-5.709 3.72c-.365.825.087 1.774.947 2.045A15.8 15.8 0 0 0 9 16.249c1.055 0 2.011-.111 2.871-.27M16.75 12.75h-5' />
</svg>);

export const UserCheckIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 7.25a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5M13.313 11.5A6.2 6.2 0 0 0 9 9.75a6.24 6.24 0 0 0-5.709 3.72c-.365.825.087 1.774.947 2.045A15.8 15.8 0 0 0 9 16.249M11.25 14.75l1.609 1.5 3.397-4.5' />
</svg>);

export const UserGroupIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 5.75a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M12.323 11.499l-.852-2.413a1.995 1.995 0 0 0-1.885-1.334H8.412c-.848 0-1.602.535-1.884 1.334l-.85 2.413a.5.5 0 0 0 .333.647l1.24.354.194 3.309a1 1 0 0 0 .998.941h1.114a1 1 0 0 0 .998-.941l.195-3.309 1.24-.354a.5.5 0 0 0 .333-.647M13.75 4.25a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M13.191 15.25h1.114a1 1 0 0 0 .998-.941L15.498 11l1.24-.354a.5.5 0 0 0 .333-.647l-.852-2.413a1.995 1.995 0 0 0-1.885-1.334H13.16M4.25 4.25a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M4.807 15.25H3.693a1 1 0 0 1-.998-.941L2.5 11l-1.239-.354a.5.5 0 0 1-.334-.647l.852-2.413a1.995 1.995 0 0 1 1.885-1.334h1.174' />
</svg>);

export const UserListIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M6.809 7.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5M10.847 14.817c.837-.291 1.266-1.257.866-2.048a5.494 5.494 0 0 0-9.812 0c-.4.791.028 1.757.866 2.048 1.031.358 2.408.683 4.04.683s3.01-.325 4.04-.683M16.25 3.25h-4.5M16.25 6.75h-4.5M16.25 10.25H13.5' />
</svg>);

export const UserPinIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M9 7.25a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5' />
    <path {...pathProps.fill} d='M14.25 14a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5' />
    <path {...pathProps.stroke} d='M10.25 9.891A6.2 6.2 0 0 0 9 9.75a6.24 6.24 0 0 0-5.709 3.72c-.365.825.087 1.774.947 2.045A15.8 15.8 0 0 0 9 16.249c.303 0 .585-.022.873-.038M14.25 17s-2.75-1.509-2.75-3.75a2.75 2.75 0 1 1 5.5 0c0 2.241-2.75 3.75-2.75 3.75' />
</svg>);

export const Users1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M11.039 14.817c.837-.291 1.266-1.257.866-2.048a5.494 5.494 0 0 0-9.812 0c-.4.791.028 1.757.866 2.048 1.03.358 2.408.683 4.04.683s3.009-.325 4.04-.683M14.532 13.555a11 11 0 0 0 1.566-.428c.702-.25 1.01-1.077.675-1.743a4.78 4.78 0 0 0-5.02-2.577M11.952 6.167c.177.045.356.083.547.083a2.25 2.25 0 1 0 0-4.5c-.334 0-.647.082-.933.213M7 7.25a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5' />
</svg>);

export const Video1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='m12.25 8 4.259-2.342a.5.5 0 0 1 .741.438v5.81a.5.5 0 0 1-.741.437l-4.259-2.342M10.25 3.75h-6.5a2 2 0 0 0-2 2v6.5a2 2 0 0 0 2 2h6.5a2 2 0 0 0 2-2v-6.5a2 2 0 0 0-2-2' />
    <path {...pathProps.fill} d='M4.75 7.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5' />
</svg>);

export const ViewAll1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M8.25 2.75h-3.5a1 1 0 0 0-1 1v3.5a1 1 0 0 0 1 1h3.5a1 1 0 0 0 1-1v-3.5a1 1 0 0 0-1-1M15.25 4.75h-2a1 1 0 0 0-1 1v6.5a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-6.5a1 1 0 0 0-1-1M8.25 11.25h-5.5a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h5.5a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1' />
</svg>);

export const WalletContentIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M2.25 4.75v8.5a2 2 0 0 0 2 2h10.5a1 1 0 0 0 1-1v-6.5a1 1 0 0 0-1-1H4.25a2 2 0 0 1-2-2M2.25 4.75a2 2 0 0 1 2-2' />
    <path {...pathProps.fill} d='M12.75 12.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5' />
    <path {...pathProps.stroke} d='M6.031 4.255 7.238 1.79a1 1 0 0 1 1.336-.463l6.014 2.917' />
</svg>);

export const WindowLinkIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.fill} d='M4.25 6a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M6.75 6a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5' />
    <path {...pathProps.stroke} d='M1.75 7.75h14.5M16.25 10.283V4.75a2 2 0 0 0-2-2H3.75a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h4.5' />
    <path {...pathProps.stroke} d='M12.75 16.75h-.5a1.5 1.5 0 0 1-1.5-1.5v-1a1.5 1.5 0 0 1 1.5-1.5h.5M15.25 16.75h.5a1.5 1.5 0 0 0 1.5-1.5v-1a1.5 1.5 0 0 0-1.5-1.5h-.5M13.25 14.75h1.5' />
</svg>);

export const WindowPlus1Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.fill} d='M4.25 6a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M6.75 6a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5' />
    <path {...pathProps.stroke} d='M1.75 7.75h14.5M16.25 10.501V4.75a2 2 0 0 0-2-2H3.75a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h5.5M14.25 12.25v5M16.75 14.75h-5' />
</svg>);

export const WindowPlus2Icon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M3.75 15.25h10.5a2 2 0 0 0 2-2v-8.5a2 2 0 0 0-2-2H3.75a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2M6.5 9.75h5M9 7.25v5' />
    <path {...pathProps.fill} d='M4.25 6a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M6.75 6a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5' />
</svg>);

export const XmarkIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M14 4 4 14M4 4l10 10' />
</svg>);

export const basicIcons: IconType[] = [
    AccessibilityIcon,
    ArrowDiagonalIn1Icon,
    ArrowLeftIcon,
    ArrowRightIcon,
    ArrowRightIconScaling,
    ArrowsExpandDiagonal6Icon,
    ArrowsReduceDiagonal1Icon,
    AscendingSortingIcon,
    AutomatedLogistics1Icon,
    BankIcon,
    BankingMobileIcon,
    BankStatementIcon,
    BoxPlusIcon,
    Box2Icon,
    Box2PlusIcon,
    BoxHeartIcon,
    BoxMinusIcon,
    BugIcon,
    BugSlashIcon,
    ButtonIcon,
    CalendarCheck2Icon,
    CalendarCheckInIcon,
    CalendarCheckOutIcon,
    CalendarEventIcon,
    CalendarIcon,
    CalendarPhoneIcon,
    CalendarPlus1Icon,
    CalendarPlus2Icon,
    CartShoppingIcon,
    ChartCircular2Icon,
    ChartColumnIcon,
    ChartComboIcon,
    ChartDonut1Icon,
    ChartStackedAreaIcon,
    Check1Icon,
    CheckboxCheckedIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronUpIcon,
    CircleCheck1Icon,
    CircleCopy1Icon,
    CircleDollarIcon,
    CircleDotsIcon,
    CircleDottedUserIcon,
    CircleHalfDottedClockIcon,
    CircleInfoIcon,
    CircleMinusIcon,
    CirclePercentageIcon,
    CirclePlusIcon,
    CircleUserIcon,
    Clock1Icon,
    ClockRotateClockwise1Icon,
    Clone1Icon,
    CoinsIcon,
    Compose1Icon,
    Copies6Icon,
    Copy1Icon,
    CreditCardIcon,
    CurrencyExchangeIcon,
    DescendingSortingIcon,
    DiscountIcon,
    DoorOpenIcon,
    DotsIcon,
    DotsVerticalIcon,
    Duplicate1Icon,
    EnvelopeIcon,
    ExportIcon,
    Eye1Icon,
    Eye2Icon,
    Eye2SlashIcon,
    FacePlusIcon,
    FileDownloadIcon,
    FilePlusIcon,
    FileSendIcon,
    FileUserIcon,
    FillIcon,
    FindReplaceIcon,
    Gear1Icon,
    Globe2Icon,
    GoalFlagIcon,
    GradientIcon,
    GridPlus2Icon,
    ImageSparkle3Icon,
    Images2Icon,
    ImportIcon,
    InputPasswordEditIcon,
    InputPasswordPointerIcon,
    InvoiceDollarIcon,
    InvoiceIcon,
    Label1Icon,
    LanguageIcon,
    LicenseIcon,
    Link5Icon,
    ListCheckbox1Icon,
    LockIcon,
    LockOpen2Icon,
    MediaPlayIcon,
    MoneyBillCoinIcon,
    MoneyBillsDollarIcon,
    MsgIcon,
    MsgSmile2Icon,
    NumberInputIcon,
    NotepadIcon,
    Nut2Icon,
    Palette1Icon,
    Paperclip1Icon,
    PaperPlane1Icon,
    Pen2Icon,
    PeopleRoofIcon,
    PersonArrowUpIcon,
    PinIcon,
    Pins2Icon,
    PlusIcon,
    Receipt1Icon,
    RectanglesHorizontalIcon,
    RectLogoutIcon,
    SackDollarIcon,
    SectionIcon,
    ShapeTriangleIcon,
    ShieldUserIcon,
    SidebarLeft2ShowIcon,
    SidebarLeft3HideIcon,
    SidebarRight2ShowIcon,
    SidebarRight3HideIcon,
    Sliders3Icon,
    SortBottomToTop1Icon,
    SpaceshipIcon,
    SquareGrid1Icon,
    SquareGrid2Icon,
    SquareGrid3Icon,
    SquareLayoutGrid3Icon,
    SquareSparkleIcon,
    SquareUserPlusIcon,
    SquareWandSparkleIcon,
    StarUpgradeIcon,
    Tag3Icon,
    Tasks2Icon,
    TextAlignLeft2Icon,
    TextTool2Icon,
    ThumbsUpIcon,
    Trash2Icon,
    TrashSlashIcon,
    TrashXmarkIcon,
    TriangleWarningIcon,
    TrophyIcon,
    UserArrowRightIcon,
    UserCheckIcon,
    UserGroupIcon,
    UserListIcon,
    UserPinIcon,
    Users1Icon,
    Video1Icon,
    ViewAll1Icon,
    WalletContentIcon,
    WindowLinkIcon,
    WindowPlus1Icon,
    WindowPlus2Icon,
    XmarkIcon,
];
