import { z } from 'zod';
import { zId } from ':utils/id';
import { zClientInfoOutput, zClientInit } from './client';
import { OrderState, PaymentMethod } from './invoicing';
import { zCurrencyId, zTaxRate } from ':utils/money';

export enum EventParticipantType {
    guest = 'guest',
    client = 'client',
    both = 'both',
}

export type EventParticipantOrder = z.infer<typeof zEventParticipantOrder>;
const zEventParticipantOrder = z.object({
    id: zId,
    paymentMethod: z.nativeEnum(PaymentMethod),
    state: z.nativeEnum(OrderState),
});

export type PaymentData = z.infer<typeof zPaymentData>;
const zPaymentData = z.object({
    price: z.number(),
    currency: zCurrencyId,
    taxRate: zTaxRate,
});

export type ParticipantPaymentData = z.infer<typeof zParticipantPaymentData>;
const zParticipantPaymentData = z.discriminatedUnion('type', [ z.object({
    type: z.literal(EventParticipantType.guest),
}), z.object({
    type: z.enum([ EventParticipantType.client, EventParticipantType.both ]),
    payment: zPaymentData,
}) ]);

export type EventParticipantOutput = z.infer<typeof zEventParticipantOutput>;
export const zEventParticipantOutput = z.object({
    id: zId,
    client: zClientInfoOutput,
    type: z.nativeEnum(EventParticipantType),
    payment: zPaymentData.optional(),
    order: zEventParticipantOrder.optional(),
});

export function isEventParticipantPaying({ type }: { type: EventParticipantType}): boolean {
    return ([ EventParticipantType.client, EventParticipantType.both ] as EventParticipantType[]).includes(type);
}

export type ClientParticipantUpsert = z.infer<typeof zClientParticipantUpsert>;
export const zClientParticipantUpsert = zParticipantPaymentData.and(z.object({
    id: zId,
}));

export type ContactParticipanUpsert = z.infer<typeof zContactParticipanUpsert>;
export const zContactParticipanUpsert = zParticipantPaymentData.and(z.object({
    contact: zClientInit,
}));
