export function MarkdownDisplay({ value }: { value: string }) {
    return (
        <div dangerouslySetInnerHTML={{ __html: value }} className='fl-markdown' />
    );
}

export type MarkdownControl = {
    current: {
        value: string;
    };
};

type MarkdownPreviewProps = Readonly<{
    control: MarkdownControl;
}>;

export function MarkdownPreview({ control }: MarkdownPreviewProps) {
    return (
        <div dangerouslySetInnerHTML={{ __html: control.current.value }} className='fl-markdown' />
    );
}
