import { useState } from 'react';
import { Card, Modal } from ':components/shadcn';
import useNotifications from ':frontend/context/NotificationProvider';
import { trpc } from ':frontend/context/TrpcProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { BankingForm } from './BankingForm';
import type { BankAccountOutput, BankAccountUpsert } from ':utils/entity/invoicing';
import { useMaster } from ':frontend/context/UserProvider';
import { type Id } from ':utils/id';
import { BankStatementIcon } from ':components/icons/basic';
import { useTranslation } from 'react-i18next';

type UpsertBankAccountModalProps = Readonly<{
    bankAccount: BankAccountOutput | undefined;
    show: boolean;
    onClose: () => void;
    /** Only the currencies that are currently unsupported. */
    currencies: Id[];
}>;

export function UpsertBankAccountModal({ bankAccount, show, onClose, currencies }: UpsertBankAccountModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'bankingForm' });
    const [ isFetching, setIsFetching ] = useState(false);

    return (
        <Modal.Root open={show} onOpenChange={() => onClose()}>
            <Modal.Content closeButton='Close' className='max-w-2xl border-0'>
                <Modal.Header>
                    <Modal.Title className='flex items-center gap-2'>
                        <BankStatementIcon className='h-5 text-danger-500' />
                        <span>{bankAccount ? t('edit-title') : t('add-new-title')}</span>
                    </Modal.Title>
                </Modal.Header>

                <Card.Divider className='my-0' />

                {bankAccount ? (
                    <EditAccount
                        bankAccount={bankAccount}
                        onClose={onClose}
                        currencies={currencies}
                        isFetching={isFetching}
                        setIsFetching={setIsFetching}
                    />
                ) : (
                    <CreateAccount
                        onClose={onClose}
                        currencies={currencies}
                        isFetching={isFetching}
                        setIsFetching={setIsFetching}
                    />
                )}
            </Modal.Content>
        </Modal.Root>
    );
}

type EditAccountProps = Readonly<{
    bankAccount: BankAccountOutput;
    onClose: () => void;
    currencies: Id[];
    isFetching: boolean;
    setIsFetching: (isFetching: boolean) => void;
}>;

function EditAccount({ bankAccount, onClose, currencies, isFetching, setIsFetching }: EditAccountProps) {
    const { addAlert } = useNotifications();
    const { setBankAccounts } = useMaster();

    const updateBankAccountMutation = trpc.invoicing.updateBankAccount.useMutation();

    function updateAccount(edit: BankAccountUpsert) {
        if (isFetching)
            return;

        setIsFetching(true);

        updateBankAccountMutation.mutate({
            raw: edit.raw,
            currencies: edit.currencies,
            id: bankAccount.id,
        }, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                setBankAccounts(prev => prev.map(a => a.id !== response.id ? a : response));
                addAlert(createTranslatedSuccessAlert('common:bankAccount.edited-alert'));
            },
            onSettled: () => {
                setIsFetching(false);
                onClose();
            },
        });
    }

    return (
        <BankingForm
            input={bankAccount}
            defaultCurrencies={currencies}
            onSubmit={updateAccount}
            isFetching={isFetching}
        />
    );
}

type CreateAccountProps = Readonly<{
    onClose: () => void;
    currencies: Id[];
    isFetching: boolean;
    setIsFetching: (isFetching: boolean) => void;
}>;

function CreateAccount({ onClose, currencies, isFetching, setIsFetching }: CreateAccountProps) {
    const { addAlert } = useNotifications();
    const { setBankAccounts } = useMaster();

    const createBankAccountMutation = trpc.invoicing.createBankAccount.useMutation();

    function createAccount(init: BankAccountUpsert) {
        setIsFetching(true);
        createBankAccountMutation.mutate(init, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                setBankAccounts(prev => [ ...prev, response ]);
                addAlert(createTranslatedSuccessAlert('common:bankAccount.created-alert'));
            },
            onSettled: () => {
                setIsFetching(false);
                onClose();
            },
        });
    }

    return (
        <BankingForm
            defaultCurrencies={currencies}
            onSubmit={createAccount}
            isFetching={isFetching}
        />
    );
}
