import { useEffect, useMemo, useRef, useState } from 'react';
import { type ProductOutput } from ':utils/entity/product';
import type { StoreOutput } from ':utils/entity/store';
import clsx from 'clsx';
import { DayPicker, type MonthCaptionProps } from 'react-day-picker';
import '../../styles/react-day-picker.css';
import { DateTime } from 'luxon';
import { secondsToMinutes, timezoneToDisplayString, type DateRange } from ':utils/dateTime';
import { ScrollArea, Tooltip } from ':components/shadcn';
import type { TimezoneCode } from ':utils/i18n';
import type { ProductPreview } from './ProductCard';

type BlocksByDay = Record<string, DateRange[]>

export type UseAvailability = (range: DateRange) => Availability | undefined;

type Availability = {
    ranges: DateRange[];
    timezone?: TimezoneCode;
};

type ProductSchedulingFormProps = {
    store: StoreOutput;
    product: ProductOutput | ProductPreview;
    /** Bevare this is a hook so it should be the same function (or a different function using the same basic hooks) for each render. */
    useAvailability: UseAvailability;
    selectedBlock: DateRange | undefined;
    onSelectBlock: (block: DateRange | undefined) => void;
    isCompact?: boolean;
};

export function ProductSchedulingForm({ store, product, useAvailability, selectedBlock, onSelectBlock, isCompact }: ProductSchedulingFormProps) {
    const [ month, setMonth ] = useState(() => DateTime.now().startOf('month'));
    const range = useMemo(() => ({ start: month, end: month.plus({ month: 1 }) }), [ month ]);

    const availability = useAvailability(range);
    const isPreview = !('id' in product);

    const [ date, setDate ] = useState(DateTime.now());

    const jsMonth = useMemo(() => month.toJSDate(), [ month ]);
    const jsDate = useMemo(() => date.toJSDate(), [ date ]);

    const setJs = useMemo(() => ({
        month: (jsMonth: Date) => setMonth(DateTime.fromJSDate(jsMonth)),
        date: (jsDate: Date | undefined) => jsDate && setDate(DateTime.fromJSDate(jsDate)),
    }), [ setMonth ]);

    // TimeBlocks by Days
    const blocksByDay: BlocksByDay = useMemo(() => {
        if (!('sessionsDuration' in product) || !product.sessionsDuration)
            return {};

        return mapRangesToBlocks({
            ranges: availability?.ranges ?? [],
            lengthInMinutes: secondsToMinutes(product.sessionsDuration),
            blockEachMinutes: 15,
        });
    }, [ product, availability?.ranges ]);

    // TimeBlocks by SelectedDate
    const blocks = useMemo(() => {
        const blocksForDay = blocksByDay[date.toISODate()];
        if (!blocksForDay)
            return [];

        if (isPreview)
            return blocksForDay.slice(0, 4);

        return blocksForDay;
    }, [ blocksByDay, date, isPreview ]);

    // Dates with at least one block
    const highlightedDates = useMemo(() => {
        return Object.keys(blocksByDay).map(dayKey => DateTime.fromISO(dayKey).toJSDate());
    }, [ blocksByDay ]);

    const isWide = !isCompact && !isPreview;

    // Set height of TimeColumn for scrolling
    const dayColumnRef = useRef<HTMLDivElement>(null);
    const [ timeColumnHeight, setTimeColumnHeight ] = useState<number>();

    useEffect(() => {
        setTimeColumnHeight(dayColumnRef.current?.clientHeight);
    }, [ month ]);


    return (
        <div className={clsx('flex flex-col items-center', isWide && 'md:flex-row md:items-start')}>
            <div ref={dayColumnRef} className={clsx('flex p-2 pb-4', isWide && 'md:w-3/4 md:p-4')}>
                <DayPicker
                    mode='single'
                    selected={jsDate}
                    onSelect={setJs.date}
                    month={jsMonth}
                    onMonthChange={setJs.month}
                    modifiers={{
                        schedulable: highlightedDates,
                    }}
                    modifiersClassNames={{
                        schedulable: 'rdp-schedulable',
                    }}
                    weekStartsOn={1}
                    components={{ MonthCaption }}
                />
            </div>

            <div
                className={clsx('w-full max-md:!h-auto border-t border-secondary-100', isWide && 'md:w-1/4 md:border-t-0 md:border-l md:overflow-hidden md:rounded-br-xl')}
                style={{ height: isWide ? timeColumnHeight : undefined }}
            >
                <ScrollArea className='h-full'>
                    <div className={clsx('p-4 grid grid-cols-2 phone:grid-cols-2 sm:grid-cols-3 gap-2', isWide && 'md:p-6 md:grid-cols-1')}>
                        <div className='col-span-full px-2'>
                            <div className='text-xl/6 font-semibold'>
                                {date.toFormat('EEE')} <span className='text-secondary-400'>{date.toFormat('dd')}</span>
                            </div>

                            {blocks.length !== 0 && (
                                <TimezoneLabel date={month} storeTimezone={availability?.timezone} name={store.bio.name} />
                            )}
                        </div>

                        {isPreview && (
                        // TODO Translation
                            <div className='col-span-full p-6 text-center text-secondary-400'>
                                This is a preview. The actual availability may differ.
                            </div>
                        )}

                        {blocks.length === 0 && (
                        // TODO Translation
                            <div className='col-span-full text-center text-secondary-400'>
                                No available times
                            </div>
                        )}

                        {blocks.map((block, index) => {
                            const isSelected = block.start === selectedBlock?.start && block.end === selectedBlock?.end;

                            return (
                                <button
                                    key={index}
                                    className={clsx(
                                        'rounded p-2 text-center border-2 transition-colors w-full',
                                        isSelected ? 'border-primary bg-primary/5' : 'border-secondary-100 hover:border-secondary',
                                    )}
                                    onClick={() => onSelectBlock(block)}
                                >
                                    {block.start.toFormat('HH:mm')} - {block.end.toFormat('HH:mm')}
                                </button>
                            );
                        })}
                    </div>
                </ScrollArea>
            </div>
        </div>
    );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function MonthCaption({ calendarMonth, displayIndex, ...divProps }: MonthCaptionProps) {
    const now = DateTime.fromJSDate(calendarMonth.date);

    return (
        <div {...divProps}>
            {now.toFormat('MMMM')} <span className='text-secondary-400'>{now.toFormat('yyyy')}</span>
        </div>
    );
}

type TimezoneLabelProps = {
    date: DateTime;
    storeTimezone?: string;
    name: string;
};

function TimezoneLabel({ date, storeTimezone, name }: TimezoneLabelProps) {
    const text = (
        <div className='py-2 font-medium text-sm text-secondary-400'>
            Adjusted to your timezone
        </div>
    );

    if (!storeTimezone)
        return text;

    const tooltipText = storeTimezone === date.zoneName
        ? `All times are displayed in your timezone (${timezoneToDisplayString(date.zoneName)}).`
        : `${name} is based in ${timezoneToDisplayString(storeTimezone)}. All times were adjusted to your timezone (${timezoneToDisplayString(date.zoneName)}).`;

    return (
        <Tooltip tooltipText={tooltipText}>
            {text}
        </Tooltip>
    );
}

type MapRangesToBlockParams = {
    ranges: DateRange[];
    lengthInMinutes: number;
    blockEachMinutes: number;
};

/**
 * Takes an array of ranges and transforms each range into blocks of {lengthInMinutes} minutes.
 * Each block starts every {blockEachMinutes} minutes from the start of the range.
 * Then it groups the resulting blocks by the date of their start time.
 *
 * Example:
 *   from = 2025-01-10T09:00:00
 *   to = 2025-01-10T17:00:00
 *   lengthInMinutes = 30
 *   blockEachMinutes = 15
 *
 * Will yield blocks (grouped under 2025-01-10):
 *   09:00-09:30
 *   09:15-09:45
 *   09:30-10:00
 *   09:45-10:15
 *   ...
 *   16:30-17:00
 */
function mapRangesToBlocks({
    ranges,
    lengthInMinutes,
    blockEachMinutes,
}: MapRangesToBlockParams): BlocksByDay {
    const blocksByDay: BlocksByDay = {};

    const addToBlocksByDay = (range: DateRange) => {
        // Group by the starting day of the block.
        const dayKey = range.start.toFormat('yyyy-MM-dd');

        if (!blocksByDay[dayKey])
            blocksByDay[dayKey] = [];

        blocksByDay[dayKey].push(range);
    };

    for (const range of ranges) {
        // Initial block if the range doesn't start at a multiple of {blockEachMinutes}
        let cursor = range.start;

        const minuteRemainder = cursor.minute % blockEachMinutes;

        if (minuteRemainder !== 0) {
            const blockEnd = cursor.plus({ minutes: lengthInMinutes });

            if (blockEnd <= range.end) {
                addToBlocksByDay({ start: cursor, end: blockEnd });
                cursor = cursor.plus({ minute: blockEachMinutes - minuteRemainder });
            }
        }

        // Generate blocks until the start of the block (cursor) reaches the range end.
        while (cursor < range.end) {
            const blockEnd = cursor.plus({ minutes: lengthInMinutes });

            // End the while so it doesn't extend beyond the range's 'end'.
            if (blockEnd > range.end)
                break;

            addToBlocksByDay({ start: cursor, end: blockEnd });

            // Move the cursor forward by {blockEachMinutes} to generate the next overlapping block.
            cursor = cursor.plus({ minutes: blockEachMinutes });
        }
    }

    return blocksByDay;
}
