import type { CountryCode, LocaleCode } from ':utils/i18n';
import { compareStringsAscii, toUnique } from ':utils/common';
import { z } from 'zod';
import { preciseDivisionByPowerOfTen, preciseMultiplicationByPowerOfTen } from ':utils/math';

// Internally, we count in the 0.01 units (usually cents) because of precision. It is required by Stripe in this format, too.
const DECIMAL_AMOUNT_POWER = 2;
// The precision is the same as the power. I.e., we want the numbers to be precise up to the last cent.
const DECIMAL_AMOUNT_PRECISION = DECIMAL_AMOUNT_POWER;

export function amountToDecimal(input: number): number {
    return preciseDivisionByPowerOfTen(input, DECIMAL_AMOUNT_POWER, DECIMAL_AMOUNT_PRECISION);
}

export function amountFromDecimal(float: number): number {
    return preciseMultiplicationByPowerOfTen(float, DECIMAL_AMOUNT_POWER, DECIMAL_AMOUNT_PRECISION);
}

/**
 * Round money in the base unit (e.g., cents).
 * Use this whenever performing operations with some non-integer (or when dividing in general).
 * It's not needed for basic operations (+, -, *) when the other number is also an integer.
 */
export const roundMoneyAmount = Math.round;

export type CurrencyId = string;
export const zCurrencyId = z.string().transform(value => value as CurrencyId);

/** In float. */
export type TaxRate = z.infer<typeof zTaxRate>;
/** In float. */
export const zTaxRate = z.number().min(0).max(1);

const ALL_CURRENCIES_MAP: Map<CurrencyId, Currency> = new Map;
/** The order matters! */
const ALL_CURRENCIES_ARRAY: Currency[] = [];
const CURRENCIES_BY_STRIPE_COUNTRY: Map<CountryCode, Currency> = new Map;
const CURRENCIES_BY_COUNTRY: Map<CountryCode, Currency> = new Map;

/**
 * Should be singleton (for each currency id).
 */
export class Currency {
    private constructor(
        /** The identifier of the currency. Shouldn't be used as a label! */
        readonly id: CurrencyId,
        /** Converts this currency to the default currency. I.e., <amount in this currency> * exchangeRate = <amount in default currency>. */
        readonly exchangeRate: number,
        readonly minimalAmount: number,
        readonly maximalAmount: number,
        /** The default country. Used for flag and stuff. */
        readonly country: CountryCode,
        /**
         * Countries for which Stripe supports this currency (as default currency for connected accounts).
         * https://docs.stripe.com/connect/payouts-connected-accounts
         */
        readonly stripeCountries: CountryCode[],
        /** All countries that use this currency, but may not be supported by Stripe. */
        readonly allCountries: CountryCode[],
    ) {}

    static define(id: CurrencyId, exchangeRate: number, minimalAmount: number, maximalAmount: number, country: CountryCode, stripeCountries: CountryCode[], otherCountries: CountryCode[] = []): void {
        if (ALL_CURRENCIES_MAP.has(id)) {
            console.warn(`Currency with id ${id} already exists.`);
            return;
        }

        const allCountries = toUnique([ country, ...stripeCountries, ...otherCountries ]);
        const currency = new Currency(
            id,
            exchangeRate,
            minimalAmount,
            maximalAmount,
            country,
            stripeCountries,
            allCountries,
        );
        ALL_CURRENCIES_MAP.set(id, currency);
        ALL_CURRENCIES_ARRAY.push(currency);
        stripeCountries.forEach(country => CURRENCIES_BY_STRIPE_COUNTRY.set(country, currency));
        allCountries.forEach(country => CURRENCIES_BY_COUNTRY.set(country, currency));
    }

    static get(id: CurrencyId): Currency {
        const currency = ALL_CURRENCIES_MAP.get(id);
        if (!currency)
            throw new Error(`Currency with id ${id} not found.`);

        return currency;
    }

    /** Returns a user-friendly label of the currency. */
    static label(id: CurrencyId): string {
        // Right now, the label is always equal to the id. However, this might change in the future. It's important to keep this in a separate method to signal different semantics.
        return id;
    }

    /**
     * This uses only hard-coded (so probably deprecated) exchange rates.
     * For better results, create a custom {@link CurrencyConverter}.
     * Exchange rates updated: 2025-03-08.
     */
    static convertToDefault(amount: number, id: CurrencyId): number {
        return defaultCurrencyConverter.convertToDefault(amount, id);
    }

    displayFull(amount: number, locale: LocaleCode, compact?: boolean): string {
        const displayValue = amountToDecimal(amount);

        return Intl.NumberFormat(locale, {
            style: 'currency',
            currency: this.id,
            currencyDisplay: 'narrowSymbol',
            minimumFractionDigits: compact ? 0 : undefined,
        }).format(displayValue);
    }

    displaySymbol(locale: LocaleCode): string {
        return Intl.NumberFormat(locale, {
            style: 'currency',
            currency: this.id,
            currencyDisplay: 'narrowSymbol',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(0).replace(/\d/g, '').trim();
    }

    isSymbolBefore(locale: LocaleCode): boolean {
        const cached = this.isSymbolBeforeCache.get(locale);
        if (cached !== undefined)
            return cached;

        const computed = this.computeIsSymbolBefore(locale);
        this.isSymbolBeforeCache.set(locale, computed);

        return computed;
    }

    private isSymbolBeforeCache: Map<LocaleCode, boolean> = new Map;

    private computeIsSymbolBefore(locale: LocaleCode): boolean {
        const parts = Intl.NumberFormat(locale, {
            style: 'currency',
            currency: this.id,
            currencyDisplay: 'code',
        }).formatToParts(69);

        return parts[0].type === 'currency';
    }

    static displayAmount(amount: number, locale: LocaleCode, compact?: boolean): string {
        const displayValue = amountToDecimal(amount);

        return Intl.NumberFormat(locale, {
            minimumFractionDigits: compact ? 0 : undefined,
        }).format(displayValue);
    }
}

// Don't forget all the non-sovereign territories (mostly islands).
Currency.define('USD', 1, 50, 99999999, 'US', [ 'US' ], [ 'AS', 'GU', 'MP', 'PR', 'UM', 'VI' ]);
Currency.define('EUR', 1.048, 50, 99999999, 'EU', [ 'AT', 'BE', 'CY', 'DE', 'EE', 'ES', 'FI', 'FR', 'GR', 'HR', 'IE', 'IT', 'LT', 'LU', 'LV', 'MT', 'NL', 'PT', 'SI', 'SK' ], [
    'AX', // Finland
    'GF', 'PF', 'TF', 'GP', 'MQ', 'YT', 'NC', 'RE', 'BL', 'MF', 'PM', 'WF', // France
    'AW', 'BQ', 'CW', 'SX', // Netherlands
]);
Currency.define('GBP', 1.264, 30, 99999999, 'GB', [ 'GB', 'GI' ], [ 'GG', 'IM', 'JE', 'AI', 'BM', 'IO', 'KY', 'FK', 'MS', 'PN', 'SH', 'GS', 'TC', 'VG' ]);
Currency.define('CAD', 0.70, 50, 99999999, 'CA', [ 'CA' ]);
Currency.define('CHF', 1.12, 50, 99999999, 'CH', [ 'CH', 'LI' ]);
Currency.define('AUD', 0.636, 50, 99999999, 'AU', [ 'AU' ], [ 'CX', 'CC', 'HM', 'NF' ]);
Currency.define('NZD', 0.575, 50, 99999999, 'NZ', [ 'NZ' ], [ 'CK', 'NU', 'PN', 'TK' ]);
Currency.define('SEK', 0.094, 300, 99999999, 'SE', [ 'SE' ]);
Currency.define('CZK', 0.044, 1500, 99999999, 'CZ', [ 'CZ' ]);
Currency.define('JPY', 0.0067, 5000, 99999999, 'JP', [ 'JP' ]);
Currency.define('AED', 0.27, 200, 99999999, 'AE', [ 'AE' ]);
Currency.define('DKK', 0.140, 250, 99999999, 'DK', [ 'DK' ]);
Currency.define('NOK', 0.09, 300, 99999999, 'NO', [ 'NO', 'BV', 'SJ' ]);
Currency.define('PLN', 0.26, 200, 99999999, 'PL', [ 'PL' ]);

// These countries might not have EUR but they are kinda close. So, if we don't support their currency, we should use EUR.
const euLikeCountries: CountryCode[] = [
    'NO', 'BV', 'SJ', // Norway
    'BE', 'CZ', 'EL', 'LT', 'PT', 'BG', 'ES', 'LU', 'RO', 'FR', 'HU', 'SI', 'DK', 'HR', 'MT', 'SK', 'DE', 'IT', 'NL', 'FI', 'EE', 'CY', 'AT', 'SE', 'IE', 'LV', 'PL', 'EF', 'IS', 'LI', 'CH', 'UK', 'BA', 'ME', 'MD', 'MK', 'AL', 'RS', 'TR', 'UA', 'XE', 'GE', 'RU',
];

// export const sepaCountries: CountryCode[] = [
//     'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR',
//     'DE', 'GR', 'HU', 'IS', 'IE', 'IT', 'LV', 'LI', 'LT', 'LU',
//     'MT', 'MC', 'NL', 'NO', 'PL', 'PT', 'RO', 'SM', 'SK', 'SI',
//     'ES', 'SE', 'CH', 'GB', 'VA',
// ];
// no currently has automatic referral enabled because of Stigg limitations
export const sepaCountries: CountryCode[] = [];

// TODO Stripe also supports these for connected accounts - we should add their currencies:
// BGN: BG (Bulgaria)
// BRL: BR (Brazil)
// HKD: HK (Hong Kong)
// HUF: HU (Hungary) - This one is a little bit tricky because the amount must be always divisible by 100 (in cents). So, "10.45" forints must be sent as "1000". That's why we don't support it yet.
// INR: IN (India)
// MXN: MX (Mexico)
// MYR: MY (Malaysia)
// RON: RO (Romania)
// SGD: SG (Singapore)
// THB: TH (Thailand)

export type Money = {
    amount: number;
    currency: CurrencyId;
};

export function toMoney(amount: number, currency: CurrencyId): Money {
    return {
        amount,
        currency,
    };
}

export function getAllCurrencies(): CurrencyId[] {
    return ALL_CURRENCIES_ARRAY.map(currency => currency.id);
}

export function compareCurrencies(a: CurrencyId, b: CurrencyId): number {
    return compareStringsAscii(a, b);
}

export function getDefaultCurrency(): CurrencyId {
    return ALL_CURRENCIES_ARRAY[0].id;
}

export function getDefaultCurrencyForCountry(country: CountryCode): CurrencyId {
    const currency = CURRENCIES_BY_COUNTRY.get(country);
    if (currency)
        return currency.id;

    console.warn(`No currency found for country ${country}.`);

    if (euLikeCountries.includes(country))
        return Currency.get('EUR').id;

    return getDefaultCurrency();
}

export function timesBy(money: Money, multiplier: number): Money {
    return {
        amount: money.amount * multiplier,
        currency: money.currency,
    };
}

export function divideBy(money: Money, divisor: number): Money {
    return {
        amount: money.amount / divisor,
        currency: money.currency,
    };
}

export function isUnderMinimalAmount(money: Money): boolean {
    return money.amount < Currency.get(money.currency).minimalAmount;
}

export class CurrencyConverter {
    private constructor(
        private readonly exchangeRates: Map<CurrencyId, number>,
        readonly isHardcoded: boolean,
    ) {}

    static create(map: Map<CurrencyId, number>): CurrencyConverter {
        return new CurrencyConverter(map, false);
    }

    static createHardcoded(): CurrencyConverter {
        const map = new Map(ALL_CURRENCIES_ARRAY.map(currency => [ currency.id, currency.exchangeRate ]));
        return new CurrencyConverter(map, true);
    }

    convertToDefault(amount: number, fromId: CurrencyId): number {
        return fromId === getDefaultCurrency() ? amount : roundMoneyAmount(amount * this.exchangeRates.get(fromId)!);
    }

    convertTo(amount: number, fromId: CurrencyId, toId: CurrencyId): number {
        if (fromId === toId)
            return amount;

        return roundMoneyAmount(amount * this.exchangeRates.get(fromId)! / this.exchangeRates.get(toId)!);
    }
}

const defaultCurrencyConverter = CurrencyConverter.createHardcoded();

/**
 * The zero tax rate is supposed to be common for all countries so it is a natural fit for a default tax rate.
 */
export const zeroTaxRate = 0;

export function taxRateIsZero(taxRate: TaxRate): boolean {
    return taxRate === zeroTaxRate;
}

export const taxRateIsInclusive = true;

const TAX_RATE_PERCENT_POWER = 2;
const TAX_RATE_PERCENT_PRECISION = 2;

export function taxRateToPercent(taxRate: TaxRate): number {
    return preciseMultiplicationByPowerOfTen(taxRate, TAX_RATE_PERCENT_POWER, TAX_RATE_PERCENT_PRECISION);
}

export function taxRateFromPercent(percent: number): TaxRate {
    return preciseDivisionByPowerOfTen(percent, TAX_RATE_PERCENT_POWER, TAX_RATE_PERCENT_PRECISION);
}

export function taxRateLabel(taxRate: TaxRate): string {
    return `${taxRateToPercent(taxRate)} %`;
}

// These computations are based on the fact that up to the max safe integer (2^53 - 1, which is a little over 9e15), all integer operations (addition, subtraction, multiplication) are precise.
// So we don't need to round them after that.
// However, division might not be precise, even if the result should be an integer. And there is really no way of knowing beforehand. So, if we ever need to divide some money, we should round it afterwards.

export function computeTax(amount: number, tax: TaxRate): { withoutTax: number, withTax: number, difference: number } {
    if (taxRateIsInclusive) {
        const withoutTax = roundMoneyAmount(amount / (1 + tax));
        const withTax = amount;
        const difference = withTax - withoutTax;

        return { withoutTax, withTax, difference };
    }
    else {
        const withoutTax = amount;
        const withTax = roundMoneyAmount(amount * (1 + tax));
        const difference = withTax - withoutTax;

        return { withoutTax, withTax, difference };
    }
}

export function getAmountWithoutTax(amount: number, tax: TaxRate): number {
    return taxRateIsInclusive ? roundMoneyAmount(amount / (1 + tax)) : amount;
}

export function getAmountWithTax(amount: number, tax: TaxRate): number {
    return taxRateIsInclusive ? amount : roundMoneyAmount(amount * (1 + tax));
}

export function getTaxAmount(amount: number, tax: TaxRate): number {
    return taxRateIsInclusive
        ? (amount - roundMoneyAmount(amount / (1 + tax)))
        : (roundMoneyAmount(amount * (1 + tax)) - amount);
}
