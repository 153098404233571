import { getCommonProps, pathProps, type IconProps } from './common';

type FlowlanceIconProps = IconProps & Readonly<{
    cut?: boolean;
}>;

function FlowlanceIcon({ size, className, cut }: FlowlanceIconProps) {
    const { width, height } = getCommonProps({ size });
    const src = cut ? '/static/flowlance-icon-cut.svg' : '/static/flowlance-icon.svg';
    return <img src={src} style={{ width, height }} className={className} />;
}

type FlowlanceBannerProps = Readonly<{
    width?: string | number;
    height?: string | number;
    className?: string;
}>;

function FlowlanceBanner({ width, height, className }: FlowlanceBannerProps) {
    return <img src='/static/flowlance-banner.svg' style={{ width: width ?? '96px', height: height ?? '24px' }} className={className} />;
}

export const flowlanceIcons = {
    FlowlanceIcon,
    FlowlanceBanner,
};

const FacebookIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path d='M18 9a9 9 0 1 0-11.174 8.735v-5.984H4.971v-2.75h1.855V7.816c0-3.064 1.387-4.483 4.394-4.483.57 0 1.554.112 1.957.223V6.05a12 12 0 0 0-1.04-.033c-1.476 0-2.046.559-2.046 2.012v.973h2.94l-.505 2.75H10.09v6.184c4.456-.539 7.91-4.333 7.91-8.934z' fill='#0866ff' />
    <path d='M12.525 11.75 13.03 9h-2.94v-.973c0-1.453.57-2.012 2.047-2.012.458 0 .827.011 1.04.034V3.556c-.403-.112-1.387-.224-1.957-.224-3.008 0-4.394 1.42-4.394 4.483V9H4.97v2.75h1.856v5.985a9 9 0 0 0 3.264.199V11.75z' fill='#fff' />
</svg>);

const InstagramIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path d='M9 1.622c2.405 0 2.69.01 3.635.052.88.039 1.354.187 1.67.31.418.161.72.358 1.034.671.316.317.51.615.671 1.034.123.316.27.794.31 1.67.042.949.052 1.234.052 3.635 0 2.404-.01 2.69-.052 3.635-.04.879-.187 1.353-.31 1.67-.162.418-.358.72-.671 1.033a2.8 2.8 0 0 1-1.034.672c-.316.123-.794.27-1.67.309-.949.042-1.234.053-3.635.053-2.404 0-2.69-.01-3.635-.053-.879-.039-1.353-.186-1.67-.31a2.8 2.8 0 0 1-1.033-.67 2.8 2.8 0 0 1-.672-1.034c-.123-.317-.27-.795-.31-1.67-.041-.95-.052-1.234-.052-3.635 0-2.405.01-2.69.053-3.635.039-.88.186-1.354.31-1.67.161-.419.358-.72.67-1.034.317-.316.616-.51 1.034-.671.317-.123.795-.271 1.67-.31.946-.042 1.23-.052 3.635-.052M9 0C6.557 0 6.251.01 5.291.053 4.335.095 3.678.25 3.108.471a4.4 4.4 0 0 0-1.596 1.04 4.4 4.4 0 0 0-1.04 1.593C.25 3.678.095 4.332.053 5.288.011 6.252 0 6.558 0 9.001s.011 2.749.053 3.709c.042.956.197 1.613.419 2.183a4.4 4.4 0 0 0 1.04 1.596 4.4 4.4 0 0 0 1.593 1.037c.573.221 1.227.376 2.183.418.96.042 1.265.053 3.709.053s2.749-.01 3.709-.053c.956-.042 1.613-.197 2.183-.418a4.4 4.4 0 0 0 1.592-1.037 4.4 4.4 0 0 0 1.037-1.593c.222-.573.377-1.227.419-2.183.042-.96.052-1.265.052-3.709s-.01-2.749-.052-3.709c-.043-.956-.197-1.613-.419-2.183a4.2 4.2 0 0 0-1.03-1.6A4.4 4.4 0 0 0 14.896.477C14.323.255 13.669.1 12.713.058 11.749.012 11.443.001 9 .001' fill='#000' />
    <path d='M9 4.377a4.624 4.624 0 0 0 0 9.246 4.624 4.624 0 0 0 0-9.246m0 7.622A3 3 0 1 1 9.001 6a3 3 0 0 1 0 5.999m5.884-7.804a1.08 1.08 0 1 1-2.159 0 1.08 1.08 0 0 1 2.16 0' fill='#000' />
</svg>);

const TwitterIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path d='M13.745 1.429h2.53l-5.527 6.317 6.502 8.597h-5.091L8.17 11.129l-4.563 5.214H1.076l5.912-6.757L.75 1.429h5.221l3.605 4.765zm-.888 13.4h1.402L5.209 2.863H3.705z' fill='#000' />
</svg>);

const LinkedInIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path d='M16.671.001H1.33A1.33 1.33 0 0 0 0 1.33v15.342A1.33 1.33 0 0 0 1.33 18h15.34A1.33 1.33 0 0 0 18 16.673V1.33A1.33 1.33 0 0 0 16.671 0M5.365 15.334H2.66V6.738h2.706zM4.01 5.547a1.554 1.554 0 1 1 1.563-1.552A1.53 1.53 0 0 1 4.01 5.547m11.33 9.795h-2.705v-4.696c0-1.385-.589-1.813-1.349-1.813-.802 0-1.59.605-1.59 1.848v4.66H6.99V6.746h2.603v1.19h.035c.261-.528 1.176-1.432 2.572-1.432 1.51 0 3.141.897 3.141 3.521z' fill='#0a66c2' />
</svg>);

const YouTubeIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path d='M17.642 4.64a2.26 2.26 0 0 0-1.592-1.602c-1.407-.379-7.032-.379-7.032-.379s-5.625 0-7.032.379A2.26 2.26 0 0 0 .395 4.639C.019 6.053.019 9 .019 9s0 2.948.376 4.362a2.26 2.26 0 0 0 1.591 1.601c1.407.379 7.032.379 7.032.379s5.625 0 7.032-.379a2.26 2.26 0 0 0 1.592-1.601c.376-1.414.376-4.361.376-4.361s0-2.947-.376-4.36' fill='#ff0302' />
    <path d='M7.177 11.677V6.324l4.705 2.677z' fill='#fefefe' />
</svg>);

const PinterestIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path d='M9 18A9 9 0 1 0 9 .002a9 9 0 0 0 0 18' fill='#fff' />
    <path d='M9 .001a9 9 0 0 0-9 9 9 9 0 0 0 5.719 8.385c-.082-.711-.148-1.808.03-2.585.162-.704 1.051-4.474 1.051-4.474s-.266-.541-.266-1.334c0-1.251.726-2.185 1.63-2.185.77 0 1.14.578 1.14 1.267 0 .77-.489 1.926-.748 3-.215.896.452 1.63 1.333 1.63 1.6 0 2.83-1.69 2.83-4.12 0-2.155-1.548-3.658-3.763-3.658-2.563 0-4.067 1.918-4.067 3.903 0 .77.297 1.6.667 2.052.074.089.081.17.06.26-.068.28-.223.896-.253 1.022-.037.163-.133.2-.303.118-1.126-.526-1.83-2.163-1.83-3.489 0-2.837 2.06-5.444 5.948-5.444 3.118 0 5.548 2.222 5.548 5.2 0 3.104-1.956 5.6-4.667 5.6-.91 0-1.77-.474-2.059-1.037l-.563 2.14c-.2.786-.748 1.763-1.118 2.363.844.26 1.733.4 2.666.4a9 9 0 0 0 9-9A8.98 8.98 0 0 0 9 .002' fill='#e60019' />
</svg>);

const MediumIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path d='M18 8.871c0 2.385-.4 4.319-.893 4.319s-.892-1.934-.892-4.319.4-4.318.892-4.318c.493 0 .893 1.933.893 4.318m-2.278 0c0 2.662-1.136 4.821-2.538 4.821s-2.538-2.159-2.538-4.82 1.136-4.821 2.538-4.821 2.538 2.158 2.538 4.82m-5.569-.001c0 2.829-2.273 5.121-5.076 5.121C2.273 13.991 0 11.698 0 8.871S2.273 3.75 5.077 3.75s5.076 2.293 5.076 5.12' fill='#000' />
</svg>);

const ThreadsIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path d='M13.27 8.343a6 6 0 0 0-.237-.107c-.139-2.56-1.537-4.025-3.886-4.04h-.032c-1.405 0-2.573.6-3.293 1.69l1.292.887c.537-.815 1.38-.99 2.001-.99h.022c.773.006 1.357.23 1.735.67.274.318.458.76.55 1.316a10 10 0 0 0-2.22-.107c-2.234.129-3.67 1.431-3.573 3.241.049.918.506 1.708 1.288 2.224.66.436 1.511.649 2.396.6 1.167-.063 2.084-.509 2.723-1.324.485-.618.792-1.42.928-2.43.556.335.969.777 1.197 1.309.387.903.41 2.387-.801 3.597-1.061 1.06-2.337 1.52-4.265 1.533-2.138-.015-3.755-.701-4.807-2.038-.984-1.252-1.493-3.06-1.512-5.373.019-2.314.528-4.122 1.512-5.374C5.34 2.291 6.957 1.605 9.095 1.59c2.154.016 3.8.705 4.891 2.048.535.659.939 1.487 1.205 2.453l1.514-.404c-.323-1.189-.83-2.213-1.52-3.063C13.784.902 11.737.02 9.1.001h-.01C6.458.019 4.434.905 3.074 2.633 1.864 4.171 1.24 6.312 1.22 8.994v.013c.021 2.683.645 4.823 1.855 6.361C4.434 17.097 6.458 17.982 9.09 18h.01c2.34-.016 3.99-.628 5.349-1.986 1.777-1.776 1.724-4.002 1.138-5.369-.42-.98-1.222-1.776-2.318-2.302m-4.04 3.799c-.98.055-1.996-.384-2.047-1.325-.037-.698.497-1.476 2.106-1.57q.276-.015.543-.015c.584 0 1.131.057 1.628.166-.185 2.315-1.273 2.691-2.23 2.744' fill='#000' />
</svg>);

const WhatsAppIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path d='m0 18 1.266-4.622A8.9 8.9 0 0 1 .076 8.92C.077 4.002 4.078.001 8.994.001a8.86 8.86 0 0 1 6.31 2.616 8.87 8.87 0 0 1 2.61 6.31c-.003 4.918-4.004 8.919-8.92 8.919a8.9 8.9 0 0 1-4.266-1.086zm4.948-2.855c1.257.747 2.457 1.194 4.044 1.194 4.086 0 7.414-3.325 7.416-7.413a7.41 7.41 0 0 0-7.41-7.42c-4.089 0-7.415 3.326-7.417 7.414 0 1.668.489 2.918 1.31 4.225l-.75 2.736zm8.54-4.098c-.055-.093-.204-.148-.427-.26s-1.319-.65-1.524-.725c-.204-.074-.352-.112-.501.112-.149.222-.576.725-.706.873s-.26.168-.483.056-.941-.347-1.792-1.106c-.663-.591-1.11-1.321-1.24-1.545-.13-.222-.014-.343.097-.454.1-.1.223-.26.335-.39s.15-.223.225-.372c.074-.148.037-.279-.019-.39-.056-.112-.502-1.209-.687-1.655-.181-.434-.365-.376-.502-.383l-.427-.007a.82.82 0 0 0-.594.279c-.204.223-.78.762-.78 1.859s.799 2.157.91 2.305 1.57 2.4 3.806 3.366c.532.23.948.366 1.271.47a3.1 3.1 0 0 0 1.404.088c.428-.064 1.318-.54 1.504-1.06.186-.521.186-.968.13-1.06' fill='#25d366' />
</svg>);

const TikTokIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path d='M12.883 6.498a7.02 7.02 0 0 0 4.107 1.32V4.86q-.437 0-.863-.091v2.328a7.02 7.02 0 0 1-4.107-1.319v6.036c0 3.019-2.439 5.466-5.447 5.466a5.4 5.4 0 0 1-3.032-.924A5.42 5.42 0 0 0 7.435 18c3.009 0 5.448-2.447 5.448-5.466zm1.064-2.984a4.13 4.13 0 0 1-1.064-2.413v-.38h-.818a4.14 4.14 0 0 0 1.882 2.793M5.443 14.04a2.5 2.5 0 0 1-.508-1.514 2.496 2.496 0 0 1 3.248-2.383V7.118a5.5 5.5 0 0 0-.862-.05v2.353a2.496 2.496 0 0 0-3.248 2.383c0 .977.557 1.823 1.37 2.235' fill='#ff004f' />
    <path d='M12.02 5.778a7.02 7.02 0 0 0 4.107 1.319V4.769a4.1 4.1 0 0 1-2.18-1.255A4.14 4.14 0 0 1 12.064.721H9.918v11.812a2.496 2.496 0 0 1-2.492 2.492 2.49 2.49 0 0 1-1.983-.986 2.5 2.5 0 0 1-1.37-2.234A2.496 2.496 0 0 1 7.32 9.422V7.068c-2.955.061-5.332 2.485-5.332 5.465 0 1.488.592 2.837 1.552 3.823.867.584 1.91.924 3.033.924 3.008 0 5.447-2.447 5.447-5.466z' fill='#000' />
    <path d='M16.127 4.77v-.63a4.1 4.1 0 0 1-2.18-.625 4.1 4.1 0 0 0 2.18 1.254M12.065.72a4 4 0 0 1-.045-.339v-.38H9.056v11.813a2.496 2.496 0 0 1-2.492 2.492 2.5 2.5 0 0 1-1.121-.267c.455.6 1.174.987 1.984.987a2.496 2.496 0 0 0 2.491-2.492V.72zM7.32 7.069v-.67a5.5 5.5 0 0 0-.747-.05c-3.009 0-5.448 2.447-5.448 5.466 0 1.893.959 3.56 2.416 4.542a5.46 5.46 0 0 1-1.553-3.822c0-2.98 2.377-5.404 5.332-5.466' fill='#00f2ea' />
</svg>);

const TelegramIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path d='M9 18A9 9 0 1 0 9 0a9 9 0 0 0 0 18' fill='url(#telegram-logo)' />
    <path fillRule='evenodd' clipRule='evenodd' d='M4.074 8.905a428 428 0 0 1 5.249-2.26c2.499-1.04 3.018-1.221 3.357-1.227a.6.6 0 0 1 .349.105.38.38 0 0 1 .128.243c.012.07.027.23.015.355-.136 1.423-.722 4.876-1.02 6.47-.126.675-.374.9-.615.923-.523.048-.92-.346-1.426-.678-.792-.519-1.24-.842-2.008-1.349-.889-.585-.313-.907.194-1.433.132-.138 2.435-2.232 2.48-2.422.005-.024.01-.112-.042-.16-.053-.046-.13-.03-.187-.017q-.119.027-3.796 2.509-.538.37-.976.36c-.321-.007-.94-.181-1.399-.33-.563-.184-1.011-.28-.972-.592q.03-.243.67-.497' fill='#fff' />
    <defs>
        <linearGradient id='telegram-logo' x1='9' y1='0' x2='9' y2='18' gradientUnits='userSpaceOnUse'>
            <stop stopColor='#2aabee' />
            <stop offset='1' stopColor='#229ed9' />
        </linearGradient>
    </defs>
</svg>);

const RedditIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path d='M18 9a9 9 0 0 1-9 9 9 9 0 0 1-9-9 9 9 0 0 1 18 0' fill='#ff4500'/>
    <path d='M15.003 9a1.3 1.3 0 0 1-.192.703 1.33 1.33 0 0 1-.538.494l.006.099a2 2 0 0 1 0 .198l-.006.099c0 2.016-2.349 3.654-5.247 3.654S3.78 12.609 3.78 10.593l-.006-.099a2 2 0 0 1 0-.198l.006-.099a1.32 1.32 0 0 1-.717-.839 1.31 1.31 0 0 1 .175-1.089 1.31 1.31 0 0 1 .943-.571 1.31 1.31 0 0 1 1.047.349 6.5 6.5 0 0 1 .808-.466 6.45 6.45 0 0 1 2.702-.641l.667-3.124a.27.27 0 0 1 .121-.177.27.27 0 0 1 .212-.039l2.205.441a.9.9 0 0 1 1.658.64.9.9 0 0 1-1.775-.092L9.9 4.185l-.585 2.808q.462.01.917.086a6.4 6.4 0 0 1 1.75.559 6.4 6.4 0 0 1 .798.462 1.3 1.3 0 0 1 .661-.342 1.3 1.3 0 0 1 .741.072 1.3 1.3 0 0 1 .583.464 1.3 1.3 0 0 1 .238.706m-8.932 1.244a.9.9 0 0 0 .487.487.9.9 0 0 0 .345.069.9.9 0 1 0-.832-.556m5.155 2.16a.243.243 0 0 0-.167-.417.3.3 0 0 0-.179.063 3 3 0 0 1-.428.255 3 3 0 0 1-.465.18 2.9 2.9 0 0 1-.988.114 3 3 0 0 1-.497-.02 3 3 0 0 1-.487-.103 3 3 0 0 1-.888-.443.243.243 0 0 0-.342.342 3.44 3.44 0 0 0 2.222.692q.298.012.592-.026a3.5 3.5 0 0 0 1.131-.351q.265-.136.502-.315zm-.165-1.533a.9.9 0 0 0 .656-.273.9.9 0 0 0 .195-.306.9.9 0 0 0 .058-.356.9.9 0 1 0-.9.9z' fill='#fff'/>
</svg>);

const SlackIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path d='M6.6 0a1.8 1.8 0 0 0-1.8 1.803 1.8 1.8 0 0 0 1.8 1.803h1.8V1.804A1.8 1.8 0 0 0 6.6 0q.001 0 0 0m0 4.81H1.8A1.8 1.8 0 0 0 0 6.613a1.8 1.8 0 0 0 1.8 1.804h4.8a1.8 1.8 0 0 0 1.8-1.803A1.8 1.8 0 0 0 6.6 4.81' fill='#36c5f0'/>
    <path d='M18 6.613a1.8 1.8 0 1 0-3.6 0v1.804h1.8A1.8 1.8 0 0 0 18 6.613m-4.8 0v-4.81A1.8 1.8 0 0 0 11.4 0a1.8 1.8 0 0 0-1.8 1.803v4.81a1.8 1.8 0 0 0 1.8 1.804 1.8 1.8 0 0 0 1.8-1.804' fill='#2eb67d'/>
    <path d='M11.4 18a1.8 1.8 0 0 0 1.8-1.803 1.8 1.8 0 0 0-1.8-1.803H9.6v1.803A1.8 1.8 0 0 0 11.4 18m0-4.81h4.8a1.8 1.8 0 0 0 1.8-1.803 1.8 1.8 0 0 0-1.8-1.804h-4.8a1.8 1.8 0 0 0-1.8 1.803 1.8 1.8 0 0 0 1.8 1.804' fill='#ecb22e'/>
    <path d='M0 11.422a1.8 1.8 0 1 0 3.6 0V9.619H1.8A1.8 1.8 0 0 0 0 11.422m4.8 0v4.81a1.8 1.8 0 0 0 1.8 1.804 1.8 1.8 0 0 0 1.8-1.803v-4.809a1.8 1.8 0 1 0-3.6-.001' fill='#e01e5a'/>
</svg>);

const DiscordIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path d='M15.248 3.321a15 15 0 0 0-3.714-1.143 10 10 0 0 0-.476.967 13.8 13.8 0 0 0-4.121 0 10 10 0 0 0-.476-.967 15 15 0 0 0-3.716 1.145C.395 6.801-.242 10.192.076 13.535a15 15 0 0 0 4.555 2.286 11 11 0 0 0 .975-1.573 9.7 9.7 0 0 1-1.536-.733c.129-.093.255-.19.377-.283a10.7 10.7 0 0 0 9.106 0q.185.153.377.283a9.7 9.7 0 0 1-1.539.735 11 11 0 0 0 .975 1.571 14.9 14.9 0 0 0 4.557-2.285c.374-3.876-.639-7.236-2.676-10.215M6.01 11.479c-.888 0-1.621-.806-1.621-1.797s.708-1.804 1.618-1.804 1.638.813 1.623 1.804-.715 1.797-1.62 1.797m5.98 0c-.889 0-1.62-.806-1.62-1.797s.708-1.804 1.62-1.804 1.634.813 1.618 1.804-.714 1.797-1.618 1.797' fill='#5865f2'/>
</svg>);

const SubstackIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path d='M1.401 0h15.766v2.21H1.401zm0 8.062h15.766V18l-7.885-4.422L1.401 18zm0-4.031h15.766v2.21H1.401z' fill='#ff681a'/>
</svg>);

const SnapchatIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path d='M18 9a9 9 0 0 1-9 9 9 9 0 0 1-9-9 9 9 0 0 1 18 0' fill='#fffc00'/>
    <path d='M7.137 14.555c-.633-.457-1.125-.703-2.32-.492-.176.035-.492.141-.527-.176-.07-.246-.07-.633-.246-.668-1.09-.141-1.477-.352-1.652-.563-.07-.07-.105-.246.035-.281 1.863-.352 2.742-2.145 2.918-2.496.211-.527-.141-.844-.809-1.055-.316-.105-.844-.246-.844-.598 0-.176.176-.281.352-.352.141-.035.281-.07.422 0 .422.176.809.246 1.02.07 0-.949-.246-2.145.141-3.129.457-1.09 1.582-2.074 3.375-2.074s2.918.984 3.375 2.074c.387.984.141 2.18.141 3.129.211.176.598.105 1.02-.07.141-.07.281-.035.422 0 .176.07.352.176.352.352 0 .352-.527.492-.844.598-.668.211-1.02.527-.809 1.055.176.352 1.055 2.145 2.918 2.496.141.035.105.211.035.281-.176.211-.563.422-1.652.563-.176.035-.176.422-.246.668-.035.316-.352.211-.527.176-1.195-.211-1.688.035-2.32.492a2.88 2.88 0 0 1-1.863.703c-.738.035-1.336-.281-1.863-.703z' fill='#fff' stroke='#000' strokeWidth='.3'/>
</svg>);

export const socialIcons = {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedInIcon,
    YouTubeIcon,
    PinterestIcon,
    MediumIcon,
    ThreadsIcon,
    WhatsAppIcon,
    TikTokIcon,
    TelegramIcon,
    RedditIcon,
    SlackIcon,
    DiscordIcon,
    SubstackIcon,
    SnapchatIcon,
};

const StripeIcon = (props: IconProps) => (<svg {...getCommonProps(props)} viewBox='0 0 512 214' preserveAspectRatio='xMidYMid'>
    <path d='M35.982 83.484c0-5.546 4.551-7.68 12.09-7.68 10.808 0 24.461 3.272 35.27 9.103V51.484c-11.804-4.693-23.466-6.542-35.27-6.542C19.2 44.942 0 60.018 0 85.192c0 39.252 54.044 32.995 54.044 49.92 0 6.541-5.688 8.675-13.653 8.675-11.804 0-26.88-4.836-38.827-11.378v33.849c13.227 5.689 26.596 8.106 38.827 8.106 29.582 0 49.92-14.648 49.92-40.106-.142-42.382-54.329-34.845-54.329-50.774m96.142-66.986-34.702 7.395-.142 113.92c0 21.05 15.787 36.551 36.836 36.551 11.662 0 20.195-2.133 24.888-4.693V140.8c-4.55 1.849-27.022 8.391-27.022-12.658V77.653h27.022V47.36h-27.022zm71.112 41.386L200.96 47.36h-30.72v124.444h35.556V87.467c8.39-10.951 22.613-8.96 27.022-7.396V47.36c-4.551-1.707-21.191-4.836-29.582 10.524m38.257-10.524h35.698v124.444h-35.698zm0-10.809 35.698-7.68V0l-35.698 7.538zm109.938 8.391c-13.938 0-22.898 6.542-27.875 11.094l-1.85-8.818h-31.288v165.83l35.555-7.537.143-40.249c5.12 3.698 12.657 8.96 25.173 8.96 25.458 0 48.64-20.48 48.64-65.564-.142-41.245-23.609-63.716-48.498-63.716m-8.533 97.991c-8.391 0-13.37-2.986-16.782-6.684l-.143-52.765c3.698-4.124 8.818-6.968 16.925-6.968 12.942 0 21.902 14.506 21.902 33.137 0 19.058-8.818 33.28-21.902 33.28M512 110.08c0-36.409-17.636-65.138-51.342-65.138-33.85 0-54.33 28.73-54.33 64.854 0 42.808 24.179 64.426 58.88 64.426 16.925 0 29.725-3.84 39.396-9.244v-28.445c-9.67 4.836-20.764 7.823-34.844 7.823-13.796 0-26.027-4.836-27.591-21.618h69.547c0-1.85.284-9.245.284-12.658m-70.258-13.511c0-16.071 9.814-22.756 18.774-22.756 8.675 0 17.92 6.685 17.92 22.756z' fill='#6772e5'/>
</svg>);

const StripeSIcon = (props: IconProps) => (<svg {...getCommonProps(props)} viewBox='0 0 28.87 28.87' preserveAspectRatio='xMidYMid'>
    <rect width='28.87' height='28.87' rx='6.48' ry='6.48' fill='#6772e5' />
    <path d='M13.3 11.2c0-.69.57-1 1.49-1a9.84 9.84 0 0 1 4.37 1.13V7.24a11.6 11.6 0 0 0-4.36-.8c-3.56 0-5.94 1.86-5.94 5 0 4.86 6.68 4.07 6.68 6.17 0 .81-.71 1.07-1.68 1.07A11.06 11.06 0 0 1 9 17.25v4.19a12.19 12.19 0 0 0 4.8 1c3.65 0 6.17-1.8 6.17-5 .03-5.21-6.67-4.27-6.67-6.24z' fill='#fff' fillRule='evenodd' />
</svg>);

const PayPalIcon = (props: IconProps) => (<svg {...getCommonProps(props)} viewBox='0 0 338.667 89.787' preserveAspectRatio='xMidYMid'>
    <path d='M108.294 17.955c-.697 0-1.289.506-1.398 1.195l-8.068 51.165a1.31 1.31 0 0 0 1.294 1.513h9.568c.696 0 1.29-.507 1.398-1.195l2.37-15.025a1.415 1.415 0 0 1 1.398-1.195h8.7c10.163 0 18.791-7.416 20.367-17.465 1.59-10.134-6.328-18.971-17.549-18.993zm9.301 11.422h6.96c5.73 0 7.596 3.381 7.006 7.12-.59 3.747-3.488 6.507-9.03 6.507h-7.085zm45.788 3.478c-2.416.009-5.196.504-8.317 1.804-7.159 2.984-10.597 9.151-12.057 13.647 0 0-4.647 13.717 5.852 21.253 0 0 9.737 7.255 20.698-.447l-.189 1.203a1.31 1.31 0 0 0 1.292 1.513h9.083c.697 0 1.29-.507 1.398-1.195l5.525-35.038a1.31 1.31 0 0 0-1.292-1.515h-9.083c-.697 0-1.29.507-1.398 1.195l-.297 1.886s-3.967-4.333-11.216-4.306zm.297 11.067c1.043 0 1.997.144 2.853.419 3.92 1.258 6.141 5.023 5.498 9.104-.793 5.025-4.914 8.725-10.199 8.725-1.042 0-1.996-.143-2.853-.418-3.918-1.258-6.154-5.023-5.51-9.104.792-5.025 4.926-8.727 10.211-8.727z' fill='#003087'/>
    <path d='M239.094 17.955c-.697 0-1.289.506-1.398 1.195l-8.068 51.165a1.31 1.31 0 0 0 1.294 1.513h9.568c.696 0 1.29-.507 1.398-1.195l2.37-15.025a1.415 1.415 0 0 1 1.398-1.195h8.7c10.163 0 18.79-7.416 20.365-17.465 1.59-10.134-6.326-18.971-17.547-18.993zm9.301 11.422h6.96c5.73 0 7.596 3.381 7.006 7.12-.59 3.747-3.487 6.507-9.03 6.507h-7.085zm45.787 3.478c-2.416.009-5.196.504-8.317 1.804-7.159 2.984-10.597 9.151-12.057 13.647 0 0-4.645 13.717 5.854 21.253 0 0 9.735 7.255 20.697-.447l-.189 1.203a1.31 1.31 0 0 0 1.294 1.513h9.082c.697 0 1.29-.507 1.398-1.195l5.527-35.038a1.31 1.31 0 0 0-1.294-1.515h-9.083c-.697 0-1.29.507-1.398 1.195l-.297 1.886s-3.967-4.333-11.216-4.306m.297 11.067c1.043 0 1.997.144 2.853.419 3.92 1.258 6.141 5.023 5.498 9.104-.793 5.025-4.914 8.725-10.199 8.725-1.042 0-1.996-.143-2.853-.418-3.918-1.258-6.154-5.023-5.51-9.104.792-5.025 4.926-8.727 10.211-8.727z' fill='#0070e0'/>
    <path d='M190.978 34.08c-.72 0-1.232.703-1.012 1.388l9.958 30.901-9.004 14.562c-.437.707.071 1.62.902 1.62h10.642a1.77 1.77 0 0 0 1.513-.854l27.811-46.007a1.063 1.063 0 0 0-.909-1.611h-10.64a1.77 1.77 0 0 0-1.523.869L207.77 53.43l-5.557-18.345a1.42 1.42 0 0 0-1.355-1.006z' fill='#003087'/>
    <path d='M327.791 17.955c-.696 0-1.289.507-1.398 1.195l-8.07 51.163a1.31 1.31 0 0 0 1.294 1.515h9.568c.696 0 1.29-.507 1.398-1.195l8.068-51.165a1.31 1.31 0 0 0-1.292-1.513z' fill='#0070e0'/>
    <path d='M28.528 17.955a2.59 2.59 0 0 0-2.556 2.185l-4.247 26.936a2.59 2.59 0 0 1 2.556-2.185h12.445c12.525 0 23.153-9.137 25.095-21.519a21 21 0 0 0 .245-2.793c-3.183-1.669-6.922-2.624-11.019-2.624z' fill='#001c64'/>
    <path d='M62.066 20.58a21 21 0 0 1-.245 2.793c-1.942 12.382-12.57 21.519-25.095 21.519H24.281a2.59 2.59 0 0 0-2.556 2.185L17.82 71.829l-2.446 15.528a2.1 2.1 0 0 0 2.075 2.43h13.508a2.59 2.59 0 0 0 2.556-2.185l3.558-22.567a2.59 2.59 0 0 1 2.558-2.185h7.953c12.525 0 23.153-9.137 25.095-21.519 1.38-8.788-3.047-16.784-10.61-20.75z' fill='#0070e0'/>
    <path d='M13.182 0a2.59 2.59 0 0 0-2.556 2.183L.026 69.399a2.103 2.103 0 0 0 2.077 2.43h15.72l3.902-24.752 4.247-26.936a2.59 2.59 0 0 1 2.556-2.185h22.52c4.097 0 7.835.956 11.018 2.624C62.284 9.307 52.982 0 40.193 0z' fill='#003087'/>
</svg>);

const PayPalPIcon = (props: IconProps) => (<svg {...getCommonProps(props)} viewBox='0 0 512 512' preserveAspectRatio='xMidYMid'>
    <path fill='#002c8a' d='M377 184.8L180.7 399h-72c-5 0-9-5-8-10l48-304c1-7 7-12 14-12h122c84 3 107 46 92 112z' />
    <path fill='#009be1' d='M380.2 165c30 16 37 46 27 86-13 59-52 84-109 85l-16 1c-6 0-10 4-11 10l-13 79c-1 7-7 12-14 12h-60c-5 0-9-5-8-10l22-143c1-5 182-120 182-120z' />
    <path fill='#001f6b' d='M197 292l20-127a14 14 0 0 1 13-11h96c23 0 40 4 54 11-5 44-26 115-128 117h-44c-5 0-10 4-11 10z' />
</svg>);

export const paymentIcons = {
    StripeIcon,
    StripeSIcon,
    PayPalIcon,
    PayPalPIcon,
};

const GoogleIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.fill} d='M9.18 7.415v3.438h4.9c-.639 2.084-2.381 3.577-4.9 3.577a5.43 5.43 0 1 1 0-10.86c1.349 0 2.579.496 3.528 1.311l2.532-2.534A8.96 8.96 0 0 0 9.18 0C4.207 0 .177 4.03.177 9s4.03 9 9.003 9c7.555 0 9.224-7.067 8.483-10.572z'/>
</svg>);

const OutlookIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.fill} d='M9 .818q-.137 0-.273.029L8.726.845l-6.797 1.36-.001.002a1.366 1.366 0 0 0-1.11 1.338v10.91a1.36 1.36 0 0 0 1.11 1.339l6.797 1.36a1.36 1.36 0 0 0 1.239-.371 1.37 1.37 0 0 0 .399-.965V2.182A1.363 1.363 0 0 0 9 .818m2.727 1.364v2.96l1.364.975 4.048-2.892a1.36 1.36 0 0 0-1.321-1.042zm5.455 2.689L13.486 7.51a.68.68 0 0 1-.79 0l-.969-.691v4.462a3.745 3.745 0 0 1 5.455-.741zm-11.876.72q1.044 0 1.687.925t.643 2.437q0 1.555-.667 2.505-.669.952-1.742.952-1.046 0-1.704-.922-.659-.921-.659-2.402 0-1.564.67-2.528.668-.965 1.772-.965m-.034 1.361q-.479 0-.762.563-.282.565-.282 1.492 0 .941.282 1.489.281.547.739.547.47.001.747-.532.277-.532.277-1.478 0-.985-.27-1.533-.266-.548-.731-.548m9.524 4.093a2.39 2.39 0 0 0-2.386 2.387 2.39 2.39 0 0 0 2.386 2.386 2.39 2.39 0 0 0 2.386-2.387 2.39 2.39 0 0 0-2.387-2.387m-.34.682h.682v1.363h1.364v.682h-2.045z'/>
</svg>);

const AppleIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.fill} d='M12.373.007c-.959.067-2.081.68-2.734 1.481-.597.725-1.087 1.804-.895 2.85 1.048.033 2.131-.596 2.759-1.409.587-.757 1.031-1.828.87-2.921'/>
    <path {...pathProps.fill} d='M16.167 6.037c-.921-1.155-2.216-1.825-3.438-1.825-1.615 0-2.298.772-3.42.772-1.156 0-2.034-.77-3.431-.77-1.372 0-2.831.838-3.757 2.272-1.301 2.018-1.079 5.813 1.03 9.045.755 1.156 1.763 2.457 3.082 2.468 1.173.012 1.504-.752 3.093-.76 1.59-.009 1.891.771 3.063.759 1.319-.01 2.382-1.452 3.137-2.608.541-.828.742-1.246 1.162-2.183-3.052-1.161-3.542-5.502-.521-7.168'/>
</svg>);

const ICSIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.fill} d='M6.421 10.151h5.158V6.139H6.421zm4.299-3.439a.287.287 0 1 1 0 .573.287.287 0 0 1 0-.573m0 1.146a.287.287 0 1 1 0 .573.287.287 0 0 1 0-.573m0 1.146a.287.287 0 1 1 0 .573.287.287 0 0 1 0-.573M9.574 6.712a.287.287 0 1 1 0 .573.287.287 0 0 1 0-.573m0 1.146a.287.287 0 1 1 0 .573.287.287 0 0 1 0-.573m0 1.146a.287.287 0 1 1 0 .573.287.287 0 0 1 0-.573M8.428 6.712a.287.287 0 1 1 0 .573.287.287 0 0 1 0-.573m0 1.146a.287.287 0 1 1 0 .573.287.287 0 0 1 0-.573m0 1.146a.287.287 0 1 1 0 .573.287.287 0 0 1 0-.573M7.282 7.858a.287.287 0 1 1 0 .573.287.287 0 0 1 0-.573m0 1.146a.287.287 0 1 1 0 .573.287.287 0 0 1 0-.573'/>
    <path {...pathProps.fill} d='M11.866 2.412V.979H4.129a1.433 1.433 0 0 0-1.433 1.433v10.029h12.609V4.417h-1.433a2.01 2.01 0 0 1-2.006-2.006m.287 8.024a.287.287 0 0 1-.287.286H6.135a.287.287 0 0 1-.287-.287V5.166a.75.75 0 0 1 .748-.749h.398V4.13a.287.287 0 1 1 .573 0v.287h2.866V4.13a.287.287 0 1 1 .573 0v.287h.398a.75.75 0 0 1 .748.748z'/>
    <path {...pathProps.fill} d='M11.404 4.995h-.398a.287.287 0 1 1-.573 0H7.568a.287.287 0 1 1-.573 0h-.397a.176.176 0 0 0-.176.175v.398h5.158V5.17a.176.176 0 0 0-.175-.175M2.697 15.592a1.433 1.433 0 0 0 1.433 1.433h9.742a1.433 1.433 0 0 0 1.433-1.433v-2.579H2.697zm8.596-.287a.86.86 0 1 1 0-1.719h.573a.287.287 0 1 1 0 .573h-.573a.287.287 0 0 0 0 .573.86.86 0 1 1 0 1.72h-.573a.287.287 0 1 1 0-.573h.573a.287.287 0 1 0 0-.573m-2.006-1.719c.141 0 .281.032.408.093a.287.287 0 1 1-.244.518.4.4 0 0 0-.164-.037c-.311 0-.573.393-.573.859s.262.86.573.86q.087 0 .164-.038a.286.286 0 1 1 .244.519 1 1 0 0 1-.408.092c-.632 0-1.146-.642-1.146-1.433 0-.789.514-1.433 1.146-1.433m-3.152.573a.287.287 0 1 1 0-.573h1.146a.287.287 0 1 1 0 .573h-.287v1.719h.287a.287.287 0 1 1 0 .573H6.135a.287.287 0 1 1 0-.573h.287V14.16zm7.736-10.315h1.028l-2.46-2.46v1.027a1.433 1.433 0 0 0 1.433 1.433'/>
</svg>);

export const emailIcons = {
    GoogleIcon,
    OutlookIcon,
    AppleIcon,
    ICSIcon,
};

export const AppsumoLogo = (props: IconProps) => (<svg {...getCommonProps(props)} viewBox='0 0 18 4'>
    <path d='M11.093 2.32c0 .431-.199.431-.298.431s-.298 0-.298-.431V1.105h-.508v1.292c0 .497.331.85.806.85s.806-.353.806-.85V1.105h-.508zm4.98-1.281c-.618 0-1.126.497-1.126 1.126 0 .618.508 1.126 1.126 1.126s1.126-.508 1.126-1.126-.508-1.126-1.126-1.126m0 1.734a.61.61 0 0 1-.607-.618.62.62 0 0 1 .596-.618.62.62 0 0 1 .618.596v.011a.617.617 0 0 1-.607.629M1.772.752.635 3.226h.574l.221-.475h.685l.221.475h.574zm-.221 1.579.21-.519.21.519zm2.672-1.215h-.828v2.087h.519v-.596h.276c.508 0 .828-.287.828-.751v-.011c.011-.453-.298-.729-.795-.729m.276.762c0 .177-.121.287-.32.287h-.265v-.574h.265c.21-.011.32.099.32.287m1.866-.762h-.828v2.087h.519v-.596h.276c.508 0 .828-.287.828-.751v-.011c.011-.453-.298-.729-.795-.729m.276.762c0 .177-.121.287-.32.287h-.265v-.574h.265c.21-.011.32.099.32.287m2.065-.099c-.409-.11-.497-.155-.497-.298V1.47c0-.11.11-.188.287-.188.221.011.431.088.607.232l.011.011.287-.42-.011-.011a1.46 1.46 0 0 0-.894-.309c-.497 0-.85.298-.85.729v.011c0 .497.342.629.806.74.398.099.464.166.464.287v.011c0 .133-.121.21-.32.21a1.1 1.1 0 0 1-.696-.287l-.011-.011-.331.398.011.011c.276.254.64.387 1.016.387.552 0 .894-.287.894-.751v-.011c0-.442-.309-.618-.773-.729m4.605 1.535-.629-1.06-.188.983h-.453l.464-2.198.817 1.38.773-1.38.464 2.198h-.486l-.177-.972z' fill='#343d45'/>
</svg>);
