import { type ClientInfoFE } from ':frontend/types/Client';
import { useTranslation } from 'react-i18next';
import { OrdersTable } from ':frontend/components/orders/OrdersTable';
import { InvoiceIcon } from ':components/icons/basic';
import { Card } from ':components/shadcn';
import { ClientPackages } from ':frontend/components/client/ClientPackages';
import { OrderStateFilter } from ':frontend/components/common/filters/OrderStateFilter';
import { CreatedAtStartFilter, CreatedAtEndFilter } from ':frontend/components/common/filters/CreatedAtFilter';
import { OrderTypeFilter } from ':frontend/components/common/filters/OrderTypeFilter';
import { useFilters, type UseFiltersControl } from ':frontend/components/common/filters/FilterRow';
import type { OrderType } from ':utils/entity/order';
import { useCallback } from 'react';
import type { OrderState } from ':utils/entity/invoicing';
import type { DateTime } from 'luxon';

type ClientOrdersProps = Readonly<{
    client: ClientInfoFE;
}>;

export default function ClientOrders({ client }: ClientOrdersProps) {
    const { t } = useTranslation('pages');

    const filtersControl = useFilters(filters);

    const filtersToServer = useCallback((control: UseFiltersControl) => ({
        type: control.toServer(OrderTypeFilter.name) as OrderType[] | undefined,
        state: control.toServer(OrderStateFilter.name) as OrderState[] | undefined,
        client: [ client.id ],
        createdAtStart: control.toServer(CreatedAtStartFilter.name) as DateTime | undefined,
        createdAtEnd: control.toServer(CreatedAtEndFilter.name) as DateTime | undefined,
    }), [ client.id ]);

    return (
        <Card className='space-y-6 max-lg:bg-transparent max-lg:p-0 max-lg:border-none max-lg:shadow-none'>
            <Card.Title className='flex items-center gap-2'>
                <InvoiceIcon size='lg' />{t('client.orders.page-title', { clientName: client.name })}
            </Card.Title>

            <ClientPackages client={client} />

            <OrdersTable filtersControl={filtersControl} filtersToServer={filtersToServer} />
        </Card>
    );
}

const filters = [
    OrderTypeFilter,
    OrderStateFilter,
    CreatedAtStartFilter,
    CreatedAtEndFilter,
];
