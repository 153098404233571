import { type FilterDefinition, type FilterFunction, type FilterMenuProps } from './FilterRow';
import { Form } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { Query } from ':frontend/utils/common';
import { type ClientInfoFE } from ':frontend/types/Client';

const filterName = 'clientName';

type FilterState = string;

function RowMenu({ state, setState }: FilterMenuProps<FilterState>) {
    const { t } = useTranslation('common', { keyPrefix: `filters.${filterName}` });

    return (
        <Form.Input
            onChange={e => setState(e.target.value)}
            value={state}
            placeholder={t('search-placeholder')}
            size='compact'
            className='w-full min-w-[240px] md:max-w-[300px]'
        />
    );
}

function createFilterFunction(state: FilterState): FilterFunction<ClientInfoFE> {
    if (!state)
        return () => true;

    const filterQuery = new Query(...state.split(' '));

    return (data: ClientInfoFE) => data.query.match(filterQuery);
}

function toServer(state: FilterState): string {
    return state;
}

export const ClientNameFilter: FilterDefinition<FilterState, undefined, ClientInfoFE, string> = {
    name: filterName,
    defaultState: '',
    RowMenu,
    createFilterFunction,
    toServer,
    alignRight: true,
};
