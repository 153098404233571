import { useEffect, useState } from 'react';
import { api } from ':frontend/utils/api';
import { Button } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { MILLISECONDS_IN_SECOND, SECONDS_IN_MINUTE } from ':utils/dateTime';
import { env } from ':env';

type NewVersionNotificationProps = Readonly<{
    isShow: boolean;
}>;

export function NewVersionNotification({ isShow }: NewVersionNotificationProps) {
    if (!isShow)
        return null;

    return (
        <NotificationInner />
    );
}

function NotificationInner() {
    const { t } = useTranslation('common', { keyPrefix: 'newVersionNotification' });

    function forceReload() {
        location.reload();
    }

    return (
        <div className='fixed top-3 left-3 right-[calc(12px+var(--removed-body-scroll-bar-size))] z-[1100] rounded-md backdrop-blur-3 webkit-backdrop-blur-3 shadow-lg backdrop-blur-sm'>
            <div className='rounded-md p-4 sm:py-6 sm:px-12 bg-primary-200 opacity-85 hover:opacity-90'>
                <div className='max-w-[1200px] mx-auto flex max-sm:flex-col sm:items-center justify-between gap-4 sm:gap-8'>
                    <span className='text-xl/6'>
                        {t('message')}
                    </span>
                    <Button className='px-8' variant='primary' onClick={forceReload}>
                        {t('refresh-button')}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export function useNewVersionNotification(): boolean {
    const [ isShow, setIsShow ] = useState(false);

    useEffect(() => {
        const manager = new VersionManager(() => setIsShow(true));
        manager.start();

        return () => manager.stop();
    }, []);


    return isShow;
}

// This needs to be shorter than the deploy time, because we are creating versions as a part of the deploy job.
/** 5 minutes */
const DEFAULT_CHECK_PERIOD = 5 * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;

class VersionManager {
    private interval?: ReturnType<typeof setInterval>;
    private currentAbort?: () => void;

    constructor(
        private readonly onRefresh: () => void,
    ) {}

    public start() {
        this.check();
        this.interval = setInterval(() => this.check(), DEFAULT_CHECK_PERIOD);
    }

    public stop() {
        if (this.interval)
            clearTimeout(this.interval);

        this.currentAbort?.();
    }

    /** Returns time in milliseconds until the next check. */
    private async check(): Promise<void> {
        const [ signal, abort ] = api.prepareAbort();
        this.currentAbort = abort;

        const response = await api.backend.getVersion(signal);
        if (!response.status)
            return;

        const version = response.data.version;

        if (env.VITE_APP_VERSION === version)
            // The version is the same, we don't have to do anything.
            return;

        // The versions aren't the same - let's assume the new version is older than the current one. We should reload the page.
        // Well ... it can't be newer, so it's more a fact than an assumption.
        this.onRefresh();
    }
}
