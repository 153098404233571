import { durationInDays, type CalendarEvent, type GoogleEventResource } from './Calendar';
import { api } from ':frontend/utils/api';
import { ClientContact, type ClientInfoFE } from '../Client';
import { type GoogleUser } from '../GoogleUser';
import { clientContactToServer } from '../EventParticipant';
import { EventParticipantType, type ClientParticipantUpsert, type ContactParticipanUpsert } from ':utils/entity/eventParticipant';
import type { GoogleEventInit } from ':utils/entity/event';
import { GoogleEvent, type GoogleEventFromServer } from ':utils/lib/google';
import { localizer } from ':frontend/lib/calendar/utils/common';
import { getNameFromEmail, Query } from ':frontend/utils/common';
import { parseColor } from ':utils/common';
import type { AppUserSettingsOutput } from ':utils/entity/settings';
import type { DateRange } from ':utils/dateTime';

export type GoogleCalendarFromServer = {
    id: string;
    summary: string;
    backgroundColor: string;
    primary: boolean | undefined;
};

export class GoogleCalendar {
    readonly query: Query;

    private constructor(
        readonly id: string,
        readonly name: string,
        /** Hex code (6 characters). */
        readonly color: string,
        /** The Flowlance Google calendar (where we write the events), not the user's default calendar. */
        readonly isDefault: boolean,
        /** The user's default Google Calendar, usually named with his email address. */
        readonly isPrimary: boolean,
    ) {
        this.query = name.includes('@') ? new Query(getNameFromEmail(name)) : new Query(...name.split(' '));
    }

    static fromServer(input: GoogleCalendarFromServer, defaultCalendarId: string | undefined): GoogleCalendar {
        return new GoogleCalendar(
            input.id,
            input.summary,
            parseColor(input.backgroundColor),
            input.id === defaultCalendarId,
            !!input.primary,
        );
    }
}

export function sortCalendars(calendars: GoogleCalendar[]): GoogleCalendar[] {
    const sortFunction = (a: GoogleCalendar, b: GoogleCalendar) => {
        if (a.isPrimary)
            return -1;
        if (b.isPrimary)
            return 1;

        if (a.id < b.id)
            return -1;
        if (a.id > b.id)
            return 1;

        return 0;
    };

    return calendars.sort(sortFunction);
}

export function isGoogleEventTransparent(event: GoogleEvent): boolean {
    // TODO should be transparent if the user isn't attending
    return false;
}

export type GoogleCalendarEventsFromServer = {
    items: GoogleEventFromServer[];
    nextPageToken?: string;
};

export async function fetchGoogleEvents(range: DateRange, calendarId: string, { signal }: { signal?: AbortSignal }): Promise<GoogleEvent[] | undefined> {
    const response = await api.google.getCalendarEvents(range, calendarId, signal);
    if (!response.status)
        return undefined;

    return response.data
        .map(e => GoogleEvent.tryFromServer(e, calendarId))
        .filter((e): e is GoogleEvent => !!e);
}

export function googleEventsToCalendarEvents(events: GoogleEvent[], calendars: GoogleCalendar[], activeIds: Set<string>): CalendarEvent[] {
    const enabledCalendars = calendars.filter(calendar => activeIds.has(calendar.id));
    return events.map(event => googleEventToCalendarEvent(event, enabledCalendars))
        .filter((event): event is CalendarEvent => !!event);
}

function googleEventToCalendarEvent(event: GoogleEvent, calendars: GoogleCalendar[]): CalendarEvent | undefined {
    const calendar = calendars.find(c => c.id === event.calendarId);
    if (!calendar)
        return undefined;

    const resource: GoogleEventResource = {
        type: 'google',
        event,
        calendar,
    };

    return {
        title: event.title ?? '',
        isAllDay: event.isAllDay,
        start: event.start,
        end: event.end,
        resource,
        startDay: localizer.startOf(event.start, 'day'),
        duration: durationInDays(event.start, event.end),
        id: event.id,
        isBillable: false,
    };
}

export function googleEventToServer(
    eventInstance: GoogleEvent,
    settings: AppUserSettingsOutput,
    clients: ClientInfoFE[],
    googleUser: GoogleUser,
): GoogleEventInit {
    const { clientParticipants, contactParticipants } = createParticipantsFromEvent(eventInstance, settings, googleUser, clients);

    return {
        clientParticipants,
        contactParticipants,
        googleCalendarId: eventInstance.calendarId,
        eventId: eventInstance.id,
        recurringEventId: eventInstance.recurringEventId,
    };
}

function createParticipantsFromEvent(event: GoogleEvent, settings: AppUserSettingsOutput, googleUser: GoogleUser, clients: ClientInfoFE[]): {
    clientParticipants: ClientParticipantUpsert[];
    contactParticipants: ContactParticipanUpsert[];
} {
    const clientParticipants: ClientParticipantUpsert[] = [];
    const contactParticipants: ContactParticipanUpsert[] = [];

    // We are only creating guests. The user can add clients later.
    event.guests.forEach(email => {
        if (email === googleUser.email)
            return;

        const foundClient = clients.find(c => c.email === email);
        if (foundClient) {
            clientParticipants.push({ id: foundClient.id, ...guestData });
        }
        else {
            const contact = ClientContact.fromEmail(email);
            contactParticipants.push({ contact: clientContactToServer(contact, settings), ...guestData });
        }
        // TODO find some other info
    });

    return { clientParticipants, contactParticipants };
}

const guestData = {
    type: EventParticipantType.guest,
    price: 0,
} as const;
