import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { env } from ':env';
import App from ':frontend/App';
import { emptyFunction } from './utils/common';

if (import.meta.env.PROD) {
    console.debug = emptyFunction;
    console.log = emptyFunction;
}

import * as Sentry from '@sentry/react';

Sentry.addTracingExtensions();

const sentryDsnKey = env.VITE_SENTRY_DSN_FRONTEND;
if (sentryDsnKey) {
    Sentry.init({
        dsn: sentryDsnKey,
        integrations: [
            Sentry.browserTracingIntegration(),
            // TODO Use specific integration for react-router once we are ready
            // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
        ],
        tracesSampleRate: 1.0,
        environment: env.VITE_SENTRY_ENVIRONMENT,
    });
}

import ':frontend/styles/index.css';
import { NotificationProvider } from ':frontend/context/NotificationProvider';
import { AuthProvider } from ':frontend/context/AuthProvider';

import ':frontend/types/i18n';

import { setupSuperjson } from ':utils/modules/superjson';
setupSuperjson();

import { TrpcProvider } from './context/TrpcProvider';
import { PosthogProvider } from './lib/posthog';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <PosthogProvider>
            <NotificationProvider>
                <BrowserRouter>
                    <AuthProvider>
                        <TrpcProvider>
                            <App />
                        </TrpcProvider>
                    </AuthProvider>
                </BrowserRouter>
            </NotificationProvider>
        </PosthogProvider>
    </React.StrictMode>,
);
