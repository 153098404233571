import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Form, Modal, Select, type SingleValue, SpinnerButton } from ':components/shadcn';
import { useMaster } from ':frontend/context/UserProvider';
import { arrayValuesToStrings, BankingForm, inputToForm } from ':frontend/components/settings/BankingForm';
import { Currency, getAllCurrencies } from ':utils/money';
import { processInputData } from ':utils/entity/bankAccountData';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { useToggle } from ':frontend/hooks';
import { useCached } from ':components/hooks';
import { type Id } from ':utils/id';
import type { BankAccountOutput, BankAccountUpsert } from ':utils/entity/invoicing';
import { trpc } from ':frontend/context/TrpcProvider';
import clsx from 'clsx';

type NoBankAccountModalProps = Readonly<{
    show: boolean;
    onClose: () => void;
    /** Only the currencies that are currently unsupported. */
    currencies: Id[];
}>;

export function NoBankAccountModal({ show, onClose, currencies }: NoBankAccountModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'noBankAccountModal' });
    const { bankAccounts } = useMaster();
    const [ isFetching, setIsFetching ] = useState(false);
    const canBeEditing = bankAccounts.length > 0;
    const [ isEditing, setIsEditing ] = useToggle(canBeEditing);

    const cachedCurrency = useCached(currencies[0]);
    if (!cachedCurrency)
        return null;

    return (
        <Modal.Root open={show} onOpenChange={open => !open && onClose()}>
            <Modal.Content className={clsx('border-0', isEditing ? 'max-w-[360px]' : 'max-w-2xl')} closeButton={t('cancel-button')}>
                <Modal.Header>
                    <Modal.Title className='leading-6 text-2lg text-secondary-500 font-semibold'>
                        {t(isEditing ? 'editing-title' : 'creating-title')}
                    </Modal.Title>
                    <Modal.Description className='mt-4'>
                        <Trans
                            t={t}
                            i18nKey={isEditing ? 'editing-description' : 'creating-description'}
                            components={{
                                currency: <span className='text-primary font-semibold'>{Currency.label(cachedCurrency)}</span>,
                            }}
                        />
                    </Modal.Description>
                </Modal.Header>

                <div>
                    {isEditing ? (
                        <EditAccount
                            onClose={onClose}
                            currencies={currencies}
                            isFetching={isFetching}
                            setIsFetching={setIsFetching}
                        />
                    ) : (
                        <CreateAccount
                            onClose={onClose}
                            currencies={currencies}
                            isFetching={isFetching}
                            setIsFetching={setIsFetching}
                        />
                    )}
                </div>

                {(isEditing || canBeEditing) && (
                    <Button
                        variant='ghost'
                        className='no-underline'
                        onClick={setIsEditing.toggle}
                        disabled={isFetching}
                    >
                        {t(isEditing ? 'start-creating-button' : 'start-editing-button')}
                    </Button>
                )}
            </Modal.Content>
        </Modal.Root>
    );
}

type EditAccountProps = Readonly<{
    onClose: () => void;
    currencies: Id[];
    isFetching: boolean;
    setIsFetching: (isFetching: boolean) => void;
}>;

function EditAccount({ onClose, currencies, isFetching, setIsFetching }: EditAccountProps) {
    const { t } = useTranslation('components', { keyPrefix: 'noBankAccountModal' });
    const { addAlert } = useNotifications();
    const { bankAccounts, setBankAccounts } = useMaster();
    const [ selectedBankAccount, setSelectedBankAccount ] = useState<BankAccountOutput>();

    const updateBankAccountMutation = trpc.invoicing.updateBankAccount.useMutation();

    function updateAccount() {
        if (isFetching || !selectedBankAccount)
            return;

        const form = inputToForm(selectedBankAccount);
        // TODO check if all currencies are enabled
        form.currencies = enabledCurrencies;
        const editAccountData = processInputData(form.country, arrayValuesToStrings(form.numberParts), form.iban, form.swift);
        const edit = {
            raw: editAccountData,
            currencies: form.currencies,
            id: selectedBankAccount.id,
        };

        setIsFetching(true);
        updateBankAccountMutation.mutate(edit, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                setBankAccounts(prev => prev.map(a => a.id !== response.id ? a : response));
                addAlert(createTranslatedSuccessAlert('common:bankAccount.edited-alert'));
                // TODO don't close if there are still unsupported currencies.
                // TODO base the modal on state instead of on callbacks.
            },
            onSettled: () => {
                setIsFetching(false);
                onClose();
            },
        });
    }

    const bankAccountOptions = useMemo(() => {
        return bankAccounts.map(bankAccount => bankAccountToOption(bankAccount));
    }, [ bankAccounts ]);

    const availableCurrencies = useMemo(() => {
        if (!selectedBankAccount)
            return [];

        const alreadyTaken = bankAccounts.filter(account => account.id !== selectedBankAccount.id).flatMap(account => account.currencies);
        return getAllCurrencies().filter(currency => !alreadyTaken.includes(currency));
    }, [ selectedBankAccount, bankAccounts ]);

    const [ enabledCurrencies, setEnabledCurrencies ] = useState<Id[]>([]);

    // Ww want to disable saving unless at least one new currency is selected.
    const newCurrencySelected = useMemo(() => {
        return currencies.some(needed => enabledCurrencies.some(enabled => enabled === needed));
    }, [ currencies, enabledCurrencies ]);

    const innerOnChange = useCallback((option: SingleValue<ReturnType<typeof bankAccountToOption>>) => {
        if (option) {
            setSelectedBankAccount(option.value);
            setEnabledCurrencies([ ...option.value.currencies ]);
        }
        else {
            setSelectedBankAccount(undefined);
            setEnabledCurrencies([]);
        }
    }, []);

    return (<>
        <div>
            <Form.Label>{t('account-select-label')}</Form.Label>
            <Select
                value={selectedBankAccount ? bankAccountToOption(selectedBankAccount) : null}
                options={bankAccountOptions}
                onChange={innerOnChange}
                className='mb-4'
            />
        </div>
        {selectedBankAccount && (
            <div>
                <h3 className='mb-2 leading-5'>{t('currencies-label')}</h3>
                <div className='p-6 bg-secondary-50 grid grid-cols-2 gap-x-8 gap-y-2 rounded-xl'>
                    {getAllCurrencies().map(currency => (
                        <div key={currency} className='flex items-center gap-6'>
                            <Form.Switch
                                label={Currency.label(currency)}
                                disabled={!availableCurrencies.includes(currency)}
                                checked={enabledCurrencies.includes(currency)}
                                onCheckedChange={value => {
                                    if (value)
                                        setEnabledCurrencies([ ...enabledCurrencies, currency ]);
                                    else
                                        setEnabledCurrencies(enabledCurrencies.filter(c => c !== currency));
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        )}
        <SpinnerButton
            isFetching={isFetching}
            onClick={updateAccount}
            className='mt-4 w-full'
            disabled={!newCurrencySelected}
        >
            {t('submit-edit-button')}
        </SpinnerButton>
    </>);
}

function bankAccountToOption(bankAccount: BankAccountOutput) {
    return {
        value: bankAccount,
        label: bankAccountToLabel(bankAccount),
    };
}

function bankAccountToLabel(bankAccount: BankAccountOutput) {
    return (
        <div className='flex justify-between items-baseline gap-1'>
            <div>{bankAccount.label}</div>
            {bankAccount.currencies.map(currency => (
                <div key={currency} className='border border-primary rounded px-1 text-sm leading'>
                    {Currency.label(currency)}
                </div>
            ))}
        </div>
    );
}

type CreateAccountProps = Readonly<{
    onClose: () => void;
    currencies: Id[];
    isFetching: boolean;
    setIsFetching: (isFetching: boolean) => void;
}>;

function CreateAccount({ onClose, currencies, isFetching, setIsFetching }: CreateAccountProps) {
    const { addAlert } = useNotifications();
    const { setBankAccounts } = useMaster();

    const createBankAccountMutation = trpc.invoicing.createBankAccount.useMutation();

    function createAccount(init: BankAccountUpsert) {
        setIsFetching(true);
        createBankAccountMutation.mutate(init, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                setBankAccounts(prev => [ ...prev, response ]);
                addAlert(createTranslatedSuccessAlert('common:bankAccount.created-alert'));
            },
            onSettled: () => {
                setIsFetching(false);
                onClose();
            },
        });
    }

    return (
        <BankingForm
            defaultCurrencies={currencies}
            onSubmit={createAccount}
            isFetching={isFetching}
        />
    );
}
