import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, SpinnerButton } from ':components/shadcn';
import { PasswordInput } from ':frontend/components/forms/PasswordInput';
import { RHFErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { useForm } from 'react-hook-form';
import { PASSWORD_MINIMAL_LENGTH } from ':utils/forms';
import useNotifications from ':frontend/context/NotificationProvider';
import { useAuth } from ':frontend/context/AuthProvider';
import { Variant, Visibility } from ':frontend/types/notifications';
import { createTranslatedErrorAlert } from ':frontend/components/notifications';

type PasswordFormData = {
    password: string;
    newPassword: string;
};

export function PasswordForm() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.password' });
    const { addAlert } = useNotifications();
    const { auth } = useAuth();
    const [ isFetching, setIsFetching ] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm<PasswordFormData>();

    async function onSubmit(data: PasswordFormData) {
        setIsFetching(true);
        const response = await auth.updatePassword(data.password, data.newPassword);
        setIsFetching(false);
        if (!response.status) {
            // TODO handle error

            addAlert(createTranslatedErrorAlert('pages:settings.password.error'));
            return;
        }

        addAlert({ message: t('success'), variant: Variant.Success }, Visibility.Global);
    }

    return (
        <Form.Root onSubmit={handleSubmit(onSubmit)} className='space-y-8'>
            <div className='space-y-4'>
                <div>
                    <PasswordInput
                        label={t('current-password-label')}
                        register={register('password', { required: t('current-password-required-error') })}
                    />
                    <RHFErrorMessage errors={errors} name='password' />
                </div>

                <div>
                    <PasswordInput
                        label={t('new-password-label')}
                        register={register('newPassword', {
                            required: t('new-password-required-error'),
                            minLength: { value: PASSWORD_MINIMAL_LENGTH, message: t('new-password-too-short', { minimalLength: PASSWORD_MINIMAL_LENGTH }) },
                        })}
                    />
                    <RHFErrorMessage errors={errors} name='newPassword' />
                </div>
            </div>

            <div>
                <SpinnerButton
                    type='submit'
                    size='small'
                    className='w-full'
                    isFetching={isFetching}
                >
                    {t('save-button')}
                </SpinnerButton>
            </div>
        </Form.Root>
    );
}
