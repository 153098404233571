import { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import ClaimUrl from ':frontend/pages/auth/ClaimUrl';
import GoogleError from ':frontend/pages/auth/GoogleError';
import Login from ':frontend/pages/auth/Login';
import ResetPassword from ':frontend/pages/auth/ResetPassword';
import Register from ':frontend/pages/auth/Register';
import AppsumoCode from ':frontend/pages/landing/AppsumoCode';

export default function UnauthorizedRoutes() {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [ location.pathname ]);

    return (
        <Routes>
            <Route path={routesFE.auth.login.path} element={<Login />} />
            <Route path={routesFE.auth.claimUrl.path} element={<ClaimUrl />} />
            <Route path={routesFE.auth.register.path} element={<Register />} />
            <Route path={routesFE.auth.resetPassword} element={<ResetPassword />} />
            <Route path={routesFE.googleError.path} element={<GoogleError />} />

            <Route path={routesFE.landing.appsumoCode.path} element={<AppsumoCode />} />

            <Route
                path='*'
                element={
                    <Navigate
                        to={routesFE.auth.claimUrl.path}
                        state={{
                            from: location,
                        }}
                        replace
                    />
                }
            />
        </Routes>
    );
}
