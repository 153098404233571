import { useRef } from 'react';
import { CreatedAtEndFilter, CreatedAtStartFilter } from ':frontend/components/common/filters/CreatedAtFilter';
import { useToggle } from ':frontend/hooks';
import { Button, Modal, SpinnerButton } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { FilterRow, useFilters, type UseFiltersControl } from ':frontend/components/common/filters/FilterRow';
import { api } from ':frontend/utils/api';
import DownloadFile, { type DownloadFileRef } from ':frontend/components/common/DownloadFile';
import { type Id } from ':utils/id';
import type { DateTime } from 'luxon';
import { trpc } from ':frontend/context/TrpcProvider';
import { ExportIcon } from ':components/icons/basic';
import type { ClientState, ExportClientsQuery } from ':utils/entity/client';
import { ClientStateFilter } from '../common/filters/ClientStateFilter';
import { ClientTagFilter } from '../common/filters/ClientTagFilter';

type ExportClientsModalProps = Readonly<{
    inputFilters: UseFiltersControl;
}>;

export function ExportClientsModal({ inputFilters }: ExportClientsModalProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'exportClientsModal' });

    return (
        <Modal.Root>
            <Modal.Trigger asChild>
                <Button size='small' variant='dark'>
                    <ExportIcon />{t('trigger-button')}
                </Button>
            </Modal.Trigger>

            <Modal.Content className='max-w-3xl p-8 border-none' closeButton={t('close-button')}>
                <ExportModalInner inputFilters={inputFilters} />
            </Modal.Content>
        </Modal.Root>
    );
}

/** We do want to reset the modal state when closed, so we use a separate component for it. */
function ExportModalInner({ inputFilters }: ExportClientsModalProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'exportClientsModal' });

    const downloadRef = useRef<DownloadFileRef>(null);
    const [ isFetching, setIsFetching ] = useToggle(false);

    const { count, filtersControl, query, isFetching: isFetchingCount } = useClientCount(inputFilters);

    async function fetchExportedInvoices() {
        const ref = downloadRef.current;
        if (!ref)
            return;

        setIsFetching.true();

        const response = await api.backend.exportClients(query);

        if (!response.status) {
            setIsFetching.false();

            return;
        }

        ref.download(response.data);

        setIsFetching.false();
    }

    return (<>
        <Modal.Header>
            <Modal.Title className='text-2xl/8 text-secondary'>{t('title')}</Modal.Title>
        </Modal.Header>

        <FilterRow control={filtersControl} />

        <Modal.Footer className='mt-4'>
            <DownloadFile fileName='clients.csv' ref={downloadRef} />

            <SpinnerButton
                isFetching={isFetching || isFetchingCount}
                onClick={fetchExportedInvoices}
                // This key is used to refresh the button each time the size of the `count` string, so that its width isn't fixed on the size of the largest width.
                key={String(count ?? 0).length}
            >
                {t('export-button', { count })}
            </SpinnerButton>
        </Modal.Footer>
    </>);
}

const filters = [
    ClientStateFilter,
    ClientTagFilter,
    CreatedAtStartFilter,
    CreatedAtEndFilter,
];

function useClientCount(inputFilters: UseFiltersControl) {
    const filtersControl = useFilters(filters, inputFilters);

    const query: ExportClientsQuery = {
        state: filtersControl.toServer(ClientStateFilter.name) as ClientState[] | undefined,
        tags: filtersControl.toServer(ClientTagFilter.name) as Id[] | undefined,
        createdAtStart: filtersControl.toServer(CreatedAtStartFilter.name) as DateTime | undefined,
        createdAtEnd: filtersControl.toServer(CreatedAtEndFilter.name) as DateTime | undefined,
    };

    const countExportClientsQuery = trpc.$client.countExportClients.useQuery(query);

    return {
        count: countExportClientsQuery.data?.count,
        filtersControl,
        query,
        isFetching: countExportClientsQuery.isFetching,
    };
}
