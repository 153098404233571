import { useCallback, useEffect, useReducer, useState } from 'react';
import { type ClientInfoFE } from ':frontend/types/Client';
import { Button, Form, Modal, Skeleton, SpinnerButton } from ':components/shadcn';
import { Trans, useTranslation } from 'react-i18next';
import { Pagination } from ':frontend/components/common/Pagination';
import { usePagination, useHashFragment, useToggle } from ':frontend/hooks';
import { type EventFE } from ':frontend/types/Event';
import { Link } from 'react-router-dom';
import { notesReducer, initialState, type NotesState } from ':frontend/types/Notes';
import { routesFE } from ':utils/routes';
import { EventDetailLink } from '../event/EventDetail';
import { SendNotesButton } from ':frontend/components/event/EventNotes';
import { CLIENT_DETAIL_SCROLLER_ID } from './ClientDetail';
import { TeamMemberBadge } from ':frontend/components/team/TeamMemberBadge';
import { useUser } from ':frontend/context/UserProvider';
import { TeamMemberRole } from ':utils/entity/team';
import { trpc } from ':frontend/context/TrpcProvider';
import clsx from 'clsx';
import { PaperPlane1Icon, CheckboxCheckedIcon, XmarkIcon, Check1Icon, Copy1Icon } from ':components/icons/basic';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import { DateTime } from 'luxon';
import { DateTimeDisplay } from ':components/custom/DateTimeDisplay';

type ClientNotesProps = Readonly<{
    client: ClientInfoFE;
}>;

export default function ClientNotes({ client }: ClientNotesProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'client.notes' });
    const pagination = usePagination();
    const [ state, dispatch ] = useReducer(notesReducer, initialState);
    useHashFragment(CLIENT_DETAIL_SCROLLER_ID, state.events);

    const [ sendingPhase, setSendingPhase ] = useState<'selecting' | 'preview'>();
    const [ selectedEvents, setSelectedEvents ] = useState<EventFE[]>([]);

    const utils = trpc.useUtils();

    async function fetchEvents() {
        dispatch({ type: 'fetchStart' });

        const response = await utils.event.getEvents.fetch({
            participants: [ client.id ],
            page: pagination.page,
            notes: state.filter,
        });

        dispatch({
            type: 'fetchSuccess',
            response,
            pagination,
        });
    }

    useEffect(() => {
        fetchEvents();
    }, [ state.filter, pagination.page ]);

    function handleFilterChange(newFilter: string) {
        dispatch({
            type: 'filterChange',
            filter: newFilter,
            pagination,
        });
        // hide the Pagination
        pagination.setTotalItems(1);
        pagination.reset();
    }

    const toggleSelectedEvent = useCallback((event: EventFE, value: boolean) => {
        setSelectedEvents(prev => value
            ? [ ...prev, event ] :
            prev.filter(selectedEvent => selectedEvent.id !== event.id),
        );
    }, []);

    const sendNotesMutation = trpc.event.sendClientNotes.useMutation();
    const { addAlert } = useNotifications();

    async function sendNotes() {
        const eventIds = selectedEvents.map(event => event.id);

        await sendNotesMutation.mutateAsync({ clientId: client.id, eventIds }, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: () => {
                addAlert(createTranslatedSuccessAlert('pages:client.notes.send-success'));
                setSelectedEvents([]);
            },
            onSettled: () => {
                setSendingPhase(undefined);
            },
        });
    }

    function showPreview() {
        const sortedEvents = selectedEvents.sort((a, b) => +a.start - +b.start);
        setSelectedEvents(sortedEvents);
        setSendingPhase('preview');
    }

    return (
        <div className='space-y-8 grid grid-cols-1'>
            <div className='w-full space-y-8'>
                {sendingPhase === 'preview' && (
                    <NotesPreviewModal
                        events={selectedEvents}
                        client={client}
                        onSend={sendNotes}
                        onHide={() => setSendingPhase('selecting')}
                    />
                )}

                <div className='flex flex-col lg:flex-row items-center justify-between gap-4'>
                    <h1 className='text-2xl/6 font-semibold'>{t('page-title', { clientName: client.name })}</h1>

                    <div className='flex flex-col lg:flex-row items-center gap-4'>
                        <Form.Input
                            size='compact'
                            className='w-full max-w-[280px] rounded-full'
                            onChange={e => handleFilterChange(e.target.value)}
                            value={state.filter}
                            placeholder={t('search-placeholder')}
                        />

                        {!sendingPhase && (
                            <Button variant='outline' onClick={() => setSendingPhase('selecting')}>
                                <CheckboxCheckedIcon />

                                {t('start-selecting-button')}
                            </Button>
                        )}

                        {sendingPhase && selectedEvents.length === 0 && (
                            <Button variant='outline' onClick={() => setSendingPhase(undefined)}>
                                <XmarkIcon />

                                {t('stop-selecting-button')}
                            </Button>
                        )}

                        {sendingPhase && selectedEvents.length > 0 && (
                            <SpinnerButton variant='primary' onClick={showPreview} isFetching={sendNotesMutation.isPending}>
                                <PaperPlane1Icon />

                                {t('send-button', { count: selectedEvents.length })}
                            </SpinnerButton>
                        )}
                    </div>
                </div>

                <ClientNotesContent
                    client={client}
                    state={state}
                    isSelecting={!!sendingPhase}
                    selectedEvents={selectedEvents}
                    toggleSelectedEvent={toggleSelectedEvent}
                />

                <Pagination controller={pagination} />
            </div>
        </div>
    );
}

type ClientNotesContentProps = Readonly<{
    client: ClientInfoFE;
    state: NotesState;
    isSelecting: boolean;
    selectedEvents: EventFE[];
    toggleSelectedEvent: (event: EventFE, value: boolean) => void;
}>;

function ClientNotesContent({ client, state, isSelecting, selectedEvents, toggleSelectedEvent }: ClientNotesContentProps) {
    if (state.error) {
        return <div className='p-6 border border-danger-200 bg-danger-100 text-danger-700 rounded-lg text-center '>
            <ClientNotesError client={client} state={state} />
        </div>;
    }

    if (state.showFetching) {
        return (
            <div>
                <Skeleton height={96} className='mb-4' />
                <Skeleton height={96} className='mb-4' />
                <Skeleton height={96} />
            </div>
        );
    }

    if (state.events) {
        return (
            <NotesList
                client={client}
                events={state.events}
                isSelecting={isSelecting}
                selectedEvents={selectedEvents}
                toggleSelectedEvent={toggleSelectedEvent}
            />
        );
    }

    return null;
}

type ClientNotesErrorProps = Readonly<{
    client: ClientInfoFE;
    state: NotesState;
}>;


function ClientNotesError({ client, state }: ClientNotesErrorProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'client.notes' });

    if (state.error === 'noEvents') {
        return (
            <Trans
                i18nKey='no-events-found'
                t={t}
                components={{
                    a: <Link to={routesFE.clients.detail.resolve({ id: client.id, key: 'events' })} />,
                }}
                values={{ name: client.name }}
                className='text-lg'
            />
        );
    }

    if (state.error === 'noSearchResults')
        return <span className='text-lg'>{t('no-notes-found', { text: state.filter })}</span>;

    return <span className='text-lg'>{t('unknown-error')}</span>;
}

type NotesListProps = Readonly<{
    client: ClientInfoFE;
    events: EventFE[];
    isSelecting: boolean;
    selectedEvents: EventFE[];
    toggleSelectedEvent: (event: EventFE, value: boolean) => void;
}>;

function NotesList({ client, events, isSelecting, selectedEvents, toggleSelectedEvent }: NotesListProps) {
    const isMaster = useUser().role === TeamMemberRole.master;
    const { addAlert } = useNotifications();

    return (
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full'>
            {events.map(event => {
                if (event.notes) {
                    const isSelected = selectedEvents.some(selectedEvent => selectedEvent.id === event.id);

                    return (
                        <div
                            key={event.id}
                            id={event.id}
                            className={clsx('border-2 p-6 rounded-lg space-y-6', {
                                'bg-warning-200 border-warning-200': !isSelecting,
                                'cursor-pointer select-none': isSelecting,
                                'bg-warning-100 border-warning-100': isSelecting && !isSelected,
                                'bg-warning-200 border-warning-300': isSelecting && isSelected,
                            })}
                            onClick={() => isSelecting && toggleSelectedEvent(event, !isSelected)}
                        >
                            <div className='flex items-start justify-between gap-4'>
                                <div className='flex items-center gap-3 truncate'>
                                    {isSelecting && (
                                        <div className='shrink-0 flex items-center justify-center size-6 rounded-sm border border-secondary-200 bg-white'>
                                            {isSelected && <Check1Icon size='xs' />}
                                        </div>
                                    )}

                                    <div className='space-y-1 text-secondary-900 font-semibold truncate'>
                                        {isSelecting ? (
                                            <div>{event.displayTitle}</div>
                                        ) : (
                                            <EventDetailLink event={event} className='no-underline hover:underline'>{event.displayTitle}</EventDetailLink>
                                        )}

                                        <div><DateTimeDisplay dateTime={event.start} /></div>
                                    </div>
                                </div>

                                {!isSelecting && (
                                    <div className='flex items-center gap-4 shrink-0'>
                                        <SendNotesButton event={event} client={client} />

                                        <Button variant='transparent' size='exact' onClick={() => {
                                            navigator.clipboard.writeText(event.notes);

                                            addAlert(createTranslatedSuccessAlert('pages:client.notes.copy-success'));
                                        }}>
                                            <Copy1Icon size='md' />
                                        </Button>
                                    </div>
                                )}
                            </div>

                            {isMaster && (
                                <TeamMemberBadge appUserId={event.ownerId} />
                            )}

                            <div className='whitespace-pre-wrap text-secondary-900'>
                                {event.notes}
                            </div>
                        </div>
                    );
                }

                return null;
            })}
        </div>
    );
}

type NotesPreviewModalProps = Readonly<{
    events: EventFE[];
    client: ClientInfoFE;
    onSend: () => Promise<void>;
    onHide: () => void;
}>;

function NotesPreviewModal({ events, client, onSend, onHide }: NotesPreviewModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventNotes.sendNotesModal' });
    const { t: te, i18n } = useTranslation('shared', { keyPrefix: 'emails.eventNotes' });
    const [ isFetching, setIsFetching ] = useToggle(false);

    async function send() {
        setIsFetching.true();
        await onSend();
    }

    return (
        <Modal.Root open onOpenChange={open => !open && onHide()}>
            <Modal.Content className='border-0 gap-4' closeButton={t('cancel-button')} closeDisabled={isFetching}>
                <Modal.Header>
                    <Modal.Title className='text-3xl leading-9 text-center font-semibold text-secondary-900'>{t('title')}</Modal.Title>
                </Modal.Header>

                <div className='leading-6 text-lg flex justify-center gap-2'>
                    {t('to-label')}
                    <span className='font-semibold'>{client.email}</span>
                </div>

                <div className='flex flex-col gap-6 text-black my-2'>
                    {events.map(event => (
                        <div key={event.id}>
                            <p className='text-center mb-4'>
                                <Trans
                                    t={te}
                                    i18nKey='text'
                                    values={{
                                        title: event.title,
                                        startDate: event.start.toLocaleString(DateTime.DATETIME_MED, { locale: i18n.language }),
                                    }}
                                />
                            </p>
                            <div className='bg-[#f5f5f5] p-2.5 rounded-xs whitespace-pre-line'>
                                {event.notes}
                            </div>
                        </div>
                    ))}
                </div>

                <Modal.Footer className='flex flex-col gap-2'>
                    <SpinnerButton
                        onClick={send}
                        variant='primary'
                        isFetching={isFetching}
                    >
                        {t('send-button')}
                    </SpinnerButton>

                    <Button variant='outline' onClick={onHide}>
                        {t('cancel-button')}
                    </Button>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    );
}
