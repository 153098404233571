import { useCallback } from 'react';
import { Form, type InputProps } from ':components/shadcn';
import { taxRateFromPercent, taxRateToPercent, type TaxRate } from ':utils/money';
import { Controller, type Control, type FieldPath, type FieldValues } from 'react-hook-form';
import { transformToValidNumberOrEmpty } from ':utils/math';

function taxRateToForm(taxRate: TaxRate): string {
    return taxRateToPercent(taxRate).toString();
}

function taxRateFromForm(form: string): TaxRate {
    const number = transformToValidNumberOrEmpty(form);
    if (number === '' || number < 0)
        return 0;

    const taxRate = taxRateFromPercent(number);
    return Math.min(taxRate, 1);
}


type TaxRateInputProps = Omit<InputProps, 'value' | 'onChange'> & Readonly<{
    value: TaxRate;
    onChange: (value: TaxRate) => void;
    className?: string;
    id?: string;
}>;

export function TaxRateInput({ value, onChange, ...rest }: TaxRateInputProps) {
    function handleChange(input: string) {
        const taxRate = taxRateFromForm(input);
        console.log({ input, value, taxRate });
        if (taxRate !== value)
            onChange(taxRate);
    }

    return (
        <Form.Input
            type='number'
            value={taxRateToForm(value)}
            onChange={e => handleChange(e.target.value)}
            {...rest}
        />
    );
}

type ControlledTaxRateInputProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    className?: string;
};

export function ControlledTaxRateInput<TFieldValues extends FieldValues>({ control, name, className }: ControlledTaxRateInputProps<TFieldValues>) {
    const InnerSelect = useCallback(({ field }: { field: { value: TaxRate, onChange: (value: TaxRate) => void } }) => {
        return (
            <TaxRateInput
                value={field.value}
                onChange={field.onChange}
                className={className}
            />
        );
    }, [ className ]);

    return (
        <Controller
            control={control}
            name={name}
            render={InnerSelect}
        />
    );
}
