import { Button, Card } from ':components/shadcn';
import { Check1Icon, ChevronLeftIcon } from ':components/icons/basic';
import { ProductType, type ProductOutput, type SessionProductOutput } from ':utils/entity/product';
import type { StoreOutput } from ':utils/entity/store';
import { useTranslation } from 'react-i18next';
import type { DateRange } from ':utils/dateTime';
import clsx from 'clsx';
import type { DateTime } from 'luxon';
import { useCallback, useState, type ReactNode } from 'react';
import { ProductSchedulingForm, type UseAvailability } from './ProductSchedulingForm';
import type { PageOutput } from ':utils/entity/page';
import { ProductPricingLabel, type PagePreview } from './ProductCheckout';
import { ProductCustomerForm, type ProductCustomerFormData } from './ProductCustomerForm';
import { renderProductAttributes, type ProductPreview } from './ProductCard';

export type ProductCheckoutFormOutput = ProductCustomerFormData & {
    scheduleAt: DateTime | undefined;
};

type ProductCheckoutCardProps = Readonly<{
    store: StoreOutput;
    product: ProductOutput | ProductPreview;
    landing: PageOutput | PagePreview | undefined;
    /** Bevare this is a hook so it should be the same function (or a different function using the same basic hooks) for each render. */
    useAvailability: UseAvailability;
    onSubmit: (data: ProductCheckoutFormOutput) => void;
    isFetching: boolean;
}>;

export function ProductCheckoutCard({ store, product, landing, useAvailability, onSubmit, isFetching }: ProductCheckoutCardProps) {
    // If there is no availability, we are in the preview mode. In that case, no availability is necessary.
    const isScheduled = product.type === ProductType.Session && (!('availability' in product) || !!product.availability);
    if (isScheduled) {
        return (
            <ScheduledProductCheckoutCard
                store={store}
                product={product}
                landing={landing}
                useAvailability={useAvailability}
                onSubmit={onSubmit}
                isFetching={isFetching}
            />
        );
    }

    return (
        <CommonCard product={product} landing={landing} className='max-w-full w-[460px]'>
            <ProductCustomerForm
                store={store}
                product={product}
                landing={landing}
                onSubmit={onSubmit}
                isFetching={isFetching}
            />
        </CommonCard>
    );
}

type CommonCardProps = Readonly<{
    children: ReactNode;
    product: ProductOutput | ProductPreview;
    landing: PageOutput | PagePreview | undefined;
    className?: string;
}>;

function CommonCard({ children, product, landing, className }: CommonCardProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });

    return (
        <Card className={clsx('fl-store-card w-full p-0', className)}>
            <div className='p-4 phone:p-4 md:p-6 space-y-2 phone:space-y-2 md:space-y-4 border-b border-secondary-100'>
                <h2 className='text-lg/5 font-semibold break-words'>
                    {landing?.title ?? product.title}
                </h2>

                <ProductPricingLabel product={product} />

                <div className='flex flex-col gap-3 text-secondary empty:hidden'>
                    {renderProductAttributes(product, t)}
                </div>
            </div>

            {children}
        </Card>
    );
}

type ScheduledProductCheckoutCardProps = Readonly<{
    store: StoreOutput;
    product: SessionProductOutput | ProductPreview;
    landing: PageOutput | PagePreview | undefined;
    /** Bevare this is a hook so it should be the same function (or a different function using the same basic hooks) for each render. */
    useAvailability: UseAvailability;
    onSubmit: (data: ProductCheckoutFormOutput) => void;
    isFetching: boolean;
}>;

export function ScheduledProductCheckoutCard({ store, product, landing, useAvailability, onSubmit, isFetching }: ScheduledProductCheckoutCardProps) {
    const [ step, setStep ] = useState<'time' | 'checkout'>('time');

    const [ selectedBlock, setSelectedBlock ] = useState<DateRange | undefined>();
    const onSelectBlock = useCallback((block: DateRange | undefined) => {
        setSelectedBlock(block);
        if (block) {
            setStep('checkout');

            if (typeof fbq === 'function')
                fbq('track', 'Schedule');
        }
    }, []);

    // We check this only for actual products, not previews.
    if ('availability' in product) {
        // We don't care about the actual availability dates because they differ month by month.
        // Even though there might be none for a given month, we still want to show the scheduling form so that the user can select a different month.
        const isSchedulable = !!product.availability && Object.values(product.availability.weekdays).some(av => av.length);
        if (!isSchedulable) {
            return (
                <NotSchedulable store={store} product={product} />
            );
        }
    }

    return (
        <CommonCard product={product} landing={landing} className={step === 'time' ? 'max-w-[760px]' : 'max-w-full w-[460px]'}>
            <div className='relative ps-4 py-4 phone:py-4 md:py-6 flex items-center justify-center gap-2 border-b border-secondary-100'>
                {step !== 'time' && (
                    <Button
                        size='exact'
                        variant='outline'
                        className='absolute left-2 phone:left-2 md:left-4 size-8'
                        aria-label='Back to time selection'
                        onClick={() => setStep('time')}
                    >
                        <ChevronLeftIcon />
                    </Button>
                )}

                <StepsLabel title='Time' position={1} current={step === 'time'} completed={step === 'checkout'} />

                <div className='w-full max-w-14 fl-bg-dashed-primary-x' />

                <StepsLabel title='Checkout' position={2} current={step === 'checkout'} completed={false} />
            </div>

            {step === 'time' && (<>
                <ProductSchedulingForm
                    store={store}
                    product={product}
                    useAvailability={useAvailability}
                    selectedBlock={selectedBlock}
                    onSelectBlock={onSelectBlock}
                    isCompact={!!landing}
                />
            </>)}

            {step === 'checkout' && selectedBlock && (
                <ProductCustomerForm
                    store={store}
                    product={product}
                    landing={landing}
                    selectedBlock={selectedBlock}
                    onSubmit={onSubmit}
                    isFetching={isFetching}
                />
            )}
        </CommonCard>
    );
}

type NotSchedulableProps = Readonly<{
    store: StoreOutput;
    product: ProductOutput | ProductPreview;
}>;

function NotSchedulable({ store, product }: NotSchedulableProps) {
    const { t } = useTranslation('components', { keyPrefix: 'notSchedulable' });

    return (
        <Card className='fl-store-card text-center'>
            <h1 className='text-xl/6 font-semibold'>
                {t('title')}
            </h1>

            <p className='mt-2 text-secondary-400 break-words'>
                {t('description', { productTitle: product.title, storeName: store.bio.name })}
            </p>
        </Card>
    );
}

type StepsLabelProps = Readonly<{
    title: string;
    position: number;
    current: boolean;
    completed: boolean;
}>;

function StepsLabel({ title, position, current, completed }: StepsLabelProps) {
    return (<>
        <div className={clsx('size-7 rounded-full border flex items-center justify-center shrink-0',
            current || completed ? 'text-primary border-primary bg-primary-50' : 'border-secondary-100 bg-white',
        )}>
            {completed ? (
                <Check1Icon size='xs' />
            ) : (
                <span>{position}</span>
            )}
        </div>

        <div className={clsx('whitespace-nowrap font-semibold', current ? 'text-primary' : '')}>
            {title}
        </div>
    </>);
}
