import { z } from 'zod';
import { DateTime, type Zone, type WeekdayNumbers } from 'luxon';
import { getEnumValues } from './common';

export const zDateTime = z.custom<DateTime>(val => DateTime.isDateTime(val));

/**
 * By default, it's expected the start is inclusive and the end is exclusive.
 * I.e., both start and end should by obtained by {@link DateTime.startOf}.
 */
export type DateRange = z.infer<typeof zDateRange>;
export const zDateRange = z.object({
    start: zDateTime,
    end: zDateTime,
});

export type TimeString = z.infer<typeof zTimeString>;
const zTimeString = z.string().regex(/^\d{2}:\d{2}$/);

export type TimeRange = z.infer<typeof zTimeRange>;
export const zTimeRange = z.object({
    start: zTimeString,
    end: zTimeString,
});

export function dateTimeToTimeString(date: DateTime): TimeString {
    return ('' + date.hour).padStart(2, '0') + ':' + ('' + date.minute).padStart(2, '0');
}

// Days of the week

export enum Weekday {
    Monday = 'MO',
    Tuesday = 'TU',
    Wednesday = 'WE',
    Thursday = 'TH',
    Friday = 'FR',
    Saturday = 'SA',
    Sunday = 'SU',
}

export const WEEK_DAYS = getEnumValues(Weekday);
export const WORK_WEEK_DAYS = [ Weekday.Monday, Weekday.Tuesday, Weekday.Wednesday, Weekday.Thursday, Weekday.Friday ];

export function getWeekday(date: DateTime): Weekday {
    return WEEK_DAYS[date.weekday - 1];
}

// Mapping to numbers. Days of the week are 1-indexed, because that's how Luxon works.

const WEEKDAYS_TO_NUMBERS: Record<Weekday, WeekdayNumbers> = Object.fromEntries(WEEK_DAYS.map((day, index) => [ day, index + 1 ])) as Record<Weekday, WeekdayNumbers>;
export function weekdayToNumber(day: Weekday): WeekdayNumbers {
    return WEEKDAYS_TO_NUMBERS[day];
}

const NUMBERS_TO_WEEKDAYS: Record<number, Weekday> = Object.fromEntries(WEEK_DAYS.map((day, index) => [ index + 1, day ]));
export function numberToWeekday(number: WeekdayNumbers): Weekday {
    return NUMBERS_TO_WEEKDAYS[number];
}

// Transformation of generic time ranges to specific date time ranges.

export function dateRangesToWeekdays(ranges: Record<Weekday, DateRange[]>): Record<Weekday, TimeRange[]> {
    const output = {} as Record<Weekday, TimeRange[]>;

    for (const [ key, value ] of Object.entries(ranges)) {
        output[key as Weekday] = value.map(({ start, end }) => ({
            start: dateTimeToTimeString(start),
            end: dateTimeToTimeString(end),
        }));
    }

    return output;
}

/** @param weekStart should be the start of the week (usually Monday 00:00) */
export function weekdaysToDateRanges(weekdays: Record<Weekday, TimeRange[]>, weekStart: DateTime): Record<Weekday, DateRange[]> {
    const output = {} as Record<Weekday, DateRange[]>;

    for (const [ key, value ] of Object.entries(weekdays)) {
        const dayStart = weekStart.plus({ days: weekdayToNumber(key as Weekday) - 1 });
        output[key as Weekday] = timeRangesToDateRanges(value, dayStart);
    }

    return output;
}

/** @param dayStart should be the start of the day (00:00) */
export function timeRangesToDateRanges(timeRanges: TimeRange[], dayStart: DateTime): DateRange[] {
    return timeRanges.map(({ start, end }) => {
        const startSplit = start.split(':');
        const endSplit = end.split(':');

        return {
            start: dayStart.set({ hour: Number(startSplit[0]), minute: Number(startSplit[1]) }),
            end: dayStart.set({ hour: Number(endSplit[0]), minute: Number(endSplit[1]) }),
        };
    });
}

/**
 * Merges overlapping ranges from input. The output is sorted by starts (also by ends).
 */
export function mergeDateRanges(input: DateRange[]): DateRange[] {
    if (input.length === 0)
        return [];

    const sorted = input.sort((a, b) => +a.start - +b.start);

    const output: DateRange[] = [];

    let current = sorted[0];
    for (let i = 1; i < sorted.length; i++) {
        // If the current range ends before the next one starts, we can close the current one.
        if (+current.end < +sorted[i].start) {
            output.push(current);
            current = sorted[i];
            continue;
        }

        // The next range overlaps with the current one. If it ends later, we extend the current one.
        if (+current.end < +sorted[i].end) {
            current = {
                start: current.start,
                end: sorted[i].end,
            };
        }
    }

    output.push(current);

    return output;
}

/**
 * This function expects the subtractor to be merged and sorted by starts (basically an output of {@link mergeDateRanges}).
 * The output is in the same format.
 * Splits the base range by subtracting each range from input.
 */
export function inverseDateRanges(base: DateRange, input: DateRange[]): DateRange[] {
    let i = 0;
    while (i < input.length && +input[i].end <= +base.start)
        i++;

    if (i === input.length)
        return [ base ];

    const output: DateRange[] = [];

    let lastStart = base.start;
    while (i < input.length && +input[i].start <= +base.end) {
        output.push({
            start: lastStart,
            end: input[i].start,
        });

        lastStart = input[i].end;
        i++;
    }

    if (+lastStart < +base.end) {
        output.push({
            start: lastStart,
            end: base.end,
        });
    }

    return output;
}

/**
 * This function expects both inputs to be merged and sorted by starts (basically an output of {@link mergeDateRanges}).
 * Returns an intersection of each range from input1 with each range from input2 (but more efficiently).
 */
export function intersectDateRanges(input1: DateRange[], input2: DateRange[]): DateRange[] {
    if (input1.length === 0 || input2.length === 0)
        return [];

    // There might be at most as many intersections as is the minimum of the lengths of the inputs.
    // So, in each step, we try to create a new intersection from the current ranges. If they don't intersect, we move to the next range.
    const output: DateRange[] = [];

    let i1 = 0;
    let i2 = 0;

    while (i1 < input1.length && i2 < input2.length) {
        const a = input1[i1];
        const b = input2[i2];

        if (+a.end <= +b.start) {
            i1++;
            continue;
        }

        if (+b.end <= +a.start) {
            i2++;
            continue;
        }

        // There is an intersection!
        output.push({
            start: +a.start > +b.start ? a.start : b.start,
            end: +a.end < +b.end ? a.end : b.end,
        });

        if (+a.end < +b.end)
            i1++;
        else
            i2++;
    }

    return output;
}

// Conversions

export const MILLISECONDS_IN_SECOND = 1000;

export function secondsToMilliseconds(seconds: number): number {
    return seconds * MILLISECONDS_IN_SECOND;
}

export function millisecondsToSeconds(milliseconds: number): number {
    return Math.round(milliseconds / MILLISECONDS_IN_SECOND);
}

export const SECONDS_IN_MINUTE = 60;

export function secondsToMinutes(seconds: number): number {
    // A minute is a basic unit of measurement here.
    return Math.round(seconds / SECONDS_IN_MINUTE);
}

export function minutesToSeconds(minutes: number): number {
    return minutes * SECONDS_IN_MINUTE;
}

// Timezones

export function timezoneToDisplayString(timezone: string): string {
    return timezone.replace('_', ' ');
}

// Predefined ranges

export enum PredefinedRangeType {
    /** available in Free plan */
    today = 'today',
    /** available in Free plan */
    last7Days = 'last7Days',
    /** available only in Pro plan */
    last14Days = 'last14Days',
    /** available only in Pro plan */
    last30Days = 'last30Days',
    /** available only in Pro plan */
    last12Months = 'last12Months',
    /** not supported now */
    custom = 'custom',
}

export const freeTierPredefinedRanges = [ PredefinedRangeType.today, PredefinedRangeType.last7Days ];

export type PredefinedRange = z.infer<typeof zPredefinedRange>;
export const zPredefinedRange = z.object({
    type: z.enum([
        PredefinedRangeType.today,
        PredefinedRangeType.last7Days,
        PredefinedRangeType.last14Days,
        PredefinedRangeType.last30Days,
        PredefinedRangeType.last12Months,
    ]),
}).or(z.object({
    type: z.literal(PredefinedRangeType.custom),
    start: zDateTime,
    end: zDateTime,
}));

export function predefinedRangeToDateRange(range: PredefinedRange, timezone: string | Zone): DateRange {
    const now = DateTime.now().setZone(timezone);

    switch (range.type) {
    case PredefinedRangeType.today:
        return { start: now.startOf('day'), end: now };
    case PredefinedRangeType.last7Days:
        return { start: now.minus({ days: 7 }).startOf('day'), end: now };
    case PredefinedRangeType.last14Days:
        return { start: now.minus({ days: 14 }).startOf('day'), end: now };
    case PredefinedRangeType.last30Days:
        return { start: now.minus({ days: 31 }).startOf('day'), end: now };
    case PredefinedRangeType.last12Months:
        return { start: now.minus({ months: 12 }).startOf('month'), end: now };
    case PredefinedRangeType.custom:
        return { start: range.start, end: range.end };
    }
}
