import { TypedError } from './typed.error';

export class CountryNotSupportedError extends TypedError {
    static readonly type = 'stripe.countryNotSupported';

    constructor(
        readonly country: string,
    ) {
        super(CountryNotSupportedError.type);
    }
}
