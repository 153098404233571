import { routesFE } from ':utils/routes';
import type React from 'react';
import { Link } from 'react-router-dom';

type NoPaymentMethodBannerProps = {
    text: React.ReactNode;
}

export function NoPaymentMethodBanner(props: NoPaymentMethodBannerProps) {
    return (
        <Link to={routesFE.payments.resolve({})}>
            <div className='bg-warning-400 p-2 w-full text-center text-secondary-900 hover:text-secondary-500'>
                {props.text}
            </div>
        </Link>
    );
}
