import { useMemo } from 'react';
import { MoneyDisplay } from ':components/custom';
import { Form } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { isParticipantBillable, type EditablePayingParticipant } from ':frontend/types/EventParticipant';
import { type CurrencyId, computeTax, type TaxRate, toMoney, amountFromDecimal, type Money, getAmountWithTax, taxRateIsZero } from ':utils/money';
import type { UseEventDispatch, UseEventState } from './useEvent';
import { toNumber } from ':utils/math';
import { useMaster } from ':frontend/context/UserProvider';
import { TaxRateInput } from '../forms/TaxRateInput';

type PriceSummaryProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
    totalEvents: number;
}>;

export function EventPriceSummary({ state, dispatch, totalEvents }: PriceSummaryProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventPayment' });
    const { teamSettings } = useMaster();
    const payment = state.payment!;

    const summary = useMemo(() => payment?.clients && computeEventPriceSummary(payment.clients, totalEvents), [ payment?.clients, totalEvents ]);
    if (!summary)
        return null;

    if (summary === 'infinity') {
        return (
            <div className='text-center text-secondary-400'>
                {t('inifinite-events')}
            </div>
        );
    }

    return (
        <div className='ps-2 space-y-2'>
            {teamSettings.isTaxesModuleOn && (
                <div className='flex items-center gap-2'>
                    <Form.Label className='m-0'>{t('taxRate-label')}</Form.Label>

                    <TaxRateInput
                        size='exact'
                        variant='transparent'
                        value={payment.taxRate}
                        onChange={value => dispatch({ type: 'payment', operation: 'taxRate', value })}
                    />

                    <div className='grow' />

                    <MoneyDisplay money={summary.taxSummary.difference} className='font-medium' />
                </div>
            )}

            {!summary.isOnlyZeroTax && (
                <div className='flex justify-between text-secondary-500'>
                    {t('without-tax-label')}
                    <MoneyDisplay money={summary.taxSummary.withoutTax} className='font-medium' />
                </div>
            )}

            <div className='flex justify-between font-semibold'>
                {t('total-label')}
                <MoneyDisplay money={summary.taxSummary.withTax} />
            </div>
        </div>
    );
}

type PriceSummary = {
    taxSummary: TaxSummary;
    isOnlyZeroTax: boolean;
};

type TaxSummary = {
    taxRate: TaxRate;
    withoutTax: Money;
    withTax: Money;
    difference: Money;
};

function computeEventPriceSummary(clients: EditablePayingParticipant[], totalEvents: number): PriceSummary | 'infinity' | undefined {
    if (clients.length === 0)
        return undefined;

    if (!isFinite(totalEvents))
        return 'infinity';

    const currency = clients[0].currency;
    const taxRate = clients[0].taxRate;

    let totalWithoutTax = 0;
    let totalWithTax = 0;
    let totalDifference = 0;
    clients.forEach(client => {
        const { withoutTax, withTax, difference } = computeTax(amountFromDecimal(toNumber(client.priceInDecimal)), taxRate);
        totalWithoutTax += withoutTax;
        totalWithTax += withTax;
        totalDifference += difference;
    });

    return {
        taxSummary: {
            taxRate,
            withoutTax: toMoney(totalWithoutTax * totalEvents, currency),
            withTax: toMoney(totalWithTax * totalEvents, currency),
            difference: toMoney(totalDifference * totalEvents, currency),
        },
        isOnlyZeroTax: taxRateIsZero(taxRate),
    };
}

type OrderSummaryProps = Readonly<{
    state: UseEventState;
}>;

export function OrderSummary({ state }: OrderSummaryProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventPayment' });
    const payment = state.payment!;

    const summary = useMemo(() => computeOrderPriceSummary(payment.clients), [ payment.clients ]);
    if (!summary)
        return null;

    return (
        <div className='pl-2 space-y-2'>
            {!summary.isAllPaid && (
                <div className='flex justify-between text-secondary-500'>
                    {t('paid-label')}
                    <MoneyDisplay money={summary.paid} className='font-medium' />
                </div>
            )}

            <div className='flex justify-between font-semibold'>
                {t('total-label')}
                <MoneyDisplay money={summary.total} />
            </div>
        </div>
    );
}

type OrderPriceSummary = {
    isBillable: boolean;
    isAllPaid: boolean;
    paid: Money;
    total: Money;
};

function computeOrderPriceSummary(clients: EditablePayingParticipant[]): OrderPriceSummary | undefined {
    if (clients.length === 0)
        return undefined;

    const currency = clients[0].currency;
    const paidClients = clients.filter(c  => c.original?.isPaid);
    const billableClients = clients.filter(c => !c.original || isParticipantBillable(c.original));

    return {
        isBillable: billableClients.length > 0,
        isAllPaid: paidClients.length === clients.length,
        paid: sumWithTax(paidClients, currency),
        total: sumWithTax(clients, currency),
    };
}

function sumWithTax(clients: EditablePayingParticipant[], currency: CurrencyId): Money {
    const amount = clients.reduce((ans, p) => ans + getAmountWithTax(amountFromDecimal(toNumber(p.priceInDecimal)), p.taxRate), 0);
    return { amount, currency };
}
