import { useMemo } from 'react';
import { MoneyDisplayString } from ':components/custom';
import { useTranslation } from 'react-i18next';
import { itemsDeletable, type OrderFormState, type UseOrderDispatch, type UseOrderState } from './useOrder';
import { Form, Card, Button } from ':components/shadcn';
import { transformToPrice, transformToPriceNegative } from ':utils/math';
import { TaxRateInput } from '../forms/TaxRateInput';
import { type CustomOrderItemFE, type EditableCustomItem } from ':frontend/types/orders/CustomOrderItem';
import { TranslatedErrorMessage } from '../forms/ErrorMessage';
import { type FormPath } from ':frontend/utils/updator';
import { Trash2Icon, PlusIcon } from ':components/icons/basic';
import { taxRateIsZero, taxRateLabel } from ':utils/money';

type OrderItemsListCustomProps = Readonly<{
    items: CustomOrderItemFE[];
}>;

export function OrderItemsListCustom({ items }: OrderItemsListCustomProps) {
    const { t } = useTranslation('components', { keyPrefix: 'orderItemsList' });

    const showTax = useMemo(() => items.some(item => !taxRateIsZero(item.taxRate)), [ items ]);

    return (
        <div className='space-y-4'>
            <div className='font-semibold text-lg'>{t('custom-title')}</div>

            <div className='space-y-2'>
                {items.map(item => (
                    <OrderItemsListCustomRow key={item.id} item={item} showTax={showTax} />
                ))}
            </div>
        </div>
    );
}

type OrderItemsListCustomRowProps = Readonly<{
    item: CustomOrderItemFE;
    showTax: boolean;
}>;

function OrderItemsListCustomRow({ item, showTax }: OrderItemsListCustomRowProps) {
    return (
        <Card key={item.id} className='max-sm:space-y-6 sm:flex sm:items-center sm:justify-between sm:gap-4'>
            <div className='font-semibold text-lg/5 truncate'>{item.title}</div>

            <div className='flex items-center gap-8 font-semibold whitespace-nowrap'>
                <div>{item.quantity}×</div>

                {showTax && <div className='font-semibold whitespace-nowrap'>{taxRateLabel(item.taxRate)}</div>}

                <div className='font-semibold whitespace-nowrap'>
                    <MoneyDisplayString money={item.unitPrice} />
                </div>
            </div>
        </Card>
    );
}

type OrderItemsListCustomFormProps = Readonly<{
    state: UseOrderState;
    form: OrderFormState;
    dispatch: UseOrderDispatch;
}>;

export function OrderItemsListCustomForm({ state, form, dispatch }: OrderItemsListCustomFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'orderItemsList' });

    // FIXME or when tax module enabled?
    const showTax = useMemo(() => state.order.items.some(item => !taxRateIsZero(item.taxRate)), [ state.order.items ]);

    const deletable = itemsDeletable(form);

    return (
        <div className='space-y-4'>
            <div className='font-semibold text-lg'>{t('custom-title')}</div>

            <div className='space-y-2'>
                {form.customItems.map((item, index) => (
                    <CustomItemRowForm
                        key={item.id}
                        state={state}
                        dispatch={dispatch}
                        item={item}
                        index={index}
                        showTax={showTax}
                        deletable={deletable}
                    />
                ))}
            </div>
        </div>
    );
}

type CustomItemRowFormProps = Readonly<{
    state: UseOrderState;
    dispatch: UseOrderDispatch;
    item: EditableCustomItem;
    index: number;
    showTax: boolean;
    deletable: boolean;
}>;

function CustomItemRowForm({ state, dispatch, item, index, showTax, deletable }: CustomItemRowFormProps) {
    const { t: to } = useTranslation('components', { keyPrefix: 'orderItemsList' });

    function input(field: FormPath<EditableCustomItem>, value: unknown) {
        dispatch({ type: 'input', field: `customItems.${index}.${field}`, value });
    }

    return (
        <Card key={item.id} className='max-sm:space-y-4 sm:flex sm:items-center sm:justify-between sm:gap-4'>
            <div className='w-full'>
                <Form.Input
                    value={item.title}
                    size='compact'
                    onChange={e => input('title', e.target.value)}
                    disabled={item.isDeleted}
                />

                {!item.isDeleted && (
                    <TranslatedErrorMessage translationId={state.formErrors?.[`customItems.${index}.title`]} />
                )}
            </div>

            <div className='max-sm:space-y-4 sm:flex sm:items-center sm:gap-4'>
                <div className='flex items-center gap-4'>
                    <div className='w-full sm:w-16'>
                        <Form.Input
                            type='number'
                            size='compact'
                            value={item.quantity}
                            onChange={e => input('quantity', transformToPrice(e.target.value))}
                            disabled={item.isDeleted}
                        />
                    </div>

                    {showTax && (
                        <div className='w-full sm:w-20'>
                            <TaxRateInput
                                size='compact'
                                value={item.taxRate}
                                onChange={value => input('taxRate', value)}
                                disabled={item.isDeleted}
                            />
                        </div>
                    )}
                </div>

                <div className='w-full sm:w-24'>
                    <Form.Input
                        type='number'
                        size='compact'
                        value={item.unitPriceInDecimal}
                        onChange={e => input('unitPriceInDecimal', transformToPriceNegative(e.target.value))}
                        disabled={item.isDeleted}
                    />
                </div>

                <div className='max-sm:flex max-sm:justify-end'>
                    {!item.isDeleted ? (
                        <Button variant='transparent' size='exact' className='shrink-0' disabled={!deletable} onClick={() => input('isDeleted', true)} aria-label={to('delete-aria', { name: item.title })}>
                            <Trash2Icon />
                        </Button>
                    ) : (
                        <Button variant='transparent' size='exact' className='shrink-0' onClick={() => input('isDeleted', false)} aria-label={to('add-aria', { name: item.title })}>
                            <PlusIcon />
                        </Button>
                    )}
                </div>
            </div>
        </Card>
    );
}
