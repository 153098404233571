import { isBooleanFeature, type StiggFeature, type StiggFeatureOutput } from ':utils/lib/stigg';
import { useStiggContext } from '@stigg/react-sdk';

export function useEntitlement<TFeature extends StiggFeature>(feature: TFeature): StiggFeatureOutput<TFeature> {
    // Not optional - we want this to fail if the context isn't available.
    const { stigg } = useStiggContext();

    if (isBooleanFeature(feature))
        return stigg.getBooleanEntitlement({ featureId: feature }).hasAccess as StiggFeatureOutput<TFeature>;

    return stigg.getNumericEntitlement({ featureId: feature }).value as StiggFeatureOutput<TFeature>;
}
