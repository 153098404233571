import { type FilterDefinition, type FilterFunction, type FilterMenuProps } from './FilterRow';
import { type EventFE } from ':frontend/types/Event';
import { EventStateBadge } from ':frontend/components/event/EventStateBadge';
import { Button, Form, DropdownMenu } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { compareArrays } from ':frontend/utils/common';
import type { CalendarEvent } from ':frontend/types/calendar/Calendar';
import { EventState, getEventState } from ':utils/entity/event';
import { getEnumValues } from ':utils/common';
import { CircleCopy1Icon } from ':components/icons/basic';

const filterName = 'eventState';

type FilterState = {
    [key in EventState]: boolean;
};

function RowMenu({ state, setState }: FilterMenuProps<FilterState>) {
    const { t } = useTranslation('common', { keyPrefix: 'filters' });
    const selectedStates = EVENT_STATE_VALUES.filter(eventState => state[eventState]);

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <Button variant='outline' className={selectedStates.length > 0 ? 'border-primary text-primary hover:border-primary' : undefined}>
                    {selectedStates.length > 0 ? (
                        selectedStates.map(eventState => <EventStateBadge key={eventState} state={eventState} />)
                            .flatMap((e, index) => [ e, <span key={`or_${index}`}>/</span> ])
                            .slice(0, -1)
                    ) : (
                        <div className='flex items-center gap-2'>
                            <CircleCopy1Icon size='md' /> {t(`${filterName}.menu-button`)}
                        </div>
                    )}
                </Button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Content side='bottom' align='start'>
                <div className='p-1.5'>
                    {EVENT_STATE_VALUES.map(eventState => (
                        <div
                            key={eventState}
                            className='flex items-center gap-4 px-4 py-3 rounded-sm cursor-pointer hover:bg-primary-100'
                            onClick={() => setState({ ...state, [eventState]: !state[eventState] })}
                        >
                            <Form.Checkbox checked={state[eventState]} />
                            <EventStateBadge state={eventState} />
                        </div>
                    ))}
                </div>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
}

const EVENT_STATE_VALUES = getEnumValues(EventState);

function createFilterFunction(state: FilterState): FilterFunction<EventFE> {
    const selectedStates = EVENT_STATE_VALUES.filter(eventState => state[eventState]);
    if (selectedStates.length === 0 || selectedStates.length === EVENT_STATE_VALUES.length)
        return () => true;

    return (data: EventFE) => !!state[getEventState(data)];
}

function createCalendarFilterFunction(state: FilterState): FilterFunction<CalendarEvent> {
    const selectedStates = EVENT_STATE_VALUES.filter(eventState => state[eventState]);
    if (selectedStates.length === 0 || selectedStates.length === EVENT_STATE_VALUES.length)
        return () => true;

    return (data: CalendarEvent) => data.resource.type !== 'event' || !!state[getEventState(data.resource.event)];
}

function toServer(state: FilterState, previous: EventState[] | undefined): EventState[] | undefined {
    const current = EVENT_STATE_VALUES.filter(eventState => state[eventState]);
    if (current.length === 0 || current.length === EVENT_STATE_VALUES.length)
        return;

    return previous && compareArrays(current, previous)
        ? previous
        : current;
}

export const EventStateFilter: FilterDefinition<FilterState, undefined, EventFE, EventState[]> = {
    name: filterName,
    defaultState: {
        [EventState.ready]: false,
        [EventState.finished]: false,
        [EventState.canceled]: false,
    },
    RowMenu,
    createFilterFunction,
    toServer,
};

export const CalendarEventStateFilter: FilterDefinition<FilterState, undefined, CalendarEvent, EventState[]> = {
    ...EventStateFilter,
    createFilterFunction: createCalendarFilterFunction,
};
