import { createEnv } from '@t3-oss/env-core';
import { z } from 'zod';

// Even though your editor probably tells you that import.meta.env === process.env, it's not the case every time.
// This misinformation comes from the bun-types (aka @types/bun) package.
// In apps/backend, they equal.
// However in apps/frontend and apps/store, they may or may not equal.
// In the vite.config.ts files, they equal.
// In the browser, process.env is undefined and import.meta.env is created by Vite,
//   it contains Vite-managed properties like BASE_URL and SSR and public env vars prefixed by VITE_.
// During SSR, process.env is created by Bun and contains all env vars including the server-only ones,
//   whereas import.meta.env is created by Vite and behaves the same as in the browser.
// We need to access server-only env vars during SSR to connect to the database etc.
// That's what this monstrosity is for.
// It isn't well-tested what happens during vite build.
// Uncomment the following lines for debugging:
// const processEnv = typeof process !== 'undefined' ? process.env : undefined;
// const importEnv = import.meta.env;
// console.log({processEnv, importEnv})
const internalEnv = typeof process !== 'undefined' ? process.env : import.meta.env;

const zBooleanString = z.enum([ 'true', 'false' ]).transform(value => value === 'true');

export const env = createEnv({
    clientPrefix: 'VITE_',
    client: {
        VITE_APP_VERSION: z.string().optional().transform(value => value || 'development'),

        VITE_APP_URL: z.string().url(),
        /** eg. 'localhost:3034', 'mystaging.flowlance.com', 'store.mariansam.eu' */
        VITE_DEFAULT_STORE_DOMAIN: z.string(),

        VITE_DNS_VALUES: zJson(z.object({
            A: z.string(),
            AAAA: z.string().optional(),
            CNAME: z.string(),
        })),

        VITE_SMALLCHAT_ID: z.string(),

        VITE_GTM_ID: z.string().optional(),

        VITE_POSTHOG_API_KEY: z.string().optional(),
        VITE_POSTHOG_HOST: z.string().optional(),
        VITE_POSTHOG_UI_HOST: z.string().optional(),

        VITE_SENTRY_DSN_FRONTEND: z.string().optional(),
        VITE_SENTRY_ENVIRONMENT: z.string().optional(),

        VITE_STIGG_API_KEY: z.string(),

        VITE_REACT_SCAN_OFF: zBooleanString.optional(),

        /** @deprecated Remove this once PayPal is fully integrated. */
        VITE_DISABLE_PAYPAL: zBooleanString.optional(),
    },
    server: {
        // https://nodejs.org/en/learn/getting-started/nodejs-the-difference-between-development-and-production
        NODE_ENV: z.enum([ 'development', 'production', 'test' ]).default('production'),
        JEST_WORKER_ID: z.coerce.number().optional(),

        DATABASE_USER: z.string(),
        DATABASE_PASSWORD: z.string(),
        DATABASE_NAME: z.string(),
        DATABASE_URL: z.string(),

        MONGO_USER: z.string(),
        MONGO_PASSWORD: z.string(),
        MONGO_DATABASE: z.string(),
        MONGO_URL: z.string(),

        REDIS_PORT: z.coerce.number(),
        REDIS_HOST: z.string(),

        STRIPE_API_KEY: z.string(),
        STRIPE_ACCOUNT_UPDATED_EVENT_SECRET: z.string(),
        STRIPE_SESSION_EVENT_SECRET: z.string(),
        STRIPE_SUBSCRIPTION_EVENT_SECRET: z.string(),

        PAYPAL_ACCOUNT_ID: z.string(),
        PAYPAL_CLIENT_ID: z.string(),
        PAYPAL_CLIENT_SECRET: z.string(),
        PAYPAL_ORDER_WEBHOOK_ID: z.string(),
        PAYPAL_INTEGRATION_WEBHOOK_ID: z.string(),

        STIGG_API_KEY: z.string(),
        STIGG_WEBHOOK_SECRET: z.string(),

        MAILCHIMP_API_KEY: z.string().optional(),
        MAILCHIMP_SERVER: z.string().optional(),
        MAILCHIMP_LIST_ID: z.string().optional(),

        APP_SECRET: z.string(),
        APP_SITEMAP_SECRET: z.string(),

        AUTH_GOOGLE_ID: z.string(),
        AUTH_GOOGLE_SECRET: z.string(),

        IMAP_USER: z.string().optional(),
        IMAP_PASSWORD: z.string().optional(),
        IMAP_HOST: z.string().optional(),
        IMAP_PORT: z.coerce.number().optional(),
        IMAP_MAILBOX: z.string().optional(),

        SMTP_URL: z.string().optional(),
        SMTP_USER: z.string().optional(),
        SMTP_PASSWORD: z.string().optional(),
        SMTP_SENDER: z.string(),
        SMTP_HOST: z.string(),
        SMTP_PORT: z.coerce.number(),
        SMTP_SECURE: zBooleanString,

        OPENAI_API_KEY: z.string().startsWith('sk'),

        SLACK_TOKEN: z.string().optional(),
        SLACK_CHANNEL_ID: z.string().optional(),

        SENTRY_DSN_BACKEND: z.string().optional(),
        SENTRY_AUTH_TOKEN: z.string().optional(),

        CURRENCYAPI_KEY: z.string().optional(),

        CUSTOMERSCORE_API_URL: z.string().optional(),
        CUSTOMERSCORE_API_KEY: z.string().optional(),
    },
    emptyStringAsUndefined: true,
    runtimeEnv: internalEnv,
});

function zJson<TOutput>(schema: z.ZodType<TOutput>) {
    return z.string().transform((value, ctx) => {
        let parsedJson;

        try {
            parsedJson = JSON.parse(value);
        }
        catch {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Invalid JSON',
            });
            return z.NEVER;
        }

        const parsed = schema.safeParse(parsedJson);
        if (!parsed.success) {
            for (const issue of parsed.error.issues)
                ctx.addIssue(issue);
            return z.NEVER;
        }

        return parsed.data;
    });
}
