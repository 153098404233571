import { type Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { customOrderToServer, OrderFE } from ':frontend/types/orders/Order';
import { CustomOrderForm } from ':frontend/components/orders/CustomOrderForm';
import { useClients, type NavigationProperty, useNavigationAction } from ':frontend/hooks';
import { useCached } from ':components/hooks';
import { type Id } from ':utils/id';
import { routesFE } from ':utils/routes';
import { CheckoutModalInner, type CheckoutOutput } from ':frontend/components/orders/checkout/CheckoutModalInner';
import { createCustomInvoicePreviewInit, type CheckoutInput } from ':frontend/components/orders/checkout/useCheckout';
import { trpc } from ':frontend/context/TrpcProvider';
import { Modal } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { tryComputePreviewCheckoutInput, useCustomOrder, type PreselectedData, type UseCustomOrderState } from ':frontend/components/orders/useCustomOrder';
import type { ClientInfoFE } from ':frontend/types/Client';
import { api } from ':frontend/utils/api';
import { useMaster } from ':frontend/context/UserProvider';
import { getInvoicePreviewError, InvoicePreviewErrorDisplay, type InvoicePreviewError } from ':frontend/components/orders/checkout/InvoicePreviewModal';
import { PaymentMethod } from ':utils/entity/invoicing';
import { StripeConnectionState } from ':utils/entity/team';

export function NewCustomOrderTab() {
    const { clients } = useClients();

    const action = useNavigationAction<PreselectCustom>('preselectCustom');
    const [ preselected, setPreselected ] = useState(initialPreselectedState(action?.data));

    const trpcUtils = trpc.useUtils();

    const fetchOrder = useCallback(async (id: Id) => {
        try {
            const response = await trpcUtils.order.getOrder.fetch({ id });
            const order = OrderFE.fromServer(response);
            setPreselected({ order });
        }
        catch {
            setPreselected(undefined);
        }
    }, [ trpcUtils ]);

    useEffect(() => {
        if (!action?.data?.orderId)
            return;

        fetchOrder(action?.data?.orderId);
    }, [ action, fetchOrder ]);

    if (!clients || preselected === 'fetching')
        return null;

    return (
        <NewCustomOrderTabInner clients={clients} preselected={preselected} />
    );
}

type NewCustomOrderTabInnerProps = Readonly<{
    clients: ClientInfoFE[];
    preselected: PreselectedData | undefined;
}>;

function NewCustomOrderTabInner({ clients, preselected }: NewCustomOrderTabInnerProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'directSale.custom' });

    const { state, dispatch } = useCustomOrder(clients, preselected);

    const navigate = useNavigate();

    const checkoutOutput = useCallback((action: CheckoutOutput) => {
        switch (action.type) {
        case 'back':
            dispatch({ type: 'form', operation: 'checkoutBack' });
            break;
        case 'finish':
            navigate(routesFE.orders.list.path);
            break;
        }
    }, [ navigate ]);

    return (
        <div className='px-4'>
            <CheckoutModal input={state.checkout} output={checkoutOutput} />

            <div className='max-w-[1040px] w-full mx-auto pt-6 pb-24'>
                <h1 className='font-semibold text-2xl text-secondary-700 leading-7'>{t('title')}</h1>

                <div className='mt-6 grid grid-cols-1 lg:grid-cols-2 gap-2 rounded-xl bg-secondary-100'>
                    <CustomOrderForm
                        clients={clients}
                        state={state}
                        dispatch={dispatch}
                    />

                    <InvoicePreview state={state} />
                </div>
            </div>
        </div>
    );
}

export type PreselectCustom = NavigationProperty<'preselectCustom', {
    clientId?: Id;
    orderId?: Id;
}>;

type PreselectedState = PreselectedData | 'fetching';

function initialPreselectedState(input: PreselectCustom['data']): PreselectedState | undefined {
    if (!input)
        return undefined;

    if (input.clientId)
        return { clientId: input.clientId };

    if (input.orderId)
        return 'fetching';
}

type CheckoutModalProps = Readonly<{
    input?: CheckoutInput;
    output: Dispatch<CheckoutOutput>;
}>;

function CheckoutModal({ input, output }: CheckoutModalProps) {
    const cachedInput = useCached(input);
    if (!cachedInput)
        return null;

    return (
        <Modal.Root open={!!input}>
            <Modal.OuterContent>
                <CheckoutModalInner input={cachedInput} output={output} />
            </Modal.OuterContent>
        </Modal.Root>
    );
}

/** How long the user has to be idle in order to try new refresh. In milliseconds. */
const DEBOUNCE_DELAY = 1500;

function InvoicePreview({ state }: { state: UseCustomOrderState }) {
    const { t, i18n } = useTranslation('pages', { keyPrefix: 'directSale.custom' });
    const masterContext = useMaster();

    const [ pdfBlobUrl, setPdfBlobUrl ] = useState('');
    const [ error, setError ] = useState<{ type: 'invalid-form' } | InvoicePreviewError>();

    const refresh = useCallback(async (form: UseCustomOrderState['form']) => {
        const input = tryComputePreviewCheckoutInput(form);
        if (!input) {
            setError({ type: 'invalid-form' });
            return;
        }

        // we don't care if a bank account for the currency is available as BE creates a dummy one
        const paymentMethod = (masterContext.team.stripeConnectionState === StripeConnectionState.connected) ? PaymentMethod.stripe : PaymentMethod.bankTransfer;
        const previewInit = createCustomInvoicePreviewInit(input, paymentMethod);
        const custom = customOrderToServer(previewInit, masterContext, i18n);

        const response = await api.backend.getInvoicePreview({ custom });
        if (!response.status) {
            const newError = await getInvoicePreviewError(response.error);
            setError(newError);

            return;
        }

        const blobUrl = URL.createObjectURL(response.data);
        setPdfBlobUrl(blobUrl + '#toolbar=0&navpanes=0');
        setError(undefined);
    }, [ i18n, masterContext ]);

    const refreshTimeout = useRef<Timer>();

    useEffect(() => {
        if (refreshTimeout.current)
            clearTimeout(refreshTimeout.current);

        refreshTimeout.current = setTimeout(() => {
            refresh(state.form);
            refreshTimeout.current = undefined;
        }, DEBOUNCE_DELAY);

        return () => {
            clearTimeout(refreshTimeout.current);
            refreshTimeout.current = undefined;
        };
    }, [ state.form, refresh ]);

    return (
        <div className='px-4 md:px-8 py-6 flex flex-col gap-4'>
            <h2 className='text-lg text-secondary-400'>{t('preview-title')}</h2>

            <div className='grow h-full w-full rounded bg-white'>
                {error ? (
                    <div className='py-12 text-center text-secondary-400'>
                        {error.type === 'invalid-form' ? (
                            <span>
                                {t('invalid-form-error')}
                            </span>
                        ) : (
                            <InvoicePreviewErrorDisplay error={error} />
                        )}
                    </div>
                ) : pdfBlobUrl ? (
                    <iframe
                        className='w-full h-full rounded max-lg:min-h-[400px]'
                        src={pdfBlobUrl}
                        title='Invoice Preview'
                    />
                ) : (
                    <div className='flex justify-center items-center h-full' />
                )}
            </div>
        </div>
    );
}
