import type { CalendarEvent } from ':frontend/types/calendar/Calendar';
import sortBy from 'lodash/sortBy';
import type { SlotMetrics } from './timeSlots';

export type StyledEvent = {
    event: CalendarEvent;
    style: {
        top: number;
        height: number;
        width: number;
        xOffset: number;
    };
}

// FIXME This algorithm is totally insecure. We should rewrite it to eliminate all these "is it container? is it leaf?" checks.

class Event {
    readonly start: number;
    readonly end: number;
    readonly startMs: number;
    readonly endMs: number;
    readonly top: number;
    readonly height: number;
    readonly data: CalendarEvent;

    rows: Event[] | undefined;
    leaves: Event[] | undefined;
    container: Event | undefined;
    row: Event | undefined;

    constructor(data: CalendarEvent, slotMetrics: SlotMetrics) {
        const { start, startDate, end, endDate, top, height } = slotMetrics.getRange(data.start, data.end);

        this.start = start;
        this.end = end;
        this.startMs = +startDate;
        this.endMs = +endDate;
        this.top = top;
        this.height = height;
        this.data = data;
    }

    /**
     * The event's width without any overlap.
     */
    private get _width(): number {
        // The container event's width is determined by the maximum number of events in any of its rows.
        if (this.rows) {
            // Add itself (+ 1) and then the container (another + 1).
            const columns = this.rows.reduce((max, row) => {
                if (!row.leaves)
                    throw new Error('This should not happen ...');

                return Math.max(max, row.leaves.length + 1);
            }, 0) + 1;

            return 100 / columns;
        }

        // The row event's width is the space left by the container, divided among itself and its leaves.
        if (this.leaves) {
            if (!this.container)
                throw new Error('This should not happen ...');

            const availableWidth = 100 - this.container._width;
            return availableWidth / (this.leaves.length + 1);
        }

        if (!this.row)
            throw new Error('This should not happen ...');

        // The leaf event's width is determined by its row's width.
        return this.row._width;
    }

    /**
     * The event's calculated width, possibly with extra width added for overlapping effect.
     */
    get width(): number {
        const noOverlap = this._width;
        const overlap = Math.min(100, this._width * 1.7);

        // Containers can always grow.
        if (this.rows)
            return overlap;


        // Rows can grow if they have leaves.
        if (this.leaves)
            return this.leaves.length > 0 ? overlap : noOverlap;

        if (!this.row)
            throw new Error('This should not happen ...');

        // Leaves can grow unless they're the last item in a row.
        const { leaves } = this.row;
        if (!leaves)
            throw new Error('This should not happen ...');

        const index = leaves.indexOf(this);
        return index === leaves.length - 1 ? noOverlap : overlap;
    }

    get xOffset(): number {
        // Containers have no offset.
        if (this.rows)
            return 0;

        // Rows always start where their container ends.
        if (this.leaves) {
            if (!this.container)
                throw new Error('This should not happen ...');

            return this.container._width;
        }

        if (!this.row)
            throw new Error('This should not happen ...');

        // Leaves are spread out evenly on the space left by its row.
        const { leaves, xOffset, _width } = this.row;
        if (!leaves)
            throw new Error('This should not happen ...');

        const index = leaves.indexOf(this) + 1;
        return xOffset + index * _width;
    }
}

/**
 * Return true if event a and b is considered to be on the same row.
 */
function onSameRow(a: Event, b: Event, minimumStartDifference: number) {
    return (
        // Occupies the same start slot.
        Math.abs(b.start - a.start) < minimumStartDifference ||
        // A's start slot overlaps with b's end slot.
        (b.start > a.start && b.start < a.end)
    );
}

function sortByRender(events: Event[]): Event[] {
    const sortedByTime = sortBy(events, [ 'startMs', e => -e.endMs ]);

    const sorted = [];
    while (sortedByTime.length > 0) {
        const event = sortedByTime.shift()!;
        sorted.push(event);

        for (let i = 0; i < sortedByTime.length; i++) {
            const test = sortedByTime[i];

            // Still inside this event, look for next.
            if (event.endMs > test.startMs)
                continue;

            // We've found the first event of the next event group.
            // If that event is not right next to our current event, we have to move it here.
            if (i > 0) {
                const event = sortedByTime.splice(i, 1)[0];
                sorted.push(event);
            }

            // We've already found the next event group, so stop looking.
            break;
        }
    }

    return sorted;
}

export function getStyledEventsOverlap(events: CalendarEvent[], minimumStartDifference: number, slotMetrics: SlotMetrics): StyledEvent[] {
    // Create proxy events and order them so that we don't have to fiddle with z-indexes.
    const proxies = events.map(event => new Event(event, slotMetrics));
    const eventsInRenderOrder = sortByRender(proxies);

    // Group overlapping events, while keeping order.
    // Every event is always one of: container, row or leaf.
    // Containers can contain rows, and rows can contain leaves.
    const containerEvents: Event[] = [];
    for (let i = 0; i < eventsInRenderOrder.length; i++) {
        const event = eventsInRenderOrder[i];

        // Check if this event can go into a container event.
        const container = containerEvents.find(c => c.end > event.start || Math.abs(event.start - c.start) < minimumStartDifference);

        // Couldn't find a container — that means this event is a container.
        if (!container) {
            event.rows = [];
            containerEvents.push(event);
            continue;
        }

        // Found a container for the event.
        event.container = container;

        if (!container.rows)
            throw new Error('This should not happen ...');

        // Check if the event can be placed in an existing row. Start looking from behind.
        let row = undefined;
        for (let j = container.rows.length - 1; !row && j >= 0; j--) {
            if (onSameRow(container.rows[j], event, minimumStartDifference))
                row = container.rows[j];
        }

        if (row) {
            if (!row.leaves)
                throw new Error('This should not happen ...');

            // Found a row, so add it.
            row.leaves.push(event);
            event.row = row;
        }
        else {
            event.leaves = [];
            // Couldn't find a row – that means this event is a row.
            container.rows.push(event);
        }
    }

    // Return the original events, along with their styles.
    return eventsInRenderOrder.map(event => ({
        event: event.data,
        style: {
            top: event.top,
            height: event.height,
            width: event.width,
            xOffset: Math.max(0, event.xOffset),
        },
    }));
}
