import { createContext, type ReactNode, useContext, useRef, useState, useMemo, useCallback } from 'react';
import { Visibility, type Alert, type AlertContent } from ':frontend/types/notifications';
import { generateId } from ':frontend/utils/common';
import { secondsToMilliseconds } from ':utils/dateTime';

export type AddAlertFunction = (content: AlertContent, visibility?: Visibility) => void;

export type NotificationContext = {
    alerts: Alert[];
    addAlert: AddAlertFunction;
    removeAlert: (id: number) => void;
    removeAllAlerts: () => void;
};

const notificationContext = createContext<NotificationContext | undefined>(undefined);

type NotificationProviderProps = Readonly<{
    children: ReactNode;
}>;

const FADE_OUT_TIMEOUT = 2000; // After how long we should remove the fading-out alerts.

export function NotificationProvider({ children }: NotificationProviderProps) {
    const [ alerts, setAlerts ] = useState<Alert[]>([]);
    const alertsRef = useRef(alerts);
    alertsRef.current = alerts;

    const removeAlert = useCallback((id: number) => {
        alertsRef.current = alertsRef.current.map(alert => alert.id !== id
            ? alert
            : {
                ...alert,
                show: false,
                removedIn: Date.now(),
            })
            .filter(alert => !alert.removedIn || alert.removedIn + FADE_OUT_TIMEOUT > Date.now());

        setAlerts(alertsRef.current);
    }, []);

    const removeAllAlerts = useCallback(() => {
        alertsRef.current = [];
        setAlerts(alertsRef.current);
    }, []);

    const addAlert: AddAlertFunction = useCallback((content: AlertContent, visibility = Visibility.Authorized) => {
        const newAlert: Alert = {
            content,
            id: generateId(),
            show: true,
            visibility,
        };

        alertsRef.current = [
            ...alertsRef.current.filter(alert => !alert.removedIn || alert.removedIn + FADE_OUT_TIMEOUT > Date.now()),
            newAlert,
        ];

        setAlerts(alertsRef.current);

        if (content.timeout)
            setTimeout(() => removeAlert(newAlert.id), secondsToMilliseconds(content.timeout));
    }, [ removeAlert ]);

    const value = useMemo(() => ({ alerts, addAlert, removeAlert, removeAllAlerts }), [ alerts, addAlert, removeAlert, removeAllAlerts ]);

    return (
        <notificationContext.Provider value={value}>
            { children }
        </notificationContext.Provider>
    );
}

export default function useNotifications(): NotificationContext {
    const context = useContext(notificationContext);
    if (context === undefined)
        throw new Error('Alert context must be used within an NotificationProvider');

    return context;
}
