import { createTFunctionWithVariant } from ':utils/i18n';
import type { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export * from './useBlocker';
export * from './useDaysLeft';
export * from './useEditing';
export * from './useHistoryState';
export * from './useMonthLoader';
export * from './usePagination';
export * from './useTailwindMediaQuery';
export * from './useToggle';
export * from './useHashFragment';
export * from './api/useClients';
export * from './api/useGoogleCalendars';

/**
 * Like normal {@link useTranslation} but a with a variant applied (see {@link createTFunctionWithVariant}).
 */
export function useTranslationWithVariant(variant: string, namespace: string, keyPrefix?: string): TFunction {
    const { t } = useTranslation(namespace, { keyPrefix });
    return useMemo(() => createTFunctionWithVariant(t, variant), [ t, variant ]);
}
