import { type ClientContact, type ClientInfoFE } from ':frontend/types/Client';
import { routesFE } from ':utils/routes';
import { Link } from 'react-router-dom';
import { cn } from ':components/shadcn/utils';

type Size = 'sm' | 'md' | 'lg' | 'xl';

type ClientIconProps = Readonly<{
    client: ClientInfoFE | ClientContact;
    className?: string;
    size?: Size;
}>;

export function ClientIcon({ client, className, size }: ClientIconProps) {
    return (
        <ClientIconCustom text={client.shortcut} className={className} size={size} isDeleted={'id' in client && client.isDeleted} />
    );
}

type ClientIconCustomProps = Readonly<{
    text: string;
    className?: string;
    size?: Size;
    isDeleted?: boolean;
}>;

export function ClientIconCustom({ text, className, size, isDeleted }: ClientIconCustomProps) {
    return (
        <div className={cn(
            'rounded-full flex items-center justify-center text-white select-none',
            classNameBySize[size ?? 'sm'],
            isDeleted ? 'bg-secondary' : 'bg-secondary-800',
            className,
        )}>
            {text}
        </div>
    );
}

const classNameBySize: Record<Size, string> = {
    sm: 'shrink-0 size-5 leading-3 text-xs',
    md: 'shrink-0 size-6 leading-4 text-xs',
    lg: 'shrink-0 size-8 leading-4 text-sm font-semibold',
    xl: 'shrink-0 size-18 leading-6 text-xl font-semibold',
};

type ClientIconLinkProps = Readonly<{
    client: ClientInfoFE | ClientContact;
    className?: string;
    iconClassName?: string;
    size?: Size;
    newTab?: boolean;
}>;

export function ClientIconLink({ client, className, iconClassName, size, newTab }: ClientIconLinkProps) {
    if (!('id' in client))
        return <ClientIconBadge client={client} size={size} className={className} iconClassName={iconClassName} />;

    if (client.isDeleted) {
        return (
            <div className={cn('flex items-center gap-3 text-inherit font-semibold truncate text-secondary-300', className)}>
                <ClientIcon client={client} size={size} className={iconClassName} />
                <span className='leading-4 truncate'>{client.name}</span>
            </div>
        );
    }

    return (
        <Link
            to={routesFE.clients.detail.resolve({ id: client.id, key: 'overview' })}
            className={cn('flex items-center gap-3 text-inherit font-semibold truncate no-underline [&:hover_span]:underline', className)}
            target={newTab ? '_blank' : undefined}
            rel={newTab ? 'noopener' : undefined}
        >
            <ClientIcon client={client} size={size} className={iconClassName} />
            <span className='leading-4 truncate'>{client.name}</span>
        </Link>
    );
}

type ClientIconBadgeProps = Readonly<{
    client: ClientInfoFE | ClientContact;
    className?: string;
    iconClassName?: string;
    size?: Size;
}>;

export function ClientIconBadge({ client, className, iconClassName, size }: ClientIconBadgeProps) {
    return (
        <div className={cn('flex items-center gap-3 text-inherit font-semibold truncate', className)}>
            <ClientIcon client={client} size={size} className={iconClassName} />
            <span className='leading-4 truncate'>{client.name}</span>
        </div>
    );
}

type ClientIconRowProps = Readonly<{
    client: ClientInfoFE | ClientContact;
    className?: string;
}>;

export function ClientIconRow({ client, className }: ClientIconRowProps) {
    const { email } = client;
    const name = client.name === email ? '' : client.name;

    return (
        <div className={cn('flex items-center gap-4', className)}>
            <ClientIconCustom text={client.shortcut} size='lg' className='rounded-full' />

            <div className='grow overflow-hidden'>
                {name ? (
                    <div>
                        <div className='truncate font-semibold text-lg leading-5'>{name}</div>
                        <div className='truncate text-secondary-400'>{email}</div>
                    </div>
                ) : (
                    <div className='truncate'>{email}</div>
                )}
            </div>
        </div>
    );
}
