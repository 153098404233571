import type { IconType } from ':components/icons/common';
import { TextBoldIcon, TextItalicIcon, TextUnderlineIcon, TextCodeIcon, AlignLeftIcon, AlignCenterIcon, AlignRightIcon, ListUnorderedIcon, ListOrderedIcon, InsertLinkIcon, InsertImageIcon, InsertEmojiIcon } from ':components/icons/custom';
import { useEditor, EditorContent, type Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { Button, DropdownMenu, Form } from ':components/shadcn';
import { ImageInput } from ':components/custom';
import { emptyFunction } from ':frontend/utils/common';
import { api } from ':frontend/utils/api';
import { routesFE } from ':utils/routes';
import { linkify } from ':utils/common';
import type { EmojiClickData } from 'emoji-picker-react';
import { EmojiPickerInner } from '../forms/EmojiPicker';
import { signal, type Signal } from '@preact/signals-react';
import type { MarkdownControl } from ':components/shadcn/MarkdownDisplay';
import { cn } from ':components/shadcn/utils';

const extensions = [
    StarterKit,
    Underline,
    TextAlign.configure({
        types: [ 'heading', 'paragraph' ],
    }),
    Image,
    Link.configure({
        protocols: [ 'http', 'https' ],
        defaultProtocol: 'https',
        HTMLAttributes: {
            rel: 'noopener',
        },
    }).extend({
        // After a link is created, subsequent text should not be part of the link.
        inclusive: false,
    }),
];

export class MarkdownInputControl implements MarkdownControl {
    readonly current: Signal<string>;

    constructor(
        private innerHtml: string,
        /** If true, the signal will subscribe to the editor's update events. */
        readonly doSubscribe: boolean,
    ) {
        this.current = signal(innerHtml);
    }

    private editor?: Editor;

    setEditor(editor: Editor) {
        if (this.doSubscribe)
            editor.on('update', () => this.current.value = editor.getHTML());

        this.editor = editor;
    }

    unsetEditor() {
        this.editor?.off('update');
        this.innerHtml = this.getHtml();
    }

    getHtml(): string {
        return this.editor?.getHTML() ?? this.innerHtml;
    }

    /** This should be called only when the editor is mounted. */
    getText(): string {
        // The default getText() creates too many line breaks at some places and too few at others. Maybe it can be fixed by configuring custom serializer, but it's not worth the effort.
        return this.editor?.view.dom.innerText ?? '';
    }
}

type MarkdownInputProps = Readonly<{
    /** Defines initial value and allows extracting current content and subscribing to its changes. */
    control: MarkdownInputControl;
    /** Use this to introduce sticky behavior. */
    menuClassName?: string;
}>;

export function MarkdownInput({ control, menuClassName }: MarkdownInputProps) {
    // This hook should be isolated to this component as it might cause frequent re-renders.
    const editor = useEditor({ extensions, content: control.getHtml() });

    useEffect(() => {
        if (!editor)
            return;

        control.setEditor(editor);

        return () => control.unsetEditor();
    }, [ control, editor ]);

    return (
        <div className='w-full rounded bg-white'>
            <div className={cn('z-10 bg-white [:focus-within>&>*]:border-primary', menuClassName)}>
                <MenuBar editor={editor} className='rounded-t-sm border border-secondary-100' />
            </div>

            <EditorContent editor={editor} className='fl-markdown [&_.tiptap]:p-4 rounded-b-sm border border-t-0 border-secondary-100 [:focus-within>&]:border-primary [&_.tiptap]:outline-none' />
        </div>
    );
}

function MenuBar({ editor, className }: Readonly<{ editor: Editor | null, className?: string }>) {
    const { t } = useTranslation('components', { keyPrefix: 'markdownInput.buttons' });

    if (!editor)
        return null;

    return (
        <div className={cn('p-2 flex flex-wrap gap-2', className)}>
            {basicMenuButtons.map(button => renderBasicMenuButton(editor, button, t))}

            {/* If we are in a link, we can unset it. Otherwise, we can create a new one. */}
            {unsetLinkButton.isActive(editor) ? renderBasicMenuButton(editor, unsetLinkButton, t) : (
                <InsertLinkButton editor={editor} />
            )}

            <InsertImageButton editor={editor} />

            <InsertEmojiButton editor={editor} />
        </div>
    );
}

function renderBasicMenuButton(editor: Editor, button: BasicMenuButton, t: TFunction) {
    const isActive = !!button.isActive?.(editor);

    return (
        <button
            key={button.name}
            className={getButtonClassName(isActive)}
            aria-label={t(button.name)}
            onClick={() => button.onClick(editor)}
        >
            {button.icon({})}
        </button>
    );
}

function getButtonClassName(isActive?: boolean) {
    return clsx('p-1 rounded hover:bg-primary-50 active:bg-primary-100', isActive && 'text-primary bg-primary-50');
}

type BasicMenuButton = {
    name: string;
    icon: IconType;
    onClick: (editor: Editor) => void;
    isActive: (editor: Editor) => boolean;
};

const basicMenuButtons: BasicMenuButton[] = [ {
    name: 'bold',
    icon: TextBoldIcon,
    onClick: e => e.chain().focus().toggleBold().run(),
    isActive: e => e.isActive('bold'),
}, {
    name: 'italic',
    icon: TextItalicIcon,
    onClick: e => e.chain().focus().toggleItalic().run(),
    isActive: e => e.isActive('italic'),
}, {
    name: 'underline',
    icon: TextUnderlineIcon,
    onClick: e => e.chain().focus().toggleUnderline().run(),
    isActive: e => e.isActive('underline'),
}, {
    // TODO When this one is active, no other (like bold) can be activated.
    name: 'code',
    icon: TextCodeIcon,
    onClick: e => e.chain().focus().toggleCode().run(),
    isActive: e => e.isActive('code'),
}, {
    name: 'left',
    icon: AlignLeftIcon,
    onClick: e => e.chain().focus().setTextAlign('left').run(),
    isActive: e => e.isActive({ textAlign: 'left' }),
}, {
    name: 'center',
    icon: AlignCenterIcon,
    onClick: e => e.chain().focus().setTextAlign('center').run(),
    isActive: e => e.isActive({ textAlign: 'center' }),
}, {
    name: 'right',
    icon: AlignRightIcon,
    onClick: e => e.chain().focus().setTextAlign('right').run(),
    isActive: e => e.isActive({ textAlign: 'right' }),
}, {
    name: 'unordered',
    icon: ListUnorderedIcon,
    onClick: e => e.chain().focus().toggleBulletList().run(),
    isActive: e => e.isActive('bulletList'),
}, {
    name: 'ordered',
    icon: ListOrderedIcon,
    onClick: e => e.chain().focus().toggleOrderedList().run(),
    isActive: e => e.isActive('orderedList'),
//     name: 'emoji',
//     icon: InsertEmojiIcon,
} ];

const unsetLinkButton: BasicMenuButton = {
    name: 'link',
    icon: InsertLinkIcon,
    onClick: e => e.chain().focus().unsetLink().run(),
    isActive: e => e.isActive('link'),
};

type ComplexMenuButtonProps = Readonly<{
    editor: Editor;
}>;

function InsertLinkButton({ editor }: ComplexMenuButtonProps) {
    const { t } = useTranslation('components', { keyPrefix: 'markdownInput.link' });
    const [ isOpen, setIsOpen ] = useState<boolean>();
    const [ text, setText ] = useState('');
    const [ url, setUrl ] = useState('');

    function onOpenChange(open: boolean) {
        setIsOpen(open);

        if (open) {
            const { from, to } = editor.state.selection;
            const selectedText = editor.state.doc.textBetween(from, to, ' ');
            setText(selectedText);
            setUrl('');
        }
    }

    function insertLink() {
        if (!text || !url)
            return;

        const from = editor.state.selection.from;
        const to = from + text.length;

        editor.chain()
            .focus()
            .deleteRange(editor.state.selection)
            .insertContentAt(from, text)
            .setTextSelection({ from, to })
            .setLink({ href: linkify(url) })
            .setTextSelection(to)
            .run();

        setIsOpen(false);
    }

    return (
        <DropdownMenu.Root open={isOpen} onOpenChange={onOpenChange}>
            <DropdownMenu.Trigger aria-label={t('trigger-button')} className={getButtonClassName()}>
                <InsertLinkIcon />
            </DropdownMenu.Trigger>
            <DropdownMenu.Content onCloseAutoFocus={e => e.preventDefault()} className='min-w-[320px] p-3 space-y-3'>
                <h3 className='text-lg'>{t('title')}</h3>

                <div>
                    <Form.Input
                        value={text}
                        onChange={e => setText(e.target.value)}
                        label={t('text-label')}
                        size='compact'
                    />
                </div>

                <div>
                    <Form.Input
                        value={url}
                        onChange={e => setUrl(e.target.value)}
                        label={t('url-label')}
                        size='compact'
                    />
                </div>

                <div className='flex justify-end'>
                    <Button size='small' disabled={!text || !url} onClick={insertLink}>
                        {t('insert-button')}
                    </Button>
                </div>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
}

function InsertImageButton({ editor }: ComplexMenuButtonProps) {
    const { t } = useTranslation('components', { keyPrefix: 'markdownInput.image' });
    const [ isOpen, setIsOpen ] = useState<boolean>();

    const onFile = useCallback(async (file: File) => {
        const response = await api.backend.uploadFile(file);
        if (!response.status)
            // TODO Handle error
            return;

        const src = routesFE.files.uploads(response.data.hashName);
        editor.chain().focus().setImage({ src }).run();
        setIsOpen(false);
    }, [ editor ]);

    return (
        <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
            <DropdownMenu.Trigger aria-label={t('trigger-button')} className={getButtonClassName()}>
                <InsertImageIcon />
            </DropdownMenu.Trigger>
            <DropdownMenu.Content onCloseAutoFocus={e => e.preventDefault()} className='min-w-[320px] p-3'>
                <ImageInput
                    value={undefined}
                    onChange={emptyFunction}
                    onFile={onFile}
                />
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
}

function InsertEmojiButton({ editor }: ComplexMenuButtonProps) {
    const { t } = useTranslation('components', { keyPrefix: 'markdownInput.emoji' });
    const [ isOpen, setIsOpen ] = useState<boolean>();

    const onEmojiClick = useCallback((emoji: EmojiClickData) => {
        editor.chain().focus().insertContent(emoji.emoji).run();
        setIsOpen(false);
    }, [ editor ]);

    return (
        <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
            <DropdownMenu.Trigger aria-label={t('trigger-button')} className={getButtonClassName()}>
                <InsertEmojiIcon />
            </DropdownMenu.Trigger>
            <DropdownMenu.Content onCloseAutoFocus={e => e.preventDefault()} className='min-w-[320px] p-3'>
                <EmojiPickerInner onEmojiClick={onEmojiClick} />
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
}
