import { type FilterDefinition, type FilterFunction, type FilterMenuProps } from './FilterRow';
import { DropdownMenu, Form, Button } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { type ClientInfoFE } from ':frontend/types/Client';
import { type Id } from ':utils/id';
import { useMaster } from ':frontend/context/UserProvider';
import { ClientTagDisplay } from ':frontend/components/client/ClientTags';
import { Tag3Icon } from ':components/icons/basic';
import { compareArrays } from ':frontend/utils/common';

const filterName = 'clientTag';

type FilterState = {
    [key: string]: boolean;
};

function RowMenu({ state, setState }: FilterMenuProps<FilterState>) {
    const { t } = useTranslation('common', { keyPrefix: 'filters' });
    const { clientTags } = useMaster();
    const selectedTags = clientTags.filter(clientTag => state[clientTag.id]);

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild disabled={clientTags.length === 0}>
                <Button variant='outline' className={selectedTags.length > 0 ? 'border-primary text-primary hover:border-primary' : undefined}>
                    {selectedTags.length > 0 ? (
                        selectedTags.map(clientTag => <ClientTagDisplay key={clientTag.id} tag={clientTag} />)
                            .flatMap((e, index) => [ e, <span key={`and_${index}`}>&</span> ])
                            .slice(0, -1)
                    ) : (
                        <div className='flex items-center gap-2'>
                            <Tag3Icon size='md' /> {t(`${filterName}.menu-button`)}
                        </div>
                    )}
                </Button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Content side='bottom' align='start'>
                <div className='p-1.5'>
                    {clientTags.map(clientTag => (
                        <div
                            key={clientTag.id}
                            className='flex items-center gap-4 px-4 py-3 rounded-sm cursor-pointer hover:bg-primary-100'
                            onClick={() => setState({ ...state, [clientTag.id]: !state[clientTag.id] })}
                        >
                            <Form.Checkbox checked={state[clientTag.id]} />
                            <ClientTagDisplay tag={clientTag} />
                        </div>
                    ))}
                </div>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
}

function createFilterFunction(state: FilterState): FilterFunction<ClientInfoFE> {
    const selectedIds = Object.entries(state)
        .filter(([ , value ]) => value)
        .map(([ key ]) => key as Id);

    if (selectedIds.length === 0)
        return () => true;

    return (data: ClientInfoFE) => selectedIds.every(id => data.tagIds.some(tagId => tagId === id));
}

function toServer(state: FilterState, previous: Id[] | undefined): Id[] | undefined {
    const current = Object.entries(state)
        .filter(([ , value ]) => value)
        .map(([ key ]) => key as Id);

    if (current.length === 0)
        return;

    return previous && compareArrays(current, previous)
        ? previous
        : current;
}

export const ClientTagFilter: FilterDefinition<FilterState, undefined, ClientInfoFE, Id[]> = {
    name: filterName,
    defaultState: {},
    RowMenu,
    createFilterFunction,
    toServer,
};
