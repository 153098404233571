import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type ClientInfoFE } from ':frontend/types/Client';
import { Select } from ':components/shadcn';
import { createOnChange, createValue, type OnChange, type Value } from ':frontend/utils/forms';
import clsx from 'clsx';

type Option = {
    value: ClientInfoFE;
    label: string;
};

function clientToOption(client: ClientInfoFE, showEmails: boolean = false): Option {
    const label = (showEmails && client.name !== client.email) ? `${client.name} (${client.email})` : client.name;

    return {
        value: client,
        label,
    };
}

type ClientSelectProps<IsMulti extends boolean> = {
    clients: ClientInfoFE[];
    value: Value<ClientInfoFE, IsMulti>;
    onChange: OnChange<ClientInfoFE, IsMulti>;
    isMulti?: IsMulti;
    disabled?: boolean;
    showEmails?: boolean;
    className?: string;
    size?: 'compact' | 'default';
};

export function ClientSelect<IsMulti extends boolean = false>({ clients, value, onChange, isMulti, disabled, showEmails = false, className, size } : ClientSelectProps<IsMulti>) {
    const { t } = useTranslation('components', { keyPrefix: 'clientSelect' });

    const options: Option[] = useMemo(() => {
        return clients.map(client => clientToOption(client, showEmails));
    }, [ clients, showEmails ]);

    const handleOnChange = useMemo(() => createOnChange(
        (option: Option) => option.value,
        isMulti,
        onChange,
    ), [ isMulti, onChange ]);

    const innerValue = useMemo(() => createValue(
        client => clientToOption(client, showEmails),
        isMulti,
        value,
    ), [ isMulti, value ]);

    // FIXME Replace with combobox.

    return (
        <div className={clsx('flex items-center', className)}>
            <Select
                immutableProps={{ size }}
                className='grow'
                placeholder={t(`placeholder-${isMulti ? 'multi' : 'single'}`)}
                noOptionsMessage={() => t('no-options-message-default')}
                options={options}
                isMulti={isMulti}
                isClearable={false}
                value={innerValue === undefined ? null : innerValue}
                onChange={handleOnChange}
                isDisabled={disabled}
            />
        </div>
    );
}
