import { MoneyDisplayString } from ':components/custom';
import { Card } from ':components/shadcn';
import type { OrderDiscount } from ':utils/entity/order';
import { amountFromDecimal, roundMoneyAmount } from ':utils/money';
import type { OrderFormState } from './useOrder';
import { useMemo } from 'react';
import type { OrderFE } from ':frontend/types/orders/Order';
import { toNumber } from ':utils/math';

type OrderItemsDiscountProps = Readonly<{
    order: OrderFE;
    discount: OrderDiscount;
    /** If provided, the total amount will be computed dynamically from the form. */
    form: OrderFormState | undefined;
}>;

export function OrderItemsDiscount({ order, discount, form }: OrderItemsDiscountProps) {
    const price = useMemo(() => {
        if (!form)
            return order.discountPrice;

        const customTotal = form.customItems.reduce((ans, item) => ans + toNumber(item.quantity) * amountFromDecimal(toNumber(item.unitPriceInDecimal)), 0);
        const productTotal = form.productItems.reduce((ans, item) => ans + amountFromDecimal(toNumber(item.unitPriceInDecimal)), 0);
        const eventTotal = form.eventItems.reduce((ans, item) => ans + amountFromDecimal(toNumber(item.unitPriceInDecimal)), 0);

        const orderTotal = customTotal + productTotal + eventTotal;

        return {
            // The discount amount is negative.
            amount: -roundMoneyAmount(orderTotal * discount.rate),
            currency: order.price.currency,
        };
    }, [ order, discount, form ]);

    return (
        <Card className='max-sm:space-y-6 sm:flex sm:items-center sm:justify-between sm:gap-4'>
            <div className='font-semibold text-lg/5 truncate'>{discount.title}</div>

            <div className='flex items-center gap-8 font-semibold whitespace-nowrap'>
                <div>1×</div>

                <div className='font-semibold whitespace-nowrap'>
                    <MoneyDisplayString money={price} />
                </div>
            </div>
        </Card>
    );
}
