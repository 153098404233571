import { type EventFE } from ':frontend/types/Event';
import { useTranslation } from 'react-i18next';
import { CircleFullIcon, CircleSemiFullIcon, CircleEmptyIcon } from ':components/icons/custom';
import clsx from 'clsx';
import { EventState, EventTransition, getEventState, isEventTransitionPossible, type EventInfoOutput } from ':utils/entity/event';
import { CalendarPlus2Icon, Trash2Icon, TrashSlashIcon } from ':components/icons/basic';
import type { PredefinedIconSize } from ':components/icons/common';
import { SpinnerButton } from ':components/shadcn';

const stateDescriptions = {
    [EventState.ready]: { colorClass: 'text-warning', label: 'ready', icon: CircleSemiFullIcon },
    [EventState.finished]: { colorClass: 'text-success', label: 'finished', icon: CircleFullIcon },
    [EventState.canceled]: { colorClass: 'text-danger', label: 'canceled', icon: CircleEmptyIcon },
} as const;

type EventStateBadgeProps = {
    size?: PredefinedIconSize | number;
    className?: string;
} & ({
    event: EventInfoOutput;
} | {
    state: EventState;
});

export function EventStateBadge(props: EventStateBadgeProps) {
    const { t } = useTranslation('common', { keyPrefix: 'event.state' });
    const state = 'event' in props ? getEventState(props.event) : props.state;
    const description = stateDescriptions[state];

    return (
        <div className={clsx('flex items-center gap-2', props.className)}>
            {description.icon({ size: props.size ?? 'sm', className: description.colorClass })}
            {t(description.label)}
        </div>
    );
}

type EventDraftBadgeProps = Readonly<{
    className?: string;
}>;

export function EventDraftBadge({ className }: EventDraftBadgeProps) {
    const { t } = useTranslation('common', { keyPrefix: 'event.state' });

    return (
        <div className={clsx('flex items-center gap-2', className)}>
            <CalendarPlus2Icon className='text-success-500' />
            {t('draft')}
        </div>
    );
}

const transitions = [
    { type: EventTransition.Cancel, button: 'text-warning', icon: Trash2Icon },
    { type: EventTransition.Revoke, button: 'text-warning', icon: TrashSlashIcon },
] as const;

type StateTransitionButtonsProps = Readonly<{
    event: EventFE;
    onSubmit: (transition: EventTransition, fid: string) => void;
    fetching: string | undefined;
    isOverlay?: boolean;
    className?: string;
    buttonClassName?: string;
}>;

export function StateTransitionButtons({ event, onSubmit, fetching, isOverlay, className, buttonClassName }: StateTransitionButtonsProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventSidebar' });

    return (
        <div className={className}>
            {transitions.filter(transition => isEventTransitionPossible(event, transition.type)).map(transition => (
                <SpinnerButton
                    key={transition.type}
                    variant='transparent'
                    className={clsx(transition.button, buttonClassName)}
                    fetching={fetching}
                    fid={transition.type}
                    isOverlay={isOverlay}
                    onClick={() => onSubmit(transition.type, transition.type)}
                >
                    {transition.icon({})}
                    {t(`${transition.type}Button`)}
                </SpinnerButton>
            ))}
        </div>
    );
}
