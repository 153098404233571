import { trpc } from ':frontend/context/TrpcProvider';
import { routeToDisplayString, routesFE, routesStore } from ':utils/routes';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { StoreOverviewTab } from './StoreOverviewTab';
import { StoreDesignTab } from './StoreDesignTab';
import { Button, Tabs } from ':components/shadcn';
import { Clone1Icon, Copies6Icon, Palette1Icon, TriangleWarningIcon, WindowPlus1Icon } from ':components/icons/basic';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedSuccessAlert } from ':frontend/components/notifications';
import { TabsTopbar, TopHeader } from ':frontend/components/Layout';
import { NoPaymentMethodBanner } from ':frontend/components/integrations/NoPaymentMethodBanner';
import { useMaster } from ':frontend/context/UserProvider';
import { StripeConnectionState } from ':utils/entity/team';
import { useToggle } from ':frontend/hooks';
import { NewProductUsecase } from ':frontend/components/product/useProductForms';
import { NewProductModal } from ':frontend/components/product/CreateProductCard';
import { useEntitlement } from ':frontend/lib/stigg';
import { FIRST_PLAN_ID, StiggFeature } from ':utils/lib/stigg';
import { CheckoutModal, UpsellButton } from '../settings/Subscriptions';

export const getProductsQuery = { onlyPublic: true };

export function StorePage() {
    const { t } = useTranslation('pages', { keyPrefix: 'store' });
    trpc.user.visitStoreEditor.useQuery(undefined, {
        staleTime: Infinity,  // minimize refetches
    });
    const store = trpc.store.getStore.useQuery();
    // This page should look like the actual store, so only the public products should be displayed.
    const products = trpc.product.getProducts.useQuery(getProductsQuery);

    const maxProducts = useEntitlement(StiggFeature.MaxProducts);

    const { key } = useParams();
    const navigate = useNavigate();
    const handleSelect = useCallback((newKey: string | null) => newKey && navigate(routesFE.store.resolve({ key: newKey })), [ navigate ]);
    const { addAlert } = useNotifications();

    const [ showNewProductModal, setShowNewProductModal ] = useToggle(false);
    const [ showUpsellModal, setShowUpsellModal ] = useToggle(false);

    const onNewProduct = useMemo(() => {
        if (!maxProducts)
            return setShowNewProductModal.true;

        if (!products.data)
            return setShowNewProductModal.true;

        if (products.data.length >= maxProducts)
            return setShowUpsellModal.true;

        return setShowNewProductModal.true;
        
    }, [ products.data, maxProducts, setShowNewProductModal.true, setShowUpsellModal.true ]);


    if (!store.data || !products.data)
        return null;

    const storeUrl = routesStore.store.absoluteResolve(store.data);

    async function copyStoreUrl() {
        await navigator.clipboard.writeText(storeUrl);
        addAlert(createTranslatedSuccessAlert('common:copied-to-clipboard'));
    }

    return (<>
        <CheckoutModal isOpen={showUpsellModal} onClose={setShowUpsellModal.false} plan={{ id: FIRST_PLAN_ID }}/>
        <NewProductModal open={showNewProductModal} onClose={setShowNewProductModal.false} usecase={NewProductUsecase.Landing} />

        <Tabs.Root value={key} onValueChange={handleSelect} className='flex flex-col h-full grow w-full'>
            <TopHeader>
                <div className='flex items-center gap-2 overflow-hidden'>
                    <a href={storeUrl} target='_blank' rel='noopener' className='min-w-0 flex'>
                        <Button variant='transparent' size='small' className='block h-7 px-2 text-primary truncate'>
                            {routeToDisplayString(storeUrl)}
                        </Button>
                    </a>

                    <Button variant='white' size='exact' className='shrink-0 size-9' onClick={copyStoreUrl}>
                        <Clone1Icon />
                    </Button>
                </div>
            </TopHeader>

            <TabsTopbar>
                <Tabs.List>
                    <Tabs.Trigger value='overview'>
                        <Copies6Icon size='xs' />
                        {t('overview-tab')}
                    </Tabs.Trigger>

                    <Tabs.Button onClick={onNewProduct}>
                        <WindowPlus1Icon size='xs' />
                        {t('create-page-button')}
                    </Tabs.Button>

                    <Tabs.Trigger value='design'>
                        <Palette1Icon size='xs' />
                        {t('design-tab')}
                    </Tabs.Trigger>
                </Tabs.List>

                <div className='max-md:hidden flex items-center gap-2 overflow-hidden'>
                    <a href={storeUrl} target='_blank' rel='noopener' className='min-w-0 flex'>
                        <Button variant='transparent' size='small' className='block h-7 px-2 text-primary truncate'>
                            {routeToDisplayString(storeUrl)}
                        </Button>
                    </a>

                    <Button variant='white' size='tiny' onClick={copyStoreUrl}>
                        <Clone1Icon />{t('copy-url-button')}
                    </Button>
                </div>
            </TabsTopbar>

            <Tabs.Content value='overview'>
                <StoreOverviewTab store={store.data} products={products.data} />
            </Tabs.Content>

            <Tabs.Content value='design' className='max-md:grow max-md:bg-white'>
                <StoreDesignTab store={store.data} products={products.data} />
            </Tabs.Content>
        </Tabs.Root>
    </>);
}

export function StoreNotEnabledBanner() {
    const { t } = useTranslation('pages', { keyPrefix: 'store' });

    const isStoreEnabled = useEntitlement(StiggFeature.Store);
    if (isStoreEnabled)
        return null;

    return (
        <div className='p-2 w-full flex items-center justify-center'>
            <div className='p-2 flex max-md:flex-col items-center justify-center gap-4 rounded-2xl bg-white text-warning'>
                <div className='pl-3 flex items-center gap-4'>
                    <TriangleWarningIcon size='md' className='shrink-0' />
                    {t('upsell-text')}
                </div>

                <UpsellButton text={t('upsell-button')} feature={StiggFeature.Store} className='max-md:w-full' />
            </div>
        </div>
    );
}

export function NoPaymentMethodBannerStore() {
    const { t } = useTranslation('pages', { keyPrefix: 'store' });
    const { team } = useMaster();

    if (
        team.stripeConnectionState === StripeConnectionState.connected ||
        team.isPaypalConnected
    )
        return null;

    const text = team.stripeConnectionState === StripeConnectionState.verificationRequired
        ? t('no-stripe-verify')
        : t('no-payment-method-connect');

    return (
        <NoPaymentMethodBanner text={text} />
    );
}
