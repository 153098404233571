import { zId } from ':utils/id';
import { z } from 'zod';

export enum FileType {
    Image = 'image',
    Document = 'document',
    Audio = 'audio',
    Video = 'video',
    Archive = 'archive',
}

export enum MimeType {
    ImagePNG = 'image/png',
    ImageJPEG = 'image/jpeg',
    ImageSVG = 'image/svg+xml',
    ApplicationPDF = 'application/pdf',
    ApplicationZIP = 'application/zip',
    /** Microsoft Word .doc */
    ApplicationWord = 'application/msword',
    /** Microsoft Word .docx */
    ApplicationWordXml = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    /** Microsoft Excel .xls */
    ApplicationExcel = 'application/vnd.ms-excel',
    /** Microsoft Excel .xlsx */
    ApplicationExcelXml = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    /** Microsoft Excel .xltx */
    ApplicationExcelTemplateXml = 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    /** Microsoft Powerpoint .ppt  */
    ApplicationPowerpoint = 'application/vnd.ms-powerpoint',
    /** Microsoft Powerpoint .pptx */
    ApplicationPowerpointXml = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    /** Microsoft Powerpoint .potx */
    ApplicationPowerpointTemplateXml = 'application/vnd.openxmlformats-officedocument.presentationml.template',
    AudioMP3 = 'audio/mpeg',
    AudioWAV = 'audio/wav',
    VideoAVI = 'video/x-msvideo',
    VideoMP4 = 'video/mp4',
    VideoMPEG = 'video/mpeg',
    VideoWEBM = 'video/webm',
}

export const zMimeType = z.nativeEnum(MimeType);

export const fileTypeToMimeTypes: Record<FileType, MimeType[]> = {
    [FileType.Image]: [ MimeType.ImagePNG, MimeType.ImageJPEG, MimeType.ImageSVG ],
    [FileType.Document]: [ MimeType.ApplicationPDF, MimeType.ApplicationWord, MimeType.ApplicationWordXml, MimeType.ApplicationExcel, MimeType.ApplicationExcelXml, MimeType.ApplicationExcelTemplateXml, MimeType.ApplicationPowerpoint, MimeType.ApplicationPowerpointXml, MimeType.ApplicationPowerpointTemplateXml ],
    [FileType.Audio]: [ MimeType.AudioMP3, MimeType.AudioWAV ],
    [FileType.Video]: [ MimeType.VideoAVI, MimeType.VideoMP4, MimeType.VideoMPEG, MimeType.VideoWEBM ],
    [FileType.Archive]: [ MimeType.ApplicationZIP ],
};

export type FileOutput = z.infer<typeof zFileOutput>;
export const zFileOutput = z.object({
    id: zId,
    originalName: z.string(),
    hashName: z.string(),
    type: zMimeType,
    size: z.number(),
});

export type FileUpsert = z.infer<typeof zFileUpsert>;
export const zFileUpsert = z.union([
    z.object({
        name: z.string(),
        type: zMimeType,
        /** base64-encoded, see {@link dataUrlToServer} */
        data: z.string(),
    }),
    z.object({
        id: zId,
    }),
]);

export type FileData = {
    dataUrl: string;
    name: string;
    type: MimeType;
    sizeMB: number;
};

/**
 * Transforms a File object (as returned by FileList[0] as returned by <input type="file" />) to *dataUrl* and some other data (name, type).
 * @see https://developer.mozilla.org/en-US/docs/Web/URI/Schemes/data
 */
export async function fileToFileData(input: File): Promise<FileData> {
    const dataUrl = await fileToDataUrl(input);
    return ({
        dataUrl,
        name: input.name,
        type: zMimeType.parse(input.type),
        // The original size is in bytes. One megabyte (MB) is exactly 1e6 bytes (we are using the SI units).
        sizeMB: input.size / 1e6,
    });
}

/**
 * Extracts *dataUrl* from the File object.
 */
function fileToDataUrl(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.result)
                resolve(reader.result.toString());
            else
                reject(null);
        };

        reader.onerror = error => reject(error);

        reader.readAsDataURL(file);
    });
}

export function fileDataToServer(input: FileData): FileUpsert {
    return {
        data: dataUrlToServer(input.dataUrl),
        name: input.name,
        type: input.type,
    };
}

/**
 * Returns a plain base64 without any data (URL stuff, mimetype, ...), padded with '=' to the correct length.
 */
function dataUrlToServer(input: string): string {
    let encoded = input.replace(/^data:(.*,)?/, '');
    if ((encoded.length % 4) > 0)
        encoded += '='.repeat(4 - (encoded.length % 4));

    return encoded;
}
