import { useCallback, useMemo, useState } from 'react';
import { DefaultFilterItemBadge, type FilterDefinition, type FilterFunction, type FilterItemBadgeProps, type FilterMenuProps } from './FilterRow';
import { useTranslation } from 'react-i18next';
import { type ClientInfoFE } from ':frontend/types/Client';
import { type OrderInfoFE } from ':frontend/types/orders/Order';
import { ClientSelect } from ':frontend/components/client/ClientSelect';
import { compareArrays } from ':frontend/utils/common';
import { type Id } from ':utils/id';

const filterName = 'orderClient';

type FilterState = ClientInfoFE[];

function RowMenuTemplate({ state, setState, allClients }: FilterMenuProps<FilterState> & { allClients: ClientInfoFE[] }) {
    const availableClients = useMemo(() => allClients.filter(client => !state.find(c => c.id === client.id)), [ state, allClients ]);
    const [ value, setValue ] = useState<ClientInfoFE>();

    const handleOnChange = useCallback((client?: ClientInfoFE) => {
        if (!client)
            return;

        setState([ ...state, client ]);
        setValue(undefined);
    }, [ state, setState ]);

    return (
        <ClientSelect
            value={value}
            clients={availableClients}
            onChange={handleOnChange}
            size='compact'
            className='w-full min-w-[240px] md:max-w-[300px]'
        />
    );
}

function ItemBadge({ item, onClose }: FilterItemBadgeProps<ClientInfoFE>) {
    const { t } = useTranslation('common', { keyPrefix: `filters.${filterName}` });

    return (
        <DefaultFilterItemBadge item={item} onClose={onClose}>
            <span className='max-w-40 truncate'>
                {t('badge-label', { name: item.name })}
            </span>
        </DefaultFilterItemBadge>
    );
}

function remove(state: FilterState, item: ClientInfoFE): FilterState {
    return state.filter(client => client.id !== item.id);
}

function toItems(state: FilterState): ClientInfoFE[] {
    return state;
}

function createFilterFunction(state: FilterState): FilterFunction<OrderInfoFE> {
    if (state.length === 0)
        return () => true;

    const set = new Set<string>(state.map(client => client.id));

    return (data: OrderInfoFE) => set.has(data.client.id);
}

function toServer(state: FilterState, previous: Id[] | undefined): Id[] | undefined {
    const current = state.map(client => client.id).sort();
    if (current.length === 0)
        return;

    return previous && compareArrays(current, previous)
        ? previous
        : current;
}

export function createOrderClientFilter(allClients: ClientInfoFE[]): FilterDefinition<FilterState, ClientInfoFE, OrderInfoFE, Id[]> {
    return {
        name: filterName,
        defaultState: [],
        RowMenu: (props: FilterMenuProps<FilterState>) => <RowMenuTemplate {...props} allClients={allClients} />,
        ItemBadge,
        remove,
        toItems,
        createFilterFunction,
        toServer,
        alignRight: true,
    };
}
createOrderClientFilter.filterName = filterName;
