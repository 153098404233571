import { PostHogProvider as DefaultPostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';
import { env } from ':env';

const posthogApiKey = env.VITE_POSTHOG_API_KEY;
const posthogApiHost = env.VITE_POSTHOG_HOST;
const posthogUiHost = env.VITE_POSTHOG_UI_HOST;

const posthogClient = (posthogApiKey && posthogApiHost) ? posthog : undefined;
if (posthogClient && posthogApiKey)
    posthogClient.init(posthogApiKey, { api_host: posthogApiHost, ui_host: posthogUiHost });

export function PosthogProvider({ children }: { children: React.ReactNode }) {
    return posthogClient ? (
        <DefaultPostHogProvider client={posthogClient}>{children}</DefaultPostHogProvider>
    ) : (
        <>{children}</>
    );
}
