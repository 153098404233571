import { type Dispatch, type SetStateAction, useCallback, useMemo, type ReactNode } from 'react';
import { Button, Card, Skeleton, Tooltip, type ButtonProps } from ':components/shadcn';
import { type ClientInfoFE } from ':frontend/types/Client';
import { useTranslation } from 'react-i18next';
import { routesFE } from ':utils/routes';
import { useClients, useToggle } from ':frontend/hooks';
import { ClientIconBadge } from ':frontend/components/client/ClientIconLink';
import { SortOrder } from ':utils/query';
import { getRandomSkeletonArray } from ':utils/math';
import { InfoCard } from ':frontend/components/settings/InfoCard';
import { ClientStateEdit } from ':frontend/components/client/ClientStateBadge';
import { ClientTags } from ':frontend/components/client/ClientTags';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import { ClientStateFilter } from ':frontend/components/common/filters/ClientStateFilter';
import { ClientTagFilter } from ':frontend/components/common/filters/ClientTagFilter';
import { ClientNameFilter } from ':frontend/components/common/filters/ClientNameFilter';
import { FilterRow, useFilters, useFiltersApply } from ':frontend/components/common/filters/FilterRow';
import { PlusIcon, ImportIcon, CircleInfoIcon } from ':components/icons/basic';
import { ClientState } from ':utils/entity/client';
import { NewClientModal } from ':frontend/components/client/NewClientModal';
import { ImportClientsModal } from ':frontend/components/client/ImportClientsModal';
import { Table } from ':frontend/components/common/Table';
import { ExportClientsModal } from ':frontend/components/client/ExportClientsModal';
import { CreatedAtEndFilter, CreatedAtStartFilter } from ':frontend/components/common/filters/CreatedAtFilter';
import { DateTimeDisplay } from ':components/custom/DateTimeDisplay';
import { SortButton, useSort, type UseSortControl } from ':frontend/components/common/SortButton';

const sortKeys = [ 'name', 'createdAt' ] as const;
type SortControl = UseSortControl<typeof sortKeys[number]>;

const schedulerFilters = [
    CreatedAtStartFilter,
    CreatedAtEndFilter,
    ClientNameFilter,
];

const masterFilters = [
    ClientStateFilter,
    ClientTagFilter,
    ...schedulerFilters,
];

export default function Clients() {
    const { t } = useTranslation('pages', { keyPrefix: 'clients' });
    const isMasterOrFreelancer = !!toMaster(useUser());

    const sort = useSort({ key: 'createdAt', order: SortOrder.Descending }, sortKeys);

    const { clients: allClients, setClients } = useClients({ sort: sort.state });

    const filters = isMasterOrFreelancer ? masterFilters : schedulerFilters;
    const filtersControl = useFilters(filters);
    const applyFilters = useFiltersApply(filtersControl);

    const filteredClients = useMemo(() => allClients?.filter(applyFilters), [ allClients, applyFilters ]);

    const stats: {lead: number, active: number, total: number} = useMemo(() => {
        return {
            active: allClients?.filter(client => client.state === ClientState.active).length ?? 0,
            lead: allClients?.filter(client => client.state === ClientState.lead).length ?? 0,
            total: allClients?.length ?? 0,
        };
    }, [ allClients ]);

    return (
        <div className='px-4 py-8'>
            <div className='max-w-[1200px] w-full mx-auto space-y-8'>
                <div className='max-sm:space-y-4 sm:flex sm:justify-between sm:items-center'>
                    <h1 className='leading-9 text-2xl text-secondary-700 font-semibold'>{t('page-title')}</h1>

                    {allClients && allClients.length !== 0 && (
                        <div className='flex items-center gap-3 flex-wrap'>
                            <ImportClientsButton variant='dark' />

                            <ExportClientsModal inputFilters={filtersControl} />

                            <NewClientButton variant='dark' />
                        </div>
                    )}
                </div>

                <InfoCard infoKey='clients' />

                <Card className='space-y-6 max-lg:bg-transparent max-lg:p-0 max-lg:border-none max-lg:shadow-none'>
                    <div className='grid grid-cols-1 lg:grid-cols-3 lg:gap-4 gap-2'>
                        {[ 'lead', 'active', 'total' ].map(statName => (
                            <div key={statName} className='rounded-lg p-8 bg-white lg:bg-secondary-100/25 border-secondary-100 border space-y-2'>
                                <div className='text-xl font-semibold'>{stats[statName as 'lead'|'active'|'total']}</div>

                                <div className='flex items-center gap-2'>
                                    <div>{t(`stats.${statName}-label`)}</div>

                                    <Tooltip
                                        tooltipText={t(`stats.${statName}-tooltip`)}
                                        side='bottom'
                                    >
                                        <CircleInfoIcon size='md' className='opacity-40' />
                                    </Tooltip>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='space-y-2'>
                        <FilterRow control={filtersControl} />

                        <ClientsTable
                            clients={filteredClients}
                            setClients={setClients}
                            allClientsCount={allClients?.length ?? 0}
                            sort={sort}
                        />
                    </div>
                </Card>
            </div>
        </div>
    );
}

function ImportClientsButton({ variant }: Readonly<{ variant?: ButtonProps['variant'] }> = {}) {
    const { t } = useTranslation('pages', { keyPrefix: 'clients' });
    const [ showImportClientsModal, setShowImportClientsModal ] = useToggle(false);

    return (<>
        {showImportClientsModal && <ImportClientsModal onClose={setShowImportClientsModal.false} />}

        <Button variant={variant} size='small' onClick={setShowImportClientsModal.true}>
            <ImportIcon /> {t('import-clients-button')}
        </Button>
    </>);
}

function NewClientButton({ variant }: Readonly<{ variant?: ButtonProps['variant'] }> = {}) {
    const { t } = useTranslation('pages', { keyPrefix: 'clients' });
    const [ showNewClientModal, setShowNewClientModal ] = useToggle(false);

    return (<>
        {showNewClientModal && <NewClientModal onClose={setShowNewClientModal.false} />}

        <Button variant={variant} size='small' onClick={setShowNewClientModal.true}>
            <PlusIcon /> {t('new-client-button')}
        </Button>
    </>);
}

type ClientsTableProps = Readonly<{
    clients?: ClientInfoFE[];
    setClients: Dispatch<SetStateAction<ClientInfoFE[] | undefined>>;
    allClientsCount: number;
    sort: SortControl;
}>;

function ClientsTable({ clients, setClients, allClientsCount, sort }: ClientsTableProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'clients' });
    const isMasterOrFreelancer = !!toMaster(useUser());

    return (
        <div className='fl-hide-scrollbar overflow-x-auto max-lg:-mx-4'>
            <div className='max-lg:px-4 max-lg:w-fit'>
                <Table px={5} className='bg-white'>
                    <Table.Header>
                        <Table.HeaderCol className='whitespace-nowrap flex gap-1 items-center'>
                            <div className='flex items-center gap-1'>
                                <span className='whitespace-nowrap'>{t('client-label')}</span>

                                <SortButton sort={sort} name='name' />
                            </div>
                        </Table.HeaderCol>

                        {isMasterOrFreelancer && (<>
                            <Table.HeaderCol xs={1.5}>{t('state-label')}</Table.HeaderCol>
                            <Table.HeaderCol xs={3}>{t('tags-label')}</Table.HeaderCol>
                        </>)}

                        <Table.HeaderCol xs={1}>
                            <div className='flex items-center gap-1'>
                                {t('createdAt-label')}

                                <SortButton sort={sort} name='createdAt' />
                            </div>
                        </Table.HeaderCol>

                        <Table.HeaderCol>{t('email-label')}</Table.HeaderCol>
                        <Table.HeaderCol xs={1.5} >{t('phone-label')}</Table.HeaderCol>
                    </Table.Header>

                    <Table.Body>
                        <ClientsList
                            clients={clients}
                            setClients={setClients}
                            allClientsCount={allClientsCount}
                        />
                    </Table.Body>
                </Table>
            </div>
        </div>
    );
}

type ClientsListProps = Readonly<{
    clients?: ClientInfoFE[];
    setClients: Dispatch<SetStateAction<ClientInfoFE[] | undefined>>;
    allClientsCount: ReactNode;
}>;

function ClientsList({ clients, setClients, allClientsCount }: ClientsListProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'clients' });

    const setClient = useCallback((client: ClientInfoFE) => {
        setClients(oldClients => oldClients?.map(oldClient => oldClient.id === client.id ? client : oldClient));
    }, [ setClients ]);

    if (!clients)
        return (<>{getRandomSkeletonArray().map(id => <ClientSkeleton key={id} />)}</>);

    if (clients.length === 0) {
        return (
            <Table.Row>
                <Table.Col colSpan={5}>
                    <div className='p-10 flex flex-col items-center gap-6'>
                        <div className='text-center font-semibold text-lg/5 text-secondary-400'>
                            {t(allClientsCount === 0 ? 'no-clients-yet-text' : 'no-clients-text')}
                        </div>

                        {allClientsCount === 0 && (
                            <div className='flex flex-wrap justify-center gap-2'>
                                <ImportClientsButton variant='secondary' />
                                <NewClientButton variant='secondary' />
                            </div>
                        )}
                    </div>
                </Table.Col>
            </Table.Row>
        );
    }

    return (<>
        {clients.map(client => (
            <ClientRow
                key={client.id}
                client={client}
                setClient={setClient}
            />
        ))}
    </>);
}

type ClientRowProps = Readonly<{
    client: ClientInfoFE;
    setClient: (client: ClientInfoFE) => void;
}>;

function ClientRow({ client, setClient }: ClientRowProps) {
    const isMasterOrFreelancer = !!toMaster(useUser());
    const link = routesFE.clients.detail.resolve({ id: client.id, key: 'overview' });

    return (
        <Table.Row className='select-none cursor-pointer hover-partial:bg-secondary-50'>
            <Table.Col truncate link={link} className='max-w-xs'>
                <ClientIconBadge client={client} />
            </Table.Col>

            {isMasterOrFreelancer && (<>
                <Table.Col className='select-none cursor-default block-hover py-2'>
                    <ClientStateEdit client={client} setClient={setClient} />
                </Table.Col>

                <Table.Col className='select-none cursor-default block-hover py-2'>
                    <ClientTags client={client} setClient={setClient} className='justify-start' />
                </Table.Col>
            </>)}

            <Table.Col link={link}><DateTimeDisplay date dateTime={client.createdAt} /></Table.Col>

            <Table.Col truncate link={link} className='max-w-xs'>
                {client.email}
            </Table.Col>
            <Table.Col link={link}>{client.phoneNumber}</Table.Col>
        </Table.Row>
    );
}

function ClientSkeleton() {
    const isMasterOrFreelancer = !!toMaster(useUser());
    const height = 28;

    return (
        <Table.Row>
            <Table.Col><Skeleton height={height} /></Table.Col>
            <Table.Col><Skeleton height={height} /></Table.Col>
            {isMasterOrFreelancer && (<>
                <Table.Col><Skeleton height={height} /></Table.Col>
                <Table.Col><Skeleton height={height} /></Table.Col>
            </>)}
            <Table.Col><Skeleton height={height} /></Table.Col>
        </Table.Row>
    );
}
