import { type NavigateFunction } from 'react-router-dom';
import { Button, Card, Form } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { routesFE } from ':utils/routes';
import { ClientIcon } from ':frontend/components/client/ClientIconLink';
import { DeleteClientModal } from ':frontend/components/client/DeleteClientModal';
import { useToggle } from ':frontend/hooks';
import useNotifications from ':frontend/context/NotificationProvider';
import { createErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import { ClientStateEdit } from ':frontend/components/client/ClientStateBadge';
import { ClientTags } from ':frontend/components/client/ClientTags';
import { Trash2Icon, PaperPlane1Icon, Pen2Icon, Copy1Icon, NotepadIcon } from ':components/icons/basic';
import { ClientStatsView } from ':frontend/components/client/ClientStats';
import { type ClientFE } from ':frontend/types/Client';
import { ClientForm } from ':frontend/components/client/ClientForm';
import type { ClientWithProfileInit } from ':utils/entity/client';
import { trpc } from ':frontend/context/TrpcProvider';

type ClientOverviewProps = {
    client: ClientFE;
    navigateUnblocked: NavigateFunction;
    invalidateClient: () => Promise<void>;
    setIsDirty: (isDirty: boolean) => void;
};

export default function ClientOverview({ client, navigateUnblocked, invalidateClient, setIsDirty }: ClientOverviewProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'client' });
    const { t: tf } = useTranslation('components', { keyPrefix: 'clientForm' });
    const { addAlert } = useNotifications();

    const userContext = useUser();
    const isMasterOrFreelancer = !!toMaster(userContext);

    const updateClientMutation = trpc.$client.updateClient.useMutation();

    const [ showEditForm, setShowEditForm ] = useToggle(false);
    const phoneNumber = client.phoneNumber;

    function handleClientFormSubmit(data: ClientWithProfileInit) {
        updateClientMutation.mutate({
            id: client.id,
            name: data.name,
            email: data.email,
            phoneNumber: data.phoneNumber,
            // there's nothing more in this form
        }, {
            onError: () => {
                addAlert(createErrorAlert(updateClientMutation.error));
            },
            onSuccess: async () => {
                setShowEditForm.false();
                await invalidateClient();
                setIsDirty(false);
                addAlert(createTranslatedSuccessAlert('pages:client.save-success'));
            },
        });
    }

    const handleNotesBlur: React.FocusEventHandler<HTMLTextAreaElement> = event => {
        const newNotes = event.currentTarget.value;
        updateClientMutation.mutate({ id: client.id, note: newNotes }, {
            onError: () => {
                addAlert(createErrorAlert(updateClientMutation.error));
            },
            onSuccess: async () => {
                await invalidateClient();
                setIsDirty(false);
                addAlert(createTranslatedSuccessAlert('pages:client.save-success'));
            },
        });
    };

    return <div className='space-y-6'>
        <div className='relative flex justify-center'>
            <ClientIcon client={client} size='xl' className='shadow-store-soft' />

            {isMasterOrFreelancer && (
                <div className='absolute -bottom-1 left-0 right-0'>
                    <div className='flex justify-center'>
                        <ClientStateEdit client={client} setClient={invalidateClient} className='hover:opacity-100' />
                    </div>
                </div>
            )}
        </div>

        <div className='space-y-2'>
            <h1 className='text-3xl leading-9 text-center font-semibold text-secondary-900'>{client.name}</h1>

            <div className='flex flex-wrap items-center justify-center gap-x-4'>
                <Button variant='transparent' className='min-w-0' onClick={() => {
                    navigator.clipboard.writeText(client.email);
                    addAlert(createTranslatedSuccessAlert('pages:client.email-copy-success'));
                }}>
                    <span className='leading-5 truncate'>{client.email}</span>

                    <span className='opacity-40'><Copy1Icon size='xs' /></span>
                </Button>

                {phoneNumber &&
                    <Button variant='transparent' className='min-w-0' onClick={() => {
                        navigator.clipboard.writeText(phoneNumber);
                        addAlert(createTranslatedSuccessAlert('pages:client.phone-number-copy-success'));
                    }}>
                        <span className='leading-5 truncate'>{phoneNumber}</span>

                        <span className='opacity-40'><Copy1Icon size='xs' /></span>
                    </Button>
                }
            </div>

            {isMasterOrFreelancer && (<>
                <ClientTags client={client} setClient={invalidateClient} className='gap-2' />
            </>)}
        </div>

        <div className='flex gap-2 items-center justify-center flex-wrap'>
            <a href={`mailto:${client.email}`} target='_blank' rel='noreferrer' className='no-underline'>
                <Button variant='outline' className='bg-transparent'>
                    <PaperPlane1Icon size='lg' />
                    {t('send-email-button')}
                </Button>
            </a>

            <Button
                variant='outline'
                className='bg-transparent'
                onClick={() => {
                    setShowEditForm.toggle();
                    setIsDirty(false);
                }}
            >
                <Pen2Icon size='lg' />
                {t('edit-button')}
            </Button>

            <DeleteButton client={client} navigateUnblocked={navigateUnblocked} />
        </div>

        {showEditForm && (
            <Card className='max-w-2xl mx-auto'>
                <div className='space-y-6'>
                    <ClientForm
                        onSubmit={handleClientFormSubmit}
                        defaultValue={client}
                        isFetching={updateClientMutation.isPending}
                        onChange={setIsDirty}
                    />
                </div>

                <Button
                    variant='outline'
                    size='small'
                    className='mt-2 w-full'
                    onClick={() => {
                        setShowEditForm.false();
                        setIsDirty(false);
                    }}
                >
                    {t('cancel-edit-button')}
                </Button>
            </Card>
        )}

        <Card className='max-w-2xl mx-auto space-y-6'>
            <ClientStatsView client={client} />

            <div className='space-y-4'>
                <Card.Title className='flex items-center gap-2'>
                    <NotepadIcon className='text-warning-500' />{t('client-note-title')}
                </Card.Title>

                <Form.Textarea
                    minRows={2}
                    onBlur={handleNotesBlur}
                    onChange={() => setIsDirty(true)}
                    defaultValue={client.note}
                    className='bg-warning-50 border border-secondary-100'
                    placeholder={tf('note-placeholder')}
                />
            </div>
        </Card>
    </div>;
}

type DeleteButtonProps = Readonly<{
    client: ClientFE;
    navigateUnblocked: NavigateFunction;
}>;

function DeleteButton({ client, navigateUnblocked }: DeleteButtonProps) {
    const [ showModal, setShowModal ] = useToggle(false);

    return (<>
        <DeleteClientModal
            client={showModal ? client : undefined}
            onClose={setShowModal.false}
            onDelete={() => navigateUnblocked(routesFE.clients.list.path)}
        />

        <Button
            variant='outline'
            className='bg-transparent'
            onClick={setShowModal.true}
        >
            <Trash2Icon className='text-danger-500' />
        </Button>
    </>);
}
