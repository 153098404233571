import { type FilterDefinition, type FilterFunction, type FilterMenuProps } from './FilterRow';
import { Form, Button, DropdownMenu } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { compareArrays } from ':frontend/utils/common';
import { type OrderInfoFE } from ':frontend/types/orders/Order';
import { getEnumValues } from ':utils/common';
import { CircleCopy1Icon } from ':components/icons/basic';
import { type OrderType } from ':utils/entity/order';
import { productsWithoutCheckout, ProductType } from ':utils/entity/product';
import { OrderTypeBadge } from ':frontend/components/orders/OrdersTable';

const filterName = 'orderType';

type FilterState = {
    [key in OrderType]: boolean;
};

function RowMenu({ state, setState }: FilterMenuProps<FilterState>) {
    const { t } = useTranslation('common', { keyPrefix: 'filters' });
    const selectedTypes = ORDER_TYPES.filter(orderType => state[orderType]);

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <Button variant='outline' className={selectedTypes.length > 0 ? 'border-primary hover:border-primary' : undefined}>
                    {selectedTypes.length > 0 ? (
                        selectedTypes.map(orderType => <OrderTypeBadge key={orderType} type={orderType} />)
                            .flatMap((e, index) => [ e, <span key={`or_${index}`} className='text-primary'>/</span> ])
                            .slice(0, -1)
                    ) : (
                        <div className='flex items-center gap-2'>
                            <CircleCopy1Icon size='md' /> {t(`${filterName}.menu-button`)}
                        </div>
                    )}
                </Button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Content side='bottom' align='start'>
                <div className='p-1.5'>
                    {ORDER_TYPES.map(orderType => (
                        <div
                            key={orderType}
                            className='flex items-center gap-4 px-4 py-3 rounded-sm cursor-pointer hover:bg-primary-100'
                            onClick={() => setState({ ...state, [orderType]: !state[orderType] })}
                        >
                            <Form.Checkbox checked={state[orderType]} />
                            <OrderTypeBadge type={orderType} />
                        </div>
                    ))}
                </div>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
}

const ORDER_TYPES: OrderType[] = [
    ...getEnumValues(ProductType).filter(type => !productsWithoutCheckout.includes(type)),
    'direct',
];

function createFilterFunction(state: FilterState): FilterFunction<OrderInfoFE> {
    const selectedTypes = ORDER_TYPES.filter(orderType => state[orderType]);
    if (selectedTypes.length === 0 || selectedTypes.length === ORDER_TYPES.length)
        return () => true;

    return (data: OrderInfoFE) => state[data.type];
}

function toServer(state: FilterState, previous: OrderType[] | undefined): OrderType[] | undefined {
    const current = ORDER_TYPES.filter(orderType => state[orderType]);
    if (current.length === 0 || current.length === ORDER_TYPES.length)
        return;

    return previous && compareArrays(current, previous)
        ? previous
        : current;
}

export const OrderTypeFilter: FilterDefinition<FilterState, undefined, OrderInfoFE, OrderType[]> = {
    name: filterName,
    defaultState: {
        [ProductType.Session]: false,
        [ProductType.Bundle]: false,
        [ProductType.Digital]: false,
        [ProductType.Lead]: false,
        [ProductType.Membership]: false,
        [ProductType.Custom]: false,
        [ProductType.Link]: false,
        [ProductType.Referral]: false,
        direct: false,
    },
    RowMenu,
    createFilterFunction,
    toServer,
};
