import { useTranslation } from 'react-i18next';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { trpc } from ':frontend/context/TrpcProvider';
import { Modal, Button, SpinnerButton } from ':components/shadcn';
import type { LocationOutput } from ':utils/entity/location';
import { Trash2Icon } from ':components/icons/basic';

type DeleteLocationModalProps = Readonly<{
    location?: LocationOutput;
    onClose(): void;
    onDelete(deletedLocation: LocationOutput): void;
}>;

export function DeleteLocationModal({ location, onClose, onDelete }: DeleteLocationModalProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.locations.deleteModal' });
    const { addAlert } = useNotifications();

    const deleteLocationMutation = trpc.location.deleteLocation.useMutation();

    function deleteLocation() {
        if (!location)
            return;

        deleteLocationMutation.mutate({ id: location.id }, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
                onClose();
            },
            onSuccess: () => {
                addAlert(createTranslatedSuccessAlert('common:locationDeletedAlert'));
                onDelete(location);
                onClose();
            },
        });
    }

    return (
        <Modal.Root
            open={!!location}
            onOpenChange={open => !open && onClose()}
        >
            <Modal.Content className='gap-6' closeButton={t('cancel-button')}>
                <Modal.Header className='space-y-4'>
                    <Trash2Icon size={32} className='mx-auto text-danger-500 stroke-2' />

                    <Modal.Title className='text-3xl leading-9 text-center font-semibold text-secondary-900'>
                        {t('title')}
                    </Modal.Title>
                </Modal.Header>

                <div className='text-center'>
                    {t('text')}
                    <br />
                </div>

                <Modal.Footer className='grid grid-cols-2 gap-4'>
                    <Button variant='secondary' onClick={onClose}>
                        {t('cancel-button')}
                    </Button>

                    <SpinnerButton
                        variant='danger'
                        isFetching={deleteLocationMutation.isPending}
                        onClick={deleteLocation}
                    >
                        {t('confirm-button')}
                    </SpinnerButton>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    );
}
