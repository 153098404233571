import { useEffect, useState } from 'react';
import { EventFE } from ':frontend/types/Event';
import { Pagination } from ':frontend/components/common/Pagination';
import { Table } from ':frontend/components/common/Table';
import { EventStateBadge } from ':frontend/components/event/EventStateBadge';
import { getSkeletonArray } from ':utils/math';
import { useTranslation } from 'react-i18next';
import { usePagination, type PaginationController } from ':frontend/hooks';
// import localStorage from ':frontend/utils/localStorage';
import { ClientIconLink } from '../client/ClientIconLink';
import { EventStateFilter } from '../common/filters/EventStateFilter';
import { FilterRow, useFilters } from '../common/filters/FilterRow';
import { eventDetailLinkOnClick } from ':frontend/pages/event/EventDetail';
import { TeamMemberBadge } from '../team/TeamMemberBadge';
import { useUser } from ':frontend/context/UserProvider';
import { type Entity, type Id } from ':utils/id';
import type { EventState } from ':utils/entity/event';
import { TeamMemberRole } from ':utils/entity/team';
import { type EnumFilter } from ':utils/common';
import { trpc } from ':frontend/context/TrpcProvider';
import { Tooltip } from ':components/shadcn';
import { NotepadIcon } from ':components/icons/basic';
import { useNavigate } from 'react-router-dom';
import { useMaster } from ':frontend/context/UserProvider';
import { routesFE } from ':utils/routes';
import { DateTimeDisplay } from ':components/custom/DateTimeDisplay';
import { SortOrder } from ':utils/query';
import { SortButton, useSort } from '../common/SortButton';

// const FILTER_KEY = 'events_table_filter_0';

const filters = [ EventStateFilter ];

type Filter = {
    state: EnumFilter<EventState>;
    clientIds: Id[];
};

const sortKeys = [ 'start' ] as const;

type EventsTableProps = {
    client: Entity;
    filter?: Filter;
};

export function EventsTable(props: EventsTableProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventsTable' });
    const [ events, setEvents ] = useState<EventFE[]>();
    // const [ filter, setFilter ] = useState<Filter>(props.filter ?? localStorage.get<Filter>(FILTER_KEY) ?? {
    //     state: {
    //         [EventState.ready]: false,
    //         [EventState.finished]: false,
    //         [EventState.canceled]: false,
    //     },
    //     clientIds: [],
    // });

    const sort = useSort({ key: 'start', order: SortOrder.Descending }, sortKeys);

    const filtersControl = useFilters(filters);
    const stateFilterToServer = filtersControl.toServer(EventStateFilter.name);

    const { isNotesModuleOn } = useMaster().teamSettings;

    const pagination = usePagination();
    const { page, setTotalItems, reset } = pagination;
    useEffect(() => reset(), [ filtersControl.state, reset ]);

    const eventsQuery = trpc.event.getEvents.useQuery({
        state: stateFilterToServer as EventState[],
        // participants: props.client ? [ props.client.id ] : filter.clientIds,
        participants: [ props.client.id ],
        page,
        sort: sort.state,
    });

    useEffect(() => {
        if (!eventsQuery.data)
            return;

        const { items, total } = eventsQuery.data;
        setEvents(items.map(EventFE.fromServer));
        setTotalItems(total);
    }, [ eventsQuery.data, setTotalItems ]);

    const isMaster = useUser().role === TeamMemberRole.master;

    return (
        <div className='space-y-2'>
            <FilterRow control={filtersControl} />

            <div className='fl-hide-scrollbar overflow-x-auto max-lg:-mx-4 space-y-2'>
                <div className='space-y-4 max-lg:px-4 max-lg:w-fit'>
                    <Table py={5} className='bg-white'>
                        {!events ? (
                            <Table.HeaderSkeleton height={17} />
                        ) : (
                            <Table.Header>
                                <Table.HeaderCol>{t('title-label')}</Table.HeaderCol>

                                <Table.HeaderCol>{t('other-clients-label')}</Table.HeaderCol>
                                {/* TODO enable filtering by clients when no client is provided */}
                                {/* : (
                                    <Table.HeaderCol xs={3}>
                                    <span className='mr-4'>{t('clients-label')}</span>
                                    <ClientIdFilter
                                    value={filter.clientIds}
                                    onChange={clientIds => setFilter({ ...filter, clientIds })}
                                    />
                                    </Table.HeaderCol>
                                    )
                                } */}
                                <Table.HeaderCol>
                                    <div className='flex items-center gap-1'>
                                        <span className='whitespace-nowrap'>{t('date-start-label')}</span>

                                        <SortButton sort={sort} name='start' />
                                    </div>
                                </Table.HeaderCol>

                                <Table.HeaderCol>{t('state-label')}</Table.HeaderCol>

                                {isNotesModuleOn && <Table.HeaderCol xs='fit' className='text-center'>{t('notes-label')}</Table.HeaderCol>}

                                {isMaster && (
                                    <Table.HeaderCol xs='fit'>{t('other-owner-label')}</Table.HeaderCol>
                                )}
                            </Table.Header>
                        )}
                        <Table.Body>
                            <EventsList events={events} client={props.client} pagination={pagination} isNotesModuleOn={isNotesModuleOn} />
                        </Table.Body>
                    </Table>

                    <Pagination controller={pagination} />
                </div>
            </div>

        </div>
    );
}

type EventsListProps = Readonly<{
    events?: EventFE[];
    client: Entity;
    pagination: PaginationController;
    isNotesModuleOn: boolean;
}>;

function EventsList({ events, client, pagination, isNotesModuleOn }: EventsListProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventsTable' });
    const navigate = useNavigate();

    const isMaster = useUser().role === TeamMemberRole.master;

    if (!events)
        return <>{getSkeletonArray(pagination).map(id => <Table.RowSkeleton key={id} height={23} />)}</>;

    if (events.length === 0) {
        return (
            <Table.Row>
                <Table.Col colSpan={5} >
                    <div className='text-center text-danger-500 text-lg py-12'>
                        {t('no-events-text')}
                    </div>
                </Table.Col>
            </Table.Row>
        );
    }

    return (<>
        {events.map(event => (
            <Table.Row key={event.id} className='hover:bg-secondary-50 cursor-pointer'>
                <Table.Col xs={3} truncate className='font-semibold leading-5 truncate max-w-sm' onClick={eventDetailLinkOnClick({ event, navigate })}>
                    {event.displayTitle}
                </Table.Col>

                <Table.Col truncate className='select-none cursor-default max-w-sm'>
                    <div className='flex gap-2 flex-wrap'>
                        {event.guests
                            .filter(participants => !client || participants.client.id !== client.id)
                            .map(participants => <ClientIconLink
                                key={participants.client.id}
                                client={participants.client}
                            />)
                        }
                    </div>
                </Table.Col>

                <Table.Col onClick={eventDetailLinkOnClick({ event, navigate })}>
                    <DateTimeDisplay dateTime={event.start} />
                </Table.Col>

                <Table.Col onClick={eventDetailLinkOnClick({ event, navigate })}>
                    <EventStateBadge event={event} />
                </Table.Col>

                {isNotesModuleOn && (
                    <Table.Col xs='fit' className='text-center select-none'>
                        {event.notes && (
                            <EventNotesTooltip
                                event={event}
                                onClick={() => navigate(routesFE.clients.detail.resolve({ id: client.id, key: 'notes' }))}
                            />
                        )}
                    </Table.Col>
                )}

                {isMaster && (
                    <Table.Col xs='fit' className='select-none cursor-default'>
                        <div className='flex justify-center'>
                            <TeamMemberBadge appUserId={event.ownerId} />
                        </div>
                    </Table.Col>
                )}
            </Table.Row>
        ))}
    </>);
}

type EventNotesTooltipProps = Readonly<{
    event: EventFE;
    onClick?: () => void;
}>;

export function EventNotesTooltip({ event, onClick }: EventNotesTooltipProps) {
    return (
        <Tooltip
            tooltipText={event.notes}
            side='left'
            className='max-w-[300px] text-base bg-warning-200 leading-5 border-warning-200 text-warning-900 p-6 whitespace-pre-line'
            onClick={onClick}
        >
            <NotepadIcon size='md' className='text-warning-500' />
        </Tooltip>
    );
}
