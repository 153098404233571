import { deepClone, deepEquals, zColor, zRequiredString } from ':utils/common';
import { zDateTime } from ':utils/dateTime';
import { z } from 'zod';
import { zFileOutput, zFileUpsert } from './file';

export type StoreSocial = z.infer<typeof zStoreSocial>;
const zStoreSocial = z.object({
    // FIXME this should be an enum
    type: z.string(),
    url: z.string(),
    title: z.string(),
});

export type StoreBio = z.infer<typeof zStoreBio>;
const zStoreBio = z.object({
    name: z.string(),
    headline: z.string().optional(),
    description: z.string().optional(),
    videoUrl: z.string().optional(),
    socials: z.array(zStoreSocial),
});

export type StoreBackground = z.infer<typeof zStoreBackground>;
export const zStoreBackground = z.object({
    type: z.enum([ 'solid', 'gradient' ]),
    color1: zColor,
    /** For gradients. */
    color2: zColor,
});

export type StoreDesign = z.infer<typeof zStoreDesign>;
export const zStoreDesign = z.object({
    background: zStoreBackground,
    card: z.object({
        corners: z.enum([ 'square', 'rounded' ]),
        shadow: z.enum([ 'none', 'soft', 'hard' ]),
        button: z.enum([ 'square', 'rounded', 'pill' ]),
        buttonBackground: zColor,
        buttonColor: zColor,
    }),
    font: z.string(),
});

export function cloneStoreDesign(design: StoreDesign): StoreDesign {
    return deepClone(design);
}

export function isStoreDesignEqual(design: StoreDesign, original: StoreDesign): boolean {
    return deepEquals(design, original);
}

// 2^16 ... This should be enough for 30 A4 pages of text.
export const TERMS_OF_SERVICE_MAX_LENGTH = 65536;

export const DOMAIN_PREFIX = '__DOMAIN__';
export type StoreDomainSlugOutput = z.infer<typeof zStoreDomainSlug>;
export const zStoreDomainSlug = z.string().transform(value => value.startsWith(DOMAIN_PREFIX) ? { domain: value.replace(DOMAIN_PREFIX, '') } : { slug: value });

export type StoreOutput = z.infer<typeof zStoreOutput>;
export const zStoreOutput = z.object({
    isPaymentEnabled: z.boolean(),
    slug: z.string(),
    bio: zStoreBio,
    design: zStoreDesign,
    image: zFileOutput.optional(),
    termsOfService: z.string().optional(),
    hideFlowlanceBranding: z.boolean(),
    metaPixelId: z.string().optional(),
    domain: z.string(),
});

export type StorePublicOutput = z.infer<typeof zStorePublicOutput>;
export const zStorePublicOutput = zStoreOutput.extend({
    isStoreEnabled: z.boolean(),
});

/** Like PATCH (then like PUT for nested objects). */
export type StoreEdit = z.infer<typeof zStoreEdit>;
export const zStoreEdit = z.object({
    slug: zRequiredString(),
    bio: zStoreBio,
    design: zStoreDesign,
    image: zFileUpsert.nullable(),
    termsOfService: z.string().nullable(),
    hideFlowlanceBranding: z.boolean().nullable(),
    metaPixelId: z.string().nullable(),
    domain: z.string(),
}).partial();

type Font = { name: string, weight: number };

export const defaultFont = 'Gilmer';

/** There is also the default font, Gilmer, which is not custom but is available (also, it's the default option). */
export const customFonts: Font[] = [
    { name: 'Manrope', weight: 700 },
    { name: 'Montserrat', weight: 600 },
    { name: 'Poppins', weight: 500 },
    { name: 'Raleway', weight: 600 },
    { name: 'Roboto', weight: 600 },
    { name: 'Space Grotesk', weight: 700 },
    { name: 'Nunito', weight: 700 },
    { name: 'Playfair Display', weight: 700 },
    { name: 'Karla', weight: 700 },
    { name: 'Fira Sans', weight: 500 },
    { name: 'Lora', weight: 600 },
    { name: 'Anton', weight: 400 },
    { name: 'Inter', weight: 600 },
    // This font is disabled because it has very large descenders, which make it hard to center.
    // Also, its baseline is extremely high, it's just a pain to watch, honestly.
    // { name: 'Amiko', weight: 600 },
    { name: 'Mulish', weight: 700 },
    { name: 'Crimson Text', weight: 700 },
    { name: 'Roboto Mono', weight: 500 },
];

export const customFontsUrl = customFontsToUrl(customFonts);

function customFontsToUrl(fonts: Font[]) {
    const payload = fonts.map(font => `family=${fontToDefinition(font)}`).join('&');
    return `https://fonts.googleapis.com/css2?${payload}&display=swap`;
}

function fontToDefinition({ name, weight }: Font) {
    return name.replace(/ /g, '+') + ':wght@' + weight;
}

export function fontNameToUrl(name: string) {
    const font = customFonts.find(font => font.name === name);
    if (!font)
        throw new Error(`Unknown font: ${name}`);

    return customFontsToUrl([ font ]);
}

export type StoreValidationOutput = z.infer<typeof zStoreValidationOutput>;
export const zStoreValidationOutput = z.discriminatedUnion('type', [ z.object({
    type: z.literal('available'),
    slug: z.string(),
}), z.object({
    type: z.literal('taken'),
    suggestion: z.string(),
}) ]);

export enum VisitType {
    Store = 'store',
    Product = 'product',
    Referral = 'referral',
}

export type VisitOutput = z.infer<typeof zVisitOutput>;
export const zVisitOutput = z.object({
    createdAt: zDateTime,
});

export type VisitInitInput = z.input<typeof zVisitInit>;
export type VisitInitParsed = z.output<typeof zVisitInit>;
export const zVisitInit = z.object({
    domainSlug: zStoreDomainSlug,
    productSlug: z.string().optional(),
    isReferral: z.boolean().optional(),
});
