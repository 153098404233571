import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from ':frontend/context/UserProvider';
import { Button, Modal, Spinner } from ':components/shadcn';
import { secondsToMilliseconds } from ':utils/dateTime';
import { trpc } from ':frontend/context/TrpcProvider';
import useNotifications from ':frontend/context/NotificationProvider';
import { createErrorAlert, createTranslatedErrorAlert } from '../notifications';
import { StripeConnectionState } from ':utils/entity/team';
import { isNewTypedError } from ':frontend/types/TypedError';
import { CountryNotSupportedError } from ':utils/error/stripe.error';

type NoStripeModalProps = Readonly<{
    show: boolean;
    onClose: (isSuccess: boolean) => void;
}>;

export function NoStripeModal({ show, onClose }: NoStripeModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'noStripeModal' });
    const { team } = useUser();
    const [ connectionUrl, setConnectionUrl ] = useState<string>();
    const [ isConnecting, setIsConnecting ] = useState(false);

    const connectStripeMutation = trpc.team.connectStripe.useMutation();
    const { addAlert } = useNotifications();

    const fetchConnectionUrl = useCallback(() => {
        connectStripeMutation.mutate(undefined, {
            onError: error => {
                if (isNewTypedError(error.data, CountryNotSupportedError.type))
                    addAlert(createTranslatedErrorAlert('pages:payments.stripeIntegration.country-not-supported-alert'));
                else
                    addAlert(createErrorAlert(error.data));
            },
            onSuccess: response => {
                setConnectionUrl(response.onboardingUrl);
            },
        });
    }, [ connectStripeMutation.mutate ]);

    useEffect(() => {
        // The link should be fetched in two cases:
        // - The modal was just opened (and wasn't opened before).
        // - The user clicked on the new-link-button.
        if (!show || connectionUrl || isConnecting)
            return;

        fetchConnectionUrl();
    }, [ show, connectionUrl, isConnecting, fetchConnectionUrl ]);

    const connectToStripe = useCallback(() => {
        if (!connectionUrl)
            return;

        setIsConnecting(true);
        setConnectionUrl(undefined);
        window.open(connectionUrl, '_blank', 'noreferrer');
    }, [ connectionUrl ]);

    const utils = trpc.useUtils();

    useEffect(() => {
        if (!isConnecting)
            return;

        const interval = setInterval(() => utils.team.getTeam.invalidate(), secondsToMilliseconds(1));

        return () => clearInterval(interval);
    }, [ isConnecting, utils ]);

    useEffect(() => {
        if (team.stripeConnectionState === StripeConnectionState.connected) {
            setIsConnecting(false);
            onClose(true);
        }
    }, [ team, onClose ]);

    return (
        <Modal.Root open={show} onOpenChange={open => !open && onClose(false)}>
            <Modal.Content className='max-w-lg' closeButton={t('cancel-button')}>
                <Modal.Header className='space-y-4'>
                    <Modal.Title>{t('title')}</Modal.Title>
                    <Modal.Description>{t('description')}</Modal.Description>
                </Modal.Header>

                <div className='mt-12'>
                    {isConnecting ? (<>
                        <div>
                            {t('wait-for-connection-text')}
                        </div>
                        <div className='flex items-center mt-4'>
                            <span className='mr-4'>
                                {t('new-link-text')}
                            </span>
                            <Button
                                onClick={() => setIsConnecting(false)}
                                variant='white'
                                size='tiny'
                            >
                                {t('new-link-button')}
                            </Button>
                        </div>
                    </>) : connectionUrl ? (
                        <Button onClick={connectToStripe}>
                            {team.stripeConnectionState === StripeConnectionState.verificationRequired
                                ? t('verify-stripe-button')
                                : t('connect-stripe-button')
                            }
                        </Button>
                    ) : (
                        <div className='h-[42px] flex items-center'>
                            <span className='mr-4'>
                                {t('wait-for-url-text')}
                            </span>
                            <Spinner size='sm' />
                        </div>
                    )}
                </div>
            </Modal.Content>
        </Modal.Root>
    );
}
