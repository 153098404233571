import { ProductType } from ':utils/entity/product';
import { productStyles } from ':components/store/product/ProductCard';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from ':components/shadcn';
import { Link } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { ProductComingSoonBadge } from ':frontend/components/product/productForms';
import { cn } from ':components/shadcn/utils';
import { ArrowRightIcon, Box2PlusIcon } from ':components/icons/basic';
import { useToggle } from ':frontend/hooks';
import { NewProductUsecase } from './useProductForms';
import { useMemo } from 'react';
import { trpc } from ':frontend/context/TrpcProvider';
import { FIRST_PLAN_ID, StiggFeature } from ':utils/lib/stigg';
import { useEntitlement } from ':frontend/lib/stigg';
import { CheckoutModal } from ':frontend/pages/settings/Subscriptions';

type CreateProductCardProps = Readonly<{
    className?: string;
    usecase: NewProductUsecase;
}>;

export function CreateProductCard({ className, usecase }: CreateProductCardProps) {
    const { t } = useTranslation('components', { keyPrefix: 'createProductCard' });
    const products = trpc.product.getProducts.useQuery();
    const maxProducts = useEntitlement(StiggFeature.MaxProducts);
    const [ showUpsellModal, setShowUpsellModal ] = useToggle(false);
    const [ showNewProductModal, setShowNewProductModal ] = useToggle(false);

    const onNewProduct = useMemo(() => {
        if (!maxProducts)
            return setShowNewProductModal.true;

        if (!products.data)
            return setShowNewProductModal.true;

        if (products.data.length >= maxProducts)
            return setShowUpsellModal.true;

        return setShowNewProductModal.true;
        
    }, [ products.data, maxProducts, setShowNewProductModal.true, setShowUpsellModal.true ]);


    return (
        <div className={cn('max-w-full w-[600px] bg-white p-6 rounded-2xl border-2 border-primary max-md:space-y-4 md:flex md:justify-between md:items-end', className)}>
            <div className='flex flex-col gap-3'>
                <Box2PlusIcon size={22} className='text-primary' />

                <span className='text-lg text-primary-800'>{t('card-title')}</span>

                <div className='flex gap-2 ml-2'>
                    {productTypes.map(type => {
                        const Icon = productStyles[type].icon;
                        return (
                            <Icon key={type} size='sm' />
                        );
                    })}
                </div>
            </div>

            <Button variant='primary' onClick={onNewProduct} className='w-full md:w-auto'>
                {t('create-button')}<ArrowRightIcon />
            </Button>
            <CheckoutModal isOpen={showUpsellModal} onClose={setShowUpsellModal.false} plan={{ id: FIRST_PLAN_ID }}/>        
            <NewProductModal open={showNewProductModal} onClose={setShowNewProductModal.false} usecase={usecase} />
        </div>
    );
}

type NewProductModalProps = Readonly<{
    open: boolean;
    onClose: () => void;
    usecase: NewProductUsecase;
}>;

export function NewProductModal({ open, onClose, usecase }: NewProductModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'createProductCard.typeModal' });

    return (
        <Modal.Root open={open} onOpenChange={open => !open && onClose()}>
            <Modal.Content className='max-w-fit gap-8 sm:gap-12 px-6 py-8 md:p-12' closeButton={t('cancel-button')}>
                <Modal.Header>
                    <Modal.Title className='text-4xl leading-10 text-center'>{t('title')}</Modal.Title>
                    <Modal.Description className='mt-2 text-md leading-5 text-center'>{t('description')}</Modal.Description>
                </Modal.Header>

                <ProductTypeSignpost usecase={usecase} />
            </Modal.Content>
        </Modal.Root>
    );
}

function ProductTypeSignpost({ usecase }: Readonly<{ usecase: NewProductUsecase }>) {
    const route = usecase === NewProductUsecase.Product ? routesFE.products.new : routesFE.products.newPage;

    const { types, oddOne } = useMemo(() => {
        const array = usecase === NewProductUsecase.Product ? productTypes : pageTypes;
        return array.length % 2 === 0 ? {
            types: array,
            oddOne: undefined,
        } : {
            types: array.slice(0, -1),
            oddOne: array[array.length - 1],
        };
    }, [ usecase ]);

    return (
        <div className='max-w-[538px] w-full'>
            <div className='grid sm:grid-cols-2 gap-8 sm:gap-12'>
                {types.map(type => isComingSoon(type) ? (
                    // Referral type has really long description which would cause a high gap between Link and Membership
                    <div key={type}>
                        <ProductTypeTile type={type} comingSoon />
                    </div>
                ) : (
                    <Link key={type} to={route.resolve({ type })}>
                        <ProductTypeTile key={type} type={type} />
                    </Link>
                ))}
            </div>
            {oddOne && (<>
                <div className='h-px w-full bg-secondary-100 my-6 sm:my-8' />
                <div className='w-fit mx-auto'>
                    <Link to={route.resolve({ type: oddOne })}>
                        <ProductTypeTile type={oddOne} isCenter />
                    </Link>
                </div>
            </>)}
        </div>
    );
}

function isComingSoon(type: ProductType) {
    return type === ProductType.Membership;
}

const productTypes: ProductType[] = [
    ProductType.Session,
    ProductType.Digital,
    ProductType.Bundle,
    ProductType.Lead,
    ProductType.Link,
    ProductType.Custom,
    ProductType.Membership,
    ProductType.Referral,
];

const pageTypes: ProductType[] = [
    ProductType.Session,
    ProductType.Digital,
    ProductType.Bundle,
    ProductType.Lead,
    ProductType.Custom,
    // Products without checkout are excluded by default.
    // Membership is excluded because it's not supported yet. However, it's still among the products for some reason.
];

type ProductTypeTileProps = Readonly<{
    type: ProductType;
    comingSoon?: boolean;
    isCenter?: boolean;
}>;

function ProductTypeTile({ type, isCenter, comingSoon }: ProductTypeTileProps) {
    const { t } = useTranslation('components', { keyPrefix: `productTypeTile.${type}` });
    const { icon, color } = productStyles[type];
    const isComingSoon = !!comingSoon;

    return (
        <div className={clsx('group max-w-64 flex flex-col gap-3 items cursor-pointer', isComingSoon && 'pointer-events-none', isCenter && 'items-center')}>
            <div className='flex items-center gap-2 text-lg'>
                {icon({ size: 22 })}
                <span className={color}>{t('title')}</span>
            </div>
            <div className={clsx('text-secondary-400 text-balance group-hover:text-secondary-600', isCenter && 'text-center')}>{t('description')}</div>
            {isComingSoon && (
                <ProductComingSoonBadge type={type} />
            )}
        </div>
    );
}
