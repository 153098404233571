export const FREE_PLAN_ID = 'plan-flowlance-tier1';
export const FIRST_PLAN_ID = 'plan-flowlance-tier2';
export const PRO_PLAN_ID = 'plan-flowlance-tier3';
export const APPSUMO_PLAN_ID = 'plan-flowlance-app-sumo';

export enum StiggFeature {
    // Boolean features
    Booking = 'feature-booking-enabled',
    CustomDomain = 'feature-custom-domain-enabled',
    CustomProductUrl = 'feature-custom-product-url-enabled',
    CustomStoreUrl = 'feature-custom-store-url-enabled',
    TermsOfServices = 'feature-terms-of-services-enabled',
    Invoicing = 'feature-invoicing-enabled',
    MetaPixel = 'feature-meta-pixel-enabled',
    RemoveFlowlanceBranding = 'feature-remove-flowlance-branding-enabled',
    Seo = 'feature-seo-enabled',
    Store = 'feature-store-enabled',
    BankTransfer = 'feature-bank-transfer-enabled',

    // Numeric features
    Fee = 'feature-fee',
    MaxProducts = 'feature-max-products',
    StatsHistory = 'feature-stats-history',
}

const booleanFeatures = [
    StiggFeature.Booking,
    StiggFeature.CustomDomain,
    StiggFeature.CustomProductUrl,
    StiggFeature.CustomStoreUrl,
    StiggFeature.TermsOfServices,
    StiggFeature.Invoicing,
    StiggFeature.MetaPixel,
    StiggFeature.RemoveFlowlanceBranding,
    StiggFeature.Seo,
    StiggFeature.Store,
    StiggFeature.BankTransfer,
] as const;

type BooleanFeature = typeof booleanFeatures[number];

const booleanFeaturesSet: Set<StiggFeature> = new Set(booleanFeatures);
export function isBooleanFeature(feature: StiggFeature): feature is BooleanFeature {
    return booleanFeaturesSet.has(feature);
}

export type StiggFeatureOutput<TFeature extends StiggFeature> = TFeature extends BooleanFeature
    ? boolean
    // Each entitlement has the "hasAccess" property. However, right now, we treat the numeric ones as "always has access" and "if undefined, there are no limitations".
    : (number | undefined);

const firstPlanFeaturesSet: Set<StiggFeature> = new Set([
    StiggFeature.Booking,
    StiggFeature.CustomDomain,
    StiggFeature.CustomProductUrl,
    StiggFeature.CustomStoreUrl,
    StiggFeature.TermsOfServices,
    StiggFeature.Invoicing,
    StiggFeature.MetaPixel,
    StiggFeature.Seo,
    StiggFeature.Store,
    StiggFeature.BankTransfer,
    StiggFeature.Fee,
    StiggFeature.MaxProducts,
    StiggFeature.StatsHistory,
]);

/**
 * Returns the first public plan that has access to the given feature.
 */
export function getSubscriptionPlanForFeature(feature: StiggFeature): string {
    return firstPlanFeaturesSet.has(feature) ? FIRST_PLAN_ID : PRO_PLAN_ID;
}
