import type { StoreBackground, StoreDesign } from ':utils/entity/store';
import type { CSSProperties } from 'react';
import clsx from 'clsx';
import { isColorDark } from ':utils/common';

/**
 * Use as <div {...getStoreStyles(storeDesign satisfies StoreDesign)}>.
 * @returns Subset of React.HTMLAttributes<HTMLElement>.
 */
export function getStoreStyles(design: StoreDesign, className?: string): { className: string, style: CSSProperties } {
    const storeClassName = `fl-store-corners_${design.card.corners} fl-store-shadow_${design.card.shadow} fl-store-button_${design.card.button}`;
    const background = getStoreBackgroundStyle(design.background);
    const isBackgroundDark = isColorDark(design.background.color1);

    return {
        className: clsx(storeClassName, className),
        style: {
            '--fl-store-bg': background,
            '--fl-store-color-base': isBackgroundDark ? '#cecad7' : '#5f5572', // secondary-200 vs secondary-500 (aka base).
            '--fl-store-color-highlight': isBackgroundDark ? '#fff' : '#0e0227', // white vs
            '--fl-store-color-muted': isBackgroundDark ? '#9d94af' : '#9d94af', // secondary-400 vs secondary-400.
            '--fl-store-button-bg': `#${design.card.buttonBackground}`,
            '--fl-store-button-color': `#${design.card.buttonColor}`,
            '--fl-store-font': design.font,
        },
    };
}

export function getStoreBackgroundStyle(background: StoreBackground, direction: 'y' | 'x' = 'y'): string {
    const angle = direction === 'y' ? 180 : 90;
    return background.type === 'gradient'
        ? `linear-gradient(${angle}deg, #${background.color1}, #${background.color2})`
        : `#${background.color1}`;
}

/** @internal Useful only for CSS variables, not usable for every CSS */
export function styleToCss(style: CSSProperties): string {
    return Object.entries(style).map(([ key, value ]) => `${key}: ${value};`).join(' ');
}
