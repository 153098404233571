import { useCallback, useMemo, type ReactNode } from 'react';
import { Form, Select, SpinnerButton } from ':components/shadcn';
import { Controller, useForm, type Control, type FieldPath, type FieldValues } from 'react-hook-form';
import { getEnumValues } from ':utils/common';
import { useTranslation } from 'react-i18next';
import { useNestedForm } from ':frontend/utils/forms';
import type { TFunction } from 'i18next';
import { PlatformTypeIcon } from './LocationDisplay';
import { MAX_TITLE_LENGTH } from ':frontend/types/Event';
import { getPlatformType, PlatformType, type LocationOutput } from ':utils/entity/location';
import { createTFunctionWithVariant } from ':utils/i18n';

type LocationFormProps = Readonly<{
    input?: Partial<LocationOutput>;
    isFetching: boolean;
    onSubmit(data: LocationFormData): void;
    typeIsConstant?: boolean;
}>;

export function LocationForm({ input, isFetching, onSubmit, typeIsConstant }: LocationFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'newLocation' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });
    const { register, handleSubmit, watch, control, formState: { isDirty, isValid } } = useForm<LocationFormData>({
        defaultValues: computeDefaultValues(input),
    });
    const handleNestedSubmit = useNestedForm(handleSubmit);

    const platformType = watch('platformType');
    console.log('PLATFORM TYPE', platformType);
    const tt = useMemo(() => createTFunctionWithVariant(t, platformType), [ t, platformType ]);

    function submit(data: LocationFormData) {
        onSubmit({ ...data });
    }

    return (
        <Form.Root onSubmit={handleNestedSubmit(submit)} className='space-y-6'>
            <div>
                <Form.Label>{t('type-label')}</Form.Label>
                <ControlledPlatformTypeSelect
                    name='platformType'
                    control={control}
                    isDisabled={typeIsConstant}
                />
            </div>

            <div>
                <Form.Input
                    label={t('title-label')}
                    {...register('title', { required: tf('title-requried'), maxLength: { value: MAX_TITLE_LENGTH, message: tf('text-too-long', { count: MAX_TITLE_LENGTH }) } })}
                    placeholder={t('title-placeholder')}
                />
            </div>

            {platformType === PlatformType.custom && (
                <div>
                    <Form.Input
                        label={t('platform-label')}
                        {...register('platform', { required: t('platform-required') })}
                        placeholder={t('platform-placeholder')}
                    />
                </div>
            )}

            <div>
                <Form.Input
                    label={tt('value-label')}
                    placeholder={tt('value-placeholder')}
                    {...register('value', { required: tt('value-required') })}
                />
            </div>

            <div>
                <SpinnerButton
                    isFetching={isFetching}
                    type='submit'
                    className='w-full'
                    disabled={!isDirty || !isValid}
                >
                    {t('save-button')}
                </SpinnerButton>
            </div>
        </Form.Root>
    );
}

export type LocationFormData = {
    title: string;
    platformType: PlatformType;
    platform: string;
    value: string;
}

function computeDefaultValues(input: Partial<LocationOutput> | undefined): LocationFormData {
    return {
        title: input?.title ?? '',
        platform: input?.platform ?? '',
        value: input?.value ?? '',
        platformType: input?.platform ? getPlatformType(input.platform) : PlatformType.googleMeet,
    };
}

type Option = {
    value: PlatformType;
    label: ReactNode;
}

function generalPlatformTypeToOption(t: TFunction, type: PlatformType): Option {
    return {
        value: type,
        label: (
            <div className='flex items-center gap-2'>
                <PlatformTypeIcon type={type} size={22} />
                {t(`option-${type}`)}
            </div>
        ),
    };
}

type ControlledPlatformTypeSelectProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    isDisabled?: boolean;
};

function ControlledPlatformTypeSelect<TFieldValues extends FieldValues>({ control, name, isDisabled }: ControlledPlatformTypeSelectProps<TFieldValues>) {
    const { t } = useTranslation('pages', { keyPrefix: 'newLocation' });

    const platformTypeToOption = useCallback((type: PlatformType) => generalPlatformTypeToOption(t, type), [ t ]);

    const options = useMemo(() => getEnumValues(PlatformType).map(platformTypeToOption), [ platformTypeToOption ]);

    const InnerSelect = useCallback(({ field }: { field: { value: PlatformType, onChange: (value?: PlatformType ) => void } }) => {
        return (
            <Select
                options={options}
                value={platformTypeToOption(field.value)}
                onChange={option => field.onChange(option?.value)}
                isDisabled={isDisabled}
            />
        );
    }, [ options, platformTypeToOption, isDisabled ]);

    return (
        <Controller
            control={control}
            name={name}
            render={InnerSelect}
        />
    );
}
