import { useTranslation } from 'react-i18next';
import { Form, SpinnerButton } from ':components/shadcn';
import { type ErrorOption, type FieldPath, useForm } from 'react-hook-form';
import { isRHFError, RHFErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { PasswordInput } from ':frontend/components/forms/PasswordInput';
import { EMAIL_SOFT_VALIDATION_PATTERN, PASSWORD_MINIMAL_LENGTH, canonizeEmail } from ':utils/forms';

export type UserFormData = {
    email: string;
    password: string;
};

export type RegisterError = {
    name: FieldPath<UserFormData>;
    error: ErrorOption;
    options?: {
        shouldFocus: boolean;
    };
};

type RegisterUserFormProps = Readonly<{
    defaultValues?: Partial<UserFormData>;
    onSubmit: (output: UserFormData) => Promise<RegisterError[]>;
    isFetching: boolean;
}>;

export function RegisterUserForm({ defaultValues, onSubmit, isFetching }: RegisterUserFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'register.userForm' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });

    const { register, handleSubmit, setError, formState: { errors } } = useForm<UserFormData>({ defaultValues });

    async function handleValidSubmit(data: UserFormData) {
        const validationErrors = await onSubmit({ ...data, email: canonizeEmail(data.email) });

        validationErrors.forEach(error => setError(error.name, error.error, error.options));
    }

    return (
        <Form.Root onSubmit={handleSubmit(handleValidSubmit)} className='space-y-2'>
            <div>
                <Form.Input
                    label={t('email-label')}
                    type='email'
                    className='text-lg md:text-base'
                    {...register('email', {
                        required: tf('email-required'),
                        pattern: {
                            value: EMAIL_SOFT_VALIDATION_PATTERN,
                            message: tf('email-invalid'),
                        },
                    })}
                    isError={isRHFError(errors, 'email')}
                    autoFocus
                />
                <RHFErrorMessage errors={errors} name='email' />
            </div>

            <div>
                <PasswordInput
                    label={t('password-label')}
                    className='text-lg md:text-base'
                    register={register('password', {
                        required: tf('password-required'),
                        minLength: { value: PASSWORD_MINIMAL_LENGTH, message: tf('password-too-short-error', { minimalLength: PASSWORD_MINIMAL_LENGTH }) },
                    })}
                    isError={isRHFError(errors, 'password')}
                />
                <RHFErrorMessage errors={errors} name='password' />
            </div>

            <SpinnerButton type='submit' className='w-full' isFetching={isFetching}>
                {t('continue-button')}
            </SpinnerButton>
        </Form.Root>
    );
}
