import { type CSSProperties } from 'react';
import clsx from 'clsx';
import { EventWrapper } from './dragAndDrop/EventWrapper';
import { getEventStyle } from './eventStyle';
import type { CalendarEvent } from ':frontend/types/calendar/Calendar';
import { EventTitle } from './TimeGridEvent';
import { useUser } from ':frontend/context/UserProvider';
import { TeamMemberRole } from ':utils/entity/team';
import { classSelectors } from './utils/common';

// TODO This one is used for months and all-day events

type EventCellProps = {
    event: CalendarEvent;
    selected: boolean;
    continuesPrior: boolean;
    continuesAfter: boolean;
    onSelect: (event: CalendarEvent, e: React.MouseEvent<HTMLDivElement>) => void;
    onDoubleClick: (event: CalendarEvent, e: React.MouseEvent<HTMLDivElement>) => void;
    onKeyPress: (event: CalendarEvent, e: React.KeyboardEvent<HTMLDivElement>) => void;
    style?: CSSProperties;
    className?: string;
    onDragEnd: () => void;
};

export function EventCell(props: EventCellProps) {
    const { style, className, event, selected, onSelect, onDoubleClick, onKeyPress, continuesPrior, continuesAfter, ...rest } = props;

    const isMaster = useUser().role === TeamMemberRole.master;
    const userProps = getEventStyle(event, isMaster, selected);

    return (
        <EventWrapper {...props} type='date'>
            <div
                {...rest}
                tabIndex={0}
                style={{ ...userProps.style, ...style }}
                className={clsx(classSelectors.event.class,
                    'relative',
                    selected && 'shadow',
                    className,
                    continuesPrior && 'rounded-l-none',
                    continuesAfter && 'rounded-r-none',
                    userProps.className,
                )}
                onClick={e => onSelect?.(event, e)}
                onDoubleClick={e => onDoubleClick?.(event, e)}
                onKeyPress={e => onKeyPress?.(event, e)}
                title={event.title}
            >
                <div className='shrink w-full min-h-5 px-2 leading-5 overflow-hidden break-words'>
                    <EventTitle event={event} />
                </div>
            </div>
        </EventWrapper>
    );
}
