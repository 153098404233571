import { getFileInputUrl, MoneyDisplay, type FileInputValue } from ':components/custom';
import type { PageOutput } from ':utils/entity/page';
import type { ProductOutput } from ':utils/entity/product';
import { useRef } from 'react';
import { type ProductPreview } from './ProductCard';
import { Button } from ':components/shadcn';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import type { StoreOutput } from ':utils/entity/store';
import { FreelancerBadge } from '../StoreBioDisplay';
import { MarkdownDisplay, MarkdownPreview, type MarkdownControl } from ':components/shadcn/MarkdownDisplay';
import type { UseAvailability } from './ProductSchedulingForm';
import { ProductCheckoutCard, type ProductCheckoutFormOutput } from './ProductCheckoutCard';

// TODO fl-store-font

type ProductCheckoutProps = Readonly<{
    store: StoreOutput;
    isStoreEnabled: boolean;
    product: ProductOutput | ProductPreview;
    landing: PageOutput | PagePreview | undefined;
    isPhonePreview?: boolean;
    useAvailability: UseAvailability;
    onSubmit: (data: ProductCheckoutFormOutput) => void;
    isFetching: boolean;
}>;

export type PagePreview = {
    title: string;
    content: MarkdownControl;
    buttonText: string;
    isHideButton: boolean;
    cover: FileInputValue;
};

export function ProductCheckout({ store, isStoreEnabled, product, landing, isPhonePreview, useAvailability, onSubmit, isFetching }: ProductCheckoutProps) {
    const scrollTarget = useRef<HTMLDivElement>(null);

    const coverUrl = landing && getFileInputUrl(landing.cover);

    return (
        <div className={clsx('grid',
            isPhonePreview && 'fl-phone-preview',
            // With landing page, we try to fit two columns here (if the screen is big enough).
            // Otherwise, we just use one column and we let the checkout component itself decide how to display.
            landing ? 'grid-cols-[minmax(0px,720px)] content-center gap-8' : 'grid-cols-1 max-md:pt-10',
            landing && !isPhonePreview && 'lg:grid-cols-[minmax(420px,720px)_420px] lg:px-8 xl:gap-16',
        )}>
            {landing && (
                <div className='fl-store-text-base w-full h-full max-md:px-4 phone:px-4 flex flex-col gap-4 phone:gap-4 md:gap-6'>
                    {coverUrl ? (
                        <div className='max-md:-mx-4 phone:-mx-4'>
                            <img src={coverUrl} className='object-cover md:rounded-t-2xl phone:rounded-none' />
                        </div>
                    ) : (
                        <div className='phone:block md:hidden' />
                    )}

                    <h2 className='fl-store-text-highlight text-2xl/8 font-semibold break-words'>
                        {landing.title}
                    </h2>

                    {isStoreEnabled && (
                        <div className='space-y-2'>
                            {/* FIXME Translation */}
                            <h3 className='font-semibold'>Meet the creator</h3>

                            <FreelancerBadge store={store} />
                        </div>
                    )}

                    <div className='w-full h-px bg-secondary-300' />

                    {!landing.isHideButton && (<>
                        <div className='phone:flex md:hidden flex flex-wrap items-center justify-between gap-4'>
                            <ProductPricingLabel product={product} isStoreBg />

                            <Button className='fl-store-button min-w-0' onClick={() => !isPhonePreview && scrollTarget.current?.scrollIntoView({ behavior: 'smooth' })}>
                                <span className='leading-6 truncate'>
                                    {landing.buttonText}
                                </span>
                            </Button>
                        </div>

                        <div className='phone:block md:hidden w-full h-px bg-secondary-300' />
                    </>)}

                    {'id' in landing ? (
                        landing.content && <MarkdownDisplay value={landing.content} />
                    ) : (
                        landing.content && <MarkdownPreview control={landing.content} />
                    )}
                </div>
            )}

            {landing ? (
                <div ref={scrollTarget} className='flex items-start justify-center'>
                    <div className='min-w-0 phone:block md:sticky top-12'>
                        <ProductCheckoutCard
                            store={store}
                            product={product}
                            landing={landing}
                            useAvailability={useAvailability}
                            onSubmit={onSubmit}
                            isFetching={isFetching}
                        />
                    </div>
                </div>
            ) : (
                <ProductCheckoutCard
                    store={store}
                    product={product}
                    landing={landing}
                    useAvailability={useAvailability}
                    onSubmit={onSubmit}
                    isFetching={isFetching}
                />
            )}
        </div>
    );
}

type ProductPricingLabelProps = Readonly<{
    product: ProductOutput | ProductPreview;
    isStoreBg?: boolean;
}>;

export function ProductPricingLabel({ product, isStoreBg }: ProductPricingLabelProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });
    const { pricing } = product;

    if (!pricing) {
        return (
            <div className={clsx('text-2xl/8 font-semibold', isStoreBg ? 'fl-store-text-highlight' : 'text-secondary-900')}>
                <div>{t('free-price')}</div>
            </div>
        );
    }

    const { price, originalPrice, currency } = pricing;

    // TODO membership - use /mo for month, /wk for week.
    // /m., /t. should be ok for cz. Or just use the english variant?

    return (
        <div className={clsx('flex flex-wrap items-start gap-x-2 text-2xl/8 font-semibold', isStoreBg ? 'fl-store-text-highlight' : 'text-secondary-900')}>
            {originalPrice && (
                <div className='text-secondary line-through'>
                    &nbsp;<MoneyDisplay amount={originalPrice} currency={currency} className={isStoreBg ? 'fl-store-text-muted' : 'text-secondary-400'} />&nbsp;
                </div>
            )}

            <MoneyDisplay amount={price} currency={currency} />
        </div>
    );
}
