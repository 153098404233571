import { type Dispatch, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMaster, type MasterContext } from ':frontend/context/UserProvider';
import { OrderFE, type ProductOrderInit, productOrderToServer, type CustomOrderInit, customOrderToServer, type EventOrderInitFE, eventOrderToServer } from ':frontend/types/orders/Order';
import { createErrorAlert, createTranslatedSuccessAlert } from '../../notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { routesFE } from ':utils/routes';
import { ClientInfoFE } from ':frontend/types/Client';
import { type CheckoutInput, CheckoutPhase, parseCcEmails, useCheckout, CheckoutType, type CheckoutState, validateOverview, SEND_NOTIFICATION_KEY } from './useCheckout';
import { CheckoutOverview } from './CheckoutOverview';
import { CheckoutEmailPreview } from './CheckoutEmailPreview';
import { InvoicePreviewModal } from './InvoicePreviewModal';
import { type i18n } from 'i18next';
import type { OrderInit } from ':utils/entity/order';
import { trpc } from ':frontend/context/TrpcProvider';
import localStorage from ':frontend/utils/localStorage';

export type CheckoutOutput = {
    type: 'back' | 'close';
} | {
    type: 'addClients';
    clients: ClientInfoFE | ClientInfoFE[];
} | {
    type: 'finish';
    orders: OrderFE[];
};

type CheckoutProps = Readonly<{
    input: CheckoutInput;
    output: Dispatch<CheckoutOutput>;
}>;

export function CheckoutModalInner({ input, output }: CheckoutProps) {
    const { i18n } = useTranslation('components', { keyPrefix: 'checkout' });
    const masterContext = useMaster();
    const { state, dispatch } = useCheckout(input);

    const { addAlert } = useNotifications();

    const createOrderMutation = trpc.order.createOrder.useMutation();

    function createOrder() {
        const formErrors = validateOverview(state);
        if (formErrors) {
            dispatch({ type: 'validation', formErrors });
            return;
        }

        const init = createOrderInit(state, masterContext, i18n);
        createOrderMutation.mutate(init, {
            onError: error => {
                addAlert(createErrorAlert(error.data));
            },
            onSuccess: response => {
                const orders = response.orders.map(OrderFE.fromServer);
                orders.forEach(order => {
                    // TODO Replace by the general alert when all checkouts are united. But be careful about the wording!
                    if (input.type === CheckoutType.Product) {
                        addAlert(createTranslatedSuccessAlert('common:productOrderCreatedAlert', { links: {
                            a: routesFE.orders.detail.resolve({ id: order.id }),
                        }, count: input.items.length }));
                    }
                    else {
                        addAlert(createTranslatedSuccessAlert('common:orderCreatedAlert', { links: {
                            a: routesFE.orders.detail.resolve({ id: order.id }),
                        } }));
                    }
                });

                const newClients = response.newClients.map(ClientInfoFE.fromServer);
                output({ type: 'addClients', clients: newClients });

                output({ type: 'finish', orders });
            },
        });
    }

    const hidePreview = useCallback(() => dispatch({ type: 'invoicePreview' }), [ dispatch ]);

    return (<>
        <InvoicePreviewModal
            invoicePreview={state.invoicePreview}
            onHide={hidePreview}
        />

        {state.phase === CheckoutPhase.Overview && (
            <CheckoutOverview
                output={output}
                state={state}
                dispatch={dispatch}
                isFetching={createOrderMutation.isPending}
                createOrder={createOrder}
            />
        )}

        {state.phase === CheckoutPhase.EmailPreview && (
            <CheckoutEmailPreview state={state} dispatch={dispatch} />
        )}
    </>);
}

// TODO This is highly not optimal. All logic should be in the useCheckout reducer.
function createOrderInit(state: CheckoutState, masterContext: MasterContext, i18n: i18n): OrderInit {
    const { input, overview } = state;
    const paymentMethod = overview.form.paymentMethod!;
    const notification = overview.form.sendNotification
        ? { ...state.emailPreview.form, cc: parseCcEmails(state.emailPreview.form.cc) }
        : undefined;

    localStorage.set(SEND_NOTIFICATION_KEY, !!notification);

    switch (input.type) {
    case CheckoutType.Custom: {
        const init: CustomOrderInit = {
            client: input.client,
            dueDays: input.dueDays,
            items: input.items,
            discount: state.cache.invoicesForClients[0].invoices[0].discount,
            paymentMethod,
            notification,
        };
        return { custom: customOrderToServer(init, masterContext, i18n) };
    }
    case CheckoutType.Product: {
        const init: ProductOrderInit = {
            client: input.client,
            guest: input.guest,
            scheduler: input.scheduler,
            items: input.items,
            discount: state.cache.invoicesForClients[0].invoices[0].discount,
            paymentMethod,
            notification,
        };
        return { product: productOrderToServer(init, masterContext, i18n) };
    }
    case CheckoutType.Event: {
        const init: EventOrderInitFE = {
            paymentMethod,
            notification,
            forClients: input.forClients,
        };
        return { event: eventOrderToServer(init, i18n) };
    }
    }
}
