import { z } from 'zod';
import { zId } from ':utils/id';
import { zFileOutput, zFileUpsert } from './file';
import { zLocationOutput } from './location';
import { zRequiredString, zOptionalString, zEnumRecord } from ':utils/common';
import { zTimezoneCode } from ':utils/i18n';
import { Weekday, zDateRange, zTimeRange } from ':utils/dateTime';
import { zCurrencyId, zTaxRate } from ':utils/money';
import { zPageOutput, zPageUpsert } from './page';

export enum ProductType {
    Session = 'session',
    Bundle = 'bundle',
    Digital = 'digital',
    /** Basically just a digital product with extra steps (and free price). */
    Lead = 'lead',
    Membership = 'membership',
    Link = 'link',
    /** The same as link except the fact it doesn't have url. So it also doesn't have the "hideUrl" option. */
    Referral = 'referral',
    Custom = 'custom',
}

export enum ProductDisplayType {
    Callout = 'callout',
    Button = 'button',
}

/** These products don't have price. The others might have a zero price (i.e., FREE), but that's different. */
export const productsWithoutPricing = [ ProductType.Lead, ProductType.Link, ProductType.Referral ];

/** These products don't have checkout page. They still have slug (for compatibility reasons), but it isn't accessible to the users. */
export const productsWithoutCheckout = [ ProductType.Link, ProductType.Referral ];

/** If these products don't have thumbnail, our logo is used as a placeholder.  */
// If it's referal, we want to always show something (user's icon or our logo as a fallback). However, normal links can be empty.
export const productsWithPlaceholderThumbnail = [ ProductType.Referral ];

export enum ProductLayout {
    SmallLeft = 'smallLeft',
    Large = 'large',
    MediumLeft = 'mediumLeft',
    MediumRight = 'mediumRight',
}

export type Availability = z.infer<typeof zAvailability>;
export const zAvailability = z.object({
    weekdays: zEnumRecord(Weekday, z.array(zTimeRange)),
    blocked: z.array(zDateRange),
    timezone: zTimezoneCode,
});

/**
 * Free product (price is undefined) is not the same as product with price 0. If it's free, it's like the product was a gift - it isn't sent to stripe, it doesn't show up in the invoice, etc.
 * There should be either both price and currency, or neither of them.
 */
export type ProductPricing = z.infer<typeof zProductPricing>;
const zProductPricing = z.object({
    currency: zCurrencyId,
    /** In cents. */
    price: z.number(),
    /**
     * This is displayed above the price as the "original price before discount".
     * In cents.
     */
    originalPrice: z.number().optional(),
    taxRate: zTaxRate,
});

export type BaseProductOutput = z.infer<typeof zBaseProductOutput>;
const zBaseProductOutput = z.object({
    id: zId,
    index: z.number(),
    slug: z.string(),
    isPublic: z.boolean(),
    layout: z.nativeEnum(ProductLayout),
    title: z.string(),
    thumbnail: zFileOutput.optional(),
    description: z.string().optional(),
    limit: z.number().optional(),
    successMessage: z.string().optional(),
    buttonText: z.string(),
});

export type SessionProductOutput = z.infer<typeof zSessionProductOutput>;
const zSessionProductOutput = zBaseProductOutput.extend({
    type: z.literal(ProductType.Session),
    pricing: zProductPricing.optional(),
    /** In seconds. */
    sessionsDuration: z.number(),
    location: zLocationOutput.optional(),
    schedulingUrl: z.string().optional(),
    availability: zAvailability.optional(),
});

export type BundleProductOutput = z.infer<typeof zBundleProductOutput>;
const zBundleProductOutput = zSessionProductOutput.extend({
    type: z.literal(ProductType.Bundle),
    sessionsCount: z.number(),
});

export type DigitalPayload = z.infer<typeof zDigitalPayload>;
export const zDigitalPayload = z.union([
    z.object({ url: z.string() }),
    z.object({ file: zFileOutput }),
]);

export type DigitalProductOutput = z.infer<typeof zDigitalProductOutput>;
const zDigitalProductOutput = zBaseProductOutput.extend({
    type: z.literal(ProductType.Digital),
    pricing: zProductPricing.optional(),
    payload: zDigitalPayload,
});

export type LeadProductOutput = z.infer<typeof zLeadProductOutput>;
const zLeadProductOutput = zBaseProductOutput.extend({
    type: z.literal(ProductType.Lead),
    pricing: z.undefined(),
    payload: zDigitalPayload,
});

export type MembershipProductOutput = z.infer<typeof zMembershipProductOutput>;
const zMembershipProductOutput = zBaseProductOutput.extend({
    type: z.literal(ProductType.Membership),
    pricing: zProductPricing.optional(),
    // FIXME
});

export type LinkProductOutput = z.infer<typeof zLinkProductOutput>;
const zLinkProductOutput = zBaseProductOutput.extend({
    type: z.literal(ProductType.Link),
    pricing: z.undefined(),
    url: z.string(),
    displayType: z.nativeEnum(ProductDisplayType),
    isHideUrl: z.boolean(),
    isHideButton: z.boolean(),
});

export type ReferralProductOutput = z.infer<typeof zReferralProductOutput>;
const zReferralProductOutput = zBaseProductOutput.extend({
    type: z.literal(ProductType.Referral),
    pricing: z.undefined(),
    displayType: z.nativeEnum(ProductDisplayType),
    isHideButton: z.boolean(),
});

export type CustomProductOutput = z.infer<typeof zCustomProductOutput>;
const zCustomProductOutput = zBaseProductOutput.extend({
    type: z.literal(ProductType.Custom),
    pricing: zProductPricing.optional(),
});

export type ProductOutput = z.infer<typeof zProductOutput>;
export const zProductOutput = z.discriminatedUnion('type', [
    zSessionProductOutput,
    zBundleProductOutput,
    zDigitalProductOutput,
    zLeadProductOutput,
    zMembershipProductOutput,
    zLinkProductOutput,
    zReferralProductOutput,
    zCustomProductOutput,
]);

export type FullProductOutput = z.infer<typeof zFullProductOutput>;
export const zFullProductOutput = z.object({
    product: zProductOutput,
    landing: zPageOutput.optional(),
});

export const PRODUCT_DESCRIPTION_MAX_LENGTH = 1000;
export const PRODUCT_URL_MAX_LENGTH = 1000;

/** Like PUT (except for the thumbnail, which is like PATCH). */
export type BaseProductUpsert = z.infer<typeof zBaseProductUpsert>;
export const zBaseProductUpsert = z.object({
    slug: zRequiredString(),
    isPublic: z.boolean(),
    layout: z.nativeEnum(ProductLayout),
    title: zRequiredString(),
    description: zOptionalString(PRODUCT_DESCRIPTION_MAX_LENGTH),
    limit: z.number().optional(),
    successMessage: zOptionalString(PRODUCT_DESCRIPTION_MAX_LENGTH),
    buttonText: zRequiredString(),
    /** Like PATCH. */
    thumbnail: zFileUpsert.nullish(),
});

export type SessionProductUpsert = z.infer<typeof zSessionProductUpsert>;
const zSessionProductUpsert = zBaseProductUpsert.extend({
    type: z.literal(ProductType.Session),
    pricing: zProductPricing.optional(),
    /** In seconds. */
    sessionsDuration: z.number(),
    locationId: zId.optional(),
    schedulingUrl: zOptionalString(PRODUCT_URL_MAX_LENGTH),
    availability: zAvailability.optional(),
});

export type BundleProductUpsert = z.infer<typeof zBundleProductUpsert>;
const zBundleProductUpsert = zSessionProductUpsert.extend({
    type: z.literal(ProductType.Bundle),
    sessionsCount: z.number(),
});

export type DigitalPayloadUpsert = z.infer<typeof zDigitalPayloadUpsert>;
const zDigitalPayloadUpsert = z.union([
    z.object({ url: zRequiredString() }),
    z.object({ file: zFileUpsert }),
]);

export type DigitalProductUpsert = z.infer<typeof zDigitalProductUpsert>;
const zDigitalProductUpsert = zBaseProductUpsert.extend({
    type: z.literal(ProductType.Digital),
    pricing: zProductPricing.optional(),
    payload: zDigitalPayloadUpsert,
});

export type LeadProductUpsert = z.infer<typeof zLeadProductUpsert>;
const zLeadProductUpsert = zBaseProductUpsert.extend({
    type: z.literal(ProductType.Lead),
    pricing: z.undefined(),
    payload: zDigitalPayloadUpsert,
});

export type MembershipProductUpsert = z.infer<typeof zMembershipProductUpsert>;
const zMembershipProductUpsert = zBaseProductUpsert.extend({
    type: z.literal(ProductType.Membership),
    pricing: zProductPricing.optional(),
    // FIXME
});

export type LinkProductUpsert = z.infer<typeof zLinkProductUpsert>;
const zLinkProductUpsert = zBaseProductUpsert.extend({
    type: z.literal(ProductType.Link),
    pricing: z.undefined(),
    url: zRequiredString(PRODUCT_URL_MAX_LENGTH),
    displayType: z.nativeEnum(ProductDisplayType),
    isHideUrl: z.boolean(),
    isHideButton: z.boolean(),
});

export type ReferralProductUpsert = z.infer<typeof zReferralProductUpsert>;
const zReferralProductUpsert = zBaseProductUpsert.extend({
    type: z.literal(ProductType.Referral),
    pricing: z.undefined(),
    displayType: z.nativeEnum(ProductDisplayType),
    isHideButton: z.boolean(),
});

export type CustomProductUpsert = z.infer<typeof zCustomProductUpsert>;
const zCustomProductUpsert = zBaseProductUpsert.extend({
    type: z.literal(ProductType.Custom),
    pricing: zProductPricing.optional(),
});

export type ProductUpsert = z.infer<typeof zProductUpsert>;
const zProductUpsert = z.discriminatedUnion('type', [
    zSessionProductUpsert,
    zBundleProductUpsert,
    zDigitalProductUpsert,
    zLeadProductUpsert,
    zMembershipProductUpsert,
    zLinkProductUpsert,
    zReferralProductUpsert,
    zCustomProductUpsert,
]);

/** Like PUT. */
export type FullProductUpsert = z.infer<typeof zFullProductUpsert>;
export const zFullProductUpsert = z.object({
    product: zProductUpsert,
    landing: zPageUpsert.optional(),
});

export type ProductOrderEdit = z.infer<typeof zProductOrderEdit>;
export const zProductOrderEdit = z.map(
    /** Id of the product. */
    zId,
    /** New index of the product. */
    z.number(),
);
