import { ArrowRightIconScaling, ButtonIcon, CircleDollarIcon, Copy1Icon, Link5Icon, SackDollarIcon, SectionIcon, UserCheckIcon } from ':components/icons/basic';
import { Accordion, Button, Skeleton } from ':components/shadcn';
import { Table } from ':frontend/components/common/Table';
import { createTranslatedSuccessAlert } from ':frontend/components/notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { routesFE } from ':utils/routes';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { trpc } from ':frontend/context/TrpcProvider';
import clsx from 'clsx';
import { DateTimeDisplay } from ':components/custom/DateTimeDisplay';
import { ReferralStatusBadge } from ':frontend/components/referral/ReferralStatusBadge';
import { useReferralLink } from ':frontend/hooks/useReferralLink';
import { ProductType } from ':utils/entity/product';
import { Link } from 'react-router-dom';
import { toMoney, type Money } from ':utils/money';
import { MoneyDisplay } from ':components/custom';
import { StripeConnectionState, type TeamReferrals } from ':utils/entity/team';
import { STRIPE_DASHBOARD_LINK } from '../settings/PaymentsSettings';
import { NoPaymentMethodBanner } from ':frontend/components/integrations/NoPaymentMethodBanner';
import { useMaster } from ':frontend/context/UserProvider';

const CONTACT_PAYOUTS_LINK = 'mailto:info@flowlance.com';

export default function Referral() {
    const { t } = useTranslation('pages', { keyPrefix: 'referral' });
    const { addAlert } = useNotifications();
    const referralLink = useReferralLink();
    const { team } = useMaster();

    const teamReferrals = trpc.team.getTeamReferrals.useQuery().data;

    function copyReferralLink() {
        if (referralLink) {
            navigator.clipboard.writeText(referralLink);
            addAlert(createTranslatedSuccessAlert('common:copied-to-clipboard'));
        }
    }

    return (<>
        {team.isReferralAutomatic && team.stripeConnectionState !== StripeConnectionState.connected && (
            <NoPaymentMethodBanner text={
                team.stripeConnectionState === StripeConnectionState.verificationRequired
                    ? t('no-stripe-verify')
                    : t('no-stripe-connect')
            } />
        )}

        <div className='space-y-20'>

            <div className='max-w-[1200px] mx-auto space-y-12 px-4 pt-20'>
                <div className='space-y-8'>
                    <div className='space-y-6'>
                        <p className='uppercase text-primary text-sm font-semibold'>{t('pre-title')}</p>

                        <h1 className='leading-9 text-3xl sm:text-6xl font-semibold text-black whitespace-pre-line'>{t('page-title', { referralPercent: team.referralPercent })}</h1>

                        <p>
                            <Trans t={t} i18nKey='sub-title' components={{ highlight: (<span className='text-black' />) }} />
                        </p>
                    </div>

                    <div className='flex max-sm:flex-col items-center gap-4'>
                        <div className='flex items-center gap-2 bg-white rounded-full pl-4 p-2'>
                            <div>{referralLink}</div>

                            <Button variant='secondary' onClick={copyReferralLink}>
                                <Copy1Icon/> {t('copy-link-button')}
                            </Button>
                        </div>

                        <div className='font-semibold uppercase'>{t('or')}</div>

                        <div>
                            <Link to={routesFE.products.new.resolve({ type: ProductType.Referral })}>
                                <Button variant='primary'>
                                    <SectionIcon/> {t('add-to-profile-button')}
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className='space-y-8 md:space-y-0 md:flex md:items-center md:justify-between md:gap-4'>
                    <div className='flex-1 max-w-[280px] max-md:mx-auto space-y-4'>
                        <Link5Icon className='text-primary [&>path]:stroke-2' size='lg' />

                        <div className='text-black font-semibold text-lg'><span className='text-primary'>1.</span> {t('steps.1.title')}</div>

                        <p className='leading-5'>{t('steps.1.text')}</p>
                    </div>

                    <div className='w-[60px] text-center max-md:hidden'>
                        <ArrowRightIconScaling className='text-primary' size='lg' />
                    </div>

                    <div className='flex-1 max-w-[280px] max-md:mx-auto space-y-4'>
                        <ButtonIcon className='text-primary [&>path]:stroke-2' size='lg' />

                        <div className='text-black font-semibold text-lg'><span className='text-primary'>2.</span> {t('steps.2.title')}</div>

                        <p className='leading-5'>{t('steps.2.text')}</p>
                    </div>

                    <div className='w-[60px] text-center max-md:hidden'>
                        <ArrowRightIconScaling className='text-primary' size='lg' />
                    </div>

                    <div className='flex-1 max-w-[280px] max-md:mx-auto space-y-4'>
                        <SackDollarIcon className='text-primary [&>path]:stroke-2' size='lg' />

                        <div className='text-black font-semibold text-lg'><span className='text-primary'>3.</span> {t('steps.3.title')}</div>

                        <p className='leading-5'>{t('steps.3.text')}</p>
                    </div>
                </div>
            </div>

            <div className={clsx(
                'bg-primary/5 py-16 bg-contain bg-right-bottom bg-no-repeat',
                teamReferrals?.referrals.length === 0 && 'bg-[url(/static/images/referral/referral-placeholder-bg.svg)]',
            )}>
                <div className='max-w-[1200px] mx-auto px-4'>
                    {teamReferrals ? (
                        <ReferralsLoaded teamReferrals={teamReferrals} referralLink={referralLink} copyReferralLink={copyReferralLink} />
                    ) : (
                        <Skeleton height={180} className='w-full' />
                    )}
                </div>
            </div>


            <div className='max-w-[1200px] mx-auto px-4 space-y-12 md:space-y-0 md:flex md:items-center md:gap-4'>
                <div className='space-y-6 md:w-2/5 pr-10'>
                    <h2 className='leading-9 text-2xl sm:text-4xl font-semibold text-black'>{t('earnings.title')}</h2>

                    <p className='leading-5 max-w-md'>{t('earnings.text')}</p>

                    <div>
                        <div className='inline-flex items-center gap-2 bg-white rounded-full pl-4 p-2'>
                            <div>{referralLink}</div>

                            <Button variant='secondary' onClick={copyReferralLink}>
                                <Copy1Icon/>
                            </Button>
                        </div>
                    </div>
                </div>

                <div className='md:w-3/5'>
                    <div className='fl-hide-scrollbar overflow-x-auto max-lg:-mx-4 space-y-2'>
                        <div className='min-w-[600px] max-lg:px-4'>
                            <EarningsTable />
                        </div>
                    </div>
                </div>
            </div>

            <div className='bg-primary/5 pt-16 pb-36'>
                <div className='max-w-[1200px] mx-auto px-4 space-y-6'>
                    <h2 className='tracking-wider font-semibold text-black uppercase'>{t('faqs.title')}</h2>

                    <Accordion.Root type='multiple' className=''>
                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                            <div className='space-y-4'>
                                {[ 'commision', 'for-how-long', 'receiving' ].map(faqItem => (
                                    <Accordion.Item key={faqItem} value={faqItem} className='bg-white rounded-md'>
                                        <Accordion.Trigger className='text-black font-semibold'>{t(`faqs.${faqItem}.question`)}</Accordion.Trigger>

                                        <Accordion.Content className='whitespace-pre-wrap leading-5'>
                                            <Trans t={t} i18nKey={`faqs.${faqItem}.answer`} components={{ reflink: (<Link to={routesFE.products.new.resolve({ type: ProductType.Referral })} className='underline cursor-pointer hover:no-underline hover:text-primary' />) }} />
                                        </Accordion.Content>
                                    </Accordion.Item>
                                ))}
                            </div>

                            <div className='space-y-4'>
                                {[ 'how-much', 'join', 'templates' ].map(faqItem => (
                                    <Accordion.Item key={faqItem} value={faqItem} className='bg-white rounded-md'>
                                        <Accordion.Trigger className='text-black font-semibold'>{t(`faqs.${faqItem}.question`)}</Accordion.Trigger>

                                        <Accordion.Content className='whitespace-pre-wrap leading-5'>
                                            <Trans t={t} i18nKey={`faqs.${faqItem}.answer`} components={{ reflink: (<Link to={routesFE.products.new.resolve({ type: ProductType.Referral })} className='underline cursor-pointer hover:no-underline hover:text-primary' />) }} />
                                        </Accordion.Content>
                                    </Accordion.Item>
                                ))}
                            </div>
                        </div>
                    </Accordion.Root>
                </div>
            </div>

        </div>
    </>);
}

type ReferralsLoadedProps = {
    teamReferrals: TeamReferrals;
    referralLink: string | undefined;
    copyReferralLink: () => void;
};

function ReferralsLoaded({ teamReferrals, referralLink, copyReferralLink }: ReferralsLoadedProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'referral' });
    const { team } = useMaster();

    if (teamReferrals.visits === 0 && teamReferrals.referrals.length === 0) {
        return (
            <div className='space-y-6'>
                <h2 className='leading-9 text-2xl sm:text-4xl font-semibold text-black'>{t('referrals.placeholder.title')}</h2>

                <p className='leading-5 max-w-md'>{t('referrals.placeholder.text')}</p>

                <div className='flex max-sm:flex-col items-center gap-4'>
                    <div className='flex items-center gap-2 bg-white rounded-full pl-4 p-2'>
                        <div>{referralLink}</div>

                        <Button variant='secondary' onClick={copyReferralLink}>
                            <Copy1Icon/> {t('copy-link-button')}
                        </Button>
                    </div>

                    <div className='font-semibold uppercase'>{t('or')}</div>

                    <div>
                        <Link to={routesFE.products.new.resolve({ type: ProductType.Referral })}>
                            <Button variant='primary'>
                                <SectionIcon/> {t('add-to-profile-button')}
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    const colClassName = team.isReferralAutomatic ? 'w-3/12' : 'w-4/12';

    return (
        <div>
            <h2 className='tracking-wider font-semibold text-black uppercase'>{t('referrals.title')}</h2>

            {!team.isReferralAutomatic && (
                <p className='mt-2'>{t('referrals.description-non-automatic')}</p>
            )}

            <div className='mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 text-center'>
                <div className='h-40 relative bg-white rounded-lg p-8 flex flex-col justify-center gap-4'>
                    <div className='text-black text-2xl font-semibold'>{teamReferrals.referrals.length}</div>

                    <div>{t('referrals.total-referrals')}</div>

                    <div className='absolute bottom-6 left-0 right-0 text-center text-xs text-secondary-400'>{t('referrals.total-referrals-description')}</div>
                </div>

                <div className='h-40 relative bg-white rounded-lg p-8 flex flex-col justify-center gap-4'>
                    <div className='text-black text-2xl font-semibold'>{teamReferrals.visits}</div>

                    <div>{t('referrals.total-visits')}</div>
                </div>

                <div className='h-40 relative sm:max-lg:col-span-2 bg-white rounded-lg p-8 pb-6 flex flex-col justify-center gap-4'>
                    {team.isReferralAutomatic ? (<>
                        <div className='text-black text-2xl font-semibold'>
                            <MoneyDisplay amount={teamReferrals.totalEarnings} currency='USD' />
                        </div>

                        <div>{t('referrals.total-earnings')}</div>

                        <div className='-mt-2'>
                            <a href={STRIPE_DASHBOARD_LINK} target='_blank' rel='noreferrer'>
                                <Button variant='primary'>
                                    <SackDollarIcon /> {t('referrals.manage-payouts-button')}
                                </Button>
                            </a>
                        </div>
                    </>) : (<>
                        <div>
                            <a href={CONTACT_PAYOUTS_LINK} target='_blank' rel='noreferrer'>
                                <Button variant='primary'>
                                    <SackDollarIcon /> {t('referrals.contact-payouts-button')}
                                </Button>
                            </a>
                        </div>

                        <div className='absolute bottom-6 left-0 right-0 text-center text-xs text-secondary-400'>{t('referrals.contact-payouts-description')}</div>
                    </>)}
                </div>
            </div>

            {teamReferrals.referrals.length > 0 && (
                <div className='fl-hide-scrollbar overflow-x-auto max-lg:-mx-4 mt-8'>
                    <div className='min-w-[600px] space-y-4 max-lg:px-4'>
                        <div className='w-full px-4 sm:px-8 flex uppercase font-semibold'>
                            <div className='w-4/12'>{t('referrals.table.date')}</div>
                            <div className={colClassName}>{t('referrals.table.user')}</div>
                            <div className={colClassName}>{t('referrals.table.status')}</div>
                            {team.isReferralAutomatic && (
                                <div className='w-2/12'>{t('referrals.table.revenue')}</div>
                            )}
                        </div>

                        <div className='space-y-2 w-full'>
                            {teamReferrals.referrals.map((referralItem, index) => (
                                <div key={index} className='w-full px-4 sm:px-8 py-6 flex bg-white rounded-lg items-center text-lg'>
                                    <div className='w-4/12'><DateTimeDisplay dateTime={referralItem.createdAt} /></div>
                                    <div className={colClassName}><span className='text-secondary-700'>{referralItem.teamCode}</span></div>
                                    <div className={colClassName}><ReferralStatusBadge referralItem={referralItem} /></div>
                                    {team.isReferralAutomatic && (
                                        <div className='w-2/12'><MoneyDisplay amount={referralItem.revenue} currency='USD' /></div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}


function EarningsTable() {
    const { t } = useTranslation('pages', { keyPrefix: 'referral' });
    const earningsTableData = useMemo(() => createEarningsTableData(), []);

    return (
        <Table className='bg-white'>
            <Table.Body>
                <Table.Row className='font-semibold text-black'>
                    <Table.Col>
                        <div className='flex gap-1 items-center'>
                            <CircleDollarIcon size='sm' className='text-primary' /> {t('earnings.table.earnings')}
                        </div>
                    </Table.Col>

                    {earningsUsers.map(users => (
                        <Table.Col key={users}>
                            <div className='pr-8 flex gap-1 items-center justify-end'>
                                <UserCheckIcon size='sm' className='text-primary' />{users}
                            </div>
                        </Table.Col>
                    ))}
                </Table.Row>

                {earningsTableData.map(month => (
                    <Table.Row key={month.month}>
                        <Table.Col className='text-primary font-semibold'>
                            <div className='flex gap-2 items-center'>
                                <svg className='size-4 -rotate-90' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
                                    <circle cx='8' cy='8' r='8' fill='none' className='fill-primary/5' />
                                    <circle cx='8' cy='8' r='6' fill='none' className='stroke-primary' strokeWidth='3' strokeDasharray='38' strokeDashoffset={38 * (12 - month.month) / 12}></circle>
                                </svg>

                                <span>{t('earnings.table.month')} {month.month}</span>
                            </div>
                        </Table.Col>

                        {earningsUsers.map(users => (
                            <Table.Col key={users} className='text-end'>
                                <MoneyDisplay money={month[users]} className='pr-8' />
                            </Table.Col>
                        ))}
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
}

const earningsUsers = [ 10, 20, 30, 40, 100 ] as const;
const earningMonths = [ 1, 2, 3, 6, 12 ] as const;

type EarningsTableRow = {
    month: number;
} & Record<keyof typeof earningsUsers, Money>;

function createEarningsTableData() {
    return earningMonths.map(month => {
        const output = { month } as EarningsTableRow;
        earningsUsers.forEach(users => {
            output[users] = toMoney(users * month * 800, 'USD');
        });

        return output;
    });
}
