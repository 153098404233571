import { type TaxRate, type Money, toMoney } from ':utils/money';
import { type Id } from ':utils/id';
import { CustomOrderItemFE } from './CustomOrderItem';
import { EventOrderItemFE } from './EventOrderItem';
import { ProductOrderItemFE, type SchedulerProductItem } from './ProductOrderItem';
import type { DateTime } from 'luxon';
import { type PartialBy } from ':utils/common';
import { type ExceptScheduler } from '../Team';
import type { TeamMemberRole } from ':utils/entity/team';
import type { GeneralOrderItemOutput, OrderDataOutput, OrderItemOutput, ProductOrderItemOutput, SpecificOrderItemOutput } from ':utils/entity/orderItem';

// API

export function orderItemFromServer(input: OrderItemOutput, order: OrderDataOutput): OrderItemFE {
    const { currency, createdAt } = order;
    const base = baseFromServer({ ...input, currency, createdAt });
    return specificFromServer(base, input);
}

export type MasterProductItemFromServer = GeneralOrderItemOutput & {
    productOrderItem: ProductOrderItemOutput;
};

export function masterProductItemFromServer(input: MasterProductItemFromServer): ProductOrderItemFE {
    const base = baseFromServer(input);
    return ProductOrderItemFE.fromServer(base, input.productOrderItem);
}

export type SchedulerProductItemFromServer = Omit<MasterProductItemFromServer, PriceFields>;

export function schedulerProductItemFromServer(input: SchedulerProductItemFromServer): SchedulerProductItem {
    const base = baseFromServer(input);
    return ProductOrderItemFE.fromServer(base, input.productOrderItem);
}

export interface OrderItemFE<TRole extends TeamMemberRole = typeof TeamMemberRole.master | typeof TeamMemberRole.freelancer> {
    readonly id: Id;
    readonly title: string;
    readonly quantity: number;
    readonly index: number;
    readonly createdAt: DateTime;
    readonly unitPrice: ExceptScheduler<TRole, Money>;
    readonly taxRate: ExceptScheduler<TRole, TaxRate>;
}

type PriceFields = 'unitPrice' | 'taxRate' | 'currency';

// Internal implementation

function baseFromServer(input: GeneralOrderItemOutput): OrderItemFE;
function baseFromServer(input: PartialBy<GeneralOrderItemOutput, PriceFields>): OrderItemFE<typeof TeamMemberRole.scheduler>;
function baseFromServer(input: PartialBy<GeneralOrderItemOutput, PriceFields>): OrderItemFE<TeamMemberRole> {
    const common: OrderItemFE<typeof TeamMemberRole.scheduler> = {
        id: input.id,
        title: input.title,
        quantity: input.quantity,
        index: input.index,
        createdAt: input.createdAt,
        unitPrice: undefined,
        taxRate: undefined,
    };
    if (input.unitPrice === undefined || !input.currency)
        return common;

    return {
        ...common,
        unitPrice: toMoney(input.unitPrice, input.currency),
        taxRate: input.taxRate,
    };
}

function specificFromServer(base: OrderItemFE, specific: SpecificOrderItemOutput): OrderItemFE {
    if (specific.eventOrderItem)
        return EventOrderItemFE.fromServer(base, specific.eventOrderItem);
    if (specific.productOrderItem)
        return ProductOrderItemFE.fromServer(base, specific.productOrderItem);

    return CustomOrderItemFE.fromServer(base);
}
