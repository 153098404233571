import { useEffect, useState } from 'react';
import UnauthorizedRoutes from ':frontend/router/UnauthorizedRoutes';
import Loading from ':frontend/pages/Loading';
import { routesFE } from ':utils/routes';
import { Visibility } from ':frontend/types/notifications';
import { SubscriptionEndedErrorModal } from './components/orders/SubscriptionErrorModal';
import { SubscriptionErrorType, type SubscriptionError } from './types/Subscription';
import { useLocation, useSearchParams } from 'react-router-dom';
import { DevBar } from ':frontend/components/dev/DevBar';
import { localeToLanguage } from './types/i18n';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { UserProvider } from './context/UserProvider';
import AuthorizedRoutes from './router/AuthorizedRoutes';
import { useAuth } from ':frontend/context/AuthProvider';
import { AlertDisplay } from './components/notifications/AlertDisplay';
import { NewVersionNotification, useNewVersionNotification } from './components/notifications/NewVersionNotification';
import type { TeamMemberRole } from ':utils/entity/team';
import { getI18nLocale } from ':utils/i18n';
import { Layout } from './components/Layout';
import { api } from './utils/api';
import { useActiveSubscriptions } from '@stigg/react-sdk';

const displayDevBar = import.meta.env.DEV;

export default function App() {
    const { state: authState } = useAuth();
    const { i18n } = useTranslation();
    const lang = localeToLanguage(getI18nLocale(i18n));
    const [ searchParams ] = useSearchParams();

    // Check whether a referral link was visited.
    useEffect(() => {
        const ref = searchParams.get('ref');
        if (!ref)
            return;

        // All the referral information is bound to the the trackingId cookie and trackend by the tracking service.
        api.backend.referralVisited(ref);
    }, [ searchParams ]);

    useEffect(() => {
        if (!authState.isAuthorized)
            Sentry.setUser(null);

    }, [ authState.isAuthorized ]);

    return (
        <div id='inner-root' lang={lang}>
            <AlertDisplay visibility={authState.isAuthorized ? Visibility.Authorized : Visibility.Unauthorized} />
            {authState.isAuthorized ? (
                <AppAuthorized role={authState.role} />
            ) : (<>
                {authState.isFetchingNewToken ? (
                    <Loading />
                ) : (
                    <UnauthorizedRoutes />
                )}
            </>)}
            {displayDevBar && (
                <DevBar />
            )}
        </div>
    );
}

type AppAuthorizedProps = Readonly<{
    role: TeamMemberRole;
}>;

function AppAuthorized({ role }: AppAuthorizedProps) {
    const isShowNewVersion = useNewVersionNotification();

    return (
        <UserProvider role={role}>
            <NewVersionNotification isShow={isShowNewVersion} />

            <SubscriptionEndedControl />

            <Layout>
                <AuthorizedRoutes />
            </Layout>
        </UserProvider>
    );
}

function SubscriptionEndedControl() {
    const { activeSubscriptions } = useActiveSubscriptions();
    const subscription = activeSubscriptions?.[0];

    const [ subscriptionInvalidError, setSubscriptionInvalidError ] = useState<SubscriptionError>();

    useEffect(() => {
        if (subscription?.status === 'PAYMENT_PENDING')
            setSubscriptionInvalidError({ type: SubscriptionErrorType.PlanPending });
        else if (subscription?.status === 'CANCELED')
            setSubscriptionInvalidError({ type: SubscriptionErrorType.PlanEnded });
        else
            setSubscriptionInvalidError(undefined);
    }, [ subscription ]);

    const location = useLocation();

    return (
        <SubscriptionEndedErrorModal error={location.pathname !== routesFE.settings.resolve({ key: 'subscription' }) ? subscriptionInvalidError : undefined} />
    );
}
