import { NewProductUsecase, ProductFormsPhase, type ProductFormsDispatch, type ProductFormsState } from ':frontend/components/product/useProductForms';
import { productsWithoutCheckout, type ProductType } from ':utils/entity/product';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductFormsPage, type ProductBarProps } from './ProductDetail';
import { useTranslation } from 'react-i18next';
import { Button, SpinnerButton } from ':components/shadcn';
import { ArrowLeftIcon, ArrowRightIcon, Check1Icon, Eye2Icon, SpaceshipIcon } from ':components/icons/basic';
import { routesFE } from ':utils/routes';

export function NewProduct() {
    const { type } = useParams<{ type: ProductType }>();
    if (!type)
        throw new Error('Missing product type');

    const input = useMemo(() => ({ type, usecase: NewProductUsecase.Product }), [ type ]);

    return (<>
        <ProductFormsPage
            input={input}
            // Disable the navigation in all cases because the user already did something (they selected the product type).
            blockAlways={true}
            components={components}
        />
    </>);
}

export function NewProductPage() {
    const { type } = useParams<{ type: ProductType }>();
    if (!type)
        throw new Error('Missing product type');

    const input = useMemo(() => ({ type, usecase: NewProductUsecase.Landing }), [ type ]);

    return (<>
        <ProductFormsPage
            input={input}
            // Disable the navigation in all cases because the user already did something (they selected the product type).
            blockAlways={true}
            components={components}
        />
    </>);
}

const components = {
    DesktopBar: NewProductDesktopBar,
    PhoneHeader: NewProductPhoneHeader,
    PhoneBar: NewProductPhoneBar,
};

function NewProductDesktopBar({ state, dispatch, isFetching }: ProductBarProps) {
    return (<>
        <div className='w-1/4'>
            <BackButton state={state} dispatch={dispatch} />
        </div>

        {phasesOverview(state)}

        <div className='w-1/4 text-end'>
            <NextStepButton state={state} dispatch={dispatch} isFetching={isFetching} />
        </div>
    </>);
}

function NewProductPhoneHeader({ state, dispatch, isFetching, previewOnClick }: ProductBarProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'productDetail' });

    return (<>
        <Button variant='transparent' size='small' onClick={previewOnClick} className='px-0'>
            <Eye2Icon /> {t(`preview`)}
        </Button>

        <NextStepButton state={state} dispatch={dispatch} isFetching={isFetching} />
    </>);
}

function NewProductPhoneBar({ state, dispatch }: ProductBarProps) {
    return (<>
        <BackButton state={state} dispatch={dispatch} />

        {phasesOverview(state)}
    </>);
}

function getPhases(state: ProductFormsState): ProductFormsPhase[] {
    const output = state.usecase === NewProductUsecase.Landing ? [
        ProductFormsPhase.LandingDetails,
    ] : [
        ProductFormsPhase.ThumbnailDetails,
    ];

    if (!productsWithoutCheckout.includes(state.type))
        output.push(ProductFormsPhase.Checkout);

    return output;
}

type BackButtonProps = Readonly<{
    state: ProductFormsState;
    dispatch: ProductFormsDispatch;
}>;

function BackButton({ state, dispatch }: BackButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'productDetail' });
    const phases = getPhases(state);
    // The index might be 0, so we need to check for undefined.
    const previousPhase: ProductFormsPhase | undefined = phases[phases.indexOf(state.phase) - 1];

    const navigate = useNavigate();

    function handleBack() {
        if (state.phase === ProductFormsPhase.ThumbnailDetails) {
            // In theory, the user should be able to navigate back if he didn't fill in any details, but it's not needed now.
            navigate(routesFE.products.list);
            return;
        }
        if (state.phase === ProductFormsPhase.LandingDetails) {
            navigate(routesFE.store.resolve({ key: 'overview' }));
            return;
        }

        dispatch({ type: 'phase', direction: 'back' });
    }

    return (
        <Button variant='transparent' size='small' onClick={handleBack} className='px-0'>
            <ArrowLeftIcon size='sm' />
            <span className='max-md:hidden'>{t(previousPhase ? `${previousPhase}.back-to-button` : 'back-first-button')}</span>
        </Button>
    );
}

type NextStepButtonProps = Readonly<{
    state: ProductFormsState;
    dispatch: ProductFormsDispatch;
    isFetching: boolean;
}>;

function NextStepButton({ state, dispatch, isFetching }: NextStepButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'productDetail' });
    const phases = getPhases(state);
    const isLastPhase = state.phase === phases[phases.length - 1];

    return isLastPhase ? (
    // We want separate components here because of how the spinner button measures its width.
        <SpinnerButton variant='dark' size='small' isFetching={isFetching} onClick={() => dispatch({ type: 'sync' })}>
            <SpaceshipIcon />{t('create-button')}
        </SpinnerButton>
    ) : (
        <SpinnerButton variant='dark' size='small' isFetching={isFetching} onClick={() => dispatch({ type: 'phase', direction: 'continue' })}>
            {t('continue-button')}<ArrowRightIcon />
        </SpinnerButton>
    );
}

function phasesOverview(state: ProductFormsState) {
    const phases = getPhases(state);

    if (phases.length === 1)
        return null;

    return (
        <div className='w-full flex items-center justify-center gap-2'>
            {phases.map(phase => (
                <PhaseLabel key={phase} phases={phases} phase={phase} currentPhase={state.phase} />
            ))}
        </div>
    );
}

type PhaseLabelProps = Readonly<{
    phases: ProductFormsPhase[];
    phase: ProductFormsPhase;
    currentPhase: ProductFormsPhase;
}>;

function PhaseLabel({ phases, phase, currentPhase }: PhaseLabelProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'productDetail' });

    const index = phases.indexOf(phase);
    const isChecked = index < phases.indexOf(currentPhase);
    const isCurrent = phase === currentPhase;

    return (<>
        {index > 0 && (
            <div className={clsx('w-full max-w-32', isChecked || isCurrent ? 'fl-bg-dashed-primary-x' : 'fl-bg-dashed-x')} />
        )}

        <div className={clsx('size-7 rounded-full border flex items-center justify-center shrink-0',
            isChecked || isCurrent ? 'text-primary border-primary bg-primary-50' : 'border-secondary-100 bg-white',
        )}>
            {isChecked ? (
                <Check1Icon size='xs' />
            ) : (
                <span>{index + 1}</span>
            )}
        </div>

        <span className={clsx('whitespace-nowrap', isCurrent ? 'text-primary' : 'max-lg:hidden')}>
            {t(`${phase}.phase-title`)}
        </span>
    </>);
}
