import { getEnumValues, type EmptyIntersection } from ':utils/common';
import { fileDataToServer, fileToFileData, MimeType, zMimeType, type FileData, type FileOutput, type FileUpsert } from ':utils/entity/file';
import { useCallback, useEffect, useRef, useState, type ReactNode } from 'react';
import clsx from 'clsx';
import { Images2Icon, Paperclip1Icon, Pen2Icon, Trash2Icon } from ':components/icons/basic';
import { useTranslation } from 'react-i18next';
import { routesFE } from ':utils/routes';
import { Cropper, type ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button, Modal } from ':components/shadcn';
import { useCached } from ':components/hooks';
import { cn } from ':components/shadcn/utils';
import type { IconType } from ':components/icons/common';

// Either we have the original file from server, or we have the new file to upload, or we have nothing.
// It's up to the form to handle the difference between 'no file' and 'remove file'.
export type FileInputValue = FileOutput | FileData | undefined;

export function getFileInputUrl(value: FileInputValue, previewUrl: string): string;

export function getFileInputUrl(value: FileOutput | FileData): string;

export function getFileInputUrl(value: FileInputValue, previewUrl?: string): string | undefined;

export function getFileInputUrl(value: FileInputValue, previewUrl?: string): string | undefined {
    if (!value)
        return previewUrl;

    return 'hashName' in value
        ? routesFE.files.uploads(value.hashName)
        : value.dataUrl;
}

/**
 * Like patch:
 *  - FileUpsert
 *      - With data - The file should be uploaded.
 *      - With id - The file should be kept / replaced by the new id.
 *  - null - The file should be removed.
 */
export function fileInputValueToServer(value: FileInputValue): FileUpsert | null {
    if (!value)
        return null;
    if ('id' in value)
        return { id: value.id };
    return fileDataToServer(value);
}

// TODO I am not sure whether the whole preview url is necessary ... It might be worthy for uploading large files (not images), however in that case we might need to upload them in a different way entirely ...

type ImageInputProps = FileInputProps & Readonly<{
    /** If defined, this function will be called for the raw File input (instead of the onChange method). Useful for automatically uploading files to backend. */
    onFile?: (file: File) => void;
    thumbnailClass?: string;
}>;

export function ImageInput({ id, value, onChange, onFile, thumbnailClass }: ImageInputProps) {
    // The createObjectUrl is synchronous, however it's much faster than the FileReader's readAsDataURL.
    // The reason is that the readAsDataURL reads the whole file, while the createObjectURL just creates a reference. So, we can use the first one for the preview and the second one for the upload to server.
    const [ previewUrl, setPreviewUrl ] = useState<string>();
    const urlToRevoke = useRef<string>();

    const handleChange = useCallback(async (file: File | null) => {
        if (urlToRevoke.current) {
            // The url needs to be revoked because it doesn't get garbage collected.
            URL.revokeObjectURL(urlToRevoke.current);
            urlToRevoke.current = undefined;
        }

        if (!file) {
            onChange(undefined);
            setPreviewUrl(undefined);
            return;
        }

        if (onFile) {
            onFile(file);
            return;
        }

        fileToFileData(file).then(onChange);

        const newPreviewUrl = URL.createObjectURL(file);
        urlToRevoke.current = newPreviewUrl;
        setPreviewUrl(newPreviewUrl);
    }, [ onChange, onFile ]);

    useEffect(() => {
        return () => {
            if (urlToRevoke.current) {
                // Again, manual revoke.
                URL.revokeObjectURL(urlToRevoke.current);
                urlToRevoke.current = undefined;
            }
        };
    });

    const thumbnailUrl = getFileInputUrl(value, previewUrl);

    return (
        <RawFileInput
            id={id}
            onChange={handleChange}
            accept={imageAccept}
            preview={thumbnailUrl ? <ImagePreview thumbnailUrl={thumbnailUrl} thumbnailClass={thumbnailClass} /> : undefined}
            icon={Images2Icon}
        />
    );
}

const imageAccept = {
    types: [ MimeType.ImagePNG, MimeType.ImageJPEG, MimeType.ImageSVG ].join(','),
    /** There is some more (e.g., .jfif), but nobody knows that. */
    extensions: '.png, .jpg, .jpeg, .svg',
};

function ImagePreview({ thumbnailUrl, thumbnailClass }: { thumbnailUrl: string, thumbnailClass?: string }) {
    return (<>
        <div className='w-10' />

        <div className='grow shrink-0 flex justify-center'>
            <img src={thumbnailUrl} className={cn('max-h-8 h-full object-contain shrink overflow-hidden select-none drag-none', thumbnailClass)} />
        </div>
    </>);
}

type CroppedImageInputProps = ImageInputProps & Readonly<{
    cropperOptions: CropperOptions;
}>;

export function CroppedImageInput({ id, value, onChange, thumbnailClass, cropperOptions }: CroppedImageInputProps) {
    const [ rawData, setRawData ] = useState<FileData>();

    const handleChange = useCallback(async (file: File | null) => {
        if (!file) {
            setRawData(undefined);
            onChange(undefined);
            return;
        }

        setRawData(await fileToFileData(file));
    }, [ onChange ]);

    const handleCropClose = useCallback((croppedData: FileData | undefined) => {
        if (croppedData)
            onChange(croppedData);

        setRawData(undefined);
    }, [ onChange ]);

    const thumbnailUrl = getFileInputUrl(value);

    return (<>
        <RawFileInput
            id={id}
            onChange={handleChange}
            accept={imageAccept}
            preview={thumbnailUrl ? <ImagePreview thumbnailUrl={thumbnailUrl} thumbnailClass={thumbnailClass} /> : undefined}
            icon={Images2Icon}
        />
        <CropperModal
            rawData={rawData}
            onClose={handleCropClose}
            options={cropperOptions}
        />
    </>);
}

type CropperOptions = {
    modalDescription: string;
    /** The image selection will appear rounded, however the final image won't be rounded. */
    selectionRounded?: 'lg' | 'full';
} & ({
    /** Max width of the cropped image in pixels. */
    maxWidth: number;
    /** Max height of the cropped image in pixels. */
    maxHeight: number;
} | EmptyIntersection);

type CropperModalProps = Readonly<{
    rawData: FileData | undefined;
    onClose(cropped: FileData | undefined): void;
    options: CropperOptions;
}>;

function CropperModal({ rawData, onClose, options }: CropperModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'fileInput.cropper' });
    const cropperRef = useRef<ReactCropperElement>(null);
    const cached = useCached(rawData);

    const { modalDescription, selectionRounded, ...rest } = options;

    function handleConfirm() {
        if (!cached)
            return;

        if (!cropperRef.current) {
            onClose(undefined);
            return;
        }

        const { cropper } = cropperRef.current;
        const canvasOptions = 'maxWidth' in rest ? fixCropperOptions(cropper, rest) : {};

        const dataUrl = cropper.getCroppedCanvas(canvasOptions).toDataURL(cached.type);
        onClose({ ...cached, dataUrl });
    }

    return (
        <Modal.Root open={!!rawData} onOpenChange={open => !open && onClose(undefined)}>
            <Modal.Content closeButton={t('cancel-button')}>
                <Modal.Header>
                    <Modal.Title>{t('modal-title')}</Modal.Title>
                    <Modal.Description className='mt-2'>{modalDescription}</Modal.Description>
                </Modal.Header>
                {cached && (
                    <Cropper
                        ref={cropperRef}
                        src={cached.dataUrl}
                        className={clsx('h-80',
                            selectionRounded === 'lg' && 'fl-image-cropper-rounded-lg',
                            selectionRounded === 'full' && 'fl-image-cropper-rounded-full',
                        )}
                        aspectRatio={1}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        guides={true}
                    />
                )}
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => onClose(undefined)}>
                        {(t('cancel-button'))}
                    </Button>
                    <Button onClick={handleConfirm}>
                        {t('confirm-button')}
                    </Button>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    );
}

// There is a bug in the cropper library (they call it feature ...) and this is a workaround.
// See https://github.com/fengyuanchen/cropperjs/issues/892. However, the fix didn't work! So, we had to improvise.
// Not sure how this will work with different aspect ratios, though ...
function fixCropperOptions(cropper: Cropper, { maxWidth, maxHeight }: { maxWidth: number, maxHeight: number }) {
    const outputAspectRatio = maxWidth / maxHeight;
    const { aspectRatio } = cropper.getImageData();
    return (outputAspectRatio > aspectRatio) ? { maxWidth } : { maxHeight };
}

type FileInputProps = Readonly<{
    id?: string;
    value: FileInputValue;
    onChange(value: FileInputValue): void;
}>;

export function FileInput({ id, value, onChange }: FileInputProps) {
    const handleChange = useCallback(async (file: File | null) => {
        if (!file) {
            onChange(undefined);
            return;
        }

        fileToFileData(file).then(onChange);
    }, [ onChange ]);

    const fileName = value ? ('name' in value ? value.name : value.originalName) : undefined;

    return (
        <RawFileInput
            id={id}
            onChange={handleChange}
            accept={fileAccept}
            preview={fileName ? <FilePreview fileName={fileName} /> : undefined}
            icon={Paperclip1Icon}
        />
    );
}

const fileAccept = {
    types: getEnumValues(MimeType).join(','),
    extensions: undefined,
};

function FilePreview({ fileName }: { fileName: string }) {
    return (<>
        <div className='shrink-0'>
            <Paperclip1Icon size='sm' className='text-black' />
        </div>

        <div className='grow min-w-0 break-words'>
            {fileName}
        </div>
    </>);
}

type RawFileInputProps = Readonly<{
    id?: string;
    onChange: (fileList: File | null) => void;
    accept: {
        /** Comma-separated mime types. Will be checked by the html input. */
        types: string;
        /** Will be shown in the error message. If undefined, a generic message will be used instead. */
        extensions: string | undefined;
    };
    /** If present, will the input is considered full. */
    preview: ReactNode | undefined;
    icon: IconType;
}>;

function RawFileInput({ id, onChange, accept, preview, icon }: RawFileInputProps) {
    const { t } = useTranslation('components', { keyPrefix: 'fileInput.input' });
    const inputRef = useRef<HTMLInputElement>(null);
    const [ isInvalidType, setIsInvalidType ] = useState<boolean>();

    function handleInput(fileList: FileList | null) {
        if (!fileList || fileList.length === 0)
            return;

        const file = fileList[0];
        const type = zMimeType.safeParse(file.type);
        if (type.error) {
            setIsInvalidType(true);
            return;
        }

        setIsInvalidType(false);
        onChange(file);
    }

    return (
        <div className='w-full'>
            <input
                type='file'
                id={id}
                onChange={e => {
                    const files = e.target.files;
                    handleInput(files);
                    // This is necessary to allow the same file to be uploaded again (so that the user can choose a different crop for example).
                    e.target.value = '';
                }}
                ref={inputRef}
                accept={accept.types}
                className='hidden'
                aria-hidden
                tabIndex={-1}
            />
            <div
                className={clsx(
                    'group p-3 flex items-center justify-center rounded-lg border leading-5',
                    preview ? 'gap-2 cursor-auto bg-secondary-50' : 'border-dashed',
                )}
                role='button'
                aria-label={t('dnd-div-aria')}
                tabIndex={0}
                onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === ' ')
                        inputRef.current?.click();
                }}
                onDragOver={e => {
                    e.preventDefault();
                    e.dataTransfer.dropEffect = 'copy';
                }}
                onDrop={e => {
                    e.preventDefault();
                    handleInput(e.dataTransfer.files);
                }}
                onClick={() => {
                    if (!preview)
                        inputRef.current?.click();
                }}
            >
                {preview ? (<>
                    {preview}

                    <button
                        type='button'
                        onClick={() => inputRef.current?.click()}
                        aria-label={t('edit-button-aria')}
                        className='hover:text-primary'
                    >
                        <Pen2Icon size='sm' />
                    </button>

                    <button
                        type='button'
                        onClick={() => onChange(null)}
                        aria-label={t('remove-button-aria')}
                        className='hover:text-danger-500'
                    >
                        <Trash2Icon size='sm' />
                    </button>
                </>) : (<>
                    <button
                        type='button'
                        onClick={e => {
                            e.stopPropagation();
                            inputRef.current?.click();
                        }}
                        aria-label={t('upload-button-aria')}
                        className='flex items-center gap-2 text-nowrap group-hover:text-primary'
                    >
                        {icon({ size: 'sm' })}{t('upload-button')}
                    </button>
                    {/* If you are on the phone, you very much can't use drag and drop. */}
                    <span className='ps-1 max-sm:hidden'>{t('dnd-text')}</span>
                </>)}
            </div>
            {isInvalidType && (
                <p className='mt-2 text-danger-500'>{accept.extensions ? t('filetype-not-supported-error-use-following', { extensions: accept.extensions }) : t('filetype-not-supported-error')}</p>
            )}
        </div>
    );
}
