import { DefaultFilterItemBadge, type FilterDefinition, type FilterFunction, type FilterItemBadgeProps, type FilterMenuProps } from './FilterRow';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type ClientInfoFE } from ':frontend/types/Client';
import { compareArrays } from ':frontend/utils/common';
import type { CalendarEvent } from ':frontend/types/calendar/Calendar';
import { SingleContinuousParticipantSelect } from ':frontend/components/client/ContinuousParticipantSelect';
import { getParticipantName, type Participant } from ':frontend/types/EventParticipant';
import type { Id } from ':utils/id';

const filterName = 'eventParticipant';

type FilterState = Participant[];

function RowMenuTemplate({ state, setState, allClients }: FilterMenuProps<FilterState> & { allClients: ClientInfoFE[] }) {
    const availableClients = useMemo(() => allClients.filter(client => !state.some(
        participant => 'info' in participant && participant.info.id === client.id,
    )), [ state ]);
    const [ value, setValue ] = useState<Participant>();

    const handleOnChange = useCallback((participant?: Participant) => {
        if (!participant)
            return;

        setState([ ...state, participant ]);
        setValue(undefined);
    }, [ state, setState ]);

    return (
        <SingleContinuousParticipantSelect
            // This forces the element to re-render when a new item is added. The reason is that we want to reset the filter options (so that the user can't select the same client twice). However, this is (most probably) not possible with AsyncSelect, so we have to force a re-render.
            key={state.length}
            immutableProps={{ size: 'compact' }}
            clients={availableClients}
            value={value}
            onChange={handleOnChange}
            autoFocus
            className='w-full min-w-[240px] md:max-w-[300px]'
        />
    );
}

function ItemBadge({ item, onClose }: FilterItemBadgeProps<Participant>) {
    const { t } = useTranslation('common', { keyPrefix: `filters.${filterName}` });
    const name = getParticipantName(item);

    return (
        <DefaultFilterItemBadge item={item} onClose={onClose}>
            <span className='max-w-40 truncate'>
                {t('badge-label', { name })}
            </span>
        </DefaultFilterItemBadge>
    );
}

function remove(state: FilterState, item: Participant): FilterState {
    return state.filter(participant => participant.identifier !== item.identifier);
}

function toItems(state: FilterState): Participant[] {
    return state;
}

function createFilterFunction(state: FilterState): FilterFunction<CalendarEvent> {
    if (state.length === 0)
        return () => true;

    const set = new Set<string>(state.map(participant => participant.identifier));

    return (data: CalendarEvent) => {
        if (data.resource.type === 'event') {
            return data.resource.event.guests.some(participant => set.has(participant.client.id))
                || data.resource.event.clients.some(participant => set.has(participant.client.id));
        }

        if (data.resource.type === 'google')
            return data.resource.event.guests.some(participant => set.has(participant));

        return true;
    };
}

type ClientParticipant = { info: ClientInfoFE, identifier: string };

function toServer(state: FilterState, previous: Id[] | undefined): Id[] {
    const current = state
        .filter((participant): participant is ClientParticipant => 'info' in participant)
        .map(participant => participant.info.id).sort();

    return previous && compareArrays(current, previous)
        ? previous
        : current;
}

export function createEventParticipantFilter(allClients: ClientInfoFE[]): FilterDefinition<FilterState, Participant, CalendarEvent, Id[]> {
    return {
        name: filterName,
        defaultState: [],
        RowMenu: (props: FilterMenuProps<FilterState>) => <RowMenuTemplate {...props} allClients={allClients} />,
        ItemBadge,
        remove,
        toItems,
        createFilterFunction,
        toServer,
    } as FilterDefinition<FilterState, Participant, CalendarEvent, Id[]>;
}
