import { useTranslation } from 'react-i18next';
import { useMaster } from ':frontend/context/UserProvider';
import { OnboardingJourney } from ':frontend/components/onboarding/OnboardingJourney';
import { OrderStatsDisplay } from ':frontend/components/orders/OrderStatsDisplay';
import { OnboardingVideos } from ':frontend/components/settings/InfoCard';
import { isOnboardingFinished } from ':utils/entity/user';

export default function MasterDashboard() {
    const { t } = useTranslation('pages', { keyPrefix: 'dashboard' });
    const { onboarding, appUser } = useMaster();

    return (
        <div>
            <div className='max-w-[1024px] w-full mx-auto py-8 space-y-8 md:py-12 md:space-y-12'>

                <div className='max-lg:px-4'>
                    <h1 className='text-secondary-900 text-lg lg:text-2xl font-semibold mb-3'>{t('welcome-title', { name: appUser.firstName })}</h1>
                    <p>{t('welcome-subtitle')}</p>
                </div>

                <OnboardingVideos className='max-lg:px-4 flex fl-hide-scrollbar overflow-x-auto' />

                <div className='max-lg:px-4 space-y-8 md:space-y-12'>
                    {!isOnboardingFinished(onboarding) && (
                        <OnboardingJourney />
                    )}

                    <OrderStatsDisplay />
                </div>
            </div>
        </div>
    );
}

