import { useMediaQuery } from 'react-responsive';
import theme from '../../../../packages/components/tailwind.config';
import resolveConfig from 'tailwindcss/resolveConfig';

const fullConfig = resolveConfig(theme);

export type TailwindMediaQueryProps = {
    minWidth: keyof typeof fullConfig.theme.screens;
};

export function useTailwindMediaQuery({ minWidth }: TailwindMediaQueryProps) {
    return useMediaQuery({ query: `(min-width: ${fullConfig.theme.screens[minWidth]})` });
}
