export { default as localizer } from '../localizer';

export enum NavigateDirection {
    Prev = 'prev',
    Next = 'next',
    Today = 'today',
}

// TODO move to some context?
// TODO fix - currently not working everywhere
export const showMultiDayTimes = false;

// How many slots there are in one section.
export const timeslots = 4;
// How much time (in minutes) each slot has.
export const step = 15;

export const classSelectors = {
    event: { class: 'rbc-event', selector: '.rbc-event' },
    rowBg: { class: 'rbc-row-bg', selector: '.rbc-row-bg' },
    daySlot: { class: 'rbc-day-slot', selector: '.rbc-day-slot' },
    alldayCell: { class: 'rbc-allday-cell', selector: '.rbc-allday-cell' },
    timeView: { class: 'rbc-time-view', selector: '.rbc-time-view' },
    monthRow: { class: 'rbc-month-row', selector: '.rbc-month-row' },
    monthView: { class: 'rbc-month-view', selector: '.rbc-month-view' },
    dragPreview: { class: 'rbc-drag-preview', selector: '.rbc-drag-preview' },
} as const;
