import { forwardRef, useMemo } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from ':components/shadcn/utils';
import { useTranslation } from 'react-i18next';
import { ArrowDiagonalIn1Icon, StarUpgradeIcon } from ':components/icons/basic';
import type { IconType } from ':components/icons/common';

const referralStatusBadgeVariants = cva('rounded-full px-4 py-2 whitespace-nowrap inline-flex items-center justify-center gap-2 [&_svg]:size-4 [&_svg]:shrink-0', {
    variants: {
        variant: {
            trialing: 'text-primary bg-primary/10 border border-primary/20 shadow-sm',
            active: 'text-success bg-success-100 border border-success-200 shadow-sm',
            'signed-up': 'text-warning bg-warning-100 border border-warning-200',
        },
    },
    defaultVariants: {
        variant: 'signed-up',
    },
});

export type ReferralStatusBadgeProps = {
    referralItem: {
        subscription: {
            status?: string;
            totalPrice?: number;
        };
    };
};

const ReferralStatusBadge = forwardRef<HTMLDivElement, ReferralStatusBadgeProps>(({ referralItem: { subscription: { status, totalPrice } }, ...props }, ref) => {
    const { t } = useTranslation('pages', { keyPrefix: 'referral' });

    const options: {variant: VariantProps<typeof referralStatusBadgeVariants>['variant'], Icon: IconType, label: string} = useMemo(() => {
        if (status == 'IN_TRIAL')
            return { variant: 'trialing', Icon: StarUpgradeIcon, label: t('referrals.status.trialing') };

        if (status == 'ACTIVE')
            return { variant: 'active', Icon: StarUpgradeIcon, label: t('referrals.status.active') };

        return { variant: 'signed-up', Icon: ArrowDiagonalIn1Icon, label: t('referrals.status.signed-up') };
    }, [ status, totalPrice ]);

    return (
        <div
            ref={ref}
            className={cn(referralStatusBadgeVariants({ variant: options.variant }))}
            {...props}
        >
            <options.Icon /> {options.label}
        </div>
    );
});
ReferralStatusBadge.displayName = 'ReferralStatusBadge';

export { ReferralStatusBadge };
