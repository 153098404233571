import { useTranslation } from 'react-i18next';
import { computeTax, amountFromDecimal, type CurrencyId, isUnderMinimalAmount, type Money, roundMoneyAmount, type TaxRate, toMoney, taxRateIsZero } from ':utils/money';
import { percentToFloat, toNumber } from ':utils/math';
import type { UseProductOrderState } from './useProductOrder';
import { MoneyDisplay } from ':components/custom';
import type { UseCustomOrderState } from './useCustomOrder';
import type { FormDiscount } from './DiscountInput';

type PriceSummaryDisplayProps = Readonly<{
    summary: PriceSummary;
}>;

export function PriceSummaryDisplay({ summary }: PriceSummaryDisplayProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'directSale.priceSummary' });

    if (summary.currencies.length === 0)
        return null;

    const isDiscount = summary.currencies[0].discount !== undefined;
    const isTax = summary.currencies[0].taxRate !== undefined;

    return (
        <div className='w-full flex justify-between sm:justify-end gap-2'>
            <div className='flex flex-col text-right gap-2'>
                {isDiscount && (
                    <div className='text-primary'>{t('discount-label')}</div>
                )}
                {isTax && (<>
                    <div>{t('subtotal-label')}</div>
                    <div>{t('taxRate-label')}</div>
                </>)}
                <div className='leading-6 text-2lg font-semibold'>{t('total-label')}</div>
            </div>

            {summary.currencies.map(forCurrency => (
                <div key={forCurrency.total.currency} className='min-w-[112px] flex flex-col items-end gap-2'>
                    {isDiscount && (
                        <MoneyDisplay money={forCurrency.discount!} className='text-primary' noColor />
                    )}
                    {isTax && (<>
                        <MoneyDisplay money={forCurrency.subtotal!} />
                        <MoneyDisplay money={forCurrency.taxRate!} />
                    </>)}
                    <div className='leading-6 text-2lg font-semibold'>
                        <MoneyDisplay money={forCurrency.total} />
                    </div>
                </div>
            ))}
        </div>
    );
}

type PriceSummary = {
    /** A summary for each currency. */
    currencies: PriceSummaryForCurrency[];
}

/** A summary for a single currency. All prices here have the same currency. */
type PriceSummaryForCurrency = {
    /** The total discount (of the base, i.e., without tax). Usually negative. Undefined if there is no discount. It's defined for either all currencies or none. */
    discount?: Money;
    /** The sum of all items and discount (but without tax). Undefined if all items have zero tax. It's defined for either all currencies or none. */
    subtotal?: Money;
    /** The sum of all taxes. The same behavior as for subtotal. */
    taxRate?: Money;
    /** Subtotal plus tax. */
    total: Money;
    /** True if the total amount is under the minimal allowed amount. */
    isUnderMinimalAmount: boolean;
};

// Let b_i be the base of a price of an item (i.e., without tax), t_i its total price (i.e., with tax), x_i its tax rate, and p_i its price (i.e., what's displayed to the user).
// If the tax is exclusive, then b_i = p_i.
// If it's inclusive, then b_i = p_i / (1 + x_i).
// Hover, in either way, b_i = t_i / (1 + x_i).
//
// Let's use S_* to denote the sum of a property * over all items. Then we have:
//  S_s = S_t - S_x = [sum_i b_i * (1 + x_i)] - [sum_i b_i * x_i] = sum_i b_i
// So that is subtotal, tax, and total. However, we need to apply the discount. Let d be the discount coefficient (usually positive), then we are actually looking for (1 - d) * S_s and so on.
// Lastly, we need to show the discount. It should be computed from the base, so it's -d * S_s.

export function computeCustomPriceSummary({ items, currency, discount }: UseCustomOrderState['form']): PriceSummary {
    const isOnlyZeroTax = items.every(item => taxRateIsZero(item.taxRate));
    const commonItems = items.map(item => ({ amount: amountFromDecimal(toNumber(item.unitPriceInDecimal)) * toNumber(item.quantity), taxRate: item.taxRate }));
    const currencies = [ computePriceSummaryForCurrency(commonItems, currency, isOnlyZeroTax, discount) ];

    return { currencies };
}

export function computeProductPriceSummary({ items, discount }: UseProductOrderState['form']): PriceSummary {
    const nonFreeItems = items.filter(item => item.price);
    const isOnlyZeroTax = nonFreeItems.every(pricing => taxRateIsZero(pricing.taxRate));

    const byCurrencies = new Map<CurrencyId, { amount: number, taxRate: TaxRate }[]>();
    nonFreeItems.forEach(item => {
        const currency = item.price!.currency;
        const itemsForCurrency = byCurrencies.get(currency) ?? [];
        itemsForCurrency.push({ amount: item.price!.amount, taxRate: item.taxRate });
        byCurrencies.set(currency, itemsForCurrency);
    });

    const currencies = [ ...byCurrencies.entries() ].map(([ currency, items ]) => computePriceSummaryForCurrency(items, currency, isOnlyZeroTax, discount));

    return { currencies };
}

function computePriceSummaryForCurrency(items: { amount: number, taxRate: TaxRate }[], currency: CurrencyId, isOnlyZeroTax: boolean, discount: FormDiscount | undefined): PriceSummaryForCurrency {
    const discountCoefficient = discount ? percentToFloat(toNumber(discount.amountInPercent)) : 0;
    let sumOfBase = 0;
    let sumOfTax = 0;

    items.forEach(item => {
        const { withoutTax, difference } = computeTax(item.amount, item.taxRate);
        sumOfBase += withoutTax;
        sumOfTax += difference;
    });

    const discountAmount = roundMoneyAmount(-discountCoefficient * sumOfBase);
    const subtotalAmount = roundMoneyAmount((1 - discountCoefficient) * sumOfBase);
    const taxAmount = roundMoneyAmount((1 - discountCoefficient) * sumOfTax);
    const totalAmount = isOnlyZeroTax ? subtotalAmount : roundMoneyAmount(subtotalAmount + taxAmount);
    const total = toMoney(totalAmount, currency);

    return {
        discount: discount ? toMoney(discountAmount, currency) : undefined,
        subtotal: isOnlyZeroTax ? undefined : toMoney(subtotalAmount, currency),
        taxRate: isOnlyZeroTax ? undefined : toMoney(taxAmount, currency),
        total,
        isUnderMinimalAmount: isUnderMinimalAmount(total),
    };
}
