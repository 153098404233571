import { useTranslation } from 'react-i18next';
import { Button } from ':components/shadcn';
import { useState } from 'react';
import { api } from ':frontend/utils/api';
import { getAppUserLocalization } from ':frontend/types/i18n';
import { usePostHog } from 'posthog-js/react';
import { gtmPushEvent } from ':frontend/utils/api/googleApi';
import type { RegisterInput } from './useRegisterEmail';

type GoogleOauthButtonProps = Readonly<{
    title?: string;
    variant: 'primary' | 'outline';
} & ({
    type: 'register';
    input: RegisterInput;
} | {
    type: 'login';
})>;

export function GoogleOauthButton({ title, variant, ...rest }: GoogleOauthButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: rest.type });
    const [ isFetching, setIsFetching ] = useState(false);

    const posthog = usePostHog();

    async function onClick() {
        if (rest.type === 'register')
            posthog?.capture('GoogleRegistrationClicked', rest.input);

        setIsFetching(true);

        if (rest.type === 'register')
            gtmPushEvent({ event: 'generate_lead' });

        const response = rest.type === 'register'
            ? await api.backend.googleRegistration({ ...rest.input, ...getAppUserLocalization() })
            : await api.backend.googleLogin();

        if (!response.status || !response.data.redirectUrl) {
            // Stop fetching only if there's nowhere to redirect to, so that the UI doesn't flicker.
            setIsFetching(false);
            return;
        }

        window.location.href = response.data.redirectUrl;
    }

    return (
        <GoogleButton
            title={title ?? t(`${rest.type}-google-button`)}
            onClick={onClick}
            disabled={isFetching}
            variant={variant}
        />
    );
}

type GoogleButtonProps = Readonly<{
    title: string;
    onClick?: () => void;
    disabled?: boolean;
    variant: 'primary' | 'outline';
}>;

function GoogleButton({ title, onClick, disabled, variant }: GoogleButtonProps) {
    return (
        <Button
            variant={variant}
            type='submit'
            className='w-full relative'
            onClick={onClick}
            disabled={disabled}
        >
            {title}
        </Button>
    );
}
