import { useState, type ComponentProps } from 'react';
import { Button, Modal } from ':components/shadcn';
import { type BillingPeriod, Checkout, Paywall, type Plan, CustomerPortal, useStiggContext } from '@stigg/react-sdk';
import { useTranslation } from 'react-i18next';
import { useToggle } from ':frontend/hooks';
import { LockIcon } from ':components/icons/basic';
import { FIRST_PLAN_ID, getSubscriptionPlanForFeature, PRO_PLAN_ID, type StiggFeature } from ':utils/lib/stigg';

// const TEAM_OPTION_BUTTON_LINK = 'https://app.lemcal.com/@stepancalta';

export function Subscriptions() {
    // const { t } = useTranslation('pages', { keyPrefix: 'subscription' });
    const [ selectedPlan, setSelectedPlan ]  = useState<SelectedPlan | undefined>(undefined);

    function onPlanSelect({ plan, selectedBillingPeriod }: {plan: Plan, selectedBillingPeriod: BillingPeriod}) {
        setSelectedPlan({ id: plan.id, billingPeriod: selectedBillingPeriod });
    }

    function onContactSupport() {
        window.location.href = 'mailto:info@flowlance.com';
    }

    return (
        <div className='max-w-[1200px] w-full mx-auto p-4 py-8 md:py-12'>
            <CheckoutModal isOpen={selectedPlan !== undefined} onClose={() => setSelectedPlan(undefined)} plan={selectedPlan}/>

            <CustomerPortal
                productId='product-flowlance'
                onContactSupport={onContactSupport}
                paywallComponent={
                    <Paywall
                        // textOverrides={{
                        //     highlightChip: 'Users\' Favorite',
                        // }}
                        // highlightedPlanId={FIRST_PLAN_ID}
                        onPlanSelected={onPlanSelect}
                        productId='product-flowlance'
                    />
                }
            />
        </div>
    );
}

type UpsellButtonProps = Readonly<{
    /** default: 'Upgrade' */
    text?: string;
    /** button className */
    className?: string;
    /** button size, default 'small' */
    size?: ComponentProps<typeof Button>['size'];
} & ({
    /** Which feature should be enabled by clicking on this button. */
    feature: StiggFeature;
} | {
    /** Disables UpsellModal so you can manage it yourself. */
    onClick: () => void;
})>;

export function UpsellButton({ text, className, size, ...rest }: UpsellButtonProps) {
    const feature = 'feature' in rest ? rest.feature : undefined;
    const onClick = 'feature' in rest ? undefined : rest.onClick;

    const { t } = useTranslation('pages', { keyPrefix: 'subscription.upsellModal' });
    const [ showCheckout, setShowCheckout ] = useToggle(false);

    return (<>
        {feature && (
            <CheckoutModal isOpen={showCheckout} onClose={setShowCheckout.false} plan={{ id: getSubscriptionPlanForFeature(feature) }} />
        )}

        <Button variant='secondary' size={size ?? 'small'} onClick={onClick ?? setShowCheckout.true} className={className}>
            <LockIcon />
            {text ?? t('upgrade-button')}
        </Button>
    </>);
}

type SelectedPlan = {
    id: string;
    billingPeriod?: BillingPeriod;
};

type CheckoutModalProps = {
    isOpen: boolean;
    onClose: () => void;
    plan: SelectedPlan | undefined;
};

export function CheckoutModal({ isOpen, onClose, plan }: CheckoutModalProps) {
    const { refreshData } = useStiggContext();

    async function onCheckoutCompleted(params: { success: boolean, error?: string }) {
        if (params.success) {
            await refreshData();
            onClose();
        }
    }

    return (
        <Modal.Root open={isOpen} onOpenChange={open => !open && onClose()}>
            {/* FIXME translations */}
            <Modal.Title className='sr-only'>
                Subscription modal
            </Modal.Title>

            <Modal.Content closeButton='Close' className='max-w-3xl p-4 [&>*>*]:!mx-0 [&>*>*]:!w-full [&>*>*]:!border-0'>
                <Checkout
                    planId={plan?.id || PRO_PLAN_ID}
                    preferredBillingPeriod={plan?.billingPeriod}
                    onCheckoutCompleted={onCheckoutCompleted}
                />
            </Modal.Content>
        </Modal.Root>
    );
}
