import { GoogleIntegration } from ':frontend/components/integrations/GoogleIntegration';
import { LocationsEditor } from ':frontend/components/settings/LocationsEditor';
import { Card, SpinnerButton } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { trpc } from ':frontend/context/TrpcProvider';
import { LocationDisplay } from ':frontend/components/location/LocationDisplay';
import { Pins2Icon } from ':components/icons/basic';
import { useState } from 'react';
import type { LocationOutput } from ':utils/entity/location';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedSuccessAlert } from ':frontend/components/notifications';
import { useUser } from ':frontend/context/UserProvider';
import { MetaPixelIntegration } from ':frontend/components/integrations/MetaPixelIntegration';

export function AdvancedSettings() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.advanced' });
    const { addAlert } = useNotifications();
    const locations = trpc.location.getLocations.useQuery().data;
    const changeEmailsSubscriptionMutation = trpc.team.changeEmailsSubscription.useMutation();
    const { team, setTeam } = useUser();
    const [ editingLocation, setEditingLocation ] = useState<LocationOutput | undefined>(undefined);
    const [ deletingLocation, setDeletingLocation ] = useState<LocationOutput | undefined>(undefined);

    async function unsubscribeWeeklyStats() {
        const newTeam = await changeEmailsSubscriptionMutation.mutateAsync({ weeklyStatsUnsubscribed: true });
        setTeam(newTeam);
        addAlert(createTranslatedSuccessAlert('pages:settings.advanced.weekly-stats-unsubscribed'));
    }

    async function resubscribeWeeklyStats() {
        const newTeam = await changeEmailsSubscriptionMutation.mutateAsync({ weeklyStatsUnsubscribed: false });
        setTeam(newTeam);
        addAlert(createTranslatedSuccessAlert('pages:settings.advanced.weekly-stats-resubscribed'));
    }

    return (
        <div className='p-4 py-8 md:py-12 space-y-8'>
            <h1 className='text-2xl font-semibold leading-6'>{t('page-title')}</h1>

            <div className='space-y-4 md:space-y-8'>
                <Card>
                    <div className='flex items-center justify-between'>
                        <Card.Title className='flex items-center gap-2'>
                            <Pins2Icon className='h-5 text-warning-500' />
                            {t('locations-section')}
                        </Card.Title>

                        <LocationsEditor
                            editingLocation={editingLocation}
                            setEditingLocation={setEditingLocation}
                            deletingLocation={deletingLocation}
                            setDeletingLocation={setDeletingLocation}
                        />
                    </div>

                    <Card.Divider />

                    <div className='space-y-4'>
                        {locations?.length ? locations.map(location => (
                            <div key={location.id}>
                                <LocationDisplay
                                    location={location}
                                    onEdit={() => setEditingLocation(location)}
                                    onDelete={() => setDeletingLocation(location)}
                                />
                            </div>
                        )) : (
                            <p className='text-center'>{t('locations-section-description')}</p>
                        )}
                    </div>
                </Card>

                <Card>
                    <Card.Title className='flex items-center gap-2'>
                        {t('integrations-section')}
                    </Card.Title>

                    <Card.Divider />

                    <div className='space-y-5'>
                        <GoogleIntegration />

                        <MetaPixelIntegration />
                    </div>
                </Card>

                <Card>
                    <Card.Title className='flex items-center gap-2'>
                        {t('emails-section')}
                    </Card.Title>

                    <Card.Divider />

                    <div className='flex gap-4 items-center max-sm:flex-col sm:justify-between'>
                        <div>
                            <p className='text-secondary-700 text-lg mb-1'>{t('weekly-stats-title')}</p>
                            <p>{t('weekly-stats-description')}</p>
                        </div>
                        <div className='flex flex-col shrink-0 w-40'>
                            <SpinnerButton
                                size='small'
                                isFetching={changeEmailsSubscriptionMutation.isPending}
                                onClick={team.isEmailsUnsubscribed ? resubscribeWeeklyStats : unsubscribeWeeklyStats}
                            >
                                {team.isEmailsUnsubscribed ? t('weekly-stats-resubscribe') : t('weekly-stats-unsubscribe')}
                            </SpinnerButton>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
}
