import { useCallback, useEffect, useRef, useState } from 'react';
import { Modal, Spinner } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { api } from ':frontend/utils/api';
import type { Id } from ':utils/id';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedErrorAlert } from '../notifications';
import type { InvoicingProfileOutput } from ':utils/entity/invoicing';

type InvoicingProfileExampleModalProps = Readonly<{
    profile?: InvoicingProfileOutput;
    onHide: () => void;
}>;

export function InvoicingProfileExampleModal({ profile, onHide }: InvoicingProfileExampleModalProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.invoicing-profile.exampleModal' });
    const [ pdfBlobUrl, setPdfBlobUrl ] = useState('');
    const { addAlert } = useNotifications();

    const profileCache = useRef<InvoicingProfileOutput>();

    const fetchInvoicingProfileExample = useCallback(async (id: Id, signal: AbortSignal) => {
        setPdfBlobUrl('');
        const response = await api.backend.getInvoicingProfileExample(id, signal);
        if (!response.status) {
            profileCache.current = undefined;
            addAlert(createTranslatedErrorAlert());
            return;
        }

        const blobUrl = URL.createObjectURL(response.data);
        setPdfBlobUrl(blobUrl);
    }, [ addAlert ]);

    useEffect(() => {
        if (!profile || profile === profileCache.current)
            return;

        profileCache.current = profile;

        const [ signal, abort ] = api.prepareAbort();
        fetchInvoicingProfileExample(profile.id, signal);
        return abort;
    }, [ profile, fetchInvoicingProfileExample ]);

    return (
        <Modal.Root
            open={!!profile}
            onOpenChange={open => !open && onHide()}
        >
            <Modal.Content className='md:max-w-[80vw] h-full md:max-h-[80vh] block' closeButton={t('close-button')}>
                <Modal.Header>
                    <Modal.Title>{t('title')}</Modal.Title>
                </Modal.Header>

                <div className='h-full pt-6 pb-2'>
                    {pdfBlobUrl ? (
                        <iframe
                            className='w-full h-full rounded'
                            src={pdfBlobUrl}
                            title='Invoice Preview'
                        />
                    ) : (
                        <div className='flex justify-center items-center h-full'>
                            <Spinner className='mx-auto' />
                        </div>
                    )}
                </div>
            </Modal.Content>
        </Modal.Root>
    );
}
