import { type FilterDefinition, type FilterFunction, type FilterMenuProps } from './FilterRow';
import { Button, Form, DropdownMenu } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { compareArrays } from ':frontend/utils/common';
import { type ClientInfoFE } from ':frontend/types/Client';
import { CLIENT_STATE_VALUES, ClientStateBadge } from ':frontend/components/client/ClientStateBadge';
import { ClientState } from ':utils/entity/client';
import { CircleCopy1Icon } from ':components/icons/basic';

const filterName = 'clientState';

type FilterState = {
    [key in ClientState]: boolean;
};

function RowMenu({ state, setState }: FilterMenuProps<FilterState>) {
    const { t } = useTranslation('common', { keyPrefix: 'filters' });
    const selectedStates = CLIENT_STATE_VALUES.filter(clientState => state[clientState]);

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <Button variant='outline' className={selectedStates.length > 0 ? 'border-primary text-primary hover:border-primary' : undefined}>
                    {selectedStates.length > 0 ? (
                        selectedStates.map(clientState => <ClientStateBadge key={clientState} state={clientState} />)
                            .flatMap((e, index) => [ e, <span key={`or_${index}`}>/</span> ])
                            .slice(0, -1)
                    ) : (
                        <div className='flex items-center gap-2'>
                            <CircleCopy1Icon size='md' /> {t(`${filterName}.menu-button`)}
                        </div>
                    )}
                </Button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Content side='bottom' align='start'>
                <div className='p-1.5'>
                    {CLIENT_STATE_VALUES.map(clientState => (
                        <div
                            key={clientState}
                            className='flex items-center gap-4 px-4 py-3 rounded-sm cursor-pointer hover:bg-primary-100'
                            onClick={() => setState({ ...state, [clientState]: !state[clientState] })}
                        >
                            <Form.Checkbox checked={state[clientState]} />
                            <ClientStateBadge state={clientState} />
                        </div>
                    ))}
                </div>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
}

function createFilterFunction(state: FilterState): FilterFunction<ClientInfoFE> {
    const selectedStates = CLIENT_STATE_VALUES.filter(clientState => state[clientState]);
    if (selectedStates.length === 0 || selectedStates.length === CLIENT_STATE_VALUES.length)
        return () => true;

    return (data: ClientInfoFE) => !!state[data.state];
}

function toServer(state: FilterState, previous: ClientState[] | undefined): ClientState[] | undefined {
    const current = CLIENT_STATE_VALUES.filter(clientState => state[clientState]);
    if (current.length === 0 || current.length === CLIENT_STATE_VALUES.length)
        return;

    return previous && compareArrays(current, previous)
        ? previous
        : current;
}

export const ClientStateFilter: FilterDefinition<FilterState, undefined, ClientInfoFE, ClientState[]> = {
    name: filterName,
    defaultState: {
        [ClientState.lead]: false,
        [ClientState.active]: false,
        [ClientState.inactive]: false,
    },
    RowMenu,
    createFilterFunction,
    toServer,
};
