import { useCallback, type Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal, SpinnerButton } from ':components/shadcn';
import { type FECustomItemInit, determineSendNotification } from ':frontend/types/orders/Order';
import { PaymentMethodSelect } from ':frontend/components/forms/PaymentMethodSelect';
import { MoneyDisplay } from ':components/custom';
import { ClientIconRow } from ':frontend/components/client/ClientIconLink';
import { getClientOrContact } from ':frontend/types/EventParticipant';
import { PercentDisplay } from ':components/custom';
import { type CheckoutOutput } from './CheckoutModalInner';
import { CheckoutPhase, type CheckoutState, type CheckoutAction, type CacheItem, CheckoutType } from './useCheckout';
import { floatToPercent } from ':utils/math';
import { PaymentMethod } from ':utils/entity/invoicing';
import { ProductCheckoutItemDisplay } from ':components/store/product/ProductCard';
import { TranslatedErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { useMaster } from ':frontend/context/UserProvider';
import clsx from 'clsx';

type CheckoutOverviewProps = Readonly<{
    output: Dispatch<CheckoutOutput>;
    state: CheckoutState;
    dispatch: Dispatch<CheckoutAction>;
    isFetching: boolean;
    createOrder: () => void;
}>;

export function CheckoutOverview({ output, state, dispatch, isFetching, createOrder }: CheckoutOverviewProps) {
    const { t } = useTranslation('components', { keyPrefix: 'checkout.overview' });
    const { cache, input, overview: { form, formErrors } } = state;
    const discount = 'discount' in input ? input.discount : undefined;
    const determinedNotification = determineSendNotification(form.paymentMethod);

    const setPaymentMethod = useCallback((value: PaymentMethod) => {
        dispatch({ type: 'overview', field: 'paymentMethod', value });
    }, [ dispatch ]);

    return (
        <Modal.InnerContent className='max-w-[680px] md:w-[680px] border-0' closeButton={null}>
            <Modal.Header>
                <Modal.Title className='text-body text-2xl font-semibold'>{t('title')}</Modal.Title>
            </Modal.Header>

            {state.input.type !== CheckoutType.Event && (<>
                {cache.items.map(displayCachedItem)}
                {discount && (
                    <div className='p-4 rounded-xl border shadow-[0px_5px_15px_0px_rgba(0,0,0,0.05)] flex flex-col gap-2'>
                        <div className='flex justify-between leading-6'>
                            <h3 className='font-semibold'>{discount.title}</h3>
                            <span className='text-lg'><PercentDisplay amount={floatToPercent(discount.rate)}/></span>
                        </div>

                        <div className='flex justify-end gap-2'>
                            {cache.overviewForCurrencies.map((forCurrency, index) => (
                                <span key={forCurrency.totalDiscount.currency}>
                                    <MoneyDisplay money={forCurrency.totalDiscount} className='text-primary' noColor />
                                    {index < cache.overviewForCurrencies.length - 1 && ','}
                                </span>
                            ))}
                        </div>
                    </div>
                )}
            </>)}

            {cache.paymentOptions && (<>
                <Form.Label className='mt-4 mb-0'>{t('payment-method-label')}</Form.Label>
                <div>
                    <PaymentMethodSelect
                        value={form.paymentMethod}
                        onChange={setPaymentMethod}
                        currencies={cache.currencies}
                        options={cache.paymentOptions}
                    />
                    <TranslatedErrorMessage translationId={formErrors?.paymentMethod} />
                </div>
            </>)}

            <h3 className='mt-4 leading-5'>{t('clients-title', { count: cache.clients.length })}</h3>
            <InvoicesTable state={state} dispatch={dispatch} />

            <div className='mt-4 max-sm:space-y-2 sm:flex sm:items-center sm:gap-4 sm:justify-between'>
                <div className='h-9 flex items-center gap-2'>
                    <Form.Switch
                        label={t('notify-label', { count: cache.clients.length })}
                        checked={form.sendNotification}
                        disabled={determinedNotification !== undefined}
                        onCheckedChange={value => dispatch({ type: 'overview', field: 'sendNotification', value })}
                    />
                </div>

                {form.sendNotification && (
                    <Button
                        variant='outline'
                        size='small'
                        onClick={() => dispatch({ type: 'phase', phase: CheckoutPhase.EmailPreview })}
                    >
                        {t('preview-email-button')}
                    </Button>
                )}
            </div>

            <Modal.Footer className='mt-4 grid grid-cols-1 sm:grid-cols-2'>
                <Button onClick={() => output({ type: 'back' })} variant='outline'>
                    {t('back-button')}
                </Button>
                <SpinnerButton
                    variant='primary'
                    onClick={createOrder}
                    isFetching={isFetching}
                >
                    {t('finish-button')}
                </SpinnerButton>
            </Modal.Footer>
        </Modal.InnerContent>
    );
}

function displayCachedItem(item: CacheItem, index: number) {
    if ('product' in item) {
        return (
            <ProductCheckoutItemDisplay key={index} product={item.product} />
        );
    }

    // TODO this shouldn't happen
    if ('event' in item)
        return null;

    return (
        <CustomItemDisplay key={index} item={item} />
    );
}

function CustomItemDisplay({ item }: Readonly<{ item: FECustomItemInit }>) {
    return (
        <div className='p-4 rounded-xl border shadow-[0px_5px_15px_0px_rgba(0,0,0,0.05)] flex items-center gap-4'>
            <h3 className='font-semibold truncate'>{item.title}</h3>
            <div className='grow' />
            <span>{item.quantity}×</span>
            <span><MoneyDisplay money={item.unitPrice} /></span>
        </div>
    );
}

type InvoicesTableProps = Readonly<{
    state: CheckoutState;
    dispatch: Dispatch<CheckoutAction>;
}>;

function InvoicesTable({ state, dispatch }: InvoicesTableProps) {
    const { t } = useTranslation('components', { keyPrefix: 'checkout.overview' });
    const { teamSettings } = useMaster();
    const showInvoicing = teamSettings.isInvoicingModuleOn && state.overview.form.paymentMethod !== PaymentMethod.noInvoice;
    const { cache: { invoicesForClients, overviewForCurrencies } } = state;
    const isAllSingle = invoicesForClients.every(forClient => forClient.invoices.length === 1);

    return (
        <div className='rounded-xl border shadow-[0px_5px_15px_0px_rgba(0,0,0,0.05)] overflow-hidden grid grid-cols-1'>
            {invoicesForClients.map(forClient => (
                <div key={forClient.client.identifier.toString()} className='p-4 md:p-6 max-md:space-y-2 md:flex md:items-center md:gap-4 md:justify-between border-b'>
                    <div className='overflow-hidden'>
                        <ClientIconRow client={getClientOrContact(forClient.client)} className='md:grow truncate' />
                    </div>

                    {showInvoicing && (
                        <div className='space-y-1'>
                            {forClient.invoices.map(invoice => (
                                <Button
                                    key={invoice.price.currency}
                                    variant='outline'
                                    size={isAllSingle ? 'small' : 'tiny'}
                                    onClick={() => dispatch({ type: 'invoicePreview', invoice })}
                                >
                                    {t('preview-invoice-button')}
                                </Button>
                            ))}
                        </div>
                    )}

                    <div className='flex flex-col items-end gap-1'>
                        {forClient.invoices.map(invoice => (
                            <MoneyDisplay key={invoice.price.currency} money={invoice.price} className={clsx('leading-5', isAllSingle ? 'py-2' : 'py-1')} />
                        ))}
                    </div>
                </div>
            ))}

            <div className='p-4 md:p-6 flex justify-end gap-2 text-lg font-semibold'>
                <span className='mr-12'>{t('total-price-label')}</span>
                {overviewForCurrencies.map((forCurrency, index) => (
                    <span key={forCurrency.totalPrice.currency}>
                        <MoneyDisplay money={forCurrency.totalPrice} />
                        {index < overviewForCurrencies.length - 1 && ','}
                    </span>
                ))}
            </div>
        </div>
    );
}
