import { type Money, type TaxRate, amountFromDecimal } from ':utils/money';
import { type Id } from ':utils/id';
import { toNumber } from ':utils/math';
import type { OrderItemFE } from './OrderItem';
import { type DateTime } from 'luxon';
import type { OrderItemEdit } from ':utils/entity/orderItem';

export class CustomOrderItemFE implements OrderItemFE {
    private constructor(
        readonly id: Id,
        readonly title: string,
        readonly quantity: number,
        readonly unitPrice: Money,
        readonly taxRate: TaxRate,
        readonly index: number,
        readonly createdAt: DateTime,
    ) {}

    static fromServer(base: OrderItemFE): CustomOrderItemFE {
        return new CustomOrderItemFE(
            base.id,
            base.title,
            base.quantity,
            base.unitPrice,
            base.taxRate,
            base.index,
            base.createdAt,
        );
    }
}

export function customItemUpdateToServer(input: EditableCustomItem): OrderItemEdit {
    return {
        id: input.id,
        title: input.title,
        quantity: toNumber(input.quantity),
        unitPrice: amountFromDecimal(toNumber(input.unitPriceInDecimal)),
        taxRate: input.taxRate,
        isDeleted: input.isDeleted ? true : undefined,
    };
}

export type EditableCustomItem = {
    readonly id: string;
    title: string;
    quantity: number | '';
    unitPriceInDecimal: number | '' | '-';
    taxRate: TaxRate;
    isDeleted: boolean;
};

export function isCustomItemEqual(form: EditableCustomItem, item: OrderItemFE): boolean {
    return !form.isDeleted
        && form.title === item.title
        && form.quantity === item.quantity
        && amountFromDecimal(toNumber(form.unitPriceInDecimal)) === item.unitPrice.amount
        && form.taxRate === item.taxRate;
}
