import { useId, useRef, useState } from 'react';
import { Button, Form, Modal, SpinnerButton } from ':components/shadcn';
import type { StoreOutput } from ':utils/entity/store';
import { useTranslation } from 'react-i18next';
import { routeToDisplayString, routesStore } from ':utils/routes';
import { createSlug, STORE_SLUG_MIN_LENGTH } from ':utils/common';
import { trpc } from ':frontend/context/TrpcProvider';
import useNotifications from ':frontend/context/NotificationProvider';
import { createErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { isNewTypedError } from ':frontend/types/TypedError';
import { ErrorMessage } from '../forms/ErrorMessage';
import { env } from ':env';
import { useEntitlement } from ':frontend/lib/stigg';
import { StiggFeature } from ':utils/lib/stigg';
import { UpsellButton } from ':frontend/pages/settings/Subscriptions';

type StoreSlugProps = Readonly<{
    store: StoreOutput;
}>;

export function StoreSlug({ store }: StoreSlugProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general' });
    const isEnabled = useEntitlement(StiggFeature.CustomStoreUrl);

    const [ showConfirmSlugChangeModal, setShowConfirmSlugChangeModal ] = useState(false);
    const [ isFetching, setIsFetching ] = useState(false);
    const [ slugInit, setSlugInit ] = useState(store.slug);
    const alreadyUsed = useRef<string[]>([]);

    const displayUrlPrefix = routeToDisplayString(routesStore.store.absoluteResolve({ domain: env.VITE_DEFAULT_STORE_DOMAIN, slug: '' }));
    const finalSlug = createSlug(slugInit);
    const isDirty = finalSlug !== store.slug;

    function validate(slug: string) {
        if (slug.length < STORE_SLUG_MIN_LENGTH)
            return 'too-short';

        if (alreadyUsed.current.includes(slug))
            return 'already-used';

        return undefined;
    }

    const error = validate(finalSlug);

    const updateStoreMutation = trpc.store.updateStore.useMutation();
    const utils = trpc.useUtils();
    const { addAlert } = useNotifications();

    function save() {
        if (error)
            return;

        setIsFetching(true);
        updateStoreMutation.mutate({ slug: finalSlug }, {
            onError: error => {
                if (isNewTypedError(error.data, 'store.slugAlreadyExists'))
                    alreadyUsed.current.push(finalSlug);
                else
                    addAlert(createErrorAlert(error.data));
            },
            onSuccess: data => {
                utils.store.getStore.setData(undefined, data);
                alreadyUsed.current = [];
                addAlert(createTranslatedSuccessAlert('pages:settings.general.storeSlug-success'));
            },
            onSettled: () => {
                setIsFetching(false);
            },
        });
    }

    const descriptionId = useId();

    return (<>
        <ConfirmSlugChangeModal
            showModal={showConfirmSlugChangeModal}
            finalSlug={finalSlug}
            onClose={() => setShowConfirmSlugChangeModal(false)}
            onConfirm={() => {
                setShowConfirmSlugChangeModal(false);
                save();
            }}
        />

        <div className='flex flex-col gap-4'>
            <div>
                <Form.Input
                    isError={!!error}
                    aria-describedby={descriptionId}
                    value={slugInit}
                    onChange={e => setSlugInit(e.target.value)}
                    disabled={!isEnabled}
                />
                {error === 'already-used' && (
                    <ErrorMessage message={t('storeSlug-already-used-error')} />
                )}
                {error === 'too-short' && (
                    <ErrorMessage message={t('storeSlug-too-short-error')} />
                )}
            </div>

            <Form.Description id={descriptionId}>
                <span className='text-secondary-400'>{displayUrlPrefix}</span>
                <span className='text-secondary-500 break-words'>{finalSlug}</span>
            </Form.Description>

            {isEnabled ? (
                <SpinnerButton size='small' className='w-full' disabled={!!error || !isDirty} isFetching={isFetching} onClick={() => setShowConfirmSlugChangeModal(true)}>
                    {t('save-button')}
                </SpinnerButton>
            ) : (
                <div className='flex justify-end'>
                    <UpsellButton feature={StiggFeature.CustomStoreUrl} />
                </div>
            )}
        </div>
    </>);
}

type ConfirmSlugChangeModalProps = Readonly<{
    showModal: boolean;
    finalSlug: string;
    onClose(): void;
    onConfirm(): void;
}>;

function ConfirmSlugChangeModal({ showModal, finalSlug, onClose, onConfirm }: ConfirmSlugChangeModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'confirmSlugChangeModal' });

    return (
        <Modal.Root
            open={showModal}
            onOpenChange={open => !open && onClose()}
        >
            <Modal.Content className='gap-6' closeButton={t('cancel-button')}>
                <Modal.Header className='space-y-4'>
                    <Modal.Title className='text-3xl leading-9 text-center font-semibold text-secondary-900'>
                        {t('title')}
                    </Modal.Title>
                </Modal.Header>

                <div className='space-y-2 text-center'>
                    <div className='leading-5 max-w-sm mx-auto text-center'>
                        {t('store-link-change')}
                    </div>
                    <div className='text-primary break-words'>
                        {routesStore.store.absoluteResolve({ domain: env.VITE_DEFAULT_STORE_DOMAIN, slug: finalSlug })}
                    </div>
                </div>

                <div className='leading-5 max-w-sm mx-auto text-center'>
                    {t('referral-link-change')}
                </div>

                <div className='space-y-2 text-center -mt-3'>
                    <div className='font-semibold text-black text-lg'>
                        {t('new-referral-link')}
                    </div>

                    <div className='text-primary break-words'>
                        {`https://www.flowlance.com/?ref=${finalSlug}`}
                    </div>
                </div>

                <Modal.Footer className='grid grid-cols-2 gap-4'>
                    <Button variant='secondary' onClick={onClose}>
                        {t('cancel-button')}
                    </Button>

                    <Button variant='primary' onClick={onConfirm}>
                        {t('confirm-button')}
                    </Button>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    );
}
