import { useState, type Dispatch, type SetStateAction, type ReactNode, useMemo, useRef, type FC } from 'react';
import { XmarkIcon } from ':components/icons/basic';
import { cn } from ':components/shadcn/utils';
import type { EmptyIntersection } from ':utils/common';

export type FilterMenuProps<TState> = Readonly<{
    state: TState;
    setState: (newState: TState) => void;
}>;

export type FilterDefinition<TState, TItem, TData, TServer> = {
    name: string;
    defaultState: TState;
    RowMenu: FC<FilterMenuProps<TState>>;
    toServer: (state: TState, previous: TServer | undefined) => TServer | undefined;
    createFilterFunction: (state: TState) => FilterFunction<TData>;
    alignRight?: boolean;
} & (TItem extends undefined ? EmptyIntersection : {
    ItemBadge: FC<FilterItemBadgeProps<TItem>>;
    remove: (state: TState, item: TItem) => TState;
    toItems: (state: TState) => TItem[];
});

export type FilterFunction<TData> = (data: TData) => boolean;

type FilterRowProps = Readonly<{
    control: UseFiltersControl;
    className?: string;
}>;

export function FilterRow({ control, className }: FilterRowProps) {
    const { filters, state: totalState, setState: setTotalState } = control;

    const left = renderFilters(filters.filter(filter => !filter.alignRight), totalState, setTotalState);
    const right = renderFilters(filters.filter(filter => filter.alignRight), totalState, setTotalState);

    const withItems = useMemo(() => filters.filter(filter => 'toItems' in filter), [ filters ]);
    const items = withItems
        .flatMap(filter => filter.toItems(totalState[filter.name]).map((item, index) => (
            <filter.ItemBadge
                key={index}
                item={item}
                onClose={item => setTotalState({ ...totalState, [filter.name]: filter.remove(totalState[filter.name], item) })}
            />
        )));

    if (right.length === 0) {
        return (
            <div className={cn('w-full flex flex-wrap items-center gap-2', className)}>
                {left}

                {items}
            </div>
        );
    }

    return (
        <div className={cn('w-full', className)}>
            <div className='w-full flex flex-wrap items-center gap-2'>
                {left}

                <div className='grow max-md:hidden' />

                <div className='max-md:-order-1 max-md:w-full flex gap-2'>
                    {right}
                </div>
            </div>

            {items.length > 0 && (
                <div className='w-full flex flex-wrap items-center gap-2 mt-2'>
                    {items}
                </div>
            )}
        </div>
    );
}

function renderFilters(filters: Filter[], totalState: TotalState<unknown>, setTotalState: Dispatch<SetStateAction<TotalState<unknown>>>): ReactNode[] {
    return filters.map(filter => (
        <filter.RowMenu
            key={filter.name}
            state={totalState[filter.name]}
            setState={newState => setTotalState({ ...totalState, [filter.name]: newState })}
        />
    ));
}

export type FilterItemBadgeProps<TItem> = Readonly<{
    item: TItem;
    onClose: (item: TItem) => void;
}>;

type DefaultFilterItemBadgeProps<TItem> = FilterItemBadgeProps<TItem> & {
    children: ReactNode;
};

export function DefaultFilterItemBadge<TItem>({ item, onClose, children }: DefaultFilterItemBadgeProps<TItem>) {
    return (
        <div className='flex items-center gap-2 border border-secondary-100 rounded-full px-3 py-2 cursor-pointer' onClick={() => onClose(item)}>
            {children}
            <XmarkIcon size='xs' />
        </div>
    );
}

// Hook

type Filter = FilterDefinition<any, any, any, any>;

export type UseFiltersControl = {
    state: TotalState<unknown>;
    setState: Dispatch<SetStateAction<TotalState<unknown>>>;
    filters: Filter[];
    toServer: (name: string) => unknown;
};

export function useFilters(filters: Filter[], inputFilters?: UseFiltersControl): UseFiltersControl {
    const [ state, setState ] = useState<TotalState<unknown>>(() => computeInitialState(filters, inputFilters?.state));

    const previousToServer = useRef(new Map<string, unknown>());

    const control = useMemo(() => {
        // The double-caching is needed because sometimes, the state does change, however the toServer output does not.
        // For example, when filtering by clients, there are no clients first (both available and selected). When we fetch the clients, the state will change, so we compute this agaein. The toServer output will be [] (again), however, [] !== [].
        // A solution might be to only include data that directly changes toServer output in the state. So we would need to store the available clients somewhere else.
        // Maybe the FilterMenu component could be dynamically passed to the FilterRow?
        const toServerCached = new Map(filters.map(filter => {
            const previous = previousToServer.current.get(filter.name);
            const current = filter.toServer(state[filter.name], previous);
            return [ filter.name, current ];
        }));
        previousToServer.current = toServerCached;

        const toServer = (name: string) => toServerCached.get(name);

        return {
            state,
            setState,
            filters,
            toServer,
        };
    }, [ state, filters ]);

    return control;
}

type TotalState<TState> = {
    [key: string]: TState;
};

function computeInitialState<TState>(filters: Filter[], input?: TotalState<TState>): TotalState<TState> {
    const output: TotalState<TState> = {};
    filters.forEach(filter => {
        output[filter.name] = input ? input[filter.name] : filter.defaultState;
    });
    return output;
}

// The apply function isn't on the default useFilters hook, because then it wouldn't be possible to combine filters with different TData types.
export function useFiltersApply<TData>({ state, filters }: UseFiltersControl, name?: string): FilterFunction<TData> {
    return useMemo(() => createFilterFunction({ state, filters }, name), [ state, filters, name ]);
}

export function createFilterFunction<TData>({ state, filters }: Pick<UseFiltersControl, 'state' | 'filters'>, name?: string): FilterFunction<TData> {
    if (!name) {
        const filterFunctions = filters.map(filter => filter.createFilterFunction(state[filter.name]));
        return (data: TData) => filterFunctions.every(filterFunction => filterFunction(data));
    }

    const filterFunction = filters.find(f => f.name === name)?.createFilterFunction(state[name]);
    if (!filterFunction)
        throw new Error(`Filter '${name}' not found`);

    return filterFunction;
}
