import { Form, SpinnerButton } from ':components/shadcn';
import { useForm, type FieldValues, type Control, type FieldErrors, type UseFormSetValue, type UseFormWatch } from 'react-hook-form';
import { ControlledLocaleSelect } from ':frontend/components/forms';
import { useTranslation } from 'react-i18next';
import { useMaster } from ':frontend/context/UserProvider';
import { RHFErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { last, optionalStringToPut } from ':frontend/utils/common';
import { useTransform } from ':frontend/utils/forms';
import { EMAIL_SOFT_VALIDATION_PATTERN, canonizeEmail } from ':utils/forms';
import { MAX_TITLE_LENGTH } from ':frontend/types/Event';
import { InfoTooltip } from '../common/InfoTooltip';
import type { LocaleCode } from ':utils/i18n';
import { addressToEditable, addressToUpsert } from ':frontend/types/Address';
import type { InvoicingProfileInit, InvoicingProfileOutput } from ':utils/entity/invoicing';

export type NewInvoicingProfileFormData = {
    title: string;
    locale: LocaleCode<'invoice'>;
    dueDays: number;
    invoicingEmail: string;
    hideEmailOnInvoice: boolean;
};

type FormDataType = NewInvoicingProfileFormData;

function inputToForm(profile: InvoicingProfileOutput): FormDataType {
    return {
        title: '',
        locale: profile.locale,
        dueDays: profile.dueDays,
        invoicingEmail: profile.email ?? '',
        hideEmailOnInvoice: false,
    };
}

function formToOutput(data: FormDataType, profile: InvoicingProfileOutput): InvoicingProfileInit {
    return {
        general: {
            address: addressToUpsert(addressToEditable(profile.address)),
            cin: optionalStringToPut(profile.cin),
            tin: optionalStringToPut(profile.tin),
            legalName: optionalStringToPut(profile.legalName),
            title: data.title,
            locale: data.locale,
            dueDays: data.dueDays,
            email: optionalStringToPut(canonizeEmail(data.invoicingEmail)),
            hideEmailOnInvoice: data.hideEmailOnInvoice,
        },
        advanced: {
            isLogoCustom: false,
            condensedInvoice: profile.condensedInvoice,
            header: optionalStringToPut(profile.header),
            footer: optionalStringToPut(profile.footer),
            customKey1: optionalStringToPut(profile.customKey1),
            customValue1: optionalStringToPut(profile.customValue1),
            customKey2: optionalStringToPut(profile.customKey2),
            customValue2: optionalStringToPut(profile.customValue2),
        },
        logo: undefined,
    };
}

type NewInvoicingProfileFormProps = Readonly<{
    onSubmit: (output: InvoicingProfileInit) => void;
    isFetching: boolean;
}>;

export function NewInvoicingProfileForm({ onSubmit, isFetching }: NewInvoicingProfileFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'newInvoicingProfileForm' });

    const { profiles } = useMaster();
    const lastProfile = last(profiles);
    const { handleSubmit, control, formState: { errors }, setValue, watch } = useForm<FormDataType>({
        defaultValues: inputToForm(lastProfile),
    });

    function onValid(data: FormDataType) {
        onSubmit(formToOutput(data, lastProfile));
    }

    return (
        <Form.Root onSubmit={handleSubmit(onValid)}>
            <NewInvoicingProfileFormInner
                control={control}
                errors={errors}
                setValue={setValue}
                watch={watch}
                autoFocus
            />
            <div className='flex justify-end mt-8'>
                <SpinnerButton
                    type='submit'
                    isFetching={isFetching}
                >
                    {t('create-button')}
                </SpinnerButton>
            </div>
        </Form.Root>
    );
}


type NewInvoicingProfileFormInnerProps<T extends FieldValues> = Readonly<{
    control: Control<T>;
    errors: FieldErrors<T>;
    setValue: UseFormSetValue<T>;
    watch: UseFormWatch<T>;
    autoFocus?: boolean;
    hideName?: boolean;
}>;

export function NewInvoicingProfileFormInner<T extends FormDataType>(props: NewInvoicingProfileFormInnerProps<T>) {
    const { t } = useTranslation('components', { keyPrefix: 'newInvoicingProfileForm' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });

    const control = props.control as unknown as Control<FormDataType>;
    const errors = props.errors as FieldErrors<FormDataType>;
    const setValue = props.setValue as unknown as UseFormSetValue<FormDataType>;
    const watch = props.watch as unknown as UseFormWatch<FormDataType>;

    const { registerPositiveInteger } = useTransform(control.register, setValue);

    const hideEmailOnInvoice = watch('hideEmailOnInvoice');

    return (
        <div className='space-y-4'>
            {!props.hideName && (
                <div>
                    <Form.Input
                        label={<>{t('title-label')}<InfoTooltip text={t('title-tooltip')} className='ml-2' /></>}
                        {...control.register('title', { required: tf('title-required'), maxLength: { value: MAX_TITLE_LENGTH, message: tf('text-too-long', { count: MAX_TITLE_LENGTH }) } })}
                        autoFocus={props.autoFocus ?? false}
                    />
                    <RHFErrorMessage errors={errors} name='title' />
                </div>
            )}

            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <div>
                    <Form.Input
                        label={t('dueDays-label')}
                        {...registerPositiveInteger('dueDays', { required: tf('dueDays-required') })}
                    />
                    <RHFErrorMessage errors={errors} name='dueDays' />
                </div>

                <div>
                    <Form.Input
                        label={t('invoicing-email-label')}
                        {...control.register('invoicingEmail', {
                            pattern: {
                                value: EMAIL_SOFT_VALIDATION_PATTERN,
                                message: tf('email-invalid'),
                            },
                        })}
                        placeholder={t('invoicing-email-placeholder')}
                        type='email'
                        disabled={hideEmailOnInvoice}
                    />
                    <RHFErrorMessage errors={errors} name='invoicingEmail' />
                </div>
            </div>

            <div className='flex items-center gap-2'>
                <Form.Switch
                    label={t('hide-email-on-invoice-label')}
                    {...control.register('hideEmailOnInvoice')}
                />
            </div>

            <div>
                <Form.Label>{t('locale-label')}</Form.Label>
                <ControlledLocaleSelect
                    control={control}
                    name='locale'
                    type='invoice'
                />
            </div>
        </div>
    );
}
