import { useCallback, useEffect, useRef, useState, type MutableRefObject, type RefObject } from 'react';
import clsx from 'clsx';
import { getBoundsForNode, isEvent, Selection } from './Selection';
import { isSelected } from './utils/selection';
import { DayColumnCell } from './columnCell';
import { TimeGridEvent } from './TimeGridEvent';
import { DateTime } from 'luxon';
import { EventContainerWrapper } from './dragAndDrop/EventContainerWrapper';
import { getSlotMetrics, type SlotMetrics } from './utils/timeSlots';
import { getStyledEventsOverlap } from './utils/overlap';
import { classSelectors, localizer, step, timeslots } from './utils/common';
import { getNow } from './localizer';
import type { CalendarEvent } from ':frontend/types/calendar/Calendar';
import type { SelectionAction, SelectionBox, SlotInfo } from '.';
import type { Selected } from ':frontend/pages/CalendarDetail';

export type Selectable = boolean | 'ignoreEvents';
// TODO See below.
type BoundsForNodeReturn = { top: number, bottom: number, left: number, right: number };

type DayColumnProps = Readonly<{
    events: CalendarEvent[];
    selected?: Selected;
    selectable?: Selectable;
    min: DateTime;
    max: DateTime;
    isNow: boolean;
    date: DateTime;
    dayIndex: number;
    onSelectEvent: (event: CalendarEvent) => void;
    onSelectSlot: (slot: SlotInfo) => void;
}>;

export function DayColumn({ events, selected, selectable, min, max, isNow, date, onSelectEvent, onSelectSlot }: DayColumnProps) {
    const containerRef = useRef<HTMLDivElement>(null);

    // There must be a better way to do this ...
    const slotMetricsRef = useRef<SlotMetrics>();
    slotMetricsRef.current = slotMetricsRef.current?.update({ min, max }) ?? getSlotMetrics({ min, max });
    const slotMetrics = slotMetricsRef.current;

    const selecting = useSelectable(selectable, onSelectSlot, containerRef, slotMetrics);

    const [ timeIndicatorPosition, setTimeIndicatorPosition ] = useState<number>();

    useEffect(() => {
        if (isNow)
            setTimeIndicatorPositionUpdateInterval(false);

        return () => clearTimeIndicatorInterval();
    }, []);

    // TODO The time indicator logic needs to be fixed.
    // useEffect(() => {
    //     clearTimeIndicatorInterval();
    //     if (isNow) {
    //         const tail = localizer.eq(prevProps.date, date, 'minute') && prevState.timeIndicatorPosition === timeIndicatorPosition;

    //         setTimeIndicatorPositionUpdateInterval(tail);
    //     }
    // }, [ isNow ]);

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps.isNow !== isNow) {
    //         this.clearTimeIndicatorInterval();

    //         if (isNow) {
    //             const tail = localizer.eq(prevProps.date, date, 'minutes') && prevState.timeIndicatorPosition === timeIndicatorPosition;

    //             setTimeIndicatorPositionUpdateInterval(tail);
    //         }
    //     }
    //     else if (
    //         isNow && (localizer.neq(prevProps.min, min, 'minutes') || localizer.neq(prevProps.max, max, 'minutes'))
    //     ) {
    //         positionTimeIndicator();
    //     }
    // }

    const intervalTriggeredRef = useRef(false);
    const timeIndicatorTimeoutRef = useRef<number>();

    /**
     * If true, the `positionTimeIndicator` will be deferred. Otherwise, it will be called upon setting interval.
     */
    function setTimeIndicatorPositionUpdateInterval(tail: boolean) {
        if (!intervalTriggeredRef.current && !tail)
            positionTimeIndicator();

        timeIndicatorTimeoutRef.current = window.setTimeout(() => {
            intervalTriggeredRef.current = true;
            positionTimeIndicator();
            setTimeIndicatorPositionUpdateInterval(false);
        }, 60000);
    }

    function clearTimeIndicatorInterval() {
        intervalTriggeredRef.current = false;
        if (timeIndicatorTimeoutRef.current !== undefined)
            window.clearTimeout(timeIndicatorTimeoutRef.current);
    }

    function positionTimeIndicator() {
        const current = getNow();

        if (current >= min && current <= max) {
            const top: number = slotMetrics.getCurrentTimePosition(current);
            intervalTriggeredRef.current = true;
            setTimeIndicatorPosition(top);
        }
        else {
            clearTimeIndicatorInterval();
        }
    }

    function renderEvents() {
        const minimumStartDifference = Math.ceil((step * timeslots) / 2);
        const styledEvents = getStyledEventsOverlap(events, minimumStartDifference, slotMetrics);

        return styledEvents.map(({ event, style }, index) => (
            <TimeGridEvent
                key={'evt_' + index}
                style={style}
                event={event}
                selected={isSelected(event, selected)}
                onClick={() => onSelectEvent(event)}
            />
        ));
    }

    const isWeekend = localizer.isWeekend(date);

    return (
        <div
            ref={containerRef}
            // TODO the move cursor should be over the whole calendar
            className={clsx(classSelectors.daySlot.class, 'relative w-full select-none flex flex-col min-h-full', selecting && 'cursor-move')}
        >
            <div className={clsx('grid grid-flow-row auto-rows-[64px] divide-y *:border-secondary-100', isWeekend && 'fl-bg-dashed')}>
                {slotMetrics.groups.map((group: DateTime[], index: number) => (
                    <DayColumnCell
                        key={index}
                        group={group}
                    />
                ))}
            </div>
            <EventContainerWrapper slotMetrics={slotMetrics}>
                <div className='absolute top-0 bottom-0 left-0 right-3'>
                    {renderEvents()}
                </div>
            </EventContainerWrapper>

            {selecting && (
                <div className='absolute left-0 right-3 z-10 px-2 py-1 rounded-sm bg-secondary-400/50 shadow' style={{ top: selecting.top, height: selecting.height }}>
                    <span>{localizer.fullFormat({ start: selecting.startDate, end: selecting.endDate }, 'selectRangeFormat')}</span>
                </div>
            )}
            {isNow && intervalTriggeredRef.current && (
                <div
                    className='rbc-current-time-indicator'
                    style={{ top: `${timeIndicatorPosition}%` }}
                />
            )}
        </div>
    );
}

export type SlotMetricsRangeReturn = {
    top: number;
    height: number;
    start: number;
    startDate: DateTime;
    end: number;
    endDate: DateTime;
};

type SelectState = {
    top: string;
    height: string;
    start: number;
    startDate: DateTime;
    end: number;
    endDate: DateTime;
};

function useSelectable(
    selectable: Selectable | undefined,
    onSelectSlot: (slot: SlotInfo) => void,
    containerRef: RefObject<HTMLElement>,
    slotMetrics: SlotMetrics,
): SelectState | undefined {
    const handleSelectSlot = useCallback(({ startDate, endDate, action }: { startDate: DateTime, endDate: DateTime, action: SelectionAction }) => {
        let current = startDate;
        const slots = [];

        while (localizer.lte(current, endDate)) {
            slots.push(current);
            // using Date ensures not to create an endless loop the day DST begins
            current = DateTime.fromMillis(+current + step * 60 * 1000);
        }

        onSelectSlot({ slots, start: startDate, end: endDate, action });
    }, [ onSelectSlot ]);

    // TODO This is highly not ideal
    const [ selecting, setSelecting ] = useState<SelectState>();
    const selectorRef = useRef<Selection | undefined>(undefined);

    useEffect(() => {
        if (selectable)
            selectorRef.current = createSelector(selectable, containerRef, slotMetrics, handleSelectSlot, setSelecting);
        else
            destroySelector(selectorRef);

        return () => destroySelector(selectorRef);
    }, [ selectable, slotMetrics, handleSelectSlot, containerRef ]);

    return selecting;
}

function createSelector(
    selectable: Selectable,
    containerRef: RefObject<HTMLElement>,
    slotMetrics: SlotMetrics,
    handleSelectSlot: (input: { startDate: DateTime, endDate: DateTime, action: SelectionAction }) => void,
    setSelecting: (selecting: SelectState | undefined) => void,
): Selection {
    const node = containerRef.current!;
    const selector = new Selection(() => node);

    let selectingStateCache: SelectState | undefined;

    function maybeSelect(box: SelectionBox) {
        const newState = selectionState(box);
        const prevState = selectingStateCache;

        if (
            !prevState ||
            +prevState.start !== +newState.start ||
            +prevState.end !== +newState.end
        ) {
            setSelecting(newState);
            selectingStateCache = newState;
        }
    }

    let initialSlotCache: DateTime | undefined;

    function selectionState(point: SelectionBox): SelectState {
        // TODO
        let currentSlot: DateTime = slotMetrics.closestSlotFromPoint(point, getBoundsForNode(node) as BoundsForNodeReturn);

        if (!selectingStateCache)
            initialSlotCache = currentSlot;

        let initialSlot = initialSlotCache!;
        if (localizer.lte(initialSlot, currentSlot))
            currentSlot = slotMetrics.nextSlot(currentSlot);
        else if (localizer.gt(initialSlot, currentSlot))
            initialSlot = slotMetrics.nextSlot(initialSlot);

        const selectRange: SlotMetricsRangeReturn = slotMetrics.getRange(
            localizer.min(initialSlot, currentSlot),
            localizer.max(initialSlot, currentSlot),
        );

        return {
            ...selectRange,
            top: `${selectRange.top}%`,
            height: `${selectRange.height}%`,
        };
    }

    const selectorClicksHandler = (box: SelectionBox, action: SelectionAction) => {
        if (!isEvent(containerRef.current, box)) {
            const { startDate, endDate } = selectionState(box);
            handleSelectSlot({ startDate, endDate, action });
        }
        setSelecting(undefined);
        selectingStateCache = undefined;
    };

    selector.on('selecting', maybeSelect);
    selector.on('selectStart', maybeSelect);

    selector.on('beforeSelect', (box: SelectionBox) => {
        if (selectable !== 'ignoreEvents')
            return;

        return !isEvent(containerRef.current, box);
    });

    selector.on('click', (box: SelectionBox) => selectorClicksHandler(box, 'click'));

    selector.on('doubleClick', (box: SelectionBox) => selectorClicksHandler(box, 'doubleClick'));

    selector.on('select', () => {
        if (selectingStateCache) {
            handleSelectSlot({ ...selectingStateCache, action: 'select' });
            setSelecting(undefined);
            selectingStateCache = undefined;
        }
    });

    selector.on('reset', () => {
        if (selectingStateCache) {
            setSelecting(undefined);
            selectingStateCache = undefined;
        }
    });

    return selector;
}

function destroySelector(selectorRef: MutableRefObject<Selection | undefined>) {
    if (!selectorRef.current)
        return;

    selectorRef.current.teardown();
    selectorRef.current = undefined;
}
