import { PlatformType } from ':utils/entity/location';
import { UserPinIcon, Video1Icon } from './basic';
import type { IconType } from './common';

const icons = {
    [PlatformType.googleMeet]: 'google-meet',
    [PlatformType.microsoftTeams]: 'ms-teams',
    [PlatformType.zoom]: 'zoom',
    [PlatformType.physical]: 'google-maps',
    [PlatformType.custom]: 'custom',
} as const;

type LocationIconProps = {
    type: PlatformType;
    size?: number | string;
    className?: string;
}

export function OnlineLocationIcon({ type, size, className }: LocationIconProps) {
    return (
        <img src={`/static/location-icons/${icons[type]}.svg`} style={{ width: size, height: size }} className={className} />
    );
}

export function simpleLocationIcon({ platform }: { platform: string }): IconType {
    return platform === PlatformType.physical ? UserPinIcon : Video1Icon;
}
