import type { TranslationOptions } from ':frontend/components/notifications/TranslatedAlertMessage';
import { MoneyDisplay } from ':components/custom';
import { type Id } from ':utils/id';

const errorTypes = [
    'order.minimalCharge',
    'order.maximalCharge',
    'login.unauthorized',
] as const;

type ErrorType = typeof errorTypes[number];
const allErrors = new Set<ErrorType>(errorTypes);

type TranslationData = { i18nKey: string, options?: TranslationOptions };
type ErrorTranslationFunction<TErrorType extends ErrorType = ErrorType> = (error: TypedError<TErrorType>) => TranslationData;

function defaultTranslation(type: ErrorType): TranslationData {
    return { i18nKey: type };
}

type ErrorData<TErrorType extends ErrorType> =
    TErrorType extends 'order.minimalCharge' ? {
        provided: number;
        required: number;
        currency: Id;
    } :
    TErrorType extends 'order.maximalCharge' ? {
        provided: number;
        required: number;
        currency: Id;
    }
    : Record<string, unknown>;

export type TypedError<TErrorType extends ErrorType = ErrorType> = {
    type: TErrorType;
} & ErrorData<TErrorType>;

const customTranslatedErrors = new Map<ErrorType, ErrorTranslationFunction>([
    [
        'order.minimalCharge',
        e => {
            const error = e as TypedError<'order.minimalCharge'>;
            return {
                i18nKey: 'order.minimalCharge',
                options: {
                    components: {
                        money: <MoneyDisplay amount={error.required} currency={error.currency} />,
                    },
                },
            };
        },
    ],
    [
        'order.maximalCharge',
        e => {
            const error = e as TypedError<'order.maximalCharge'>;
            return {
                i18nKey: 'order.maximalCharge',
                options: {
                    components: {
                        money: <MoneyDisplay amount={error.required} currency={error.currency} />,
                    },
                },
            };
        },
    ],
]);

/** @deprecated */
export function isTypedError(value: unknown): value is TypedError {
    if (!value || typeof value !== 'object')
        return false;

    if (!('type' in value) || typeof value.type !== 'string')
        return false;

    return (allErrors as Set<string>).has(value.type);
}

// TODO Replace the old errors by some new solution shared between the backend and the frontend.
type NewTypedError<TErrorType extends string> = {
    type: TErrorType;
};

export function isNewTypedError<TErrorType extends string>(value: unknown, type: TErrorType): value is NewTypedError<TErrorType> {
    if (!value || typeof value !== 'object')
        return false;

    if (!('type' in value) || typeof value.type !== 'string')
        return false;

    return value.type === type;
}

export function getErrorTranslation(error: TypedError): TranslationData {
    const customTranslation = customTranslatedErrors.get(error.type);

    return customTranslation?.(error) ?? defaultTranslation(error.type);
}
