import { Button, Card, DropdownMenu, Form } from ':components/shadcn';
import { ProductDisplay } from ':components/store/product/ProductCard';
import { trpc } from ':frontend/context/TrpcProvider';
import { NewProductModal } from ':frontend/components/product/CreateProductCard';
import { Link, useNavigate } from 'react-router-dom';
import { BoxPlusIcon, DotsVerticalIcon, Duplicate1Icon, Eye1Icon, Link5Icon, Pen2Icon, Trash2Icon } from ':components/icons/basic';
import { routesFE, routesStore } from ':utils/routes';
import type { Id } from ':utils/id';
import { ProductType, type ProductOutput } from ':utils/entity/product';
import { useToggle } from ':frontend/hooks';
import { DeleteProductModal } from './ProductDetail';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { cn } from ':components/shadcn/utils';
import { Query } from ':frontend/utils/common';
import { createErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { useReferralLink } from ':frontend/hooks/useReferralLink';
import { NewProductUsecase } from ':frontend/components/product/useProductForms';
import { useEntitlement } from ':frontend/lib/stigg';
import { FIRST_PLAN_ID, StiggFeature } from ':utils/lib/stigg';
import { CheckoutModal } from '../settings/Subscriptions';

export function Products() {
    const { t } = useTranslation('pages', { keyPrefix: 'products' });

    const [ filter, setFilter ] = useState<'all' | 'public' | 'private'>('all');
    const [ query, setQuery ] = useState('');
    const [ showNewProductModal, setShowNewProductModal ] = useToggle(false);
    const [ showUpsellModal, setShowUpsellModal ] = useToggle(false);


    const referralLink = useReferralLink();
    const productQuery = trpc.product.getProducts.useQuery();

    const maxProducts = useEntitlement(StiggFeature.MaxProducts);

    const onNewProduct = useMemo(() => {
        if (!maxProducts)
            return setShowNewProductModal.true;

        if (!productQuery.data)
            return setShowNewProductModal.true;

        if (productQuery.data.length >= maxProducts)
            return setShowUpsellModal.true;

        return setShowNewProductModal.true;

    }, [ productQuery.data, maxProducts, setShowNewProductModal.true, setShowUpsellModal.true ]);

    const stats = useMemo(() => {
        if (!productQuery.data)
            return;

        return {
            all: undefined,
            public: productQuery.data.filter(product => product.isPublic).length,
            private: productQuery.data.filter(product => !product.isPublic).length,
        };
    }, [ productQuery.data ]);

    const filteredProducts = useMemo(() => {
        if (!productQuery.data)
            return;

        let byVisibility: ProductOutput[];

        switch (filter) {
        case 'all':
            byVisibility = productQuery.data;
            break;
        case 'public':
            byVisibility = productQuery.data.filter(product => product.isPublic);
            break;
        case 'private':
            byVisibility = productQuery.data.filter(product => !product.isPublic);
            break;
        }

        if (query.length === 0)
            return byVisibility;

        const filterQuery = new Query(...query.split(' '));

        const byQuery = byVisibility.filter(product => {
            const productQuery = new Query(...product.title.split(' '));
            return productQuery.match(filterQuery);
        });

        return byQuery;
    }, [ productQuery.data, filter, query ]);

    return (
        <div className='px-4 py-8'>
            <CheckoutModal isOpen={showUpsellModal} onClose={setShowUpsellModal.false} plan={{ id: FIRST_PLAN_ID }}/>
            <NewProductModal open={showNewProductModal} onClose={setShowNewProductModal.false} usecase={NewProductUsecase.Product} />

            <div className='max-w-[900px] mx-auto w-full'>
                <h1 className='text-2xl font-semibold text-secondary-700 leading-normal mb-5'>{t('title')}</h1>
                <div className='space-y-4 sm:space-y-0 sm:flex sm:justify-between sm:items-center mb-4'>
                    <div className='flex gap-2'>
                        {([ 'all', 'public', 'private' ] as const).map(type => (
                            <Button
                                key={type}
                                variant={type === filter ? 'white' : 'white'}
                                size='small'
                                onClick={() => setFilter(type)}
                                className={cn('text-lg', type !== filter && 'bg-transparent')}
                            >
                                {t(`filter.${type}`)}
                                {stats && stats[type] !== undefined && (
                                    <span className='flex justify-center items-center size-5 rounded-full bg-secondary-100 text-sm leading-normal -mr-1'>{stats[type]}</span>
                                )}
                            </Button>
                        ))}
                    </div>

                    <Form.Input
                        size='compact'
                        placeholder={t('query-placeholder')}
                        value={query}
                        onChange={e => setQuery(e.target.value)}
                        className='sm:w-2/5'
                    />
                </div>

                <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3'>
                    <Button asChild variant='transparent' size='exact' className='rounded-2xl cursor-pointer' onClick={onNewProduct}>
                        <Card className='bg-transparent h-[250px] flex flex-col items-start justify-center gap-2 text-primary w-full'>
                            <BoxPlusIcon size={40} className='!size-10' />
                            <span className='text-lg font-semibold'>{t('create-product')}</span>
                        </Card>
                    </Button>

                    {filteredProducts?.map(product => (
                        <ProductDisplay
                            key={product.id}
                            product={product}
                            referralLink={referralLink}
                            topRight={<ProductDropdown product={product} />}
                            bottomRight={<ProductVisibility isPublic={product.isPublic} />}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

function ProductDropdown({ product }: Readonly<{ product: ProductOutput }>) {
    const { t } = useTranslation('pages', { keyPrefix: 'products.dropdown' });
    const navigate = useNavigate();
    const { addAlert } = useNotifications();

    const [ isDeleteModalOpen, setIsDeleteModalOpen ] = useToggle(false);

    const store = trpc.store.getStore.useQuery();
    const referralLink = useReferralLink();
    const duplicateProductMutation = trpc.product.duplicateProduct.useMutation({
        onSuccess: response => {
            navigate(routesFE.products.detail.resolve({ id: response.id }));
        },
        onError: error => {
            addAlert(createErrorAlert(error.data));
        },
    });

    function copyLink() {
        if (product.type === ProductType.Link) {
            navigator.clipboard.writeText(product.url);
            addAlert(createTranslatedSuccessAlert('common:copied-to-clipboard'));
            return;
        }

        if (product.type === ProductType.Referral) {
            if (!referralLink)
                return;
            navigator.clipboard.writeText(referralLink);
            addAlert(createTranslatedSuccessAlert('common:copied-to-clipboard'));
            return;
        }

        if (!store.data)
            return;

        const url = routesStore.product.absoluteResolve(store.data, product.slug);
        navigator.clipboard.writeText(url);
        addAlert(createTranslatedSuccessAlert('common:copied-to-clipboard'));
    }

    // it's important to have this stopPropagation otherwise clicking in the modal or dropdown menu would cause the whole ProductDisplay to be clicked
    return (
        <div onClick={e => e.stopPropagation()}>
            <DeleteProductModal
                product={isDeleteModalOpen ? product : undefined}
                onCancel={setIsDeleteModalOpen.false}
                onDeleted={setIsDeleteModalOpen.false}
            />
            <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                    <Button variant='transparent' className='hover:bg-secondary-50 size-9 p-0'>
                        <DotsVerticalIcon size={14} />
                    </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content side='bottom' align='end' className='w-56'>
                    <Link to={routesFE.products.detail.resolve({ id: product.id })}>
                        <DropdownMenu.Item>
                            <Pen2Icon size={16} />
                            <span>{t('edit')}</span>
                        </DropdownMenu.Item>
                    </Link>
                    <DropdownMenu.Item onClick={copyLink}>
                        <Link5Icon size={16} />
                        <span>{t('copy-link')}</span>
                    </DropdownMenu.Item>
                    <DropdownMenu.Item onClick={() => duplicateProductMutation.mutate({ id: product.id })}>
                        <Duplicate1Icon size={16} />
                        <span>{t('duplicate')}</span>
                    </DropdownMenu.Item>
                    <DropdownMenu.Item onClick={setIsDeleteModalOpen.true} className='text-danger focus:text-danger'>
                        <Trash2Icon size={16} />
                        <span>{t('delete')}</span>
                    </DropdownMenu.Item>
                </DropdownMenu.Content>
            </DropdownMenu.Root>
        </div>
    );
}

function ProductVisibility({ isPublic }: { isPublic: boolean }) {
    const { t } = useTranslation('pages', { keyPrefix: 'products.visibility' });

    if (isPublic) {
        return (
            <div className='flex items-center gap-1'>
                <Eye1Icon size={14} />
                <span>{t('public')}</span>
            </div>
        );
    }
    else {
        return (
            <span>{t('private')}</span>
        );
    }
}

export function ProductEditLink({ id }: Readonly<{id: Id}>) {
    return (
        <Button variant='outline' size='tiny' className='shrink-0 px-0 w-[22px] h-[22px]'>
            <Link to={routesFE.products.detail.resolve({ id })}>
                <Pen2Icon size={12} className='!w-3 !h-3'/>
            </Link>
        </Button>
    );
}
