import { memo } from 'react';
import { DateTime, type DateTimeFormatOptions } from 'luxon';
import clsx from 'clsx';

type DateTimeFormatType = { date?: true } | { time?: true };

type DateTimeDisplayProps = Readonly<{
    dateTime: DateTime;
    className?: string;
} & DateTimeFormatType>;

export function DateTimeDisplay({ dateTime, className, ...type }: DateTimeDisplayProps) {
    return (
        <span className={clsx('tabular-nums whitespace-nowrap', className)}>{dateTime.toLocaleString(getFormat(type))}</span>
    );
}

function getFormat(type: DateTimeFormatType): DateTimeFormatOptions {
    if ('date' in type)
        return DateTime.DATE_MED;

    if ('time' in type)
        return DateTime.TIME_SIMPLE;

    return DateTime.DATETIME_MED;
}

type DayLongFormatProps = Readonly<{
    day: DateTime;
}>;

export const DayLongFormat = memo(DayLongFormatRaw);

function DayLongFormatRaw({ day }: DayLongFormatProps) {
    return (
        <span className='tabular-nums whitespace-nowrap'>
            {day.toLocaleString(DateTime.DATE_HUGE)}
        </span>
    );
}
