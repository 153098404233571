import { cloneElement, Component, createRef, type ContextType, type ReactElement, type RefObject } from 'react';
import { ActionDirection, dndContext } from './dndContext';
import { scrollParent, scrollTop } from 'dom-helpers';
import qsa from 'dom-helpers/cjs/querySelectorAll';
import { getBoundsForNode, getEventNodeFromPoint, Selection } from '../Selection';
import { TimeGridEvent } from '../TimeGridEvent';
import { eventTimes, pointInColumn } from './common';
import { classSelectors, localizer } from '../utils/common';
import type { CalendarEvent } from ':frontend/types/calendar/Calendar';
import type { SlotMetricsRangeReturn } from '../DayColumn';
import type { SelectionBox } from '..';
import clsx from 'clsx';
import type { GetRangeReturn, SlotMetrics } from '../utils/timeSlots';

type EventContainerWrapperProps = Readonly<{
    slotMetrics: SlotMetrics;
    children: ReactElement;
}>;

type EventContainerWrapperState = {
    event?: CalendarEvent | null;
    top?: number | null;
    height?: number | null;
};

export class EventContainerWrapper extends Component<EventContainerWrapperProps, EventContainerWrapperState> {
    static contextType = dndContext;
    declare context: ContextType<typeof dndContext>;

    ref: RefObject<HTMLDivElement>;

    constructor(props: EventContainerWrapperProps) {
        super(props);
        this.state = {};
        this.ref = createRef();
    }

    componentDidMount() {
        this._selectable();
    }

    componentWillUnmount() {
        this._teardownSelectable();
    }

    reset() {
        if (this.state.event)
            this.setState({ event: null, top: null, height: null });
    }

    update(event: CalendarEvent, { startDate, endDate, top, height }: SlotMetricsRangeReturn) {
        const { event: lastEvent } = this.state;
        if (
            lastEvent &&
            startDate === lastEvent.start &&
            endDate === lastEvent.end
        )
            return;

        this.setState({
            top,
            height,
            event: { ...event, start: startDate, end: endDate },
        });
    }

    eventOffsetTop: number | undefined;

    handleMove(point: SelectionBox, bounds: GetBoundsForNodeReturn) {
        // TODO probably not needed, just a type assertion.
        if (this.eventOffsetTop === undefined)
            return;

        if (!pointInColumn(bounds, point))
            return this.reset();

        const { event } = this.context.draggable.dragAndDropAction;
        // TODO Type assertion.
        if (!event)
            return;

        const { slotMetrics } = this.props;

        const newSlot = slotMetrics.closestSlotFromPoint({ y: point.y - this.eventOffsetTop, x: point.x }, bounds);

        const { duration } = eventTimes(event);
        const newEnd = localizer.add(newSlot, duration, 'millisecond');
        this.update(event, slotMetrics.getRange(newSlot, newEnd, false, true));
    }

    handleResize(point: SelectionBox, bounds: GetBoundsForNodeReturn) {
        const { slotMetrics } = this.props;
        const { event, direction } = this.context.draggable.dragAndDropAction;
        const newTime = slotMetrics.closestSlotFromPoint(point, bounds);

        // TODO Type assertion.
        if (!event)
            return;

        const { start, end } = eventTimes(event);
        let newRange: GetRangeReturn | undefined;
        if (direction === ActionDirection.Up) {
            const newStart = localizer.min(
                newTime,
                slotMetrics.closestSlotFromDate(end, -1),
            );
            // Get the new range based on the new start but don't overwrite the end date as it could be outside this day boundary.
            newRange = {
                ...slotMetrics.getRange(newStart, end),
                endDate: end,
            };
        }
        else if (direction === ActionDirection.Down) {
            // Get the new range based on the new end but don't overwrite the start date as it could be outside this day boundary.
            const newEnd = localizer.max(
                newTime,
                slotMetrics.closestSlotFromDate(start),
            );
            newRange = {
                ...slotMetrics.getRange(start, newEnd),
                startDate: start,
            };
        }
        else {
            // This should not happen ... so why it's here?
            return;
        }

        this.update(event, newRange);
    }

    handleDropFromOutside(point: SelectionBox, boundaryBox: GetBoundsForNodeReturn) {
        const { slotMetrics } = this.props;

        const start = slotMetrics.closestSlotFromPoint({ y: point.y, x: point.x }, boundaryBox);

        this.context.draggable.onDropFromOutside({
            start,
            end: slotMetrics.nextSlot(start),
            isAllDay: false,
        });
    }

    updateParentScroll(parent: HTMLElement, node: HTMLElement) {
        setTimeout(() => {
            const draggedEl = qsa(node, classSelectors.dragPreview.selector)[0];
            if (draggedEl) {
                if (draggedEl.offsetTop < parent.scrollTop) {
                    scrollTop(parent, Math.max(draggedEl.offsetTop, 0));
                }
                else if (
                    draggedEl.offsetTop + draggedEl.offsetHeight > parent.scrollTop + parent.clientHeight
                ) {
                    scrollTop(
                        parent,
                        Math.min(
                            draggedEl.offsetTop - parent.offsetHeight + draggedEl.offsetHeight,
                            parent.scrollHeight,
                        ),
                    );
                }
            }
        });
    }

    _selector: Selection | null = null;

    _selectable = () => {
        const wrapper = this.ref.current!;
        const node = wrapper.children[0] as HTMLElement;
        let isBeingDragged = false;
        const selector = (this._selector = new Selection(() => wrapper.closest(classSelectors.timeView.selector)));
        const parent = scrollParent(wrapper) as HTMLElement;

        selector.on('beforeSelect', (point: SelectionBox) => {
            const { dragAndDropAction } = this.context.draggable;

            if (!dragAndDropAction.action)
                return false;
            if (dragAndDropAction.action === 'resize')
                return pointInColumn(getBoundsForNode(node), point);

            const eventNode = getEventNodeFromPoint(node, point);
            if (!eventNode)
                return false;

            // eventOffsetTop is distance from the top of the event to the initial
            // mouseDown position. We need this later to compute the new top of the
            // event during move operations, since the final location is really a
            // delta from this point. note: if we want to DRY this with WeekWrapper,
            // probably better just to capture the mouseDown point here and do the
            // placement computation in handleMove()...
            this.eventOffsetTop = point.y - (getBoundsForNode(eventNode as HTMLElement) as GetBoundsForNodeReturn).top;
        });

        selector.on('selecting', (box: SelectionBox) => {
            const bounds = getBoundsForNode(node) as GetBoundsForNodeReturn;
            const { dragAndDropAction } = this.context.draggable;

            if (dragAndDropAction.action === 'move') {
                this.updateParentScroll(parent, node);
                this.handleMove(box, bounds);
            }
            if (dragAndDropAction.action === 'resize') {
                this.updateParentScroll(parent, node);
                this.handleResize(box, bounds);
            }
        });

        selector.on('dropFromOutside', (point: SelectionBox) => {
            if (!this.context.draggable.onDropFromOutside)
                return;
            const bounds = getBoundsForNode(node) as GetBoundsForNodeReturn;
            if (!pointInColumn(bounds, point))
                return;
            this.handleDropFromOutside(point, bounds);
        });

        selector.on('dragOver', (point: SelectionBox) => {
            if (!this.context.draggable.dragFromOutsideItem)
                return;
            const bounds = getBoundsForNode(node) as GetBoundsForNodeReturn;
            this.handleDropFromOutside(point, bounds);
        });

        selector.on('selectStart', () => {
            isBeingDragged = true;
            this.context.draggable.onStart();
        });

        selector.on('select', (point: SelectionBox) => {
            const bounds = getBoundsForNode(node) as GetBoundsForNodeReturn;
            isBeingDragged = false;
            const { dragAndDropAction } = this.context.draggable;
            if (dragAndDropAction.action === 'resize')
                this.handleInteractionEnd();

            else if (!this.state.event || !pointInColumn(bounds, point))
                return;

            else
                this.handleInteractionEnd();

        });

        selector.on('click', () => {
            if (isBeingDragged)
                this.reset();
            this.context.draggable.onEnd(null);
        });
        selector.on('reset', () => {
            this.reset();
            this.context.draggable.onEnd(null);
        });
    };

    handleInteractionEnd = () => {
        const { event } = this.state;
        // TODO Type assertion.
        if (!event)
            return;

        this.reset();

        this.context.draggable.onEnd({
            start: event.start,
            end: event.end,
        });
    };

    _teardownSelectable = () => {
        if (!this._selector)
            return;
        this._selector.teardown();
        this._selector = null;
    };

    renderContent() {
        const { children } = this.props;

        const { event, top, height } = this.state;
        if (!event)
            return children;

        // TODO again, why?
        const finalTop = top ?? 0;
        const finalHeight = height ?? 0;
        const events = children.props.children;

        return cloneElement(children, {
            children: (<>
                {events}

                {event && (
                    <TimeGridEvent
                        event={event}
                        isPreview
                        className={clsx(classSelectors.dragPreview.class, 'cursor-move')}
                        style={{ top: finalTop, height: finalHeight, width: 100, xOffset: 0 }}
                    />
                )}
            </>),
        });
    }

    render() {
        return <div ref={this.ref}>{this.renderContent()}</div>;
    }
}

type GetBoundsForNodeReturn = {
    top: number;
    bottom: number;
    left: number;
    right: number;
};
