import { useMemo } from 'react';
import { Button, DropdownMenu, ScrollArea, Sheet, Spinner, SpinnerButton } from ':components/shadcn';
import { EventForm } from ':frontend/components/event/EventForm';
import { useClients, createActionState, type BlockerModalControl } from ':frontend/hooks';
import { useCachedWithTimeout } from ':components/hooks';
import type { Selected } from ':frontend/pages/CalendarDetail';
import { getChange, SyncModal, useEvent, type UpdateEventsAction, type UseEventDispatch, type UseEventState } from '../event/useEvent';
import { type ClientInfoFE } from ':frontend/types/Client';
import { EventUpdateModal } from '../event/EventUpdateModal';
import { EventPayment } from '../event/EventPayment';
import { EventStateBadge, EventDraftBadge, StateTransitionButtons } from '../event/EventStateBadge';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import { EventNotes } from '../event/EventNotes';
import { isParticipantBillable } from ':frontend/types/EventParticipant';
import { Link } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { useUser } from ':frontend/context/UserProvider';
import { TeamMemberBadge } from '../team/TeamMemberBadge';
import { TeamMemberRole } from ':utils/entity/team';
import { Check1Icon, TrashXmarkIcon } from ':components/icons/basic';
import { BlockNavigationModal } from '../BlockNavigationModal';
import type { PreselectEventOrder } from '../orders/useEventOrder';

const HIDE_SIDEBAR_TIMEOUT = 300;

type EventSidebarProps = Readonly<{
    input?: Selected;
    onClose: (action?: UpdateEventsAction) => void;
}>;

export function EventSidebar({ input, onClose }: EventSidebarProps) {
    const { clients, addClients } = useClients();

    // Wee need to keep showing the sidebar for a while after it's closed, so that the animations can finish.
    // However, we want to unmount it eventually, so that the state of its components is reset. E.g., all the collapsible menus etc.
    const cachedInput = useCachedWithTimeout(input, HIDE_SIDEBAR_TIMEOUT);
    if (!clients || !cachedInput)
        return null;

    return (
        <EventSidebarInner
            show={!!input}
            input={cachedInput}
            onClose={onClose}
            clients={clients}
            addClients={addClients}
        />
    );
}

type EventSidebarInnerProps = Readonly<{
    show: boolean;
    input: Selected;
    onClose: (action?: UpdateEventsAction) => void;
    clients: ClientInfoFE[];
    addClients: (clients: ClientInfoFE[]) => void;
}>;

function EventSidebarInner({ show, input, onClose, clients, addClients }: EventSidebarInnerProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventSidebar' });
    const { state, dispatch } = useEvent(input, onClose, addClients);
    const confirmCloseModalControl: BlockerModalControl = {
        show: !!state.isConfirmClose,
        stay: () => dispatch({ type: 'confirmClose', value: 'back' }),
        exit: () => dispatch({ type: 'confirmClose', value: 'discard' }),
    };

    return (<>
        {state.sync && <EventUpdateModal state={state.sync} dispatch={dispatch} />}

        <BlockNavigationModal control={confirmCloseModalControl} />

        <Sheet.Root open={show} onOpenChange={newOpen => !newOpen && dispatch({ type: 'confirmClose', value: 'start' })}>
            {/*
                The width is *very precisely* selected so that the datetime picker will fit inside. Otherwise it would be blocked by overflow-hidden. No, there isn't a way how to create a general 'exception' from overflow-hidden. The only good solution is to use something like modal for the datepicker (which we probably should, anyway). See https://ui.shadcn.com/docs/components/date-picker.

                After that, change this back to 420px.
            */}
            <Sheet.Content className='max-w-[424px] overflow-hidden' closeButton={t('close-button-aria')}>
                <ScrollArea className='h-full'>
                    <EventDisplayInner
                        state={state}
                        dispatch={dispatch}
                        clients={clients}
                    />
                </ScrollArea>
            </Sheet.Content>
        </Sheet.Root>
    </>);
}

type StateDispatchProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
}>;

type EventDisplayInner = StateDispatchProps & Readonly<{
    clients: ClientInfoFE[];
}>;

export function EventDisplayInner({ state, dispatch, clients }: EventDisplayInner) {
    const { t } = useTranslation('components', { keyPrefix: 'eventSidebar' });
    const isMaster = useUser().role === TeamMemberRole.master;

    return (
        <div className='w-full max-w-[424px] min-h-full px-4 sm:px-8 py-8 space-y-4 flex flex-col'>
            <div className='max-sm:space-y-4 sm:flex sm:items-center sm:gap-4 text-secondary-700'>
                <Sheet.Title>
                    {state.event ? (
                        <EventStateBadge event={state.event} />
                    ) : (
                        <EventDraftBadge />
                    )}
                </Sheet.Title>

                <Sheet.Description className='sr-only'>{t('description')}</Sheet.Description>

                {state.event?.isInPackage ? (
                    <div>
                        <span>{state.event?.packageProgress}</span>
                        {' '}
                        <span>{t('package-progress-label')}</span>
                    </div>
                ) : state.event?.recurrenceIndex !== undefined && (
                    <div>
                        <span>#{state.event.recurrenceIndex + 1}</span>
                        {' '}
                        <span>{t('recurrence-index-label')}</span>
                    </div>
                )}

                {isMaster && state.event && (
                    <TeamMemberBadge appUserId={state.event.ownerId} size='md' />
                )}

                <div className='grow' />
                {autosaveLabel(state, t)}
            </div>

            <div className='w-full h-px bg-secondary-100' />

            <EventForm
                state={state}
                dispatch={dispatch}
                clients={clients}
            />

            <div className='w-full h-px bg-secondary-100' />

            <EventNotes
                state={state}
                dispatch={dispatch}
            />

            {!state.event?.isInPackage && (
                <EventPayment
                    state={state}
                    dispatch={dispatch}
                    clients={clients}
                />
            )}

            <div className='grow' />

            <div className='flex items-center'>
                <OptionsMenu state={state} dispatch={dispatch} />
                <div className='grow' />
                <SaveButtons state={state} dispatch={dispatch} />
            </div>
        </div>
    );
}

function autosaveLabel(state: UseEventState, t: TFunction) {
    const isSaving = !!state.sync?.fetching;
    const isSaved = !!state.close?.result;

    return (
        <div className='w-24 flex items-center text-success'>
            {isSaving ? (<>
                <Spinner size='xs' colorClass='border-success' className='mr-2 -mt-[2px]' />
                {t('autosave-saving-label')}
            </>) : isSaved && (<>
                <Check1Icon size='sm' className='mr-2' />
                {t('autosave-saved-label')}
            </>)}
        </div>
    );
}

function OptionsMenu({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventSidebar' });

    if (!state.event)
        return null;

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <Button variant='ghost' size='small'>
                    {t('options-button')}
                </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
                <StateTransitionButtons
                    event={state.event}
                    onSubmit={(transition, fid) => dispatch({ type: 'sync', operation: { type: 'transition', transition }, fid })}
                    buttonClassName='w-full justify-start'
                    fetching={state.sync?.fetching}
                    isOverlay={state.sync?.phase !== SyncModal.None}
                />

                <SpinnerButton
                    variant='transparent'
                    className='w-full justify-start text-danger'
                    onClick={() => dispatch({ type: 'sync', operation: { type: 'delete' }, fid: FID_DELETE })}
                    fetching={state.sync?.fetching}
                    fid={FID_DELETE}
                    isOverlay={state.sync?.phase !== SyncModal.None}
                >
                    <TrashXmarkIcon />{t('delete-button')}
                </SpinnerButton>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
}

const FID_DELETE = 'delete';

function SaveButtons({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventSidebar' });
    const change = getChange(state);
    // We want to enable the button if there is anything to save. Note this still doesn't include things like notes and payment state, which are saved separately.
    const isChange = change.type !== 'none';
    const { payment } = state;
    const existingPaymentState = useMemo(() => {
        if (state.event?.clients.some(c => c.isInOrder))
            return 'order';

        return undefined;
    }, [ state.event ]);

    // The payment hasn't even started - we show just the save button.
    if (!payment)
        return saveButton(true, isChange, state, dispatch, t);

    // There is already a payment. If it's complete (all clients are invoiced), we show, again, just the save button.
    // If it's, however, only partial, we enable the Save&Bill button.
    if (existingPaymentState) {
        const billableClients = payment.clients.filter(c => !c.original || isParticipantBillable(c.original));
        const isBillable = billableClients.length > 0;

        return (
            <div className='flex gap-1'>
                {isBillable && saveAndBillButton(isChange, state, dispatch, t)}
                {saveButton(!isBillable, isChange, state, dispatch, t)}
            </div>
        );
    }

    // The event exists, but it isn't invoiced yet. This means the Save&Bill button.
    const isCheckoutEnabled = payment.clients.length > 0;
    return (
        <div className='flex gap-1'>
            {isCheckoutEnabled && saveAndBillButton(isChange, state, dispatch, t)}
            {saveButton(!isCheckoutEnabled, isChange, state, dispatch, t)}
        </div>
    );
}

function saveButton(isPrimary: boolean, isChange: boolean, state: UseEventState, dispatch: UseEventDispatch, t: TFunction) {
    return (
        <SpinnerButton
            variant={isPrimary ? 'primary' : 'outline'}
            onClick={() => dispatch({ type: 'sync', operation: { type: 'start', isBillLater: false }, fid: FID_SAVE })}
            fetching={state.sync?.fetching}
            fid={FID_SAVE}
            isOverlay={state.sync?.phase !== SyncModal.None}
            disabled={!isChange}
            className='px-8'
        >
            {t('save-button')}
        </SpinnerButton>
    );
}

const FID_SAVE = 'save';

function saveAndBillButton(isChange: boolean, state: UseEventState, dispatch: UseEventDispatch, t: TFunction) {
    if (isChange) {
        return (
            <SpinnerButton
                variant='primary'
                onClick={() => dispatch({ type: 'sync', operation: { type: 'start', isBillLater: true }, fid: FID_SAVE_AND_BILL })}
                fetching={state.sync?.fetching}
                fid={FID_SAVE_AND_BILL}
                isOverlay={state.sync?.phase !== SyncModal.None}
            >
                {t('save-and-bill-button')}
            </SpinnerButton>
        );
    }

    if (!state.event)
        return null;

    return (
        <Link to={routesFE.directSale.event} state={createActionState<PreselectEventOrder>('preselectEventOrder', {
            eventIds: [ state.event.id ],
        })}>
            <Button>
                {t('bill-button')}
            </Button>
        </Link>
    );
}

const FID_SAVE_AND_BILL = 'save-and-bill';
