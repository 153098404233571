import { z, type EnumLike } from 'zod';

export function enumFilterQueryToOrm<E extends string>(enumObject: EnumLike, input: E[]) {
    if (input.length === 0 || input.length === Object.keys(enumObject).length)
        return {};

    return { $in: input };
}

export const zPage = z.number().int().gte(1);

export function zPaginated<T>(schema: z.ZodType<T>) {
    return z.object({
        items: z.array(schema),
        total: z.number().int().gte(0),
    });
}

export function paginatedQueryToOrm({ page }: { page?: number }) {
    if (page === undefined)
        return {};

    return {
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: (page - 1) * DEFAULT_ITEMS_PER_PAGE,
    };
}

export const DEFAULT_ITEMS_PER_PAGE = 15;

export enum SortOrder {
    Ascending = 'asc',
    Descending = 'desc',
}

export type Sort<TKey extends string> = { key: TKey, order: SortOrder };
export function zSort<TKey extends string>(keys: [TKey, ...TKey[]]) {
    return z.object({
        key: z.enum(keys),
        order: z.nativeEnum(SortOrder),
    });
}

export function sortQueryToOrm<TKey extends string>(sort: Sort<TKey> | undefined) {
    if (!sort)
        return {};

    return {
        [sort.key]: sort.order,
    };
}
