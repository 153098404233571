import { useEffect, useMemo, useState } from 'react';
import { Form, Card, Button } from ':components/shadcn';
import { useForm } from 'react-hook-form';
import { type ClientFE } from ':frontend/types/Client';
import { SubscriberFormInner, formToOutput as subscriberFormToOutput, type FormDataType as SubscriberFormData, inputToForm as subscriberInputToForm } from '../settings/SubscriberForm';
import { formToOutput as invoicingOverrideFormToOutput, type InvoicingOverrideFormData, inputToForm as invoicingOverrideInputToForm, InvoicingOverrideFormInner } from '../settings/InvoicingOverrideForm';
import { useTranslation } from 'react-i18next';
import { useMaster } from ':frontend/context/UserProvider';
import type { OmitId } from ':utils/id';
import type { ClientUpdate } from ':utils/entity/client';
import { LicenseIcon, ArrowsExpandDiagonal6Icon, ArrowsReduceDiagonal1Icon } from ':components/icons/basic';
import { InvoicingProfileExampleModal } from ':frontend/components/orders/InvoicingProfileExampleModal';

type ClientInvoicingFormProps = Readonly<{
    onSubmit: (output: OmitId<ClientUpdate>) => void;
    defaultValue: ClientFE;
    onChange?: (isDirty: boolean) => void;
    saveButton?: React.FC<{ onClick: () => void }>;
    showExampleModal: boolean;
    hideExampleModal: () => void;
}>;

export function ClientInvoicingForm({ onSubmit, defaultValue, onChange, saveButton, showExampleModal, hideExampleModal }: ClientInvoicingFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'client' });
    const { form, handleSubmit, profile } = useClientInvoicingForm(onSubmit, defaultValue, onChange);

    const [ showMainSection, setShowMainSection ] = useState(true);
    const [ showOverrideSection, setShowOverrideSection ] = useState(false);

    return (
        <>
            <InvoicingProfileExampleModal profile={showExampleModal ? profile : undefined} onHide={hideExampleModal} />

            <Form.Root onSubmit={handleSubmit} className='space-y-8'>
                <Card>
                    <div className='flex items-center justify-between cursor-pointer' onClick={() => setShowMainSection(!showMainSection)}>
                        <Card.Title className='flex item-center gap-2'>
                            <LicenseIcon className='shrink-0 text-warning-500' />
                            {t('invoicing-main-title')}
                        </Card.Title>

                        <Button variant='transparent'>
                            {!showMainSection && <ArrowsExpandDiagonal6Icon className='h-4' />}
                            {showMainSection && <ArrowsReduceDiagonal1Icon className='h-4' />}
                        </Button>
                    </div>

                    {showMainSection && (
                        <>
                            <Card.Divider />
                            <SubscriberFormInner
                                form={form}
                            />
                            <div className='mt-8'>{saveButton?.({ onClick: handleSubmit })}</div>
                        </>
                    )}
                </Card>

                <Card>
                    <div className='flex items-center justify-between cursor-pointer' onClick={() => setShowOverrideSection(!showOverrideSection)}>
                        <Card.Title className='flex item-center gap-2'>
                            <LicenseIcon className='shrink-0 text-warning-500' />
                            {t('invoicing-override-title')}
                        </Card.Title>

                        <Button variant='transparent'>
                            {!showOverrideSection && <ArrowsExpandDiagonal6Icon className='h-4' />}
                            {showOverrideSection && <ArrowsReduceDiagonal1Icon className='h-4' />}
                        </Button>
                    </div>

                    {showOverrideSection && (
                        <>
                            <p>{t('invoicing-override-description')}</p>
                            <Card.Divider />
                            <InvoicingOverrideFormInner
                                form={form}
                                profile={profile}
                            />
                            <div className='mt-8'>{saveButton?.({ onClick: handleSubmit })}</div>
                        </>
                    )}
                </Card>
            </Form.Root>
        </>
    );
}

function useClientInvoicingForm(
    onSubmit: (output: OmitId<ClientUpdate>) => void,
    defaultValue: ClientFE,
    onChange?: (isDirty: boolean) => void,
) {
    const { profiles } = useMaster();
    const profile = useMemo(() => profiles.find(profile => profile.id === defaultValue.invoicingProfileId), [ profiles, defaultValue ]);
    if (!profile)
        throw new Error(`Profile "${defaultValue.invoicingProfileId}" not found for client "${defaultValue.id}".`);

    const form = useForm<SubscriberFormData & InvoicingOverrideFormData>({
        defaultValues: {
            ...subscriberInputToForm(defaultValue.subscriberSettings),
            ...invoicingOverrideInputToForm(defaultValue.invoicingOverride, profile, defaultValue),
        },
    });
    const isDirty = form.formState.isDirty;

    useEffect(() => onChange?.(isDirty), [ isDirty, onChange ]);

    function onValidSubmit(data: SubscriberFormData & InvoicingOverrideFormData) {
        onSubmit({
            subscriber: subscriberFormToOutput(data),
            override: invoicingOverrideFormToOutput(data),
        });
    }

    useEffect(() => {
        form.reset({
            ...subscriberInputToForm(defaultValue.subscriberSettings),
            ...invoicingOverrideInputToForm(defaultValue.invoicingOverride, profile, defaultValue),
        });
    }, [ defaultValue ]);

    return {
        form,
        handleSubmit: form.handleSubmit(onValidSubmit),
        profile,
        showProfileSelect: profiles.length > 1,
    };
}
