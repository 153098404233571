import { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { EventsOperationOutputFE, type EventFE } from ':frontend/types/Event';
import { Calendar, DEFAULT_CALENDAR_VIEW } from ':frontend/lib/calendar/Calendar';
import { useTranslation } from 'react-i18next';
import { type CalendarEvent, eventToCalendarEvent, type FullEventResource, isFullEvent, isDraftEvent, type DraftEventResource, createDraftEvent, isGoogleEvent, eventsToCalendarEvents } from ':frontend/types/calendar/Calendar';
import { EventSidebar } from ':frontend/components/calendar/EventSidebar';
import { useNavigationAction, useGoogleCalendars, useMonthLoader, type NavigationProperty, IdFilter, groupMonthFunction, type GroupParams, type SimpleParams, simpleMonthFunction, fetchFlowlanceEvents, LoadingState, useClients, useTailwindMediaQuery } from ':frontend/hooks';
import { googleEventsToCalendarEvents, fetchGoogleEvents, googleEventToServer } from ':frontend/types/calendar/Google';
import type { EventChangeArgs, SlotInfo } from ':frontend/lib/calendar';
import { useUser } from ':frontend/context/UserProvider';
import { type Id } from ':utils/id';
import { DateTime } from 'luxon';
import type { View } from ':frontend/lib/calendar/Views';
import { roundDateToMinutes } from ':frontend/utils/common';
import { type UpdateEventsAction } from ':frontend/components/event/useEvent';
import { trpc } from ':frontend/context/TrpcProvider';
import type { GoogleEvent } from ':utils/lib/google';
import { useTeamMemberCalendarsFilter } from ':frontend/components/calendar/TeamMemberCalendarsFilter';
import { isGoogleCalendarEnabled } from ':frontend/types/AppUser';
import { CalendarToolbar } from ':frontend/components/calendar/CalendarToolbar';
import { CalendarSidebar } from ':frontend/components/calendar/CalendarSidebar';

export type PreselectEvent = NavigationProperty<'preselectEvent', 'new' | Id>;

type SelectedEvent = {
    event: CalendarEvent<FullEventResource>;
    changes?: InitialEventChanges;
}

type InitialEventChanges = {
    start: DateTime;
    end: DateTime;
};

export type Selected = SelectedEvent | {
    draft: CalendarEvent<DraftEventResource>;
}

export function getSelectedEvent(selected: Selected | undefined): SelectedEvent | undefined {
    return (selected && 'event' in selected) ? selected : undefined;
}

/** In seconds. */
const DEFAULT_EVENT_DURATION = 60 * 60;

export default function CalendarDetail() {
    const { t } = useTranslation('pages', { keyPrefix: 'calendar' });
    const userContext = useUser();
    const { appUser, settings, googleUser, role } = userContext;

    const defaultEventDuration = DEFAULT_EVENT_DURATION;

    const preselectEvent = useNavigationAction<PreselectEvent>('preselectEvent');
    const [ date, setDate ] = useState<DateTime>(() => DateTime.now());
    const [ view, setView ] = useState<View>(DEFAULT_CALENDAR_VIEW);
    const loadingGoogleEvents = useRef<Set<string>>(new Set());

    // Flowlance events

    const flowlanceMonthFunction = useCallback((params: SimpleParams) => simpleMonthFunction(
        fetchFlowlanceEvents,
        params,
    ), []);

    const flowlanceIdFilter = useMemo(() => new IdFilter((event: EventFE) => event.id), []);

    const { dataObject, setDataObject, handleNavigate, visibleMonths } = useMonthLoader(flowlanceMonthFunction, flowlanceIdFilter);

    // For now, we filter the team members only on FE.
    // TODO do it on BE
    const { disabledIds: disabledMemberIds, toggleMember } = useTeamMemberCalendarsFilter();

    // Google events

    const { calendars, activeIds, toggleCalendar } = useGoogleCalendars();

    const googleMonthFunction = useCallback((params: GroupParams) => groupMonthFunction(
        fetchGoogleEvents,
        [ ...activeIds.values() ],
        params,
    ), [ activeIds ]);

    const googleIdFilter = useMemo(() => new IdFilter((event: GoogleEvent) => event.id), []);

    const {
        dataObject: googleDataObject,
        handleNavigate: googleHandleNavigate,
        visibleMonths: googleVisibleMonths,
    } = useMonthLoader(googleMonthFunction, googleIdFilter);

    // Loading spinner

    const visibleMonthsLoaded = useMemo(() => {
        const flowlanceLoaded = visibleMonths.every(month => month === LoadingState.Loaded);
        return flowlanceLoaded && !!calendars && (
            !isGoogleCalendarEnabled(appUser, role)
            || googleVisibleMonths.every(month => !month || Object.entries(month).every(([ id, state ]) => !activeIds.has(id) || state === LoadingState.Loaded))
        );
    }, [ visibleMonths, googleVisibleMonths, calendars, activeIds, appUser ]);

    // Selecting, creating and editing events

    const [ selected, setSelected ] = useState<Selected>();

    const clonedGoogleEvents = useMemo(() => {
        return dataObject.data.filter(ev => !!ev.googleId);
    }, [ dataObject ]);

    const { teamMembers } = userContext;
    const calendarEvents = useMemo(() => calendars
        ? eventsToCalendarEvents(dataObject.data, teamMembers, disabledMemberIds, calendars, activeIds, appUser.google.calendarId)
        : [],
    [ dataObject, teamMembers, disabledMemberIds, calendars, activeIds, appUser.google.calendarId ]);


    // const calendarEvents = useMemo(() => calendars
    //     ? eventsToCalendarEvents(dataObject.data, calendars, activeIds, appUser.google.calendar?.calendarId)
    //     : [],
    // [ dataObject, calendars, activeIds ]);

    const events = useMemo(() => [
        ...calendarEvents,
        ...(calendars ? googleEventsToCalendarEvents(googleDataObject.data, calendars, activeIds) : []),
        ...(selected && 'draft' in selected ? [ selected.draft ] : []),
    ].flatMap(event => {
        if (!isFullEvent(event)) {
            // Don't show Google events that are already in our db
            if (isGoogleEvent(event)) {
                if (visibleMonthsLoaded) {
                    const flowlanceEvent = clonedGoogleEvents.find(ev => ev.googleId === event.resource.event.id);
                    if (!flowlanceEvent)
                        return event;
                }

                return [];
            }
            return event;
        }

        // ?
        const selectedEvent = getSelectedEvent(selected);
        if (selectedEvent?.event.resource.event.id === event.resource.event.id)
            return selectedEvent.event;

        // the current flowlance event could be a Google event clone
        // we first need to load google events, so we know the correct color
        if (!visibleMonthsLoaded)
            return [];

        return event;
    }), [ calendarEvents, googleDataObject, selected, calendars, activeIds, clonedGoogleEvents, visibleMonthsLoaded ]);

    const updateEvents = useCallback((action: UpdateEventsAction) => {
        setDataObject(({ data }) => ({ data: computeEventsUpdate(data, action) }));
    }, [ setDataObject ]);

    useEffect(() => {
        if (getSelectedEvent(selected) || !preselectEvent?.data)
            return;

        if (preselectEvent.data === 'new') {
            handleNewEventButton();
            return;
        }

        const preselectedEventId = preselectEvent.data;
        const preselectedEvent = calendarEvents.find(event => event.resource.event.id === preselectedEventId);
        if (preselectedEvent) {
            setSelected({ event: preselectedEvent });
            setDate(preselectedEvent.start);
        }
    }, [ calendarEvents, preselectEvent ]);

    useEffect(() => {
        const selectedEvent = getSelectedEvent(selected);
        if (!selectedEvent)
            return;

        const newSelectedEvent = calendarEvents.find(event => event.resource.event.id === selectedEvent.event.resource.event.id);
        if (!newSelectedEvent) {
            setSelected(undefined);
            return;
        }

        if (newSelectedEvent !== selectedEvent.event) {
            setSelected({
                event: {
                    ...newSelectedEvent,
                    ...selectedEvent.changes,
                },
                changes: selectedEvent.changes,
            });
        }
        // This is intentional - we want to update the selected event only when the original events change.
        // TODO - do this in a way that doesn't require dependency on the selectedEvent.
    }, [ calendarEvents ]);

    const handleNewEventButton = useCallback(() => {
        const start = roundDateToMinutes(DateTime.now(), 15);
        const draft = createDraftEvent(start, start.plus({ second: defaultEventDuration }), t('new-event'));
        setSelected({ draft });
    }, [ defaultEventDuration, t ]);

    const handleSelectSlot = useCallback(({ start, end, action }: SlotInfo) => {
        if (action === 'click')
            end = start.plus({ second: defaultEventDuration });
        const draft = createDraftEvent(start, end, t('new-event'));
        setSelected({ draft });
    }, [ defaultEventDuration, t ]);

    const rescheduleEvent = useCallback(({ event, start, end }: EventChangeArgs) => {
        // `event` is the event currently stored in `events` (we want to change that one)
        // `start`, `end` and `isAllDay` is the information about the change that happened

        const changed: CalendarEvent = {
            ...event,
            start,
            end,
        };

        if (isDraftEvent(changed)) {
            setSelected({ draft: changed });
        }
        else if (isFullEvent(changed)) {
            if (+changed.start === +event.start && +changed.end === +event.end)
                return;

            setSelected({
                event: changed,
                changes: {
                    start,
                    end,
                },
            });
        }
    }, []);

    const closeEventSidebar = useCallback((action?: UpdateEventsAction) => {
        setSelected(undefined);
        if (action)
            updateEvents(action);
    }, [ updateEvents ]);

    const { clients, addClients } = useClients();
    const createGoogleEventMutation = trpc.event.createGoogleEvent.useMutation();

    function createEventFromGoogle(googleEvent: GoogleEvent) {
        if (!googleUser)
            return; // oh no

        loadingGoogleEvents.current.add(googleEvent.id);

        const init = googleEventToServer(googleEvent, settings, clients ?? [], googleUser);
        createGoogleEventMutation.mutate(init, {
            onError: () => {
                // TODO Do something.
            },
            onSuccess: response => {
                const result = EventsOperationOutputFE.fromServer(response);

                const newEvents = result.events;
                const events = newEvents.map(e => eventToCalendarEvent(e, teamMembers));

                addClients(result.newClients);

                updateEvents({ type: 'update', events: newEvents });
                // TODO Don't know if this is enough, because the CalendarEvent will be also created in the useMemo above.
                setSelected({ event: events[0] });
                loadingGoogleEvents.current.delete(googleEvent.id);
                flowlanceIdFilter.apply(newEvents);
            },
        });
    }

    async function selectEvent(event: CalendarEvent) {
        if (isFullEvent(event)) {
            setSelected({ event });
            return;
        }

        if (!isGoogleEvent(event) || event.isAllDay || loadingGoogleEvents.current.has(event.resource.event.id))
            return;

        createEventFromGoogle(event.resource.event);
    }

    const onNavigate = useCallback((newDate: DateTime, newView: View) => {
        setDate(newDate);
        setView(newView);
        handleNavigate(newDate, newView);
        googleHandleNavigate(newDate, newView);
    }, [ handleNavigate, googleHandleNavigate ]);

    const lgScreen = useTailwindMediaQuery({ minWidth: 'lg' });
    const [ isCollapsed, setIsCollapsed ] = useState(!lgScreen);

    return (
        <div className='w-full'>
            <CalendarToolbar
                date={date}
                view={view}
                onView={setView}
                onNavigate={onNavigate}
                onNewEvent={handleNewEventButton}
                calendarsProps={{ disabledMemberIds, toggleMember, calendars, activeIds, toggleCalendar }}
            />

            <EventSidebar
                input={selected}
                onClose={closeEventSidebar}
            />

            <div className={`min-w-full w-fit transition-all pt-[--topbar-height] max-lg:pr-[37px] ${isCollapsed ? 'lg:pr-[37px]' : 'lg:pr-[281px]' }`}>
                <Calendar
                    events={events}
                    selected={selected}
                    onSelectEvent={selectEvent}
                    onNavigate={onNavigate}
                    view={view}
                    onSelectSlot={handleSelectSlot}
                    onMoveEvent={rescheduleEvent}
                    onResizeEvent={rescheduleEvent}
                    loading={!visibleMonthsLoaded}
                    date={date}
                    clients={clients ?? []}
                />
            </div>

            <CalendarSidebar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
        </div>
    );
}

function computeEventsUpdate(originalEvents: EventFE[], { type, events }: UpdateEventsAction): EventFE[] {
    if (type === 'create')
        return [ ...originalEvents, ...events ];

    const filteredEvents = originalEvents.filter(e => !events.find(event => event.id === e.id));

    return type === 'delete'
        ? filteredEvents
        : [ ...filteredEvents, ...events ];
}
