import { CircleCheck1Icon } from ':components/icons/basic';
import clsx from 'clsx';

type BulletFeaturesProps = {
    text: string;
    isActive: boolean;
};

export function BulletFeatures({ text, isActive }: BulletFeaturesProps) {
    return (<>
        {text.split(';').map(text => (
            <div key={text} className='flex items-center gap-2'>
                <CircleCheck1Icon className={clsx('shrink-0 h-5', isActive ? 'text-success' : 'text-primary')} />
                <span className='py-[2px]'>{text}</span>
            </div>
        ))}
    </>
    );
}
