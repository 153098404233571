import { useMemo, useState } from 'react';
import { SortOrder, type Sort } from ':utils/query';
import { SortOrderIcon } from ':components/icons/custom';
import type { IconProps } from ':components/icons/common';

type SortButtonProps<TKey extends string> = {
    sort: UseSortControl<TKey>;
    name: TKey;
    className?: string;
} & IconProps;

export function SortButton<TKey extends string>({ sort, name, className, ...iconProps }: SortButtonProps<TKey>) {
    const order = sort.state?.key !== name ? undefined : sort.state.order;

    return (
        <button type='button' className={className} onClick={sort.toggle[name]}>
            <SortOrderIcon order={order} {...iconProps} />
        </button>
    );
}

export type UseSortControl<TKey extends string> = {
    state: Sort<TKey> | undefined;
    set: {
        [key in TKey]: (sort: SortOrder | undefined) => void;
    };
    toggle: {
        [key in TKey]: () => void;
    };
};

/**
 * The keys are used as dependency array.
 */
export function useSort<TKey extends string>(intialValue: Sort<TKey> | (() => Sort<TKey>) | undefined, keys: TKey[] | readonly TKey[]): UseSortControl<TKey> {
    const [ state, setState ] = useState(intialValue);

    const functions = useMemo(() => {
        const set = {} as UseSortControl<TKey>['set'];
        const toggle = {} as UseSortControl<TKey>['toggle'];

        for (const key of keys) {
            set[key] = sort => setState(sort ? { key, order: sort } : undefined);

            toggle[key] = () => setState(oldState => ({
                key,
                order: (oldState?.key !== key || oldState.order === SortOrder.Descending) ? SortOrder.Ascending : SortOrder.Descending,
            }));
        }

        return {
            set,
            toggle,
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, keys);

    return {
        state,
        ...functions,
    };
}
