import { LayoutLargeIcon, LayoutMediumLeftIcon, LayoutMediumRightIcon, LayoutSmallLeftIcon } from ':components/icons/custom';
import { Form } from ':components/shadcn';
import { getEnumValues } from ':utils/common';
import { ProductLayout } from ':utils/entity/product';
import { useTranslation } from 'react-i18next';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { forwardRef, useId, type ElementRef } from 'react';

type LayoutSelectProps = Readonly<{
    value: ProductLayout;
    onChange: (value: ProductLayout) => void;
}>;

export function LayoutSelect({ value, onChange }: LayoutSelectProps) {
    /*
    <div className='flex border border-black/10 rounded-full p-1'>
        {getEnumValues(ProductLayout).map(layout => (
            <LayoutOption key={layout} value={layout} isActive={value === layout} onClick={() => onChange(layout)} />
        ))}
    </div>
    */

    return (
        <RadioGroup.Root
            value={value}
            onValueChange={onChange}
            className='p-1 flex items-center gap-px rounded-full border'
        >
            {layoutOptions.map(name => (
                <LayoutRadioItem key={name} layout={name} />
            ))}
        </RadioGroup.Root>
    );
}

type LayoutRadioItemProps = Readonly<{
    layout: ProductLayout;
}>;

const LayoutRadioItem = forwardRef<
    ElementRef<typeof RadioGroup.Item>, LayoutRadioItemProps
>(({ layout,...props }, ref) => {
    const { t } = useTranslation('components', { keyPrefix: 'productLayoutSelect' });
    const id = useId();
    const icon = layoutIcons[layout];

    return (
        <div>
            <RadioGroup.Item
                ref={ref}
                id={id}
                value={layout}
                className={`
                    h-11 w-11 rounded-full flex items-center justify-center
                    hover:bg-primary-50 active:bg-primary-100 data-[state=checked]:bg-primary-50 data-[state=checked]:border data-[state=checked]:border-secondary-100 data-[state=checked]:text-primary
                    focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-300 focus-visible:ring-offset-2
                `}
                {...props}
            >
                {icon({ size: 'sm' })}
            </RadioGroup.Item>
            <Form.Label htmlFor={id} className='sr-only'>{t(layout)}</Form.Label>
        </div>
    );
});

const layoutOptions = getEnumValues(ProductLayout);
const layoutIcons = {
    [ProductLayout.SmallLeft]: LayoutSmallLeftIcon,
    [ProductLayout.Large]: LayoutLargeIcon,
    [ProductLayout.MediumLeft]: LayoutMediumLeftIcon,
    [ProductLayout.MediumRight]: LayoutMediumRightIcon,
} as const;
