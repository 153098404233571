import { useId, type ReactNode } from 'react';
import { Button, Card, Form } from ':components/shadcn';
import { createDefaultAvailabilityRange, getProductSlug, ProductFormsPhase, type ProductFormsDispatch, type ProductFormsState } from './useProductForms';
import { useTranslation } from 'react-i18next';
import type { IconType } from ':components/icons/common';
import { productStyles } from ':components/store/product/ProductCard';
import { ButtonIcon, CalendarPhoneIcon, ChevronDownIcon, CircleInfoIcon, CirclePercentageIcon, Compose1Icon, Eye2Icon, InputPasswordPointerIcon, Link5Icon, MoneyBillsDollarIcon, MsgSmile2Icon, PlusIcon, Trash2Icon, WindowLinkIcon } from ':components/icons/basic';
import { ProductDisplayType, productsWithoutCheckout, productsWithoutPricing, productsWithPlaceholderThumbnail, ProductType } from ':utils/entity/product';
import type { TFunction } from 'i18next';
import clsx from 'clsx';
import { CroppedImageInput, CurrencyDisplay, FileInput, ImageInput } from ':components/custom';
import { useMaster } from ':frontend/context/UserProvider';
import { TaxRateInput } from '../forms/TaxRateInput';
import { trpc } from ':frontend/context/TrpcProvider';
import { routeToDisplayString, routesStore } from ':utils/routes';
import { useTranslationWithVariant } from ':frontend/hooks';
import { LocationSelect } from '../location/LocationSelect';
import { ErrorMessage, TranslatedErrorMessage } from '../forms/ErrorMessage';
import { transformToPositiveIntegerOrEmpty, transformToPrice } from ':utils/math';
import { DatePicker } from '../forms';
import { SelectBlockTimeModal } from './SelectBlockTimeModal';
import { WEEK_DAYS, type DateRange, type Weekday } from ':utils/dateTime';
import { DateTimeDisplay } from ':components/custom/DateTimeDisplay';
import { LayoutSelect } from './LayoutSelect';
import { MarkdownInput } from '../common/MarkdownInput';
import { UpsellButton } from ':frontend/pages/settings/Subscriptions';
import { StiggFeature } from ':utils/lib/stigg';
import { useEntitlement } from ':frontend/lib/stigg';

type StateDispatchProps = Readonly<{
    state: ProductFormsState;
    dispatch: ProductFormsDispatch;
}>;

export function ProductForms({ state, dispatch }: StateDispatchProps) {
    switch (state.phase) {
    case ProductFormsPhase.ThumbnailDetails:
        return (<>
            <ProductThumbnailCard state={state} dispatch={dispatch} />
            <ProductDetailsForm state={state} dispatch={dispatch} />
        </>);
    case ProductFormsPhase.Landing:
        return (
            <ProductLandingCard state={state} dispatch={dispatch} />
        );
    case ProductFormsPhase.Checkout:
        return (
            <ProductCheckoutForm state={state} dispatch={dispatch} />
        );
    case ProductFormsPhase.LandingDetails:
        return (<>
            <ProductLandingCard state={state} dispatch={dispatch} />
            <ProductDetailsForm state={state} dispatch={dispatch} />
        </>);
    }
}

function ProductThumbnailCard({ state, dispatch }: StateDispatchProps) {
    const tt = useTranslationWithVariant(state.type, 'components', 'productThumbnailForm');
    const thumbnailId = useId();

    const isWithoutCheckout = productsWithoutCheckout.includes(state.type);

    return defaultCard(state.type, tt('thumbnail-card-title'), InputPasswordPointerIcon, <>
        {isWithoutCheckout && (
            <div className='flex justify-between items-center'>
                <h3>{tt('displayType-title')}</h3>
                <div>
                    <Form.RadioGroup value={state.thumbnail.form.displayType} onValueChange={newValue => dispatch({ type: 'thumbnail', field: 'displayType', value: newValue })}>
                        <Form.RadioItem direction='row' value={ProductDisplayType.Callout} label={tt('displayType-callout-label')} />
                        <Form.RadioItem direction='row' value={ProductDisplayType.Button} label={tt('displayType-button-label')} />
                    </Form.RadioGroup>
                </div>
            </div>
        )}

        {state.thumbnail.form.displayType === ProductDisplayType.Callout && (
            <div>
                <Form.Input
                    name='title'
                    label={<>{tt('title-label')}<Form.RequiredIcon /></>}
                    placeholder={tt('title-placeholder')}
                    isError={!!state.thumbnail.formErrors?.title}
                    value={state.thumbnail.form.title}
                    onChange={e => dispatch({ type: 'thumbnail', field: 'title', value: e.target.value })}
                    size='compact'
                />
                <TranslatedErrorMessage translationId={state.thumbnail.formErrors?.title} />
            </div>
        )}

        <div>
            <Form.Input
                label={<>{tt('buttonText-label')}<Form.RequiredIcon /></>}
                placeholder={tt('buttonText-placeholder')}
                isError={!!state.thumbnail.formErrors?.buttonText}
                value={state.thumbnail.form.buttonText}
                onChange={e => dispatch({ type: 'thumbnail', field: 'buttonText', value: e.target.value })}
                size='compact'
            />
            <TranslatedErrorMessage translationId={state.thumbnail.formErrors?.buttonText} />
        </div>

        {state.thumbnail.form.displayType === ProductDisplayType.Callout && (<>

            {isWithoutCheckout && (<>
                <FormSwitchWithIcon
                    translationPrefix='productThumbnailForm.isHideButton'
                    checked={!state.thumbnail.form.isHideButton}
                    onCheckedChange={value => dispatch({ type: 'thumbnail', field: 'isHideButton', value: !value })}
                    icon={WindowLinkIcon}
                    type={state.type}
                />

                {/* This part of the form was kinda overcrowded, this div helps to bring here some space */}
                <div />
            </>)}

            <div>
                <Form.Label htmlFor={thumbnailId}>
                    {<>{tt('thumbnail-label')} <span className='text-secondary-400'>{tt('thumbnail-label-more', { maxSize: THUMBNAIL_MAX_SIZE })}</span></>}
                </Form.Label>
                <CroppedImageInput
                    id={thumbnailId}
                    value={state.thumbnail.form.thumbnail}
                    onChange={value => dispatch({ type: 'thumbnail', field: 'thumbnail', value })}
                    thumbnailClass='max-h-14 h-14 w-14 rounded-lg'
                    cropperOptions={{
                        modalDescription: tt('thumbnail-cropper-description', { maxSize: THUMBNAIL_MAX_SIZE }),
                        selectionRounded: 'lg',
                        maxWidth: THUMBNAIL_MAX_SIZE,
                        maxHeight: THUMBNAIL_MAX_SIZE,
                    }}
                />
            </div>

            {(state.thumbnail.form.thumbnail || productsWithPlaceholderThumbnail.includes(state.type)) && (
                <div className='flex justify-between items-center'>
                    <h3>{tt('layout-title')}</h3>
                    <LayoutSelect value={state.thumbnail.form.layout} onChange={value => dispatch({ type: 'thumbnail', field: 'layout', value })} />
                </div>
            )}

            {!isWithoutCheckout && (
                <div>
                    <Form.Textarea
                        name='description'
                        label={tt('description-label')}
                        placeholder={tt('description-placeholder')}
                        isError={!!state.thumbnail.formErrors?.description}
                        value={state.thumbnail.form.description}
                        onChange={e => dispatch({ type: 'thumbnail', field: 'description', value: e.target.value })}
                        minRows={2}
                    />
                    <TranslatedErrorMessage translationId={state.thumbnail.formErrors?.description} />
                </div>
            )}

        </>)}
    </>);
}

// Four times the desired size - just to be sure.
// Well it's not exactly four times, it's a little bit more, but 448 px isn't pretty.
const THUMBNAIL_MAX_SIZE = 2**9;

function defaultCard(type: ProductType, title: string, icon: IconType, content: ReactNode) {
    return (
        <Card className='flex flex-col gap-4'>
            <h3 className='flex items-center gap-2'>{styledIcon(icon, type)}<span className='text-lg'>{title}</span></h3>
            <div className='h-px w-full bg-secondary-100 mt-1 mb-2' />
            {content}
        </Card>
    );
}

function styledIcon(icon: IconType, type: ProductType): ReactNode {
    return icon({ size: 'md', className: clsx('shrink-0', productStyles[type].iconColor) });
}

function ProductLandingCard({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productLandingForm' });
    // No need to create a typed TFunction synce the translations are the same for all types.
    const coverId = useId();

    return defaultCard(state.type, t('landing-card-title'), Compose1Icon, <>
        <div>
            <Form.Input
                name='title'
                label={<>{t('title-label')}<Form.RequiredIcon /></>}
                placeholder={t('title-placeholder')}
                isError={!!state.landing.formErrors?.title}
                value={state.landing.form.title}
                onChange={e => dispatch({ type: 'landing', field: 'title', value: e.target.value })}
                size='compact'
            />
            <TranslatedErrorMessage translationId={state.landing.formErrors?.title} />
        </div>

        <div>
            <Form.Input
                label={<>{t('buttonText-label')}<Form.RequiredIcon /></>}
                placeholder={t('buttonText-placeholder')}
                isError={!!state.landing.formErrors?.buttonText}
                value={state.landing.form.buttonText}
                onChange={e => dispatch({ type: 'landing', field: 'buttonText', value: e.target.value })}
                size='compact'
            />
            <TranslatedErrorMessage translationId={state.landing.formErrors?.buttonText} />
        </div>

        <FormSwitchWithIcon
            translationPrefix='productLandingForm.isHideButton'
            checked={!state.landing.form.isHideButton}
            onCheckedChange={value => dispatch({ type: 'landing', field: 'isHideButton', value: !value })}
            icon={WindowLinkIcon}
            type={state.type}
        />

        <div>
            <Form.Label htmlFor={coverId}>
                {<>{t('cover-label')} <span className='text-secondary-400'>{t('cover-label-more', COVER_RECOMMENDED_SIZE)}</span></>}
            </Form.Label>
            <ImageInput
                id={coverId}
                value={state.landing.form.cover}
                onChange={value => dispatch({ type: 'landing', field: 'cover', value })}
                thumbnailClass='max-h-14 h-14 w-14 rounded-lg'
            />
        </div>

        <div>
            <Form.Label>{t('content-label')}</Form.Label>
            <MarkdownInput control={state.landing.form.content} menuClassName='sticky top-[--topheader-height] md:top-[--topbar-height]' />
        </div>
    </>);
}

const COVER_RECOMMENDED_SIZE = {
    width: 1920,
    height: 1080,
};

function ProductDetailsForm({ state, dispatch }: StateDispatchProps) {
    const tt = useTranslationWithVariant(state.type, 'components', 'productDetailsForm');

    return (<>
        {!productsWithoutPricing.includes(state.type) && (
            <PricingCard state={state} dispatch={dispatch} />
        )}

        {productSpecificCard(state, dispatch, tt)}

        {visibilityCard(state, dispatch, tt)}
    </>);
}

function PricingCard({ state, dispatch }: StateDispatchProps) {
    const tt = useTranslationWithVariant(state.type, 'components', 'productDetailsForm');
    const { teamSettings } = useMaster();
    const basePriceInDecimalId = useId();
    const discountedPriceInDecimalId = useId();
    const taxRateId = useId();

    return defaultCard(state.type, tt('pricing-card-title'), MoneyBillsDollarIcon, <>
        <Form.RadioGroup
            value={state.details.form.basePriceInDecimal === undefined ? 'free' : 'paid'}
            onValueChange={value => dispatch({ type: 'details', field: 'basePriceInDecimal', value: value === 'free' ? undefined : '' })}
        >
            {[ 'free', 'paid' ].map(value => (
                <Form.RadioItem key={value} value={value} label={tt(`basePriceInDecimal-${value}-label`)} description={tt(`basePriceInDecimal-${value}-description`)} />
            ))}
        </Form.RadioGroup>

        {state.details.form.basePriceInDecimal !== undefined && (<>
            <div className='pl-7 grid grid-cols-2 gap-4 items-start'>
                <div className={clsx(!teamSettings.isTaxesModuleOn && 'col-span-2')}>
                    <Form.Label htmlFor={basePriceInDecimalId}>{tt('basePriceInDecimal-label')}<Form.RequiredIcon /></Form.Label>

                    <div className='flex items-baseline gap-2'>
                        <CurrencyDisplay currency={state.details.form.currency}>
                            <Form.Input
                                id={basePriceInDecimalId}
                                placeholder={tt('basePriceInDecimal-placeholder')}
                                isError={!!state.details.formErrors?.basePriceInDecimal}
                                type='number'
                                value={state.details.form.basePriceInDecimal}
                                onChange={e => dispatch({ type: 'details', field: 'basePriceInDecimal', value: transformToPrice(e.target.value) })}
                                size='compact'
                            />
                        </CurrencyDisplay>
                    </div>

                    <TranslatedErrorMessage translationId={state.details.formErrors?.basePriceInDecimal} />
                </div>

                {teamSettings.isTaxesModuleOn && (
                    <div>
                        <Form.Label htmlFor={taxRateId}>{tt('taxRate-label')}</Form.Label>
                        <TaxRateInput
                            id={taxRateId}
                            size='compact'
                            value={state.details.form.taxRate}
                            onChange={value => dispatch({ type: 'details', field: 'taxRate', value })}
                        />
                    </div>
                )}
            </div>

            {state.type === ProductType.Membership && (
                <div className='pl-7'>
                    <Form.RadioGroup
                        value={state.details.form.pricingPeriod}
                        onValueChange={value => dispatch({ type: 'details', field: 'pricingPeriod', value })}
                    >
                        {[ 'monthly', 'weekly' ].map(value => (
                            <Form.RadioItem key={value} value={value} label={tt(`pricingPeriod-${value}-label`)} description={tt(`pricingPeriod-${value}-description`)} />
                        ))}
                    </Form.RadioGroup>
                </div>
            )}

            <div className='h-px w-full bg-secondary-100' />

            <FormSwitchWithIcon
                translationPrefix='productDetailsForm.discountedPriceInDecimal-toggle'
                checked={state.details.form.discountedPriceInDecimal !== undefined}
                onCheckedChange={value => dispatch({ type: 'details', field: 'discountedPriceInDecimal', value: value ? '' : undefined })}
                icon={CirclePercentageIcon}
                type={state.type}
            />

            {state.details.form.discountedPriceInDecimal !== undefined && (
                <div className='pl-7'>
                    <Form.Label htmlFor={discountedPriceInDecimalId}>{tt('discountedPriceInDecimal-label')}<Form.RequiredIcon /></Form.Label>

                    <div className='flex items-baseline gap-2'>
                        <CurrencyDisplay currency={state.details.form.currency}>
                            <Form.Input
                                id={discountedPriceInDecimalId}
                                placeholder={tt('discountedPriceInDecimal-placeholder')}
                                isError={!!state.details.formErrors?.discountedPriceInDecimal}
                                type='number'
                                value={state.details.form.discountedPriceInDecimal}
                                onChange={e => dispatch({ type: 'details', field: 'discountedPriceInDecimal', value: transformToPrice(e.target.value) })}
                                size='compact'
                            />
                        </CurrencyDisplay>
                    </div>

                    <TranslatedErrorMessage translationId={state.details.formErrors?.discountedPriceInDecimal} />
                </div>
            )}
        </>)}
    </>);
}

type FormSwitchWithIconProps = Readonly<{
    translationPrefix: string;
    checked: boolean;
    onCheckedChange: (value: boolean) => void;
    icon: IconType;
    type: ProductType;
}>;

function FormSwitchWithIcon({ translationPrefix, checked, onCheckedChange, icon, type }: FormSwitchWithIconProps) {
    const tt = useTranslationWithVariant(type, 'components');
    const id = useId();
    const descriptionId = `${id}-description`;

    return (
        <div className='flex items-center gap-2'>
            {styledIcon(icon, type)}
            <div>
                <Form.Label htmlFor={id} className='leading-3'>{tt(`${translationPrefix}-label`)}</Form.Label>
                <Form.Description id={descriptionId} className='leading-4'>{tt(`${translationPrefix}-description`)}</Form.Description>
            </div>
            <div className='grow' />
            <Form.Switch
                id={id}
                aria-describedby={descriptionId}
                checked={checked}
                onCheckedChange={onCheckedChange}
            />
        </div>
    );
}

function productSpecificCard(state: ProductFormsState, dispatch: ProductFormsDispatch, t: TFunction) {
    switch (state.type) {
    case ProductType.Session:
    case ProductType.Bundle:
        return <SchedulableDetailsCard state={state} dispatch={dispatch} t={t} />;
    case ProductType.Digital:
    case ProductType.Lead:
        return downloadableDetailsCard(state, dispatch, t);
    case ProductType.Membership:
        return membershipDetailsCard(state, dispatch, t);
    case ProductType.Link:
        return linkDetailsCard(state, dispatch, t);
    default:
        return null;
    }
}

function SchedulableDetailsCard({ state, dispatch, t }: StateDispatchProps & { t: TFunction }) {
    const isBookingEnabled = useEntitlement(StiggFeature.Booking);
    const locationSelectId = useId();
    const schedulingUrlDescriptionId = useId();

    return defaultCard(state.type, t('session-details-card-title'), productStyles[state.type].icon, <>
        {state.type === ProductType.Bundle && (
            <div>
                <Form.Input
                    label={<>{t('sessions-label')}<Form.RequiredIcon /></>}
                    isError={!!state.details.formErrors?.sessions}
                    type='number'
                    value={state.details.form.sessions}
                    onChange={e => dispatch({ type: 'details', field: 'sessions', value: transformToPositiveIntegerOrEmpty(e.target.value) })}
                    size='compact'
                />
                <TranslatedErrorMessage translationId={state.details.formErrors?.sessions} />
            </div>
        )}

        <div>
            <Form.Input
                label={<>{t('duration-label')}<Form.RequiredIcon /><span className='text-secondary-400'>{t('duration-label-more')}</span></>}
                placeholder={t('duration-placeholder')}
                isError={!!state.details.formErrors?.duration}
                type='number'
                value={state.details.form.duration}
                onChange={e => dispatch({ type: 'details', field: 'duration', value: transformToPositiveIntegerOrEmpty(e.target.value) })}
                size='compact'
            />
            <TranslatedErrorMessage translationId={state.details.formErrors?.duration} />
        </div>

        <div>
            <Form.Label htmlFor={locationSelectId}>{t('location-label')}</Form.Label>
            <LocationSelect
                immutableProps={{ size: 'compact' }}
                id={locationSelectId}
                value={state.details.form.locationId}
                onChange={value => dispatch({ type: 'details', field: 'locationId', value })}
            />
        </div>

        <div className='h-px w-full bg-secondary-100 mt-2 mb-1' />

        <h4 className='flex items-center gap-2'>
            {styledIcon(CalendarPhoneIcon, state.type)}
            <span className='text-base leading-3'>{t('scheduling-title')}</span>
        </h4>

        <div className='h-px w-full bg-secondary-100 mt-1' />

        <Form.RadioGroup
            value={state.details.form.scheduling}
            onValueChange={value => dispatch({ type: 'details', field: 'scheduling', value })}
        >
            {/* TODO Enable this for bundles as well. */}
            {state.type === ProductType.Session && (<>
                <Form.RadioItem
                    value='enabled'
                    label={<>{t(`scheduling-enabled-label`)}{state.type !== ProductType.Session && <ProductComingSoonBadge type={state.type} className='ms-1' />}</>}
                    description={t(`scheduling-enabled-description`)}
                    disabled={!isBookingEnabled}
                />

                {!isBookingEnabled && (
                    <div className='flex justify-end'>
                        <UpsellButton text={t('upgrade-to-edit-button')} feature={StiggFeature.Booking} />
                    </div>
                )}
            </>)}

            {[ 'custom', 'disabled' ].map(value => (
                <Form.RadioItem key={value} value={value} label={t(`scheduling-${value}-label`)} description={t(`scheduling-${value}-description`)} />
            ))}
        </Form.RadioGroup>

        {state.details.form.scheduling === 'custom' && (
            <div className='pl-7'>
                <Form.Input
                    label={<>{t('schedulingUrl-label')}<Form.RequiredIcon /></>}
                    labelClassName='sr-only'
                    placeholder={t('schedulingUrl-placeholder')}
                    isError={!!state.details.formErrors?.schedulingUrl}
                    aria-describedby={schedulingUrlDescriptionId}
                    value={state.details.form.schedulingUrl}
                    onChange={e => dispatch({ type: 'details', field: 'schedulingUrl', value: e.target.value })}
                    size='compact'
                />
                <TranslatedErrorMessage translationId={state.details.formErrors?.schedulingUrl} />
                <Form.Description
                    id={schedulingUrlDescriptionId}
                    className='mt-2 flex items-center gap-2 leading-5 text-secondary-400'
                >
                    <CircleInfoIcon size='xs' className='shrink-0' />{t('schedulingUrl-description')}
                </Form.Description>
            </div>
        )}

        {state.details.form.scheduling === 'enabled' && (
            <AvailabilityInput state={state} dispatch={dispatch} />
        )}
    </>);
}

function AvailabilityInput({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('common', { keyPrefix: 'weekday' });
    const { t: tf } = useTranslation('components', { keyPrefix: 'productDetailsForm' });
    const notFilled = Object.values(state.details.form.schedulingDays).every(day => !day.length);

    return (
        <div className='space-y-8'>
            <div className='divide-y divide-secondary-100'>
                {(WEEK_DAYS).map(day => {
                    const dayRanges = state.details.form.schedulingDays[day];
                    const isEmpty = dayRanges.length === 0;

                    const toggleDay = () => dispatch({
                        type: 'details',
                        field: `schedulingDays.${day}`,
                        value: isEmpty ? [ createDefaultAvailabilityRange() ] : [],
                    });

                    return (
                        <div key={day} className='space-y-2 md:space-y-0 md:flex md:items-start md:justify-between md:gap-4 py-2'>
                            <Button onClick={toggleDay} variant={isEmpty ? 'secondary' : 'primary'} size='small' className='w-28'>
                                {t(day)}
                            </Button>

                            {!isEmpty && dayRangesInput(day, dayRanges, dispatch)}
                        </div>
                    );
                })}
            </div>

            {notFilled && (
                <ErrorMessage isOnlyWarning message={tf('availability-not-filled')} />
            )}

            <div className='space-y-4'>
                <SelectBlockTimeModal onSelect={range => dispatch({ type: 'details', field: 'blockedDates', value: [ ...state.details.form.blockedDates, range ] })} />

                <div className='divide-y divide-secondary-100'>
                    {state.details.form.blockedDates.map((blockedDate, index) => blockedDateDisplay(blockedDate, index, state, dispatch))}
                </div>
            </div>
        </div>
    );
}

function dayRangesInput(day: Weekday, dayRanges: DateRange[], dispatch: ProductFormsDispatch) {
    return (
        <div className='space-y-2 sm:space-y-1'>
            {dayRanges.map((timePeriod, index) => (
                <div key={index} className='flex items-center justify-between gap-2'>
                    <div className='space-y-1 sm:space-y-0 sm:flex sm:items-center sm:gap-2'>
                        <div className='relative w-22 border border-secondary-100 rounded-md pl-2'>
                            <DatePicker
                                selected={timePeriod.start}
                                onChange={value => dispatch({ type: 'details', field: `schedulingDays.${day}.${index}.start`, value })}
                                type='time'
                                className='w-20'
                            />

                            <ChevronDownIcon size='xs' className='absolute right-2 top-1.5 pointer-events-none' />
                        </div>
                    </div>

                    <div>-</div>

                    <div className='space-y-1 sm:space-y-0 sm:flex sm:items-center sm:gap-2'>
                        <div className='relative w-22 border border-secondary-100 rounded-md pl-2'>
                            <DatePicker
                                selected={timePeriod.end}
                                onChange={value => dispatch({ type: 'details', field: `schedulingDays.${day}.${index}.end`, value })}
                                type='time'
                                className='w-20'
                            />

                            <ChevronDownIcon size='xs' className='absolute right-2 top-1.5 pointer-events-none' />
                        </div>
                    </div>

                    <div>
                        <Button
                            onClick={() => dispatch({ type: 'details', field: `schedulingDays.${day}`, value: dayRanges.toSpliced(index, 1) })}
                            variant='transparent'
                            size='exact'
                        >
                            <Trash2Icon className='text-secondary-400' />
                        </Button>
                    </div>
                </div>
            ))}

            <div className='flex justify-end'>
                <Button
                    variant='transparent'
                    size='exact'
                    onClick={() => {
                        const value = [ ...dayRanges, createDefaultAvailabilityRange() ];
                        dispatch({ type: 'details', field: `schedulingDays.${day}`, value });
                    }}
                >
                    <PlusIcon className='text-secondary-400' />
                </Button>
            </div>
        </div>
    );
}

function blockedDateDisplay({ start, end }: DateRange, index: number, state: ProductFormsState, dispatch: ProductFormsDispatch) {
    return (
        <div key={index} className='py-2 flex items-center justify-between gap-2'>
            {start.hasSame(end, 'day') ? (
                <div>
                    <div className='sm:hidden'>
                        <div>
                            <DateTimeDisplay dateTime={start} date />
                        </div>

                        <div>
                            <DateTimeDisplay dateTime={start} time />
                            {' - '}
                            <DateTimeDisplay dateTime={end} time />
                        </div>
                    </div>

                    <div className='max-sm:hidden'>
                        <DateTimeDisplay dateTime={start} date />
                        {', '}
                        <DateTimeDisplay dateTime={start} time />
                        {' - '}
                        <DateTimeDisplay dateTime={end} time />
                    </div>
                </div>
            ) : (
                <div className='sm:flex sm:items-center sm:gap-2'>
                    <div>
                        <DateTimeDisplay dateTime={start} date />
                        {', '}
                        <DateTimeDisplay dateTime={start} time />
                    </div>

                    <div className='max-sm:hidden'>-</div>

                    <div>
                        <DateTimeDisplay dateTime={end} date />
                        {', '}
                        <DateTimeDisplay dateTime={end} time />
                    </div>
                </div>
            )}

            <div>
                <Button
                    onClick={() => dispatch({ type: 'details', field: 'blockedDates', value: state.details.form.blockedDates.toSpliced(index, 1) })}
                    variant='transparent'
                    size='exact'
                >
                    <Trash2Icon className='text-secondary-400' />
                </Button>
            </div>
        </div>
    );
}

export function ProductComingSoonBadge({ type, className }: { type: ProductType, className?: string }) {
    const { t } = useTranslation('components', { keyPrefix: 'productTypeTile' });
    const { color, bg } = productStyles[type];

    return (
        <span className={clsx(color, bg, 'py-1 px-2 w-fit rounded-full text-xs', className)}>{t('coming-soon-text')}</span>
    );
}

function downloadableDetailsCard(state: ProductFormsState, dispatch: ProductFormsDispatch, t: TFunction) {
    // This function is called exactly once per render, so this is fine.
    // eslint-disable-next-line
    const fileId = useId();

    return defaultCard(state.type, t(`${state.type}-details-card-title`), productStyles[state.type].icon, <>
        <Form.RadioGroup
            value={state.details.form.digitalType}
            onValueChange={value => dispatch({ type: 'details', field: 'digitalType', value })}
        >
            <Form.RadioItem value='file' label={<>{t(`digitalType-file-label`)}<Form.RequiredIcon /></>} description={t(`digitalType-file-description`)} />

            {state.details.form.digitalType === 'file' && (
                <div className='pl-7'>
                    <FileInput
                        id={fileId}
                        value={state.details.form.file}
                        onChange={value => dispatch({ type: 'details', field: 'file', value })}
                    />
                    <TranslatedErrorMessage translationId={state.details.formErrors?.file} />
                </div>
            )}

            <Form.RadioItem value='url' label={<>{t(`digitalType-url-label`)}<Form.RequiredIcon /></>} description={t(`digitalType-url-description`)} />

            {state.details.form.digitalType === 'url' && (
                <div className='pl-7'>
                    <Form.Input
                        placeholder={t('url-placeholder')}
                        value={state.details.form.url}
                        onChange={e => dispatch({ type: 'details', field: 'url', value: e.target.value })}
                        size='compact'
                    />
                    <TranslatedErrorMessage translationId={state.details.formErrors?.url} />
                </div>
            )}
        </Form.RadioGroup>

        {/* TODO backlogged
        <div>
            limited offer
        </div> */}
    </>);
}

function membershipDetailsCard(state: ProductFormsState, dispatch: ProductFormsDispatch, t: TFunction) {
    return defaultCard(state.type, t('membership-details-card-title'), productStyles[state.type].icon, <>
        <div>
            <Form.Input
                label={t('url-label')}
                placeholder={t('url-placeholder')}
                value={state.details.form.url}
                onChange={e => dispatch({ type: 'details', field: 'url', value: e.target.value })}
                size='compact'
            />
        </div>

        {/* TODO backlogged <div>
            limited offer
        </div> */}
    </>);
}

function linkDetailsCard(state: ProductFormsState, dispatch: ProductFormsDispatch, t: TFunction) {
    return defaultCard(state.type, t('link-details-card-title'), productStyles[state.type].icon, <>
        <div>
            {urlInput(state, dispatch, t, true)}
        </div>

        {state.thumbnail.form.displayType === ProductDisplayType.Callout && (<>
            <FormSwitchWithIcon
                translationPrefix='productDetailsForm.isHideUrl'
                checked={!state.details.form.isHideUrl}
                onCheckedChange={value => dispatch({ type: 'details', field: 'isHideUrl', value: !value })}
                icon={ButtonIcon}
                type={state.type}
            />
        </>)}
    </>);
}

function urlInput(state: ProductFormsState, dispatch: ProductFormsDispatch, t: TFunction, isRequired?: boolean) {
    return (<>
        <Form.Input
            label={(
                <div>
                    <div>
                        {t('url-label')}
                        {isRequired && <Form.RequiredIcon />}
                    </div>
                    <div className='text-secondary-400'>{t('url-description')}</div>
                </div>
            )}
            placeholder={t('url-placeholder')}
            value={state.details.form.url}
            onChange={e => dispatch({ type: 'details', field: 'url', value: e.target.value })}
            size='compact'
        />
        {isRequired && <TranslatedErrorMessage translationId={state.details.formErrors?.url} />}
    </>);
}

function SuccessCard({ state, dispatch }: StateDispatchProps) {
    const tt = useTranslationWithVariant(state.type, 'components', 'productCheckoutForm');
    const successMessageDescriptionId = useId();
    const { appUser: { firstName } } = useMaster();

    return defaultCard(state.type, tt('success-card-title'), MsgSmile2Icon, <>
        <div className='-mt-2'>
            <Form.Textarea
                label={tt('successMessage-label')}
                labelClassName='sr-only'
                placeholder={tt('successMessage-placeholder', { firstName })}
                isError={!!state.details.formErrors?.successMessage}
                aria-describedby={successMessageDescriptionId}
                value={state.checkout.form.successMessage}
                onChange={e => dispatch({ type: 'checkout', field: 'successMessage', value: e.target.value })}
                minRows={2}
            />
            <TranslatedErrorMessage translationId={state.details.formErrors?.successMessage} />
            <Form.Description
                id={successMessageDescriptionId}
                className='mt-2 flex items-center gap-2 leading-5 text-secondary-400'
            >
                <CircleInfoIcon size='xs' className='shrink-0' />{tt('successMessage-description')}
            </Form.Description>
        </div>
    </>);
}

function ProductCheckoutForm({ state, dispatch }: StateDispatchProps) {
    // Some products don't have a checkout page, so the slug isn't needed. It technically still exists (it's generated from the title), but the user can't change it.
    // The same is true for success message and some other fields.
    // However, we don't have to check for that here since this form won't be rendered for those products.

    return (<>
        <SlugCard state={state} dispatch={dispatch} />

        <SuccessCard state={state} dispatch={dispatch} />
    </>);
}

function visibilityCard(state: ProductFormsState, dispatch: ProductFormsDispatch, t: TFunction) {
    return defaultCard(state.type, t('visibility-card-title'), Eye2Icon, <>
        <Form.RadioGroup
            value={state.details.form.visibility}
            onValueChange={value => dispatch({ type: 'details', field: 'visibility', value })}
        >
            {[ 'public', 'private' ].map(value => (
                <Form.RadioItem key={value} value={value} label={t(`visibility-${value}-label`)} description={t(`visibility-${value}-description`)} />
            ))}
        </Form.RadioGroup>
    </>);
}

function SlugCard({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productCheckoutForm' });
    const store = trpc.store.getStore.useQuery();
    const descriptionId = useId();
    const displayUrlPrefix = store.data && routeToDisplayString(routesStore.store.absoluteResolve(store.data));

    const isCustomProductUrlEnabled = useEntitlement(StiggFeature.CustomProductUrl);
    // The slug is optional. If the user doesn't provide one, we will use the suggested one.
    const { suggested, final } = getProductSlug(state, isCustomProductUrlEnabled);

    return defaultCard(state.type, t('slug-card-title'), Link5Icon, <>
        <div className='flex gap-2 items-end'>
            <div className='grow'>
                <Form.Input
                    label={t('slugInit-label')}
                    placeholder={suggested}
                    isError={!!state.checkout.formErrors?.slugInit}
                    aria-describedby={descriptionId}
                    value={state.checkout.form.slugInit}
                    onChange={e => dispatch({ type: 'checkout', field: 'slugInit', value: e.target.value })}
                    size='compact'
                    disabled={!isCustomProductUrlEnabled}
                    className={clsx(!isCustomProductUrlEnabled && 'placeholder:text-secondary-500')}
                />
                <TranslatedErrorMessage translationId={state.checkout.formErrors?.slugInit} />
            </div>

            {!isCustomProductUrlEnabled && (
                <UpsellButton text={t('upgrade-to-edit-button')} className='h-10' feature={StiggFeature.CustomProductUrl} />
            )}
        </div>
        <Form.Description id={descriptionId}>
            <span className='text-secondary-400'>{displayUrlPrefix}/</span>
            <span className='text-secondary-500 break-words'>{final}</span>
        </Form.Description>
    </>);
}
