import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useNotifications from ':frontend/context/NotificationProvider';
import { useUser } from ':frontend/context/UserProvider';
import { trpc } from ':frontend/context/TrpcProvider';
import type { AppUserEdit } from ':utils/entity/user';
import { optionalStringToPut } from ':frontend/utils/common';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { AppUserFE } from ':frontend/types/AppUser';
import { Form, SpinnerButton } from ':components/shadcn';

export type PersonFormData = {
    firstName: string;
    lastName: string;
    phoneNumber: string;
};

export function PersonForm() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general' });
    const { addAlert } = useNotifications();
    const { appUser, setAppUser } = useUser();

    const { register, handleSubmit } = useForm<PersonFormData>({ defaultValues: {
        firstName: appUser.firstName,
        lastName: appUser.lastName,
        phoneNumber: appUser.phoneNumber,
    } });

    const updateAppUserMutation = trpc.user.updateAppUser.useMutation();

    function onPersonSubmit(data: PersonFormData) {
        const edit: AppUserEdit = {
            ...data,
            phoneNumber: optionalStringToPut(data.phoneNumber),
        };
        updateAppUserMutation.mutate(edit, {
            onError: () => {
                setAppUser({ ...appUser });
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                setAppUser(AppUserFE.fromServer(response));
                addAlert(createTranslatedSuccessAlert('pages:settings.general.generic-success'));
            },
        });
    }

    return (
        <Form.Root onSubmit={handleSubmit(onPersonSubmit)} className='space-y-8'>
            <div className='space-y-4'>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <div>
                        <Form.Input
                            label={t('firstName-label')}
                            {...register('firstName')}
                        />
                    </div>

                    <div>
                        <Form.Input
                            label={t('lastName-label')}
                            {...register('lastName')}
                        />
                    </div>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <div>
                        <Form.Input
                            label={t('email-label')}
                            type='email'
                            value={appUser.email}
                            disabled
                            readOnly
                        />
                    </div>

                    <div>
                        <Form.Input
                            label={t('phone-label')}
                            {...register('phoneNumber')}
                        />
                    </div>
                </div>
            </div>

            <div>
                <SpinnerButton
                    type='submit'
                    size='small'
                    className='w-full'
                    isFetching={updateAppUserMutation.isPending}
                >
                    {t('save-button')}
                </SpinnerButton>
            </div>
        </Form.Root>
    );
}
