import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { transformToPriceNegative } from ':utils/math';
import type { EditableProductItem, ProductOrderItemFE } from ':frontend/types/orders/ProductOrderItem';
import { ProductInvoiceItemDisplay, productStyles, renderProductAttributes } from ':components/store/product/ProductCard';
import { itemsDeletable, type OrderFormState, type UseOrderDispatch, type UseOrderState } from './useOrder';
import type { FormPath } from ':frontend/utils/updator';
import { Button, Card, Form } from ':components/shadcn';
import { cn } from ':components/shadcn/utils';
import { PlusIcon, Trash2Icon } from ':components/icons/basic';
import { TaxRateInput } from '../forms/TaxRateInput';
import clsx from 'clsx';
import { taxRateIsZero } from ':utils/money';
import type { OrderDiscount } from ':utils/entity/order';

type OrderItemsListProductProps = Readonly<{
    items: ProductOrderItemFE[];
    discount?: OrderDiscount;
}>;

export function OrderItemsListProduct({ items }: OrderItemsListProductProps) {
    const { t } = useTranslation('components', { keyPrefix: 'orderItemsList' });

    return (
        <div className='space-y-4'>
            <div className='font-semibold text-lg'>{t('product-title')}</div>

            <div className='space-y-2'>
                {items.map(item => (
                    <ProductInvoiceItemDisplay
                        key={item.id}
                        product={item}
                        // Products have always 1 quantity. If that ever changes, we need to change this.
                        price={item.unitPrice}
                        taxRate={item.taxRate}
                    />
                ))}
            </div>
        </div>
    );
}

type OrderItemsListProductFormProps = Readonly<{
    state: UseOrderState;
    form: OrderFormState;
    dispatch: UseOrderDispatch;
}>;

export function OrderItemsListProductForm({ state, form, dispatch }: OrderItemsListProductFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'orderItemsList' });

    const showTax = useMemo(() => state.order.items.some(item => !taxRateIsZero(item.taxRate)), [ state.order.items ]);

    const deletable = itemsDeletable(form);

    return (
        <div className='space-y-4'>
            <div className='font-semibold text-lg'>{t('product-title')}</div>

            <div className='space-y-2'>
                {form.productItems.map((item, index) => (
                    <ProductItemRowForm
                        key={item.id}
                        dispatch={dispatch}
                        item={item}
                        index={index}
                        showTax={showTax}
                        deletable={deletable}
                    />
                ))}
            </div>
        </div>
    );
}

type ProductItemRowFormProps = Readonly<{
    dispatch: UseOrderDispatch;
    item: EditableProductItem;
    index: number;
    showTax: boolean;
    deletable: boolean;
}>;

function ProductItemRowForm({ dispatch, item, index, showTax, deletable }: ProductItemRowFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });
    const { t: to } = useTranslation('components', { keyPrefix: 'orderItemsList' });
    const styles = productStyles[item.type];
    const attributes = renderProductAttributes(item, t);

    function input(field: FormPath<EditableProductItem>, value: unknown) {
        dispatch({ type: 'input', field: `productItems.${index}.${field}`, value });
    }

    return (
        <Card className='flex items-center gap-4'>
            <div className='min-w-0 grow'>
                <div className='flex items-center justify-between sm:gap-4'>
                    <h3 className={cn('min-w-0 w-full flex items-center gap-2')}>
                        {styles.icon({ size: 22, className: 'shrink-0' })}

                        <div className={clsx(styles.color, 'text-lg/5 font-semibold truncate', item.isDeleted && 'line-through')}>
                            {item.title}
                        </div>
                    </h3>

                    <div className='flex items-center gap-4'>
                        {showTax && (
                            <div className='w-20'>
                                <TaxRateInput
                                    size='compact'
                                    value={item.taxRate}
                                    onChange={value => input('taxRate', value)}
                                    disabled={item.isDeleted}
                                />
                            </div>
                        )}

                        <div className='w-24'>
                            <Form.Input
                                type='number'
                                size='compact'
                                value={item.unitPriceInDecimal}
                                onChange={e => input('unitPriceInDecimal', transformToPriceNegative(e.target.value))}
                                disabled={item.isDeleted}
                            />
                        </div>
                    </div>
                </div>

                {attributes.length > 0 && (<>
                    <div className='my-4 w-full h-px bg-secondary-100' />

                    <div className='max-sm:space-y-2 sm:flex sm:items-center sm:gap-4'>
                    {...attributes}
                    </div>
                </>)}
            </div>

            {!item.isDeleted ? (
                <Button variant='transparent' size='exact' className='shrink-0' disabled={!deletable} onClick={() => input('isDeleted', true)} aria-label={to('delete-aria', { name: item.title })}>
                    <Trash2Icon />
                </Button>
            ) : (
                <Button variant='transparent' size='exact' className='shrink-0' onClick={() => input('isDeleted', false)} aria-label={to('add-aria', { name: item.title })}>
                    <PlusIcon />
                </Button>
            )}
        </Card>
    );
}

