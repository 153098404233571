import { CircleMinusIcon, DiscountIcon } from ':components/icons/basic';
import { Button, Form } from ':components/shadcn';
import type { OrderDiscount } from ':utils/entity/order';
import { between, percentToFloat, transformToPrice } from ':utils/math';
import { useTranslation } from 'react-i18next';

type DiscountInputProps = Readonly<{
    discount: FormDiscount | undefined;
    onChange: (discount?: FormDiscount) => void;
}>;

export function DiscountInput({ discount, onChange }: DiscountInputProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'directSale.discount' });

    function addDiscount() {
        const amountInPercent = 10;
        onChange({ title: t('title-default', { amountInPercent }), amountInPercent });
    }

    function handleChange(value: string) {
        if (!discount)
            return;

        const transformed = transformToPrice(value);
        const amountInPercent = transformed === '' ? '' : between(transformed, 0, 100);
        onChange({ ...discount, amountInPercent });
    }

    if (!discount) {
        return (
            <Button variant='secondary' size='medium' className='w-full' onClick={() => addDiscount()}>
                <DiscountIcon />{t('add-button')}
            </Button>
        );
    }

    return (
        <div className='flex gap-2 items-start'>
            <div className='grow max-md:space-y-2 md:flex md:items-center md:gap-2'>
                <div className='relative shrink-0 md:w-24'>
                    <Form.Input
                        size='compact'
                        className='bg-primary-50'
                        label={t('amount-label')}
                        labelClassName='sr-only'
                        type='number'
                        value={discount.amountInPercent}
                        onChange={e => handleChange(e.target.value)}
                        autoFocus
                    />
                    <span className='absolute right-4 top-3'>%</span>
                </div>

                <div className='grow'>
                    <Form.Input
                        size='compact'
                        className='bg-primary-50'
                        label={t('title-label')}
                        labelClassName='sr-only'
                        value={discount.title}
                        onChange={e => onChange({ ...discount, title: e.target.value })}
                        autoFocus
                    />
                </div>
            </div>

            <div className='-ml-2 -mr-4 h-10 flex items-center'>
                <Button
                    variant='transparent'
                    size='tiny'
                    onClick={() => onChange(undefined)}
                    aria-label={t('remove-button')}
                >
                    <CircleMinusIcon />
                </Button>
            </div>
        </div>
    );
}

export type FormDiscount = {
    title: string;
    amountInPercent: number | '';
};

export function formDiscountToDiscount(input: FormDiscount | undefined): OrderDiscount | undefined {
    if (!input)
        return undefined;

    const percents = transformToPrice(input?.amountInPercent);
    if (percents === '' || percents === 0)
        return undefined;

    return {
        title: input.title,
        rate: percentToFloat(percents),
    };
}
