import { z } from 'zod';
import { zLocaleCode, zCountryCode, zTimezoneCode } from ':utils/i18n';
import { zId, type Id } from ':utils/id';
import { zInvoicingOverrideOutput, zSubscriberSettingsUpsert, zSubscriberSettingsOutput, zInvoicingOverrideUpdate } from './invoicing';
import { zCanonizedEmail } from ':utils/forms';
import { compareStringsAscii, zColor } from ':utils/common';
import { zDateTime } from ':utils/dateTime';

export type ClientAccessFromServer = {
    id: Id;
    /** AppUser Id */
    appUser: Id;
    access: ClientAccessType;
}

export enum ClientAccessType {
    owner = 'owner',
    readOnly = 'readOnly',
}

export enum ClientState {
    lead = 'lead',
    active = 'active',
    inactive = 'inactive',
}

export type ClientInfoOutput = z.infer<typeof zClientInfoOutput>;
export const zClientInfoOutput = z.object({
    id: zId,
    email: z.string(),
    name: z.string(),
    phoneNumber: z.string().optional(),
    createdAt: zDateTime,
    state: z.nativeEnum(ClientState),
    isDeleted: z.boolean(),
    invoicingProfile: zId,
    locale: zLocaleCode,
    dueDays: z.number().optional(),
    tags: z.array(zId),
});

export type ClientOutput = z.infer<typeof zClientOutput>;
export const zClientOutput = zClientInfoOutput.extend({
    timezone: zTimezoneCode,
    country: zCountryCode,
    note: z.string().optional(),
    subscriberSettings: zSubscriberSettingsOutput,
    invoicingOverride: zInvoicingOverrideOutput,
    access: z.nativeEnum(ClientAccessType).optional(),
});

export type ClientInit = z.infer<typeof zClientInit>;
export const zClientInit = z.object({
    email: zCanonizedEmail,
    name: z.string(),
    phoneNumber: z.string().optional(),
    locale: zLocaleCode,
    timezone: z.string(),
    country: zCountryCode,
    note: z.string().optional(),
    subscriberSettings: zSubscriberSettingsUpsert.optional(),
    /** If undefined, a default value will be used. */
    state: z.nativeEnum(ClientState).optional(),
});

export type ClientOrContactInit = z.infer<typeof zClientOrContactInit>;
export const zClientOrContactInit = z.union([
    z.object({
        id: zId,
    }),
    z.object({
        contact: zClientInit,
    }),
]);

export type ClientWithProfileInit = z.infer<typeof zClientWithProfileInit>;
export const zClientWithProfileInit = zClientInit.extend({
    invoicingProfileId: zId.optional(),
});

export type ClientTagOutput = z.infer<typeof zClientTagOutput>;
export const zClientTagOutput = z.object({
    id: zId,
    name: z.string(),
    color: zColor,
});

export type ClientTagInit = z.infer<typeof zClientTagInit>;
export const zClientTagInit = z.object({
    clientId: zId.optional(),
}).merge(zClientTagOutput.pick({ name: true, color: true }));


export type ClientTagEdit = z.infer<typeof zClientTagEdit>;
export const zClientTagEdit = z.object({
    id: zId,
    name: z.string().optional(),
    color: zColor.optional(),
});

export function sortClientTags(tags: ClientTagOutput[]): ClientTagOutput[] {
    return tags.sort((a, b) => compareStringsAscii(a.id, b.id));
}

export type ClientStatsOutput = z.infer<typeof zClientStatsOutput>;
export const zClientStatsOutput = z.object({
    orderTotal: z.number(),
    orderCount: z.number(),
});

/** Like PATCH. */
export type ClientUpdate = z.infer<typeof zClientUpdate>;
export const zClientUpdate = z.object({
    name: z.string(),
    email: z.string(),
    phoneNumber: z.string().nullable(),
    note: z.string().nullable(),
    subscriber: zSubscriberSettingsUpsert,
    override: zInvoicingOverrideUpdate,
    timezone: zTimezoneCode,
    // Hidden for the same reason as AppUser locale.
    // locale: zLocaleCode,
    country: zCountryCode,
    invoicingProfileId: zId,
    state: z.nativeEnum(ClientState),
}).partial().extend({
    id: zId,
});

export type ExportClientsQuery = z.infer<typeof zExportClientsQuery>;
export const zExportClientsQuery = z.object({
    state: z.array(z.nativeEnum(ClientState)).optional(),
    tags: z.array(zId).optional(),
    createdAtStart: zDateTime.optional(),
    createdAtEnd: zDateTime.optional(),
});
