import { type FilterDefinition, type FilterFunction, type FilterMenuProps } from './FilterRow';
import { type DateTime } from 'luxon';
import { DateTimeDisplay } from ':components/custom/DateTimeDisplay';
import { useTranslation } from 'react-i18next';
import { DatePicker } from ':frontend/components/forms';
import { Button, DropdownMenu } from ':components/shadcn';
import { CalendarCheckInIcon, CalendarCheckOutIcon, XmarkIcon } from ':components/icons/basic';

const createdAtStartFilterName = 'createdAtStart';
const createdAtEndFilterName = 'createdAtEnd';

type FilterState = DateTime | undefined;

function createRowMenu(name: typeof createdAtStartFilterName | typeof createdAtEndFilterName) {
    return ({ state, setState }: FilterMenuProps<FilterState>) => {
        const { t } = useTranslation('common', { keyPrefix: `filters.${name}` });

        return (
            <DropdownMenu.Root>
                <div className='relative'>
                    <DropdownMenu.Trigger asChild>
                        <Button variant='outline' className={state ? 'border-primary text-primary hover:border-primary pr-10' : undefined}>
                            <div className='flex items-center gap-2'>
                                {name === createdAtEndFilterName && <CalendarCheckInIcon size='md' />}
                                {name === createdAtStartFilterName && <CalendarCheckOutIcon size='md' />}

                                {state && <DateTimeDisplay date dateTime={state} />}
                                {!state && t('menu-button')}
                            </div>
                        </Button>
                    </DropdownMenu.Trigger>

                    {state && (
                        <div className='absolute top-1/2 -translate-y-1/2 right-4 text-primary cursor-pointer hover:opacity-80' onClick={() => setState(undefined)}>
                            <XmarkIcon size='md' />
                        </div>
                    )}
                </div>

                <DropdownMenu.Content
                    className='p-0 overflow-visible border-0 rounded-none bg-transparent shadow-none outline-none'
                    side='bottom'
                >
                    <DatePicker
                        selected={state}
                        onChange={setState}
                        type='date'
                        inline
                    />
                </DropdownMenu.Content>
            </DropdownMenu.Root>
        );
    };
}

type FilterEntity = { createdAt: DateTime };

function createStartFilterFunction(state: FilterState): FilterFunction<FilterEntity> {
    if (!state)
        return () => true;

    const start = state.startOf('day');

    return (data: FilterEntity) => start <= data.createdAt;
}

function createEndFilterFunction(state: FilterState): FilterFunction<FilterEntity> {
    if (!state)
        return () => true;

    const end = state.startOf('day').plus({ days: 1 });

    return (data: FilterEntity) => data.createdAt < end;
}

function startToServer(state: FilterState): DateTime | undefined {
    if (!state)
        return undefined;
    return state.startOf('day').toUTC();
}

function endToServer(state: FilterState): DateTime | undefined {
    if (!state)
        return undefined;
    return state.plus({ days: 1 }).startOf('day').toUTC();
}

const defaultState = undefined;

export const CreatedAtStartFilter: FilterDefinition<FilterState, undefined, FilterEntity, DateTime> = {
    defaultState,
    name: createdAtStartFilterName,
    RowMenu: createRowMenu(createdAtStartFilterName),
    createFilterFunction: createStartFilterFunction,
    toServer: startToServer,
};

export const CreatedAtEndFilter: FilterDefinition<FilterState, undefined, FilterEntity, DateTime> = {
    defaultState,
    name: createdAtEndFilterName,
    RowMenu: createRowMenu(createdAtEndFilterName),
    createFilterFunction: createEndFilterFunction,
    toServer: endToServer,
};
