import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { getEnumValues } from ':utils/common';
import { useMaster, type MasterContext } from ':frontend/context/UserProvider';
import { NoBankAccountModal } from './NoBankAccountModal';
import { NoStripeModal } from './NoStripeModal';
import { type Id } from ':utils/id';
import { isCurrencySupported, PaymentMethod } from ':utils/entity/invoicing';
import { BankingMobileIcon, LockIcon } from ':components/icons/basic';
import { paymentIcons } from ':components/icons/logos';
import { Button, Form } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { isPaymentMethodSupported } from ':frontend/components/orders/checkout/useCheckout';
import { StripeConnectionState } from ':utils/entity/team';
import { StiggFeature } from ':utils/lib/stigg';
import { UpsellButton } from ':frontend/pages/settings/Subscriptions';
import { useEntitlement } from ':frontend/lib/stigg';

// this file could be simplified a lot because previously PaymentMethodRadio was in :components for an unknown reason
// it got moved back here because of the upsell functionality
// This file should be simplified and tested ASAP because it's 💰💰💰💰MONEY💵FLOW💸💸🤑🤑 and the complexity causes bugs

type PaymentMethodSelectProps = Readonly<{
    value?: PaymentMethod;
    onChange: (value: PaymentMethod) => void;
    currencies?: Id[];
    options?: PaymentMethod[];
}>;

export function PaymentMethodSelect({ value, onChange, currencies, options }: PaymentMethodSelectProps) {
    const [ state, dispatch ] = usePaymentMethods(options, currencies);
    const isBankTransferEnabled = useEntitlement(StiggFeature.BankTransfer);
    const optionComponents = useMemo(() => state.options.map(pm => paymentMethodToTabOption(pm, state, dispatch, isBankTransferEnabled)), [ state, dispatch, isBankTransferEnabled ]);
    const closeStripeModal = useCallback(() => dispatch({ type: 'stripeModal', isOpen: false }), [ dispatch ]);

    return (<>
        <PaymentMethodRadio
            value={value}
            onChange={onChange}
            options={optionComponents}
        />
        {state.isBankModalNeeded && (
            <NoBankAccountModal
                show={state.isBankModalOpen}
                onClose={() => dispatch({ type: 'bankModal', isOpen: false })}
                currencies={state.unsupportedCurrencies}
            />
        )}
        {state.isStripeModalNeeded && (
            <NoStripeModal
                show={state.isStripeModalOpen}
                onClose={closeStripeModal}
            />
        )}
    </>);
}

function paymentMethodToTabOption(method: PaymentMethod, state: PaymentMethodsState, dispatch: PaymentMethodsDispatch, isBankTransferEnabled: boolean): PaymentMethodRadioOption {
    const isDisabled = !state.enabledOptions.includes(method);
    const output: PaymentMethodRadioOption = {
        method,
        isDisabled,
        onEnableClick: undefined,
        isComingSoon: method === PaymentMethod.paypal,
        upsellFeature: (!isDisabled && method === PaymentMethod.bankTransfer && !isBankTransferEnabled) ? StiggFeature.BankTransfer : undefined,
    };
    console.log('paymentMethodToTabOption', method, state, isBankTransferEnabled, isDisabled, output);

    if (method === PaymentMethod.bankTransfer)
        output.onEnableClick = () => dispatch({ type: 'bankModal', isOpen: true });
    if (method === PaymentMethod.stripe)
        output.onEnableClick = () => dispatch({ type: 'stripeModal', isOpen: true });

    return output;
}

function usePaymentMethods(
    options?: PaymentMethod[],
    currencies?: Id[],
) {
    const masterContext = useMaster();
    const initialState = useMemo(() => computeInitialState({ masterContext, currencies, options }), [ masterContext, currencies, options ]);
    const [ state, dispatch ] = useReducer(paymentMethodsReducer, initialState);

    useEffect(() => {
        dispatch({ type: 'input', input: { masterContext, currencies, options } });
    }, [ masterContext, currencies, options ]);

    return [ state, dispatch ] as const;
}

type PaymentMethodsInput = {
    options?: PaymentMethod[];
    currencies?: Id[];
    masterContext: MasterContext;
};

type PaymentMethodsState = {
    input: PaymentMethodsInput;
    unsupportedCurrencies: Id[];
    options: PaymentMethod[];
    enabledOptions: PaymentMethod[];
    isBankModalOpen: boolean;
    isBankModalNeeded: boolean;
    isStripeModalOpen: boolean;
    isStripeModalNeeded: boolean;
};

function computeInitialState(input: PaymentMethodsInput): PaymentMethodsState {
    return {
        input,
        ...computeOptions(input),
        isBankModalOpen: false,
        isStripeModalOpen: false,
    };
}

function computeOptions(input: PaymentMethodsInput, previousState?: PaymentMethodsState) {
    const unsupportedCurrencies = input.currencies?.filter(id => !isCurrencySupported(input.masterContext.bankAccounts, id)) ?? [];
    const options = input.options ?? getEnumValues(PaymentMethod);
    const enabledOptions = options.filter(pm => isPaymentMethodSupported(pm, input.currencies ?? [], input.masterContext));

    // If the modal was needed in the previous state, we include it as well so that it doesn't just disappear.
    const isBankModalNeeded = unsupportedCurrencies.length > 0 || !!previousState?.isBankModalNeeded;

    return {
        unsupportedCurrencies,
        options,
        enabledOptions,
        isBankModalNeeded,
        isStripeModalNeeded: (input.masterContext.team.stripeConnectionState !== StripeConnectionState.connected) || !!previousState?.isStripeModalNeeded,
    };
}

type PaymentMethodAction = InputAction | ModalAction;
type PaymentMethodsDispatch = (action: PaymentMethodAction) => void;

type InputAction = {
    type: 'input';
    input: PaymentMethodsInput;
};

type ModalAction = {
    type: 'bankModal' | 'stripeModal';
    isOpen: boolean;
};

function paymentMethodsReducer(state: PaymentMethodsState, action: PaymentMethodAction) {
    console.log('Reduce:', state, action);
    switch (action.type) {
    case 'input': return {
        ...state,
        input: action.input,
        ...computeOptions(action.input, state),
    };
    case 'bankModal': return { ...state, isBankModalOpen: action.isOpen };
    case 'stripeModal': return { ...state, isStripeModalOpen: action.isOpen };
    }
}


export type PaymentMethodRadioOption = {
    method: PaymentMethod;
    isComingSoon?: boolean;
    /** If true, will show an enable button. */
    isDisabled?: boolean;
    /** If defined, will be called on enable button click. */
    onEnableClick?: () => void;
    /** If defined, will show an upsell button which also handles the UpsellModal. */
    upsellFeature?: StiggFeature;
};

type PaymentMethodRadioProps = Readonly<{
    value?: PaymentMethod;
    options: PaymentMethodRadioOption[];
    onChange: (value: PaymentMethod) => void;
}>;

export function PaymentMethodRadio({ value, options, onChange }: PaymentMethodRadioProps) {
    const { t } = useTranslation('components', { keyPrefix: 'paymentMethodSelect' });

    return (
        <Form.RadioGroup
            className='grid grid-cols-1 md:grid-flow-col md:auto-cols-fr gap-4'
            value={value}
            onValueChange={onChange}
        >
            {options.map(option => {
                const { method, isDisabled, upsellFeature } = option;
                if (method === PaymentMethod.noInvoice) {
                    // This should not happen.
                    console.error('PaymentMethodRadio: noInvoice is not supported');
                    return null;
                }

                if (isDisabled || upsellFeature) {
                    return (
                        <div key={method} className='flex flex-col items-center gap-2'>
                            <div className='self-stretch m-[2px] h-28 rounded-md flex flex-col items-center justify-center gap-2 outline outline-2 outline-secondary-200'>
                                {paymentMethodIcons[method]()}

                                <OptionButton option={option} />
                            </div>
                            <span className='leading-5 text-secondary-300'>{t(`${method}-title`)}</span>
                        </div>
                    );
                }

                return (
                    <Form.CustomRadioItem
                        key={method}
                        value={method}
                        direction='col'
                        label={t(`${method}-title`)}
                    >
                        <div className='h-28 rounded-md flex flex-col items-center justify-center [&:not(:hover)]:outline-secondary-200'>
                            {paymentMethodIcons[method]()}
                        </div>
                    </Form.CustomRadioItem>
                );
            })}
        </Form.RadioGroup>
    );
}

function OptionButton({ option }: { option: PaymentMethodRadioOption }) {
    const { t } = useTranslation('components', { keyPrefix: 'paymentMethodSelect' });

    if (option.isComingSoon) {
        return (
            <div className='px-4 h-7 flex items-center justify-center gap-2 text-sm text-[#0C3FE6] bg-[#EBF3FF] rounded-full select-none'>
                <LockIcon size={16} />
                <span>{t('coming-soon')}</span>
            </div>
        );
    }

    if (option.upsellFeature) {
        return (
            <UpsellButton size='tiny' feature={option.upsellFeature} />
        );
    }

    if (option.isDisabled && option.onEnableClick) {
        return (
            <Button variant='white' size='tiny' onClick={option.onEnableClick}>
                {t(`${option.method}-enable-button`)}
            </Button>
        );
    }
}

const paymentMethodIcons = {
    [PaymentMethod.stripe]: () => <paymentIcons.StripeIcon className='w-17 h-8' />,
    [PaymentMethod.paypal]: () => <paymentIcons.PayPalIcon className='w-17 h-8' />,
    [PaymentMethod.bankTransfer]: () => <BankingMobileIcon size={36} />,
} as const;
