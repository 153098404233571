import { createContext, type ReactNode, useContext, useState, useMemo, type SetStateAction, type Dispatch, type ComponentType, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppUserFE } from ':frontend/types/AppUser';
import { setLocale, setTimezone } from ':frontend/types/i18n';
import { GoogleUser } from ':frontend/types/GoogleUser';
import { extract } from ':frontend/hooks/api/utils';
import { TeamMemberFE, TeamMembers } from ':frontend/types/Team';
import { trpc } from './TrpcProvider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from ':frontend/context/AuthProvider';
import { routesFE } from ':utils/routes';
import { api } from ':frontend/utils/api';
import * as Sentry from '@sentry/react';
import Loading from ':frontend/pages/Loading';
import { TeamMemberRole, type TeamOutput } from ':utils/entity/team';
import { fixLocale } from ':utils/i18n';
import type { OnboardingStateOutput } from ':utils/entity/user';
import { sortClientTags, type ClientTagOutput } from ':utils/entity/client';
import type { BankAccountOutput, InvoicingProfileOutput } from ':utils/entity/invoicing';
import type { AppUserSettingsOutput, TeamSettingsOutput } from ':utils/entity/settings';
import { StiggProvider, useStiggContext } from '@stigg/react-sdk';
import { env } from ':env';
import { sleep } from ':frontend/utils/common';

const authorizedContext = createContext<UserContext | undefined>(undefined);

type UserProviderProps = Readonly<{
    children: ReactNode;
    role: TeamMemberRole;
}>;

export function UserProvider({ children, role }: UserProviderProps) {
    const appUser = trpc.user.getAppUser.useQuery();
    const appUserFE = useMemo(() => {
        if (!appUser.data)
            return;

        const output = AppUserFE.fromServer(appUser.data);
        Sentry.setUser({ id: output.id, email: output.email });

        return output;
    }, [ appUser.data ]);

    const appUserSettings = trpc.user.getAppUserSettings.useQuery().data;

    useEffect(() => {
        if (appUserSettings?.locale)
            setLocale(fixLocale(appUserSettings.locale));
    }, [ appUserSettings?.locale ]);

    useEffect(() => {
        if (appUserSettings?.timezone)
            setTimezone(appUserSettings.timezone);
    }, [ appUserSettings?.timezone ]);

    const team = trpc.team.getTeam.useQuery().data;

    const teamMembers = trpc.team.getTeamMembers.useQuery();
    const teamMembersFE = useMemo(() => teamMembers.data && appUserFE && new TeamMembers(appUserFE, teamMembers.data.map(TeamMemberFE.fromServer)), [ appUserFE, teamMembers.data ]);

    const onboarding = trpc.user.getOnboarding.useQuery().data;

    const googleUser = useGoogleUser();

    const commonDefaults: CommonDefaults | undefined = useMemo(() => {
        if (!team || !teamMembersFE || !appUserFE || !appUserSettings || !onboarding || googleUser === undefined)
            return;

        return {
            role: TeamMemberRole.scheduler,
            team,
            teamMembers: teamMembersFE,
            appUser: appUserFE,
            settings: appUserSettings,
            onboarding,
            googleUser: googleUser ?? undefined,
        };
    }, [ team, teamMembersFE, appUserFE, appUserSettings, onboarding, googleUser ]);

    return  (

        <StiggProvider apiKey={env.VITE_STIGG_API_KEY}>
            {role === TeamMemberRole.scheduler ? (
                <SchedulerProvider defaults={commonDefaults}>
                    {children}
                </SchedulerProvider>
            ) : (
                <MasterProvider role={role} defaults={commonDefaults}>
                    {children}
                </MasterProvider>
            )}
        </StiggProvider>
    );
}

type CommonDefaults = SchedulerDefaults

type SchedulerProviderProps = Readonly<{
    defaults: CommonDefaults | undefined;
    children: ReactNode;
}>;

function SchedulerProvider({ defaults, children }: SchedulerProviderProps) {
    if (!defaults)
        return <Loading />;

    return (
        <UserProviderLoaded defaults={defaults}>
            {children}
        </UserProviderLoaded>
    );
}

type MasterProviderProps = Readonly<{
    role: typeof TeamMemberRole.master | typeof TeamMemberRole.freelancer;
    defaults: CommonDefaults | undefined;
    children: ReactNode;
}>;

function MasterProvider({ role, defaults, children }: MasterProviderProps) {
    const teamSettings = trpc.team.getTeamSettings.useQuery().data;
    const profiles = trpc.invoicing.getInvoicingProfiles.useQuery().data;
    const bankAccounts = trpc.invoicing.getBankAccounts.useQuery().data;

    const clientTags = trpc.$client.getClientTags.useQuery();
    const clientTagsFE = useMemo(() => clientTags.data && sortClientTags(clientTags.data), [ clientTags.data ]);

    const masterDefaults: MasterState | undefined = useMemo(() => {
        if (!defaults || !teamSettings || !profiles || !bankAccounts || !clientTagsFE)
            return;

        return {
            ...defaults,
            role,
            teamSettings,
            profiles,
            bankAccounts,
            clientTags: clientTagsFE,
        };
    }, [ defaults, role, teamSettings, profiles, bankAccounts, clientTagsFE ]);

    if (!masterDefaults)
        return <Loading />;

    return (
        <UserProviderLoaded defaults={masterDefaults}>
            {children}
        </UserProviderLoaded>
    );
}

type UserState = SchedulerState | MasterState;

type UserProviderLoadedProps = Readonly<{
    children: ReactNode;
    defaults: UserState;
}>;

function UserProviderLoaded({ children, defaults }: UserProviderLoadedProps) {
    // TODO This is wrong. The state should be updated when the defaults change - e.g., when we invalidate a query ...
    // We can also try to remove as many parts of the state as possible and move them to their respective components. We can create hooks for them ...
    // Here is an idea - hook that returns value and setValue. The setValue takes the raw data from server (so it can be used for mutations), but returns the FE value (so it can be used for alerts).
    const [ state, setState ] = useState<UserState>(defaults);

    useEffect(() => {
        // TODO This is just temporary.
        setState(defaults);
    }, [ defaults ]);

    // Yes, this is very gay. Unfortunatelly, we can't pass the customerId directly to the stigg provider, because they don't await the setCustomerId function.
    // Whis then throws errors at all places that use the stigg hooks. The set function shouldn't be async in the first place, but it is what it is.

    const { stigg } = useStiggContext();
    const [ isStiggCustomerLoaded, setIsStiggCustomerLoaded ] = useState(false);
    const stiggCustomerId = state.team.stiggCustomerId;

    useEffect(() => {
        (async () => {
            await stigg.setCustomerId(stiggCustomerId);
            setIsStiggCustomerLoaded(true);

            // this is super ugly, but sometimes it happens that the stigg state is old after registration
            for (let i = 0; i < 10; i++) {
                await sleep(1200);
                await stigg.refresh();
            }
        })();
    }, [ stigg, stiggCustomerId ]);

    const setters = useMemo(() => ({
        setAppUser: (input: SetStateAction<AppUserFE>) => setState(state => ({
            ...state, appUser: extract(input, state.appUser),
        })),
        setSettings: (input: SetStateAction<AppUserSettingsOutput>) => setState(state => {
            const settings = extract(input, state.settings);
            // Synchronize locale with the settings.
            setTimezone(settings.timezone);
            setLocale(fixLocale(settings.locale));

            return { ...state, settings };
        }),
        setOnboarding: (input: SetStateAction<OnboardingStateOutput>) => setState(state => ({
            ...state, onboarding: extract(input, state.onboarding),
        })),
        setTeam: (input: SetStateAction<TeamOutput>) => setState(state => state && ({
            ...state, team: extract(input, state.team),
        })),
        setTeamSettings: (input: SetStateAction<TeamSettingsOutput>) => setState(state => ({
            ...state, teamSettings: extract(input, (state as MasterState).teamSettings),
        })),
        setTeamMembers: (input: SetStateAction<TeamMembers>) => setState(state => ({
            ...state, teamMembers: extract(input, state.teamMembers),
        })),
        setProfiles: (input: SetStateAction<InvoicingProfileOutput[]>) => setState(state => {
            const profiles = extract(input, (state as MasterState).profiles);
            return { ...state, profiles };
        }),
        setBankAccounts: (input: SetStateAction<BankAccountOutput[]>) => setState(state => ({
            ...state, bankAccounts: extract(input, (state as MasterState).bankAccounts),
        })),
        setClientTags: (input: SetStateAction<ClientTagOutput[]>) => setState(state => ({
            ...state, clientTags: extract(input, (state as MasterState).clientTags),
        })),
    }), []);

    const value = useMemo(() => ({
        ...state,
        ...setters,
    }), [ state, setters ]);

    if (!isStiggCustomerLoaded)
        return <Loading />;

    return (
        <authorizedContext.Provider value={value}>
            {children}
        </authorizedContext.Provider>
    );
}

type SchedulerState = {
    role: typeof TeamMemberRole.scheduler;
    team: TeamOutput;
    teamMembers: TeamMembers;
    appUser: AppUserFE;
    settings: AppUserSettingsOutput;
    onboarding: OnboardingStateOutput;
    googleUser: GoogleUser | undefined;
};

type SchedulerDefaults = SchedulerState;
export type SchedulerContext = SchedulerState & {
    setTeam: Dispatch<SetStateAction<TeamOutput>>;
    setAppUser: Dispatch<SetStateAction<AppUserFE>>;
    setSettings: Dispatch<SetStateAction<AppUserSettingsOutput>>;
    setOnboarding: Dispatch<SetStateAction<OnboardingStateOutput>>;
};

/**
 * Returns data only for user role scheduler. Throws error otherwise.
 */
export function useScheduler(): SchedulerContext  {
    const context = useContext(authorizedContext);
    if (context === undefined)
        throw new Error('useScheduler must be used within an AuthProvider');

    const schedulerContext = toScheduler(context);
    if (!schedulerContext)
        throw new Error(`useScheduler can't be used with a ${context.role} role`);

    return schedulerContext;
}

export function toScheduler(context: UserContext): SchedulerContext | undefined {
    return context.role === TeamMemberRole.scheduler ? context : undefined;
}

type MasterState = {
    role: typeof TeamMemberRole.master | typeof TeamMemberRole.freelancer;
    team: TeamOutput;
    teamMembers: TeamMembers;
    appUser: AppUserFE;
    settings: AppUserSettingsOutput;
    onboarding: OnboardingStateOutput;
    googleUser: GoogleUser | undefined;
    teamSettings: TeamSettingsOutput;
    profiles: InvoicingProfileOutput[];
    bankAccounts: BankAccountOutput[];
    clientTags: ClientTagOutput[];
};

export type MasterContext = MasterState & {
    setTeam: Dispatch<SetStateAction<TeamOutput>>;
    setTeamSettings: Dispatch<SetStateAction<TeamSettingsOutput>>;
    setTeamMembers: Dispatch<SetStateAction<TeamMembers>>;
    setProfiles: Dispatch<SetStateAction<InvoicingProfileOutput[]>>;
    setBankAccounts: Dispatch<SetStateAction<BankAccountOutput[]>>;
    setClientTags: Dispatch<SetStateAction<ClientTagOutput[]>>;
    setAppUser: Dispatch<SetStateAction<AppUserFE>>;
    setSettings: Dispatch<SetStateAction<AppUserSettingsOutput>>;
    setOnboarding: Dispatch<SetStateAction<OnboardingStateOutput>>;
};

/**
 * Returns data only for user roles master or freelancer. Throws error otherwise.
 */
export function useMaster(): MasterContext {
    const context = useContext(authorizedContext);
    if (context === undefined)
        throw new Error('useMaster must be used within an AuthProvider');

    const masterContext = toMaster(context);
    if (!masterContext)
        throw new Error(`useMaster can't be used with a ${context.role} role`);

    return masterContext;
}

export function toMaster(context: UserContext): MasterContext | undefined {
    return (context.role === TeamMemberRole.master || context.role === TeamMemberRole.freelancer) ? context : undefined;
}

export function masterComponent<TProps>(Component: ComponentType<TProps>): ComponentType<TProps> {
    return function MasterComponentWrapper(props: TProps) {
        const masterContext = toMaster(useUser());
        if (!masterContext)
            return null;

        return <Component {...props} masterContext={masterContext} />;
    };
}

export type UserContext = SchedulerContext | MasterContext;

export function useUser(): UserContext {
    const context = useContext(authorizedContext);
    if (context === undefined)
        throw new Error('useUser must be used within an AuthProvider');

    return context;
}

async function fetchGoogleUser() {
    const response = await api.google.getUserInfo();
    if (!response.status)
        throw new Error('Google user info fetch failed');

    const googleUser = GoogleUser.fromServer(response.data);
    return googleUser;
}

function useGoogleUser() {
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    const { auth } = useAuth();
    const shouldRefreshGoogle = !!searchParams.get('refresh-google');
    const shouldFetchGoogle = !!api.google.authorizer.getAuthorizationHeader();
    const googleQuery = useQuery({ queryKey: [ 'google-user' ], queryFn: fetchGoogleUser, enabled: shouldFetchGoogle });

    async function refreshGoogle() {
        await auth.refreshAccessToken();
        navigate(routesFE.integrations.path, { replace: true });
    }

    useEffect(() => {
        if (shouldRefreshGoogle)
            refreshGoogle();
    }, []);

    if (googleQuery.data)
        return googleQuery.data;

    if (shouldRefreshGoogle)
        return undefined;

    if (shouldFetchGoogle && googleQuery.isFetching)
        return undefined;

    return null;
}
