import { getCommonProps, type IconProps, type IconType } from './common';

export const SessionProductIcon = (props: IconProps) => (<svg {...getCommonProps(props, 22)}>
    <path d='M19.317 16.254c.334-.101.503-.413.446-.672zm0 0a12.9 12.9 0 0 1-4.86.504 4.45 4.45 0 0 0 .02-2.024m4.84 1.52-4.84-1.52m0 0a8.2 8.2 0 0 0-.762-2.083m.762 2.082-.762-2.082m0 0a4.28 4.28 0 0 1 6.048 2.931zm3.702-6.539a1.833 1.833 0 1 1-3.666 0 1.833 1.833 0 0 1 3.666 0Z' fill='#e6d90b' fillOpacity='.3' stroke='#c48f00' strokeWidth='1.222'/>
    <path d='M6.417 8.557a2.444 2.444 0 1 0 0-4.889 2.444 2.444 0 0 0 0 4.889m-3.909 8.282c-.598-.181-1-.776-.867-1.387a4.89 4.89 0 0 1 9.553 0c.133.61-.268 1.206-.867 1.387a13.5 13.5 0 0 1-7.82 0m13.077-8.282a2.444 2.444 0 1 0 0-4.889 2.444 2.444 0 0 0 0 4.889m-2.179 3.567a4.89 4.89 0 0 1 6.956 3.33c.133.61-.268 1.206-.867 1.387a13.5 13.5 0 0 1-5.454.49' stroke='#c48f00' strokeWidth='1.833' strokeLinecap='round' strokeLinejoin='round'/>
</svg>);

export const DigitalProductIcon = (props: IconProps) => (<svg {...getCommonProps(props, 22)}>
    <path d='M5.806 7.03h10.389c1.012 0 1.833.82 1.833 1.832v7.334c0 1.012-.82 1.833-1.833 1.833H5.805a1.833 1.833 0 0 1-1.833-1.833V8.862c0-1.012.821-1.833 1.834-1.833Z' fill='#00ff75' fillOpacity='.12' stroke='#00cf5f' strokeWidth='1.222'/>
    <path d='M11 2.75v7.333m5.195-3.665H5.805a2.444 2.444 0 0 0-2.444 2.444v7.334a2.444 2.444 0 0 0 2.445 2.444h10.389a2.444 2.444 0 0 0 2.444-2.444V8.862a2.444 2.444 0 0 0-2.444-2.444' stroke='#00cf5f' strokeWidth='1.833' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='m3.667 7.68 1.771-3.57a2.44 2.44 0 0 1 2.19-1.36h6.744c.93 0 1.777.527 2.19 1.36l1.772 3.572m-11.917 7.9h2.444' stroke='#00cf5f' strokeWidth='1.833' strokeLinecap='round' strokeLinejoin='round'/>
</svg>);

export const BundleProductIcon = (props: IconProps) => (<svg {...getCommonProps(props, 22)}>
    <path d='M13.468 8.28a2.75 2.75 0 1 0 0-4.952c.376.745.587 1.586.587 2.477s-.211 1.731-.587 2.475' fill='#b200cf' fillOpacity='.3'/>
    <path d='M8.557 8.555a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5' fill='#b200cf' fillOpacity='.3'/>
    <path d='M18.028 12.527v6.112m3.056-3.057h-6.11M8.557 8.555a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5m5.173-.171c.293.106.607.17.937.17a2.75 2.75 0 1 0 0-5.5 2.7 2.7 0 0 0-.937.172m-.752 10.058a6.67 6.67 0 0 0-4.422-1.675A6.72 6.72 0 0 0 2.56 15.3c-.49.967.034 2.148 1.058 2.504a15 15 0 0 0 4.938.834c1.78 0 3.29-.319 4.495-.698' stroke='#b200cf' strokeWidth='1.833' strokeLinecap='round' strokeLinejoin='round'/>
</svg>);

export const LeadProductIcon = (props: IconProps) => (<svg {...getCommonProps(props, 22)}>
    <path d='m14.977 6.408.473 3.777a4.49 4.49 0 0 1-4.453 5.044 4.49 4.49 0 0 1-4.452-5.044l.473-3.784-3.946-.45-.457 3.655c-.143 1.142-.098 2.311.225 3.416 1.115 3.816 4.508 6.18 8.16 6.18h-.005c3.652 0 7.045-2.363 8.16-6.18.322-1.105.367-2.274.224-3.416l-.465-3.723z' fill='#6369d1' fillOpacity='.3'/>
    <path d='m3.077 5.914 3.941.494m11.904-.494-3.942.494M11 19.202c-3.652 0-7.045-2.364-8.16-6.18-.323-1.105-.368-2.274-.225-3.416l.762-6.09A1.22 1.22 0 0 1 4.74 2.455l1.515.19c.67.084 1.145.694 1.061 1.363l-.772 6.178a4.49 4.49 0 0 0 4.452 5.044 4.49 4.49 0 0 0 4.453-5.044l-.773-6.178a1.22 1.22 0 0 1 1.061-1.364l1.516-.189a1.22 1.22 0 0 1 1.364 1.06l.761 6.091c.143 1.142.098 2.311-.225 3.416-1.114 3.817-4.507 6.18-8.16 6.18z' stroke='#4b4da3' strokeWidth='1.833' strokeLinecap='round' strokeLinejoin='round'/>
</svg>);

export const MembershipProductIcon = (props: IconProps) => (<svg {...getCommonProps(props, 22)}>
    <path d='m17.88 14.432 1.128-5.922-4.338 2.67L11 5.174 7.33 11.18 2.992 8.51l1.128 5.922c.12.63.67 1.085 1.31 1.085h11.138c.64 0 1.192-.455 1.312-1.085' fill='#96031a' fillOpacity='.3'/>
    <path d='M11.001 2.84a1.335 1.335 0 1 0 0-2.67 1.335 1.335 0 0 0 0 2.67M1.658 6.511a1.335 1.335 0 1 0 0-2.67 1.335 1.335 0 0 0 0 2.67m18.684 0a1.335 1.335 0 1 0 0-2.67 1.335 1.335 0 0 0 0 2.67' fill='#96031a'/>
    <path d='m17.88 14.432 1.128-5.922-4.338 2.67L11 5.174 7.33 11.18 2.992 8.51l1.128 5.922c.12.63.67 1.085 1.31 1.085h11.138c.64 0 1.192-.455 1.312-1.085m-.206 4.422H4.328' stroke='#96031a' strokeWidth='2.002' strokeLinecap='round' strokeLinejoin='round'/>
</svg>);

export const LinkProductIcon = (props: IconProps) => (<svg {...getCommonProps(props, 22)}>
    <path d='m17.954 11.815-2.64-2.64a4.323 4.323 0 1 0-6.113 6.114l2.64 2.639a4.322 4.322 0 1 0 6.112-6.113' fill='#009c80' fillOpacity='.3'/>
    <path d='M10.389 8.344a4.3 4.3 0 0 0-1.19.843l-.012.012a4.32 4.32 0 0 0 0 6.111l2.659 2.659a4.32 4.32 0 0 0 6.11 0l.013-.013a4.32 4.32 0 0 0 0-6.11l-1.138-1.139' stroke='#009c80' strokeWidth='1.833' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M11.611 13.655a4.3 4.3 0 0 0 1.19-.844l.011-.012a4.32 4.32 0 0 0 0-6.111L10.155 4.03a4.32 4.32 0 0 0-6.11 0l-.013.012a4.32 4.32 0 0 0 0 6.11l1.138 1.139' stroke='#009c80' strokeWidth='1.833' strokeLinecap='round' strokeLinejoin='round'/>
</svg>);

export const ReferralProductIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path d='M7 7a2.25 2.25 0 1 0 0-4.5A2.25 2.25 0 0 0 7 7M11.234 6.86c.24.087.497.14.767.14a2.25 2.25 0 1 0 0-4.5c-.27 0-.527.052-.767.14M8.865 9.839A5.5 5.5 0 0 0 6.999 9.5c-2.145 0-4 1.229-4.906 3.02-.4.791.028 1.757.866 2.048a12.193 12.193 0 0 0 6.194.489M13.782 15.947a.48.48 0 0 0 .437 0c.729-.374 3.031-1.73 3.031-3.934.004-.968-.791-1.757-1.777-1.763a1.8 1.8 0 0 0-1.473.786 1.81 1.81 0 0 0-1.473-.786c-.985.006-1.78.794-1.777 1.763 0 2.205 2.303 3.56 3.031 3.934z' stroke='#B45700' strokeWidth='1.833' strokeLinecap='round' strokeLinejoin='round' />
</svg>);

export const CustomProductIcon = (props: IconProps) => (<svg {...getCommonProps(props, 22)}>
    <path d='m11.496 1.678 7.754 3.445-7.754 3.446a1.22 1.22 0 0 1-.993 0L2.75 5.123l7.753-3.445c.317-.14.678-.14.993 0' fill='#b20baa' fillOpacity='.3'/>
    <path d='M6.416 10.623v-3.87l8.25-3.667' stroke='#b20baa' strokeWidth='1.833' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='m11.496 1.678 7.754 3.445-7.754 3.446a1.22 1.22 0 0 1-.993 0L2.75 5.123l7.753-3.445c.317-.14.678-.14.993 0' stroke='#b20baa' strokeWidth='1.833' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M2.75 5.123v9.594c0 .483.285.92.726 1.118l7.028 3.124c.316.14.677.14.992 0l7.028-3.124c.441-.197.726-.635.726-1.118V5.124M11 8.674v10.282' stroke='#b20baa' strokeWidth='1.833' strokeLinecap='round' strokeLinejoin='round'/>
</svg>);

export const GreenCheckCircleIcon = (props: IconProps) => (<svg {...getCommonProps(props, 28)}>
    <path d='M24.6724 14C24.6724 20.1704 19.6704 25.1724 13.5 25.1724C7.32965 25.1724 2.32759 20.1704 2.32759 14C2.32759 7.82965 7.32965 2.82759 13.5 2.82759C19.6704 2.82759 24.6724 7.82965 24.6724 14Z' fill='#10B981' fillOpacity='0.3' stroke='#059669' strokeWidth='1.65517'/>
    <path d='M13.5 26C20.1275 26 25.5 20.6275 25.5 14C25.5 7.37259 20.1275 2 13.5 2C6.87259 2 1.5 7.37259 1.5 14C1.5 20.6275 6.87259 26 13.5 26Z' stroke='#059669' strokeWidth='2.48276' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M7.70703 14.0006C9.13545 15.4356 10.251 17.0924 11.1829 18.9032C13.3462 14.7802 16.0491 11.5112 19.2932 9.09961' stroke='#059669' strokeWidth='2.48276' strokeLinecap='round' strokeLinejoin='round'/>
</svg>);

export const storeIcons: IconType[] = [
    SessionProductIcon,
    BundleProductIcon,
    DigitalProductIcon,
    LeadProductIcon,
    MembershipProductIcon,
    LinkProductIcon,
    CustomProductIcon,
    GreenCheckCircleIcon,
];
