import { type Dispatch, type ReactNode, useCallback, useMemo } from 'react';
import { Button, Form, Modal, Skeleton } from ':components/shadcn';
import { getSkeletonArray } from ':utils/math';
import { useTranslation } from 'react-i18next';
import { type InvoicingClient, type EventItem, type UseEventOrderDispatch, useEventOrder } from ':frontend/components/orders/useEventOrder';
import { useCached } from ':components/hooks';
import { type Id } from ':utils/id';
import { EventStateBadge } from ':frontend/components/event/EventStateBadge';
import { ClientIconLink } from ':frontend/components/client/ClientIconLink';
import { FilterRow, useFilters, useFiltersApply, type FilterFunction } from ':frontend/components/common/filters/FilterRow';
import { EventStateFilter } from ':frontend/components/common/filters/EventStateFilter';
import { createClientFilter } from ':frontend/components/common/filters/ClientFilter';
import clsx from 'clsx';
import { type EventFE } from ':frontend/types/Event';
import { InfoCard } from ':frontend/components/settings/InfoCard';
import { type CheckoutInput } from ':frontend/components/orders/checkout/useCheckout';
import { CheckoutModalInner, type CheckoutOutput } from ':frontend/components/orders/checkout/CheckoutModalInner';
import { routesFE } from ':utils/routes';
import { useNavigate } from 'react-router-dom';
import { ArrowsExpandDiagonal6Icon, ArrowsReduceDiagonal1Icon } from ':components/icons/basic';
import { DateTime } from 'luxon';
import { DateTimeDisplay } from ':components/custom/DateTimeDisplay';
import type { TFunction } from 'i18next';
import { useTailwindMediaQuery, useToggle } from ':frontend/hooks';

// FIXME info card, pagination, filters

export function NewEventOrderTab() {
    const { t } = useTranslation('pages', { keyPrefix: 'directSale.event' });
    const navigate = useNavigate();

    const showDesktopView = useTailwindMediaQuery({ minWidth: 'lg' });

    const { state: { invoicingClients, clients, checkoutInput }, selectedItems, preselected, dispatch } = useEventOrder();
    const checkoutOutput = useCallback((action: CheckoutOutput) => {
        switch (action.type) {
        case 'back':
            dispatch({ type: 'checkoutEnd' });
            break;
        case 'finish':
            navigate(routesFE.orders.list.path);
            break;
        }
    }, [ dispatch, navigate ]);

    const filters = useMemo(() => [
        EventStateFilter,
        createClientFilter(clients ?? []),
    ], [ clients ]);

    const filtersControl = useFilters(filters);
    const applyEventFilter = useFiltersApply(filtersControl, EventStateFilter.name);
    const applyClientFilter = useFiltersApply(filtersControl, createClientFilter.filterName);
    const filteredInvoicingClients = useMemo(() => invoicingClients?.filter(client => applyClientFilter(client.client)), [ invoicingClients, applyClientFilter ]);

    const isInvoicing = selectedItems !== 0;

    return (
        <div className='px-4'>
            <CheckoutModal input={checkoutInput} output={checkoutOutput} />
            <div className='max-w-[910px] w-full mx-auto pt-6 pb-24 space-y-6'>
                <h1 className='font-semibold text-2xl text-secondary-700 leading-7'>{t('title')}</h1>

                <InfoCard infoKey='eventOrder' />

                <FilterRow control={filtersControl} />

                <div className='fl-hide-scrollbar overflow-x-auto max-md:-mx-4'>
                    <div className='max-md:px-4 max-md:w-fit'>
                        <div className='rounded-xl shadow-lg min-w-[500px] w-full'>
                            {showDesktopView && isInvoicing && (
                                // This negative margin - positive padding combo is here to hide the shadow around the sticky header.
                                <div className='max-md:hidden sticky top-0 bg-secondary-50 -mx-4 px-4'>
                                    <div className='px-6 h-16 rounded-t-xl border border-primary bg-primary-50 flex items-center gap-2'>
                                        <span className='text-primary tabular-nums'>
                                            {t('selected-items-label', { count: selectedItems })}
                                        </span>

                                        <div className='grow' />

                                        <div className='flex items-center gap-2'>
                                            <Button variant='secondary' onClick={() => dispatch({ type: 'reset' })}>
                                                {t('cancel-button')}
                                            </Button>

                                            <Button onClick={() => dispatch({ type: 'checkoutStart' })}>
                                                {t('checkout-button')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!(showDesktopView && isInvoicing) && (
                                // (Desktop view && !isInvoicing) or Phone view
                                <div className={clsx(outerGridClass, 'h-16 rounded-t-xl border bg-white text-secondary-400')}>
                                    <span>{t('client-label')}</span>
                                    <span>{t('events-label')}</span>
                                    <span>{t('email-label')}</span>
                                </div>
                            )}

                            {!showDesktopView && isInvoicing && (
                                <div className='md:hidden fixed z-[9999] bottom-0 left-0 right-0 p-4 border-t border-primary bg-primary-50 space-y-4'>
                                    <div className='text-primary tabular-nums font-semibold'>
                                        {t('selected-items-label', { count: selectedItems })}
                                    </div>

                                    <div className='flex items-center gap-2'>
                                        <Button variant='outline' onClick={() => dispatch({ type: 'reset' })}>
                                            {t('cancel-button')}
                                        </Button>

                                        <Button onClick={() => dispatch({ type: 'checkoutStart' })}>
                                            {t('checkout-button')}
                                        </Button>
                                    </div>
                                </div>
                            )}

                            <div className='border-l border-r bg-white'>
                                <InvoicingClientsList
                                    invoicingClients={filteredInvoicingClients}
                                    dispatch={dispatch}
                                    preselectedClientId={preselected?.clientId}
                                    applyEventFilter={applyEventFilter}
                                />
                            </div>

                            <div className='p-6 rounded-b-xl border bg-white'>
                                {/* NICE_TO_HAVE pagination */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

type InvoicingClientsListProps = Readonly<{
    invoicingClients?: InvoicingClient[];
    preselectedClientId?: Id;
    dispatch: UseEventOrderDispatch;
    applyEventFilter: FilterFunction<EventFE>;
}>;

function InvoicingClientsList({ invoicingClients, dispatch, preselectedClientId, applyEventFilter }: InvoicingClientsListProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'directSale.event' });
    const skeletonArray = useMemo(() => getSkeletonArray(), []);

    if (!invoicingClients)
        return (<>{skeletonArray.map(id => <InvoicingClientSkeleton key={id} />)}</>);

    if (invoicingClients.length === 0) {
        return (
            <div className='text-center text-xl py-12'>
                {t('no-invoicing-clients-yet-text')}
            </div>
        );
    }

    return (<>
        {invoicingClients.map(invoicingClient => (
            <InvoicingClientRow
                key={invoicingClient.client.id}
                invoicingClient={invoicingClient}
                dispatch={dispatch}
                isPreselected={invoicingClient.client.id === preselectedClientId}
                applyEventFilter={applyEventFilter}
            />
        ))}
        {invoicingClients.length === 0 && (
            <div className='text-center text-xl py-12'>
                {t('no-invoicing-clients-text')}
            </div>
        )}
    </>);
}

const outerGridClass = 'px-6 grid grid-cols-[minmax(0,1fr)_auto_minmax(0,1fr)] md:grid-cols-3 items-center gap-5';

type InvoicingClientRowProps = Readonly<{
    invoicingClient: InvoicingClient;
    dispatch: UseEventOrderDispatch;
    isPreselected: boolean;
    applyEventFilter: FilterFunction<EventFE>;
}>;

function InvoicingClientRow({ invoicingClient: { client, items }, dispatch, isPreselected, applyEventFilter }: InvoicingClientRowProps) {
    const { visibleItems, selectedItems, selectedVisibleItems } = useMemo(() => {
        const visibleItems = items.filter(item => applyEventFilter(item.event));
        return {
            visibleItems,
            selectedItems: items.filter(i => i.isSelected),
            selectedVisibleItems: visibleItems.filter(i => i.isSelected),
        };
    }, [ items, applyEventFilter ]);

    const [ isExpanded, setIsExpanded ] = useToggle(isPreselected || selectedVisibleItems.length > 0);
    const isAllSelected = selectedVisibleItems.length === visibleItems.length;

    if (visibleItems.length === 0)
        return null;

    return (
        <div className='border-b last:border-0'>
            {/* The doulble toggle is intentional - the second one negates the first one so there is an 'unclickable zone' around the checkbox. */}
            <div className={clsx(outerGridClass, 'select-none cursor-pointer hover-partial:bg-secondary-50', isExpanded && 'bg-secondary-50')} onClick={setIsExpanded.toggle}>
                <div className='flex items-center gap-5'>
                    <div className='shrink-0 py-4 select-none cursor-default block-hover' onClick={setIsExpanded.toggle}>
                        <Form.Threebox
                            checked={selectedVisibleItems.length > 0 && (isAllSelected || 'indeterminate')}
                            onCheckedChange={value => dispatch({ type: 'select', items: visibleItems, isSelected: !!value })}
                        />
                    </div>
                    <ClientIconLink client={client} className='block-hover' />
                </div>

                <div className='flex items-center gap-1 tabular-nums'>
                    <div className='size-2 rounded-full bg-primary-400' />
                    <span>{selectedItems.length}</span>
                    <span className='text-secondary-400'>/</span>
                    <span className='text-secondary-400'>{items.length}</span>
                </div>

                <div className='flex items-center gap-5 justify-between'>
                    <span className='truncate'>{client.email}</span>
                    <div className='shrink-0'>
                        <Button variant='transparent' size='exact'>
                            {isExpanded ? <ArrowsReduceDiagonal1Icon /> : <ArrowsExpandDiagonal6Icon />}
                        </Button>
                    </div>
                </div>
            </div>
            {isExpanded && (
                <InvoicingClientEvents visibleItems={visibleItems} dispatch={dispatch} />
            )}
        </div>
    );
}

type InvoicingClientEventsProps = Readonly<{
    visibleItems: EventItem[];
    dispatch: UseEventOrderDispatch;
}>;

function InvoicingClientEvents({ visibleItems, dispatch }: InvoicingClientEventsProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'directSale.event' });
    const sections = useMemo(() => {
        const today = DateTime.now();
        const monthStart = today.startOf('month');
        const monthEnd = today.endOf('month');

        return {
            current: visibleItems.filter(item => item.event.start >= monthStart && item.event.start <= monthEnd),
            future: visibleItems.filter(item => item.event.start > monthEnd),
            past: visibleItems.filter(item => item.event.start < monthStart),
        };
    }, [ visibleItems ]);

    return (
        <div className='p-4 md:px-6 md:pt-5 md:pb-10 border-t'>
            <h3 className='font-semibold'>{t('client-events-title')}</h3>
            {Object.entries(sections).map(([ section, items ]) => itemsTimeSection(section, items, dispatch, t))}
        </div>
    );
}

function itemsTimeSection(section: string, items: EventItem[], dispatch: UseEventOrderDispatch, t: TFunction): ReactNode {
    if (items.length === 0)
        return null;

    return (
        <div key={section}>
            <div className='py-4 flex items-center gap-2'>
                <h4 className='text-secondary-400'>{t(`${section}-section`)}</h4>
                <Button variant='transparent' size='exact' className='text-primary' onClick={() => dispatch({ type: 'select', isSelected: true, items })}>
                    {t('select-section-button')}
                </Button>
            </div>

            <div className='border rounded-xl'>
                {items.map(item => (
                    <ParticipantRow key={item.event.id} item={item} dispatch={dispatch} />
                ))}
            </div>
        </div>
    );
}

type ParticipantRowProps = Readonly<{
    item: EventItem;
    dispatch: UseEventOrderDispatch;
}>;

function ParticipantRow({ item, dispatch }: ParticipantRowProps) {
    const { event, isSelected } = item;

    return (
        <div className='px-6 grid grid-cols-[auto_repeat(3,minmax(0,1fr))] items-center gap-5 border-b last:border-b-0'>
            <div className='w-fit py-3'>
                <Form.Checkbox checked={isSelected} onCheckedChange={value => dispatch({ type: 'select', item, isSelected: value })} />
            </div>
            <div className='truncate'>
                {event.displayTitle}
            </div>
            <DateTimeDisplay dateTime={event.start} date className='text-secondary-400' />
            <EventStateBadge event={item.event}/>
        </div>
    );
}

function InvoicingClientSkeleton() {
    return (
        <div className={clsx(outerGridClass, 'border-b last:border-0')}>
            <div className='flex items-center gap-5'>
                <div className='py-4'>
                    <Form.Checkbox disabled />
                </div>
                <Skeleton height={24} className='grow' />
            </div>

            <Skeleton height={24} />

            <div className='flex items-center gap-5 justify-between'>
                <Skeleton height={24} />
                <Button variant='transparent' size='exact' disabled>
                    <ArrowsExpandDiagonal6Icon />
                </Button>
            </div>
        </div>
    );
}

type CheckoutModalProps = Readonly<{
    input?: CheckoutInput;
    output: Dispatch<CheckoutOutput>;
}>;

function CheckoutModal({ input, output }: CheckoutModalProps) {
    const cachedInput = useCached(input);
    if (!cachedInput)
        return null;

    return (
        <Modal.Root open={!!input}>
            <Modal.OuterContent>
                <CheckoutModalInner input={cachedInput} output={output} />
            </Modal.OuterContent>
        </Modal.Root>
    );
}
