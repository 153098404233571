import { TaskList } from '../task/TaskList';
import { Button, ScrollArea } from ':components/shadcn';
import { SidebarRight2ShowIcon, SidebarRight3HideIcon } from ':components/icons/basic';
import clsx from 'clsx';

export function CalendarSidebar({ isCollapsed, setIsCollapsed }: { isCollapsed: boolean, setIsCollapsed: (newIsCollapsed: boolean) => void }) {
    return (
        // The max-height and flex are necessary for the scroll area to work. If the dimensions change, they need to be recalculated.
        <div className={
            clsx(`fixed z-20 right-[--removed-body-scroll-bar-size] top-[--topbar-height] bottom-0 max-md:bottom-[80px] flex flex-col border-l border-secondary-100 transition-[width] bg-secondary-50`,
                isCollapsed ? 'w-[37px]' : 'w-[281px]',
            )
        }>
            <div className='shrink-0 h-12 flex items-center'>
                <Button
                    size='small'
                    variant='transparent'
                    className={clsx('[&_svg]:size-5 px-2 text-secondary-300 transition-opacity', isCollapsed && 'text-secondary-500')}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                >
                    {isCollapsed ? (
                        <SidebarRight2ShowIcon />
                    ) : (
                        <SidebarRight3HideIcon />
                    )}
                </Button>
            </div>

            <div className='shrink min-h-0 border-t border-secondary-100'>
                {!isCollapsed && (
                    <ScrollArea className='h-full flex flex-col'>
                        <div className='max-w-[280px] p-1'>
                            <TaskList />
                        </div>
                    </ScrollArea>
                )}
            </div>
        </div>
    );
}
