import { type DateTime } from 'luxon';
import { isSubscription, SubscriptionTierCode, type SubscriptionTierPaymentPeriod, type SubscriptionOutput, type SubscriptionTierRestrictions } from ':utils/entity/subscription';

export class SubscriptionFE {
    constructor(
        readonly code: SubscriptionTierCode,
        readonly paymentPeriod: SubscriptionTierPaymentPeriod,
        readonly createdAt: DateTime,
        readonly trialExpiresAt: DateTime,
        readonly isActive: boolean,
        readonly isPending: boolean,
        readonly isTrial: boolean,
        /** Shorthand for users able to use professional features */
        readonly isPro: boolean,
        readonly restrictions: SubscriptionTierRestrictions,
    ) {}

    static fromServer(input: SubscriptionOutput): SubscriptionFE {
        const trialExpiresAt = input.createdAt.endOf('day').plus({ days: input.trialDays ?? 0 });
        const isActive = isSubscription.active(input);

        return new SubscriptionFE(
            input.tierCode,
            input.paymentPeriod,
            input.createdAt,
            trialExpiresAt,
            isActive,
            isSubscription.pending(input),
            isSubscription.trial(input),
            input.tierCode === SubscriptionTierCode.paid && isActive,
            input.restrictions,
        );
    }
}

export enum SubscriptionErrorType {
    PlanPending = 'subscription.planPending',
    PlanEnded = 'subscription.planEnded',
}

export type SubscriptionError = {
    type: SubscriptionErrorType;
};
