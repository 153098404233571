import type { DateTime } from 'luxon';
import { type CurrencyId } from ':utils/money';
import { type Id } from ':utils/id';
import { optionalStringToPut, optionalStringToPatch } from ':frontend/utils/common';
import { stringifyRecurrence, type Recurrence } from ':utils/recurrence';
import { EventParticipantFE, isParticipantBillable, participantsToServer, type Participant, type PayingParticipant, type EditablePayingParticipant } from './EventParticipant';
import { ClientInfoFE } from './Client';
import { type GenericProductItem } from './orders/ProductOrderItem';
import { type TeamMemberFE } from './Team';
import { type EventTransition, RecurrenceRange, type EventEdit, type EventsOperationOutput, type EventInit, type EventOutput, type ProductEventInit } from ':utils/entity/event';
import type { AppUserSettingsOutput } from ':utils/entity/settings';

export const MAX_NOTES_LENGTH = 2000;
export const MAX_TITLE_LENGTH = 255;

export class EventFE {
    /** recurrence index / package progress + title */
    readonly displayTitle: string;

    private constructor(
        readonly id: Id,
        readonly title: string,
        readonly isCanceled: boolean,
        readonly start: DateTime,
        readonly end: DateTime,
        readonly guests: EventParticipantFE[],
        readonly clients: PayingParticipant[],
        readonly recurrenceIndex: number | undefined,
        readonly notes: string,
        readonly description: string,
        readonly locationId: Id | undefined,
        /** Id of the corresponding google event. */
        readonly googleId: string | undefined,
        readonly googleCalendarId: string | undefined,
        readonly isDeleted: boolean,
        /** If the event was created by scheduling a package, this is its index in that package. */
        readonly productIndex: number | undefined,
        readonly productSessionsCount: number | undefined,
        readonly ownerId: Id,
    ) {
        const recurrencePrefix = this.recurrenceIndex !== undefined && `#${this.recurrenceIndex + 1}`;
        const prefix = this.packageProgress ?? recurrencePrefix;
        this.displayTitle = (prefix ? `${prefix} ` : '') + this.title;
    }

    static fromServer(input: EventOutput): EventFE {
        const guests = input.participants.map(EventParticipantFE.fromServer).filter(p => p.isGuest);
        const clients = input.participants.map(EventParticipantFE.fromServer).filter((p): p is PayingParticipant => !!p.isPaying);
        const start = input.start;

        return new EventFE(
            input.id,
            input.title,
            input.isCanceled,
            start,
            input.end,
            guests,
            clients,
            input.recurrenceIndex,
            input.notes,
            input.description,
            input.locationId,
            input.googleId,
            input.googleCalendarId,
            input.deleted,
            input.productIndex,
            input.productSessionsCount,
            input.appUser,
        );
    }

    toUpdated({ guests, clients, notes }: EventUpdateObject): EventFE {
        return new EventFE(
            this.id,
            this.title,
            this.isCanceled,
            this.start,
            this.end,
            guests ?? this.guests,
            clients ?? this.clients,
            this.recurrenceIndex,
            notes ?? this.notes,
            this.description,
            this.locationId,
            this.googleId,
            this.googleCalendarId,
            this.isDeleted,
            this.productIndex,
            this.productSessionsCount,
            this.ownerId,
        );
    }

    get isInPackage(): boolean {
        return this.productIndex !== undefined;
    }

    get packageProgress(): string | undefined {
        return this.isInPackage
            ? `(${this.productIndex! + 1}/${this.productSessionsCount})`
            : undefined;
    }

    findParticipantIdByClientId(clientId: Id): Id | undefined {
        const participant = this.guests.find(p => p.client.id === clientId) ?? this.clients.find(p => p.client.id === clientId);
        return participant?.id;
    }
}

export class EventsOperationOutputFE {
    private constructor(
        readonly events: EventFE[],
        readonly newClients: ClientInfoFE[],
    ) {}

    static fromServer(input: EventsOperationOutput) {
        return new EventsOperationOutputFE(
            input.events.map(EventFE.fromServer),
            input.newClients.map(ClientInfoFE.fromServer),
        );
    }
}

export type EventUpdateObject = {
    guests?: EventParticipantFE[];
    clients?: PayingParticipant[];
    notes?: string;
};

export function eventIsBillable(event: EventFE) {
    return event.clients.some(participant => isParticipantBillable(participant) && !participant.isInOrder);
}

export function isEventTransparent(event: EventFE): boolean {
    return event.isCanceled;
}

export function eventHasClientWithCurrency(event: EventFE, client: ClientInfoFE, currency: CurrencyId): boolean {
    return event.clients.some(c => c.id === client.id && c.payment.currency === currency);
}

export type EventInitFE = {
    title: string;
    description: string;
    locationId?: Id;
    start: DateTime;
    end: DateTime;
    recurrence?: Recurrence;
    guests: Participant[];
    clients: EditablePayingParticipant[];
    initialNotes: string;
    isBillLater: boolean;
    scheduler?: TeamMemberFE;
};

export type EventEditFE = {
    event: EventFE;
    title: string;
    description: string;
    locationId?: Id;
    start: DateTime;
    end: DateTime;
    guests: Participant[];
    clients: EditablePayingParticipant[];
    isBillLater: boolean;
    // notes: string; // TODO
};

export enum SyncType {
    Create = 'create',
    Update = 'update',
    Transition = 'transition',
    Delete = 'delete',
    Schedule = 'schedule',
}

type TEventSync<TType extends SyncType, TData> = {
    sendNotification?: boolean;
    range?: RecurrenceRange;
    type: TType;
    data: TData;
};

export type EventCreate = TEventSync<SyncType.Create, EventInitFE>;
export type EventUpdate = TEventSync<SyncType.Update, EventEditFE>;
export type EventTransitionFE = TEventSync<SyncType.Transition, { event: EventFE, transition: EventTransition }>;
export type EventDelete = TEventSync<SyncType.Delete, undefined>;

export type EventSync = EventCreate | EventUpdate | EventTransitionFE | EventDelete | ProductEventInitFE;

export function eventCreateToServer(create: EventCreate, settings: AppUserSettingsOutput): EventInit {
    const init = create.data;
    const { clientParticipants, contactParticipants } = participantsToServer(init.clients, init.guests, settings);

    return {
        title: init.title,
        description: init.description,
        locationId: init.locationId,
        start: init.start,
        end: init.end,
        recurrence: init.recurrence && stringifyRecurrence(init.recurrence),
        clientParticipants,
        contactParticipants,
        sendNotification: !!create.sendNotification,
        notes: optionalStringToPut(init.initialNotes),
        schedulerId: init.scheduler?.appUserId,
    };
}

export type ProductEventInitFE = TEventSync<SyncType.Schedule, {
    title: string;
    locationId?: Id;
    start: DateTime;
    end: DateTime;
    recurrence?: Recurrence;
    guests: Participant[];
    productItem: GenericProductItem;
    scheduler?: TeamMemberFE;
}>;

export function productEventToServer(schedule: ProductEventInitFE, settings: AppUserSettingsOutput): ProductEventInit {
    const init = schedule.data;
    const { clientParticipants, contactParticipants } = participantsToServer([], init.guests, settings);

    return {
        title: init.title,
        locationId: init.locationId,
        start: init.start,
        end: init.end,
        recurrence: init.recurrence && stringifyRecurrence(init.recurrence),
        clientGuests: clientParticipants.map(p => p.id),
        contactGuests: contactParticipants.map(p => p.contact),
        orderItemId: init.productItem.id,
        schedulerId: init.scheduler?.appUserId,
        sendNotification: !!schedule.sendNotification,
    };
}

export function eventUpdateToServer(update: EventUpdate, settings: AppUserSettingsOutput): EventEdit {
    const edit = update.data;
    const event = edit.event;

    const { clientParticipants, contactParticipants } = participantsToServer(edit.clients, edit.guests, settings);

    return {
        title: edit.title !== event.title ? edit.title : undefined,
        description: edit.description !== event.description ? edit.description : undefined,
        locationId: optionalStringToPatch(event.locationId, edit.locationId),
        start: !edit.start.equals(event.start) ? edit.start : undefined,
        end: !edit.end.equals(event.end) ? edit.end : undefined,
        clientParticipants,
        contactParticipants,
        // notes: edit.notes,

        id: event.id,
        range: update.range ?? RecurrenceRange.This,
        sendNotification: !!update.sendNotification,
    };
}

export function eventTransitionToServer(action: EventTransitionFE): EventEdit {
    return {
        transition: action.data.transition,
        id: action.data.event.id,
        range: action.range ?? RecurrenceRange.This,
        sendNotification: !!action.sendNotification,
    };
}
