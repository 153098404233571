import { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from ':components/shadcn';
import { type ClientInfoFE } from ':frontend/types/Client';
import { ErrorMessage, TranslatedErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { CurrencySelect } from ':frontend/components/forms/CurrencySelect';
import { DateTime } from 'luxon';
import { DateTimeDisplay } from ':components/custom/DateTimeDisplay';
import { transformToIntegerOrEmpty, transformToPrice } from ':utils/math';
import { SingleContinuousParticipantSelect } from '../client/ContinuousParticipantSelect';
import clsx from 'clsx';
import { getI18nLocale } from ':utils/i18n';
import { CircleMinusIcon, CirclePlusIcon } from ':components/icons/basic';
import { TaxRateInput } from '../forms/TaxRateInput';
import { DiscountInput } from './DiscountInput';
import { computeFinalDueDays, type UseCustomOrderDispatch, type UseCustomOrderState } from './useCustomOrder';
import { computeCustomPriceSummary, PriceSummaryDisplay } from './PriceSummary';
import { useMaster } from ':frontend/context/UserProvider';
import { Currency } from ':utils/money';

type CustomOrderFormProps = Readonly<{
    clients: ClientInfoFE[];
    state: UseCustomOrderState;
    dispatch: UseCustomOrderDispatch;
}>;

export function CustomOrderForm({ clients, state, dispatch }: CustomOrderFormProps) {
    const { t, i18n } = useTranslation('pages', { keyPrefix: 'directSale.custom' });

    const dueDaysId = useId();
    // Due days can be undefined because we can always compute it on the backend. However, we need to display the final date (how the server will compute it).
    const dueDate = DateTime.now().plus({ days: computeFinalDueDays(state) });
    const priceSummary = computeCustomPriceSummary(state.form);

    return (
        <Form.Root className='lg:min-h-[712px] px-4 md:px-8 py-7 flex flex-col gap-4 rounded-xl bg-white'>
            <div>
                <Form.Label className='text-lg font-semibold mb-3'>{t('client-label')}</Form.Label>
                <SingleContinuousParticipantSelect
                    isCreatable
                    clients={clients}
                    value={state.form.client}
                    onChange={value => dispatch({ type: 'input', field: 'client', value })}
                />
                <TranslatedErrorMessage translationId={state.formErrors?.['client']} />
            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <div>
                    <Form.Label>{t('currency-label')}</Form.Label>
                    <CurrencySelect
                        value={state.form.currency}
                        onChange={value => value && dispatch({ type: 'input', field: 'currency', value })}
                    />
                </div>

                <div>
                    <Form.Label htmlFor={dueDaysId}>{t('dueDays-label')}</Form.Label>
                    <div className='relative'>
                        <Form.Input
                            id={dueDaysId}
                            isError={!!state.formErrors?.dueDays}
                            type='number'
                            value={state.form.dueDays}
                            onChange={e => dispatch({ type: 'input', field: 'dueDays', value: transformToIntegerOrEmpty(e.target.value) })}
                        />
                        {dueDate && (
                            <DateTimeDisplay dateTime={dueDate} date className='absolute right-4 top-0 bottom-0 self-center text-secondary-400' />
                        )}
                    </div>
                </div>
            </div>

            <h2 className='mt-4 text-lg font-semibold'>{t('items-section')}</h2>

            <div className='space-y-4 md:space-y-2'>
                {state.form.items.map((_, index) => (
                    <CustomOrderFormItems
                        key={index}
                        index={index}
                        state={state}
                        dispatch={dispatch}
                    />
                ))}

                <Button variant='outline' size='small' className='w-full' onClick={() => dispatch({ type: 'form', operation: 'addItem' })}>
                    <CirclePlusIcon />{t('add-item-button')}
                </Button>

                <DiscountInput discount={state.form.discount} onChange={value => dispatch({ type: 'input', field: 'discount', value })} />
            </div>

            <PriceSummaryDisplay summary={priceSummary} />

            {state.wasSubmitted && (<>
                {priceSummary.currencies.filter(c => c.isUnderMinimalAmount).map(({ total: { currency } }) => (
                    <ErrorMessage key={currency} message={t('minimal-charge-error', {
                        money: Currency.get(currency).displayFull(Currency.get(currency).minimalAmount, getI18nLocale(i18n), true),
                    })} />
                ))}
            </>)}

            <div className='max-lg:hidden grow' />

            <Button className='mt-4 lg:mt-10 w-full' onClick={() => dispatch({ type: 'form', operation: 'checkoutStart' })}>
                {t('continue-button')}
            </Button>
        </Form.Root>
    );
}

type CustomOrderFormItemsProps = Readonly<{
    index: number;
    state: UseCustomOrderState;
    dispatch: UseCustomOrderDispatch;
}>;

function CustomOrderFormItems({ index, state, dispatch }: CustomOrderFormItemsProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'directSale.custom' });
    const { teamSettings } = useMaster();
    const item = state.form.items[index];
    const isFirstRow = index === 0;
    const isRemovable = state.form.items.length > 1;

    return (
        <div>
            <div className={clsx('flex items-start gap-2', !isFirstRow && '[&_label]:sr-only')}>
                <div className='max-md:space-y-1 md:flex md:items-start md:gap-2 grow'>
                    <div className='flex items-start gap-2 grow'>
                        <div className='w-13 shrink-0'>
                            <Form.Input
                                size='compact'
                                className='text-right'
                                label={t('quantity-label')}
                                type='number'
                                value={item.quantity}
                                // This isn't price, but it has the same rules.
                                onChange={e => dispatch({ type: 'input', field: `items.${index}.quantity`, value: transformToPrice(e.target.value) })}
                            />
                        </div>

                        <div className='grow'>
                            <Form.Textarea
                                size='compact'
                                label={t('title-label')}
                                value={item.title}
                                onChange={e => dispatch({ type: 'input', field: `items.${index}.title`, value: e.target.value })}
                                minRows={1}

                            />
                        </div>
                    </div>

                    <div className='max-md:grid max-md:grid-cols-2 md:flex md:items-start gap-2'>
                        <div className='md:w-17 shrink-0'>
                            <Form.Input
                                size='compact'
                                className='text-right'
                                label={t('unitPriceInDecimal-label')}
                                type='number'
                                value={item.unitPriceInDecimal}
                                onChange={e => dispatch({ type: 'input', field: `items.${index}.unitPriceInDecimal`, value: transformToPrice(e.target.value) })}
                            />
                        </div>

                        {teamSettings.isTaxesModuleOn && (
                            <div className='md:w-16 shrink-0'>
                                <Form.Label>{t('taxRate-label')}</Form.Label>
                                <TaxRateInput
                                    size='compact'
                                    value={item.taxRate}
                                    onChange={value => dispatch({ type: 'input', field: `items.${index}.taxRate`, value })}
                                />
                            </div>
                        )}
                    </div>
                </div>

                {isRemovable && (
                    <div className={clsx('-ml-2 -mr-4 h-10 flex items-center', isFirstRow && 'mt-6')}>
                        <Button
                            variant='transparent'
                            size='tiny'
                            onClick={() => dispatch({ type: 'form', operation: 'removeItem', index })}
                            aria-label={t('remove-button')}
                        >
                            <CircleMinusIcon />
                        </Button>
                    </div>
                )}
            </div>
            <TranslatedErrorMessage translationId={state.formErrors?.[`items.${index}.quantity`]} />
            <TranslatedErrorMessage translationId={state.formErrors?.[`items.${index}.title`]} />
            <TranslatedErrorMessage translationId={state.formErrors?.[`items.${index}.price`]} />
        </div>
    );
}
