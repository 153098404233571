import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// FIXME This is not going to work since we (a) removed fl-main-scroller and (b) changed the layout of the notes page.

/**
 * This hook scrolls to the element with the id from the hash fragment. It can be triggered multiple times (e.g., when the data is loaded).
 * However, the scrolling will be performed only once.
 *
 * @param scrollerId An id of the scrolling element. Probably has a fl-main-scroller class.
 * @param trigger When this value changes, the hook will be triggered again.
 */
export function useHashFragment(scrollerId: string, trigger: unknown) {
    const location = useLocation();
    const wasTriggered = useRef(false);

    useEffect(() => {
        if (!location.hash || wasTriggered.current)
            return;

        const elementId = location.hash.substring(1);
        const elementToScroll = document.getElementById(elementId);
        if (!elementToScroll)
            return;

        const scroller = document.getElementById(scrollerId);
        if (!scroller)
            return;

        const top = elementToScroll.offsetTop - scroller.offsetTop;
        scroller.scrollTo({
            top,
            behavior: 'smooth',
        });
        wasTriggered.current = true;

    }, [ location.hash, scrollerId, trigger ]);
}
