'use client';

import { forwardRef, type ComponentPropsWithoutRef, type ElementRef, type HTMLAttributes, type ReactNode } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { cn } from './utils';
import { XmarkIcon } from ':components/icons/basic';

const Root = DialogPrimitive.Root;

const Trigger = DialogPrimitive.Trigger;

const Portal = DialogPrimitive.Portal;

const Close = DialogPrimitive.Close;

type OverlayProps = ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay> & {
    className?: string;
};

const Overlay = forwardRef<ElementRef<typeof DialogPrimitive.Overlay>, OverlayProps>(({ className, ...props }, ref) => (
    <DialogPrimitive.Overlay
        ref={ref}
        className={cn(`
            fixed inset-0 z-[1200] bg-[rgba(184,161,230,0.10)] p-4 md:p-8 scrollbar-gutter-stable-both-edges backdrop-blur-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0
            overflow-y-auto max-h-screen grid place-items-center
            `, className,
        )}
        {...props}
    />
));
Overlay.displayName = 'ModalOverlay';

type ContentProps = ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & CloseButtonProps & {
    className?: string;
};

/**
 * If the modal is closed, this component is still rendered! However, its children are not ...
 */
const Content = forwardRef<ElementRef<typeof DialogPrimitive.Content>, ContentProps>(({ className, closeButton, closeDisabled, children, ...props }, ref) => (
    <Portal>
        <Overlay>
            <DialogPrimitive.Content
                ref={ref}
                className={cn(`
                    z-50 relative w-full max-w-lg max-sm:max-w-[calc(100vw-32px)] p-6 flex flex-col gap-4 transition-none
                    border-2 border-primary bg-white rounded-2xl shadow-lg duration-200
                    data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95
                    `, className,
                )}
                {...props}
                onPointerDownOutside={preventEventIfScrollbarClick}
            >
                {children}
                {closeButton !== null && (
                    <CommonCloseButton closeButton={closeButton} closeDisabled={closeDisabled} />
                )}
            </DialogPrimitive.Content>
        </Overlay>
    </Portal>
));
Content.displayName = 'ModalContent';

/**
 * This function prevents closing the modal when clicking on the scrollbar.
 * Based on https://github.com/tailwindlabs/headlessui/pull/1333/files#diff-d095a5f3fa3ad7f5ff99576cb61e5d75a979a6b7d5557f8a092f5d5c8c0c34deR49.
 */
function preventEventIfScrollbarClick(event: CustomEvent<{ originalEvent: PointerEvent }>) {
    const target = event.target as HTMLElement;
    const viewport = target.ownerDocument.documentElement;

    // Ignore if the target doesn't exist in the DOM anymore.
    if (!viewport.contains(target))
        return;

    const originalEvent = event.detail.originalEvent;
    // This isn't ideal but there probably isn't a better way to do this.
    // The issue is that overlay scrollbars don't take any space so we can't reliably measure them.
    const scrollbarWidth = 20;
    if (
        originalEvent.clientX > viewport.clientWidth - scrollbarWidth ||
        originalEvent.clientX < scrollbarWidth ||
        originalEvent.clientY > viewport.clientHeight - scrollbarWidth ||
        originalEvent.clientY < scrollbarWidth
    )
        event.preventDefault();
}

type OuterContentProps = ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    className?: string;
};

/**
 * This component is used when we need to display modals in modals.
 * It needs to be rendered at all times because it uses multiple animations.
 * It expects an {@link InnerContent} component as a child.
 */
const OuterContent = forwardRef<ElementRef<typeof DialogPrimitive.Content>, OuterContentProps>(({ className, children, ...props }, ref) => (
    <Portal>
        <Overlay>
            <DialogPrimitive.Content
                ref={ref}
                className={cn(`
                    z-50 max-md:w-full min-w-[288px]
                    duration-200
                    data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95
                    `, className,
                )}
                {...props}
            >
                {children}
            </DialogPrimitive.Content>
        </Overlay>
    </Portal>
));
OuterContent.displayName = 'ModalOuterContent';

type InnerContentProps = CloseButtonProps & Readonly<{
    className?: string;
    children: ReactNode;
}>;

/**
 * This component is used when we need to display modals in modals.
 * It might be rendered conditionally.
 * It is supposed to be rendered inside an {@link OuterContent} component.
 * Use max-width together with width to control the size of this component.
 */
function InnerContent({ className, closeButton, closeDisabled, children }: InnerContentProps) {
    return (
        <div
            className={cn(`
                relative w-full max-w-lg max-sm:p-4 p-6 flex flex-col gap-4
                border-2 border-primary bg-white rounded-2xl shadow-lg
                `, className,
            )}
        >
            {children}
            {closeButton !== null && (
                <CommonCloseButton closeButton={closeButton} closeDisabled={closeDisabled} />
            )}
        </div>
    );
}
InnerContent.displayName = 'ModalInnerContent';

export type CloseButtonProps = Readonly<{
    /** The label of the close button for screen readers. If null, the close button won't be displayed at all. */
    closeButton: string | null;
    /** E.g., for fetching. */
    closeDisabled?: boolean;
}>;

export function CommonCloseButton({ closeButton, closeDisabled }: CloseButtonProps): ReactNode {
    return (
        <DialogPrimitive.Close
            className='absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-secondary-600'
            disabled={closeDisabled}
        >
            <span className='h-4 w-4'>
                <XmarkIcon size='sm' />
            </span>
            <span className='sr-only'>{closeButton}</span>
        </DialogPrimitive.Close>
    );
}

const Header = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div className={cn('flex flex-col', className)} {...props} />
);
Header.displayName = 'ModalHeader';

const Footer = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div className={cn('flex justify-end gap-2', className)} {...props} />
);
Footer.displayName = 'ModalFooter';

type TitleProps = ComponentPropsWithoutRef<typeof DialogPrimitive.Title>;

const Title = forwardRef<ElementRef<typeof DialogPrimitive.Title>, TitleProps>(({ className, ...props }, ref) => (
    <DialogPrimitive.Title
        ref={ref}
        className={cn('text-2lg font-semibold text-secondary-900', className)}
        {...props}
    />
));
Title.displayName = 'ModalTitle';

type DescriptionProps = ComponentPropsWithoutRef<typeof DialogPrimitive.Description>;

const Description = forwardRef<ElementRef<typeof DialogPrimitive.Description>, DescriptionProps>(({ className, ...props }, ref) => (
    <DialogPrimitive.Description
        ref={ref}
        className={className}
        {...props}
    />
));
Description.displayName = 'ModalDescription';

export const Modal = {
    Root,
    Portal,
    Overlay,
    Close,
    Trigger,
    Content,
    InnerContent,
    OuterContent,
    Header,
    Footer,
    Title,
    Description,
};
