import type { CountryCode, TimezoneCode } from ':utils/i18n';
import cityToCountry from './cityToCountry.json';
import type { Id } from ':utils/id';

export function getCountryFromTimezone(timezone: TimezoneCode): CountryCode {
    const split = timezone.split('/');
    const city = split[split.length - 1];

    return city in cityToCountry ? cityToCountry[city as keyof typeof cityToCountry] : DEFAULT_COUNTRY;
}

const DEFAULT_COUNTRY = 'CZ';

/** We want to allow 500mb for the users, so this is double just to be sure (just the base64 conversion adds ~30 %). */
export const APP_JSON_BODY_LIMIT = '1gb';

/** The same as {@link APP_JSON_BODY_LIMIT}, but in bytes. */
export const FILE_SIZE_LIMIT_B = 1e9;

/** Make sure the server and nginx limits are well above this value! */
export const MAX_FILE_SIZE_MB = 500;

/**
 * If the new value is undefined, returns the old value.
 * Otherwise, returns the new value.
 */
export function updateFromPatch<TType>(oldValue: TType, newValue: TType | undefined): TType;

/**
 * If the new value is undefined, returns the old value.
 * If the new value is null, returns undefined.
 * Otherwise, returns the new value.
 */
export function updateFromPatch<TType>(oldValue: TType | undefined, newValue: TType | null | undefined): TType | undefined {
    if (newValue === undefined)
        return oldValue;

    return newValue === null ? undefined : newValue;
}

/**
 * Returns an array of the same length as the input ids, except that each id is replaced by the corresponding entity.
 * Throws an error if an entity isn't found.
 * This should be used every time we use the IN operator to fetch entities by their ids, because the database will return them in a random order!
 */
export function sortEntitiesByIds<TEntity extends { id: Id }>(entities: TEntity[], ids: Id[]): TEntity[] {
    const map = mapEntitiesByIds(entities);
    return ids.map(id => map.get(id));
}

/**
 * Throws an error if an entity isn't found.
 */
export type FullMap<TEntity extends { id: Id }> = {
    get(id: Id): TEntity;
};

/**
 * Throws an error if an entity isn't found.
 * @see sortEntitiesByIds
 */
export function mapEntitiesByIds<TEntity extends { id: Id }>(entities: TEntity[]): FullMap<TEntity> {
    const map = new Map(entities.map(entity => [ entity.id, entity ]));
    return {
        get: id => {
            const entity = map.get(id);
            if (!entity)
                throw new Error(`Entity with id: '${id}' not found.`);

            return entity;
        },
    };
}

/** The input should be shorter than 30 characters. Creates 123 charactes in total. */
export function createLongStringForTests(input: string): string {
    const output = [];
    for (let i = 0; i < 4; i++) {
        // Use both g and d to make it easier to spot line-height issues.
        const prefix = `${i}-${input}-gd`;
        const rest = 30 - prefix.length;
        output.push(prefix + 'x'.repeat(rest));
    }

    return output.join(' ');
}

export type SitemapRecord = {
    url:  ({ loc: string } | { lastmod: string })[];
};