import { getCommonProps, pathProps, type IconProps, type IconType } from './common';
import { AscendingSortingIcon, DescendingSortingIcon } from './basic';
import { SortOrder } from ':utils/query';

// These icons are not in the All icons file. Or like, they might have been, but they were modified for some specific use case.

type SwitchIconProps = Readonly<{
    checked?: boolean;
    className?: string;
}>

export function SwitchIcon({ checked, className }: SwitchIconProps) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' className={className}>
            {checked ? (
                <path d='M11.333 5.5 6.75 10.083 4.667 8'/>
            ) : (
                <path d='m10.75 5.25-5.5 5.5m0-5.5 5.5 5.5'/>
            )}
        </svg>
    );
}

export function SwitchLinkIcon({ checked, className }: SwitchIconProps) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none' className={className}>
            <path d='m12.325 8.519-1.68-1.68a2.75 2.75 0 0 0-3.89 3.89l1.68 1.68a2.75 2.75 0 0 0 3.89-3.89' fill='currentColor' fillOpacity='.3'/>
            <path d='M7.511 6.31c-.274.13-.53.31-.757.536l-.007.008a2.75 2.75 0 0 0 0 3.889l1.691 1.692a2.75 2.75 0 0 0 3.89 0l.007-.008a2.75 2.75 0 0 0 0-3.89l-.724-.723M8.289 9.69c.274-.131.53-.31.757-.537l.007-.008a2.75 2.75 0 0 0 0-3.89l-1.691-1.69a2.75 2.75 0 0 0-3.89 0l-.007.007a2.75 2.75 0 0 0 0 3.889l.724.724' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
            {!checked && (
                <path d='M10.528 3.917l.583-1.75m.972 3.306 1.75-.584m-9.916 5.639-1.75.583m3.306.972-.584 1.75' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
            )}
        </svg>
    );
}

type CheckboxIconProps = Readonly<{
    isIndeterminate?: boolean;
    className?: string;
}>;

export function CheckboxIcon({ isIndeterminate, className }: CheckboxIconProps) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none' className={className}>
            {isIndeterminate ? (
                <path d='M1 5h8' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round'/>
            ) : (
                <path d='M2.222 5.222 3.89 6.89 7.778 3' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round'/>
            )}
        </svg>
    );
}

type RadioCheckIconProps = Readonly<{
    className?: string;
}>;

export function RadioCheckIcon({ className }: RadioCheckIconProps) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' className={className}>
            <path d='M14.755 7.417 9.75 14.083a1.25 1.25 0 01-.91.497l-.09.003c-.33 0-.648-.13-.883-.365l-2.505-2.5a1.25 1.25 0 111.77-1.768l1.486 1.483 4.14-5.516a1.25 1.25 0 111.998 1.502z' fill='currentColor'/>
        </svg>
    );
}

type SortOrderIconProps = IconProps & {
    order?: SortOrder;
};

export function SortOrderIcon({ order, ...iconProps }: SortOrderIconProps) {
    if (!order)
        return <UndefinedSortingIcon {...iconProps} />;

    return order === SortOrder.Ascending ? <AscendingSortingIcon {...iconProps} /> : <DescendingSortingIcon {...iconProps} />;
}

export const UndefinedSortingIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M2.75 9.75h5m-5-3.5h5m-5-3.5h10' />
</svg>);

export const CircleEmptyIcon = (props: IconProps) => (<svg {...getCommonProps(props, 16)}>
    <circle cx='8' cy='8' r='7' stroke='currentColor' strokeWidth='2' fill='none' />
</svg>);

export const CircleSemiFullIcon = (props: IconProps) => (<svg {...getCommonProps(props, 16)}>
    <circle cx='8' cy='8' r='7' stroke='currentColor' strokeWidth='2' fill='none' />
    <path d='M8 5c0-.552-.455-1.012-.99-.876a4 4 0 0 0 0 7.752c.535.136.99-.324.99-.876z' stroke='none' fill='currentColor' />
</svg>);

export const CircleMostlyFullIcon = (props: IconProps) => (<svg {...getCommonProps(props, 16)}>
    <circle cx='8' cy='8' r='7' stroke='currentColor' strokeWidth='2' fill='none' />
    <path d='M5 8c-.552 0-1.012.455-.876.99A4 4 0 1 0 8.99 4.124C8.455 3.988 8 4.448 8 5v2a1 1 0 0 1-1 1z' stroke='none' fill='currentColor' />
</svg>);

export const CircleFullIcon = (props: IconProps) => (<svg {...getCommonProps(props, 16)}>
    <circle cx='8' cy='8' r='7' stroke='currentColor' strokeWidth='2' fill='none' />
    <circle cx='8' cy='8' r='4' />
</svg>);

export const LayoutLargeIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M2.75 9.25v.25a1 1 0 0 0 1 1h10.5a1 1 0 0 0 1-1v-.25a1 1 0 0 0-1-1H3.75a1 1 0 0 0-1 1m0 4.75v.25a1 1 0 0 0 1 1h10.5a1 1 0 0 0 1-1V14a1 1 0 0 0-1-1H3.75a1 1 0 0 0-1 1' />
    <path {...pathProps.strokeFill} d='M15.25 4.75v-1a1 1 0 0 0-1-1H3.75a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h10.5a1 1 0 0 0 1-1' />
</svg>);

export const LayoutSmallLeftIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M2.75 9.25v.25a1 1 0 0 0 1 1h10.5a1 1 0 0 0 1-1v-.25a1 1 0 0 0-1-1H3.75a1 1 0 0 0-1 1m0 4.75v.25a1 1 0 0 0 1 1h10.5a1 1 0 0 0 1-1V14a1 1 0 0 0-1-1H3.75a1 1 0 0 0-1 1' />
    <path {...pathProps.strokeFill} d='M6.25 4.75v-1a1 1 0 0 0-1-1h-1.5a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1.5a1 1 0 0 0 1-1' />
</svg>);

export const LayoutMediumLeftIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M16 14V3.75a1 1 0 0 0-1-1h-2.5a1 1 0 0 0-1 1V14a1 1 0 0 0 1 1H15a1 1 0 0 0 1-1' />
    <path {...pathProps.strokeFill} d='M8.75 13.75v-10a1 1 0 0 0-1-1h-3.5a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h3.5a1 1 0 0 0 1-1' />
</svg>);

export const LayoutMediumRightIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path {...pathProps.stroke} d='M7 14V3.75a1 1 0 0 0-1-1H3.5a1 1 0 0 0-1 1V14a1 1 0 0 0 1 1H6a1 1 0 0 0 1-1' />
    <path {...pathProps.strokeFill} d='M15.25 13.75v-10a1 1 0 0 0-1-1h-3.5a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h3.5a1 1 0 0 0 1-1' />
</svg>);

export const TextBoldIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path fill='currentColor' d='M11.438 8.85a2.76 2.76 0 0 0 1.313-2.1 2.947 2.947 0 0 0-2.895-3H4.988v10.5h5.25a2.804 2.804 0 0 0 2.775-2.835v-.09a2.73 2.73 0 0 0-1.575-2.475m-4.95-3.6h3.15a1.57 1.57 0 0 1 1.5.975 1.57 1.57 0 0 1-1.027 1.957 1.7 1.7 0 0 1-.473.068h-3.15zm3.45 7.5h-3.45v-3h3.45a1.57 1.57 0 0 1 1.5.975 1.57 1.57 0 0 1-1.027 1.957 1.7 1.7 0 0 1-.473.068'/>
</svg>);

export const TextItalicIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path fill='currentColor' d='M8.82 6.75h1.5l-1.65 7.5h-1.5zm1.26-3a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5'/>
</svg>);

export const TextUnderlineIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path fill='currentColor' d='M14.25 15v1.5H3.75V15zM12 9.911a3 3 0 0 1-4.305 2.55A2.81 2.81 0 0 1 6 9.814v-6.06H4.5V9.91a4.5 4.5 0 0 0 6 4.08 4.39 4.39 0 0 0 3-4.237v-6H12zm0-6.161h1.5zm-6 0H4.5z'/>
</svg>);

export const TextCodeIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path fill='currentColor' d='M 9.79 2.58 l 1.44 0.35 L 8.21 15.42 L 6.77 15.07 Z M 2.8 9 l 3.5 -3.5 l -1.06 -1.06 l -4.56 4.56 l 4.56 4.56 l 1.06 -1.06 Z M 15.2 9 l -3.5 -3.5 l 1.06 -1.06 l 4.56 4.56 l -4.56 4.56 l -1.06 -1.06 Z'/>
</svg>);

export const AlignLeftIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path fill='currentColor' d='M2.25 13.5h4.5V12h-4.5zm0-5.25v1.5h9v-1.5zm0-3.75V6h13.5V4.5z'/>
</svg>);

export const AlignCenterIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path fill='currentColor' d='M6.75 13.5h4.5V12h-4.5zM4.5 8.25v1.5h9v-1.5zM2.25 4.5V6h13.5V4.5z'/>
</svg>);

export const AlignRightIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path fill='currentColor' d='M11.25 13.5h4.5V12h-4.5zm-4.5-5.25v1.5h9v-1.5zM2.25 4.5V6h13.5V4.5z'/>
</svg>);

export const ListUnorderedIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path fill='currentColor' d='M3 7.875c-.6 0-1.125.525-1.125 1.125S2.4 10.125 3 10.125 4.125 9.6 4.125 9 3.6 7.875 3 7.875m0-3.75c-.6 0-1.125.525-1.125 1.125S2.4 6.375 3 6.375 4.125 5.85 4.125 5.25 3.6 4.125 3 4.125m0 7.5c-.6 0-1.125.525-1.125 1.125S2.4 13.875 3 13.875s1.125-.525 1.125-1.125S3.6 11.625 3 11.625M5.625 4.5V6h10.5V4.5zm0 9h10.5V12h-10.5zm0-3.75h10.5v-1.5h-10.5z'/>
</svg>);

export const ListOrderedIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path fill='currentColor' d='M1.875 12h1.5v.375h-.75v.75h.75v.375h-1.5v.75h2.25v-3h-2.25zm.75-5.25h.75v-3h-1.5v.75h.75zm-.75 1.5h1.35l-1.35 1.575v.675h2.25v-.75h-1.35l1.35-1.575V7.5h-2.25zm3.75-3.75V6h10.5V4.5zm0 9h10.5V12h-10.5zm0-3.75h10.5v-1.5h-10.5z'/>
</svg>);

export const InsertLinkIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path fill='currentColor' d='M8.25 12.75h-3a3.75 3.75 0 0 1 0-7.5h3v1.5h-3a2.25 2.25 0 1 0 0 4.5h3zm4.5-7.5h-3v1.5h3a2.25 2.25 0 0 1 0 4.5h-3v1.5h3a3.75 3.75 0 0 0 0-7.5m-.75 3H6v1.5h6z'/>
</svg>);

export const InsertImageIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path fill='currentColor' d='M10.65 8.25 13.5 12h-9l2.25-2.925L8.325 11.1 10.5 8.25zm-4.275 0c.6 0 1.125-.525 1.125-1.125S6.975 6 6.375 6 5.25 6.525 5.25 7.125 5.775 8.25 6.375 8.25M16.5 4.5v9c0 .825-.675 1.5-1.5 1.5H3c-.825 0-1.5-.675-1.5-1.5v-9C1.5 3.675 2.175 3 3 3h12c.825 0 1.5.675 1.5 1.5M15 6.6V4.5H3v9h12z'/>
</svg>);

export const InsertEmojiIcon = (props: IconProps) => (<svg {...getCommonProps(props)}>
    <path fill='currentColor' d='M8.993 2.25a6.75 6.75 0 1 0 .014 13.5 6.75 6.75 0 0 0-.014-13.5m.007 12a5.25 5.25 0 1 1 0-10.5 5.25 5.25 0 0 1 0 10.5m2.329-3.9h1.127a3.71 3.71 0 0 1-6.912 0h1.127a2.684 2.684 0 0 0 4.658 0M5.625 7.313a1.013 1.013 0 1 1 2.025 0 1.013 1.013 0 0 1-2.025 0m4.725 0a1.013 1.013 0 1 1 2.026 0 1.013 1.013 0 0 1-2.026 0'/>
</svg>);

export const customIcons: IconType[] = [
    (props: IconProps) => <SwitchIcon {...props} />,
    (props: IconProps) => <SwitchIcon {...props} checked />,
    (props: IconProps) => <CheckboxIcon {...props} />,
    (props: IconProps) => <CheckboxIcon {...props} isIndeterminate />,
    (props: IconProps) => <SortOrderIcon {...props} />,
    (props: IconProps) => <SortOrderIcon {...props} order={SortOrder.Descending} />,
    CircleEmptyIcon,
    CircleSemiFullIcon,
    CircleMostlyFullIcon,
    CircleFullIcon,
    LayoutLargeIcon,
    LayoutSmallLeftIcon,
    LayoutMediumLeftIcon,
    LayoutMediumRightIcon,
    TextBoldIcon,
    TextItalicIcon,
    TextUnderlineIcon,
    TextCodeIcon,
    AlignLeftIcon,
    AlignCenterIcon,
    AlignRightIcon,
    ListUnorderedIcon,
    ListOrderedIcon,
    InsertLinkIcon,
    InsertImageIcon,
    InsertEmojiIcon,
];
