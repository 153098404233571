import { z, type ZodSchema } from 'zod';
import { zId } from ':utils/id';
import { zCustomItemInit, zEventItemInit, zOrderItemEdit, zOrderItemOutput } from './orderItem';
import { zClientInfoOutput, zClientOrContactInit } from './client';
import { DocumentType, OrderState, OrderTransition, PaymentMethod, zInvoicingIdentityOutput, zInvoicingIdentityUpdate } from './invoicing';
import { zDateTime, zDateRange, PredefinedRangeType } from ':utils/dateTime';
import { zSort, zPage } from ':utils/query';
import { type DateTime } from 'luxon';
import { ProductType } from './product';
import { zVisitOutput } from './store';
import { zCurrencyId } from ':utils/money';

// Logs

export enum OrderLogType {
    Created = 'order.created',
    NotificationSent = 'order.notificationSent',
    Updated = 'order.updated',
    Transition = 'order.transition',
}

export enum OrderCreatedFrom {
    App = 'app',
    Store = 'store',
}

export function zLogOutputFunction<TData extends ZodSchema>(data: TData) {
    return z.object({
        id: zId,
        createdAt: zDateTime,
        data,
    });
}

const zOrderDiscount = z.object({
    title: z.string(),
    rate: z.number().min(0).max(1),
});
export type OrderDiscount = z.infer<typeof zOrderDiscount>;

export const zOrderLogData = z.discriminatedUnion('type', [
    z.object({
        type: z.literal(OrderLogType.Created),
    }),
    z.object({
        type: z.literal(OrderLogType.NotificationSent),
        email: z.string(),
    }),
    z.object({
        type: z.literal(OrderLogType.Updated),
    }),
    z.object({
        type: z.literal(OrderLogType.Transition),
        before: z.nativeEnum(OrderState),
        after: z.nativeEnum(OrderState),
        transition: z.nativeEnum(OrderTransition),
        isAutomatic: z.boolean(),
    }),
]);

export const zOrderLogOutput = zLogOutputFunction(zOrderLogData);

// Outputs

/** A product type (if all items are products items of the same product type). Otherwise it's a direct sale. */
export type OrderType = z.infer<typeof zOrderType>;
const zOrderType = z.nativeEnum(ProductType).or(z.literal('direct'));

export const orderTypeLabels: Record<OrderType, string> = {
    [ProductType.Session]: 'Meeting',
    [ProductType.Bundle]: 'Package',
    [ProductType.Digital]: 'Digital',
    [ProductType.Lead]: 'Lead',
    [ProductType.Membership]: 'Membership',
    [ProductType.Link]: 'Link',
    [ProductType.Referral]: 'Referral',
    [ProductType.Custom]: 'Custom',
    direct: 'Direct sale',
};

export type CommonOrderOutput = z.infer<typeof zCommonOrderOutput>;
const zCommonOrderOutput = z.object({
    id: zId,
    documentType: z.nativeEnum(DocumentType),
    type: zOrderType,
    client: zClientInfoOutput,
    prefix: z.string().optional(),
    index: z.number().optional(),
    title: z.string(),
    total: z.number(),
    currency: zCurrencyId,
    state: z.nativeEnum(OrderState),
    createdAt: zDateTime,
    issueDate: zDateTime,
    paymentMethod: z.nativeEnum(PaymentMethod),
});

export type OrderInfoOutput = z.infer<typeof zOrderInfoOutput>;
export const zOrderInfoOutput = zCommonOrderOutput.extend({
    /** AppUser */
    schedulerIds: z.array(zId),
});

export type SchedulerOrderInfoOutput = z.infer<typeof zSchedulerOrderInfoOutput>;
export const zSchedulerOrderInfoOutput = z.object({
    id: zId,
    client: zClientInfoOutput,
    title: z.string(),
    state: z.nativeEnum(OrderState),
    createdAt: z.string(),  // TODO zDateTime ?
});

export type OrderCustomFields = z.infer<typeof zOrderCustomFields>;
export const zOrderCustomFields = z.object({
    header: z.string().optional(),
    footer: z.string().optional(),
    customKey1: z.string().optional(),
    customValue1: z.string().optional(),
    customKey2: z.string().optional(),
    customValue2: z.string().optional(),
});

export type OrderOutput = z.infer<typeof zOrderOutput>;
export const zOrderOutput = zCommonOrderOutput.merge(zOrderCustomFields).extend({
    supplier: zInvoicingIdentityOutput,
    subscriber: zInvoicingIdentityOutput,
    variableSymbol: z.string().optional(),
    items: z.array(zOrderItemOutput),
    logs: z.array(zOrderLogOutput),
    isNotificationSent: z.boolean(),
    dueDate: zDateTime,
    taxDate: zDateTime,
    condensedInvoice: z.boolean(),
    discount: zOrderDiscount.optional(),
});

export function generateVariableSymbol(issueDate: DateTime, index: number): string {
    return issueDate.toFormat('yymm') + index.toString().padStart(4, '0');
}

export type NotificationInit = z.infer<typeof zNotificationInit>;
export const zNotificationInit = z.object({
    /** If undefined, the client's email will be used instead. */
    email: z.string().optional(),
    cc: z.array(z.string()).optional(),
    subject: z.string(),
    body: z.string(),
});

export const emailVariables = {
    invoiceLink: '#invoiceLink#',
    receiptLink: '#receiptLink#',
    paymentLink: '#paymentLink#',
} as const;
export type EmailVariable = typeof emailVariables[keyof typeof emailVariables];

const zOrderInitBase = z.object({
    paymentMethod: z.nativeEnum(PaymentMethod),
    dueDays: z.number().optional(),
    notification: zNotificationInit.optional(),
    createdFrom: z.nativeEnum(OrderCreatedFrom),
    discount: zOrderDiscount.optional(),
});

export type EventOrderInit = z.infer<typeof zEventOrderInit>;
export const zEventOrderInit = zOrderInitBase.extend({
    /** There should be one item for each client. */
    eventItems: z.array(zEventItemInit),
});

export type ProductOrderInit = z.infer<typeof zProductOrderInit>;
export const zProductOrderInit = zOrderInitBase.extend({
    // TODO remove title and calculate it on BE
    title: z.string(),
    client: zClientOrContactInit,
    guest: zClientOrContactInit,
    scheduler: zId.optional(),

    /** Product */
    productItems: z.array(zId),
});

export type CustomOrderInit = z.infer<typeof zCustomOrderInit>;
export const zCustomOrderInit = zOrderInitBase.extend({
    // TODO remove title and calculate it on BE
    title: z.string(),
    client: zClientOrContactInit,

    currency: zCurrencyId,
    items: z.array(zCustomItemInit),
});

export type OrderInit = z.infer<typeof zOrderInit>;
export const zOrderInit = z.union([
    z.object({
        event: zEventOrderInit,
    }),
    z.object({
        product: zProductOrderInit,
    }),
    z.object({
        custom: zCustomOrderInit,
    }),
]);

/** Like PATCH. */
export type OrderEdit = z.infer<typeof zOrderEdit>;
export const zOrderEdit = z.object({
    prefix: z.string(),
    index: z.number(),
    title: z.string(),
    variableSymbol: z.string(),
    issueDate: zDateTime,
    dueDate: zDateTime,
    taxDate: zDateTime,
    condensedInvoice: z.boolean(),
    /** Like PUT - if anything changes, the object is replaced. */
    subscriber: zInvoicingIdentityUpdate,
    /** Like PUT - if anything changes, the object is replaced. */
    supplier: zInvoicingIdentityUpdate,
    /** Like PUT - if anything changes, the object is replaced. */
    fields: zOrderCustomFields,
    /** Like PUT - if anything changes on a specific item, the item is replaced. */
    orderItems: z.array(zOrderItemEdit),
}).partial().extend({
    id: zId,
});

export type OrderStatsOrder = z.infer<typeof zOrderStatsOrder>;
const zOrderStatsOrder = z.object({
    createdAt: zDateTime,
    /** in cents */
    total: z.number(),
    state: z.nativeEnum(OrderState),
    createdFrom: z.nativeEnum(OrderCreatedFrom),
});

/**
 * There are two distinct types of orders:
 * - leads (for now, orders with price equal to 0)
 * - sales (for now, orders with price not equal to 0)
 */
export function isOrderLead(order: { total: number }): boolean {
    return order.total === 0;
}

export type ProductStatsProduct = z.infer<typeof zProductStatsProduct>;
export const zProductStatsProduct = z.object({
    id: zId,
    type: z.nativeEnum(ProductType),
    title: z.string(),
    ordersCount: z.number(),
    /** in cents */
    ordersValue: z.number(),
});

export type OrderStatsProduct = z.infer<typeof zOrderStatsProduct>;
const zOrderStatsProduct = zProductStatsProduct.extend({
    clicks: z.number(),
});

export type OrderStatsOutput = z.infer<typeof zOrderStatsOutput>;
export const zOrderStatsOutput = z.object({
    visits: z.array(zVisitOutput),
    clicks: z.array(zVisitOutput),
    orders: zOrderStatsOrder.array(),
    products: zOrderStatsProduct.array(),
    range: zDateRange,
    rangeType: z.nativeEnum(PredefinedRangeType),
    currency: zCurrencyId,
});

export function isStripeOrder({ paymentMethod }: { paymentMethod: PaymentMethod }): boolean {
    return paymentMethod === PaymentMethod.stripe;
}

export const zOrderIndex = z.number().int().min(1).max(9999);

export type GetOrdersQuery = z.infer<typeof zGetOrdersQuery>;
export const zGetOrdersQuery = z.object({
    type: z.array(zOrderType),
    state: z.array(z.nativeEnum(OrderState)),
    client: z.array(zId),
    invoicingProfile: zId,
    createdAtStart: zDateTime,
    createdAtEnd: zDateTime,

    page: zPage,
    sort: zSort([ 'createdAt' ]),
}).partial();

export enum ExportOrdersFormat {
    PdfZip = 'pdf-zip',
    Pdf = 'pdf',
    Csv = 'csv',
    Isdoc = 'isdoc',
}

export const documentExportOrderFormats = [ ExportOrdersFormat.Pdf, ExportOrdersFormat.PdfZip, ExportOrdersFormat.Isdoc ];

export type ExportOrdersQuery = z.infer<typeof zExportOrdersQuery>;
export const zExportOrdersQuery = z.object({
    documentOnly: z.boolean().optional(),
    format: z.nativeEnum(ExportOrdersFormat),
    type: z.array(zOrderType).optional(),
    state: z.array(z.nativeEnum(OrderState)).optional(),
    createdAtStart: zDateTime.optional(),
    createdAtEnd: zDateTime.optional(),
    client: z.array(zId).optional(),
});
