import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination } from ':frontend/components/common/Pagination';
import { Table } from ':frontend/components/common/Table';
import { MoneyDisplayString } from ':components/custom';
import { OrderInfoFE, SchedulerOrderInfoFE } from ':frontend/types/orders/Order';
import { OrderStateBadge } from ':frontend/components/orders/OrderStateBadge';
import { getSkeletonArray } from ':utils/math';
import { usePagination, type PaginationController } from ':frontend/hooks';
import { routesBE, routesFE } from ':utils/routes';
import { ClientIconLink } from ':frontend/components/client/ClientIconLink';
import { FilterRow, type UseFiltersControl } from ':frontend/components/common/filters/FilterRow';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import { type TFunction } from 'i18next';
import { TeamMemberBadge } from '../team/TeamMemberBadge';
import { TeamMemberRole } from ':utils/entity/team';
import { orderTypeLabels, type GetOrdersQuery, type OrderInfoOutput, type OrderType, type SchedulerOrderInfoOutput } from ':utils/entity/order';
import { trpc } from ':frontend/context/TrpcProvider';
import { DateTimeDisplay } from ':components/custom/DateTimeDisplay';
import { InvoiceIcon, Receipt1Icon } from ':components/icons/basic';
import { useMaster } from ':frontend/context/UserProvider';
import { productStyles } from ':components/store/product/ProductCard';
import { cn } from ':components/shadcn/utils';
import { DocumentType, PaymentMethod } from ':utils/entity/invoicing';
import clsx from 'clsx';
import type { ProductType } from ':utils/entity/product';
import { SortOrder } from ':utils/query';
import { SortButton, useSort, type UseSortControl } from '../common/SortButton';

const sortKeys = [ 'createdAt' ] as const;
type SortControl = UseSortControl<typeof sortKeys[number]>;

type CommonOrderInfo = OrderInfoFE | SchedulerOrderInfoFE;

type OrdersTableProps = Readonly<{
    filtersControl: UseFiltersControl;
    filtersToServer: (control: UseFiltersControl) => GetOrdersQuery;
    showClients?: boolean;
}>;

export function OrdersTable({ filtersControl, filtersToServer, showClients }: OrdersTableProps) {
    const sort = useSort({ key: 'createdAt', order: SortOrder.Descending }, sortKeys);

    // TODO Index descending order by

    const pagination = usePagination();
    const { page, reset } = pagination;
    useEffect(() => reset(), [ filtersControl.state, reset ]);

    const isMasterOrFreelancer = !!toMaster(useUser());
    const { isInvoicingModuleOn } = useMaster().teamSettings;

    const filtredOrdersOutputs = trpc.order.getOrders.useQuery({
        ...filtersToServer(filtersControl),
        sort: sort.state,
        page,
    });

    const orders = useMemo(() => {
        if (!filtredOrdersOutputs.data)
            return;
        const filtredOrders = isMasterOrFreelancer
            ? (filtredOrdersOutputs.data as OrderInfoOutput[]).map(OrderInfoFE.fromServer)
            : (filtredOrdersOutputs.data as SchedulerOrderInfoOutput[]).map(SchedulerOrderInfoFE.fromServer);
        return filtredOrders;
    }, [ filtredOrdersOutputs, isMasterOrFreelancer ]);

    return (
        <div>
            <FilterRow control={filtersControl} />

            <div className='mt-2 fl-hide-scrollbar overflow-x-auto max-lg:-mx-4'>
                <div className='max-lg:px-4 max-lg:w-fit'>
                    <Table py={3} className='bg-white'>
                        <OrdersHeader orders={orders} showClients={!!showClients} isInvoicingModuleOn={isInvoicingModuleOn} sort={sort} />

                        <Table.Body>
                            <OrdersList orders={orders} showClients={!!showClients} pagination={pagination} />
                        </Table.Body>
                    </Table>
                </div>
            </div>

            <Pagination controller={pagination} className='mt-4' />
        </div>
    );
}

type OrdersHeaderProps = Readonly<{
    orders?: CommonOrderInfo[];
    showClients: boolean;
    isInvoicingModuleOn: boolean;
    sort: SortControl;
}>;

function OrdersHeader({ orders, showClients, isInvoicingModuleOn, sort }: OrdersHeaderProps) {
    const { t } = useTranslation('components', { keyPrefix: 'ordersTable' });
    const userContext = useUser();
    const isMasterOrFreelancer = !!toMaster(userContext);

    if (!orders)
        return <Table.HeaderSkeleton height={17} />;

    return isMasterOrFreelancer
        ? masterOrdersHeader(showClients, isInvoicingModuleOn, userContext.role === TeamMemberRole.master, sort, t)
        : schedulerOrdersHeader(showClients, sort, t);
}

function masterOrdersHeader(showClients: boolean, isInvoicingModuleOn: boolean, showScheduler: boolean, sort: SortControl, t: TFunction) {
    return (
        <Table.Header>
            <Table.HeaderCol>{t('type-label')}</Table.HeaderCol>
            <Table.HeaderCol>{t('title-label')}</Table.HeaderCol>

            {showClients && (
                <Table.HeaderCol xs={2}>{t('client-label')}</Table.HeaderCol>
            )}

            <Table.HeaderCol className='whitespace-nowrap'>
                <div className='flex items-center gap-1'>
                    <div>{t('created-label')}</div>

                    <SortButton sort={sort} name='createdAt' />
                </div>
            </Table.HeaderCol>
            <Table.HeaderCol className='text-right'>{t('price-label')}</Table.HeaderCol>
            <Table.HeaderCol className='text-right'>{t('state-label')}</Table.HeaderCol>
            <Table.HeaderCol xs='fit' className='text-right'>{t(isInvoicingModuleOn ? 'invoice-label' : 'receipt-label')}</Table.HeaderCol>
            {showScheduler && (
                <Table.HeaderCol xs='fit' className='whitespace-nowrap'>{t('scheduler-label')}</Table.HeaderCol>
            )}
        </Table.Header>
    );
}

function schedulerOrdersHeader(showClients: boolean, sort: SortControl, t: TFunction) {
    return (
        <Table.Header>
            <Table.HeaderCol xs={6}>{t('title-label')}</Table.HeaderCol>

            {showClients && (
                <Table.HeaderCol xs={2}>{t('client-label')}</Table.HeaderCol>
            )}

            <Table.HeaderCol className='whitespace-nowrap'>
                <div className='flex items-center gap-1'>
                    <div>{t('created-label')}</div>

                    <SortButton sort={sort} name='createdAt' />
                </div>
            </Table.HeaderCol>
            <Table.HeaderCol className='text-center'>{t('state-label')}</Table.HeaderCol>
        </Table.Header>
    );
}

type OrdersListProps = Readonly<{
    orders?: CommonOrderInfo[];
    showClients: boolean;
    pagination: PaginationController;
}>;

function OrdersList({ orders, showClients, pagination }: OrdersListProps) {
    const { t } = useTranslation('components', { keyPrefix: 'ordersTable' });
    const userContext = useUser();
    const isMasterOrFreelancer = !!toMaster(userContext);

    if (!orders)
        return <>{getSkeletonArray(pagination).map(id => <Table.RowSkeleton key={id} height={20} />)}</>;

    if (orders.length === 0) {
        return (
            <Table.Row>
                <Table.Col colSpan={7} >
                    <div className='text-center text-danger-500 text-lg py-12'>
                        {t('no-orders-text')}
                    </div>
                </Table.Col>
            </Table.Row>
        );
    }

    return (<>
        {orders.map(order => isMasterOrFreelancer
            ? masterOrderRow(order as OrderInfoFE, showClients, userContext.role === TeamMemberRole.master)
            : schedulerOrderRow(order as SchedulerOrderInfoFE, showClients),
        )}
    </>);
}

function masterOrderRow(order: OrderInfoFE, showClients: boolean, showScheduler: boolean) {
    const link = routesFE.orders.detail.resolve({ id: order.id });
    const styles = order.type !== 'direct' ? productStyles[order.type] : undefined;

    return (
        <Table.Row className='hover:bg-secondary-50' key={order.id}>
            <Table.Col><OrderTypeBadge type={order.type} /></Table.Col>
            <Table.Col link={link} truncate className={cn('max-w-xs font-semibold', styles?.color)}>
                {order.title}
            </Table.Col>

            {showClients && (
                <Table.Col xs={2} className='max-w-xs'>
                    <ClientIconLink client={order.client} />
                </Table.Col>
            )}

            <Table.Col link={link}><DateTimeDisplay date dateTime={order.createdAt} /></Table.Col>
            <Table.Col link={link} className='text-right'><MoneyDisplayString money={order.price} /></Table.Col>

            <Table.Col link={link} className='text-right'>
                <div className='inline-block'><OrderStateBadge order={order} /></div>
            </Table.Col>

            <Table.Col xs='fit' className='text-center leading-0'>
                {order.paymentMethod !== PaymentMethod.noInvoice && (
                    <a href={routesBE.public.document.resolve({ id: order.id })} target='_blank' rel='noopener' className='inline-block'>
                        {order.documentType === DocumentType.Invoice && <InvoiceIcon size='md' />}
                        {order.documentType === DocumentType.Receipt && <Receipt1Icon size='md' />}
                    </a>
                )}
            </Table.Col>

            {showScheduler && (
                <Table.Col xs='fit' truncate className='select-none'>
                    <div className='flex justify-center gap-2'>
                        {order.schedulerIds.map(id => (
                            <TeamMemberBadge key={id} appUserId={id} />
                        ))}
                    </div>
                </Table.Col>
            )}
        </Table.Row>
    );
}

export function OrderTypeBadge({ type }: Readonly<{ type: OrderType }>) {
    const label = orderTypeLabels[type];

    if (type === 'direct') {
        return (
            <div className={clsx('px-[5px] py-px w-fit h-5 rounded-xs text-sm border')}>
                {label}
            </div>
        );
    }

    const styles = productStyles[type as ProductType];
    return (
        <div className={clsx('px-[6px] py-[2px] w-fit h-5 rounded-xs text-sm', styles.bg, styles.color)}>
            {label}
        </div>
    );
}

function schedulerOrderRow(order: SchedulerOrderInfoFE, showClients: boolean) {
    return (
        <Table.Row className='hover:bg-secondary-50' key={order.id}>
            <Table.Col xs={6} truncate>{order.title}</Table.Col>
            {showClients && (
                <Table.Col xs={2} truncate>
                    <ClientIconLink client={order.client} />
                </Table.Col>
            )}
            <Table.Col><DateTimeDisplay date dateTime={order.createdAt} /></Table.Col>
            <Table.Col><OrderStateBadge order={order} /></Table.Col>
        </Table.Row>
    );
}

