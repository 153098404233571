import { z } from 'zod';
import { zId } from ':utils/id';
import { zLocaleCode, zTimezoneCode } from ':utils/i18n';
import { zCanonizedEmail } from ':utils/forms';
import { isStoreSlugValid } from ':utils/common';
import { zPromoCode } from './team';

/** This type is used in JSON property, so it has to use only JSON-compatible properties. */
export type GoogleIntegration = z.infer<typeof zGoogleIntegration>;
const zGoogleIntegration = z.object({
    refreshToken: z.string(),
    calendarId: z.string().optional(),
    contacts: z.boolean().optional(),
});

const zGoogleIntegrationOutput = zGoogleIntegration.omit({ refreshToken: true });

export type AppUserOutput = z.infer<typeof zAppUserOutput>;
export const zAppUserOutput = z.object({
    id: zId,
    firstName: z.string(),
    lastName: z.string().optional(),
    email: z.string(),
    phoneNumber: z.string().optional(),
    google: zGoogleIntegrationOutput.optional(),
});

export type AppUserGoogleInit = z.infer<typeof zAppUserGoogleInit>;
export const zAppUserGoogleInit = z.object({
    timezone: zTimezoneCode,
    locale: zLocaleCode,
    storeSlug: z.string(),
    promoCode: zPromoCode.optional(),
});

export type AppUserInit = z.infer<typeof zAppUserInit>;
export const zAppUserInit = zAppUserGoogleInit.extend({
    email: zCanonizedEmail,
    password: z.string().min(8).max(255),
    firstName: z.string(),
    lastName: z.string().optional(),
    storeSlug: z.string().refine(isStoreSlugValid),
});

/** Like PUT. */
export type AppUserEdit = z.infer<typeof zAppUserEdit>;
export const zAppUserEdit = z.object({
    firstName: z.string(),
    lastName: z.string(),
    phoneNumber: z.string().optional(),
});

export type OnboardingStateOutput = z.infer<typeof zOnboardingStateOutput>;
export const zOnboardingStateOutput = z.object({
    storeSetUp: z.boolean(),
    productUpserted: z.boolean(),
    paymentMethodConnected: z.boolean(),
    clientCreated: z.boolean(),
});

export function isOnboardingFinished(state: OnboardingStateOutput): boolean {
    return state.storeSetUp && state.productUpserted && state.paymentMethodConnected && state.clientCreated;
}
