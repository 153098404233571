import { type FilterDefinition, type FilterFunction, type FilterMenuProps } from './FilterRow';
import { Form, Button, DropdownMenu } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { compareArrays } from ':frontend/utils/common';
import { type OrderInfoFE, ORDER_STATE_VALUES } from ':frontend/types/orders/Order';
import { OrderStateBadge } from ':frontend/components/orders/OrderStateBadge';
import { OrderState } from ':utils/entity/invoicing';
import { CircleCopy1Icon } from ':components/icons/basic';

const filterName = 'orderState';

type FilterState = {
    [key in OrderState]: boolean;
};

function RowMenu({ state, setState }: FilterMenuProps<FilterState>) {
    const { t } = useTranslation('common', { keyPrefix: 'filters' });
    const selectedStates = ORDER_STATE_VALUES.filter(orderState => state[orderState]);

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <Button variant='outline' className={selectedStates.length > 0 ? 'border-primary text-primary hover:border-primary' : undefined}>
                    {selectedStates.length > 0 ? (
                        selectedStates.map(orderState => <OrderStateBadge key={orderState} state={orderState} />)
                            .flatMap((e, index) => [ e, <span key={`or_${index}`}>/</span> ])
                            .slice(0, -1)
                    ) : (
                        <div className='flex items-center gap-2'>
                            <CircleCopy1Icon size='md' /> {t(`${filterName}.menu-button`)}
                        </div>
                    )}
                </Button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Content side='bottom' align='start'>
                <div className='p-1.5'>
                    {ORDER_STATE_VALUES.map(orderState => (
                        <div
                            key={orderState}
                            className='flex items-center gap-4 px-4 py-3 rounded-sm cursor-pointer hover:bg-primary-100'
                            onClick={() => setState({ ...state, [orderState]: !state[orderState] })}
                        >
                            <Form.Checkbox checked={state[orderState]} />
                            <OrderStateBadge state={orderState} />
                        </div>
                    ))}
                </div>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
}

function createFilterFunction(state: FilterState): FilterFunction<OrderInfoFE> {
    const selectedStates = ORDER_STATE_VALUES.filter(orderState => state[orderState]);
    if (selectedStates.length === 0 || selectedStates.length === ORDER_STATE_VALUES.length)
        return () => true;

    return (data: OrderInfoFE) => !!state[data.state];
}

function toServer(state: FilterState, previous: string[] | undefined): string[] | undefined {
    const current = ORDER_STATE_VALUES.filter(orderState => state[orderState]);
    if (current.length === 0 || current.length === ORDER_STATE_VALUES.length)
        return;

    return previous && compareArrays(current, previous)
        ? previous
        : current;
}

export const OrderStateFilter: FilterDefinition<FilterState, undefined, OrderInfoFE, string[]> = {
    name: filterName,
    defaultState: {
        [OrderState.fulfilled]: false,
        [OrderState.new]: false,
        [OrderState.overdue]: false,
        [OrderState.canceled]: false,
    },
    RowMenu,
    createFilterFunction,
    toServer,
};
