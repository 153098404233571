import { AppsumoLogo, flowlanceIcons } from ':components/icons/logos';
import { Form, SpinnerButton } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { trpc } from ':frontend/context/TrpcProvider';
import { usePostHog } from 'posthog-js/react';
import { ErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { PROMO_CODE_KEY } from '../auth/Register';

const APPSUMO_PROMO_CODE = 'appsumo';

export default function AppsumoCode() {
    const { t } = useTranslation('pages', { keyPrefix: 'appsumoCode' });

    const [ input, setInput ] = useState('');
    const [ isFetching, setIsFetching ] = useState(false);
    /** The last code that was found invalid. */
    const [ error, setError ] = useState<string>();
    const isError = input === error;

    const posthog = usePostHog();
    const navigate = useNavigate();

    const utils = trpc.useUtils();

    async function submit() {
        if (!input) {
            setError('');
            return;
        }

        const promoCode = {
            type: APPSUMO_PROMO_CODE,
            value: input,
        };

        setIsFetching(true);
        const response = await utils.team.validatePromoCode.fetch(promoCode);

        posthog?.capture('PromoCodeValidated', { ...promoCode, isValid: response.isValid });

        if (!response.isValid) {
            setIsFetching(false);
            setError(input);
            return;
        }

        navigate(routesFE.auth.claimUrl.path, { state: {
            [PROMO_CODE_KEY]: promoCode,
        } });
    }

    return (
        <div className='min-w-80 h-screen w-screen flex md:items-center md:justify-center overflow-x-hidden'>
            <div className='w-full max-w-lg p-4 flex flex-col items-center gap-4'>
                <div className='w-full h-20 flex items-center justify-between'>
                    <flowlanceIcons.FlowlanceBanner />

                    <AppsumoLogo className='w-40 h-20' />
                </div>

                <h1 className='text-secondary-800 text-2xl font-semibold leading-8 md:text-4xl md:leading-10 text-center whitespace-pre-line'>{t('page-title')}</h1>

                <p className='mt-2'>{t('page-description')}</p>

                <div className='mt-4'>
                    <Form.Input
                        // The input has to have font size at least 16px, because iOS would zoom in on it otherwise. And that is a really unpleasant user experience. Thanks, Apple, again.
                        // https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
                        // Setting maximum-scale=1 would break the zooming functionality for other devices.
                        // We decided to use this settings for all inputs on unauthenticated pages.
                        className='mx-auto w-60 items-baseline text-lg md:text-base'
                        name='code'
                        label={t('code-label')}
                        labelClassName='sr-only'
                        value={input}
                        onChange={e => setInput(e.target.value)}
                        isError={isError}
                        autoFocus
                        spellCheck={false}
                        onKeyUp={e => e.key === 'Enter' && submit()}
                    />
                    {isError ? (
                        <ErrorMessage message={t('code-error')} className='h-4' />
                    ) : (
                        <div className='h-6' />
                    )}
                </div>

                <SpinnerButton className='w-60 mx-auto' onClick={submit} isFetching={isFetching}>
                    {t('validate-button')}
                </SpinnerButton>
            </div>

            <div className='max-md:hidden w-96 h-96 rounded-full bg-primary-500 blur-[150px] fixed -bottom-10 -right-10' />
        </div>
    );
}
