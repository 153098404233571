import { useCallback, useId, useMemo, useState } from 'react';
import { type ClientInfoFE } from ':frontend/types/Client';
import { useTranslation } from 'react-i18next';
import { type PaymentState, type UseEventDispatch, type UseEventState } from './useEvent';
import { Button, Form } from ':components/shadcn';
import { ArrowsExpandDiagonal6Icon, ArrowsReduceDiagonal1Icon, CirclePlusIcon, InvoiceIcon, MoneyBillsDollarIcon, PlusIcon } from ':components/icons/basic';
import { transformToPrice } from ':utils/math';
import { ContinuousParticipantSelect, ParticipantOval } from '../client/ContinuousParticipantSelect';
import { type EditablePayingParticipant, type Participant } from ':frontend/types/EventParticipant';
import { MoneyDisplay, CurrencyDisplay } from ':components/custom';
import { masterComponent } from ':frontend/context/UserProvider';
import { computeRecurrenceCount, type Recurrence } from ':utils/recurrence';
import { OrderSummary, EventPriceSummary } from './EventPriceSummary';
import { routesBE } from ':utils/routes';
import type { DateTime } from 'luxon';
import clsx from 'clsx';
import { amountFromDecimal } from ':utils/money';

type EventPaymentProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
    clients: ClientInfoFE[];
}>;

export const EventPayment = masterComponent(({ state, dispatch, clients }: EventPaymentProps) => {
    const { t } = useTranslation('components', { keyPrefix: 'eventPayment' });

    // If the event isn't new, we show data about only one item.
    const totalEvents = useMemo(() => state.event ? 1 : computeEventsCount(state.form), [ state.event, state.form ]);
    const isInOrder = useMemo(() => !!state.event?.clients.some(c => c.isInOrder), [ state.event ]);

    const [ isVisible, setIsVisible ] = useState(!!state.payment);

    function onClick() {
        setIsVisible(!isVisible);
        if (!state.payment)
            dispatch({ type: 'payment', operation: 'create' });
    }

    return (
        <div>
            <div className='flex items-center gap-2 cursor-pointer' onClick={onClick}>
                <MoneyBillsDollarIcon className='text-[#b20baa]' />

                <h3 className='grow text-secondary-700'>{t('title')}</h3>

                {state.payment ? (
                    <Button variant='transparent' size='exact' aria-label={t(isVisible ? 'hide-aria' : 'show-aria')}>
                        {isVisible ? <ArrowsReduceDiagonal1Icon /> : <ArrowsExpandDiagonal6Icon />}
                    </Button>
                ) : (
                    <Button variant='transparent' size='exact' className='text-primary text-sm [&_svg]:size-3'>
                        <PlusIcon />{t('add-payment-button')}
                    </Button>
                )}
            </div>

            <div className='mt-5 mb-2 w-full h-px bg-secondary-100' />

            {isVisible && (
                isInOrder ? (
                    // See existing payment for an event that is already in order.
                    <ExistingOrder state={state} dispatch={dispatch} />
                ) : (
                    // Add payment to a new/existing event *or* update payment on existing event that doesn't have any orders.
                    <ClientsTable state={state} dispatch={dispatch} clients={clients} totalEvents={totalEvents} />
                )
            )}
        </div>
    );
});

type ClientsTableProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
    clients: ClientInfoFE[];
    totalEvents: number;
}>;

function ClientsTable({ state, dispatch, clients, totalEvents }: ClientsTableProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventPayment' });
    const payment = state.payment!;

    const linkId = useId();
    const linkDescriptionId = useId();

    return (
        <div className='flex flex-col gap-2'>
            <div className='mb-2 flex items-center gap-6'>
                <div>
                    <Form.Label htmlFor={linkId}>{t(payment.isLinked ? 'link-label-linked' : 'link-label-unlinked')}</Form.Label>
                    <Form.Description id={linkDescriptionId}>{t('link-description')}</Form.Description>
                </div>

                <Form.Switch
                    id={linkId}
                    aria-describedby={linkDescriptionId}
                    checked={payment.isLinked}
                    onCheckedChange={value => dispatch({ type: 'payment', operation: 'link', value })}
                    variant='link'
                />
            </div>

            {(payment.clients.length > 0 || !payment.isLinked) && (<>
                {payment.isLinked && (
                    <ClientsPriceRow payment={payment} dispatch={dispatch} />
                )}

                <div className='p-4 bg-secondary-50 rounded-2xl flex flex-col gap-2'>
                    {!payment.isLinked && (
                        <div className='flex items-center justify-between gap-2'>
                            <ClientSelect payment={payment} dispatch={dispatch} clients={clients} />
                        </div>
                    )}

                    {payment.clients.map(client => (
                        <ClientRow key={client.identifier} client={client} dispatch={dispatch} isLinked={payment.isLinked} />
                    ))}

                    <div className='last:hidden w-full h-px bg-secondary-100' />

                    <EventPriceSummary state={state} dispatch={dispatch} totalEvents={totalEvents} />
                </div>
            </>)}
        </div>
    );
}

function computeEventsCount({ start, recurrence }: { start: DateTime, recurrence?: Recurrence}): number {
    return recurrence
        ? computeRecurrenceCount(recurrence, start)
        : 1;
}

function ClientsPriceRow({ payment, dispatch }: Readonly<{ payment: PaymentState, dispatch: UseEventDispatch }>) {
    const { t } = useTranslation('components', { keyPrefix: 'eventPayment' });
    const priceId = useId();

    return (
        <div>
            <Form.Label htmlFor={priceId}>{t('price-label')}</Form.Label>

            <div className='w-1/2 flex items-baseline gap-2'>
                <CurrencyDisplay currency={payment.currency}>
                    <Form.Input
                        size='compact'
                        className='text-right'
                        type='number'
                        value={payment.priceInDecimal}
                        onChange={e => dispatch({ type: 'payment', operation: 'priceInDecimal', value: transformToPrice(e.target.value) })}
                    />
                </CurrencyDisplay>
            </div>
        </div>
    );
}

type ClientSelectProps = Readonly<{
    payment: PaymentState;
    dispatch: UseEventDispatch;
    clients: ClientInfoFE[];
}>;

function ClientSelect({ payment, dispatch, clients }: ClientSelectProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventPayment' });
    const [ isShow, setIsShow ] = useState(false);
    const onChange = useCallback((value: Participant[]) => {
        dispatch({ type: 'payment', operation: 'clients', value });
        setIsShow(false);
    }, [ dispatch ]);

    if (!isShow) {
        return (
            <Button variant='outline' className='px-3 text-base' onClick={() => setIsShow(true)}>
                <CirclePlusIcon />{t('add-client-button')}
            </Button>
        );
    }

    return (
        <ContinuousParticipantSelect
            immutableProps={{ size: 'compact', menuOverflow: 'x' }}
            className='flex-1 min-w-0'
            clients={clients}
            value={payment.clients}
            onChange={onChange}
            placeholder={t('clients-placeholder')}
            hideValue
            menuIsOpen
            autoFocus
            onBlur={() => setIsShow(false)}
        />
    );
}

type ClientRowProps = Readonly<{
    client: EditablePayingParticipant;
    dispatch: UseEventDispatch;
    isLinked: boolean;
}>;

function ClientRow({ client, dispatch, isLinked }: ClientRowProps) {
    return (
        <div className='flex items-center justify-between gap-2'>
            <ParticipantOval participant={client} onRemove={() => dispatch({ type: 'payment', operation: 'clientRemove', client })} />

            {isLinked ? (
                <MoneyDisplay amount={amountFromDecimal(client.priceInDecimal || 0)} currency={client.currency} />
            ) : (
                <div className='shrink-0 flex items-baseline gap-1'>
                    <CurrencyDisplay currency={client.currency}>
                        <Form.Input
                            autoFocus={!client.original}
                            variant='transparent'
                            size='exact'
                            className='max-w-16 text-right bg-white rounded px-2 py-1'
                            type='number'
                            value={client.priceInDecimal}
                            onChange={e => dispatch({ type: 'payment', operation: 'clientPriceInDecimal', client, value: transformToPrice(e.target.value) })}
                        />
                    </CurrencyDisplay>
                </div>
            )}
        </div>
    );
}

type ExistingOrderProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
}>;

function ExistingOrder({ state, dispatch }: ExistingOrderProps) {
    const event = state.event;
    if (!event)
        return null;

    const isSomeDocument = event.clients.some(client => client.documentId);

    return (
        <div className='p-4 bg-secondary-50 rounded-2xl flex flex-col gap-2'>
            {state.payment?.clients.map(client => (
                client.original?.isInOrder
                    ? <ExistingOrderRow key={client.identifier} client={client} isSomeDocument={isSomeDocument} />
                    : <ClientRow key={client.identifier} client={client} dispatch={dispatch} isLinked={false} />
            ))}

            <div className='w-full h-px bg-secondary-100' />

            <OrderSummary state={state} />
        </div>
    );
}

type ExistingOrderRowProps = Readonly<{
    client: EditablePayingParticipant;
    isSomeDocument: boolean;
}>;

function ExistingOrderRow({ client, isSomeDocument }: ExistingOrderRowProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventPayment' });
    const original = client.original!;

    return (
        <div className='flex items-baseline gap-1'>
            <ParticipantOval participant={client} />

            <div className='grow' />

            <div className={clsx('shrink-0 text-center text-sm font-semibold rounded-xs', original.isPaid ? 'text-success' : 'text-warning')}>
                {t(original.isPaid ? 'paid-text' : 'unpaid-text')}
            </div>


            {isSomeDocument && (
                original.documentId ? (
                    <a className='self-center' href={routesBE.public.document.resolve({ id: original.documentId })} target='_blank' rel='noopener'>
                        <InvoiceIcon />
                    </a>
                ) : (
                    <div className='w-[18px]' />
                )
            )}

            <MoneyDisplay amount={original.payment.price} currency={original.payment.currency} className='min-w-16 text-end' />
        </div>
    );
}
