import clsx from 'clsx';
import useNotifications from ':frontend/context/NotificationProvider';
import { Variant, Visibility } from ':frontend/types/notifications';
import { Button } from ':components/shadcn';
import { XmarkIcon } from ':components/icons/basic';

type AlertDisplayProps = Readonly<{
    visibility: Visibility;
    left?: boolean;
}>;

export function AlertDisplay({ visibility, left }: AlertDisplayProps) {
    const { alerts, removeAlert } = useNotifications();

    return (
        // The pointer-events-none class turns off pointer events so that the user can click through the container.
        // We then have to turn them back on for the alerts themselves.
        <div className={clsx('top-16 fixed left-0 right-[--removed-body-scroll-bar-size] py-1 px-5 z-[1210] flex flex-col gap-2 pointer-events-none', left ? 'items-start' : 'items-end')}>
            {alerts
                .filter(alert => alert.visibility === visibility || (visibility !== Visibility.Dev && alert.visibility === Visibility.Global))
                .map(alert => alert.show && (
                    <div
                        key={alert.id}
                        className={clsx('pl-4 pr-3 py-2 flex items-center border gap-3 rounded-md pointer-events-auto', {
                            'bg-success-100/85 border-success-200 text-success-900': alert.content.variant === Variant.Success,
                            'bg-warning-100/80 border-warning-200 text-warning-900': alert.content.variant === Variant.Warning,
                            'bg-danger-100/80 border-danger-200 text-danger-900': alert.content.variant === Variant.Error,
                        })}
                    >
                        {typeof alert.content.message === 'string' ? (
                            alert.content.message
                        ) : (
                            <alert.content.message alertId={alert.id} />
                        )}
                        <Button
                            variant='white'
                            size='exact'
                            className='shrink-0 size-6'
                            onClick={() => removeAlert(alert.id)} aria-label='Close alert'
                        >
                            <XmarkIcon />
                        </Button>
                    </div>
                ))}
        </div>
    );
}
