import { TriangleWarningIcon } from ':components/icons/basic';
import clsx from 'clsx';
import { get, type FieldErrors, type FieldPath, type FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type ErrorMessageProps = Readonly<{
    message: string | undefined;
    className?: string;
    /** If true, the color will be less danger and more warning. */
    isOnlyWarning?: boolean;
}>;

export function ErrorMessage({ message, className, isOnlyWarning }: ErrorMessageProps) {
    return (
        <div className={clsx('mt-2 flex items-center gap-2 leading-4', className, isOnlyWarning ? 'text-warning' : 'text-danger')}>
            <TriangleWarningIcon size='sm' className='shrink-0' />
            <span>{message ?? ''}</span>
        </div>
    );
}

type TranslatedErrorMessageProps = Readonly<{
    translationId: string | undefined;
    className?: string;
}>;

export function TranslatedErrorMessage({ translationId, className }: TranslatedErrorMessageProps) {
    const { t } = useTranslation();

    if (!translationId)
        return null;

    return (
        <ErrorMessage message={t(translationId)} className={className} />
    );
}

type RHFErrorMessageProps<T extends FieldValues> = {
    errors: FieldErrors<T>;
} & ({
    /** Same as names={[ name ]}. */
    name: FieldPath<T>;
} | {
    /** Show an error for the first field that has the error. */
    names: FieldPath<T>[];
});

export function RHFErrorMessage<T extends FieldValues>({ errors, ...data }: RHFErrorMessageProps<T>) {
    const message = getFirstErrorMessage(errors, 'name' in data ? [ data.name ] : data.names);
    if (message === undefined)
        return null;

    return <ErrorMessage message={message} />;
}

function getFirstErrorMessage<T extends FieldValues>(errors: FieldErrors<T>, names: FieldPath<T>[]): string | undefined {
    for (const name of names) {
        const error = get(errors, name);
        if (error) {
            const message = error.message || error.root?.message;
            if (message)
                return message;
        }
    }

    return undefined;
}

export function isRHFError<T extends FieldValues>(errors: FieldErrors<T>, ...names: FieldPath<T>[]): boolean {
    return getFirstErrorMessage(errors, names) !== undefined;
}
