import type { Selected } from ':frontend/pages/CalendarDetail';
import type { CalendarEvent } from ':frontend/types/calendar/Calendar';
import isEqual from 'lodash/isEqual';

// TODO this might be really not performant
// TODO Use undefined instead of null
// FIXME This how does it make sense to compare two different types?
export function isSelected(event: CalendarEvent | null | undefined, selected: Selected | null | undefined) {
    if (!event || selected == null)
        return false;
    return isEqual(event, selected);
}

// TODO Unify with other similar types.
type Box = {
    top: number;
    right: number;
    left: number;
    bottom: number;
    x: number;
    y: number;
};

// TODO Unify with other similar types.
type Position = {
    x: number;
    y: number;
};

function slotWidth(rowBox: Box, slots: number) {
    const rowWidth = rowBox.right - rowBox.left;
    const cellWidth = rowWidth / slots;

    return cellWidth;
}

export function getSlotAtX(rowBox: Box, x: number, slots: number) {
    const cellWidth = slotWidth(rowBox, slots);
    return Math.floor((x - rowBox.left) / cellWidth);
}

export function pointInBox(box: Box, { x, y }: Position) {
    return y >= box.top && y <= box.bottom && x >= box.left && x <= box.right;
}

export function dateCellSelection(start: Position, rowBox: Box, box: Box, slots: number) {
    let startIdx = -1;
    let endIdx = -1;
    const lastSlotIdx = slots - 1;

    // Cell under the mouse.
    const currentSlot = getSlotAtX(rowBox, box.x, slots);

    // Identify row as either the initial row or the row under the current mouse point.
    const isCurrentRow = rowBox.top < box.y && rowBox.bottom > box.y;
    const isStartRow = rowBox.top < start.y && rowBox.bottom > start.y;

    // This row's position relative to the start point.
    const isAboveStart = start.y > rowBox.bottom;
    const isBelowStart = rowBox.top > start.y;
    const isBetween = box.top < rowBox.top && box.bottom > rowBox.bottom;

    // This row is between the current and start rows, so entirely selected.
    if (isBetween) {
        startIdx = 0;
        endIdx = lastSlotIdx;
    }

    if (isCurrentRow) {
        if (isBelowStart) {
            startIdx = 0;
            endIdx = currentSlot;
        }
        else if (isAboveStart) {
            startIdx = currentSlot;
            endIdx = lastSlotIdx;
        }
    }

    if (isStartRow) {
        const cellWidth = slotWidth(rowBox, slots);
        // Select the cell under the initial point.
        startIdx = endIdx = Math.floor((start.x - rowBox.left) / cellWidth);

        if (isCurrentRow) {
            if (currentSlot < startIdx)
                startIdx = currentSlot;
            else
                // Select the current range.
                endIdx = currentSlot;
        }
        else if (start.y < box.y) {
            // The current row is below start row, so, select cells to the right of the start cell.
            endIdx = lastSlotIdx;
        }
        else {
            // Select cells to the left of the start cell.
            startIdx = 0;
        }
    }

    return { startIdx, endIdx };
}
