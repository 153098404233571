import jwtDecode from 'jwt-decode';
import { random } from ':utils/math';
import localStorage from './localStorage';
import { type ApiAuthorizer, CANCELED_ERROR, type RawApi } from './api';
import type { AppUserInit } from ':utils/entity/user';
import { DataResultSuccess, type Result } from ':frontend/types/result';
import type { Dispatch, SetStateAction } from 'react';
import { zAccessTokenPayload, type AccessTokenPayload, type AccessTokens, type RegisterValidateError, type RegisterValidateInput } from ':utils/auth';
import { routesBE } from ':utils/routes';
import { queryClient } from ':frontend/context/TrpcProvider';

const TOKENS_KEY = 'access_tokens';

let cachedTokens: AccessTokens | null = null;

/**
 * Possible usecases:
 *  1. The application starts in one or multiple tabs (theoretically indistinguishable cases so we always assume multiple tabs).
 *  2. The application is already running in one or more tabs while another tab is opened (or multiple tabs are opened).
 *  3. The application is already running in one or more tabs while the user logs in.
 *  4. The application is already running in one or more tabs while the user logs out.
 */

function getTokensFromStorage(): AccessTokens | null {
    return localStorage.get<AccessTokens>(TOKENS_KEY);
}

const MINIMAL_REFRESH_RATE = 0.5;
const MAXIMAL_REFRESH_RATE = 0.9;
const randomRefreshRate = random(MINIMAL_REFRESH_RATE, MAXIMAL_REFRESH_RATE);
const REFRESH_NOW_TOLERANCE = 100; // in ms

const REFRESHING_KEY = 'refreshing_token_since';
const REFRESHING_TIMEOUT = 1000; // in ms

type RefreshingInfo = {
    since: number;
};

export type AuthStateLogin = {
    isAuthorized: true;
    identifier: string;
    role: AccessTokenPayload['role'];
};

type AuthStateLogout = {
    isAuthorized: false;
    isFetchingNewToken: boolean;
}

export type AuthState = AuthStateLogin | AuthStateLogout;

function compareStates(state1: AuthState, state2: AuthState): boolean {
    if (state1.isAuthorized)
        return state2.isAuthorized && (state1.identifier === state2.identifier);

    return !state2.isAuthorized && (state1.isFetchingNewToken === state2.isFetchingNewToken);
}

type AuthManagerEvents = {
    login?: (state: AuthStateLogin, isThisTab: boolean) => void;
    logout?: (state: AuthStateLogout, isThisTab: boolean) => void;
};

export class AuthManager {
    // TODO this is not ideal, we should sync it with the AuthProvider state.
    private state: AuthState = {
        isAuthorized: false,
        isFetchingNewToken: true,
    };
    private readonly update: (authState: AuthState, isThisTab: boolean) => void;

    constructor(
        private readonly api: RawApi,
        update: Dispatch<SetStateAction<AuthState>>,
        /** Callbacks that will be executed on specific action. */
        events: AuthManagerEvents = {},
        private readonly authorizers: ApiAuthorizer[] = [],
    ) {
        // Do not update if the state isn't new.
        // Probably should be solved by using only boolean variable as a state (instead of an object), but the state can be extended in the future so ...
        this.update = (newState: AuthState, isThisTab: boolean) => update((oldState: AuthState) => {
            if (compareStates(newState, oldState))
                return oldState;

            if (newState.isAuthorized && !oldState.isAuthorized)
                events.login?.(newState, isThisTab);
            else if (!newState.isAuthorized && oldState.isAuthorized)
                events.logout?.(newState, isThisTab);

            this.state = newState;
            return newState;
        });
    }

    getTokens(): AccessTokens | null {
        return cachedTokens;
    }

    invalidateAccessToken() {
        cachedTokens = null;
    }

    /* On start */

    public onStart() {
        localStorage.addEventListener(TOKENS_KEY, this.onTokensChange);
        this.authorizers.forEach(authorizer => authorizer.setExpiredJWTCallback(() => this.scheduleRefresh(0)));

        const tokens = getTokensFromStorage();

        if (tokens) {
            this.setTokensLocally(tokens, false);
        }
        else {
            this.update({ isAuthorized: false, isFetchingNewToken: true }, false);
            this.refreshAccessToken();
        }
    }

    private onTokensChange = (newValue: AccessTokens | null) => {
        if (!newValue)
            this.logoutLocally(false);
        else
            this.setTokensLocally(newValue, false);
    };

    /* Cleanup */

    public cleanup() {
        localStorage.removeEventListener(this.onTokensChange);
        this.cancelScheduledRefresh();
        localStorage.set(REFRESHING_KEY, null);
        this.abort();
    }

    /* Login */

    private setTokensLocally(tokens: AccessTokens, isThisTab: boolean) {
        cachedTokens = tokens;

        const rawPayload = jwtDecode(tokens.backend);
        // Sometimes, we change the token format. If that's the case, we just logout the user.
        const payloadResult = zAccessTokenPayload.safeParse(rawPayload);
        if (!payloadResult.success) {
            this.logoutGlobally();
            return;
        }

        const payload = payloadResult.data;
        const now = Date.now();

        if (payload.exp < now) {
            this.update({ isAuthorized: false, isFetchingNewToken: true }, isThisTab);
            this.refreshAccessToken();
            return;
        }

        this.update({ isAuthorized: true, identifier: payload.email, role: payload.role }, isThisTab);

        const timeToRefresh = payload.iat + randomRefreshRate * (payload.exp - payload.iat) - now;

        if (timeToRefresh < REFRESH_NOW_TOLERANCE)
            this.refreshAccessToken();
        else
            this.scheduleRefresh(timeToRefresh);
    }

    private setTokensGlobally(tokens: AccessTokens) {
        this.setTokensLocally(tokens, true);
        localStorage.set(TOKENS_KEY, tokens);
    }

    public async loginWithEmail(email: string, password: string): Promise<Result<void>> {
        const response = await this.api.POST<AccessTokens>(routesBE.auth.login, { email, password });
        if (!response.status)
            return response;

        this.setTokensGlobally(response.data);
        return DataResultSuccess<void>(undefined);
    }

    public async registerValidate(input: RegisterValidateInput): Promise<Result<{ errors?: RegisterValidateError[] }>> {
        return this.api.POST<{ errors?: RegisterValidateError[] }, RegisterValidateInput>(routesBE.auth.registerValidate, input);
    }

    public async register(data: AppUserInit, signal?: AbortSignal): Promise<boolean> {
        const response = await this.api.POST<AccessTokens>(routesBE.auth.register, data, { signal });
        if (!response.status)
            return false;

        this.setTokensGlobally(response.data);
        return true;
    }

    /**
     * Login user by tokens. Useful when registered via an integration.
     */
    public loginWithTokens(tokens: AccessTokens) {
        this.setTokensGlobally(tokens);
    }

    /* Logout */

    private logoutLocally(isThisTab: boolean) {
        this.invalidateAccessToken();
        this.update({ isAuthorized: false, isFetchingNewToken: false }, isThisTab);
        this.cancelScheduledRefresh();

        // should this be in logoutLocally? or in logoutGlobally? or logout?
        // we probably don't want to clear currencies and tax rates as that causes loading to appear for a second
        queryClient.clear();
    }

    private logoutGlobally() {
        this.logoutLocally(true);
        localStorage.remove(TOKENS_KEY);
    }

    public async logout(): Promise<boolean> {
        const response = await this.callInvalidateRequest();
        this.logoutGlobally();

        return response.status;
    }

    /** Public for testing purposes. */
    public async callInvalidateRequest() {
        return this.api.POST(routesBE.auth.logout);
    }

    /* Refreshing */

    private currentRefreshTimeout: number | undefined = undefined;

    private scheduleRefresh(timeToRefresh: number) {
        this.cancelScheduledRefresh();
        this.currentRefreshTimeout = window.setTimeout(this.createRefreshFunction(), timeToRefresh);
    }

    private createRefreshFunction(): () => void {
        const oldToken = cachedTokens;
        return () => {
            if (!cachedTokens || cachedTokens === oldToken)
                this.refreshAccessToken();
        };
    }

    private cancelScheduledRefresh() {
        window.clearTimeout(this.currentRefreshTimeout);
    }

    private abort: () => void = () => {
        console.warn('TODO Abort has not been set properly.');
    };

    public async refreshAccessToken() {
        const currentRefresh = localStorage.get<RefreshingInfo>(REFRESHING_KEY);
        const now = Date.now();

        if (currentRefresh && currentRefresh.since + REFRESHING_TIMEOUT > now)
            return;

        localStorage.set(REFRESHING_KEY, { since: now });

        const [ signal, abort ] = this.api.prepareAbort();
        this.abort = abort;

        const response = await this.api.POST<AccessTokens>(routesBE.auth.refresh, undefined, { signal });
        if (response.status)
            this.setTokensGlobally(response.data);
        // TODO This can fail for so many reasons ... for example, when the internet connection is lost. Therefore, we should not log out the user in such cases.
        else if (response.error !== CANCELED_ERROR)
            this.logoutGlobally();

        localStorage.set(REFRESHING_KEY, null);
    }

    public async updatePassword(password: string, newPassword: string): Promise<Result<void>> {
        const response = await this.api.POST<void>(routesBE.auth.updatePassword, {
            email: (this.state as AuthStateLogin).identifier,
            password,
            newPassword,
        });
        if (response.status)
            this.logoutGlobally();

        return response;
    }

    public async resetPassword(email: string): Promise<Result<void>> {
        return this.api.POST<void>(routesBE.auth.resetPassword, { email });
    }
}
