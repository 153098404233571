import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { flowlanceIcons } from ':components/icons/logos';

export default function GoogleError() {
    const { t } = useTranslation('pages', { keyPrefix: 'googleError' });

    return (
        <div className='min-w-80 h-screen w-screen flex md:items-center md:justify-center overflow-auto'>
            <div className='w-full md:w-[400px] space-y-4 p-10 md:p-0'>
                <div className='flex flex-col justify-center gap-8 h-full'>
                    <flowlanceIcons.FlowlanceBanner />

                    <h1 className='text-2xl font-semibold leading-8 md:text-4xl md:leading-10'>{t('something-went-wrong')}</h1>

                    <p className='text-lg'>
                        <Trans
                            i18nKey='try-again'
                            t={t}
                            components={{
                                a: <Link to={routesFE.auth.claimUrl.path} />,
                            }}
                        />
                    </p>
                </div>
            </div>

            <div className='max-md:hidden w-96 h-96 rounded-full bg-primary-500 blur-[150px] fixed -bottom-10 -right-10' />
        </div>
    );
}
