import { ArrowLeftIcon, Eye2Icon, LockIcon, TrashXmarkIcon } from ':components/icons/basic';
import { Button, Modal, ScrollArea, SpinnerButton } from ':components/shadcn';
import { ProductPhonePreviewDisplay, productStyles } from ':components/store/product/ProductCard';
import { StoreBioDisplay } from ':components/store/StoreBioDisplay';
import { getStoreStyles } from ':components/store/utils';
import { BlockNavigationModal } from ':frontend/components/BlockNavigationModal';
import { Topbar, TopHeader } from ':frontend/components/Layout';
import { createProductPreview, NewProductUsecase, ProductFormsPhase, useProductForms, type ProductFormsDispatch, type ProductFormsInput, type ProductFormsState } from ':frontend/components/product/useProductForms';
import { trpc } from ':frontend/context/TrpcProvider';
import { type ProductType, type ProductOutput, productsWithoutCheckout } from ':utils/entity/product';
import { routesFE } from ':utils/routes';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useParams, type NavigateFunction } from 'react-router-dom';
import { useMemo, useState, type FC } from 'react';
import { createErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { useTailwindMediaQuery } from ':frontend/hooks';
import { RemoveScroll } from 'react-remove-scroll';
import { cn } from ':components/shadcn/utils';
import { ProductCheckout } from ':components/store/product/ProductCheckout';
import { emptyFunction } from ':frontend/utils/common';
import type { DateRange } from ':utils/dateTime';
import { ProductForms } from ':frontend/components/product/productForms';
import { useEntitlement } from ':frontend/lib/stigg';
import { StiggFeature } from ':utils/lib/stigg';

export function ProductDetail() {
    const { id } = useParams<{ id: ProductType }>();
    if (!id)
        throw new Error('Missing product id');

    const fullProduct = trpc.product.getFullProduct.useQuery({ id }).data;
    if (!fullProduct)
        return null;

    return (
        <ProductFormsPage
            input={fullProduct}
            // Disable the navigation only after the user made an edit.
            blockAlways={false}
            components={components}
        />
    );
}

const components = {
    DesktopBar: ProductDetailDesktopBar,
    PhoneHeader: ProductDetailPhoneHeader,
    PhoneBar: ProductDetailPhoneBar,
};

type ProductFormsPageProps = Readonly<{
    input: ProductFormsInput;
    blockAlways: boolean;
    components: {
        DesktopBar: FC<ProductBarProps>;
        PhoneHeader: FC<ProductBarProps>;
        PhoneBar: FC<ProductBarProps>;
    };
}>;

export function ProductFormsPage({ input, blockAlways, components }: ProductFormsPageProps) {
    // Disable the navigation only after the user made an edit.
    const [ state, dispatch, isFetching, blockerControl, navigateUnblocked ] = useProductForms(input, blockAlways);

    const showDesktopView = useTailwindMediaQuery({ minWidth: 'md' });
    const [ phoneViewShowPreview, setPhoneViewShowPreview ] = useState(false);

    return (<>
        {showDesktopView ? (<>

            <Topbar>
                <div className='grow mx-auto max-w-[1200px] flex items-center justify-between gap-4'>
                    <components.DesktopBar
                        state={state}
                        dispatch={dispatch}
                        isFetching={isFetching}
                        navigateUnblocked={navigateUnblocked}
                        previewOnClick={() => setPhoneViewShowPreview(true)}
                    />
                </div>
            </Topbar>

            <div className='w-full max-w-[1000px] mx-auto px-4 py-12 flex justify-between'>
                <div className='w-full max-w-[480px] space-y-6'>
                    <ProductContentTitle state={state} />

                    <ProductForms state={state} dispatch={dispatch} />
                </div>

                <ProductPhonePreview state={state} className='!sticky top-[109px]' />
            </div>

        </>) : (<>

            <div className={clsx('h-full w-full flex flex-col', phoneViewShowPreview && 'blur-[10px]')}>
                <TopHeader>
                    <div className='flex items-center gap-4'>
                        <components.PhoneHeader
                            state={state}
                            dispatch={dispatch}
                            isFetching={isFetching}
                            navigateUnblocked={navigateUnblocked}
                            previewOnClick={() => setPhoneViewShowPreview(true)}
                        />
                    </div>
                </TopHeader>

                <div className='fl-hide-scrollbar overflow-x-auto px-4 py-2 flex items-center gap-4'>
                    <components.PhoneBar
                        state={state}
                        dispatch={dispatch}
                        isFetching={isFetching}
                        navigateUnblocked={navigateUnblocked}
                        previewOnClick={() => setPhoneViewShowPreview(true)}
                    />
                </div>

                <div className='w-full max-w-[480px] mx-auto p-4 pb-8 space-y-6'>
                    <ProductContentTitle state={state} />

                    <ProductForms state={state} dispatch={dispatch} />
                </div>
            </div>

            {phoneViewShowPreview && (
                <RemoveScroll>
                    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 bg-white/30 flex flex-col'>
                        <button onClick={() => setPhoneViewShowPreview(false)} className='p-4 text-primary block'>
                            <ArrowLeftIcon size='lg' />
                        </button>

                        <div className='px-4 pb-8 overflow-auto'>
                            <ProductPhonePreview state={state} className='h-full' />
                        </div>
                    </div>
                </RemoveScroll>
            )}

        </>)}

        <BlockNavigationModal control={blockerControl} />
    </>);
}

export type ProductBarProps = Readonly<{
    state: ProductFormsState;
    dispatch: ProductFormsDispatch;
    isFetching: boolean;
    navigateUnblocked: NavigateFunction;
    previewOnClick: () => void;
}>;

function ProductDetailDesktopBar({ state, dispatch, isFetching, navigateUnblocked }: ProductBarProps) {
    return (<>
        <div className='w-1/4 flex items-center gap-2'>
            <DeleteButton state={state} navigateUnblocked={navigateUnblocked} />
        </div>

        {phasesOverview(state, dispatch)}

        <div className='w-1/4 text-end'>
            <SaveButton dispatch={dispatch} isFetching={isFetching} />
        </div>
    </>);
}

function ProductDetailPhoneHeader({ state, dispatch, isFetching, navigateUnblocked, previewOnClick }: ProductBarProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'productDetail' });

    return (<>
        <DeleteButton state={state} navigateUnblocked={navigateUnblocked} />

        <Button variant='transparent' size='small' onClick={previewOnClick} className='px-0'>
            <Eye2Icon /> {t(`preview`)}
        </Button>

        <SaveButton dispatch={dispatch} isFetching={isFetching} />
    </>);
}

function ProductDetailPhoneBar({ state, dispatch }: ProductBarProps) {
    return (<>
        {phasesOverview(state, dispatch)}
    </>);
}

function getPhases(state: ProductFormsState): ProductFormsPhase[] {
    const output = state.original?.landing ? [
        ProductFormsPhase.ThumbnailDetails,
        // If the product has a landing page, we allow the user to edit the checkout part.
        // Otherwise, the checkout can't be edited.
        ProductFormsPhase.Landing,
    ] : [
        ProductFormsPhase.ThumbnailDetails,
    ];

    if (!productsWithoutCheckout.includes(state.type))
        output.push(ProductFormsPhase.Checkout);

    return output;
}

function phasesOverview(state: ProductFormsState, dispatch: ProductFormsDispatch) {
    const phases = getPhases(state);

    if (phases.length === 1)
        return null;

    return (
        <div className='p-1 rounded-full flex items-center justify-center gap-2 bg-white'>
            {phases.map(phase => (
                <ProductPhaseLabel key={phase} phase={phase} state={state} dispatch={dispatch} />
            ))}
        </div>
    );
}

type ProductPhaseLabelProps = Readonly<{
    phase: ProductFormsPhase;
    state: ProductFormsState;
    dispatch: ProductFormsDispatch;
}>;

function ProductPhaseLabel({ phase, state, dispatch }: ProductPhaseLabelProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'productDetail' });

    const isCurrent = phase === state.phase;

    return (
        <button
            className={cn(
                'shrink-0 h-9 px-5 rounded-full whitespace-nowrap leading-4 flex items-center gap-2 overflow-hidden hover:bg-primary-50 active:bg-primary-100 active:text-primary',
                isCurrent && 'pointer-events-none bg-primary-50 text-primary',
            )}
            onClick={() => !isCurrent && dispatch({ type: 'phase', phase })}
        >
            {t(`${phase}.phase-title`)}
        </button>
    );
}

type DeleteButtonProps = Readonly<{
    state: ProductFormsState;
    navigateUnblocked: NavigateFunction;
}>;

function DeleteButton({ state, navigateUnblocked }: DeleteButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'productDetail' });
    const [ isDeleteModalOpen, setIsDeleteModalOpen ] = useState(false);

    return (<>
        <Button variant='danger' size='small' className='max-md:w-9 max-md:px-0' onClick={() => setIsDeleteModalOpen(true)} aria-label={t('delete-button')}>
            <TrashXmarkIcon className='md:hidden' />
            <span className='max-md:hidden'>{t('delete-button')}</span>
        </Button>

        <DeleteProductModal
            product={isDeleteModalOpen && state.original?.product || undefined}
            onCancel={() => setIsDeleteModalOpen(false)}
            onDeleted={() => navigateUnblocked(routesFE.products.list)}
        />
    </>);
}

type SaveButtonProps = Readonly<{
    dispatch: ProductFormsDispatch;
    isFetching: boolean;
}>;

function SaveButton({ dispatch, isFetching }: SaveButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'productDetail' });

    return (
        <SpinnerButton variant='dark' size='small' isFetching={isFetching} onClick={() => dispatch({ type: 'sync' })}>
            {t('save-button')}
        </SpinnerButton>
    );
}

function ProductContentTitle({ state }: Readonly<{ state: ProductFormsState }>) {
    const { t } = useTranslation('pages', { keyPrefix: 'productDetail' });
    const styles = productStyles[state.type];

    return (
        <div className='flex items-start gap-4'>
            <div className={clsx('shrink-0 size-10 rounded-full flex items-center justify-center', styles.bg)}>
                {styles.icon({ size: 22 })}
            </div>

            <div>
                <h1 className='text-md leading-4 font-semibold text-secondary-900'>
                    {t(`${state.phase}.page-title`, { type: state.type })}
                </h1>
                <p className='leading-5 mt-1'>{t(`${state.phase}.page-description`)}</p>
            </div>
        </div>
    );
}

type ProductPhonePreviewProps = Readonly<{
    state: ProductFormsState;
    className?: string;
}>;

function ProductPhonePreview({ state, className }: ProductPhonePreviewProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'productDetail' });
    const isStoreEnabled = useEntitlement(StiggFeature.Store);
    const store = trpc.store.getStore.useQuery().data;
    const locations = trpc.location.getLocations.useQuery().data;

    const product = useMemo(() => createProductPreview(state.type, state.thumbnail.form, state.details.form, locations ?? []), [ state.type, state.thumbnail.form, state.details.form, locations ]);

    if (!store)
        return null;

    const storeStyles = getStoreStyles(store.design, 'fl-store-bg');

    // If the product should have a landing page, we show it in the preview. Otherwise, we return undefined because the landing form is still there.
    const landing = (!!state.original?.landing || state.usecase === NewProductUsecase.Landing)
        ? state.landing.form
        : undefined;
    // Blur the preview only in the thumbnail phase so that the user can still see changes in other phases.
    const isPrivate = state.phase === ProductFormsPhase.ThumbnailDetails && state.details.form.visibility === 'private';

    return (
        <ScrollArea
        // Based on iPhone 11 + one pixel on each side for the border.
            className={clsx(`
                w-full max-w-[377px] mx-auto max-h-[814px] h-[calc(100dvh-160px)] rounded-2xl border border-[#e6e6e6]
                shadow-[1.3px_1.3px_4px_0px_rgba(80,88,113,0.25),5.3px_5.3px_13.2px_0px_rgba(80,88,113,0.08),4px_4px_7.9px_0px_rgba(121,135,176,0.08)_inset,0px_4px_4px_0px_rgba(0,0,0,0.25)]
                `, className)}
        >
            {landingPreviewPhases.includes(state.phase) ? (
                <div className={clsx('w-full min-h-full max-w-[375px] pb-12', storeStyles.className)} style={storeStyles.style}>
                    <ProductCheckout
                        store={store}
                        isStoreEnabled={isStoreEnabled}
                        product={product}
                        landing={landing}
                        isPhonePreview
                        useAvailability={useExampleAvailability}
                        onSubmit={emptyFunction}
                        isFetching={false}
                    />
                </div>
            ) : (
                <div className={clsx('w-full min-h-full max-w-[375px] px-5 py-6 flex flex-col items-center gap-6', storeStyles.className)} style={storeStyles.style}>
                    <StoreBioDisplay bio={store.bio} image={store.image} />

                    <div className={clsx('w-full', isPrivate && 'relative [&_.fl-store-card]:blur')}>
                        <ProductPhonePreviewDisplay product={product} />

                        {isPrivate && (
                            <div className='absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center gap-2'>
                                <LockIcon />
                                {t('preview-private-text')}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </ScrollArea>
    );
}

const landingPreviewPhases = [ ProductFormsPhase.Landing, ProductFormsPhase.LandingDetails, ProductFormsPhase.Checkout ];

function useExampleAvailability({ start, end }: DateRange) {
    return useMemo(() => {
        const ranges: DateRange[] = [];

        let currentDay = start.plus({ weeks: 1 }).startOf('week');

        while (currentDay < end) {
            ranges.push({
                start: currentDay.set({ hour: 9 }),
                end: currentDay.set({ hour: 17 }),
            });

            currentDay = currentDay.plus({ days: 1 });
            while (currentDay.weekday > 5)
                currentDay = currentDay.plus({ days: 1 });
        }

        return {
            ranges,
            timezone: start.zoneName,
        };
    }, [ +start, +end ]);
}

type DeleteProductModalProps = Readonly<{
    product?: ProductOutput;
    onCancel: () => void;
    onDeleted: () => void;
}>;

export function DeleteProductModal({ product, onCancel, onDeleted }: DeleteProductModalProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'productDetail.deleteModal' });
    const { addAlert } = useNotifications();

    const deleteMutation = trpc.product.deleteProduct.useMutation();
    const trpcUtils = trpc.useUtils();

    function deleteProduct() {
        if (!product)
            return;

        deleteMutation.mutate({ id: product.id }, {
            onError: error => {
                addAlert(createErrorAlert(error.data));
            },
            onSuccess: async () => {
                addAlert(createTranslatedSuccessAlert('pages:productDetail:delete-success'));
                await trpcUtils.product.getProducts.invalidate();
                onDeleted();
            },
        });
    }

    return (
        <Modal.Root open={!!product} onOpenChange={open => !open && onCancel()}>
            <Modal.Content className='max-w-md p-8 gap-6' closeButton={t('cancel-button')}>
                <Modal.Header className='items-center'>
                    <TrashXmarkIcon size={32} className='text-danger' />
                    <Modal.Title className='mt-5 text-center text-2xl leading-7'>{t('title')}</Modal.Title>
                    <Modal.Description className='mt-3 text-center text-lg leading-7'>{t('text')}</Modal.Description>
                </Modal.Header>
                <Modal.Footer className='grid grid-cols-2'>
                    <Button onClick={onCancel} variant='secondary'>
                        {t('cancel-button')}
                    </Button>
                    <SpinnerButton onClick={deleteProduct} variant='primary' isFetching={deleteMutation.isPending}>
                        {t('confirm-button')}
                    </SpinnerButton>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    );
}
