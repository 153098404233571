import { useTranslation } from 'react-i18next';
import { Form, SpinnerButton } from ':components/shadcn';
import { useForm } from 'react-hook-form';
import { RHFErrorMessage } from ':frontend/components/forms/ErrorMessage';

export type PersonFormData = {
    firstName: string;
    lastName: string;
};

type RegisterPersonFormProps = Readonly<{
    onSubmit: (output: PersonFormData) => void;
    isFetching: boolean;
}>;

export function RegisterPersonForm({ onSubmit, isFetching }: RegisterPersonFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'register.personForm' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });
    const { register, handleSubmit, formState: { errors } } = useForm<PersonFormData>();

    return (
        <Form.Root onSubmit={handleSubmit(onSubmit)} className='w-full space-y-2'>
            <div>
                <Form.Input
                    label={t('firstName-label')}
                    className='text-lg md:text-base'
                    {...register('firstName', { required: tf('firstName-required') })}
                />
                <RHFErrorMessage errors={errors} name='firstName' />
            </div>

            <div>
                <Form.Input
                    label={t('lastName-label')}
                    className='text-lg md:text-base'
                    {...register('lastName')}
                />
                <RHFErrorMessage errors={errors} name='lastName' />
            </div>

            <SpinnerButton type='submit' className='w-full' isFetching={isFetching}>
                {t('finish-button')}
            </SpinnerButton>
        </Form.Root>
    );
}
