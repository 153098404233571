import { optionalStringToPut } from ':frontend/utils/common';
import type { AddressOutput, AddressUpsert } from ':utils/entity/address';

export type EditableAddress = Required<AddressUpsert>;

export function addressToEditable(input?: AddressOutput): EditableAddress {
    if (!input)
        return defaultEditableAddress;

    return {
        city: input.city ?? '',
        country: input.country ?? '',
        line1: input.line1 ?? '',
        line2: input.line2 ?? '',
        postalCode: input.postalCode ?? '',
        state: input.state ?? '',
    };
}

const defaultEditableAddress: EditableAddress = {
    city: '',
    country: '',
    line1: '',
    line2: '',
    postalCode: '',
    state: '',
};

export function addressToUpsert(input: EditableAddress): AddressUpsert {
    return {
        city: optionalStringToPut(input.city),
        country: optionalStringToPut(input.country),
        line1: optionalStringToPut(input.line1),
        line2: optionalStringToPut(input.line2),
        postalCode: optionalStringToPut(input.postalCode),
        state: optionalStringToPut(input.state),
    };
}

export function isAddressEqual(form: EditableAddress, address: AddressOutput | EditableAddress): boolean {
    return form.city === (address.city ?? '')
        && form.country === (address.country ?? '')
        && form.line1 === (address.line1 ?? '')
        && form.line2 === (address.line2 ?? '')
        && form.postalCode === (address.postalCode ?? '')
        && form.state === (address.state ?? '');
}
