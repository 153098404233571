import { memo } from 'react';
import { DateTime } from 'luxon';
import { localizer } from ':frontend/lib/calendar/utils/common';
import { useTranslation } from 'react-i18next';

type DayLongFormatProps = Readonly<{
    day: DateTime;
}>;

export const DayLongFormat = memo(DayLongFormatRaw);

function DayLongFormatRaw({ day }: DayLongFormatProps) {
    return (
        <span className='tabular-nums whitespace-nowrap'>
            {day.toLocaleString(DateTime.DATE_HUGE)}
        </span>
    );
}

type EventRangeFormatProps = Readonly<{
    start: DateTime;
    end: DateTime;
    currentDayStart: DateTime;
}>;

export const EventRangeFormat = memo(EventRangeFormatRaw);

function EventRangeFormatRaw({ start, end, currentDayStart }: EventRangeFormatProps) {
    const { t } = useTranslation('components', { keyPrefix: 'calendar' });

    if (localizer.isSameDate(start, end)) {
        // The event starts and ends on the same day => we display only times.
        return (
            <span className={eventRangeClassName}>{start.toLocaleString(DateTime.TIME_SIMPLE)}{' - '}{end.toLocaleString(DateTime.TIME_SIMPLE)}</span>
        );
    }

    // Now we know that the event spans multiple days.

    if (localizer.isSameDate(start, currentDayStart)) {
        // The event starts on the same day as the current day => we display only the start time.
        return (
            <span className={eventRangeClassName}>{start.toLocaleString(DateTime.TIME_SIMPLE)}{' - '}{floatingDots(end)}</span>
        );
    }

    if (localizer.isSameDate(end, currentDayStart)) {
    // The event ends on the same day as the current day => we display only the end time.
    // The first part is here (but hidded) just for the spacing.
        return (
            <span className={eventRangeClassName}>{floatingDots(start)}{' - '}{end.toLocaleString(DateTime.TIME_SIMPLE)}</span>
        );
    }

    // The event spans the whole current day.
    return (
        <span className={eventRangeClassName}>{t('all-day')}</span>
    );
}

const eventRangeClassName = 'tabular-nums whitespace-nowrap';

function floatingDots(date: DateTime) {
    return (
        <span className='relative'>
            <span className='invisible'>{date.toLocaleString(DateTime.TIME_SIMPLE)}</span>
            <span className='absolute left-1/2 -translate-x-1/2'>...</span>
        </span>
    );
}
