import { useReducer, type Dispatch } from 'react';
import { type MasterContext, useMaster } from ':frontend/context/UserProvider';
import { type TypedAction } from ':frontend/utils/common';
import type { RequiredBy } from ':utils/common';
import { type RulesDefinition, Updator, Validator, type FormErrors, type FormPath } from ':frontend/utils/updator';
import { type Participant } from ':frontend/types/EventParticipant';
import { type ProductItemInit } from ':frontend/types/orders/Order';
import { type CheckoutInput, CheckoutType } from './checkout/useCheckout';
import { type TeamMemberFE } from ':frontend/types/Team';
import { type ProductOutput } from ':utils/entity/product';
import { formDiscountToDiscount, type FormDiscount } from './DiscountInput';
import { zeroTaxRate, toMoney } from ':utils/money';

export function useProductOrder() {
    const masterContext = useMaster();
    const [ state, dispatch ] = useReducer(productOrderReducer, masterContext, computeInitialState);

    return {
        state,
        dispatch,
    };
}

export type UseProductOrderState = {
    masterContext: MasterContext;
    isModalOpen: boolean;
    form: ProductOrderFormState;
    formErrors?: FormErrors;
    wasSubmitted?: boolean;
    checkout?: CheckoutInput;
};

type ProductOrderFormState = {
    guest: Participant | undefined;
    client: Participant | undefined;
    isLinked: boolean;
    scheduler: TeamMemberFE | undefined;
    items: ProductItemInit[];
    discount?: FormDiscount;
};

function computeInitialState(masterContext: MasterContext): UseProductOrderState {
    return {
        masterContext,
        isModalOpen: false,
        form: {
            guest: undefined,
            client: undefined,
            isLinked: true,
            scheduler: undefined,
            items: [],
        },
    };
}

export type UseProductOrderDispatch = Dispatch<ProductOrderAction>;

type ProductOrderAction = FormAction | InputAction | {
    type: 'sellProduct';
    product: ProductOutput;
} | {
    type: 'closeModal';
};

function productOrderReducer(state: UseProductOrderState, action: ProductOrderAction): UseProductOrderState {
    console.log('Reduce:', state, action);

    switch (action.type) {
    case 'form': return form(state, action);
    case 'input': return input(state, action);
    case 'sellProduct': return {
        masterContext: state.masterContext,
        isModalOpen: true,
        form: {
            guest: undefined,
            client: undefined,
            isLinked: true,
            scheduler: undefined,
            items: [ productToProductItem(action.product) ],
        },
    };
    case 'closeModal':
        return { ...state, isModalOpen: false };
    }
}

function productToProductItem(product: ProductOutput): ProductItemInit {
    const pricing = product.pricing;
    if (!pricing)
        return { product, price: undefined, taxRate: zeroTaxRate };

    return {
        product,
        price: toMoney(pricing.price, pricing.currency),
        taxRate: pricing.taxRate,
    };
}

// Form

type FormAction = TypedAction<'form', {
    operation: 'checkoutStart' | 'checkoutBack';
} | {
    operation: 'addItem';
    product: ProductOutput;
} | {
    operation: 'removeItem';
    index: number;
}>;

function form(state: UseProductOrderState, action: FormAction): UseProductOrderState {
    if (action.operation === 'checkoutStart') {
        const formErrors = Validator.validate(state.form, rules);
        if (formErrors)
            return { ...state, formErrors, wasSubmitted: true };

        // Validated by the form
        const guest = state.form.guest!;
        const client = state.form.isLinked ? guest : state.form.client!;
        const scheduler = state.form.scheduler;
        const items = state.form.items;
        const discount = formDiscountToDiscount(state.form.discount);

        return { ...state, checkout: { type: CheckoutType.Product, guest, client, items, discount, scheduler } };
    }

    if (action.operation === 'checkoutBack')
        return { ...state, checkout: undefined };

    if (action.operation === 'removeItem') {
        const newItems = state.form.items.toSpliced(action.index, 1);
        return { ...state, form: { ...state.form, items: newItems } };
    }

    if (action.operation === 'addItem') {
        const newItems = [ ...state.form.items, productToProductItem(action.product) ];
        return { ...state, form: { ...state.form, items: newItems } };
    }

    throw new Error(`Unknown operation: ${action.operation}`);
}

// Input

type InputAction = TypedAction<'input', {
    field: FormPath<ProductOrderFormState>;
    value: unknown;
}>;

function input(state: UseProductOrderState, action: InputAction): UseProductOrderState {
    const { form, formErrors } = Updator.update(state as RequiredBy<UseProductOrderState, 'form'>, action.field, action.value, state.wasSubmitted ? rules : undefined);

    return { ...state, form, formErrors };
}

const rules: RulesDefinition<ProductOrderFormState> = {
    guest: (value: unknown) => value !== undefined || 'pages:directSale.product.guest-required',
    client: (value: unknown, form: ProductOrderFormState) => form.isLinked || value !== undefined || 'pages:directSale.product.client-required',
};
