import { type Money, type TaxRate, amountFromDecimal } from ':utils/money';
import { type Id } from ':utils/id';
import { EventParticipantFE, type PayingParticipant } from '../EventParticipant';
import { type OrderItemFE } from './OrderItem';
import { type DateTime } from 'luxon';
import type { EventOrderItemOutput, OrderItemEdit } from ':utils/entity/orderItem';
import { toNumber } from ':utils/math';
import type { EventInfoOutput } from ':utils/entity/event';

export class EventOrderItemFE implements OrderItemFE {
    private constructor(
        readonly id: Id,
        readonly title: string,
        readonly quantity: number,
        readonly unitPrice: Money,
        readonly taxRate: TaxRate,
        readonly index: number,
        readonly createdAt: DateTime,

        readonly participant: PayingParticipant,
        readonly event: EventInfoOutput,
    ) {
    }

    static fromServer(base: OrderItemFE, input: EventOrderItemOutput): EventOrderItemFE {
        const participant = EventParticipantFE.fromServer(input.eventParticipant) as PayingParticipant;

        return new EventOrderItemFE(
            base.id,
            base.title,
            base.quantity,
            base.unitPrice,
            base.taxRate,
            base.index,
            base.createdAt,
            participant,
            input.eventParticipant.event,
        );
    }
}

export function eventItemUpdateToServer(input: EditableEventItem): OrderItemEdit {
    return {
        id: input.id,
        title: input.title,
        quantity: input.quantity,
        unitPrice: amountFromDecimal(input.unitPriceInDecimal as number),
        taxRate: input.taxRate,
        isDeleted: input.isDeleted ? true : undefined,
    };
}

export type EditableEventItem = {
    readonly id: string;
    readonly event: EventInfoOutput;
    title: string;
    unitPriceInDecimal: number | '' | '-';
    readonly quantity: number;
    taxRate: TaxRate;
    isDeleted: boolean;
};

export function isEventItemEqual(form: EditableEventItem, item: EventOrderItemFE): boolean {
    return !form.isDeleted
        && form.title === item.title
        && amountFromDecimal(toNumber(form.unitPriceInDecimal)) === item.unitPrice.amount
        && form.taxRate === item.taxRate;
}
