import { eventIsBillable, type EventFE } from '../Event';
import { computeIfAbsent } from ':utils/common';
import type { GoogleCalendar } from './Google';
import { type DateTime } from 'luxon';
import { toMoney, type CurrencyId, type Money } from ':utils/money';
import type { GoogleEvent } from ':utils/lib/google';
import type { TeamMemberFE, TeamMembers } from '../Team';
import { localizer } from ':frontend/lib/calendar/utils/common';

export enum EventType {
    Ready = 'ready',
    Finished = 'finished',
    Canceled = 'canceled',
}

type CalendarEventResource<T extends string> = {
    type: T;
};

export type FullEventResource = CalendarEventResource<'event'> & {
    event: EventFE;
    totalPrice?: Money;
    calendar?: GoogleCalendar;
    teamMember: TeamMemberFE;
};

export type GoogleEventResource = CalendarEventResource<'google'> & {
    event: GoogleEvent;
    calendar: GoogleCalendar;
};

export type DraftEventResource = CalendarEventResource<'draft'>;

export type AnyEventResource = FullEventResource | DraftEventResource | GoogleEventResource;

// according to
// https://jquense.github.io/react-big-calendar/examples/index.html?path=/docs/props--events
export type CalendarEvent<TResource extends AnyEventResource = AnyEventResource> = {
    title: string;
    start: DateTime;
    end: DateTime;
    isAllDay?: boolean;
    resource: TResource;
    startDay: DateTime;
    /** in whole days (so usually 0) */
    duration: number;
    /** If not set, the title is used instead. */
    tooltip?: string;
    id: string;
    isBillable: boolean;
};

export function durationInDays(start: DateTime, end: DateTime): number {
    return Math.max(localizer.diff(start, localizer.ceil(end, 'day'), 'day'));
}

export function eventsToCalendarEvents(events: EventFE[], members: TeamMembers, disabledMemberIds: Set<string>, calendars: GoogleCalendar[], activeIds: Set<string>, defaultCalendarId?: string): CalendarEvent<FullEventResource>[] {
    const enabledCalendars = calendars.filter(calendar => activeIds.has(calendar.id));
    const output: CalendarEvent<FullEventResource>[] = [];

    events
        .map(event => eventToCalendarEvent(event, members))
        .forEach(event => {
            if (disabledMemberIds.has(event.resource.teamMember.id.toString()))
                return;

            const calendarId = event.resource.event.googleCalendarId;
            // The event doesn't have a calendar - it's our event, so we don't need to filter it.
            // Or, the event is in the default calendar. That means it's our event that was written to the default Google calendar, so it should be displayed as-is.
            if (!calendarId || calendarId === defaultCalendarId) {
                output.push(event);
                return;
            }

            // Could be activeIds.has(calendarId), but it's possible an activeId is from a no-longer-accessible calendar (or something idk).
            // Corresponding enabled (visible) calendar of the current user.
            const calendar = enabledCalendars.find(c => c.id === calendarId);
            // The event has a calendar, but the calendar is hidden by the user OR the calendar is from a different account (team member)
            if (!calendar) {
                // Corresponding calendar of the current user.
                const userCalendar = calendars.find(c => c.id === calendarId);
                if (!userCalendar)  // The calendar is not accessible (the event is from a team member).
                    output.push(event);
                return;
            }

            event.resource.calendar = calendar;
            output.push(event);
        });

    return output;
}

export function eventToCalendarEvent(event: EventFE, members: TeamMembers): CalendarEvent<FullEventResource> {
    const teamMember = members.getByAppUserId(event.ownerId)!;
    const duration = durationInDays(event.start, event.end);

    return {
        title: event.title,
        start: event.start,
        end: event.end,
        resource: {
            type: 'event',
            event,
            totalPrice: getEventPrice(event),
            teamMember,
        },
        startDay: localizer.startOf(event.start, 'day'),
        duration,
        isAllDay: duration >= 1,
        id: event.id as unknown as string,
        isBillable: eventIsBillable(event),
    };
}

function getEventPrice(event: EventFE): Money | undefined {
    if (event.isInPackage)
        return undefined;

    const pricesByCurrency = new Map<CurrencyId, Money>();
    event.clients.forEach(client => {
        const { price, currency } = client.payment;
        const current: Money = computeIfAbsent(pricesByCurrency, currency, () => toMoney(0, currency));
        current.amount += price;
    });

    if (pricesByCurrency.size === 0)
        return undefined;

    // TODO what if there are multiple currencies? It shouldn't be possible now, but might be eventually.
    // TODO what if there are no currencies?

    return pricesByCurrency.values().next().value;
}

let nextDraftId = 0;

export function createDraftEvent(start: DateTime, end: DateTime, title: string): CalendarEvent<DraftEventResource> {
    return {
        start,
        end,
        title,
        resource: {
            type: 'draft',
        },
        startDay: localizer.startOf(start, 'day'),
        duration: durationInDays(start, end),
        id: 'draft_' + nextDraftId++,
        isBillable: false,
    };
}

export function isDraftEvent(event: CalendarEvent): event is CalendarEvent<DraftEventResource> {
    return event.resource.type === 'draft';
}

export function isFullEvent(event: CalendarEvent): event is CalendarEvent<FullEventResource> {
    return event.resource.type === 'event';
}

export function isGoogleEvent(event: CalendarEvent): event is CalendarEvent<GoogleEventResource> {
    return event.resource.type === 'google';
}
