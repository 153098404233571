import { useCallback, useMemo, type ReactNode } from 'react';
import { Currency, getAllCurrencies, type CurrencyId } from ':utils/money';
import { Select, type FilterOptionOption, type SelectConfig } from ':components/shadcn';
import { Controller, type Control, type FieldPath, type FieldValues } from 'react-hook-form';
import { createOnChange, createValue, type OnChange, type Value } from ':frontend/utils/forms';
import { useTranslation } from 'react-i18next';
import { type Id } from ':utils/id';
import { countryToFlagUrl } from './CountrySelect';

type CurrencySelectProps<IsMulti extends boolean> = Readonly<{
    value: Value<CurrencyId, IsMulti>;
    onChange: OnChange<CurrencyId, IsMulti>;
    options?: CurrencyId[];
    isMulti?: IsMulti;
    className?: string;
    id?: string;
    immutableProps?: SelectConfig<Option>;
}>;

export function CurrencySelect<IsMulti extends boolean = false>({ value, onChange, options, isMulti, className, ...rest }: CurrencySelectProps<IsMulti>) {
    const { t } = useTranslation('common', { keyPrefix: 'select' });
    const innerOptions = useMemo(() => {
        return (options ?? getAllCurrencies()).map(currency => currencyToOption(currency));
    }, [ options, t ]);

    const handleOnChange = useMemo(() => createOnChange(
        (option: Option) => option.value,
        isMulti,
        onChange,
    ), [ isMulti, onChange ]);

    const innerValue = useMemo(() => createValue(
        value => currencyToOption(value),
        isMulti,
        value,
    ), [ isMulti, value ]);

    return (
        <Select
            placeholder={t('currency-placeholder')}
            options={innerOptions}
            value={innerValue === undefined ? null : innerValue}
            onChange={handleOnChange}
            isMulti={isMulti}
            className={className}
            filterOption={filterOption}
            {...rest}
        />
    );
}

type Option = {
    value: CurrencyId;
    label: ReactNode;
};

function currencyToOption(currency: CurrencyId): Option {
    const flagUrl = countryToFlagUrl.get(Currency.get(currency).country);

    return {
        value: currency,
        label: (
            <div className='flex items-center gap-2'>
                {flagUrl ? (
                    <img src={flagUrl} className='size-5 rounded-full object-cover border' />
                ) : (
                    <div className='size-5'/>
                )}
                {Currency.label(currency)}
            </div>
        ),
    };
}

function filterOption(option: FilterOptionOption<Option>, query: string): boolean {
    return Currency.label(option.data.value).toLowerCase().includes(query.toLowerCase());
}

type ControlledCurrencySelectProps<TFieldValues extends FieldValues, IsMulti extends boolean> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    options?: CurrencyId[];
    isMulti?: IsMulti;
    className?: string;
};

export function ControlledCurrencySelect<TFieldValues extends FieldValues, IsMulti extends boolean>({ control, name, options, isMulti, className }: ControlledCurrencySelectProps<TFieldValues, IsMulti>) {
    const InnerSelect = useCallback(({ field }: { field: { value: Value<Id, IsMulti>, onChange: OnChange<Id, IsMulti> } }) => {
        return (
            <CurrencySelect
                value={field.value}
                onChange={field.onChange}
                options={options}
                isMulti={isMulti}
                className={className}
            />
        );
    }, [ options, isMulti, className ]);

    return (
        <Controller
            control={control as Control<FieldValues>}
            name={name}
            render={InnerSelect}
        />
    );
}
