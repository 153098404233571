import { useReducer, type Dispatch } from 'react';
import { type MasterContext, useMaster } from ':frontend/context/UserProvider';
import { type TypedAction } from ':frontend/utils/common';
import type { RequiredBy } from ':utils/common';
import { type RulesDefinition, Updator, Validator, type FormErrors, type FormPath } from ':frontend/utils/updator';
import { getClientIdentifier, type Participant } from ':frontend/types/EventParticipant';
import { type CustomCheckoutInput, CheckoutType } from './checkout/useCheckout';
import { formDiscountToDiscount, type FormDiscount } from './DiscountInput';
import type { Id } from ':utils/id';
import type { ClientInfoFE } from ':frontend/types/Client';
import type { FECustomItemInit, OrderFE } from ':frontend/types/orders/Order';
import { amountFromDecimal, amountToDecimal, toMoney, type CurrencyId, type TaxRate } from ':utils/money';
import { toNumber } from ':utils/math';
import { computeCustomPriceSummary } from './PriceSummary';

export type PreselectedData = {
    clientId: Id;
} | {
    order: OrderFE;
};

export function useCustomOrder(clients: ClientInfoFE[], preselected?: PreselectedData) {
    const masterContext = useMaster();
    const [ state, dispatch ] = useReducer(customOrderReducer, { masterContext, clients, preselected }, computeInitialState);

    return {
        state,
        dispatch,
    };
}

export type UseCustomOrderState = {
    masterContext: MasterContext;
    form: CustomOrderFormState;
    formErrors?: FormErrors;
    wasSubmitted?: boolean;
    checkout?: CustomCheckoutInput;
};

function computeInitialState({ masterContext, clients, preselected }: { masterContext: MasterContext, clients: ClientInfoFE[], preselected?: PreselectedData }): UseCustomOrderState {
    return {
        masterContext,
        form: computeInitialFormState(masterContext, clients, preselected),
    };
}

type CustomOrderFormState = {
    client: Participant | undefined;
    currency: CurrencyId;
    items: CustomItemFormState[];
    dueDays: number | '' | '-';
    discount?: FormDiscount;
};

function computeInitialFormState(masterContext: MasterContext, clients: ClientInfoFE[], preselected?: PreselectedData): CustomOrderFormState {
    if (!preselected || 'clientId' in preselected) {
        const clientId = preselected?.clientId;
        const rawClient = clientId ? clients.find(client => client.id === clientId) : undefined;
        const currency = masterContext.teamSettings.currency;
        const client: Participant | undefined = rawClient ? { info: rawClient, identifier: getClientIdentifier(rawClient) } : undefined;

        return {
            client,
            dueDays: rawClient?.dueDays ?? '',
            currency,
            discount: undefined,
            items: [ createNewCustomItem(masterContext) ],
        };
    }

    // Duplicate the current order. We allow only custom items since the event items would require duplicating the events.
    const order = preselected.order;
    const items = order.getCustomItems().map(item => ({
        title: item.title,
        quantity: item.quantity,
        unitPriceInDecimal: amountToDecimal(item.unitPrice.amount),
        taxRate: item.taxRate,
    } satisfies CustomItemFormState));
    const currency = order.price.currency;
    const client = { info: order.client, identifier: getClientIdentifier(order.client) };

    return {
        client,
        dueDays: order.issueDate.diff(order.dueDate).days,
        currency,
        items,
    };
}

type CustomItemFormState = {
    title: string;
    quantity: number | '';
    unitPriceInDecimal: number | '';
    taxRate: TaxRate;
};

function createNewCustomItem(masterContext: MasterContext): CustomItemFormState {
    return {
        title: '',
        quantity: 1,
        unitPriceInDecimal: '',
        taxRate: masterContext.teamSettings.taxRate,
    };
}

export type UseCustomOrderDispatch = Dispatch<CustomOrderAction>;

type CustomOrderAction = FormAction | InputAction;

function customOrderReducer(state: UseCustomOrderState, action: CustomOrderAction): UseCustomOrderState {
    console.log('Reduce:', state, action);

    switch (action.type) {
    case 'form': return form(state, action);
    case 'input': return input(state, action);
    }
}

// Form

type FormAction = TypedAction<'form', {
    operation: 'checkoutStart' | 'checkoutBack' | 'addItem';
} | {
    operation: 'removeItem';
    index: number;
}>;

function form(state: UseCustomOrderState, action: FormAction): UseCustomOrderState {
    if (action.operation === 'checkoutStart') {
        const formErrors = Validator.validate(state.form, rules);
        const isTooCheap = computeCustomPriceSummary(state.form).currencies.some(currency => currency.isUnderMinimalAmount);
        if (formErrors || isTooCheap)
            return { ...state, formErrors, wasSubmitted: true };

        return { ...state, checkout: computeCheckoutInput(state.form) };
    }

    if (action.operation === 'checkoutBack')
        return { ...state, checkout: undefined };

    if (action.operation === 'removeItem') {
        const newItems = state.form.items.toSpliced(action.index, 1);
        return { ...state, form: { ...state.form, items: newItems } };
    }

    if (action.operation === 'addItem') {
        const newItems = [ ...state.form.items, createNewCustomItem(state.masterContext) ];
        return { ...state, form: { ...state.form, items: newItems } };
    }

    throw new Error(`Unknown operation: ${action.operation}`);
}

function computeCheckoutInput(form: CustomOrderFormState): CustomCheckoutInput {
    const items: FECustomItemInit[] = form.items.map(item => ({
        title: item.title,
        quantity: toNumber(item.quantity),
        unitPrice: toMoney(amountFromDecimal(toNumber(item.unitPriceInDecimal)), form.currency),
        taxRate: item.taxRate,
    }));

    return {
        type: CheckoutType.Custom,
        client: form.client!,
        dueDays: form.dueDays !== '' ? toNumber(form.dueDays) : undefined,
        currency: form.currency,
        items,
        discount: formDiscountToDiscount(form.discount),
    };
}

export function tryComputePreviewCheckoutInput(form: CustomOrderFormState): CustomCheckoutInput | undefined {
    const formErrors = Validator.validate(form, rules);
    const isTooCheap = computeCustomPriceSummary(form).currencies.some(currency => currency.isUnderMinimalAmount);
    if (formErrors || isTooCheap)
        return;

    return computeCheckoutInput(form);
}

// Input

type InputAction = TypedAction<'input', {
    field: FormPath<CustomOrderFormState>;
    value: unknown;
}>;

function input(state: UseCustomOrderState, action: InputAction): UseCustomOrderState {
    const { form, formErrors } = Updator.update(state as RequiredBy<UseCustomOrderState, 'form'>, action.field, action.value, state.wasSubmitted ? rules : undefined);
    if (action.field === 'client' && form.dueDays === '')
        form.dueDays = computeFinalDueDays(state);

    return { ...state, form, formErrors };
}

/** Due days as they will be computed on the backend. */
export function computeFinalDueDays({ masterContext: { profiles }, form: { dueDays, client } }: UseCustomOrderState): number {
    if (dueDays !== '')
        return toNumber(dueDays);

    if (client && 'info' in client) {
        return client.info.dueDays
            ?? profiles.find(profile => profile.id === client.info.invoicingProfileId)?.dueDays
            // The first profile is the default one.
            ?? profiles[0].dueDays;
    }

    return profiles[0].dueDays;
}

const rules: RulesDefinition<CustomOrderFormState> = {
    client: (value: unknown) => value !== undefined || 'pages:directSale.custom.client-required',
    items: {
        title: (value: unknown) => value !== '' || 'pages:directSale.custom.title-required',
        unitPriceInDecimal: (value: unknown) => value !== '' || 'pages:directSale.custom.unitPriceInDecimal-required',
        quantity: (value: unknown) => value !== '' || 'pages:directSale.custom.quantity-required',
    },
};
