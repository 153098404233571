import { useTranslation } from 'react-i18next';
import { useCallback, type Dispatch, type SetStateAction } from 'react';
import { ClientFE } from ':frontend/types/Client';
import { ClientPreferencesForm } from ':frontend/components/client/ClientPreferencesForm';
import useNotifications from ':frontend/context/NotificationProvider';
import { createErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import { trpc } from ':frontend/context/TrpcProvider';
import type { ClientUpdate } from ':utils/entity/client';
import type { OmitId } from ':utils/id';
import { SpinnerButton } from ':components/shadcn';

const formParts = {
    preferences: ClientPreferencesForm,
};

export enum ClientUpdateType {
    Preferences = 'preferences',
}

type ClientFormPartProps = {
    client: ClientFE;
    onUpdate: Dispatch<SetStateAction<ClientFE | undefined>>;
    type: ClientUpdateType;
    isDirty: boolean;
    setIsDirty: (isDirty: boolean) => void;
};

// NICE_TO_HAVE simplify, now it's way more complicated than it should be

export function ClientFormPart({ client, onUpdate, type, isDirty, setIsDirty }: ClientFormPartProps) {
    const { addAlert } = useNotifications();

    const clientUpdateMutation = trpc.$client.updateClient.useMutation();

    function onSubmit(update: OmitId<ClientUpdate>) {
        clientUpdateMutation.mutate({ id: client.id, ...update }, {
            onError: () => {
                addAlert(createErrorAlert(clientUpdateMutation.error));
            },
            onSuccess: response => {
                const updatedClient = ClientFE.fromServer(response);
                onUpdate(updatedClient);
                addAlert(createTranslatedSuccessAlert('pages:client.save-success'));
            },
        });
    }

    const saveButton = useCallback(({ onClick }: { onClick: () => void }) => (
        <SaveButton onClick={onClick} isDirty={isDirty} isFetching={clientUpdateMutation.isPending} />
    ), [ clientUpdateMutation.isPending, isDirty ]);

    const InnerForm = formParts[type];

    return (
        <InnerForm
            onSubmit={onSubmit}
            defaultValue={client}
            onChange={setIsDirty}
            saveButton={saveButton}
        />
    );
}

type SaveButtonProps = Readonly<{
    onClick: () => void;
    isDirty: boolean;
    isFetching: boolean;
}>;

function SaveButton({ onClick, isDirty, isFetching }: SaveButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'client' });

    return (
        <SpinnerButton
            type='submit'
            isFetching={isFetching}
            variant='primary'
            size='small'
            className='w-full'
            onClick={onClick}
            disabled={!isDirty}
        >
            {t('save-button')}
        </SpinnerButton>
    );
}
