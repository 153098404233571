import { useForm } from 'react-hook-form';
import { Form, SpinnerButton } from ':components/shadcn';
import { productStyles, type ProductPreview } from ':components/store/product/ProductCard';
import { MoneyDisplay } from ':components/custom';
import { CalendarEventIcon } from ':components/icons/basic';
import { type ProductOutput } from ':utils/entity/product';
import type { StoreOutput } from ':utils/entity/store';
import type { DateRange } from ':utils/dateTime';
import clsx from 'clsx';
import { useCallback } from 'react';
import type { PageOutput } from ':utils/entity/page';
import { type PagePreview } from './ProductCheckout';
import type { ProductCheckoutFormOutput } from './ProductCheckoutCard';
import { DateTimeDisplay } from ':components/custom/DateTimeDisplay';

export type ProductCustomerFormData = {
    name: string;
    email: string;
    phone?: string;
};

type ProductCustomerFormProps = Readonly<{
    store: StoreOutput;
    product: ProductOutput | ProductPreview;
    landing: PageOutput | PagePreview | undefined;
    selectedBlock?: DateRange;
    onSubmit: (data: ProductCheckoutFormOutput) => void;
    isFetching: boolean;
}>;

export function ProductCustomerForm({ store, product, landing, selectedBlock, onSubmit, isFetching }: ProductCustomerFormProps) {
    const { register, handleSubmit } = useForm<ProductCustomerFormData>();

    // FIXME
    const isPhoneInputEnabled = false;
    // FIXME
    const isPhoneInputRequired = false;

    const { iconColor } = productStyles[product.type];
    const isPreview = !('id' in product);
    const isComingSoon = !isPreview && !store.isPaymentEnabled && !!product.pricing;

    const submit = useCallback((data: ProductCustomerFormData) => {
        onSubmit({ ...data, scheduleAt: selectedBlock?.start });
    }, [ onSubmit, selectedBlock ]);

    return (
        <Form.Root onSubmit={handleSubmit(submit)} className='w-full p-4 phone:p-4 md:p-6 flex flex-col gap-4 phone:gap-4 md:gap-6'>
            {selectedBlock && (
                <div className='flex items-center gap-1'>
                    <CalendarEventIcon size='sm' className={clsx('shrink-0', iconColor)} />

                    <ProductDateRangeDisplay range={selectedBlock} />
                </div>
            )}

            {isComingSoon && (
                <div>
                    {/* FIXME Translations */}
                    <span>Product coming soon.</span>
                </div>
            )}

            <div className='flex flex-col gap-2'>
                {/* TODO input icon */}
                <Form.Input {...register('name')} size='compact' placeholder='Name' className='w-full' disabled={isComingSoon} />
                {/* TODO input icon */}
                <Form.Input {...register('email')} size='compact' type='email' required placeholder='Email' className='w-full' disabled={isComingSoon} />
                {isPhoneInputEnabled && (
                    <Form.Input
                        {...register('phone', { required: isPhoneInputRequired })}
                        size='compact'
                        type='tel'
                        placeholder='Phone'
                        className='w-full'
                        disabled={isComingSoon}
                    />
                )}
            </div>

            <div className='w-full flex gap-3 items-center justify-end'>
                {product.pricing && (
                    <MoneyDisplay amount={product.pricing.price} currency={product.pricing.currency} className='text-lg' />
                )}

                <SpinnerButton type='submit' className='fl-store-button min-w-0' isFetching={isFetching} disabled={isComingSoon}>
                    <span className='truncate'>

                        {landing?.buttonText ?? 'Continue'}
                    </span>
                </SpinnerButton>
            </div>
        </Form.Root>
    );
}

export function ProductDateRangeDisplay({ range, className }: { range: DateRange, className?: string }) {
    return (
        <div className={className}>
            <DateTimeDisplay dateTime={range.start} time />
            {' - '}
            <DateTimeDisplay dateTime={range.end} time />
            {', '}
            <DateTimeDisplay dateTime={range.start} date className='text-secondary-400' />
        </div>
    );
}
