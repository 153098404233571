import { cn } from ':components/shadcn/utils';
import { BundleProductIcon, CustomProductIcon, DigitalProductIcon, LeadProductIcon, LinkProductIcon, MembershipProductIcon, ReferralProductIcon, SessionProductIcon } from ':components/icons/store';
import { socialIcons } from ':components/icons/logos';
import clsx from 'clsx';

type PillsSetProps = Readonly<{
  isSorted: boolean;
}>;

export function PillsSetSort({ isSorted }: PillsSetProps) {
    return (
        <div className='absolute bottom-0 top-0 w-[280px] flex items-center justify-center pointer-events-none'>
            <div className={clsx('grid grid-cols-4 gap-2 transition-all duration-1000 translate-y-[100px]', isSorted && 'max-md:translate-y-[-200px]')}>
                {icons.map(({ icon: Icon, size, unsortedClass }, index) => (
                    <div key={index} className={getPillClass(unsortedClass, isSorted)}>
                        <Icon size={size} />
                    </div>
                ))}
            </div>
        </div>
    );
}

function getPillClass(unsortedClass: string, isSorted: boolean) {
    return cn(
        baseClass,
        isSorted ? commonSortedClass : (commonUnsortedClass + ' ' + unsortedClass),
    );
}

const baseClass = 'relative shadow-[0_24px_34px_0_rgba(0,0,0,0.2)] bg-white rounded-xl flex justify-center items-center border border-black/10 size-16 transition-all ease-in-out';
const commonSortedClass = 'top-0 left-0 right-0 duration-1000 max-md:size-0 max-md:opacity-0';
const commonUnsortedClass = 'animate-pill [animation-iteration-count:_infinite] [animation-duration:_3s]';
const icons = [
    { icon: socialIcons.TwitterIcon, size: 'sm', unsortedClass: 'md:top-[-300px] left-[-40px]' },
    { icon: socialIcons.YouTubeIcon, size: 'sm', unsortedClass: 'md:top-[-320px] left-[0px] [animation-delay:_0.1s]' },
    { icon: socialIcons.LinkedInIcon, size: 'sm', unsortedClass: 'md:top-[-260px] left-[100px] [animation-delay:_0.2s]' },
    { icon: socialIcons.InstagramIcon, size: 'sm', unsortedClass: 'md:top-[-130px] left-[30px] [animation-delay:_0.3s]' },
    { icon: socialIcons.FacebookIcon, size: 'md', unsortedClass: 'top-[-60px] left-[-50px] [animation-delay:_0.4s]' },
    { icon: MembershipProductIcon, size: 'md', unsortedClass: 'top-[-20px] left-[-150px] [animation-delay:_0.5s]' },
    { icon: DigitalProductIcon, size: 'md', unsortedClass: 'top-[-160px] left-[40px] [animation-delay:_0.6s]' },
    { icon: LinkProductIcon, size: 'md', unsortedClass: 'top-[-90px] left-[-120px] [animation-delay:_0.7s]' },
    { icon: ReferralProductIcon, size: 'md', unsortedClass: 'top-[-90px] left-[-120px] [animation-delay:_0.7s]' },
    { icon: LeadProductIcon, size: 'md', unsortedClass: 'top-[-170px] right-[-40px] [animation-delay:_0.8s]' },
    { icon: BundleProductIcon, size: 'md', unsortedClass: 'top-[-60px] right-[-10px] [animation-delay:_0.9s]' },
    { icon: SessionProductIcon, size: 'md', unsortedClass: 'top-[0px] left-[100px] [animation-delay:_1s]' },
    { icon: CustomProductIcon, size: 'md', unsortedClass: 'top-[100px] left-[-100px] [animation-delay:_1.1s]' },
] as const;
