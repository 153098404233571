import { useEffect } from 'react';
import { Form } from ':components/shadcn';
import { useForm } from 'react-hook-form';
import { addressToEditable, addressToUpsert, type EditableAddress } from ':frontend/types/Address';
import { optionalStringToPut } from ':frontend/utils/common';
import { NewInvoicingProfileFormInner, type NewInvoicingProfileFormData } from './NewInvoicingProfileForm';
import { useTranslation } from 'react-i18next';
import { useMaster } from ':frontend/context/UserProvider';
import { ControlledCountrySelect } from '../forms';
import { canonizeEmail } from ':utils/forms';
import { InfoTooltip } from '../common/InfoTooltip';
import type { InvoicingProfileGeneralUpsert, InvoicingProfileOutput } from ':utils/entity/invoicing';

type InputType = InvoicingProfileOutput;
type FormDataType = NewInvoicingProfileFormData & EditableAddress & {
    cin: string;
    legalName: string;
    tin: string;
};

export type GeneralSupplierFormOutput = {
    general: InvoicingProfileGeneralUpsert;
};

function inputToForm(input: InputType): FormDataType {
    return {
        ...addressToEditable(input.address),
        cin: input.cin ?? '',
        tin: input.tin ?? '',
        legalName: input.legalName ?? '',
        title: input.title,
        locale: input.locale,
        dueDays: input.dueDays,
        invoicingEmail: input.email ?? '',
        hideEmailOnInvoice: input.hideEmailOnInvoice,
    };
}

function formToOutput(data: FormDataType): GeneralSupplierFormOutput {
    return {
        general: {
            address: addressToUpsert(data),
            cin: optionalStringToPut(data.cin),
            tin: optionalStringToPut(data.tin),
            legalName: optionalStringToPut(data.legalName),
            email: optionalStringToPut(canonizeEmail(data.invoicingEmail)),
            hideEmailOnInvoice: data.hideEmailOnInvoice,
            title: data.title,
            locale: data.locale,
            dueDays: data.dueDays,
        },
    };
}

type GeneralSupplierFormProps = Readonly<{
    input: InputType;
    onSubmit: (output: GeneralSupplierFormOutput) => void;
    saveButton?: React.FC<{ onClick: () => void }>;
    onChange?: (isDirty: boolean) => void;
}>;

export function GeneralSupplierForm({ input, onSubmit, saveButton, onChange }: GeneralSupplierFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'invoicingForm' });
    const { profiles } = useMaster();

    const form = useForm<FormDataType>({
        defaultValues: inputToForm(input),
    });
    const { register, control, formState: { errors, isDirty }, setValue, handleSubmit } = form;

    function onValid(data: FormDataType) {
        onSubmit(formToOutput(data));
    }

    useEffect(() => onChange?.(isDirty), [ isDirty ]);
    // GeneralSupplierForm and PersonalizationForm can be edited and saved independently
    // but we don't want saving of one form to reset the other
    // useEffect(() => {
    //     reset(inputToForm(input));
    // }, [ input ]);

    // If there is only one profile, we show only that profile. Therefore, there's no need to show the name field.
    const isOnlyOneProfile = profiles.length === 1;

    return (
        <Form.Root onSubmit={handleSubmit(onValid)} className='space-y-8'>
            <NewInvoicingProfileFormInner
                control={control}
                errors={errors}
                setValue={setValue}
                watch={form.watch}
                hideName={isOnlyOneProfile}
            />

            <div className='space-y-4'>
                <h5 className='font-semibold text-lg'>{t('invoicing-company-title')}</h5>

                <div className='flex items-end gap-4'>
                    <div className='flex-1'>
                        <Form.Input
                            label={t('cin-label')}
                            {...register('cin')}
                            placeholder={t('cin-placeholder')}
                        />
                    </div>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <div>
                        <Form.Input
                            label={t('legal-name-label')}
                            {...register('legalName')}
                            placeholder={t('legal-name-placeholder')}
                        />
                    </div>

                    <div>
                        <Form.Input
                            label={<>{t('tin-label')}<InfoTooltip text={t('tin-tooltip')} className='ml-2' /></>}
                            {...register('tin')}
                        />
                    </div>
                </div>
            </div>

            <div className='space-y-4'>
                <h5 className='font-semibold text-lg'>{t('invoicing-address-title')}</h5>

                <div>
                    <Form.Input
                        label={t('city-label')}
                        {...register('city')}
                        placeholder={t('city-placeholder')}
                    />
                </div>

                <div>
                    <Form.Input
                        label={t('line1-label')}
                        {...register('line1')}
                        placeholder={t('line1-placeholder')}
                    />
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <div>
                        <Form.Input
                            label={t('postal-code-label')}
                            {...register('postalCode')}
                            placeholder={t('postal-code-placeholder')}
                        />
                    </div>

                    <div>
                        <Form.Label>{t('country-label')}</Form.Label>
                        <ControlledCountrySelect
                            control={control}
                            name='country'
                        />
                    </div>
                </div>
            </div>

            <>{saveButton?.({ onClick: handleSubmit(onValid) })}</>
        </Form.Root>
    );
}
