import { Button, Form, Modal, SpinnerButton } from ':components/shadcn';
import { trpc } from ':frontend/context/TrpcProvider';
import type { StoreOutput } from ':utils/entity/store';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { optionalStringToPatch } from ':frontend/utils/common';
import useNotifications from ':frontend/context/NotificationProvider';
import { useToggle } from ':frontend/hooks';
import { StiggFeature } from ':utils/lib/stigg';
import { UpsellButton } from ':frontend/pages/settings/Subscriptions';
import { useEntitlement } from ':frontend/lib/stigg';

type TermsOfServiceProps = Readonly<{
    store: StoreOutput;
    switchSize?: 'small' | 'default';
    customLabel?: string;
}>;

export function TermsOfService({ store, switchSize, customLabel }: TermsOfServiceProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general.termsOfService' });
    const isEnabled = useEntitlement(StiggFeature.TermsOfServices);

    const [ showModal, setShowModal ] = useToggle(false);

    return (
        <div className='flex flex-wrap items-center justify-between gap-4'>
            <div className='flex items-center gap-2'>
                <Form.Switch
                    label={customLabel ?? t('switch-label')}
                    size={switchSize}
                    checked={!!store.termsOfService}
                    disabled={!isEnabled}
                    // The switch actually never switches anything (because we don't want to delete the whole TOS just by clicking the switch).
                    // Instead, we show the modal and let the user edit or delete the TOS.
                    onCheckedChange={setShowModal.true}
                />
            </div>

            {!isEnabled && (
                <div className='grow flex justify-end'>
                    <UpsellButton feature={StiggFeature.TermsOfServices} />
                </div>
            )}

            <Modal.Root open={showModal} onOpenChange={setShowModal.value}>
                <TermsOfServiceModalContent input={store.termsOfService} onClose={setShowModal.false} />
            </Modal.Root>
        </div>
    );
}

type TermsOfServiceModalContentProps = Readonly<{
    input: string | undefined;
    onClose: () => void;
}>;

function TermsOfServiceModalContent({ input, onClose }: TermsOfServiceModalContentProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general.termsOfService' });
    const [ isFetching, setIsFetching ] = useState(false);
    const [ value, setValue ] = useState(input ?? '');

    const updateStoreMutation = trpc.store.updateStore.useMutation();
    const utils = trpc.useUtils();

    const { addAlert } = useNotifications();

    function save() {
        const termsOfService = optionalStringToPatch(input, value);
        if (termsOfService === undefined) {
            addAlert(createTranslatedSuccessAlert('pages:settings.general.termsOfService.success'));
            onClose();
            return;
        }

        setIsFetching(true);
        updateStoreMutation.mutate({ termsOfService }, {
            onError: error => addAlert(createErrorAlert(error.data)),
            onSuccess: data => {
                utils.store.getStore.setData(undefined, data);
                addAlert(createTranslatedSuccessAlert('pages:settings.general.termsOfService.success'));
                onClose();
            },
            onSettled: () => {
                setIsFetching(false);
            },
        });
    }

    return (
        <Modal.Content closeButton={t('cancel-button')} className='max-w-[800px] gap-6'>
            <Modal.Header>
                <Modal.Title>{t('modal-title')}</Modal.Title>
            </Modal.Header>
            <Form.Textarea
                minRows={10}
                value={value}
                onChange={e => setValue(e.target.value)}
            />
            <Modal.Footer className='grid grid-cols-2'>
                <Button variant='white' onClick={onClose} disabled={isFetching}>
                    {t('cancel-button')}
                </Button>
                <SpinnerButton type='submit' onClick={save} isFetching={isFetching}>
                    {t('save-button')}
                </SpinnerButton>
            </Modal.Footer>
        </Modal.Content>
    );
}
