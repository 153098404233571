import { useTranslation } from 'react-i18next';
import { Modal, Button } from ':components/shadcn';
import { ArrowRightIcon, ChevronDownIcon, Clock1Icon } from ':components/icons/basic';
import { forwardRef, useState, type ComponentPropsWithoutRef } from 'react';
import { DateTime } from 'luxon';
import { DatePicker } from '../forms';
import { cn } from ':components/shadcn/utils';
import { createDefaultAvailabilityRange } from './useProductForms';
import type { DateRange } from ':utils/dateTime';

type SelectBlockTimeModalProps = Readonly<{
    onSelect: (props: DateRange) => void;
}>;

export function SelectBlockTimeModal({ onSelect }: SelectBlockTimeModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'selectBlockTimeModal' });

    const [ showSelectBlockTimeModal, setShowSelectBlockTimeModal ] = useState(false);
    const [ range, setRange ] = useState<DateRange>(createDefaultAvailabilityRange());

    return (<>
        <Button
            variant='transparent'
            size='exact'
            className='text-primary font-semibold'
            onClick={() => setShowSelectBlockTimeModal(true)}
        >
            {t('show-modal-button')} <ArrowRightIcon />
        </Button>

        <Modal.Root
            open={showSelectBlockTimeModal}
            onOpenChange={open => !open && setShowSelectBlockTimeModal(false)}
        >
            <Modal.Content className='gap-6' closeButton={t('close-button')}>
                <Modal.Header className='space-y-4'>
                    <Clock1Icon size={32} className='mx-auto text-primary stroke-2' />

                    <Modal.Title className='text-3xl leading-9 text-center font-semibold text-secondary-900'>
                        {t('title')}
                    </Modal.Title>
                </Modal.Header>

                <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                    <div>
                        <DatePicker
                            selected={range.start}
                            onChange={start => {
                                if (!start)
                                    return;

                                let end = range.end;
                                if (start > end)
                                    end = start.hour < end.hour  ? start.set({ hour: end.hour }) : start.plus({ hour: 2 });

                                setRange({ start, end });
                            }}
                            type='all'
                            customInput={<DatePickerInput label={t('start-label')} />}
                            wrapperClassName='w-full'
                        />
                    </div>

                    <div>
                        <DatePicker
                            selected={range.end}
                            onChange={end => {
                                if (!end)
                                    return;

                                let start = range.start;
                                if (end < start)
                                    start = end.hour > start.hour ? end.set({ hour: start.hour }) : end.minus({ hour: 2 });

                                setRange({ start, end });
                            }}
                            type='all'
                            customInput={<DatePickerInput label={t('end-label')} />}
                            wrapperClassName='w-full'
                        />
                    </div>
                </div>

                <Modal.Footer className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                    <Button variant='secondary' onClick={() => setShowSelectBlockTimeModal(false)}>
                        {t('cancel-button')}
                    </Button>

                    <Button
                        variant='primary'
                        onClick={() => {
                            onSelect(range);
                            setShowSelectBlockTimeModal(false);
                        }}
                    >
                        {t('confirm-button')}
                    </Button>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    </>);
}

type DatePickerInputProps = ComponentPropsWithoutRef<'button'> & {
    value?: string;
    className?: string;
    label: string;
};

const DatePickerInput = forwardRef<HTMLButtonElement, DatePickerInputProps>(({ value, label, className, ...rest }, ref) => {
    if (typeof value !== 'string')
        return;

    // i don't really understand how this works
    // i think the DatePicker should either provide a DateTime or a correctly formatted ISO string,
    // either in UTC or in the user's (input) timezone
    const dateTime = DateTime.fromISO(value, { setZone: true });

    return (
        <button
            ref={ref}
            type='button'
            className={cn(className, 'flex items-center gap-3 p-4 rounded-xl border border-secondary-100 bg-secondary-50 text-start w-full')}
            {...rest}
        >
            <div>
                <label className='opacity-75'>{label}</label>
                <p className='font-semibold text-2lg leading-normal'>{dateTime.toLocaleString(DateTime.TIME_SIMPLE)}</p>
                <p>{dateTime.toLocaleString(DateTime.DATE_MED)}</p>
            </div>
            <ChevronDownIcon size={14} />
        </button>
    );
});
