import type { DateTime } from 'luxon';
import { localizer, step, timeslots } from './common';

type GetKeyInput = {
    min: DateTime;
    max: DateTime;
    step?: number;
    slots?: number;
};

function getKey({ min, max, step, slots }: GetKeyInput): string {
    return `${+localizer.startOf(min, 'minute')}` +
        `${+localizer.startOf(max, 'minute')}` +
        `${step}-${slots}`;
}

export type SlotMetrics = ReturnType<typeof getSlotMetrics>;
export type GetRangeReturn = {
    top: number;
    height: number;
    start: number;
    startDate: DateTime;
    end: number;
    endDate: DateTime;
};

export function getSlotMetrics({ min: start, max: end }: { min: DateTime, max: DateTime }) {
    const key = getKey({ min: start, max: end });

    // DST differences are handled inside the localizer
    const totalMin = 1 + localizer.getTotalMin(start, end);
    const minutesFromMidnight = localizer.getMinutesFromMidnight(start);
    const numGroups = Math.ceil((totalMin - 1) / (step * timeslots));
    const numSlots = numGroups * timeslots;

    const groups: DateTime[][] = new Array(numGroups);
    const slots: DateTime[] = new Array(numSlots);
    // Each slot date is created from `zero`, instead of adding `step` to
    // the previous one, in order to avoid DST oddities
    for (let groupIndex = 0; groupIndex < numGroups; groupIndex++) {
        groups[groupIndex] = new Array(timeslots);

        for (let slot = 0; slot < timeslots; slot++) {
            const slotIndex = groupIndex * timeslots + slot;
            const minFromStart = slotIndex * step;
            // A date with total minutes calculated from the start of the day
            slots[slotIndex] = groups[groupIndex][slot] = localizer.getSlotDate(
                start,
                minutesFromMidnight,
                minFromStart,
            );
        }
    }

    // Necessary to be able to select up until the last timeslot in a day
    const lastSlotMinFromStart = slots.length * step;
    slots.push(localizer.getSlotDate(start, minutesFromMidnight, lastSlotMinFromStart));

    function positionFromDate(date: DateTime) {
        const diff = localizer.diff(start, date, 'minute') + localizer.getDstOffset(start, date);
        return Math.min(diff, totalMin);
    }

    return {
        groups,

        update(args: GetKeyInput) {
            if (getKey(args) !== key)
                return getSlotMetrics(args);
            return this;
        },

        dateIsInGroup(date: DateTime, groupIndex: number) {
            const nextGroup = groups[groupIndex + 1];
            return localizer.inRange(
                date,
                groups[groupIndex][0],
                nextGroup ? nextGroup[0] : end,
                'minute',
            );
        },

        nextSlot(slot: DateTime): DateTime {
            let next = slots[Math.min(slots.indexOf(slot) + 1, slots.length - 1)];
            // in the case of the last slot we won't a long enough range so manually get it
            if (next === slot)
                next = localizer.add(slot, step, 'minute');
            return next;
        },

        closestSlotToPosition(percent: number): DateTime {
            const slot = Math.min(
                slots.length - 1,
                Math.max(0, Math.floor(percent * numSlots)),
            );
            return slots[slot];
        },

        closestSlotFromPoint(point: { x: number, y: number }, boundaryRect: { top: number, bottom: number }): DateTime {
            const range = Math.abs(boundaryRect.top - boundaryRect.bottom);
            return this.closestSlotToPosition((point.y - boundaryRect.top) / range);
        },

        closestSlotFromDate(date: DateTime, offset = 0): DateTime {
            if (localizer.lt(date, start, 'minute'))
                return slots[0];
            if (localizer.gt(date, end, 'minute'))
                return slots[slots.length - 1];

            const diffMins = localizer.diff(start, date, 'minute');
            return slots[(diffMins - (diffMins % step)) / step + offset];
        },

        getRange(rangeStart: DateTime, rangeEnd: DateTime, ignoreMin?: boolean, ignoreMax?: boolean): GetRangeReturn {
            if (!ignoreMin)
                rangeStart = localizer.min(end, localizer.max(start, rangeStart));
            if (!ignoreMax)
                rangeEnd = localizer.min(end, localizer.max(start, rangeEnd));

            const rangeStartMin = positionFromDate(rangeStart);
            const rangeEndMin = positionFromDate(rangeEnd);
            const top = (rangeEndMin > step * numSlots && !localizer.eq(end, rangeEnd))
                ? ((rangeStartMin - step) / (step * numSlots)) * 100
                : (rangeStartMin / (step * numSlots)) * 100;

            return {
                top,
                height: (rangeEndMin / (step * numSlots)) * 100 - top,
                start: rangeStartMin,
                startDate: rangeStart,
                end: rangeEndMin,
                endDate: rangeEnd,
            };
        },

        getCurrentTimePosition(rangeStart: DateTime): number {
            const rangeStartMin = positionFromDate(rangeStart);
            const top = (rangeStartMin / (step * numSlots)) * 100;

            return top;
        },
    };
}
