import { useCallback, useMemo } from 'react';
import EmojiPickerReact, { EmojiStyle, type EmojiClickData, Categories } from 'emoji-picker-react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button, DropdownMenu } from ':components/shadcn';
import { useToggle } from ':frontend/hooks';
import { FacePlusIcon, Trash2Icon } from ':components/icons/basic';

type EmojiPickerProps = Readonly<{
    value?: string;
    onChange: (emoji: string) => void;
    className?: string;
}>;

export function EmojiPicker({ value, onChange, className }: EmojiPickerProps) {
    const { t } = useTranslation('components', { keyPrefix: 'emojiPicker' });
    const [ isShow, setIsShow ] = useToggle(false);

    const innerOnChange = useCallback((emoji: EmojiClickData) => {
        onChange(emoji.emoji);
        setIsShow.false();
    }, [ onChange ]);

    function unselect() {
        onChange('');
        setIsShow.false();
    }

    const isEmoji = value && value !== '';

    return (
        <div className={clsx('flex items-center', className)}>
            <DropdownMenu.Root open={isShow} onOpenChange={setIsShow.value}>
                <DropdownMenu.Trigger
                    className='size-9 min-h-0 py-1 px-[5px] border rounded-md bg-transparent block text-inherit'
                    aria-label={t('emoji-button-aria')}
                    onClick={setIsShow.toggle}
                >
                    {isEmoji
                        ? <span className='text-lg'>{value}</span>
                        : <FacePlusIcon size={24} />
                    }
                </DropdownMenu.Trigger>
                <DropdownMenu.Content className='p-0'>
                    <EmojiPickerInner onEmojiClick={innerOnChange} />
                </DropdownMenu.Content>
            </DropdownMenu.Root>
            {isEmoji && (
                <Button variant='transparent' size='exact' className='p-2' onClick={unselect} aria-label={t('remove-button-aria')}>
                    <Trash2Icon />
                </Button>
            )}
        </div>
    );
}

type EmojiPickerInnerProps = Readonly<{
    onEmojiClick: (data: EmojiClickData) => void;
}>;

export function EmojiPickerInner({ onEmojiClick }: EmojiPickerInnerProps) {
    const { t } = useTranslation('components', { keyPrefix: 'emojiPicker' });

    const categories: CategoryConfig[] = useMemo(() => allEmojiCategories.map(category => ({
        category,
        name: t('categories.' + category),
    })), [ t ]);

    return (
        <EmojiPickerReact
            onEmojiClick={onEmojiClick}
            skinTonesDisabled={true}
            previewConfig={{ showPreview: false }}
            emojiStyle={EmojiStyle.NATIVE}
            searchPlaceholder={t('search-placeholder')}
            categories={categories}
            className='!border-0'
        />
    );
}

type CategoryConfig = {
    category: Categories;
    name: string;
};

const allEmojiCategories: Categories[] = [
    Categories.SUGGESTED,
    Categories.SMILEYS_PEOPLE,
    Categories.ANIMALS_NATURE,
    Categories.FOOD_DRINK,
    Categories.TRAVEL_PLACES,
    Categories.ACTIVITIES,
    Categories.OBJECTS,
    Categories.SYMBOLS,
    Categories.FLAGS,
];
