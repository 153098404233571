import { DateTime } from 'luxon';
import { getDefaultCurrency, amountFromDecimal } from ':utils/money';
import { OrderCreatedFrom, type OrderStatsOrder, type OrderStatsOutput, type OrderStatsProduct } from ':utils/entity/order';
import { capitalize, getEnumValues } from ':utils/common';
import type { VisitOutput } from ':utils/entity/store';
import { OrderState } from ':utils/entity/invoicing';
import { ProductType } from ':utils/entity/product';
import { PredefinedRangeType } from ':utils/dateTime';

export function createExampleOrderStats(rangeType: PredefinedRangeType): OrderStatsOutput {
    const visits = rangeType === PredefinedRangeType.last12Months ? createExampleYearData() : createExampleMonthData();
    const clickCount = Math.floor(visits.length / 2);
    const orderCount = Math.floor(visits.length / 4);
    const ordersValue = amountFromDecimal(Math.floor(visits.length * 12));
    const orders = Array(orderCount) as OrderStatsOrder[];
    orders[0] = { createdAt: visits[0].createdAt, total: ordersValue, state: OrderState.new, createdFrom: OrderCreatedFrom.App };
    const products = createExampleProductData();

    return {
        visits,
        clicks: Array(clickCount) as VisitOutput[],
        orders,
        products,
        range: {
            start: visits[0].createdAt,
            end: visits[visits.length - 1].createdAt,
        },
        rangeType,
        currency: getDefaultCurrency(),
    };
}

function createExampleMonthData(): VisitOutput[] {
    const stats = [ 22, 27, 32, 35, 43, 49, 52, 59, 50, 67, 73, 78, 83, 89, 84, 98, 103, 109, 112, 118, 101, 110, 132, 139, 141, 148, 151, 155, 160, 168, 170 ];
    const start = DateTime.now().minus({ days: stats.length });
    const output: VisitOutput[] = [];

    for (let i = 0; i < stats.length; i++) {
        const count = stats[i];
        const date = start.plus({ days: i });
        for (let j = 0; j < count; j++)
            output.push({ createdAt: date });
    }

    return output;
}

function createExampleYearData(): VisitOutput[] {
    const stats = [ 22, 27, 43, 52, 61, 67, 78, 74, 70, 80, 90, 100, 120 ];
    const start = DateTime.now().minus({ months: stats.length });
    const output: VisitOutput[] = [];

    for (let i = 0; i < stats.length; i++) {
        const count = stats[i];
        const date = start.plus({ months: i });
        for (let j = 0; j < count; j++)
            output.push({ createdAt: date });
    }

    return output;
}

function createExampleProductData(): OrderStatsProduct[] {
    const output: OrderStatsProduct[] = [];

    for (const productType of getEnumValues(ProductType)) {
        output.push({
            id: 'example' + productType,
            type: productType,
            title: capitalize(productType),
            clicks: Math.floor(Math.random() * 1000),
            ordersCount: Math.floor(Math.random() * 1000),
            ordersValue: amountFromDecimal(Math.floor(Math.random() * 1000)),
        });
    }

    return output;
}
