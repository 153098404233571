import { useState } from 'react';
import { Button, Tooltip, SpinnerButton } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { api } from ':frontend/utils/api';
import { useUser } from ':frontend/context/UserProvider';
import { createTranslatedErrorAlert } from '../notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { Link } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { type AppUserFE } from ':frontend/types/AppUser';
import { BulletFeatures } from './integrationUtils';

export function GoogleIntegration() {
    const { t } = useTranslation('pages', { keyPrefix: 'integrations.google' });
    const { state, isFetching, connect } = useGoogleIntegration();

    return (
        <div className='border rounded-2xl p-5 max-sm:space-y-4 sm:flex sm:items-start sm:gap-4'>
            <img className='h-8 w-auto' src={`/static/integration-icons/google-${state === IntegrationState.Partial ? 'email' : 'calendar'}.svg`} />

            <div>
                <h2 className='mb-2 text-secondary-900'>{t('product')}</h2>

                {state === IntegrationState.Partial ? (<>
                    <BulletFeatures text={t(`features-${state}-active`)} isActive={true} />
                    <BulletFeatures text={t(`features-${state}-available`)} isActive={false} />
                </>) : (
                    <BulletFeatures text={t(`features-${state}`)} isActive={state === IntegrationState.All} />
                )}
            </div>

            <div className='grow self-stretch flex items-center justify-end'>
                {state === IntegrationState.All ? (
                    <Link to={routesFE.calendar}>
                        <Button variant='ghost' size='small' className='max-sm:w-full'>
                            {t(`action-button-${state}`)}
                        </Button>
                    </Link>
                ) : (
                    <SpinnerButton
                        isFetching={isFetching}
                        onClick={() => connect()}
                        size='small'
                        className='max-sm:w-full'
                    >
                        {t(`action-button-${state}`)}
                    </SpinnerButton>
                )}
            </div>
        </div>
    );
}

function useGoogleIntegration() {
    const { addAlert } = useNotifications();
    const { appUser } = useUser();
    const [ isFetching, setIsFetching ] = useState(false);

    async function connect() {
        if (isFetching)
            return;

        const connectRes = await api.backend.googleIntegration();
        setIsFetching(false);
        if (!connectRes.status || !connectRes.data.redirectUrl) {
            addAlert(createTranslatedErrorAlert());
            return;
        }

        window.location.href = connectRes.data.redirectUrl;
    }

    return {
        state: getIntegrationState(appUser),
        isFetching,
        connect,
    };
}

enum IntegrationState {
    None = 'none',
    Partial = 'partial',
    All = 'all',
}

function getIntegrationState(appUser: AppUserFE) {
    // TODO this needs to be rethinked. There are more states now.

    if (!appUser.google.calendarId)
        return IntegrationState.None;

    return appUser.google.isContacts ? IntegrationState.All : IntegrationState.Partial;
}

export function GoogleIntegrationBadge() {
    const { t } = useTranslation('pages', { keyPrefix: 'integrations.google' });
    const { state, isFetching, connect } = useGoogleIntegration();

    if (state === IntegrationState.All)
        return null;

    return (
        <div>
            <Tooltip
                side='left'
                tooltipText={t(`badge-text-${state}`)}
            >
                <div>
                    <SpinnerButton
                        isFetching={isFetching}
                        className='w-full'
                        onClick={connect}
                        variant='white'
                        size='small'
                    >
                        <div className='size-5 mr-1'>
                            <img src={`/static/integration-icons/google-${state === IntegrationState.Partial ? 'email' : 'calendar'}.svg`} />
                        </div>
                        <div>
                            {t(`badge-title-${state}`)}
                        </div>
                    </SpinnerButton>
                </div>
            </Tooltip>
        </div>
    );
}
