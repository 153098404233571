import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ClientFE } from ':frontend/types/Client';
import { Tabs } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { routesFE } from ':utils/routes';
import { useBlockerModal } from ':frontend/hooks';
import { BlockNavigationModal } from ':frontend/components/BlockNavigationModal';
import { trpc } from ':frontend/context/TrpcProvider';
import { WindowPlus2Icon } from ':components/icons/basic';
import ClientNotes from './ClientNotes';
import ClientOrders from './ClientOrders';
import ClientEvents from './ClientEvents';
import ClientOverview from './ClientOverview';
import ClientPreferences from './ClientPreferences';
import { ClientModules } from './ClientModules';
import { useMaster } from ':frontend/context/UserProvider';
import ClientInvoicing from './ClientInvoicing';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon, UserListIcon, InvoiceIcon, CalendarIcon, NotepadIcon, LanguageIcon, LicenseIcon } from ':components/icons/basic';
import { TabsTopbar } from ':frontend/components/Layout';

export const CLIENT_DETAIL_SCROLLER_ID = 'client-detail-scroller';

// We assume that the client isn't deleted. If he is, he shouldn't be returned from the server.

export default function ClientDetail() {
    const { t } = useTranslation('pages', { keyPrefix: 'client' });
    const { id, key } = useParams<{id: string, key: string}>();
    const navigate = useNavigate();
    const handleSelect = useCallback((newKey: string | null) => id && newKey && navigate(routesFE.clients.detail.resolve({ id, key: newKey })), [ id, navigate ]);

    const { isLocalizationModuleOn, isNotesModuleOn, isInvoicingModuleOn } = useMaster().teamSettings;

    const clientQuery = trpc.$client.getClient.useQuery({ id: id! });
    const trpcUtils = trpc.useUtils();
    const invalidateClient = useCallback(() => trpcUtils.$client.getClient.invalidate(), [ trpcUtils ]);

    const client = useMemo(() => clientQuery.data && ClientFE.fromServer(clientQuery.data), [ clientQuery.data ]);

    const [ isDirty, setIsDirty ] = useState(false);
    const { navigateUnblocked, control } = useBlockerModal(isDirty);

    if (!client)
        return null;

    return (<>
        {/* NICE_TO_HAVE move BlockNavigationModal to the inner pages */}
        <BlockNavigationModal control={control} />

        <Tabs.Root value={key} onValueChange={handleSelect} className='flex flex-col h-full'>
            <TabsTopbar>
                <Tabs.List>
                    <Link to={routesFE.clients.list.path} className='px-3 py-2 rounded-md leading-4 flex items-center gap-2 hover:bg-primary-50 whitespace-nowrap'>
                        <ChevronLeftIcon size='md' /> {t('back-to-list-tab-title')}
                    </Link>

                    <Tabs.Trigger value='overview'>
                        <UserListIcon size='md' /> {t('overview-tab-title')}
                    </Tabs.Trigger>
                    <Tabs.Trigger value='orders'>
                        <InvoiceIcon size='md' /> {t('orders-tab-title')}
                    </Tabs.Trigger>
                    <Tabs.Trigger value='events'>
                        <CalendarIcon size='md' /> {t('events-tab-title')}
                    </Tabs.Trigger>

                    {isLocalizationModuleOn && (
                        <Tabs.Trigger value='preferences'>
                            <LanguageIcon size='md' /> {t('preferences-tab-title')}
                        </Tabs.Trigger>
                    )}

                    {isNotesModuleOn && (
                        <Tabs.Trigger value='notes'>
                            <NotepadIcon size='md' /> {t('notes-tab-title')}
                        </Tabs.Trigger>
                    )}

                    {isInvoicingModuleOn && (
                        <Tabs.Trigger value='invoicing'>
                            <LicenseIcon size='md' /> {t('invoicing-tab-title')}
                        </Tabs.Trigger>
                    )}

                    <Tabs.Trigger value='modules'><WindowPlus2Icon /></Tabs.Trigger>
                </Tabs.List>
            </TabsTopbar>

            <div
                className='px-4'
                id={CLIENT_DETAIL_SCROLLER_ID}
            >
                <div className='max-w-[1024px] w-full mx-auto py-8'>
                    <Tabs.Content value='overview'>
                        <ClientOverview client={client} navigateUnblocked={navigateUnblocked} invalidateClient={invalidateClient} setIsDirty={setIsDirty} />
                    </Tabs.Content>
                    <Tabs.Content value='orders'>
                        <ClientOrders client={client} />
                    </Tabs.Content>
                    <Tabs.Content value='events'>
                        <ClientEvents client={client} />
                    </Tabs.Content>

                    {isLocalizationModuleOn && (
                        <Tabs.Content value='preferences'>
                            <ClientPreferences client={client} navigateUnblocked={navigateUnblocked} invalidateClient={invalidateClient} isDirty={isDirty} setIsDirty={setIsDirty} />
                        </Tabs.Content>
                    )}

                    {isNotesModuleOn && (
                        <Tabs.Content value='notes'>
                            <ClientNotes client={client} />
                        </Tabs.Content>
                    )}

                    {isInvoicingModuleOn && (
                        <Tabs.Content value='invoicing'>
                            <ClientInvoicing client={client} invalidateClient={invalidateClient} setIsDirty={setIsDirty} isDirty={isDirty} />
                        </Tabs.Content>
                    )}

                    <Tabs.Content value='modules'>
                        <ClientModules />
                    </Tabs.Content>
                </div>
            </div>
        </Tabs.Root>
    </>);
}
