import { ApiAuthorizer, createApiObject } from './types';
import superjson from 'superjson';
import type { Result } from ':frontend/types/result';
import type { AppUserGoogleInit } from ':utils/entity/user';
import type { OrderInit, ExportOrdersQuery } from ':utils/entity/order';
import { routesBE } from ':utils/routes';
import type { VisitInitInput } from ':utils/entity/store';
import type { VersionOutput } from ':utils/common';
import type { FileOutput } from ':utils/entity/file';
import type { ExportClientsQuery } from ':utils/entity/client';

const publicApiObject = createApiObject();

const authorizer = new ApiAuthorizer();
const privateApiObject = createApiObject({
    authorizer,
});

type GoogleAuthFromServer = {
    redirectUrl: string;
};

function googleRegistration(query: AppUserGoogleInit): Promise<Result<GoogleAuthFromServer>> {
    return publicApiObject.POST<GoogleAuthFromServer, AppUserGoogleInit>(routesBE.google.oauth, query);
}

function googleLogin(): Promise<Result<GoogleAuthFromServer>> {
    return publicApiObject.POST<GoogleAuthFromServer>(routesBE.google.oauth);
}

function googleIntegration(): Promise<Result<GoogleAuthFromServer>> {
    return privateApiObject.POST<GoogleAuthFromServer>(routesBE.google.integration);
}

function getVersion(signal?: AbortSignal): Promise<Result<VersionOutput>> {
    return publicApiObject.GET<VersionOutput>(routesBE.public.version, {}, { signal });
}

function exportOrders(query: ExportOrdersQuery): Promise<Result<Blob>> {
    const serialized = superjson.serialize(query) as { json: unknown, meta: unknown };
    return privateApiObject.POST<Blob>(routesBE.private.ordersExport, serialized, { responseType: 'blob' });
}

function exportClients(query: ExportClientsQuery): Promise<Result<Blob>> {
    const serialized = superjson.serialize(query) as { json: unknown, meta: unknown };
    return privateApiObject.POST<Blob>(routesBE.private.clientsExport, serialized, { responseType: 'blob' });
}

function getInvoicePreview(query: OrderInit, signal?: AbortSignal): Promise<Result<Blob>> {
    return privateApiObject.POST<Blob>(routesBE.private.invoicePreview, query, { responseType: 'blob', signal });
}

function getInvoicingProfileExample(id: string, signal?: AbortSignal): Promise<Result<Blob>> {
    return privateApiObject.GET<Blob>(routesBE.private.invoicingProfileExample.resolve({ id }), {}, { responseType: 'blob', signal });
}

function referralVisited(storeSlug: string): Promise<Result> {
    return publicApiObject.POST(routesBE.public.visit, { domainSlug: storeSlug, isReferral: true } satisfies VisitInitInput);
}

function uploadFile(file: File): Promise<Result<FileOutput>> {
    const formData = new FormData();
    formData.append('file', file);

    return privateApiObject.POST<FileOutput>(routesBE.private.uploadFile, formData);
}

export const backendApi = {
    authorizer,
    googleRegistration,
    googleLogin,
    googleIntegration,
    getVersion,
    exportOrders,
    exportClients,
    getInvoicePreview,
    getInvoicingProfileExample,
    referralVisited,
    uploadFile,
};
