import { useState } from 'react';
import { Button, Modal, SpinnerButton } from ':components/shadcn';
import { Link } from 'react-router-dom';
import { SubscriptionErrorType, type SubscriptionError } from ':frontend/types/Subscription';
import { useTranslation } from 'react-i18next';
import { emptyFunction } from ':frontend/utils/common';
import { routesFE } from ':utils/routes';
import { useCached } from ':components/hooks';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import { TriangleWarningIconScaling } from ':components/icons/basic';

type SubscriptionEndedErrorModalProps = Readonly<{
    error: SubscriptionError | undefined;
}>;

export function SubscriptionEndedErrorModal({ error }: SubscriptionEndedErrorModalProps) {
    return (
        <CommonModal error={error} onHide={emptyFunction} />
    );
}

type CommonModalProps = Readonly<{
    error: SubscriptionError | undefined;
    onHide: () => void;
}>;

function CommonModal({ error, onHide }: CommonModalProps) {
    const cachedError = useCached(error);
    if (!cachedError)
        return null;

    return (
        <Modal.Root open={!!error}>
            <Modal.Content closeButton={null} className='items-center gap-6'>
                <ModalBody error={cachedError} onHide={onHide} />
            </Modal.Content>
        </Modal.Root>
    );
}

function ModalBody({ error, onHide }: CommonModalProps) {
    const isMasterOrFreelancer = !!toMaster(useUser());
    const { t } = useTranslation('common', { keyPrefix: isMasterOrFreelancer ? error?.type : 'subscription.schedulerError' });
    const [ isFetching, setIsFetching ] = useState(false);


    async function openSubscriptionSession() {
        setIsFetching(true);
        // TODO subscription error modal correct URL (stigg checkout)
    }

    return (<>
        <Modal.Header className='text-center'>
            <TriangleWarningIconScaling size={60} className='mx-auto text-warning' />

            <Modal.Title className='mt-3'>{t('title')}</Modal.Title>

            <Modal.Description className='mt-1 leading-5 whitespace-pre-wrap'>
                {t('description')}
            </Modal.Description>
        </Modal.Header>

        {isMasterOrFreelancer && (
            <Modal.Footer>
                {error?.type === SubscriptionErrorType.PlanPending ? (
                    <SpinnerButton isFetching={isFetching} onClick={openSubscriptionSession}>
                        {t('check-button')}
                    </SpinnerButton>
                ) : error?.type === SubscriptionErrorType.PlanEnded ? (
                    <Link to={routesFE.settings.resolve({ key: 'subscription' })} className='float-left'>
                        <Button>{t('new-plan-button')}</Button>
                    </Link>
                ) : (
                    <Button onClick={onHide}>
                        {t('hide-button')}
                    </Button>
                )}
            </Modal.Footer>
        )}
    </>);
}
