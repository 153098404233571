import type { Config } from 'tailwindcss';
import plugin from 'tailwindcss/plugin';
import tailwindcssAnimate from 'tailwindcss-animate';

export default {
    darkMode: [ 'class' ],
    // This will be evaluated from the app that imports this file. So the './src' will be the app's src folder.
    content: [
        '../../packages/components/src/**/*.{js,ts,jsx,tsx}',
        './src/**/*.{js,ts,jsx,tsx}',
    ],
    theme: {
        fontSize: {
            xs: '0.625rem',     // 10px
            sm: '0.75rem',      // 12px (Extra small for paragraphs)
            base: '0.875rem',   // 14px (Small for paragraphs)
            lg: '1rem',         // 16px (Base for paragraphs)
            '2lg': '1.125rem',  // 18px (Large for paragraphs)
            xl: '1.25rem',      // 20px
            '2xl': '1.5rem',    // 24px
            '3xl': '1.75rem',   // 28px
            '4xl': '2rem',      // 32px
            '5xl': '2.25rem',   // 36px
            '6xl': '2.5rem',    // 40px
        },
        lineHeight: {
            0: '0px',           //      (This one is completely new)
            3: '0.875rem',      // 14px (All except this one are same as the default)
            4: '1rem',          // 16px
            5: '1.25rem',       // 20px
            6: '1.5rem',        // 24px
            7: '1.75rem',       // 28px
            8: '2rem',          // 32px
            9: '2.25rem',       // 36px
            10: '2.5rem',       // 40px
            'none': '1',
            'normal': '1.5',
        },
        borderRadius: {
            DEFAULT: '0.5rem',  // 8px
            'none': '0',
            '2xs': '0.125rem',  // 2px
            xs: '0.25rem',      // 4px
            sm: '0.5rem',       // 8px
            md: '0.75rem',      // 12px
            lg: '1rem',         // 16px
            xl: '1.25rem',      // 20px
            '2xl': '1.5rem',    // 24px
            '3xl': '1.75rem',   // 32px
            '4xl': '2rem',      // 40px
            'full': '9999px',
        },
        colors: {
            primary: {
                DEFAULT: '#550ce6',
                50: '#f1ebff',
                100: '#e5d9fd',
                200: '#cbb3fb',
                300: '#b18cf9',
                400: '#9766f7',
                500: '#550ce6',
                600: '#4009ac',
                700: '#350890',
                800: '#200456',
                900: '#0b011d',
            },
            secondary: {
                DEFAULT: '#5f5572',
                50: '#f4f2f7',
                100: '#e7e4eb', // Default background muted color.
                200: '#cecad7', // Default border color.
                300: '#b6afc3',
                400: '#9d94af',
                500: '#5f5572', // Default text color.
                600: '#474056',
                700: '#2c2534',
                800: '#18151c',
                900: '#040304',
            },
            success: {
                DEFAULT: '#10b981',
                50: '#ecfdf5',
                100: '#d1fae5',
                200: '#a7f3d0',
                300: '#6ee7b7',
                400: '#34d399',
                500: '#10b981',
                600: '#065f46',
                700: '#064e3b',
                800: '#059669',
                900: '#047857',
            },
            warning: {
                DEFAULT: '#f59e0b',
                50: '#fffbeb',
                100: '#fef3c7',
                200: '#fde68a',
                300: '#fcd34d',
                400: '#fbbf24',
                500: '#f59e0b',
                600: '#d97706',
                700: '#b45309',
                800: '#92400e',
                900: '#78350f',
            },
            danger: {
                DEFAULT: '#ef4444',
                50: '#fef2f2',
                100: '#fee2e2',
                200: '#fecaca',
                300: '#fca5a5',
                400: '#f87171',
                500: '#ef4444',
                600: '#dc2626',
                700: '#b91c1c',
                800: '#991b1b',
                900: '#7f1d1d',
            },
            white: '#ffffff',
            black: '#000000',
            body: '#5f5572',    // Default text color.
            info: '#cff4fc',    // Needed e.g. for client states. Should be removed eventually.
            transparent: 'transparent',
            current: 'currentColor',
        },
        extend: {
            spacing: {
                // Special values for paddings that include border of 1px.
                '1b': 'calc(0.25rem - 1px)',
                '2b': 'calc(0.5rem - 1px)',
                '3b': 'calc(0.75rem - 1px)',
                '4b': 'calc(1rem - 1px)',
                '5b': 'calc(1.25rem - 1px)',
                '6b': 'calc(1.5rem - 1px)',
                // Jachym: Why this isn't in the default theme?
                // Marian: Because these are super unround numbers; 48, 56, and 64 are way nicer.
                // Jachym: Tell that to our designers :P
                13: '3.25rem',  // 52px
                15: '3.75rem',  // 60px
                17: '4.25rem',  // 68px
                18: '4.5rem',  // 72px
                '1/8': '12.5%',
                '3/8': '37.5%',
                '5/8': '62.5%',
                '7/8': '87.5%',
            },
            borderColor: {
                DEFAULT: '#cecad7', // secondary-200
            },
            boxShadow: {
                'store-soft': '0px 15px 35px 0px rgba(0, 0, 0, 0.1)',
                'store-hard': '6px 6px 0px 0px rgba(0, 0, 0, 0.70)',
            },
            // TODO The following stuff is from shadcn ... it should be removed eventually.
            keyframes: {
                'accordion-down': {
                    from: { height: '0' },
                    to: { height: 'var(--radix-accordion-content-height)' },
                },
                'accordion-up': {
                    from: { height: 'var(--radix-accordion-content-height)' },
                    to: { height: '0' },
                },
                'skeleton': {
                    '100%': {
                        transform: 'translateX(100%)',
                    },
                },
                'pill': {
                    '0%, 100%': {
                        translate: 'translateY(-20%)',
                        'animation-timing-function': 'cubic-bezier(.42, 0, .58, 1)',
                    },
                    '50%': {
                        transform: 'translateY(20%)',
                        'animation-timing-function': 'cubic-bezier(.42, 0, .58, 1)',
                    },
                },
            },
            animation: {
                'accordion-down': 'accordion-down 0.2s ease-out',
                'accordion-up': 'accordion-up 0.2s ease-out',
                'skeleton': 'skeleton 1.5s ease-in-out infinite',
                'pill': 'pill 0.2s infinite',
            },
        },
    },
    plugins: [
        tailwindcssAnimate,
        plugin(({ addUtilities, addVariant }) => {
            // use draggable={false} instead as user-drag is not standardized and supported
            addUtilities({
                '.drag-none': {
                    '-webkit-user-drag': 'none',
                    '-khtml-user-drag': 'none',
                    '-moz-user-drag': 'none',
                    '-o-user-drag': 'none',
                    'user-drag': 'none',
                },
            });

            addVariant('hover-partial', '&:hover:not(:has(.block-hover:hover))');

            addVariant('phone', '.fl-phone-preview &');
        }),
    ],
} satisfies Config;
