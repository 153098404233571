import { cloneElement, Component, type ContextType, type MouseEvent, type ReactElement, type ReactNode } from 'react';
import clsx from 'clsx';
import { ActionDirection, dndContext } from './dndContext';
import { isGoogleEvent, type CalendarEvent } from ':frontend/types/calendar/Calendar';
import { cn } from ':components/shadcn/utils';
import { classSelectors } from '../utils/common';

const draftEvent = null; // TODO draft event - use context

function isChangeable(event: CalendarEvent) {
    if (isGoogleEvent(event))
        return false;

    if (!draftEvent)
        return true;

    return event === draftEvent;
}

function isDraggableFunction(event: CalendarEvent) {
    return isChangeable(event);
}

function isResizableFunction(event: CalendarEvent) {
    return isChangeable(event);
}

type EventWrapperProps = {
    event: CalendarEvent;
    isPreview?: boolean;
    draggable?: boolean;
    isAllDay?: boolean;
    isRow?: boolean;
    isDragging?: boolean;
    isResizing?: boolean;
    children: ReactElement;
} & ({
    type: 'time';
} | {
    type: 'date';
    continuesPrior: boolean;
    continuesAfter: boolean;
});

export class EventWrapper extends Component<EventWrapperProps> {
    static contextType = dndContext;
    declare context: ContextType<typeof dndContext>;

    handleResize(e: MouseEvent<HTMLDivElement>, direction: ActionDirection) {
        if (e.button === 0)
            this.context.draggable.onBeginAction(this.props.event, 'resize', direction);
    }

    handleStartDragging = (e: MouseEvent<HTMLDivElement>) => {
        if (e.button !== 0)
            return;

        // hack: because of the way the anchors are arranged in the DOM, resize
        // anchor events will bubble up to the move anchor listener. Don't start
        // move operations when we're on a resize anchor.
        const isResizeHandle = (e.target as HTMLDivElement)
            .getAttribute('class')
            ?.includes('fl-resize-anchor');
        if (!isResizeHandle)
            this.context.draggable.onBeginAction(this.props.event, 'move');
    };

    renderAnchor(direction: ActionDirection) {
        const isUpDown = direction === ActionDirection.Up || direction === ActionDirection.Down;

        return (
            <div
                className={clsx('fl-resize-anchor absolute', isUpDown ? 'h-2 left-0 right-0 first:top-0 last:bottom-0 cursor-ns-resize' : 'w-2 top-0 bottom-0 first:left-0 last:right-0 cursor-ew-resize')}
                onMouseDown={e => this.handleResize(e, direction)}
            />
        );
    }

    render() {
        const { event, isPreview, type, children } = this.props;

        if (isPreview) {
            return cloneElement(children, {
                className: cn(children.props.className, classSelectors.dragPreview.class, 'cursor-move shadow-lg outline-0'),
            });
        }

        const { draggable } = this.context;
        const isDraggable = isDraggableFunction(event);

        /* Event is not draggable, no need to wrap it */
        if (!isDraggable)
            return children;

        /*
        * The resizability of events depends on whether they are
        * allDay events and how they are displayed.
        *
        * 1. If the event is being shown in an event row (because
        * it is an allDay event shown in the header row or because as
        * in month view the view is showing all events as rows) then we
        * allow east-west resizing.
        *
        * 2. Otherwise the event is being displayed
        * normally, we can drag it north-south to resize the times.
        *
        * See `DropWrappers` for handling of the drop of such events.
        *
        * Notwithstanding the above, we never show drag anchors for
        * events which continue beyond current component. This happens
        * in the middle of events when showMultiDay is true, and to
        * events at the edges of the calendar's min/max location.
        */
        const isResizable = isResizableFunction(event);

        if (isResizable || isDraggable) {
            /*
            * props.children is the singular <Event> component.
            * BigCalendar positions the Event abolutely and we
            * need the anchors to be part of that positioning.
            * So we insert the anchors inside the Event's children
            * rather than wrap the Event here as the latter approach
            * would lose the positioning.
            */
            const newProps: {
                onMouseDown: (e: MouseEvent<HTMLDivElement>) => void;
                onTouchStart: (e: MouseEvent<HTMLDivElement>) => void;
                className?: string;
                children?: ReactNode;
            } = {
                onMouseDown: this.handleStartDragging,
                onTouchStart: this.handleStartDragging,
            };

            if (isResizable) {
                // replace original event child with anchor-embellished child
                let StartAnchor = null;
                let EndAnchor = null;

                if (type === 'date') {
                    const { continuesPrior, continuesAfter } = this.props;
                    StartAnchor = !continuesPrior && this.renderAnchor(ActionDirection.Left);
                    EndAnchor = !continuesAfter && this.renderAnchor(ActionDirection.Right);
                }
                else {
                    StartAnchor = this.renderAnchor(ActionDirection.Up);
                    EndAnchor = this.renderAnchor(ActionDirection.Down);
                }

                newProps.children = (<>
                    {StartAnchor}
                    {children.props.children}
                    {EndAnchor}
                </>);
            }

            if (
                draggable.dragAndDropAction.interacting // if an event is being dragged right now
            )
                // add a new class to it
                newProps.className = clsx(children.props.className, draggable.dragAndDropAction.event === event ? 'opacity-0' : 'opacity-50');

            return cloneElement(children, newProps);
        }

        return children;
    }
}
