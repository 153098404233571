import { useMaster } from ':frontend/context/UserProvider';
import { useTranslation } from 'react-i18next';
import { Button, Card, Form, Modal, SpinnerButton } from ':components/shadcn';
import { useToggle, useUpdating } from ':frontend/hooks';
import { trpc } from ':frontend/context/TrpcProvider';
import { useCallback } from 'react';
import type { IconType } from ':components/icons/common';
import { BankIcon, LicenseIcon } from ':components/icons/basic';
import { FIRST_PLAN_ID, StiggFeature } from ':utils/lib/stigg';
import { CheckoutModal } from './Subscriptions';
import { useEntitlement } from ':frontend/lib/stigg';

export function ModulesSettings() {
    return (
        <div className='p-4 py-8 md:py-12 space-y-4 md:space-y-8'>
            <ModuleCard type='invoicing' Icon={LicenseIcon} />
            <ModuleCard type='taxes' Icon={BankIcon} />
        </div>
    );
}

const typeToFlag = {
    'invoicing': 'isInvoicingModuleOn',
    'taxes': 'isTaxesModuleOn',
} as const;

type ModuleCardProps = Readonly<{
    type: keyof typeof typeToFlag;
    Icon: IconType;
}>;

function ModuleCard({ type, Icon }: ModuleCardProps) {
    const { t } = useTranslation('pages', { keyPrefix: `settings.modules.${type}` });
    const { t: tm } = useTranslation('pages', { keyPrefix: `settings.modules.${type}.disableModal` });
    const flag = typeToFlag[type];
    const { teamSettings, setTeamSettings } = useMaster();
    const [ showModal, setShowModal ] = useToggle(false);
    const [ showUpsellModal, setShowUpsellModal ] = useToggle(false);
    const isInvoicingEnabled = useEntitlement(StiggFeature.Invoicing);

    const updateFlagMutation = trpc.team.updateTeamFlag.useMutation();

    const syncState = useCallback(async (newValue: boolean) => {
        try {
            const response = await updateFlagMutation.mutateAsync({ flag, value: newValue });
            setTeamSettings(response);
            return true;
        }
        catch {
            return false;
        }
    }, [ flag, updateFlagMutation, setTeamSettings ]);

    const [ state, updateState, isUpdatingState ] = useUpdating(teamSettings[flag], syncState);

    function onCheckedChange(newState: boolean) {
        if (type === 'taxes' && !newState)
            setShowModal.true();
        else if (type === 'invoicing' && !isInvoicingEnabled)
            setShowUpsellModal.true();
        else
            updateState(newState);
    }

    async function confirmModal() {
        const result = await syncState(false);
        if (result)
            setShowModal.false();
    }

    // If the invoicing module is off, the taxes can't be turned on.
    const isDisabled = (type === 'taxes' && !teamSettings.isInvoicingModuleOn)
        // If the taxes are on, the invoicing module can't be turned off.
        || (type === 'invoicing' && teamSettings.isTaxesModuleOn);

    return (
        <Card className='space-y-4'>
            <div className='flex flex-row items-center justify-between'>
                <div className='flex items-center justify-between gap-2'>
                    <div className='size-10 rounded-full flex items-center justify-center bg-secondary-100'>
                        <Icon size={20} />
                    </div>

                    <Card.Title className='capitalize'>{type}</Card.Title>
                </div>

                <Form.Switch
                    checked={state}
                    onCheckedChange={onCheckedChange}
                    disabled={isUpdatingState || isDisabled}
                />
            </div>

            <p className='mt-4 leading-5'>{t('description')}</p>

            {isDisabled && (
                <p className='text-secondary-300 mt-4'>{t('disabled-warning')}</p>
            )}

            {
                type === 'invoicing' && (
                    <CheckoutModal isOpen={showUpsellModal} onClose={setShowUpsellModal.false} plan={{ id: FIRST_PLAN_ID }}/>
                )
            }

            {type === 'taxes' && (
                <Modal.Root open={showModal} onOpenChange={setShowModal.false}>
                    <Modal.Content className='gap-6' closeButton={tm('cancel-button')}>
                        <Modal.Header className='space-y-6 text-center'>
                            <Modal.Title className='text-3xl leading-9 font-semibold text-secondary-900'>
                                {tm('title')}
                            </Modal.Title>

                            <Modal.Description>{tm('description')}</Modal.Description>
                        </Modal.Header>

                        <Modal.Footer className='grid grid-cols-2 gap-4'>
                            <Button variant='secondary' onClick={setShowModal.false}>
                                {tm('cancel-button')}
                            </Button>

                            <SpinnerButton variant='danger' onClick={confirmModal} isFetching={updateFlagMutation.isPending}>
                                {tm('confirm-button')}
                            </SpinnerButton>
                        </Modal.Footer>
                    </Modal.Content>
                </Modal.Root>
            )}
        </Card>
    );
}
