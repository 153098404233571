import { useCallback, useEffect, useRef } from 'react';
import { DayColumn, type Selectable } from './DayColumn';
import { TimeGutter } from './TimeGutter';
import { TimeGridHeader } from './TimeGridHeader';
import { type SlotInfo } from '.';
import { classSelectors, localizer, showMultiDayTimes } from './utils/common';
import { getNow } from './localizer';
import { DateTime } from 'luxon';
import type { CalendarEvent } from ':frontend/types/calendar/Calendar';
import type { Selected } from ':frontend/pages/CalendarDetail';
import clsx from 'clsx';

type TimeGridProps = Readonly<{
    date: DateTime;
    events: CalendarEvent[];
    selected?: Selected;
    selectable?: Selectable;
    eventOffset: number;
    range: DateTime[];
    min: DateTime;
    max: DateTime;
    onSelectEvent: (event: CalendarEvent) => void;
    onSelectSlot: (slot: SlotInfo) => void;
}>;

const DEFAULT_SCROLL_HOUR = 7;

export function TimeGrid({ date, events, range, min, max, onSelectSlot, ...props }: TimeGridProps) {
    const scrollRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!contentRef.current)
            return;

        const scrollToTime = DateTime.now().set({ hour: DEFAULT_SCROLL_HOUR, minute: 0, second: 0 });
        const diffMillis = +scrollToTime - +localizer.startOf(scrollToTime, 'day');
        const totalMillis = localizer.diff(min, max, 'millisecond');
        const scrollRatio = diffMillis / totalMillis;
        contentRef.current.scrollTop = contentRef.current.scrollHeight * scrollRatio;
        // We want to do this only once.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
        if (scrollRef.current)
            scrollRef.current.scrollLeft = (e.target as unknown as { scrollLeft: number }).scrollLeft;
    }, []);

    const handleSelectAllDaySlot = useCallback((slots: DateTime[], slotInfo: SlotInfo) => {
        const start = slots[0];
        const end = slots[slots.length - 1].plus({ day: 1 });

        onSelectSlot({
            slots,
            start,
            end,
            action: slotInfo.action,
        });
    }, [ onSelectSlot ]);

    const start = range[0];
    const end = range[range.length - 1];
    const currentRange = { start, end };

    const allDayEvents: CalendarEvent[] = [];
    const rangeEvents: CalendarEvent[] = [];

    // TODO memoize?
    events.filter(event => localizer.inEventRange(event, currentRange)).forEach(event => {
        const eStart = event.start;
        const eEnd = event.end;

        if (
            event.isAllDay ||
                localizer.startAndEndAreDateOnly(eStart, eEnd) ||
                (showMultiDayTimes && !localizer.isSameDate(eStart, eEnd))
        )
            allDayEvents.push(event);
        else
            rangeEvents.push(event);
    });

    allDayEvents.sort(localizer.sortEvents);
    const now = getNow();

    return (
        <div className={clsx(classSelectors.timeView.class, 'w-full')}>
            <div className='sticky top-[--topbar-height] z-20'>
                <TimeGridHeader
                    scrollRef={scrollRef}
                    range={range}
                    date={date}
                    events={allDayEvents}
                    selected={props.selected}
                    selectable={props.selectable}
                    onSelectEvent={props.onSelectEvent}
                    onSelectSlot={handleSelectAllDaySlot}
                />
            </div>

            <div
                ref={contentRef}
                className='grow flex-1 flex items-start w-fit min-w-full'
                onScroll={handleScroll}
            >
                <TimeGutter
                    min={localizer.merge(start, min)}
                    max={localizer.merge(start, max)}
                />

                <div className='grow flex *:flex-1 *:min-w-32 divide-x *:border-secondary-100 bg-white'>
                    {range.map((dayDate, dayIndex) => {
                        const daysEvents = rangeEvents.filter(event => localizer.inRange(dayDate, event.start, event.end, 'day'));

                        return (
                            <DayColumn
                                {...props}
                                min={localizer.merge(dayDate, min)}
                                max={localizer.merge(dayDate, max)}
                                isNow={localizer.isSameDate(dayDate, now)}
                                key={dayIndex}
                                date={dayDate}
                                dayIndex={dayIndex}
                                events={daysEvents}
                                onSelectSlot={onSelectSlot}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
