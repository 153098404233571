import { Component, createRef } from 'react';
import { EventRow } from '../EventRow';
import { getBoundsForNode, Selection } from '../Selection';
import { eventSegments } from '../utils/eventLevels';
import { getSlotAtX, pointInBox } from '../utils/selection';
import { eventTimes } from './common';
import { ActionDirection, dndContext } from './dndContext';
import { classSelectors, localizer } from '../utils/common';

export class WeekWrapper extends Component {
    static contextType = dndContext;

    constructor(...args) {
        super(...args);
        this.state = {};
        this.ref = createRef();
    }

    componentDidMount() {
        this._selectable();
    }

    componentWillUnmount() {
        this._teardownSelectable();
    }

    reset() {
        if (this.state.segment)
            this.setState({ segment: null });
    }

    update(event, start, end) {
        const segment = eventSegments(
            { ...event, end, start, __isPreview: true },
            this.props.slotMetrics.range,
        );

        const { segment: lastSegment } = this.state;
        if (
            lastSegment &&
      segment.span === lastSegment.span &&
      segment.left === lastSegment.left &&
      segment.right === lastSegment.right
        )
            return;

        this.setState({ segment });
    }

    handleMove = (point, bounds, draggedEvent) => {
        if (!pointInBox(bounds, point))
            return this.reset();
        const event = this.context.draggable.dragAndDropAction.event || draggedEvent;
        const { slotMetrics } = this.props;

        const slot = getSlotAtX(bounds, point.x, slotMetrics.slots);

        const date = slotMetrics.getDateForSlot(slot);

        // Adjust the dates, but maintain the times when moving
        let { start, duration } = eventTimes(event);
        start = localizer.mergeUnsafe(date, start);
        const end = localizer.add(start, duration, 'milliseconds');
        // LATER: when dragging a multi-row event, only the first row is animating
        this.update(event, start, end);
    };

    handleDropFromOutside = (point, bounds) => {
        if (!this.context.draggable.onDropFromOutside)
            return;
        const { slotMetrics } = this.props;

        const slot = getSlotAtX(bounds, point.x, slotMetrics.slots);
        const start = slotMetrics.getDateForSlot(slot);

        this.context.draggable.onDropFromOutside({
            start,
            end: localizer.add(start, 1, 'day'),
            isAllDay: false,
        });
    };

    handleDragOverFromOutside = (point, node) => {
        if (!this.context.draggable.dragFromOutsideItem)
            return;
        this.handleMove(point, node, this.context.draggable.dragFromOutsideItem());
    };

    handleResize(point, bounds) {
        const { event, direction } = this.context.draggable.dragAndDropAction;
        const { slotMetrics } = this.props;

        let { start, end } = eventTimes(event);

        const slot = getSlotAtX(bounds, point.x, slotMetrics.slots);
        const date = slotMetrics.getDateForSlot(slot);
        const cursorInRow = pointInBox(bounds, point);

        if (direction === ActionDirection.Right) {
            if (cursorInRow) {
                if (slotMetrics.last < start)
                    return this.reset();
                if (localizer.eq(localizer.startOf(end, 'day'), end))
                    end = localizer.add(date, 1, 'day');
                else
                    end = date;
            }
            else if (
                localizer.inRange(start, slotMetrics.first, slotMetrics.last) || (bounds.bottom < point.y && +slotMetrics.first > +start)
            ) {
                end = localizer.add(slotMetrics.last, 1, 'milliseconds');
            }
            else {
                this.setState({ segment: null });
                return;
            }
            const originalEnd = event.end;
            end = localizer.mergeUnsafe(end, originalEnd);
            if (localizer.lt(end, start))
                end = originalEnd;

        }
        else if (direction === ActionDirection.Left) {
            if (cursorInRow) {
                if (slotMetrics.first > end)
                    return this.reset();
                start = date;
            }
            else if (
                localizer.inRange(end, slotMetrics.first, slotMetrics.last) || (bounds.top > point.y && localizer.lt(slotMetrics.last, end))
            ) {
                start = localizer.add(slotMetrics.first, -1, 'milliseconds');
            }
            else {
                this.reset();
                return;
            }
            const originalStart = event.start;
            start = localizer.mergeUnsafe(start, originalStart);
            if (localizer.gt(start, end))
                start = originalStart;

        }

        this.update(event, start, end);
    }

    _selectable = () => {
        let node = this.ref.current.closest(`${classSelectors.monthRow.selector}, ${classSelectors.alldayCell.selector}`);
        let container = node.closest(`${classSelectors.monthView.selector}, ${classSelectors.timeView.selector}`);
        let isMonthRow = node.classList.contains(classSelectors.monthRow.class);

        // Valid container check only necessary in TimeGrid views
        let selector = (this._selector = new Selection(() => container, {
            validContainers: [
                ...(!isMonthRow ? [ classSelectors.daySlot.selector, classSelectors.alldayCell.selector ] : []),
            ],
        }));

        selector.on('beforeSelect', point => {
            const { isAllDay } = this.props;
            const { action } = this.context.draggable.dragAndDropAction;
            const bounds = getBoundsForNode(node);
            const isInBox = pointInBox(bounds, point);
            return (
                action === 'move' || (action === 'resize' && (!isAllDay || isInBox))
            );
        });

        selector.on('selecting', box => {
            const bounds = getBoundsForNode(node);
            const { dragAndDropAction } = this.context.draggable;
            if (dragAndDropAction.action === 'move')
                this.handleMove(box, bounds);
            if (dragAndDropAction.action === 'resize')
                this.handleResize(box, bounds);
        });

        selector.on('selectStart', () => this.context.draggable.onStart());

        selector.on('select', point => {
            const bounds = getBoundsForNode(node);
            if (!this.state.segment)
                return;
            if (!pointInBox(bounds, point))
                this.reset();
            else
                this.handleInteractionEnd();

        });

        selector.on('dropFromOutside', point => {
            if (!this.context.draggable.onDropFromOutside)
                return;
            const bounds = getBoundsForNode(node);
            if (!pointInBox(bounds, point))
                return;
            this.handleDropFromOutside(point, bounds);
        });

        selector.on('dragOverFromOutside', point => {
            if (!this.context.draggable.dragFromOutsideItem)
                return;
            const bounds = getBoundsForNode(node);

            this.handleDragOverFromOutside(point, bounds);
        });

        selector.on('click', () => this.context.draggable.onEnd(null));

        selector.on('reset', () => {
            this.reset();
            this.context.draggable.onEnd(null);
        });
    };

    handleInteractionEnd = () => {
        const { isAllDay } = this.props;
        const { event } = this.state.segment;

        this.reset();

        this.context.draggable.onEnd({
            start: event.start,
            end: event.end,
            isAllDay,
        });
    };

    _teardownSelectable = () => {
        if (!this._selector)
            return;
        this._selector.teardown();
        this._selector = null;
    };

    render() {
        const { children } = this.props;
        const { segment } = this.state;

        return (
            <div ref={this.ref} className='relative'>
                {children}

                {segment && (
                    <EventRow
                        {...this.props}
                        selected={null}
                        className='absolute top-0 left-0 right-0'
                        segments={[ segment ]}
                    />
                )}
            </div>
        );
    }
}
