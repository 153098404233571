import { Component, createRef } from 'react';
import clsx from 'clsx';
import getHeight from 'dom-helpers/height';
import qsa from 'dom-helpers/querySelectorAll';
import { BackgroundCells } from './BackgroundCells';
import { EventRow } from './EventRow';
import { EventEndingRow } from './EventEndingRow';
import { WeekWrapper } from './dragAndDrop/WeekWrapper';
import { getSlotMetrics } from './utils/DateSlotMetrics';
import { getNow } from './localizer';
import { classSelectors, localizer } from './utils/common';

export class DateContentRow extends Component {
    constructor(...args) {
        super(...args);

        this.containerRef = createRef();
        this.headingRowRef = createRef();
        this.eventRowRef = createRef();

        this.slotMetrics = getSlotMetrics();
    }

    handleSelectSlot = slot => {
        const { range, onSelectSlot } = this.props;

        onSelectSlot(range.slice(slot.start, slot.end + 1), slot);
    };

    handleShowMore = (slot, target) => {
        const { range, onShowMore } = this.props;
        let metrics = this.slotMetrics(this.props);
        let row = qsa(this.containerRef.current, classSelectors.rowBg.selector)[0];

        let cell;
        if (row)
            cell = row.children[slot - 1];

        let events = metrics.getEventsForSlot(slot);
        onShowMore(events, range[slot - 1], cell, slot, target);
    };

    getContainer = () => {
        const { container } = this.props;
        return container ? container() : this.containerRef.current;
    };

    getRowLimit() {
    /* Guessing this only gets called on the dummyRow */
        const eventHeight = getHeight(this.eventRowRef.current);
        const headingHeight = this.headingRowRef?.current
            ? getHeight(this.headingRowRef.current)
            : 0;
        const eventSpace = getHeight(this.containerRef.current) - headingHeight;

        return Math.max(Math.floor(eventSpace / eventHeight), 1);
    }

    renderHeadingCell = (date, index) => {
        let { renderHeader } = this.props;

        return renderHeader({
            date,
            key: `header_${index}`,
            className: clsx('rbc-date-cell', localizer.isSameDate(date, getNow()) && 'rbc-now'),
        });
    };

    renderDummy = () => {
        let { className, range, renderHeader } = this.props;
        return (
            <div className={className} ref={this.containerRef}>
                <div className='relative select-none'>
                    {renderHeader && (
                        <div className='flex' ref={this.headingRowRef}>
                            {range.map(this.renderHeadingCell)}
                        </div>
                    )}
                    <div className='flex' ref={this.eventRowRef}>
                        <div className='pr-3 py-px'>
                            <div className={classSelectors.event.class}>
                                <div className='shrink w-full min-h-5'>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { date, range, className, selected, selectable, renderForMeasure, renderHeader, onSelect, onDoubleClick, onKeyPress, isAllDay } = this.props;

        if (renderForMeasure)
            return this.renderDummy();

        let metrics = this.slotMetrics(this.props);
        let { levels, extra } = metrics;

        const eventRowProps = {
            selected,
            onSelect,
            onDoubleClick,
            onKeyPress,
            slotMetrics: metrics,
        };

        return (
            <div className={className} ref={this.containerRef}>
                <BackgroundCells
                    date={date}
                    range={range}
                    selectable={selectable}
                    container={this.getContainer}
                    onSelectSlot={this.handleSelectSlot}
                />
                <div className='relative select-none'>
                    {renderHeader && (
                        <div className='flex' ref={this.headingRowRef}>
                            {range.map(this.renderHeadingCell)}
                        </div>
                    )}
                    <WeekWrapper isAllDay={isAllDay} {...eventRowProps}>
                        {levels.map((segs, index) => (
                            <EventRow key={index} segments={segs} {...eventRowProps} />
                        ))}
                        {!!extra.length && (
                            <EventEndingRow
                                segments={extra}
                                onShowMore={this.handleShowMore}
                                {...eventRowProps}
                            />
                        )}
                    </WeekWrapper>
                </div>
            </div>
        );
    }
}

DateContentRow.defaultProps = {
    minRows: 0,
    maxRows: Infinity,
};
