import { forwardRef, useCallback, useMemo, useState, type ComponentPropsWithoutRef } from 'react';
import { type ClientInfoFE } from ':frontend/types/Client';
import { useTranslation } from 'react-i18next';
import { type UseEventFormDispatch, type UseEventFormState } from './useEvent';
import { Button, Form } from ':components/shadcn';
import { ContinuousParticipantSelect, ParticipantOval } from '../client/ContinuousParticipantSelect';
import { DatePicker, RecurrenceInput } from '../forms';
import { DateTime } from 'luxon';
import { minutesToSeconds } from ':utils/dateTime';
import type { TFunction } from 'i18next';
import { ErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { LocationSelect } from '../location/LocationSelect';
import { useToggle } from ':frontend/hooks';
import type { Participant } from ':frontend/types/EventParticipant';
import { TeamMemberSelect } from '../team/TeamMemberSelect';
import { useUser } from ':frontend/context/UserProvider';
import { TeamMemberRole } from ':utils/entity/team';
import { computeRecurrenceCount, type Recurrence } from ':utils/recurrence';
import { computeEventDuration } from ':utils/entity/event';
import { CirclePlusIcon, ChevronDownIcon, TextAlignLeft2Icon } from ':components/icons/basic';
import { cn } from ':components/shadcn/utils';

type EventFormProps = Readonly<{
    state: UseEventFormState;
    dispatch: UseEventFormDispatch;
    clients: ClientInfoFE[];
    isForProduct?: boolean;
    recurrenceCountLimit?: number;
}>;

export function EventForm({ state, dispatch, clients, isForProduct, recurrenceCountLimit }: EventFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventForm' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });

    const isDescriptionInitiallyVisible = !!state.event?.description;
    const [ isDescriptionVisible, setIsDescriptionVisible ] = useState(isDescriptionInitiallyVisible);
    const [ isDescriptionFocus, setIsDescriptionFocus ] = useToggle(false);

    const { role } = useUser();
    const isTeamMaster = role === TeamMemberRole.master;

    const isNew = !state.event;
    // Just an optimization, probably not necessary. Might be handled automatically in the new React compiler.
    const itemDispatch = useMemo(() => createItemDispatch(dispatch), [ dispatch ]);
    const error = validateEvent(state.form, tf);

    // Not the ideal solution, but it would be highly impractical to modify the continuous client select just for this.
    const guests = state.form.guests;
    const removeGuest = useCallback((participant: Participant) => {
        dispatch({ type: 'input', field: 'guests', value: guests.filter(p => p !== participant) });
    }, [ guests, dispatch ]);

    const { recurrence, start } = state.form;
    const eventsCount: number | undefined = useMemo(() => {
        if (recurrenceCountLimit === undefined)
            return undefined;

        const unlimitedCount = recurrence ? computeRecurrenceCount(recurrence, start) : 1;

        return Math.min(unlimitedCount, recurrenceCountLimit);
    }, [ recurrence, start, recurrenceCountLimit ]);

    return (
        <div className='space-y-4'>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                <div>
                    <DatePicker
                        autoFocus={isForProduct}
                        selected={state.form.start}
                        onChange={itemDispatch.start}
                        type='all'
                        customInput={<DatePickerInput label={t('start-label')} />}
                        wrapperClassName='w-full'
                    />
                </div>

                <div>
                    <DatePicker
                        selected={state.form.end}
                        onChange={itemDispatch.end}
                        type='all'
                        customInput={<DatePickerInput label={t('end-label')} />}
                        wrapperClassName='w-full'
                    />
                </div>
            </div>

            <div>
                <div>
                    <Form.Input
                        autoFocus={!isForProduct}
                        label={t('title-label')}
                        placeholder={t('title-placeholder')}
                        value={state.form.title}
                        onChange={e => dispatch({ type: 'input', field: 'title', value: e.target.value })}
                    />
                    {state.error?.invalidForm === 'title' && (
                        <ErrorMessage message={tf('title-required')} />
                    )}
                </div>

                {!isForProduct && (
                    isDescriptionVisible ? (
                        <div className='mt-4'>
                            <Form.Textarea
                                autoFocus={!isDescriptionInitiallyVisible}
                                label={t('description-label')}
                                placeholder={t('description-placeholder')}
                                value={state.form.description}
                                onChange={e => dispatch({ type: 'input', field: 'description', value: e.target.value })}
                                onFocus={setIsDescriptionFocus.true}
                                onBlur={setIsDescriptionFocus.false}
                                minRows={1}
                                maxRows={isDescriptionFocus ? undefined : 3}
                            />
                            {/* <RHFErrorMessage errors={errors} name='description' /> */}
                        </div>
                    ) : (
                        <Button variant='transparent' size='exact' className='mt-1 h-7 text-primary text-sm [&_svg]:size-3' onClick={() => setIsDescriptionVisible(true)}>
                            <TextAlignLeft2Icon />
                            {t('show-description-button')}
                        </Button>
                    )
                )}
            </div>

            <div>
                <Form.Label>{t('guests-label')}</Form.Label>
                {guests.length > 0 && (
                    <div className='mb-1 flex gap-1 flex-wrap'>
                        {guests.map(participant => (
                            <ParticipantOval key={participant.identifier} participant={participant} onRemove={removeGuest} />
                        ))}
                    </div>
                )}

                <div>
                    <GuestSelect state={state} dispatch={dispatch} clients={clients} />
                </div>
            </div>


            {isNew && (
                <div >
                    <Form.Label>{t('recurrence-label')}</Form.Label>

                    <RecurrenceInput
                        startDate={state.form.start}
                        value={state.form.recurrence}
                        onChange={itemDispatch.recurrence}
                        countLimit={2}
                    />

                    {eventsCount !== undefined && recurrence && (
                        <div className='px-2 py-1 text-sm text-secondary-600'>{t('events-count-label', { count: eventsCount })}</div>
                    )}
                </div>
            )}

            <div className='!mt-2'>
                <Form.Label>{t('location-label')}</Form.Label>

                <LocationSelect
                    immutableProps={{ variant: 'outline', removeIndicator: false }}
                    value={state.form.locationId}
                    onChange={value => dispatch({ type: 'input', field: 'locationId', value })}
                />
            </div>

            {error && <ErrorMessage message={error} />}

            {isNew && isTeamMaster && (
                <div>
                    <Form.Label>{t('scheduler-label')}</Form.Label>

                    <TeamMemberSelect
                        value={state.form.scheduler}
                        onChange={value => dispatch({ type: 'input', field: 'scheduler', value })}
                    />
                </div>
            )}
        </div>
    );
}

function createItemDispatch(dispatch: UseEventFormDispatch) {
    return {
        start: (value?: DateTime) => {
            if (!value)
                return;

            dispatch({ type: 'input', field: 'start', value });
        },
        end: (value?: DateTime) => {
            if (!value)
                return;

            dispatch({ type: 'input', field: 'end', value });
        },
        recurrence: (value?: Recurrence) => dispatch({ type: 'input', field: 'recurrence', value }),
    };
}

function validateEvent(event: { start: DateTime, end: DateTime }, tf: TFunction) {
    if (computeEventDuration(event) < minutesToSeconds(5))
        return tf('duration-less-than-5');

    return undefined;
}

type GuestSelectProps = Readonly<{
    state: UseEventFormState;
    dispatch: UseEventFormDispatch;
    clients: ClientInfoFE[];
}>;

function GuestSelect({ state, dispatch, clients }: GuestSelectProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventForm' });
    const [ isShow, setIsShow ] = useState(false);
    const onChange = useCallback((value: Participant[]) => {
        dispatch({ type: 'input', field: 'guests', value });
        setIsShow(false);
    }, [ dispatch ]);

    if (!isShow) {
        return (
            <Button variant='outline' className='px-3 text-base' onClick={() => setIsShow(true)}>
                <CirclePlusIcon />{t('add-guest-button')}
            </Button>
        );
    }

    return (
        <ContinuousParticipantSelect
            immutableProps={{ size: 'compact' }}
            clients={clients}
            value={state.form.guests}
            onChange={onChange}
            placeholder={t('guests-placeholder')}
            hideValue
            menuIsOpen
            autoFocus
            onBlur={() => setIsShow(false)}
        />
    );
}

type DatePickerInputProps = ComponentPropsWithoutRef<'button'> & {
    value?: string;
    className?: string;
    label: string;
};

const DatePickerInput = forwardRef<HTMLButtonElement, DatePickerInputProps>(({ value, label, className, ...rest }, ref) => {
    if (typeof value !== 'string')
        return;

    // i don't really understand how this works
    // i think the DatePicker should either provide a DateTime or a correctly formatted ISO string,
    // either in UTC or in the user's (input) timezone
    const dateTime = DateTime.fromISO(value, { setZone: true });

    return (
        <button
            ref={ref}
            type='button'
            className={cn(className, 'flex justify-between items-center gap-3 p-4 rounded-xl border border-secondary-100 bg-secondary-50 text-start text-secondary-700 w-full')}
            {...rest}
        >
            <div>
                <label className='opacity-75'>{label}</label>
                <p className='font-semibold text-2lg leading-normal'>{dateTime.toLocaleString(DateTime.TIME_SIMPLE)}</p>
                <p>{dateTime.toLocaleString(DateTime.DATE_MED)}</p>
            </div>
            <ChevronDownIcon size={14} />
        </button>
    );
});
