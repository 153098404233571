import { flowlanceIcons } from ':components/icons/logos';
import { Button, Form } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routeToDisplayString, routesFE, routesStore } from ':utils/routes';
import { PillsSetSort } from '../../components/auth/PillsSet';
import { createSlug, isStoreSlugValid, STORE_SLUG_MIN_LENGTH } from ':utils/common';
import { trpc } from ':frontend/context/TrpcProvider';
import { useCachedWithTimeout } from ':components/hooks';
import { usePostHog } from 'posthog-js/react';
import { env } from ':env';
import { zPromoCode } from ':utils/entity/team';
import { useLocationState } from ':frontend/hooks';
import { PROMO_CODE_KEY, STORE_SLUG_KEY } from './Register';

// Although this is probably the first page that the user will see, we don't handle referrals here.
// We do it globally in the App component to make sure they work on every page.

export const SLUG_QUERY_PARAM = 'username';

export default function ClaimUrl() {
    const { t } = useTranslation('pages', { keyPrefix: 'claimUrl' });

    const [ searchParams ] = useSearchParams();
    const initialSlug = searchParams.get(SLUG_QUERY_PARAM);
    const [ input, setInput ] = useState(initialSlug ?? '');
    const slug = createSlug(input);
    const validateStoreQuery = trpc.store.validateStoreSlug.useQuery(slug, { enabled: isStoreSlugValid(slug) });
    // Wait 100 ms before showing the new result to prevent flickering (the query data is undefined when fetching).
    // The value should be as small as possible, but still larger than the time it takes to fetch the new result.
    const validation = useCachedWithTimeout(validateStoreQuery.data, 100);
    const [ isShortError, setIsShortError ] = useState(false);

    useEffect(() => {
        if (input.length === 0 || input.length >= STORE_SLUG_MIN_LENGTH) {
            setIsShortError(false);
            return;
        }

        // Wait 1000 ms so that the error isn't shown immediately when the user is typing.
        const timer = setTimeout(() => setIsShortError(!!input && input.length < STORE_SLUG_MIN_LENGTH), 1000);
        return () => clearTimeout(timer);
    }, [ input ]);

    function useSuggestedUrl() {
        if (validation?.type !== 'taken')
            return;

        setInput(validation.suggestion);
    }

    const posthog = usePostHog();
    const navigate = useNavigate();

    const promoCode = useLocationState(PROMO_CODE_KEY, zPromoCode);

    function claimUrl() {
        if (validation?.type !== 'available')
            return;

        posthog?.capture('UrlClaimed', { storeSlug: validation.slug });
        navigate(routesFE.auth.register.resolve({}), { state: {
            [STORE_SLUG_KEY]: validation.slug,
            [PROMO_CODE_KEY]: promoCode,
        } });
    }

    const placeholderUrl = routeToDisplayString(routesStore.store.absoluteResolve({ domain: env.VITE_DEFAULT_STORE_DOMAIN, slug: '' }));

    return (
        <div className='min-w-80 h-screen w-screen flex md:items-center md:justify-center overflow-x-hidden'>
            <div className='w-full flex flex-col z-20 md:space-x-20 md:flex-row md:max-w-5xl'>
                <div className='relative md:w-1/2 p-10 md:p-0 flex flex-col justify-center gap-8'>
                    {promoCode && (
                        <div className='md:absolute top-20 max-md:-mx-4 md:w-full px-4 py-2 rounded-md text-lg/6 border border-success-200 bg-success-100/85 text-success-900'>
                            {t('promo-code-success')}
                        </div>
                    )}

                    <div className='space-y-4'>
                        <flowlanceIcons.FlowlanceBanner />

                        <h1 className='text-secondary-800 text-2xl font-semibold leading-8 md:text-4xl md:leading-10'>{t('page-title')}</h1>

                        <div className='text-lg'>{t('sub-title')}</div>
                    </div>

                    <div className='space-y-4'>
                        <div>
                            <Form.Input
                                prefixNode={
                                    <div className='text-secondary-500 opacity-50 select-none'>
                                        {placeholderUrl}
                                    </div>
                                }
                                // The input has to have font size at least 16px, because iOS would zoom in on it otherwise. And that is a really unpleasant user experience. Thanks, Apple, again.
                                // https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
                                // Setting maximum-scale=1 would break the zooming functionality for other devices.
                                // We decided to use this settings for all inputs on unauthenticated pages.
                                className='items-baseline text-lg md:text-base'
                                name='url'
                                label={t('url-label')}
                                value={input}
                                onChange={e => setInput(e.target.value)}
                                isError={(slug.length < STORE_SLUG_MIN_LENGTH && isShortError) || validation?.type === 'taken'}
                                isValid={validation?.type === 'available'}
                                autoFocus
                                spellCheck={false}
                                onKeyUp={e => e.key === 'Enter' && claimUrl()}
                            />
                        </div>

                        <div className='h-10'>
                            {validation && (
                                validation.type === 'available' ? (
                                    <Button className='w-full' onClick={claimUrl}>
                                        {t('grab-my-link')}
                                    </Button>
                                ) : (
                                    <div className='flex items-start gap-1'>
                                        <div className='text-danger-600'>
                                            {t('already-taken')}
                                        </div>

                                        <Button variant='transparent' size='exact' className='text-danger-600 underline' onClick={useSuggestedUrl}>
                                            {validation.suggestion}
                                        </Button>
                                    </div>
                                )
                            )}
                            {slug && slug.length < STORE_SLUG_MIN_LENGTH && isShortError && (
                                <div className='text-danger-600'>
                                    {t('slug-too-short')}
                                </div>
                            )}
                        </div>

                        <div className='flex justify-end'>
                            <Button
                                variant='outline'
                                size='small'
                                className='bg-transparent'
                                onClick={() => navigate(routesFE.auth.login.path)}
                            >
                                {t('or-login')}
                            </Button>
                        </div>
                    </div>
                </div>

                <div className='flex justify-center order-1 h-[220px] py-4 relative md:order-2 md:h-auto md:w-1/2'>
                    <PillsSetSort isSorted={false} />
                    <img src='/static/images/login/login-store-mockup.svg' className='max-md:hidden h-fit w-[240px] md:w-[375px] drag-none' alt='Store Mockup' draggable={false} />
                </div>
            </div>

            <div className='max-md:hidden w-96 h-96 rounded-full bg-primary-500 blur-[150px] fixed -bottom-10 -right-10' />
        </div>
    );
}
