import { useTranslation } from 'react-i18next';
import type { ClientFE } from ':frontend/types/Client';
import { ClientInvoicingForm } from ':frontend/components/client/ClientInvoicingForm';
import { useCallback } from 'react';
import { useToggle } from ':frontend/hooks';
import { Button, SpinnerButton } from ':components/shadcn';
import { trpc } from ':frontend/context/TrpcProvider';
import type { OmitId } from ':utils/id';
import type { ClientUpdate } from ':utils/entity/client';
import useNotifications from ':frontend/context/NotificationProvider';
import { createErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';

type ClientInvoicingProps = {
    client: ClientFE;
    invalidateClient: () => Promise<void>;
    isDirty: boolean;
    setIsDirty: (isDirty: boolean) => void;
};

export default function ClientInvoicing({ client, invalidateClient, isDirty, setIsDirty }: ClientInvoicingProps) {
    const { t } = useTranslation('pages');
    const { addAlert } = useNotifications();

    const updateClientMutation = trpc.$client.updateClient.useMutation();

    const [ showExampleModal, setShowExampleModal ] = useToggle(false);

    const saveButton = useCallback(({ onClick }: { onClick: () => void }) => (
        <SaveButton onClick={onClick} isFetching={updateClientMutation.isPending} disabled={!isDirty} />
    ), [ updateClientMutation.isPending, isDirty ]);

    function handleSubmit(data: OmitId<ClientUpdate>) {
        updateClientMutation.mutate({ id: client.id, ...data }, {
            onError: () => {
                addAlert(createErrorAlert(updateClientMutation.error));
            },
            onSuccess: async () => {
                await invalidateClient();
                setIsDirty(false);
                addAlert(createTranslatedSuccessAlert('pages:client.save-success'));
            },
        });
    }

    return (
        <div className='space-y-8 max-w-[800px] mx-auto'>
            <div className='flex flex-wrap items-center justify-between gap-4'>
                <h1 className='text-2xl/6 font-semibold'>{t('client.invoicing.page-title', { clientName: client.name })}</h1>

                <Button variant='outline' size='small' className='bg-transparent' onClick={setShowExampleModal.true}>{t('client.invoicing.preview-invoice')}</Button>
            </div>

            <ClientInvoicingForm
                onSubmit={handleSubmit}
                defaultValue={client}
                onChange={setIsDirty}
                saveButton={saveButton}
                showExampleModal={showExampleModal}
                hideExampleModal={setShowExampleModal.false}
            />
        </div>
    );
}

type SaveButtonProps = Readonly<{
    onClick: () => void;
    isFetching: boolean;
    disabled: boolean;
}>;

function SaveButton({ onClick, isFetching, disabled }: SaveButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.invoicing-profile' });

    return (
        <SpinnerButton
            type='submit'
            isFetching={isFetching}
            variant='primary'
            onClick={onClick}
            disabled={disabled}
            className='w-full'
        >
            {t('save-button')}
        </SpinnerButton>
    );
}
