import { useCallback, type RefAttributes, useEffect, useMemo, type FunctionComponent, type ReactNode } from 'react';
import BaseSelect, { type Props as BaseProps, type ClassNamesConfig, type GroupBase, type MultiValue, type OnChangeValue, type SingleValue, type MenuListProps, type SelectComponentsConfig, type OptionProps, type DropdownIndicatorProps, type ClearIndicatorProps } from 'react-select';
import type SelectBase from 'react-select/base';
import { type UniqueType, type Entity, type Unique } from ':utils/id';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from './utils';
import { ScrollArea } from './ScrollArea';
import { ChevronDownIcon, XmarkIcon } from ':components/icons/basic';
import BaseCreatableSelect, { type CreatableProps } from 'react-select/creatable';
import BaseAsyncSelect, { type AsyncProps } from 'react-select/async';
import BaseAsyncCreatableSelect, { type AsyncCreatableProps } from 'react-select/async-creatable';

// TODO Style group labels

export type { GroupBase, MultiValue, OptionsOrGroups, SingleValue } from 'react-select';

type ValueType = string | number | Entity | Unique | UniqueType<string, string>;

type SelectProps<
    Option extends { value: ValueType }, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>
> = Omit<BaseProps<Option, IsMulti, Group> & RefAttributes<SelectBase<Option, IsMulti, Group>>, 'options' | 'classNames' | 'components'> & {
    options?: (Option | Group)[];
    /** By default, whenever options change, if they no longer contain the current value, the value becomes unselected. This option prevents this behavior. */
    ignoreOptionChanges?: boolean;
    immutableProps?: SelectConfig<Option>;
};

export function Select<
    Option extends { value: ValueType }, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>
>(props: SelectProps<Option, IsMulti, Group>) {
    const { options, ignoreOptionChanges, immutableProps, id } = props;

    /**
     * Whenever the options change, we have to manually find all currently selected ones that are not included in the new options and remove them.
     */
    useEffect(() => tryUnselectRemovedOptions(props), [ options, ignoreOptionChanges ]);

    const configProps = useMemo(() => ({
        classNames: createClassNames<Option, IsMulti, Group>(immutableProps ?? {}),
        components: createComponents<Option, IsMulti, Group>(immutableProps ?? {}),
    }), []);

    return (
        <BaseSelect
            // FIXME This doesn't work!
            // If we use `id`, it's just on the container element.
            // If we use `inputId`, it's on the input, but the select doesn't receive focus when clicking on the label.
            // aria-labelledby also doesn't work ...
            aria-labelledby={id}
            unstyled
            onKeyDown={handleKeyDown}
            {...configProps}
            {...props}
        />
    );
}

/**
 * Workaround for the home and end keys in writeable selects.
 */
export function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement> & React.BaseSyntheticEvent<KeyboardEvent, HTMLInputElement, HTMLInputElement>) {
    const length = event.target.value.length;

    switch(event.key) {
    case 'Home':
        event.preventDefault();
        if (event.shiftKey)
            event.target.selectionStart = 0;
        else
            event.target.setSelectionRange(0,0);
        break;
    case 'End':
        event.preventDefault();
        if (event.shiftKey)
            event.target.selectionEnd = length;
        else
            event.target.setSelectionRange(length,length);
        break;
    }
}

export type SelectConfig<Option extends { value: ValueType }> = VariantProps<typeof controlVariants> & {
    removeIndicator?: boolean;
    /** Display menu in the specified direction (if it's so large that it would be displayed outside the screen). */
    menuAlignment?: 'top' | 'left' | 'top-left';
    /** Menu can be larger than the input (vertically) or it can have no limits (horizontally). */
    menuOverflow?: 'x' | 'y' | 'xy';
    CustomOption?: CustomOption<Option>;
    valueRight?: boolean;
    /** If defined, it overrides the `size` argument for the input. */
    inputSize?: 'compact' | 'default';
    inputClassName?: string;
}

const controlVariants = cva('w-full !min-h-0 border hover:border-primary-200 focus-within:border-primary hover:focus-within:border-primary focus-within:outline-none whitespace-nowrap !flex-nowrap', {
    variants: {
        variant: {
            outline: 'border-secondary-100 bg-white',
            ghost: 'border-white bg-white',
            transparent: 'border-0 bg-transparent',
            'outline-transparent': 'border-secondary-100 bg-transparent',
        },
        size: {
            compact: 'pl-4b pr-3b h-10 rounded-md',
            default: 'pl-4b pr-3b h-13 rounded-lg',
            exact: '',
        },
    },
    defaultVariants: {
        variant: 'outline',
        size: 'default',
    },
});

function createClassNames<
    Option extends { value: ValueType }, IsMulti extends boolean, Group extends GroupBase<Option>
>({ size, variant, menuAlignment: menu, menuOverflow, valueRight, inputSize, inputClassName }: SelectConfig<Option>): ClassNamesConfig<Option, IsMulti, Group> {
    const isDefault = !size || size === 'default';

    return {
        control: state => cn(
            controlVariants({ size: inputSize ?? size, variant }),
            state.menuIsOpen && 'open',
            inputClassName,
        ),
        valueContainer: () => 'h-full',
        singleValue: () => cn('h-full flex items-center', valueRight && 'justify-end'),
        input: () => 'h-full',
        placeholder: () => 'text-secondary-200',
        menu: () => cn('my-1 z-10 p-1 bg-white border shadow-[0px_15px_35px_0px_rgba(0,0,0,0.15)]',
            isDefault ? 'rounded-lg' : 'rounded-md',
            (menu === 'top' || menu === 'top-left') && 'bottom-full !top-auto',
            (menu === 'left' || menu === 'top-left') && 'right-0',
            (menuOverflow === 'x' || menuOverflow === 'xy') && '!w-auto',
        ),
        menuList: () => cn(
            isDefault ? 'rounded-md' : 'rounded-sm',
            (menuOverflow === 'y' || menuOverflow === 'xy') && 'max-h-none',
        ),
        groupHeading: () => cn('text-secondary-400', isDefault ? 'pb-2' : 'pb-1'),
        group: () => isDefault ? '[&+&]:!mt-4' : '[&+&]:!mt-2',
        option: state => cn('shrink-0 w-full px-5 truncate !flex items-center text-nowrap hover:bg-primary-50 active:bg-primary-100 select-none',
            isDefault ? 'py-4 rounded-md' : 'py-2 rounded-sm',
            state.isSelected && 'text-primary',
            state.isDisabled && 'pointer-events-none bg-secondary-100',
        ),
        noOptionsMessage: () => 'shrink-0 px-5 py-2 truncate flex items-center text-nowrap',
    };
}

function createComponents<
    Option extends { value: ValueType }, IsMulti extends boolean, Group extends GroupBase<Option>
>({ removeIndicator, CustomOption }: SelectConfig<Option>): SelectComponentsConfig<Option, IsMulti, Group> {
    return {
        MenuList,
        DropdownIndicator: removeIndicator ? EmptyComponent : DropdownIndicator,
        ClearIndicator,
        IndicatorSeparator: EmptyComponent,
        Option: CustomOption ? createOptionComponent(CustomOption) : OptionComponent,
    };
}

function EmptyComponent() {
    return null;
}

function MenuList<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(props: MenuListProps<Option, IsMulti, Group>) {
    const { maxHeight, getClassNames } = props;
    const className = getClassNames('menuList', props);
    if (className?.includes('max-h-none')) {
        return (
            <div className={className}>
                <div className='space-y-1 p-2'>
                    {props.children}
                </div>
            </div>
        );
    }

    // The flex flex-col is needed for the scroller to work. Otherwise, we would have to set height instead of maxHeight.
    return (
        <ScrollArea style={{ maxHeight }} className={cn('flex flex-col', getClassNames('menuList', props))}>
            <div className='space-y-1'>
                {props.children}
            </div>
        </ScrollArea>
    );
}

function DropdownIndicator<Option, IsMulti extends boolean, Group extends GroupBase<Option>>({ innerProps }: DropdownIndicatorProps<Option, IsMulti, Group>) {
    return (
        <div {...innerProps} className='p-1 hover:text-primary cursor-pointer'><ChevronDownIcon size={10} /></div>
    );
}

function ClearIndicator<Option, IsMulti extends boolean, Group extends GroupBase<Option>>({ innerProps }: ClearIndicatorProps<Option, IsMulti, Group>) {
    return (
        <div {...innerProps} className='p-1 hover:text-primary cursor-pointer'><XmarkIcon size={10} /></div>
    );
}

type CustomOption<Option extends { value: ValueType }> = FunctionComponent<{
    data: Option;
}>;

function createOptionComponent<
    Option extends { value: ValueType }, IsMulti extends boolean, Group extends GroupBase<Option>
>(CustomOption: CustomOption<Option>): FunctionComponent<OptionProps<Option, IsMulti, Group>> {
    return ({ children, ...props }: OptionProps<Option, IsMulti, Group>) => {
        if (typeof children !== 'string')
            return <OptionComponent {...props}>{children}</OptionComponent>;

        const data = props.data;

        return (
            <OptionComponent {...props}>
                {CustomOption({ data })}
            </OptionComponent>
        );
    };
}

// The react-select changes focus of elements when hovering or moving mouse. This is extremely inefficient, because each time this happens, the whole list is re-rendered (several times, because they don't use proper memoization). All items are also filtered, etc.
// This component prevents this behavior.
function OptionComponent<
    Option extends { value: ValueType }, IsMulti extends boolean, Group extends GroupBase<Option>
>(props: OptionProps<Option, IsMulti, Group>) {
    const { children, getClassNames, isDisabled, innerRef, innerProps } = props;

    return (
        <div
            className={getClassNames('option', props)}
            aria-disabled={isDisabled}
            ref={innerRef}
            {...innerProps}
            onMouseMove={undefined}
            onMouseOver={undefined}
        >
            {children}
        </div>
    );
}

function tryUnselectRemovedOptions<
    Option extends { value: ValueType }, IsMulti extends boolean, Group extends GroupBase<Option>
>(props: SelectProps<Option, IsMulti, Group>) {
    const options = props.options;
    if (props.ignoreOptionChanges || !options || !props.onChange)
        return;

    if (props.isMulti) {
        const removedValues: Option[] = (props.value as MultiValue<Option>).filter(value => !optionsInclude(options, value));
        if (removedValues.length === 0)
            return;

        const newValues: readonly Option[] = (props.value as MultiValue<Option>).filter(value => optionsInclude(options, value));
        props.onChange(newValues as OnChangeValue<Option, IsMulti>, {
            action: 'clear',
            removedValues,
        });
    }
    else {
        const value = props.value as SingleValue<Option>;
        if (!value || optionsInclude(options, value))
            return;

        props.onChange(null as OnChangeValue<Option, IsMulti>, {
            action: 'clear',
            removedValues: [ value ],
        });
    }
}

function optionsInclude<Option extends { value: ValueType }, Group extends GroupBase<Option>>(options: (Option | Group)[], option: Option): boolean {
    const onlyOptions = options.flatMap(o => 'options' in o ? o.options : o);
    const { value } = option;
    if (typeof value !== 'object')
        return onlyOptions.some(o => o.value === value);

    if ('id' in value)
        return onlyOptions.some(o => (o.value as Entity).id === value.id);

    if ('unique' in value)
        return onlyOptions.some(o => (o.value as Unique).unique === value.unique);

    return false;
}

type StringOption = {
    value: string;
    label: ReactNode;
};

export type TranslationFunction = (id: string) => ReactNode;

function valueToOption(value: string, t: TranslationFunction): StringOption {
    return {
        value,
        label: t(value),
    };
}

type StringSelectProps = Omit<SelectProps<StringOption, false>, 'value' | 'onChange' | 'options'> & {
    value?: string;
    onChange: (value?: string) => void;
    options: string[];
    t: TranslationFunction;
};

export function StringSelect({ value, onChange, options, t, ...rest }: StringSelectProps) {
    const innerOptions = useMemo(() => options.map(value => valueToOption(value, t)), [ options, t ]);
    const innerValue = useMemo(() => value ? valueToOption(value, t) : null, [ value, t ]);
    const innerOnChange = useCallback((option: SingleValue<StringOption>) => onChange(option ? option.value : undefined), [ onChange ]);

    return (
        <Select
            value={innerValue}
            onChange={innerOnChange}
            options={innerOptions}
            {...rest}
        />
    );
}

type CreatableSelectProps<
    Option extends { value: ValueType }, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>
> = Omit<CreatableProps<Option, IsMulti, Group> & RefAttributes<SelectBase<Option, IsMulti, Group>>, 'options' | 'classNames' | 'components'> & {
    options?: (Option | Group)[];
    /** By default, whenever options change, if they no longer contain the current value, the value becomes unselected. This option prevents this behavior. */
    ignoreOptionChanges?: boolean;
    immutableProps?: SelectConfig<Option>;
};

export function CreatableSelect<
    Option extends { value: ValueType }, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>
>(props: CreatableSelectProps<Option, IsMulti, Group>) {
    const { options, ignoreOptionChanges, immutableProps } = props;

    /**
     * Whenever the options change, we have to manually find all currently selected ones that are not included in the new options and remove them.
     */
    useEffect(() => tryUnselectRemovedOptions(props), [ options, ignoreOptionChanges ]);

    const cofigProps = useMemo(() => ({
        classNames: createClassNames<Option, IsMulti, Group>(immutableProps ?? {}),
        components: createComponents<Option, IsMulti, Group>(immutableProps ?? {}),
    }), []);

    return (
        <BaseCreatableSelect
            unstyled
            {...props}
            {...cofigProps}
            onKeyDown={handleKeyDown}
        />
    );
}

type AsyncSelectProps<
    Option extends { value: ValueType }, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>
> = Omit<AsyncProps<Option, IsMulti, Group> & RefAttributes<SelectBase<Option, IsMulti, Group>>, 'options' | 'classNames' | 'components'> & {
    // options?: (Option | Group)[];
    /** By default, whenever options change, if they no longer contain the current value, the value becomes unselected. This option prevents this behavior. */
    ignoreOptionChanges?: boolean;
    immutableProps?: SelectConfig<Option>;
};

export function AsyncSelect<
    Option extends { value: ValueType }, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>
>(props: AsyncSelectProps<Option, IsMulti, Group>) {
    const { immutableProps } = props;
    const cofigProps = useMemo(() => ({
        classNames: createClassNames<Option, IsMulti, Group>(immutableProps ?? {}),
        components: createComponents<Option, IsMulti, Group>(immutableProps ?? {}),
    }), []);

    return (
        <BaseAsyncSelect
            unstyled
            {...props}
            {...cofigProps}
            onKeyDown={handleKeyDown}
        />
    );
}

type AsyncCreatableSelectProps<
    Option extends { value: ValueType }, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>
> = Omit<AsyncCreatableProps<Option, IsMulti, Group> & RefAttributes<SelectBase<Option, IsMulti, Group>>, 'options' | 'classNames' | 'components'> & {
    // options?: (Option | Group)[];
    /** By default, whenever options change, if they no longer contain the current value, the value becomes unselected. This option prevents this behavior. */
    ignoreOptionChanges?: boolean;
    immutableProps?: SelectConfig<Option>;
};

export function AsyncCreatableSelect<
    Option extends { value: ValueType }, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>
>(props: AsyncCreatableSelectProps<Option, IsMulti, Group>) {
    const { immutableProps } = props;
    const cofigProps = useMemo(() => ({
        classNames: createClassNames<Option, IsMulti, Group>(immutableProps ?? {}),
        components: createComponents<Option, IsMulti, Group>(immutableProps ?? {}),
    }), []);

    return (
        <BaseAsyncCreatableSelect
            unstyled
            {...props}
            {...cofigProps}
            onKeyDown={handleKeyDown}
        />
    );
}

/**
 * A copy of the type from react-select (because it isn't exported for some reason).
 * Also, the og type is wrong (it assumes that value is always a string), so we don't include label and value for this reason.
 */
export type FilterOptionOption<TOption> = {
    readonly data: TOption;
};
